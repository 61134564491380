import React from "react";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { TableRow, TableCell } from "@mui/material";
import { TableSortOrder } from "../../utils/table-utils";
import { EnhancedTableHeader } from ".";
import EnhancedCheckbox from "../enhanced-form/EnhancedCheckbox";
import clsx from "clsx";
import { isEmpty } from "../../utils/validationUtils";
import { makeStyles } from "tss-react/mui";
import {
  MAIN_ONE_THEME,
  contentFontFamilyBoldImportant,
  contentFontFamilyRegular,
} from "../../constants";

export interface HeadRow {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface IEnhancedTableProps {
  headRows: Record<string, EnhancedTableHeader>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (name: string, checked: boolean) => void;
  order?: TableSortOrder;
  orderBy?: string | undefined;
  rowCount: number;
  disableSelection?: boolean;
  forceDisableSelectAll?: boolean;
  disabledSorting?: boolean;
}

const useStyles = makeStyles()(() => ({
  cellRoot: {
    color: `${MAIN_ONE_THEME.palette.primary2.main} !important`,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
    lineHeight: "13px !important",
    height: "34px !important",
    fontFamily: contentFontFamilyBoldImportant,
    border: "none !important",
    padding: "4px 6px !important",
  },
  checkboxCellRoot: {
    width: "14px !important",
    padding: "0 14px 0 8px !important",
    border: "none !important",
    height: "34px !important",
    fontFamily: contentFontFamilyRegular,
  },
  paddedCellRoot: {
    padding: '4px 2px 4px 20px !important',
  },
}));

const EnhancedTableHead: React.FC<IEnhancedTableProps> = ({
  headRows,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  disableSelection,
  forceDisableSelectAll = false,
  disabledSorting = false,
}) => {
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const { classes } = useStyles();
  return (
    <TableHead>
      <TableRow>
        {!disableSelection && (
          <TableCell
            role="checkbox"
            padding="none"
            classes={{ root: classes.checkboxCellRoot }}
          >
            <EnhancedCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount && rowCount !== 0}
              disabled={rowCount === 0 || forceDisableSelectAll}
              onChange={onSelectAllClick}
              name={"Select All"}
              checkboxOnly
            />
          </TableCell>
        )}

        {Object.keys(headRows).map((headerKey, headerIndex) => (
          <TableCell
            classes={{
              root: clsx(classes.cellRoot, {
                [classes.paddedCellRoot]: disableSelection && !headerIndex,
              }),
            }}
            key={headerKey}
            padding="none"
          >
            {!isEmpty(headRows[headerKey].title) && (
              <TableSortLabel
                active={orderBy === headerKey && !disabledSorting}
                direction={order}
                onClick={createSortHandler(headerKey)}
                disabled={disabledSorting}
                hideSortIcon={disabledSorting}
              >
                {headRows[headerKey].title}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
