import React, { useEffect, useMemo, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { getInputs, initialValues } from "./content";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getAdditionalInfoPersonDetailsInfo,
  getAdditionalInformationEnums,
  modifyAdditionalInfo,
} from "./queries";
import { graphqlEntityToAdditionalInfo, LookupToList } from "./utils";

import Loader from "../../components/Loader";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { useNavigate } from "react-router-dom";
import { getError } from "../../utils/graph-utils";

const AdditionalInformationDrawer: React.FC<
  IPersonAdditionalInformationDrawerProps
> = ({ personId, personType, open, onSuccess, onClose }) => {
  const navigate = useNavigate();
  const [booted, setBooted] = useState<boolean>(false);

  const [values, setValues] = useState<Record<string, any>>();

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

  const [additionalInformationEnumResultsQuery] = useLazyQuery(
    getAdditionalInformationEnums()
  );

  const [getAdditionalInformationInfo] = useLazyQuery(
    getAdditionalInfoPersonDetailsInfo(),
    {
      variables: { id: personId },
    }
  );

  const [additionalInformationAction] = useMutation(modifyAdditionalInfo());

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const loadLovList = async () => {
    const result = await additionalInformationEnumResultsQuery({
      fetchPolicy: "no-cache",
    });

    const newAdditionalInformationEnums = LookupToList(result.data);

    return newAdditionalInformationEnums;
  };

  const getEntityInfo = async () => {
    if (personId) {
      const apiResult = await getAdditionalInformationInfo();
      if (apiResult.data) {
        const additionalInformationEntity = graphqlEntityToAdditionalInfo(
          apiResult.data
        );
        return additionalInformationEntity;
      }
    }

    return null;
  };

  const initialize = async () => {
    try {
      const [additionalInfoEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      setLovs(lovData);
      setValues({ ...additionalInfoEntity });
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(
      getInputs(values as any, lovs, personType),
      values
    );

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variables = {
        additionalInfoInputs: {
          gender: data.gender || null,
          maritalStatus: data.maritalStatus || null,
          fatherName: data.fatherName || null,
          motherName: data.motherName || null,
          countryOfBirth: data.countryOfBirth || null,
          cityOfBirth: data.cityOfBirth || null,
          nationalityID: data.nationalityID || null,
          passportNumber: data.passportNumber || null,
          nationalityTwo: data.secondNationality || null,
        },
      };

      const res = await additionalInformationAction({
        variables: personId ? { ...variables, entityId: personId } : variables,
        errorPolicy: "all",
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {personId
              ? "Additional Information successfully updated"
              : "Additional Information successfully created"}
          </ToastSuccessMessage>
        );

        setSubmitButtonState("success");

        setTimeout(() => {
          onSuccess();
          onClose();

          if (!personId) {
            const newPersonId =
              res.data.salesforceManagement.actions.createPerson.id;
            navigate(`/salesforce/persons/` + newPersonId);
          }
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const inputForm = useMemo(() => {
    const result = getInputs(
      values || (initialValues as any),
      lovs,
      personType
    );

    return result;
  }, [values, lovs]);

  return (
    <GenericDrawer
      title={"Modify Additional Information"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={!!submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default AdditionalInformationDrawer;
