import { gql } from "@apollo/client";

export function getAgencyEnums() {
  return gql(/* GraphQL */ `
    query getAgencyEnums {
      Insurance_AgencyStatuses: __type(name: "Insurance_AgencyStatuses") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        lookups {
          agencyTypes {
            Id
            Title
          }
          agencyCodes {
            Id
            Title
          }
        }
      }
    }
  `);
}

export function getAgencyInfo() {
  return gql`
    query getAgencyInfo($id: String!) {
      Insurance {
        entities {
          agency(id: $id) {
            views {
              Insurance_all {
                properties {
                  AgencyName
                  AgencyStatus
                  AgencyType {
                    Id
                    Title
                  }
                  AgencyCode {
                    Id
                    Title
                  }
                  MTPCommissionPercentage
                  MBICommissionPercentage
                  ExpatCommissionPercentage
                  UFACode
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createAgency() {
  return gql`
    mutation createAgency(
      $agencyName: String!
      $agencyStatus: Insurance_AgencyStatuses!
      $agencyType: String!
      $agencyCode: String!
      $mtpCommissionPercentage: Long
      $mbiCommissionPercentage: Long
      $expatCommissionPercentage: Long
      $ufaCode: String!
    ) {
      insurance {
        actions {
          createAgency(
            agencyName: $agencyName
            agencyStatus: $agencyStatus
            agencyType: $agencyType
            agencyCode: $agencyCode
            mtpCommissionPercentage: $mtpCommissionPercentage
            mbiCommissionPercentage: $mbiCommissionPercentage
            expatCommissionPercentage: $expatCommissionPercentage
            ufaCode: $ufaCode
          ) {
            id
            views {
              Insurance_all {
                properties {
                  AgencyName
                  AgencyStatus
                  AgencyType {
                    Id
                  }
                  AgencyCode {
                    Id
                  }
                  MTPCommissionPercentage
                  MBICommissionPercentage
                  ExpatCommissionPercentage
                  UFACode
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updateAgency() {
  return gql`
    mutation updateAgency(
      $agencyId: String!
      $agencyName: String!
      $agencyStatus: Insurance_AgencyStatuses!
      $agencyType: String!
      $agencyCode: String!
      $mtpCommissionPercentage: Long
      $mbiCommissionPercentage: Long
      $expatCommissionPercentage: Long
      $ufaCode: String!
    ) {
      insurance {
        actions {
          updateAgency(
            agencyId: $agencyId
            agencyName: $agencyName
            agencyStatus: $agencyStatus
            agencyType: $agencyType
            agencyCode: $agencyCode
            mtpCommissionPercentage: $mtpCommissionPercentage
            mbiCommissionPercentage: $mbiCommissionPercentage
            expatCommissionPercentage: $expatCommissionPercentage
            ufaCode: $ufaCode
          ) {
            views {
              Insurance_all {
                properties {
                  AgencyName
                  AgencyStatus
                  AgencyType {
                    Id
                    Title
                  }
                  AgencyCode {
                    Id
                    Title
                  }
                  MTPCommissionPercentage
                  MBICommissionPercentage
                  ExpatCommissionPercentage
                  UFACode
                }
              }
            }
          }
        }
      }
    }
  `;
}
