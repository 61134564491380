import React, { useEffect, useMemo, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { getSections } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import Loader from "../../components/Loader";
import { isEmpty } from "lodash";
import {
	DEFAULT_ERROR_TEXT,
	SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { IPlanInformationDrawerProps } from ".";
import SectionDynamicForm from "../../DynamicForm/SectionDynamicForm";
import { getPlanDetailsEnums, updatePlanAndAdditionalDetails } from "./queries";
import { LookupToList } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import dayjs from "dayjs";
import { isZeroValue } from "../../utils/validationUtils";
import { checkPlanExists } from "../plan-drawer/queries";

const PlanInformationDrawer: React.FC<IPlanInformationDrawerProps> = ({
	open,
	onSuccess,
	onClose,
	planDetailsInfo,
	planId,
}) => {

	const [booted, setBooted] = useState<boolean>(false);
	const [formDisabled, setFormDisabled] = useState(false);
	const [submitButtonState, setSubmitButtonState] =
		useState<EnhancedButtonStatus>();

	const [values, setValues] = useState<Record<string, any>>();
	const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

	const [planDetailsListResults] = useLazyQuery(getPlanDetailsEnums());
	const [planDetailsAction] = useMutation(updatePlanAndAdditionalDetails());

	const [checkPlanExistsLazy] = useLazyQuery(checkPlanExists(), {});





	const getLovs = async () => {
		const data = await planDetailsListResults({});
		const newLovs = LookupToList(data.data);
		return newLovs;
	};


	const initialize = async () => {
		try {

			setValues(planDetailsInfo);
			const newLovs = await getLovs();
			setLovs(newLovs);
			setBooted(true);
		} catch (err) {
			toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
		}
	};

	useEffect(() => {
		initialize();
	}, []);

	const inputsForm = useMemo(() => {
		const result = getSections(values || {}, lovs, planDetailsInfo.lineIdExternalCode);
		return result;
	}, [values, lovs]);

	const validatePlanExistsOnNameChange = async (
		selectedSublineId: string,
		planName?: string
	) => {
		if (!isEmpty(planName) && !isEmpty(selectedSublineId)) {
			const result = await checkPlanExistsLazy({
				variables: {
					name: planName,
					planId: planId,
					sublineId: selectedSublineId,
					externalCode: "",
				},
			});

			if (
				result.data.PlanConfigManagement?.queries?.checkPlanExists
					.length > 0
			) {
				return "Plan Name already exists under the same Subline";
			}
		}
		return "";
	};

	const validatePlanExistsOnExternalCodeChange = async (
		selectedSublineId: string,
		externalCode?: string
	) => {
		if (!isEmpty(externalCode) && !isEmpty(selectedSublineId)) {
			const result = await checkPlanExistsLazy({
				variables: {
					planId: planId,
					externalCode: externalCode,
					sublineId: selectedSublineId,
					name: "",
				},
			});

			if (result.data.PlanConfigManagement?.queries?.checkPlanExists.length > 0) {
				return "External Code already exists under the same Subline";
			}
		}
		return "";
	};


	const onCustomValidate = async (fieldName: string, values: Record<string, any>) => {
		if (fieldName === "planName") {
			return await validatePlanExistsOnNameChange(planDetailsInfo.sublineId, values[fieldName]);
		}

		if (fieldName === "externalCode") {
			return await validatePlanExistsOnExternalCodeChange(planDetailsInfo.sublineId, values[fieldName]);
		}

		return "";
	}

	const onCustomBlur = async (fieldName: string, values: Record<string, any>, errors: Record<string, string>) => {
		if (fieldName === "planName" || fieldName === "externalCode") {
			if (!errors[fieldName]) {
				errors[fieldName] = await onCustomValidate(fieldName, values);
			}
		}
		return { values, errors }
	}

	const onCustomValidateForm = async (values: Record<string, any>, errors: Record<string, string>) => {
		if (!errors.planName) {
		  errors.planName = await onCustomValidate("planName", values);
		}
	
		if (!errors.externalCode) {
		  errors.externalCode = await onCustomValidate("externalCode", values);
		}
		return errors;
	  }


	  const submitForm = async (values: Record<string, any>) => {
		const [data] = normaliseDynamicValues(
			{
				...inputsForm?.section1?.inputs,
				...inputsForm?.section2?.inputs,
			},
			values
		);
	
		setFormDisabled(true);
		setSubmitButtonState("loading");
	
		try {
			let variablesMutation = {
				entityId: planId,
				planDetailInputs: {
					externalCode: data.externalCode || null,
					lineID: planDetailsInfo.lineId || null,
					sublineID: planDetailsInfo.sublineId || null,
					name: data.planName || null,
					nameArabic: data.arabicName || null,
					abbreviation: data.abbreviation || null,
					effectiveFrom:
						dayjs(new Date(data.effectiveFrom)).format(SEND_TO_BACKEND_DATE_FORMAT) ||
						null,
					effectiveTo:
						dayjs(new Date(data.effectiveTo)).format(SEND_TO_BACKEND_DATE_FORMAT) ||
						null,
					planStatus: planDetailsInfo.status || null,
					maxNbInsured: parseInt(data?.maxNumberofInsured),
					maxNbBeneficiary: parseInt(data?.maxNumberofBeneficiary),
					clauseEditable: data?.clauseEditable,
				},
				planAdditionalDetailInputs: {
					planCurrency: data.planCurrency || null,
					acalCategory: data.acalCategory || null,
					scratching: data.scratching || null,
					sosService: data?.sOSService || null,
					replacementCarApplied: data.replacementCarApplied || null,
					replacementCarValue: isZeroValue(data.replacementCarValue)
						? 0
						: Number(data.replacementCarValue) || null,
					replacementCarDays: isZeroValue(data.replacementCarDays)
						? 0
						: Number(data.replacementCarDays) || null,
				},
			};
	
			const res = await planDetailsAction({
				variables: variablesMutation,
				errorPolicy: "all",
			});
	
			if (isEmpty(res.errors)) {
				toast.success(
					<ToastSuccessMessage>
						Plan Details successfully updated.
					</ToastSuccessMessage>
				);
				setTimeout(() => {
					setSubmitButtonState("success");
					onSuccess();
					setFormDisabled(false);
					onClose();
				}, 500);
			} else {
				setFormDisabled(false);
				setSubmitButtonState(undefined);
				toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
			}
		} catch (err) {
			setFormDisabled(false);
			setSubmitButtonState(undefined);
			toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
		} finally {
		}
	};

	return (
		<GenericDrawer
			title={"Modify Plan Details"}
			onClose={() => onClose()}
			isOpen={open}
		>
			{!booted ? (
				<Loader />
			) : (
				<>
					<SectionDynamicForm
						onSubmit={() => submitForm(values)}
						buttonText={"Submit"}
						submitButtonState={submitButtonState}
						onCustomBlur={onCustomBlur}
						disableForm={formDisabled}
						sections={inputsForm}
						inputs={{
							...inputsForm?.section1?.inputs,
							...inputsForm?.section2?.inputs,
						}}
						onCustomValidate={onCustomValidateForm}
						onChange={(
							fieldName: string,
							value: string | string[],
							allValues
						) => {
							setValues(allValues);
						}}
					/>
				</>
			)}
		</GenericDrawer>
	);
};

export default PlanInformationDrawer;
