import { IGenericPersonAddressTableData } from ".";
import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import { steps } from "./content";

export function entityToPersonDetails(data: any): any {
  const personDetailsInfo =
    data?.SalesforceManagement?.entities?.person?.views
      ?.SalesforceManagement_all?.properties;

  return {
    UserID: personDetailsInfo?.UserID?.id,
    code: personDetailsInfo?.PersonCode,
    name: personDetailsInfo?.FullName,
    type: personDetailsInfo?.PersonType,
    email: personDetailsInfo?.Email,
    phoneNumber: personDetailsInfo?.PhoneDetails?.PhoneNumber,
    phoneType: personDetailsInfo?.PhoneDetails?.PhoneType,
    createdOn: personDetailsInfo?.createdOn,
    createdBy: `${data?.SalesforceManagement?.entities?.person?.queries?.salesforceManagement?.relatedUsers[0]?.createdBy_firstName} ${data?.SalesforceManagement?.entities?.person?.queries?.salesforceManagement?.relatedUsers[0]?.createdBy_lastName}`,
    modifiedOn: personDetailsInfo?.modifiedOn,
    modifiedBy: `${data?.SalesforceManagement?.entities?.person?.queries?.salesforceManagement?.relatedUsers[0]?.modifiedBy_firstName} ${data?.SalesforceManagement?.entities?.person?.queries?.salesforceManagement?.relatedUsers[0]?.modifiedBy_lastName}`,
    companyName: personDetailsInfo?.CompanyName,
    title: personDetailsInfo?.Title?.Title,
    titleId: personDetailsInfo?.Title?.Id,
    firstName: personDetailsInfo?.FirstName,
    middleName: personDetailsInfo?.MiddleName,
    lastName: personDetailsInfo?.LastName,
    surname: personDetailsInfo?.Surname,
    initials: personDetailsInfo?.Initials,
    dateOfBirth: personDetailsInfo?.DateOfBirth,
    nationality: personDetailsInfo?.NationalityOne?.Title,
    nationalityId: personDetailsInfo?.NationalityOne?.Id,
    relativeOf: personDetailsInfo?.RelativeOf,
    smoker: personDetailsInfo?.Smoker,
    weight: personDetailsInfo?.Weight,
    height: personDetailsInfo?.Height,
    dateOfDeath: personDetailsInfo?.DateOfDeath,
    dateOfDeathNotified: personDetailsInfo?.DateOfDeathNotified || null,
    taxable: personDetailsInfo?.IsTaxable,
    financialReference: personDetailsInfo?.FinancialReference,
    occupationClassCode: personDetailsInfo?.OccupationClass?.Id,
    occupationClass: personDetailsInfo?.OccupationClass?.Title,
    profession: personDetailsInfo?.Profession,
    employer: personDetailsInfo?.Employer,
    dateOfEmployment: personDetailsInfo?.DateOfEmployment,
    salary: personDetailsInfo?.Salary,
    dateOfSelfCertificateReceived:
      personDetailsInfo?.DateOfSelfCertificateReceived,
    dateOfSelfCertificateValidated:
      personDetailsInfo?.DateOfSelfCertificateValidated,
    category: personDetailsInfo?.Category?.Title,
    dateOfKYC: personDetailsInfo?.DateOfKYC,
    gender: personDetailsInfo?.Gender,
    maritalStatus: personDetailsInfo?.MaritalStatus,
    fatherName: personDetailsInfo?.FatherName,
    motherName: personDetailsInfo?.MotherName,
    countryOfBirth: personDetailsInfo?.CountryOfBirth?.Title,
    cityOfBirth: personDetailsInfo?.CityOfBirth,
    nationalityID: personDetailsInfo?.NationalityID,
    passportNumber: personDetailsInfo?.PassportNumber,
    nationalityTwo: personDetailsInfo?.NationalityTwo?.Title,
    comments: personDetailsInfo?.Comments,
    status: personDetailsInfo?.Status,
  };
}

export const getPersonStatus = (status: string) => {
  switch (status) {
    case "inactive":
      return steps[0];
    case "active":
      return steps[1];
    default:
      return steps[0];
  }
};

export function mapToPersonAddressesListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.SalesforceManagement.queries.getPersonAddressList.items.forEach(
    (obj: IGenericPersonAddressTableData, i: number) => {
      list[i] = {
        salesforceManagement_PersonAddress_Id:
          obj.salesforceManagement_PersonAddress_Id,
        salesforceManagement_PersonAddress_IsPrimary:
          obj.salesforceManagement_PersonAddress_IsPrimary ? "Yes" : "No",
        salesforceManagement_PersonAddress_AddressType: capitalizeFirstLetter(
          obj.salesforceManagement_PersonAddress_AddressType
        ),
        salesforceManagement_PersonAddress_Country:
          obj.salesforceManagement_PersonAddress_Address?.Country?.Title,
        salesforceManagement_PersonAddress_Zone:
          obj.salesforceManagement_PersonAddress_Address?.Zone?.Title,
        salesforceManagement_PersonAddress_City:
          obj.salesforceManagement_PersonAddress_Address?.City,
        salesforceManagement_PersonAddress_Street:
          obj.salesforceManagement_PersonAddress_Address?.Street,
        salesforceManagement_PersonAddress_Building:
          obj.salesforceManagement_PersonAddress_Address?.Building,
        salesforceManagement_PersonAddress_AddressDetails:
          obj.salesforceManagement_PersonAddress_Address?.AddressDetails,
        salesforceManagement_PersonAddress_AddressDetailsArabic:
          obj.salesforceManagement_PersonAddress_Address?.AddressDetailsArabic,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.SalesforceManagement?.queries?.getPersonAddressList?.paging
        ?.pageSize,
    pageNumber:
      data?.SalesforceManagement?.queries?.getPersonAddressList?.paging
        ?.pageNumber,
    totalCount:
      data?.SalesforceManagement?.queries?.getPersonAddressList?.paging
        ?.totalCount,
  };
}
