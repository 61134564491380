import { gql } from "@apollo/client";

export function saveAndCalculationProposalPage() {
  return gql`
    mutation saveAndCalculationProposalPage(
      $entityId: String!
      $planId: String!
      $policyCurrency: String!
      $policyEffectiveDate: Date!
      $policyExpiryDate: Date!
      $agencyRepairYear: Long
      $depreciationYears: Long
      $policyPersonInput: [Production_policyPersonInputs!]!
      $policyPlanCoverInput: [Production_policyPlanCoverInputs!]!
      $costAndChargeInput: Production_CostAndChargesInputs!
      $vehicleDetailsInput: Production_VehicleDetailsInputs!
    ) {
      production {
        entities {
          proposal {
            production {
              calculateAndSaveMotor(
                entityId: $entityId
                planID: $planId
                policyCurrency: $policyCurrency
                policyEffectiveDate: $policyEffectiveDate
                policyExpiryDate: $policyExpiryDate
                agencyRepairYear: $agencyRepairYear
                policyPersonInput: $policyPersonInput
                policyPlanCoverInput: $policyPlanCoverInput
                costAndChargeInput: $costAndChargeInput
                vehicleDetailsInput: $vehicleDetailsInput
                depreciationYears: $depreciationYears
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function saveProposalDraftMotor() {
  return gql`
    mutation saveProposalDraftMotor(
      $entityId: String!
      $policyPersonInput: [Production_policyPersonInputs!]!
      $policyPlanCoverInput: [Production_policyPlanCoverInputs!]!
      $costAndChargeInput: Production_CostAndChargesInputs!
      $vehicleDetailsInput: Production_VehicleDetailsInputs!
      $depreciationYears: Long
      $agencyRepairYear: Long
    ) {
      production {
        entities {
          proposal {
            production {
              saveProposalDraftMotor(
                entityId: $entityId
                policyPersonInput: $policyPersonInput
                policyPlanCoverInput: $policyPlanCoverInput
                costAndChargeInput: $costAndChargeInput
                vehicleDetailsInput: $vehicleDetailsInput
                depreciationYears: $depreciationYears
                agencyRepairYear: $agencyRepairYear
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
