import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery, useQuery } from "@apollo/client";
import { filterQuery, listQuery } from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { actions, filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import { IEnhanceTableHeaderClickable } from "../../components/enhanced-table";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import dayjs from "dayjs";
import PlanDrawer from "../../forms/plan-drawer/PlanDrawer";
import { capitalizeFirstCharacter } from "../../utils/formatting-utils";
import { ITableOrder, TableSortOrder } from "../../utils/table-utils";

interface IPlansPage {}

const PlansPage: React.FC<IPlansPage> = ({}) => {
  const navigate = useNavigate();

  const [booted, setBooted] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [planDrawerOpen, setPlanDrawerOpen] = useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>("");
  const [selectedLineId, setSelectedLineId] = useState<string>("");

  const FILTER_SESSION_KEY = "plansFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  let setSearchTimeout: NodeJS.Timeout;

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
          subline: [],
          SelectedStatuses: [],
          createdOn: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableOrder, setTableOrder] = useState<Record<string, ITableOrder>>({
    plan: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getPlansLazy] = useLazyQuery(listQuery());

  const loadPlanList = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    orders = tableOrder,
    filterV = filterValues,
    searchKeyword = keywordSearch
  ) => {
    const result = await getPlansLazy({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        SelectedLineId:
          filterV?.line && filterV?.line?.length > 0 ? filterV?.line : [],
        SelectedSublineID:
          filterV?.subline && filterV?.subline?.length > 0
            ? filterV.subline
            : [],
        SelectedStatuses:
          filterV?.status && filterV?.status?.length > 0
            ? filterV.status
            : null,
        FromCreatedOn: filterV?.createdOn?.[0]
          ? dayjs(filterV?.createdOn?.[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        ToCreatedOn: filterV?.createdOn?.[1]
          ? dayjs(filterV?.createdOn?.[1]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        Attribute: capitalizeFirstCharacter(
          orders?.plan?.orderBy || "PlanConfigManagement_Plan_Name"
        ),
        Descending: orders?.plan?.orderDirection !== "asc",
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: "all",
    });

    return result;
  };

  const initialize = async () => {
    try {
      const planListData = await loadPlanList();
      const mappedPlanList = await mapToListingData(planListData.data);

      if (planListData.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }

      const filterDataResponse = await filterResponse.refetch();
      const lovs = dataToLovs(filterDataResponse.data);

      const savedFilters = getFilter(FILTER_SESSION_KEY) || filterValues;

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setTableData({ ...mappedPlanList, pageNumber: 0 });
      setFilterValues(savedFilters);
      setFilterSections(newFilterSections);
      setBooted(true);
    } catch (error) {
      console.error("Error initializing data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const filterResponse = useQuery(filterQuery(), {
    fetchPolicy: "no-cache",
    variables: { SelectedLineId: selectedLineId },
  });

  useEffect(() => {
    if (filterResponse?.loading) setLoadingState(true);
    if (filterResponse?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (filterResponse?.data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = dataToLovs(filterResponse.data);

      if (!Object.keys(lovs.line).length) {
        lovs.line = filterValues?.line;
      }

      if (!Object.keys(lovs.subline).length) {
        lovs.subline = filterValues?.subline;
      }

      if (savedFilters.subline) {
        savedFilters.subline = savedFilters.subline.filter((val: string) =>
          Object.keys(lovs.subline).includes(val)
        );
      }

      if (!Object.keys(lovs.status).length) {
        lovs.status = filterValues?.status;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [filterResponse]);

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrder, filterValues, val);
    }, 1000);
  };

  const handleSortChange = (orderBy: string, order: string) => {
    const newOrder = _.cloneDeep(tableOrder);

    newOrder.plan.orderBy = orderBy;
    newOrder.plan.orderDirection = order as any;
    handlePageChange(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder,
      filterValues,
      keywordSearch
    );
    setTableOrder(newOrder);
  };

  const handlePageChange = async (
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrder,
    filterV = filterValues,
    searchKeyword: string = keywordSearch
  ) => {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadPlanList(
        page,
        pageSize,
        orders,
        filterV,
        searchKeyword
      );
      const mappedPlanList = await mapToListingData(result.data);
      setTableData({ ...mappedPlanList, pageNumber: page, pageSize });
      setLoadingState(false);
    }
  };

  actions[0].onClick = () => {
    setChosenEditId(undefined);
    setPlanDrawerOpen(true);
  };

  (
    headers.planConfigManagement_Plan_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate("/plan/plans/" + payload.columns.planConfigManagement_Plan_Id);
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: "20px" }}>
      <EnhancedTable
        title="Plans"
        name="count"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={(page: number) =>
          handlePageChange(page, tableData.pageSize)
        }
        handleSearchChange={delaySearch}
        handleSort={handleSortChange}
        handleRowsPerPageChange={(pageSize: number) =>
          handlePageChange(0, pageSize)
        }
        currentPage={tableData.pageNumber}
        hideToolbar={false}
        usePagination
        disableSelection
        actions={actions}
        isToolbarAction={false}
        //loader={loadingState}
        ordering={tableOrder.plan.orderDirection as TableSortOrder}
        orderingBy={tableOrder.plan.orderBy}
      />
      {planDrawerOpen && (
        <PlanDrawer
          open={planDrawerOpen}
          onClose={() => setPlanDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0, 10);
          }}
          isLineNameDisabled={false}
          canNavigateToDetailsPage={true}
          planId={chosenEditId}
        />
      )}
    </div>
  );

  const onFilterUpdate = (newFilters: Record<string, any>) => {
    setSelectedLineId(newFilters.line);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    handlePageChange(0, tableData.pageSize, tableOrder, newFilters);
  };

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        filters={filterSections}
        onApplyFilter={(v) => {
          onFilterUpdate(v);
        }}
      />
    );

  return (
    <StaticLayout
      loading={!booted}
      name="Applications"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default PlansPage;
