import React from 'react';
import EnhancedDisplay, { IEnhancedDisplayProps } from './EnhancedDisplay';
import { useAppSelector } from '../../redux/hooks';

interface IEnhancedDisplayWrapperProps extends IEnhancedDisplayProps {}

const EnhancedDisplayWrapper: React.FC<IEnhancedDisplayWrapperProps> = ({
  currencySymbol,
  ...props
}) => {
  const tenant = useAppSelector((state) => state.tenant);

  return (
    <EnhancedDisplay
      {...props}
      aircall={tenant.aircall}
      currencySymbol={currencySymbol || tenant.currencySymbol}
      dateFormat={tenant.dateFormat}
      phoneNumberPattern={tenant.phoneNumberPattern}
    />
  );
};

export default EnhancedDisplayWrapper;
