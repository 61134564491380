import { IAbstractRecord } from "../../models";

export interface IInformationBoxData {
  title: string;
  hint?: string;
  hidePremiumBreakdown?: boolean;
  premiumColor?: string;
  status: string;
  statusIcon: string;
  statusColor: string;
  statusReason?: string;
  reviewedOn: string;
  subtitle: string | JSX.Element;
  description?: string | JSX.Element;
  tabs: IInformationBoxTab[];
  collapsible: boolean;
  initiallyCollapsed: boolean;
  hintIconNumber?: string;
}

export interface IInformationBoxTab {
  headers: Record<string, IInformationBoxTabHeader>;
  data: Record<string, IAbstractRecord>;
  headerData?: Record<string, IAbstractRecord>;
  groupingTitle: string;
  groupingValues: string[];
  title: string;
  name: string;
  supportsGrouping: boolean;
}

export interface IInformationBoxTabHeader {
  title: string;
  name: string;
  type: InformationDataType;
  headerDataType?: InformationDataType;
}

export interface IInformationBoxRowText extends IInformationBoxTabHeader {
  type: InformationDataType.Text;
}

export interface IInformationBoxRowCurrency extends IInformationBoxTabHeader {
  type: InformationDataType.Currency;
  currencySymbol: string;
}

export interface IInformationBoxRowPercentage extends IInformationBoxTabHeader {
  type: InformationDataType.Percentage;
}

export interface IInformationBoxRowSummaryList
  extends IInformationBoxTabHeader {
  type: InformationDataType.SummaryList;
  description: string;
  date: string;
}

export enum InformationBoxStatus {
  Accepted = 'Accepted',
  Pending = 'Pending',
  Sent = 'Sent',
  Rejected = 'Rejected',
}

export enum InformationDataType {
  Text = 'Text',
  Currency = 'Currency',
  Status = 'Status',
  Rate = 'Rate',
  Percentage = 'Percentage',
  Date = 'Date',
  DateAndTime = 'DateAndTime',
  YesNo = 'YesNo',
  Integer = 'Integer',
  SummaryList = 'SummaryList',
}
