import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  createTransfer,
  getTransfersEnums,
  getProductInfo,
} from "./queries";
import { LookupToList, graphqlEntityToProductInfo } from "./utils";
import Loader from "../../components/Loader";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { isEmpty } from "../../utils/validationUtils";
import { DEFAULT_ERROR_TEXT, SEND_TO_BACKEND_DATE_FORMAT } from "../../constants";
import { getError } from "../../utils/graph-utils";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const TransferDrawer: React.FC<ITransferDrawerProps> = ({
  transferId,
  open,
  onSuccess,
  onClose,
}) => {

  const navigate = useNavigate();

  // const accountEnumResults = useQuery(getAccountingEnums(), {
  //   fetchPolicy: "no-cache",
  // });


  const [accountEnumResultsQeury] = useLazyQuery(getTransfersEnums());

  const [accountAction] = useMutation(
     createTransfer()
  );

  const [productInfoQuery] = useLazyQuery(getProductInfo());

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    type: {},
    relatedCompanies: {}
  });

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>();

  const [booted, setBooted] = useState<boolean>(false);

  const initialize = async () => {
    setBooted(false);
    try {

      const values = {
        company: '',
        type: '',
        fromDate: '',
        toDate: ''
      };

      let newLovs: Record<string, Record<string, string>> = {};

      const promises: Promise<any>[] = [];
      const accountEnumResultsQeuryPromise = accountEnumResultsQeury({
        fetchPolicy: "no-cache"
      });

      promises.push(accountEnumResultsQeuryPromise);

      if (transferId) {
        const productInfoResultPromise = productInfoQuery({
          variables: { id: transferId },
          fetchPolicy: "no-cache",
        });
        promises.push(productInfoResultPromise);
      }


      const result = await Promise.all(promises);

      if (result[0]?.data) {
        const newAccountEnums = LookupToList(result[0].data);

        newLovs = {
          type: newAccountEnums["Type"],
          relatedCompanies: newAccountEnums["relatedCompanies"]
        }
      }

      if (transferId && result[1]?.data) {

        if (result[1]?.data) {
          const productEntity = graphqlEntityToProductInfo(result[1]?.data);
          if (productEntity) {
            values.company = productEntity.company;
          }
        }
      }
      const newInputForm = inputs(!!transferId ? "edit" : "add", values, newLovs);
      setLovs(newLovs);
      setInputsForm(newInputForm);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setBooted(true);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputsForm, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variables = {
        SelectedCompany: data.company,
        TransferType: data.type,
        TransferFromDate: dayjs(data.fromDate).format(SEND_TO_BACKEND_DATE_FORMAT),
        TransferToDate: dayjs(data.toDate).add(1, 'day').format(SEND_TO_BACKEND_DATE_FORMAT)
      };

      const res = await accountAction({
				variables: transferId
					? {
							EnteredProductDescription: data.description,
							EnteredProductName: data.name,
							EnteredProductPrice:
								data.price === "" ? null : Number(data.price),
							CurrentProductID: transferId,
					  }
					: variables,
				errorPolicy: "all",
			})

      if (isEmpty(res.errors)) {
				toast.success(
					<ToastSuccessMessage>
						{transferId
							? "Transfer successfully updated"
							: "Transfer successfully created"}
					</ToastSuccessMessage>
				);
				setTimeout(() => {
					setSubmitButtonState("success");
					onClose();
					onSuccess();
					if (!transferId) {
						navigate(`/accounting/transfers`);
					}
				}, 500);
			} else {
				setSubmitButtonState(undefined);
				toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
			}

    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const onCustomChange = async (fieldName: string, value: any, allValues: Record<string, any>, errors: Record<string, any>) => {
    const currentMode = !!transferId ? "edit" : "add";
    const newLovs = { ...lovs };

    if (fieldName === 'fromDate' || fieldName === 'toDate') {
        setFormDisabled(true);
        if(allValues.fromDate !== '' && allValues.toDate !== '' && Date.parse(allValues.fromDate) > Date.parse(allValues.toDate)){
            errors["toDate"] = "Must be greater than Transfer From Date"
        }
        else {
            errors["toDate"] = ""
        }

        setFormDisabled(false);
    }
    let newInputForm = inputs(currentMode, allValues, newLovs);

    setInputsForm(newInputForm);
  }

  return (
    <GenericDrawer
      title={transferId ? "Modify Transfer" : "Add New Transfer"}
      onClose={() => onClose()}
      isOpen={open}
    >


      {(!booted || !inputsForm) && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            onChange={onCustomChange}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default TransferDrawer;
