import { gql } from "@apollo/client";

export function getMedicalPlanRatesEnums() {
  return gql`
    query getMedicalPlanRatesEnums {
      Core_CoNSSFOptions: __type(name: "Core_CoNSSFOptions") {
        name
        enumValues {
          name
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
          medicalPlanClasses {
            Id
            Title
          }
          medicalLevels {
            Id
            Code
            Title
          }
        }
      }
    }
  `;
}

export function getPlanSpecificMedicalInfo() {
  return gql`
    query getPlanSpecificMedicalInfo($id: String!) {
      PlanConfigManagement {
        entities {
          planSpecificMedical(id: $id) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  Id
                  PlanID {
                    id
                  }
                  PolicyCoverID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          Name
                          ExternalCode
                        }
                      }
                    }
                  }
                  NbOfAdherent
                  Class {
                    Id
                    Title
                  }
                  AgeFrom
                  AgeTo
                  CoNssf
                  NetPremium
                  TPAFeesPercentage
                  MinTPAFees
                  MaxTPAFees
                  IsMain
                  IsMandatory
                  IsEditable
                  CoNssf
                  Level {
                    Id
                    Code
                    Title
                  }
                  SumInsured
                  SumInsuredIsAdditive
                  ExcessOnClaimType
                  ExcessOnClaimAmount
                  ExcessOnClaimPercentage
                  ExcessOnClaimDays
                  ClauseID {
                    id
                    views {
                      PlanConfigManagement_all {
                        properties {
                          ExternalCode
                          Name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createPlanSpecificMedical() {
  return gql`
    mutation createPlanSpecificMedical(
      $planSpecificMedicalInputs: PlanConfigManagement_PlanSpecificMedicalInputs!
    ) {
      planConfigManagement {
        actions {
          createPlanSpecificMedical(
            planSpecificMedicalInputs: $planSpecificMedicalInputs
          ) {
            id
          }
        }
      }
    }
  `;
}

export function updatePlanSpecificMedical() {
  return gql`
    mutation updatePlanSpecificMedical(
      $entityId: String!
      $planSpecificMedicalInputs: PlanConfigManagement_PlanSpecificMedicalInputs!
    ) {
      planConfigManagement {
        entities {
          planSpecificMedical {
            planConfigManagement {
              updatePlanSpecificMedical(
                entityId: $entityId
                planSpecificMedicalInputs: $planSpecificMedicalInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function fetchedPolicyCoverList() {
  return gql`
    query fetchedPolicyCoverList(
      $selectedLineIds: [String]
      $searchKeyword: String!
    ) {
      PlanConfigManagement {
        queries {
          allPolicyCoverList(
            selectedLineIds: $selectedLineIds
            searchKeyword: $searchKeyword
          ) {
            planConfigManagement_PolicyCover_Id
            planConfigManagement_PolicyCover_createdOn
            planConfigManagement_PolicyCover_ExternalCode
            planConfigManagement_PolicyCover_Name
            planConfigManagement_PolicyCover_NameArabic
            planConfigManagement_PolicyCover_NameFrench
            planConfigManagement_PolicyCover_LineID {
              id
            }
          }
        }
      }
    }
  `;
}

export function fetchedClausesList() {
  return gql`
    query fetchedClausesList(
      $selectedLineIds: [String]
      $searchKeyword: String!
    ) {
      PlanConfigManagement {
        queries {
          allClauseList(
            selectedLineIds: $selectedLineIds
            searchKeyword: $searchKeyword
          ) {
            planConfigManagement_Clause_Id
            planConfigManagement_Clause_ExternalCode
            planConfigManagement_Clause_Name
          }
        }
      }
    }
  `;
}
