import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { IEnhancedCommonProps } from '..';
import EnhancedInputTooltip from '../enhanced-tooltip/EnhancedInputTooltip';
import SectionShell from '../common/SectionShell';
import { Tooltip } from '@mui/material';
import { isEmpty } from '../../utils/validationUtils';
import { makeStyles } from 'tss-react/mui';

interface IInformationBoxWidgetSectionProps extends IEnhancedCommonProps {
  actions?: JSX.Element;
  middle?: JSX.Element;
  bottom?: JSX.Element;
  classes?: {
    header?: string;
    headerContainer?: string;
    container?: string;
    middle?: string;
    title?: string;
    bottom?: string;
  };
  title?: string;
  hint?: string;
  hidePremiumBreakdown?: boolean;
  premiumColor?: string;
  subtitle?: JSX.Element;
  useSeparator?: boolean;
  showFullTitle?: boolean;
  hintIconNumber?: string;
}

const useStyles = makeStyles()(() => ({
    headerContainer: {
      width: '100%',
      display: 'block',
    },
    sectionHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // flexWrap: 'wrap',
    },
    leftHeader: {
      maxWidth: 'calc(55% - 0.5em)',
      margin: '0 0.5em 0 0',
    },
    leftHeaderFullWidth: {
      maxWidth: '100%',
      margin: '0 0.5em 0 0',
    },
    sectionTitle: {
      display: 'block',
      minWidth: 'fit-content',
      margin: '0 0 0.5em',
      fontFamily: 'HelveticaNeue-Bold',
    },
    subtitleContainer: {
      display: 'block',
      margin: '0.5em 0 0',
    },
    itemsContainer: {
      margin: '0 0 0.5em',
    },
    fullWidthHeader: {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      padding: 0,
    },
  }),
);

const InformationBoxWidgetSection: React.FC<PropsWithChildren<IInformationBoxWidgetSectionProps>> = ({
  actions,
  children,
  classes = {},
  middle,
  title,
  subtitle,
  useSeparator,
  className,
  style,
  bottom,
  showFullTitle = false,
  hint,
  hintIconNumber
}) => {
  const sectionClasses = useStyles().classes;

  return (
    <SectionShell
      classes={{
        header: showFullTitle ? sectionClasses.fullWidthHeader : undefined,
        container: classes.container,
      }}
      className={className}
      style={style}
      useSeparator={useSeparator}
      header={
        title ? (
          <div className={sectionClasses.headerContainer}>
            <div
              className={clsx(
                sectionClasses.sectionHeader,
                classes.headerContainer,
              )}
            >
              <div
                className={clsx(
                  showFullTitle
                    ? sectionClasses.leftHeaderFullWidth
                    : sectionClasses.leftHeader,
                  classes.header,
                )}
              >
                {title.length > 12 && !showFullTitle ? (
                  <>
                    <Tooltip title={title}>
                      <span
                        className={clsx(
                          sectionClasses.sectionTitle,
                          classes.title,
                        )}
                      >
                        {title}
                      </span>
                    </Tooltip>
                    {
                      !!hint && <EnhancedInputTooltip description={hint} />
                    }
                  </>
                ) : (
                  <span
                    className={clsx(sectionClasses.sectionTitle, classes.title)}
                  >
                    <span>{title}</span>
                    {!isEmpty(hintIconNumber) && hintIconNumber !== "0" ? (
                      <EnhancedInputTooltip
                        // title={hintIconNumber || ''}
                        description={hint || ''}
                      />
                    ) : !isEmpty(hint) ? (
                      <EnhancedInputTooltip description={hint || ''} />
                    ) : (
                      <></>
                    )}
                  </span>
                )}
                {!!subtitle && (
                  <span className={sectionClasses.subtitleContainer}>
                    {subtitle}
                  </span>
                )}
              </div>
              {middle && (
                <div
                  className={clsx(
                    sectionClasses.itemsContainer,
                    classes.middle,
                  )}
                >
                  {middle}
                </div>
              )}
              {actions && (
                <div className={sectionClasses.itemsContainer}>{actions}</div>
              )}
            </div>
            {bottom && <div className={classes.bottom}> {bottom} </div>}
          </div>
        ) : (
          undefined
        )
      }
    >
      {children}
    </SectionShell>
  );
};

export default InformationBoxWidgetSection;
