import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from "../../components/enhanced-table";

export const steps = ["Inactive", "Active"];

export const headers: Record<string, EnhancedTableHeader> = {
  salesforceManagement_PersonAddress_IsPrimary: {
    name: "salesforceManagement_PersonAddress_IsPrimary",
    title: "Primary",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_AddressType: {
    name: "salesforceManagement_PersonAddress_AddressType",
    title: "Address Type",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_Country: {
    name: "salesforceManagement_PersonAddress_Country",
    title: "Country",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_Zone: {
    name: "salesforceManagement_PersonAddress_Zone",
    title: "Zone",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_City: {
    name: "salesforceManagement_PersonAddress_City",
    title: "City",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_Street: {
    name: "salesforceManagement_PersonAddress_Street",
    title: "Street",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_Building: {
    name: "salesforceManagement_PersonAddress_Building",
    title: "Building",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_AddressDetails: {
    name: "salesforceManagement_PersonAddress_AddressDetails",
    title: "Address Details",
    type: EnhancedTableHeaderType.Text,
  },
  salesforceManagement_PersonAddress_AddressDetailsArabic: {
    name: "salesforceManagement_PersonAddress_AddressDetailsArabic",
    title: "Address Details Arabic",
    type: EnhancedTableHeaderType.Text,
  },
};
