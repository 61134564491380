import { initialValues } from ".";
import { quote } from "../../components/custom/QuotesCards";
import { isValidDate } from "../../utils/date-utils";
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
  lookupListAsRecordObjectWithArabicTitle,
} from "../../utils/graph-utils";
import {
  isEmpty,
  isValidEmail,
  validatePhoneNumber,
} from "../../utils/validationUtils";
import { steps } from "./content";

export function entityToApplicationDetails(data: any): any {
  const applicationInfo =
    data?.Insurance?.entities?.application?.views?.Insurance_all?.properties;
  const expatDetails =
    data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
      ?.ExpatDetailsID?.views?.Insurance_all?.properties;
  const customerDetails =
    data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
      ?.ApplicationContactDetailsID?.views?.Insurance_all?.properties;

  return {
    status: applicationInfo?.Status,

    applicationName: applicationInfo?.ApplicationName,
    lineOfBusiness: applicationInfo?.LineOfBusiness?.Title,
    createdBy: `${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.createdBy_firstName} ${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: applicationInfo?.createdOn,
    modifiedBy: `${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.modifiedBy_firstName} ${data?.Insurance?.entities?.application?.queries?.insurance?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: applicationInfo?.modifiedOn,
    statusReason: applicationInfo?.StatusReason,

    effectiveDate: applicationInfo?.EffectiveDate,
    expiryDate: applicationInfo?.ExpiryDate,
    isRenewal: applicationInfo?.IsRenewal || initialValues.isRenewal,
    renewalNumber: applicationInfo?.RenewalNumber,

    title: customerDetails?.Title?.Id || initialValues.title,
    firstName: customerDetails?.FirstName,
    middleName: customerDetails?.MiddleName,
    lastName: customerDetails?.LastName,
    email: customerDetails?.Email,
    phoneType: customerDetails?.PhoneType || initialValues.phoneType,
    mobileNumber: customerDetails?.MobileNumber,
    country: customerDetails?.Address?.Country?.Id || initialValues.country,
    city: customerDetails?.Address?.City,
    street: customerDetails?.Address?.Street,
    building: customerDetails?.Address?.Building,
    insuredName: expatDetails?.InsuredName,
    insuredFirstName: expatDetails?.InsuredFirstName,
    insuredPlan: expatDetails?.InsuredPlan?.Code,
    insuredMiddleName: expatDetails?.InsuredMiddleName,
    insuredLastName: expatDetails?.InsuredLastName,
    insuredDateofBirth: expatDetails?.InsuredDateOfBirth,
    insuredNationality:
      expatDetails?.InsuredNationality?.Id || initialValues.insuredNationality,
    insuredGender: expatDetails?.InsuredGender || initialValues.insuredGender,
    insuredPassportNumber: expatDetails?.InsuredPassportNumber,
    insuredOccupation:
      expatDetails?.InsuredOccupation?.Id || initialValues.insuredOccupation,

    agencyName:
      applicationInfo?.AssignedAgencyID?.views?.Insurance_all?.properties
        ?.AgencyName,
    agentName:
      applicationInfo?.AssignedAgentID?.views?.Insurance_all?.properties
        ?.FirstName +
      " " +
      applicationInfo?.AssignedAgentID?.views?.Insurance_all?.properties
        ?.LastName,
    customerName:
      applicationInfo?.ApplicationContactDetailsID?.views?.Insurance_all
        ?.properties?.FullName,
  };
}

export function getFirstInsuranceQuoteProductCode(data: any): string {
  const quotes = data.Insurance.entities.application.queries.insurance.applicationQuotes;
  return quotes.length > 0 ? quotes[0].insuranceQuote_Product.Code : '';
}

export function getFirstInsuranceQuoteProductName(data: any): string {
  const quotes = data.Insurance.entities.application.queries.insurance.applicationQuotes;
  return quotes.length > 0 ? quotes[0].insuranceQuote_Product.Title + " 1" : '';
}

export function extractLovs(data: any, agentType: string): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    countries: {},
    phoneTypes: {},
    products: {},
    genders: {},
    nationalities: {},
    occupations: {},
    renewalStatus: {},
  };

  lovs["countries"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.countries
  );

  lovs["titles"] = lookupListAsRecordObject(data?.Insurance?.lookups?.titles);

  lovs["phoneTypes"] = enumListAsRecordObject(
    data?.Insurance_PhoneType?.enumValues
  );

  lovs["products"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.products
  );

  lovs["genders"] = enumListAsRecordObject(data.Insurance_Genders.enumValues);

  lovs["nationalities"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.nationalities
  );
  
  if(agentType && agentType?.toUpperCase() === "EXTERNAL"){
    delete lovs["nationalities"][290];
  }

  delete lovs["nationalities"][291];

  lovs["occupations"] = lookupListAsRecordObjectWithArabicTitle(
    data?.Insurance?.lookups?.occupations
  );

  lovs["insuredPlan"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.insuredPlans
  );

  lovs["renewalStatus"] = enumListAsRecordObject(
    data.Insurance_RenewalStatus.enumValues
  );

  // let result: Record<string, string> = {};

  // const nations = Object.values(lovs['nationalities']).sort();

  // nations.forEach(n => {
  //   let key = getKeyByValue(lovs['nationalities'], n);
  //   result["_" + key.toString()] = n;
  // });

  // lovs['nationalities'] = result;
  return lovs;
}

export function graphqlEntityToQuoteDetails(
  data: any,
  index: number,
  currencySymbol: string,
  lob: string
): quote {
  return {
    lineOfBusiness: lob,
    id: data?.Insurance?.entities?.application?.queries?.insurance
      ?.applicationQuotes[index]?.insuranceQuote_Id,
    title:
      data?.Insurance?.entities?.application?.queries?.insurance
        ?.applicationQuotes[index]?.insuranceQuote_Name,
    status:
      data?.Insurance?.entities?.application?.queries?.insurance
        ?.applicationQuotes[index]?.insuranceQuote_Status,
    statusReason:
      data?.Insurance?.entities?.application?.queries?.insurance
        ?.applicationQuotes[index]?.insuranceQuote_StatusReason,
    createdOn:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.createdOn,
    modifiedOn:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.modifiedOn,
    hasCoverage: true,
    hasOnlyMainAmount: true,
    coverageAmount: "",
    coverageAmountCurrencySymbol: "",
    premiumAmount:
      data?.Insurance?.entities?.application?.queries?.insurance
        ?.applicationQuotes[index]?.insuranceQuote_TotalAnnualPremium != 0
        ? data?.Insurance?.entities?.application?.queries?.insurance
            ?.applicationQuotes[index]?.insuranceQuote_TotalAnnualPremium
        : "0",
    premiumAmountCurrencySymbol: currencySymbol,
    policyId:
      data?.Insurance?.entities?.application?.queries?.insurance
        ?.applicationQuotes[index]?.insuranceQuote_PolicyID?.id,
    fullPolicyNumber:
      data?.Insurance?.entities?.application?.queries?.insurance
        ?.applicationQuotes[index]?.insuranceQuote_PolicyID?.views
        ?.Insurance_all?.properties?.FullPolicyNumber,
    disabled:
      data?.Insurance?.entities?.application?.queries?.insurance
        ?.applicationQuotes[index]?.quote_Status == "REFER_TO_UW_DEPARTMENT",
    expatDetails:
      data?.Insurance?.entities?.application?.views?.Insurance_all?.properties
        ?.ExpatDetailsID?.views?.Insurance_all?.properties,
    product: {
      Code: data?.Insurance?.entities?.application?.queries?.insurance
      ?.applicationQuotes[index]?.insuranceQuote_Product?.Code,
      Title: data?.Insurance?.entities?.application?.queries?.insurance
      ?.applicationQuotes[index]?.insuranceQuote_Product?.Title,
    },
  };
}

//method need to return true to avoid phone number validation in case phone number is empty
export const checkPhoneNumber = (phoneNumber: string) => {
  //need to check that we are not receiving the country code also
  if (!isEmpty(phoneNumber) && phoneNumber.length > 4) {
    return validatePhoneNumber(phoneNumber);
  }

  return false;
};

export const checkEmail = (email: string) => {
  if (!isEmpty(email)) {
    return isValidEmail(email);
  }

  return true;
};

export const getApplicationStatus = (status: string) => {
  switch (status) {
    case "NEW":
      return steps[0];
    case "QUOTE_PREPARATION":
      return steps[1];
    case "QUOTE_CONFIRMATION":
      return steps[2];
    case "CLOSED":
      return steps[3];
    default:
      return steps[0];
  }
};

export const getApplicationProgress = (status: string) => {
  switch (status) {
    case "NEW":
      return 25;
    case "QUOTE_PREPARATION":
      return 50;
    case "QUOTE_CONFIRMATION":
      return 57;
    case "CLOSED":
      return 100;
    default:
      return 0;
  }
};

export const checkDate = (date: string) => {
  return isValidDate(date);
};

// function getKeyByValue(object: {[key: string]: any}, value: any): string | null {
//   return Object.keys(object).find(key => object[key] === value) || null;
// }
