import React, { useMemo } from "react";
import { IDynamicDiplayView } from "../../../components/widgets/dynamic-display";
import DynamicWidget from "../../../components/widgets/dynamic-display/DynamicWidget";
import { capitalizeFirstLetter } from "../../../utils/formatting-utils";

interface IBusinessPartnerDetailsWidgetProps {
  data: Record<string, any>;
  actions?: JSX.Element;
}

const BusinessPartnerDetailsWidget: React.FC<
  IBusinessPartnerDetailsWidgetProps
> = ({ data, actions }) => {
  const view = useMemo(() => {
    const dynamicView: IDynamicDiplayView = {
      name: "Business Partner Information",
      sections: [
        {
          name: "businessPartnerDetails",
          title: "Business Partner Details",
          showActionButtons: true,
          properties: {
            relatedCompany: {
              multiline: false,
              value: data?.relatedCompanyTitle,
              name: "relatedCompany",
              title: "Related Company",
            },
            type: {
              multiline: false,
              value: capitalizeFirstLetter(data?.typeTitle),
              name: "type",
              title: "Type",
            },
            category: {
              multiline: false,
              value: data?.categoryTitle,
              name: "category",
              title: "Category",
            },
            isCompany: {
              multiline: false,
              value: data?.isCompany ? "Yes" : "No",
              name: "isCompany",
              title: "Is Company",
            },
            isLocal: {
              multiline: false,
              value: data?.isLocal ? "Yes" : "No",
              name: "isLocal",
              title: "Is Local",
            },
            isRegistered: {
              multiline: false,
              value: data?.isRegistered ? "Yes" : "No",
              name: "isRegistered",
              title: "Is Registered",
            },
            registerNumber: {
              multiline: false,
              value: data?.registerNumber,
              name: "registerNumber",
              title: "Register Number",
            },
            taxAccount: {
              multiline: false,
              value: data?.taxAccount,
              name: "taxAccount",
              title: "Tax Account",
            },
            account: {
              multiline: false,
              value: data?.account,
              name: "account",
              title: "Account",
            },
            allowAccessToAllInsured: {
              multiline: false,
              value: data?.allowAccessToAllInsured ? "Yes" : "No",
              name: "allowAccessToAllInsured",
              title: "Allow Access To All Insured",
            },
          },
        },
      ],
    };

    return dynamicView;
  }, [data]);

  return <DynamicWidget hasFourFields={true} view={view} actions={actions} />;
};

export default BusinessPartnerDetailsWidget;
