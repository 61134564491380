import { FormInputTypes } from "../../DynamicForm";
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from "../../components/enhanced-table";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import { IAbstractRecord } from "../../models";
import { isEmpty } from "../../utils/validationUtils";

export const getHeaders = (navigate : (v: string) => void) : Record<string, EnhancedTableHeader> => ({
  line_Name: {
    name: "line_Name",
    title: "Line",
    type: EnhancedTableHeaderType.Clickable,
    callback: (payload) => {
      navigate("/plan/lines/" + payload.columns.lineId)
    },
  },
  line_ExternalCode: {
    name: "line_ExternalCode",
    title: "Line Code",
    type: EnhancedTableHeaderType.Clickable,
    callback: (payload) => {
      navigate("/plan/lines/" + payload.columns.lineId)
    },
  },
  configuration_Taxation_FixedStamp: {
    name: "configuration_Taxation_FixedStamp",
    title: "Fixed Stamp",
    type: EnhancedTableHeaderType.Text,
  },
  configuration_Taxation_ProportionalStamp: {
    name: "configuration_Taxation_ProportionalStamp",
    title: "Proportional Stamp",
    type: EnhancedTableHeaderType.Rate,
  },
  configuration_Taxation_MunicipalityTax: {
    name: "configuration_Taxation_MunicipalityTax",
    title: "Municipality Tax",
    type: EnhancedTableHeaderType.Rate,
  },
  configuration_Taxation_TaxOnCommission: {
    name: "configuration_Taxation_TaxOnCommission",
    title: "Tax On Commission",
    type: EnhancedTableHeaderType.Rate,
  },
  configuration_Taxation_ReinsuranceTax: {
    name: "configuration_Taxation_ReinsuranceTax",
    title: "Reinsurance Tax",
    type: EnhancedTableHeaderType.Rate,
  },
  configuration_Taxation_EffectiveFrom: {
    name: "configuration_Taxation_EffectiveFrom",
    title: "Effective From",
    type: EnhancedTableHeaderType.Date,
  },
  configuration_Taxation_EffectiveTo: {
    name: "configuration_Taxation_EffectiveTo",
    title: "Effective To",
    type: EnhancedTableHeaderType.Date,
  },
  configuration_Taxation_createdOn: {
    name: "configuration_Taxation_createdOn",
    title: "Created On",
    type: EnhancedTableHeaderType.Date,
  },
  configuration_Taxation_modifiedOn: {
    name: "configuration_Taxation_modifiedOn",
    title: "Modified On",
    type: EnhancedTableHeaderType.Date,
  },
});

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: "Filters",
      inputs: {
        line: {
          name: "line",
          title: "Line",
          type: FormInputTypes.chips,
          value: filterValues?.line || [],
          placeholder: "Line",
          triggerUpdate: true,
          selectOptions: { ...lovs.line },
        },
        date: {
          name: "date",
          title: "Date",
          type: FormInputTypes.date,
          value: filterValues.date || null,
          placeholder: "Date",
          triggerUpdate: true,
          allowDeleteValue: true,
          canClearDate: true,
        },
        createdOn: {
          name: "createdOn",
          title: "Created On",
          type: FormInputTypes.daterange,
          value: filterValues?.createdOn || [],
          placeholder: "Created On",
          triggerUpdate: true,
        },
      },
    },
  ];

  return newFilterSections;
}

export const actions = (setDrawerOpen: (v: boolean) => void, setEditDrawerId: (v: string) => void) : IEnhancedTableMenuItem[] => ([
  {
    title: "+ New",
    onClick: () => {
      setDrawerOpen(true);
    },
    isEntity: false,
    isBulk: false,
    iconUrl: "",
  },
  {
    title: "Modify",
    onClick: (selected) => {
      if(selected.length === 1 && selected[0]){
        setEditDrawerId(selected[0]);
        setDrawerOpen(true);
      }
    },
    isEntity: false,
    isBulk: false,
    iconUrl: "",
    isSecondary: true,
    conditionalDisable: (selected, selectedItems) => {
      return !(
        selectedItems.length === 1 &&
        selectedItems[0] &&
        (new Date(selectedItems[0]?.configuration_Taxation_EffectiveFrom) >
          new Date()) &&
        isEmpty(selectedItems[0]?.configuration_Taxation_EffectiveTo));
    }
  },
]);
