import {Checkbox} from '@mui/material';
import React, {useCallback} from 'react';
import { IEnhancedCommonProps } from '..';
// import clsx from 'clsx';

interface IEnhancedCheckboxProps extends IEnhancedCommonProps {
  name: string;
  checked: boolean;
  title?: string | JSX.Element;
  error?: string;
  disabled?: boolean;
  indeterminate?: boolean;
  checkboxOnly?: boolean;
  onChange: (name: string, value: boolean) => void;
  classes?: {root?: string; error?: string};
  style?: any;
}

const EnhancedCheckbox: React.FC<IEnhancedCheckboxProps> = ({
  name,
  title,
  checked,
  error,
  disabled,
  indeterminate,
  checkboxOnly,
  onChange,
  className,
  classes = {},
  style,
}) => {
  //const checkboxClasses = useStyles();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, inputChecked: boolean) => {
      onChange(name, inputChecked);
    },
    [name, onChange],
  );

  const renderCheckbox = () => (
    <Checkbox
      color="primary"
      // classes={{
      //   root: clsx(checkboxClasses.checkRoot, classes.root),
      // }}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      indeterminate={indeterminate}
    />
  );

  return checkboxOnly ? (
    renderCheckbox()
  ) : (
    <label
      htmlFor={name}
      // className={clsx(checkboxClasses.labelWrapper, className)}
      style={style}
    >
      <div>
        {renderCheckbox()}
        <span>{title}</span>
      </div>
      <span id={`errorMsg-${name}`}>{error}</span>
    </label>
  );
};

export default EnhancedCheckbox;
