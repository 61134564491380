// import { IListingData } from '../../models/listing';
import { IActivityEntityBase } from "../../activities";
import {
    lookupListAsRecordObject,
  } from "../../utils/graph-utils";

  export function mapToListingData(data: any) {
    const list: Record<string, Record<string, IAccountsGenericTableData>> = {};
  
    data.Accounting.queries.ChartOfAccountsList.forEach(
      (obj: IAccountsGenericTableData) => {
        if(obj.directParent_AccountGroup) {
        const parentName = obj.directParent_AccountGroup + ' ' + obj.directParent_AccountGroupName;
  
        if (!list[parentName]) {
          list[parentName] = {};
        }
  
        list[parentName][obj.accounting_ChartOfAccounts_Id] = {
          accounting_ChartOfAccounts_AccountDescription: obj.accounting_ChartOfAccounts_AccountDescription,
          accounting_ChartOfAccounts_AccountName: obj.accounting_ChartOfAccounts_AccountName,
          accounting_ChartOfAccounts_Id: obj.accounting_ChartOfAccounts_Id,
          accounting_ChartOfAccounts_createdOn: obj.accounting_ChartOfAccounts_createdOn,
          parent_AccountName: obj.parent_AccountName,
          accounting_ChartOfAccounts_AccountCategory: obj.directParent_AccountGroup + ' ' + obj.directParent_AccountGroupName,
          accounting_ChartOfAccounts_AccountType: obj.directParent_AccountGroup + ' ' + obj.directParent_AccountGroupName,
          accounting_ChartOfAccounts_AccountCurrency: obj.accounting_ChartOfAccounts_AccountCurrency.Title,
          accounting_ChartOfAccounts_AccountID: obj.accounting_ChartOfAccounts_AccountID,
          accounting_ChartOfAccounts_AccountCompany: obj.accounting_ChartOfAccounts_AccountCompany.Title,
          directParent_AccountGroup: obj.directParent_AccountGroup,
          directParent_AccountGroupName: obj.directParent_AccountGroupName
        };
      }
    }
    );
  
    return list;
  }

export function mapToTables (data: Record<string, IAccountsGenericTableData>) : Record<string, IAccountsTable[]> {
  const list: Record<string, IAccountsTable[]>= {};
  const types: string[] = [];

  Object.entries(data).filter(([key, value]) => {
    types.push(value.accounting_ChartOfAccounts_AccountType);
  });

  // types.forEach(
  //   (obj: string, i: number) => {
  //     const elements = Object.values(data).find(obj => obj.accounting_ChartOfAccounts_AccountType === types[i]);
  //     if(!list[types[i]])
  //       list[types[i]] = [];
  //     list[types[i]].push({title: types[i], data: elements});
  //   });

  return list;
}

export function toLookups(data: any) {
    let lovs: Record<string, Record<string, string>> = {
      type: {},
      status: {},
      company: {},
    };
    lovs.company = lookupListAsRecordObject(
      data?.SalesforceManagement?.lookups?.relatedCompanies
    );
  
    return lovs;
  }

  export function toLookupsData(data: any) {
    let lovs: Record<string, Record<string, string>> = {
      company: {},
      accountCategory: {},
      accountSubCategory: {}
    };
    
    // lovs.accountCategory = lookupListAsRecordObject(
    //     data?.Accounting?.lookups?.accountCategory
    //   );

    data?.Accounting?.queries?.GetAccountGroupLayer2ByCompany?.forEach((element: { [key: string]: string }) => {
      if (element.accounting_ChartOfAccountsGroup_Id) {
        lovs.accountCategory[element.accounting_ChartOfAccountsGroup_Id] = element.accounting_ChartOfAccountsGroup_AccountGroup + ' ' + element.accounting_ChartOfAccountsGroup_AccountGroupName;
      }
    });

    data?.Accounting?.queries?.GetAccountGroupLayer3ByCompany?.forEach((element: { [key: string]: string }) => {
      if (element.accounting_ChartOfAccountsGroup_Id) {
        lovs.accountSubCategory[element.accounting_ChartOfAccountsGroup_Id] = element.accounting_ChartOfAccountsGroup_AccountGroup + ' ' + element.accounting_ChartOfAccountsGroup_AccountGroupName;
      }
    });

      lovs.company = lookupListAsRecordObject(
        data?.SalesforceManagement?.lookups?.relatedCompanies
      );
  
    return lovs;
  }

  function extractIconUrl(html: string): string | undefined {
    const match = html.match(/background-image: url\('(.+?)'\)/);
    return match ? match[1] : undefined;
}

 export function convertAuditTrailsToActivities(data: any): IActivityEntityBase[] {
    return data.Accounting.queries.GetAccountAuditTrails.map((auditTrail: any) => ({
        id: auditTrail.accounting_AuditTrail_Id,
        createdAt: new Date(auditTrail.accounting_AuditTrail_createdOn),
        htmlBody: auditTrail.accounting_AuditTrail_Message,
        iconUrl: extractIconUrl(auditTrail.accounting_AuditTrail_Message)
    }));
}
