import { FormInputTypes } from "../../DynamicForm";
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from "../../components/enhanced-table";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import { IAbstractRecord } from "../../models";

export const headers: Record<string, EnhancedTableHeader> = {
  planConfigManagement_Plan_Name: {
    name: "planConfigManagement_Plan_Name",
    title: "Plan Name",
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  planConfigManagement_Plan_SublineID: {
    name: "planConfigManagement_Plan_SublineID",
    title: "Subline Name",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_LineID: {
    name: "planConfigManagement_Plan_LineID",
    title: "Line",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_ExternalCode: {
    name: "planConfigManagement_Plan_ExternalCode",
    title: "External Code",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_NameArabic: {
    name: "planConfigManagement_Plan_NameArabic",
    title: "Arabic Name",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_EffectiveFrom: {
    name: "planConfigManagement_Plan_EffectiveFrom",
    title: "Effective From",
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Plan_EffectiveTo: {
    name: "planConfigManagement_Plan_EffectiveTo",
    title: "Effective To",
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Plan_PlanStatus: {
    name: "planConfigManagement_Plan_PlanStatus",
    title: "Status",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_createdOn: {
    name: "planConfigManagement_Plan_createdOn",
    title: "Created On",
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Plan_modifiedOn: {
    name: "planConfigManagement_Plan_modifiedOn",
    title: "Modified On",
    type: EnhancedTableHeaderType.Date,
  },
};

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: "Filters",
      inputs: {
        line: {
          name: "line",
          title: "Line",
          type: FormInputTypes.chips,
          value: filterValues?.line || [],
          placeholder: "Line",
          triggerUpdate: true,
          selectOptions: { ...lovs.line },
        },
        subline: {
          name: "subline",
          title: "Subline",
          type: FormInputTypes.chips,
          value: filterValues?.subline || [],
          placeholder: "Subline",
          triggerUpdate: true,
          selectOptions: { ...lovs.subline },
        },
        status: {
          name: "status",
          title: "Status",
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: "Status",
          triggerUpdate: true,
          selectOptions: { ...lovs.status },
        },
        createdOn: {
          name: "createdOn",
          title: "Created On",
          type: FormInputTypes.daterange,
          value: filterValues?.createdOn || [],
          placeholder: "Created On",
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: "+ New",
    onClick: () => {},
    isEntity: false,
    isBulk: false,
    iconUrl: "",
  },
];
