import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import {makeStyles} from 'tss-react/mui';

export interface GroupTableToolbarProps {
  children?: JSX.Element;
  title: string;
  index?: number;
}

const useStyles = makeStyles<{expanded: boolean, index?: number}>()((theme, {expanded, index}) => ({
  mainContainer: {
    backgroundColor: '#D9E0E5',
    padding: expanded ? '0' : '0',
    borderRadius: index === 0 ? "5px 5px 0px 0px": "0",
    margin: "0 !important"
  },
  filtersTitle: {
    fontFamily: 'SourceSansPro-Bold',
    fontSize: '18px',
    lineHeight: '25px',
    color: '#344054',
    margin: '0',
  },
  accordionSummary: {
    minHeight: 'unset!important',
    margin: '0',
    padding: "12px 17px 12px 36px",
    '& .MuiAccordionSummary-content': {
      padding: '0',
      margin: expanded ? '0 !important' : '0',
    },

  },
  accordionDetails: {
    padding: '0',
  },
}));

const GroupTableToolbar: React.FC<GroupTableToolbarProps> = ({
  children,
  title, 
  index
}) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const {classes} = useStyles({expanded: expanded, index});

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return children ? (
    <Accordion
      elevation={0}
      className={classes.mainContainer}
      onChange={() => handleChange()}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordionSummary}
      >
        <div>
          <span className={classes.filtersTitle}>{title}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
      {children}
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};

export default GroupTableToolbar;
