import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import { createVignetteRange, getVignetteStatusesEnums } from "./queries";
import { cloneDeep } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { isEmpty } from "../../utils/validationUtils";
import { getError } from "../../utils/graph-utils";
import { VignetteLookupsToList } from "./utils";
import { useParams } from "react-router-dom";

const VignetteRangeDrawer: React.FC<IVignetteRangeDrawerProps> = ({
  open,
  onSuccess,
  onClose,
}) => {
  const [vignetteRangeAction] = useMutation(createVignetteRange());
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);
  const params = useParams();

  const [vignetteStatusesEnumsQuery] = useLazyQuery(
    getVignetteStatusesEnums(),
    {
      fetchPolicy: "no-cache",
    }
  );

  const loadLovList = async () => {
    const result = await vignetteStatusesEnumsQuery();
    const vignetteEnums = VignetteLookupsToList(result.data);

    return vignetteEnums;
  };

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      const lovData = await loadLovList();

      (updatedInputs.rangeStatus as IFormSelectDynamicProps).selectOptions =
        lovData["Insurance_VignetteRangeStatuses"];

      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variables = {
        entityId: params.id,
        rangeCode: data.rangeCode,
        rangeFrom: parseInt(data.rangeFrom as unknown as string),
        rangeStatus: data.rangeStatus,
        rangeTo: parseInt(data.rangeTo as unknown as string),
        rangeYear: parseInt(data.rangeYear as unknown as string),
      };

      const res = await vignetteRangeAction({
        variables: variables,
        errorPolicy: "all",
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {"Vignette Range successfully created"}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState("success");
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <GenericDrawer
      title={"New Vignette Range"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      }
    </GenericDrawer>
  );
};

export default VignetteRangeDrawer;
