import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  clauseId: "",
  lineId: "",
  lineName: "",
  clauseName: "",
  clauseExternalCode: "",
  clauseStatus: "",
  clauseDescription: "",
};

export const inputs: Record<string, DynamicFormInputType> = {
  lineName: {
    name: "lineName",
    title: "Line",
    type: FormInputTypes.chips,
    placeholder: "Line",
    value: !isEmpty(initialValues.lineName) ? initialValues.lineName : "",
    disabled: true,
    multiple: false,
    popUpStyling: true,
    canClearSingleValueSelection: false,
    required: true,
  },
  clauseStatus: {
    name: "clauseStatus",
    title: "Status",
    placeholder: "Status",
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.clauseStatus)
      ? initialValues.clauseStatus
      : "",
    required: true,
    multiple: false,
    popUpStyling: true,
  },
  clauseExternalCode: {
    name: "clauseExternalCode",
    title: "External Code",
    type: FormInputTypes.text,
    placeholder: "External Code",
    value: !isEmpty(initialValues.clauseExternalCode)
      ? initialValues.clauseExternalCode
      : "",
    required: true,
    popUpStyling: true,
  },
  clauseName: {
    name: "clauseName",
    title: "Name",
    type: FormInputTypes.text,
    placeholder: "Name",
    value: !isEmpty(initialValues.clauseName) ? initialValues.clauseName : "",
    required: true,
    popUpStyling: true,
  },
  clauseDescription: {
    name: "clauseDescription",
    title: "Description",
    type: FormInputTypes.text,
    placeholder: "Description",
    value: !isEmpty(initialValues.clauseDescription)
      ? initialValues.clauseDescription
      : "",
    required: true,
    isEditor: true,
    addButton: true,
    minCharacters: 0,
    maxCharacters: 2500,
    multiline: true,
    popUpStyling: false,
  },
};
