import { gql } from "@apollo/client";

export function getExpatPolicyDetails() {
  return gql`
  query getExpatPolicyDetails($id: String!) {
    Insurance {
      entities {
        policy(id: $id) {
          views {
            Insurance_all {
              properties {
                ExpatDetailsID {
                  views {
                    Insurance_all {
                      properties {
                        PolicyID {
                          id
                          views {
                            Insurance_all {
                              properties {
                                FullPolicyNumber
                                IsRenewal
                                RenewalNumber
                              }
                            }
                          }
                        }
                        InsuredName
                        InsuredFirstName
                        NSSFFullCode
                        InsuredMiddleName
                        InsuredLastName
                        InsuredDateOfBirth
                        InsuredPlan {
                          Code
                          Title
                        }
                        InsuredNationality {
                          Id
                          Code
                          Title
                        }
                        InsuredGender
                        InsuredPassportNumber
                        InsuredOccupation {
                          Id
                          Code
                          Title
                          TitleAr
                        }
                        InsuredAge
                      }
                    }
                  }
                }
                PolicyNumber
                FullPolicyNumber
                PolicyEffectiveDate
                CancelationEffectiveDate
                PolicyIssueDate
                PolicyExpiryDate
                createdOn
                createdBy
                modifiedOn
                modifiedBy
                PendingDocumentRegeneration
                ExpatDetailsID {
                  id
                }
                PolicyHolderID {
                  views {
                    Insurance_all {
                      properties {
                        Title {
                          Id
                          Title
                        }
                        FirstName
                        MiddleName
                        LastName
                        FullName
                        Email
                        MobileNumber
                        PhoneType
                        Address {
                          Country {
                            Id
                            Code
                            Title
                          }
                          City
                          Street
                          Building
                        }
                      }
                    }
                  }
                }
                PolicyStatus {
                  Code
                  Title
                }
                PolicyStatusReason
                LineOfBusiness {
                  Code
                  Title
                }
                AssignedAgencyID {
                  views {
                    Insurance_all {
                      properties {
                        AgencyName
                      }
                    }
                  }
                }
                AssignedAgentID {
                  views {
                    Insurance_all {
                      properties {
                        CanViewCommission
                        FirstName
                        LastName
                      }
                    }
                  }
                }
                QuoteID {
                  id
                  views {
                    Insurance_all {
                      properties {
                        createdOn
                        createdBy
                        modifiedOn
                        modifiedBy
                        Name
                        Status
                        StatusReason
                        TotalBasePremium
                        PolicyCost
                        TotalAnnualPremium
                      }
                    }
                  }
                }
                TotalAnnualPremium
                PolicyDocument {
                  id
                  location
                  path
                  fileName
                  length
                  contentType
                }
              }
            }
          }
          queries {
            insurance {
              relatedUsers {
                createdBy_firstName
                createdBy_lastName
                modifiedBy_firstName
                modifiedBy_lastName
              }
            policyExpatQuotes {
              insuranceQuote_Id
              insuranceQuoteExpatDetails_InsuredNationality {
                Code
                Title
              }
              insuranceQuoteExpatDetails_InsuredAge
              insuranceQuoteExpatDetails_InsuredGender
              insuranceQuote_modifiedOn
              insuranceQuote_Name
              insuranceQuote_OrderAdded
              insuranceQuote_Status
              insuranceQuote_StatusReason
              insuranceQuote_TotalAnnualPremium
            }
            }
            
          }
          actions {
            able_To_UploadAdditionalDocument
          }
        }
      }
      queries {
        regeneratedDocuments: getNewPolicyDocuments(
          policyId: $id
          uploadedManually: false
        ) {
          insurance_Policy_Id
          docs_Id
          docs_DocumentCode
          docs_Version
          docs_createdOn
          docs_PolicyDocument {
            id
            location
            path
            fileName
            length
            contentType
          }
        }
        additionalDocuments: getNewPolicyDocuments(
          policyId: $id
          uploadedManually: true
        ) {
          insurance_Policy_Id
          docs_Id
          docs_DocumentCode
          docs_Version
          docs_createdOn
          docs_PolicyDocument {
            id
            location
            path
            fileName
            length
            contentType
          }
        }
      }
    }
  }
  `;
}

export function regeneratePolicyDocument() {
  return gql(/* GraphQL */ `
    mutation regeneratePolicyDocument($id: String!) {
      insurance {
        entities {
          policy {
            insurance {
              regeneratePolicyDocuments(entityId: $id) {
                Items {
                  EntityTypeId
                  EntityId
                  EntityViewId
                  PropertyId
                  File {
                    id
                    location
                    path
                    fileName
                    length
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
}

export function generateCommissionDocument() {
  return gql`
    mutation generateCommissionDocument($id: String!) {
      insurance {
        entities {
          policy {
            insurance {
              generateCommissionDocument(entityId: $id) {
                Items {
                  EntityTypeId
                  EntityId
                  EntityViewId
                  PropertyId
                  File {
                    id
                    location
                    path
                    fileName
                    length
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}
