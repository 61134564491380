import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const result: Record<string, Record<string, string>> = {};

  result["currency"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies,
    true
  );
  result["usageTypes"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.usageTypes
  );
  result["bodyTypes"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.bodyTypes
  );
  result["engineTypes"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.engineTypes
  );
  result["plateCodes"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.plateCodes
  );
  result["brands"] = lookupListAsRecordObject(data?.Insurance?.lookups?.brands);
  result["colors"] = lookupListAsRecordObject(data?.Core?.lookups?.colors);

  return result;
}

export const getProposalStatus = (steps: string[], status: string) => {
  switch (status?.toUpperCase()) {
    case "CLOSED_WON":
      return steps[2];
    case "CLOSED_LOST":
      return steps[2];
    case "IN_PROGRESS":
      return steps[1];
    default:
      return steps[0];
  }
};

export function extractLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    plateCodes: {},
    usageTypes: {},
    bodyTypes: {},
    engineTypes: {},
    brands: {},
    models: {},
    policyCurrencies: {},
    tpaTypes: {},
    tpaApplicableOnOptions: {},
  };
  lovs["plateCodes"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.plateCodes
  );
  lovs["usageTypes"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.usageTypes
  );
  lovs["bodyTypes"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.bodyTypes
  );
  lovs["engineTypes"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.engineTypes
  );
  lovs["brands"] = lookupListAsRecordObject(data?.Insurance?.lookups?.brands);

  lovs["policyCurrencies"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  lovs["tpaTypes"] = enumListAsRecordObject(
    data?.Core_TPATypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  lovs["tpaApplicableOnOptions"] = enumListAsRecordObject(
    data?.Core_TPAApplicableOnOptions?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  return lovs;
}

export function extractActiveAssignedBPPlan(data: any) {
  const personLookups: Record<string, Record<string, string>> = {};
  let payerPersons: Record<string, string> = {};

  data.SalesforceManagement.queries.AllActivePersons.forEach(
    (element: {
      salesforceManagement_Person_Id: string;
      salesforceManagement_Person_FullName: string;
      salesforceManagement_Person_PersonCode: string;
    }) => {
      payerPersons[
        element.salesforceManagement_Person_Id
      ] = `${element.salesforceManagement_Person_PersonCode} - ${element.salesforceManagement_Person_FullName}`;
    }
  );

  personLookups["payerPersons"] = payerPersons;

  return personLookups;
}

export function extractPersons(data: any) {
  const personLookups: Record<string, Record<string, string>> = {};
  let payerPersons: Record<string, string> = {};
  let ownerPersons: Record<string, string> = {};
  let insuredPersons: Record<string, string> = {};
  let beneficiaryPersons: Record<string, string> = {};

  data.Production.queries.GetAssignedPersons.forEach(
    (element: {
      salesforceManagement_AssignedPerson_PersonID: {
        id: string;
      };
      salesforceManagement_AssignedPerson_FullName: string;
      salesforceManagement_AssignedPerson_PersonCode: string;
    }) => {
      payerPersons[
        element.salesforceManagement_AssignedPerson_PersonID?.id
      ] = `${element.salesforceManagement_AssignedPerson_PersonCode} - ${element.salesforceManagement_AssignedPerson_FullName}`;
    }
  );
  data.Production.queries.GetAssignedPersons.forEach(
    (element: {
      salesforceManagement_AssignedPerson_PersonID: {
        id: string;
      };
      salesforceManagement_AssignedPerson_FullName: string;
      salesforceManagement_AssignedPerson_PersonCode: string;
    }) => {
      ownerPersons[
        element.salesforceManagement_AssignedPerson_PersonID?.id
      ] = `${element.salesforceManagement_AssignedPerson_PersonCode} - ${element.salesforceManagement_AssignedPerson_FullName}`;
    }
  );
  data.Production.queries.GetAssignedPersons.forEach(
    (element: {
      salesforceManagement_AssignedPerson_PersonID: {
        id: string;
      };
      salesforceManagement_AssignedPerson_FullName: string;
      salesforceManagement_AssignedPerson_PersonCode: string;
    }) => {
      insuredPersons[
        element.salesforceManagement_AssignedPerson_PersonID?.id
      ] = `${element.salesforceManagement_AssignedPerson_PersonCode} - ${element.salesforceManagement_AssignedPerson_FullName}`;
    }
  );
  data.Production.queries.GetAssignedPersons.forEach(
    (element: {
      salesforceManagement_AssignedPerson_PersonID: {
        id: string;
      };
      salesforceManagement_AssignedPerson_FullName: string;
      salesforceManagement_AssignedPerson_PersonCode: string;
    }) => {
      beneficiaryPersons[
        element.salesforceManagement_AssignedPerson_PersonID?.id
      ] = `${element.salesforceManagement_AssignedPerson_PersonCode} - ${element.salesforceManagement_AssignedPerson_FullName}`;
    }
  );

  personLookups["payerPersons"] = payerPersons;
  personLookups["ownerPersons"] = ownerPersons;
  personLookups["insuredPersons"] = insuredPersons;
  personLookups["beneficiaryPersons"] = beneficiaryPersons;

  return personLookups;
}

export function extractPersonsAddresses(data: any) {
  const personLookups: Record<
    string,
    Record<string, { address: string; isPrimary: boolean }>
  > = {};
  let payerPersonAddress: Record<
    string,
    { address: string; isPrimary: boolean }
  > = {};
  let ownerPersonAddress: Record<
    string,
    { address: string; isPrimary: boolean }
  > = {};
  let insuredPersonAddress: Record<
    string,
    { address: string; isPrimary: boolean }
  > = {};
  let beneficiaryPersonAddress: Record<
    string,
    { address: string; isPrimary: boolean }
  > = {};

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ""
      );
      const address = parts.join(", ");

      payerPersonAddress[element.salesforceManagement_PersonAddress_Id] = {
        address,
        isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
      };
    }
  );

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ""
      );
      const address = parts.join(", ");

      ownerPersonAddress[element.salesforceManagement_PersonAddress_Id] = {
        address,
        isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
      };
    }
  );

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ""
      );
      const address = parts.join(", ");

      insuredPersonAddress[element.salesforceManagement_PersonAddress_Id] = {
        address,
        isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
      };
    }
  );

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ""
      );
      const address = parts.join(", ");

      beneficiaryPersonAddress[element.salesforceManagement_PersonAddress_Id] =
        {
          address,
          isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
        };
    }
  );

  personLookups["payerPersonAddress"] = payerPersonAddress;
  personLookups["ownerPersonAddress"] = ownerPersonAddress;
  personLookups["insuredPersonAddress"] = insuredPersonAddress;
  personLookups["beneficiaryPersonAddress"] = beneficiaryPersonAddress;

  return personLookups;
}

export function extractPersonsFixed(data: any) {
  let result: Record<string, string> = {};

  data.Production.queries.GetAssignedPersons.forEach(
    (element: {
      salesforceManagement_AssignedPerson_PersonID: {
        id: string;
      };
      salesforceManagement_AssignedPerson_FullName: string;
      salesforceManagement_AssignedPerson_PersonCode: string;
    }) => {
      result[
        element.salesforceManagement_AssignedPerson_PersonID?.id
      ] = `${element.salesforceManagement_AssignedPerson_PersonCode} - ${element.salesforceManagement_AssignedPerson_FullName}`;
    }
  );

  return result;
}

export function extractPersonsAddressesFixed(data: any) {
  let result: Record<string, { address: string; isPrimary: boolean }> = {};

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ""
      );
      const address = parts.join(", ");

      result[element.salesforceManagement_PersonAddress_Id] = {
        address,
        isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
      };
    }
  );

  return result;
}

export function filteredUsageTypeList(
  plateCode = "",
  usageType: Record<string, string>
) {
  let usageTypeList = ["Public"];

  if (usageType) {
    if (plateCode != "M" && plateCode != "P") {
      usageTypeList = [...usageTypeList, "Private"];
    }

    // if (plateCode == "M") {
    //   usageTypeList = ["Commercial", "Private"];
    // }

    if (plateCode != "P") {
      usageTypeList = [...usageTypeList, "Commercial"];
    }

    return Object.fromEntries(
      Object.entries(usageType).filter(([key]) => usageTypeList.includes(key))
    );
  }
  return {};
}

export function filteredBodyTypeList(
  usageType = "",
  bodyType: Record<string, string>
) {
  let bodyTypeList: string[] = [];

  if (bodyType && usageType) {
    switch (usageType) {
      case "Private":
        bodyTypeList = [
          "4x4",
          "BUS",
          "MINI_BUS",
          "MINIVAN",
          "MOTORCYCLE",
          "SCHOOL_BUS",
          "SEDAN",
          "STATION",
          "SUV",
          "VAN",
        ];
        break;

      case "Public":
        bodyTypeList = ["TAXI"];
        break;

      case "Commercial":
        bodyTypeList = [
          "BUS",
          "CRANE",
          "F_TANKER",
          "MICROBUS",
          "MINI_BUS",
          "MINIVAN",
          "MOTORCYCLE",
          "PICKUP",
          "SCHOOL_BUS",
          "TOWING",
          "TRUCK",
          "VAN",
        ];
        break;
    }

    return Object.fromEntries(
      Object.entries(bodyType).filter(([key]) => bodyTypeList.includes(key))
    );
  }

  return {};
}
