import React, { useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
	calculateMaxFrequencyQuery,
	calculateMaxPaymentsQuery,
	changePaymentSimulationQuery,
	createChangePaymentTermsQuery,
	getPolicyDetailsQuery,
} from "./queries";
import Loader from "../../components/Loader";
import { makeStyles } from "tss-react/mui";
import NewChipsInput from "../../components/enhanced-form/NewChipsInput";
import { enumListAsRecordObject, getError } from "../../utils/graph-utils";
import EnhancedInput from "../../components/enhanced-form/EnhancedInput";
import {
	IPaymentSimulationTableData,
	IPaymentTermsPopupFormState,
	IPaymentTermsPopupProps,
} from ".";
import {
	arrayToList,
	getPaymentTermsPopupFormState,
	mapToListingData,
	numToList,
} from "./utils";
import GenericPopUp from "../../components/custom/GenericPopUp";
import EnhancedDatePicker from "../../components/enhanced-form/EnhancedDatePicker";
import {
	AddMonths,
	isDateBefore,
	subtractMonths,
} from "../../utils/date-utils";
import EnhancedButton from "../../components/EnhancedButton";
import { formatDate, formatDateTime } from "../../utils/formatting-utils";
import {
	DEFAULT_ERROR_TEXT,
	MAIN_ONE_THEME,
	SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { cloneDeep, isEmpty, isEqual } from "lodash";
import { IAbstractRecord } from "../../models";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingData } from "../../models/listing";
import { paymentTermsHeaders } from "./content";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";

const useStyles = makeStyles()(() => ({
	dialogPaper: {
		height: "80%",
		width: "80%",
		maxWidth: "1539px",
	},
	buttonContainer: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		margin: "20px 0",
	},
	fieldRow: {
		display: "grid",
		gridTemplateColumns: `repeat(3, 30%)`,
		gap: "3%",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const PaymentTermsPopup: React.FunctionComponent<IPaymentTermsPopupProps> = ({
	policyId,
	open,
	onClose,
}) => {
	const { classes } = useStyles();
	const [booted, setBooted] = useState<boolean>(false);
	const [pageState, setPageState] = useState<IPaymentTermsPopupFormState>(
		getPaymentTermsPopupFormState()
	);
	const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] =
		useState<boolean>(true);

	const [lovs, setLovs] = useState<{
		paymentTypes: Record<string, string>;
		frequencies: Record<string, string>;
		numOfPayments: Record<string, number>;
	}>({
		paymentTypes: {},
		frequencies: {},
		numOfPayments: {},
	});

	const [tableData, setTableData] = useState<IListingData<any>>({
		pagedItems: [],
		pageNumber: 0,
		pageSize: 10,
		totalCount: 0,
	});

	const maxEffectiveFrom = useMemo(() => {
		return subtractMonths(new Date(pageState.values.policyExpiryDate), 1);
	}, [pageState.values.policyExpiryDate]);

	const amountToBeAllocated = useMemo(() => {
		const totalAmount = pageState.values.bills.reduce(
			(total: number, bill: IAbstractRecord) => {
				return total + bill.accounting_Bills_OutstandingBalance;
			},
			0
		);

		return totalAmount;
	}, [pageState.values.bills]);

	const [getPolicyDetails] = useLazyQuery(getPolicyDetailsQuery());
	const [calculateMaxFrequency] = useMutation(calculateMaxFrequencyQuery());
	const [calculateMaxPayments] = useMutation(calculateMaxPaymentsQuery());
	const [changePaymentSimulation] = useMutation(changePaymentSimulationQuery());
	const [createChangePaymentTerms] = useMutation(
		createChangePaymentTermsQuery()
	);

	const loadData = async () => {
		try {
			const res = await getPolicyDetails({
				variables: {
					policyId: policyId,
				},
			});

			const paymentTypes = enumListAsRecordObject(
				res?.data?.Production_PaymentDivisionTypeList?.enumValues
			);
			setLovs((prev) => ({
				...prev,
				paymentTypes,
			}));

			const policyDetails =
				res?.data?.Production?.entities?.policy?.views?.Production_all
					?.properties;

			const policyExpiryDate =
				policyDetails?.PolicyExpiryDate ||
				AddMonths(new Date(policyDetails?.PolicyIssueDate), 6);

			const effectiveFrom = isDateBefore(
				new Date(),
				policyDetails.PolicyIssueDate
			)
				? formatDate(policyDetails.PolicyIssueDate, SEND_TO_BACKEND_DATE_FORMAT)
				: formatDate(new Date(), SEND_TO_BACKEND_DATE_FORMAT);

			setPageState((prev) => ({
				...prev,
				values: {
					...prev.values,
					policyNumber: policyDetails?.PolicyNumber,
					policyIssuanceDate: policyDetails?.PolicyIssueDate,
					policyExpiryDate: policyExpiryDate,
					paymentType: "EQUIVALENT",
					effectiveFrom: effectiveFrom,
					bills: res.data.Accounting.queries.GetPolicyReceivableBills,
				},
			}));

			loadFrequencies(
				policyDetails?.PolicyIssueDate,
				policyExpiryDate,
				new Date()
			);

			setBooted(true);
		} catch (error) {
			toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
		}
	};

	const loadFrequencies = async (
		policyIssuanceDate: string,
		policyExpiryDate: string,
		effectiveFromDate: Date
	) => {
		try {
			const res = await calculateMaxFrequency({
				variables: {
					policyIssuanceDate: formatDate(
						policyIssuanceDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					policyExpiryDate: formatDate(
						policyExpiryDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					effectiveFromDate: formatDate(
						effectiveFromDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
				},
			});

			const maxFrequencies = arrayToList(
				res?.data?.accounting?.actions?.calculateMaxFrequency?.Values
			);

			setLovs((prev) => ({
				...prev,
				frequencies: maxFrequencies,
			}));
		} catch (error) {
			toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
		}
	};

	const loadPayments = async (selectedFrequency: string) => {
		try {
			const res = await calculateMaxPayments({
				variables: {
					policyIssuanceDate: formatDate(
						pageState.values.policyIssuanceDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					policyExpiryDate: formatDate(
						pageState.values.policyExpiryDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					effectiveFromDate: formatDate(
						pageState.values.effectiveFrom,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					selectedFrequency: selectedFrequency || pageState.values.frequency,
				},
			});

			const maxPayments: number =
				res?.data?.accounting?.actions?.calculateMaxPayments?.Value;

			setLovs((prev) => ({
				...prev,
				numOfPayments: numToList(maxPayments),
			}));
		} catch (error) {
			toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
		}
	};

	const initialize = async () => {
		await loadData();
	};

	useEffect(() => {
		initialize();
	}, []);

	const handleApply = async () => {
		try {
			setIsSubmitButtonDisabled(false);
			const res = await changePaymentSimulation({
				variables: {
					policyIssuanceDate: formatDate(
						pageState.values.policyIssuanceDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					policyExpiryDate: formatDate(
						pageState.values.policyExpiryDate,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					effectiveFromDate: formatDate(
						pageState.values.effectiveFrom,
						SEND_TO_BACKEND_DATE_FORMAT
					),
					selectedFrequency:
						pageState.values.paymentType === "CUSTOM"
							? "Month"
							: pageState.values.frequency,
					paymentType: pageState.values.paymentType,
					noOfPayments: Number(pageState.values.numOfPayments),
					noOfPaymentsList: Array.from(
						{ length: Number(pageState.values.numOfPayments) - 1 },
						(_, i) => i + 1
					),
					amountToBeAllocated: amountToBeAllocated,
					currentPolicyID: policyId,
				},
			});

			if (isEmpty(res.errors)) {
				const pagedItems = mapToListingData(
					res.data.accounting.actions.changePaymentSimulation.List
				);

				setTableData({
					pagedItems: pagedItems,
					pageNumber: 0,
					pageSize: pagedItems.length,
					totalCount: 0,
				});
			} else {
				toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
			}
		} catch (error) {
			if (
				isEqual(
					error.graphQLErrors[0].extensions.code,
					"NotAllowedNoOfPayments"
				)
			) {
				pageState.errors.numOfPayments =
					"Number of Payments is not in allowed range";
			} else {
				toast.error(
					<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
				);
			}
		}
	};

	const handleSubmit = async () => {
		try {
			setIsSubmitButtonDisabled(true);
			const res = await createChangePaymentTerms({
				variables: {
					policyIssuanceDate: pageState.values.policyIssuanceDate,
					policyExpiryDate: pageState.values.policyExpiryDate,
					effectiveFromDate: pageState.values.effectiveFrom,
					selectedFrequency:
						pageState.values.paymentType === "CUSTOM"
							? "Month"
							: pageState.values.frequency,
					paymentType: pageState.values.paymentType,
					noOfPayments: Number(pageState.values.numOfPayments),
					noOfPaymentsList: Array.from(
						{ length: Number(pageState.values.numOfPayments) - 1 },
						(_, i) => i + 1
					),
					amountToBeAllocated: amountToBeAllocated,
					currentPolicyID: policyId,
					newReceivableBillsList: tableData.pagedItems.map(
						(item: IPaymentSimulationTableData) => ({
							AmountDue: item.amountDue,
							BillNumber: item.billNumber,
							BillStatus: item.status,
							DueDate: item.dueDate,
							TotalCommission: item.totalCommission,
							TotalPremium: item.totalPremium,
							OutstandingBalance: item.outstandingAmount,
						})
					),
				},
			});

			if (res.errors) {
				toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
				return;
			} else {
				toast.success("Payment Terms Changed Successfully");
			}
		} catch (error) {
			console.log(error);
			toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
		}
	};

	const handleChange = async (inputName: string, value: any) => {
		setIsSubmitButtonDisabled(true);
		let newValues = cloneDeep(pageState.values);
		let newTouched = cloneDeep(pageState.touched);
		let newErrors = cloneDeep(pageState.errors);

		switch (inputName) {
			case "effectiveFrom":
				if (pageState.values.paymentType === "EQUIVALENT") {
					newValues.frequency = null;
					newValues.numOfPayments = null;

					newValues = {
						...newValues,
						[inputName]: value,
					};

					newTouched = {
						...newTouched,
						[inputName]: value,
					};

					setPageState((prev) => ({
						...prev,
						values: newValues,
						touched: newTouched,
					}));

					await loadFrequencies(
						pageState.values.policyIssuanceDate,
						pageState.values.policyExpiryDate,
						value
					);
					return;
				}
				break;

			case "frequency":
				newValues.numOfPayments = null;
				await loadPayments(value);
				break;

			case "paymentType":
				newValues.frequency = null;
				break;

			case "numOfPayments":
				newErrors.numOfPayments = null;
				break;

			default:
				break;
		}

		newValues = {
			...newValues,
			[inputName]: value,
		};

		newTouched = {
			...newTouched,
			[inputName]: value,
		};

		setPageState({
			values: newValues,
			touched: newTouched,
			errors: newErrors,
		});
	};

	const handleCellValueChange = (
		index: number,
		columnName: string,
		value: any
	) => {
		const newTableData = cloneDeep(tableData);
		newTableData.pagedItems[index][columnName] = value;

		if (columnName === "amountDue") {
			const sumOfAmountDue = newTableData.pagedItems.reduce(
				(sum: number, item: IPaymentSimulationTableData) =>
					sum + Number(item.amountDue),
				0
			);

			const error = sumOfAmountDue > amountToBeAllocated;
			const newState = cloneDeep(pageState);
			newState.errors.amountDue = error
				? "Amount Due Cannot be greater than Amount to Be Allocated"
				: null;

			if (error) {
				toast.error(
					<ToastErrorMessage>{newState.errors.amountDue}</ToastErrorMessage>
				);
			}

			setIsSubmitButtonDisabled(error);
			setPageState(newState);
		}

		setTableData((prev) => ({
			...prev,
			pagedItems: newTableData.pagedItems,
		}));
	};

	const renderContent = () => {
		return (
			<>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleApply();
					}}
				>
					<div className={classes.fieldRow}>
						<EnhancedInput
							title="Policy Issuance Date"
							name="PolicyIssuanceDate"
							type="text"
							value={formatDate(pageState.values.policyIssuanceDate)}
							onBlur={() => {}}
							onChange={() => {}}
							disabled
						/>
						<EnhancedInput
							title="Policy Expiry Date"
							name="PolicyExpiryDate"
							type="text"
							value={formatDate(pageState.values.policyExpiryDate)}
							onBlur={() => {}}
							onChange={() => {}}
							disabled
						/>
						<NewChipsInput
							title="Payment Type"
							name="paymentType"
							items={lovs.paymentTypes}
							onChange={(v) => {
								handleChange("paymentType", v);
							}}
							value={pageState.values.paymentType}
						/>
					</div>
					<div className={classes.fieldRow}>
						<EnhancedDatePicker
							title="Effective From"
							name="effectiveFrom"
							value={pageState.values.effectiveFrom}
							onDateChange={(v) => {
								handleChange(
									"effectiveFrom",
									formatDateTime(v, SEND_TO_BACKEND_DATE_FORMAT)
								);
							}}
							onBlur={() => {}}
							minDate={new Date(pageState.values.policyIssuanceDate)}
							maxDate={maxEffectiveFrom}
						/>
						{pageState.values.paymentType === "EQUIVALENT" && (
							<NewChipsInput
								title="Frequency"
								name="frequency"
								items={lovs.frequencies}
								onChange={(v) => {
									handleChange("frequency", v);
								}}
								value={pageState.values.frequency}
							/>
						)}
						<EnhancedInput
							title="No of Payments"
							name="numOfPayments"
							type="text"
							value={pageState.values.numOfPayments}
							error={pageState.errors.numOfPayments}
							onBlur={() => {}}
							onChange={(e) => {
								// validate value to be a whole number using regex
								const regex = /^[0-9]*$/;
								if (regex.test(e.target.value)) {
									handleChange("numOfPayments", e.target.value);
								}
							}}
						/>
						{pageState.values.paymentType === "CUSTOM" && (
							<EnhancedInput
								title="Amount to be Allocated"
								name="amountToBeAllocated"
								type="number"
								value={amountToBeAllocated}
								onBlur={() => {}}
								onChange={() => {}}
								disabled
							/>
						)}
					</div>
					<div className={classes.buttonContainer}>
						<EnhancedButton
							type="submit"
							variant="outlined"
							style={{ width: "108px" }}
						>
							Apply
						</EnhancedButton>
					</div>
				</form>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<EnhancedTable
						name="paymentTermsSchedule"
						data={tableData}
						headers={paymentTermsHeaders(pageState)}
						handleBlur={(index, columnName, value) => {
							handleCellValueChange(index, columnName, value);
						}}
						usePagination
						showTablePagination={false}
						showInlineFilter={false}
						hideToolbar
						disableSelection
						inline
						showCellFullData
					/>
					<div className={classes.buttonContainer}>
						<EnhancedButton
							type="submit"
							disabled={isSubmitButtonDisabled}
							variant="contained"
							backgroundColor={MAIN_ONE_THEME.palette.primary5.main}
							color="rgba(255, 255, 255, 1)"
							style={{ width: "108px" }}
						>
							Submit
						</EnhancedButton>
					</div>
				</form>
			</>
		);
	};

	return (
		<GenericPopUp
			open={open}
			onClose={onClose}
			title={
				!booted
					? "Change Payment Terms"
					: `Change Payment Terms (${pageState.values.policyNumber})`
			}
			content={!booted ? <Loader /> : renderContent()}
		/>
	);
};

export default PaymentTermsPopup;
