import React, { useMemo } from "react";
import { IDynamicDiplayView } from "../../../../../components/widgets/dynamic-display";
import DynamicWidget from "../../../../../components/widgets/dynamic-display/DynamicWidget";
import { IProductionDetailsSummary } from "..";
import { EnhancedDisplayType } from "../../../../../components/enhanced-form/EnhancedDisplay";

interface IPolicyDetailsWidgetProps {
  data: IProductionDetailsSummary;
}

const ProductionPolicyMarineDetailsWidget: React.FC<
  IPolicyDetailsWidgetProps
> = ({ data }) => {
  const dynamicView = useMemo(
    (): IDynamicDiplayView => ({
      name: "PolicyMarineDetails",
      sections: [
        {
          name: "MarineDetails",
          title: "Marine Details",
          properties: {
            matter: {
              multiline: false,
              value: data?.Marine?.matter,
              name: "matter",
              title: "Matter",
            },
            description: {
              multiline: false,
              value: data?.Marine?.description,
              name: "description",
              title: "Description",
            },
            vesselName: {
              multiline: false,
              value: data?.Marine?.vesselName,
              name: "vesselName",
              title: "Vessel Name",
            },
            vesselAge: {
              multiline: false,
              value: data?.Marine?.vesselAge?.toString(),
              name: "vesselAge",
              title: "Vessel Age",
            },
            from: {
              multiline: false,
              value: data?.Marine?.from,
              name: "from",
              title: "From",
            },
            to: {
              multiline: false,
              value: data?.Marine?.to,
              name: "to",
              title: "To",
            },
            via: {
              multiline: false,
              value: data?.Marine?.via,
              name: "via",
              title: "Via",
            },
            survey: {
              multiline: false,
              value: data?.Marine?.survey,
              name: "survey",
              title: "Survey",
            },
            cargoContract: {
              multiline: false,
              value: data?.Marine?.cargoContract,
              name: "cargoContract",
              title: "Cargo Contract",
            },
            lcNumber: {
              multiline: false,
              value: data?.Marine?.lcNumber,
              name: "lcNumber",
              title: "LC Number",
            },
            lcIssueDate: {
              multiline: false,
              value: data?.Marine?.lcIssueDate,
              type: EnhancedDisplayType.Date,
              name: "lcIssueDate",
              title: "LC Issue Date",
            },
            bank: {
              multiline: false,
              value: data?.Marine?.bank,
              name: "bank",
              title: "Bank",
            },
            claimsPayableBy: {
              multiline: false,
              value: data?.Marine?.claimsPayableBy,
              name: "claimsPayableBy",
              title: "Claims Payable by",
            },
            loading: {
              multiline: false,
              value: data?.Marine?.loading?.toString(),
              type: EnhancedDisplayType.Percentage,
              name: "loading",
              title: "Loading",
            },
            remarks: {
              multiline: false,
              value: data?.Marine?.remarks,
              name: "remarks",
              title: "Remarks",
            },
          },
        },
      ],
    }),
    [data]
  );

  return <DynamicWidget hasFourFields={true} view={dynamicView} />;
};

export default ProductionPolicyMarineDetailsWidget;
