import { isEmpty, isValidNumber } from "./validationUtils";

export function removeObjectAtIndex<T>(array: T[], index: number): T[] {
  if (index < 0 || index >= array.length) {
    return array;
  }

  return array.filter((_, idx) => idx !== index);
}

export function numberToPercentage(num: number | string): number {

  if(isEmpty(num) || !isValidNumber(num)) {
    return num  as any;
  }

  // Convert num to a number if it's a string
  const parsedNum = typeof num === 'string' ? parseFloat(num) : num;

  // Calculate percentage
  const percentage = parsedNum * 100;

  // Format the percentage as a string with 2 decimal places and a percentage sign
  return Number(percentage.toFixed(2));
}


export function convertToFixedNumber(input: number | string): number {
  // Convert input to number if it's a string

  if(isEmpty(input) || !isValidNumber(input)) {
    return input  as any;
  }

  const num = typeof input === 'string' ? parseFloat(input) : input;
  
  // Convert to fixed-point number with 2 decimal places
  const fixedNum = num.toFixed(2);
  
  return Number(fixedNum);
}


export function convertToTitleCase(input: string): string {

  if(isEmpty(input)){
    return "";
  }

  // Replace underscores with spaces and split into words
  let words = input.replace(/_/g, ' ').split(' ');

  // Convert each word to lowercase except the first character
  words = words.map(word => {
      if (word.length === 0) return ''; // Handle empty strings
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  // Join the words into a single string
  return words.join(' ');
}
