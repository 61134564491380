import { useApolloClient, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  IBusinessPartnerDetails,
  IBusinessPartnerDetailsPageProps,
  initialValues,
} from ".";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { entityView, headers, steps } from "./content";
import {
  getAgencyVignetteRange,
  getBusinessPartnersDetailsInfo,
} from "./queries";
import {
  entityToBusinessPartnersDetails,
  getBusinessPartnerStatus,
  mapToListingData,
} from "./utils";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import { useAppSelector } from "../../redux/hooks";
import AgencyDrawer from "../agency-drawer/AgencyDrawer";
import dayjs from "dayjs";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import VignetteRangeDrawer from "../vignette-range-drawer/VignetteRangeDrawer";
import { IListingData } from "../../models/listing";
import WidgetSection from "../../components/common/WidgetSection";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import { makeStyles } from "tss-react/mui";
import { IEnhancedTableMenuItem } from "../../components/enhanced-table";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    ".Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& label": {
      "& span": {
        margin: "-2px 0",
      },
    },
  },
  sectionTitle: {
    padding: "-10x 10x!important",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "15px 20px 10px 0px",
    alignItems: "flex-start",
    display: "grid",
    gap: "5px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "19.5% 19.5% 19.5% 19% 16% 3% 3%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  repeaterField: {
    margin: "0px!important",
  },
  arField: {
    width: "96%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
}));

const BusinessPartnerDetailsPage: React.FC<
  IBusinessPartnerDetailsPageProps
> = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const [modifyDrawerOpen, setModifyDrawerOpen] = useState<boolean>(false);
  //#endregion

  //#region component hooks and states
  const [vignetteRangeDrawerOpen, setVignetteRangeDrawerOpen] =
    useState<boolean>(false);

  const [businessPartnerInfo, setBusinessPartnerInfo] =
    useState<IBusinessPartnerDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const entityId = params.id;

  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);
  //#endregion

  //#region graphql hooks
  const client = useApolloClient();

  const entityResult = useQuery(getBusinessPartnersDetailsInfo(), {
    variables: { id: entityId },
    errorPolicy: "all",
    fetchPolicy: "no-cache",
  });

  let actions: IEnhancedTableMenuItem[] = [];
  actions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: false,
      disabled: false,
    },
  ];

  actions[0].onClick = () => {
    setVignetteRangeDrawerOpen(true);
  };

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const { loading, error, data, fetchMore } = useQuery(
    getAgencyVignetteRange(),
    {
      variables: {
        currentPage: 1,
        currentPageSize: tableData.pageSize,
        SelectedAgencyID: params.id,
      },
      errorPolicy: "all",
    }
  );
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);
      setTableData({ ...tableData, pageNumber: 0 });
    }
    setLoadingState(false);
  }, [loading, error, data]);

  useEffect(() => {
    try {
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const businessPartnerDetails = entityToBusinessPartnersDetails(
          entityResult?.data
        );

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.companyCode.value =
          businessPartnerDetails.companyCode;
        applicationProperties.properties.status.value = capitalizeFirstLetter(
          businessPartnerDetails.status
        );
        applicationProperties.properties.type.value =
          businessPartnerDetails.type;

        applicationProperties.properties.mTPCommissionPercentage.value =
          businessPartnerDetails.mTPCommissionPercentage == 0
            ? "0"
            : businessPartnerDetails.mTPCommissionPercentage;
        applicationProperties.properties.mBICommissionPercentage.value =
          businessPartnerDetails.mBICommissionPercentage == 0
            ? "0"
            : businessPartnerDetails.mBICommissionPercentage;
        applicationProperties.properties.expatCommissionPercentage.value =
          businessPartnerDetails.expatCommissionPercentage == 0
            ? "0"
            : businessPartnerDetails.expatCommissionPercentage;

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value =
          businessPartnerDetails.createdBy;
        systemProperties.properties.createdOn.value = dayjs(
          new Date(businessPartnerDetails.createdOn)
        ).format("DD/MM/YYYY");
        systemProperties.properties.modifiedBy.value =
          businessPartnerDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(businessPartnerDetails.modifiedOn)
        ).format("DD/MM/YYYY");

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        setBusinessPartnerInfo(businessPartnerDetails);

        setUpdatedLeftSideInfo(newLeftEntityView);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);
  //#endregion

  //#region functions
  const handleModifyLeftSectionChange = async () => {
    try {
      client.refetchQueries({
        include: [getBusinessPartnersDetailsInfo()],
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{error}</ToastErrorMessage>);
    }
  };

  function handlePageChange(page: number) {
    setTableLoadingState(true);
    fetchMore({
      variables: {
        currentPage: ++page,
        currentPageSize: tableData.pageSize,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToListingData(fetchMoreResult.data);
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: --page,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    fetchMore({
      variables: {
        currentPage: 1,
        currentPageSize: numberOfRecordsPerPage,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToListingData(fetchMoreResult.data);

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  const renderMainChildren = () => {
    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={getBusinessPartnerStatus(
              entityResult?.data?.Insurance?.entities?.agency?.views?.Insurance_all?.properties?.AgencyStatus.toLowerCase()
            )}
            steps={steps}
            className={classes.stepper}
          />
          <WidgetSection>
            <form>
              {loading ? (
                <div></div>
              ) : (
                <div style={{ marginTop: "20px" }}>
                  <EnhancedTable
                    title={capitalizeFirstLetter("Vignette Range Listing")}
                    inlineTitle={capitalizeFirstLetter(
                      "Vignette Range Listing"
                    )}
                    name="count"
                    entityName={"Vignette"}
                    entityIdColumnName={"insurance_Vignette_Id"}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={tableData}
                    headers={headers}
                    handlePageChange={(page: number) => handlePageChange(page)}
                    handleRowsPerPageChange={(page: number) =>
                      handleRowsPerPageChange(page)
                    }
                    currentPage={tableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection
                    actions={actions}
                    loader={tableLoadingState}
                    showTablePagination={true}
                  />
                  {vignetteRangeDrawerOpen && (
                    <VignetteRangeDrawer
                      open={vignetteRangeDrawerOpen}
                      onClose={() => setVignetteRangeDrawerOpen(false)}
                      onSuccess={() => {
                        handlePageChange(0);
                      }}
                    />
                  )}
                </div>
              )}
            </form>
          </WidgetSection>
        </div>

        {modifyDrawerOpen && (
          <AgencyDrawer
            agencyId={params.id}
            open={modifyDrawerOpen}
            onClose={() => setModifyDrawerOpen(false)}
            onSuccess={() => {
              handleModifyLeftSectionChange();
              setModifyDrawerOpen(false);
            }}
          />
        )}
      </>
    );
  };

  const handleIconClick = () => {
    setModifyDrawerOpen(true);
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={businessPartnerInfo.agency}
          view={updatedLeftSideInfo}
          iconUrl={tenant.cdnUrl + "/icons/edit-secondary.svg"}
          onClickIcon={handleIconClick}
        ></EntityInfoWidget>
      </>
    );
  };
  //#endregion

  return loadingState ? (
    <Loader />
  ) : (
    <StaticLayout
      name={"Application Details"}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default BusinessPartnerDetailsPage;
