import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  checkClaimCoverExists,
  createClaimCover,
  getClaimCoverEnums,
  getClaimCoverInfo,
  updateClaimCover,
  checkClaimCoverPrintingOrderExists,
} from "./queries";
import { LookupToList, graphqlEntityToClaimCoverInfo } from "./utils";
import Loader from "../../components/Loader";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { isEmpty, isValidNumber } from "../../utils/validationUtils";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { getError } from "../../utils/graph-utils";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { inputs } from "./content";
import { cloneDeep } from "lodash";

const ClaimCoverDrawer: React.FC<IClaimCoverDrawerProps> = ({
  claimCoverId,
  lineId,
  lineName,
  isPolicyCoverNameDisabled = false,
  policyCoverId,
  open,
  onSuccess,
  onClose,
}) => {
  const [booted, setBooted] = useState<boolean>(false);

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [claimCoverEnumResultsQuery] = useLazyQuery(getClaimCoverEnums());

  const [claimCoverAction] = useMutation(
    claimCoverId ? updateClaimCover() : createClaimCover()
  );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [claimCoverInfoQuery] = useLazyQuery(getClaimCoverInfo());

  const [checkClaimCoverExistsLazy] = useLazyQuery(checkClaimCoverExists(), {});
  const [checkClaimCoverPrintingOrderExistsLazy] = useLazyQuery(
    checkClaimCoverPrintingOrderExists(),
    {}
  );

  const loadLovList = async () => {
    const result = await claimCoverEnumResultsQuery({
      fetchPolicy: "no-cache",
      variables: { SelectedLineId: lineId },
    });

    const newClaimCoverEnums = LookupToList(result.data);

    return newClaimCoverEnums;
  };

  const getEntityInfo = async () => {
    if (claimCoverId) {
      const apiResult = await claimCoverInfoQuery({
        variables: { id: claimCoverId },
      });
      if (apiResult.data) {
        const claimCoverEntity = graphqlEntityToClaimCoverInfo(
          apiResult.data,
          lineId,
          lineName
        );
        return claimCoverEntity;
      }
    }

    return null;
  };

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      const [claimCoverEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      updatedInputs.lineName.value = lineId;
      updatedInputs.policyCover.value = policyCoverId;

      if (claimCoverEntity) {
        updatedInputs.lineName.value = claimCoverEntity.lineName || lineId;
        updatedInputs.policyCover.value = claimCoverEntity.policyCover;
        updatedInputs.claimCoverName.value = claimCoverEntity.claimCoverName;
        updatedInputs.claimCoverExternalCode.value =
          claimCoverEntity.claimCoverExternalCode;
        updatedInputs.arabicName.value = claimCoverEntity.arabicName;
        updatedInputs.claimCoverPrintingOrder.value =
          claimCoverEntity.claimCoverPrintingOrder;
        updatedInputs.claimCoverDescription.value =
          claimCoverEntity.claimCoverDescription;
      }

      (updatedInputs.lineName as IFormSelectDynamicProps).selectOptions =
        lovData["lines"];

      (updatedInputs.policyCover as IFormSelectDynamicProps).selectOptions =
        lovData["policiesCovers"];

      updatedInputs.policyCover.disabled = isPolicyCoverNameDisabled;

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const validateClaimCoverExistsOnNameChange = async (
    selectedPolicyCoverId: string,
    claimCoverName?: string
  ): Promise<string> => {
    if (
      !isEmpty(lineId) &&
      !isEmpty(claimCoverName) &&
      !isEmpty(selectedPolicyCoverId)
    ) {
      const validationResponse = await checkClaimCoverExistsLazy({
        variables: {
          policyCoverId: !isEmpty(policyCoverId)
            ? policyCoverId
            : selectedPolicyCoverId,
          name: claimCoverName,
          claimCoverId: claimCoverId,
          externalCode: "",
        },
      });
      if (
        validationResponse.data.PlanConfigManagement?.queries
          ?.checkClaimCoverExists.length > 0
      ) {
        return "Claim Cover Name already exists under the same Policy Cover";
      }
    }
    return "";
  };

  const validateClaimCoverExistsOnExternalCodeChange = async (
    selectedPolicyCoverId: string,
    claimCoverExternalCode?: string
  ): Promise<string> => {
    if (
      !isEmpty(lineId) &&
      !isEmpty(claimCoverExternalCode) &&
      !isEmpty(selectedPolicyCoverId)
    ) {
      const validationResponse = await checkClaimCoverExistsLazy({
        variables: {
          lineId: lineId,
          externalCode: claimCoverExternalCode,
          claimCoverId: claimCoverId,
          policyCoverId: selectedPolicyCoverId,
          name: "",
        },
      });
      if (
        validationResponse.data.PlanConfigManagement?.queries
          ?.checkClaimCoverExists.length > 0
      ) {
        return "External Code already exists under the same Policy Cover";
      }
    }
    return "";
  };

  const validateClaimCoverPrintingOrderExistsOnPrintingOrderChange = async (
    selectedPolicyCoverId: string,
    claimCoverPrintingOrder: number
  ): Promise<string> => {
    if (!isEmpty(lineId) && !isEmpty(claimCoverPrintingOrder)) {
      const validationResponse = await checkClaimCoverPrintingOrderExistsLazy({
        variables: {
          printingOrder: isValidNumber(claimCoverPrintingOrder)
            ? parseInt(claimCoverPrintingOrder as any)
            : 0,
          policyCoverId: selectedPolicyCoverId,
          claimCoverId: claimCoverId,
        },
      });

      if (
        validationResponse.data.PlanConfigManagement?.queries
          ?.checkClaimCoverPrintingOrderExists.length > 0
      ) {
        return "Printing Order already exists under the same Policy Covere";
      }
    }
    return "";
  };

  const onCustomValidate = async (
    fieldName: string,
    values: Record<string, any>
  ) => {

    if (fieldName === "claimCoverName") {
      return await validateClaimCoverExistsOnNameChange(
        values.policyCover,
        values[fieldName]
      );
    }

    if (fieldName === "claimCoverExternalCode") {
      return await validateClaimCoverExistsOnExternalCodeChange(
        values.policyCover,
        values[fieldName]
      );
    }

    if (fieldName === "claimCoverPrintingOrder") {
      return await validateClaimCoverPrintingOrderExistsOnPrintingOrderChange(
        values.policyCover,
        values[fieldName]
      );
    }

    return "";
  };

  const onChange = async (
    fieldName: string,
    value: any,
    values: Record<string, any>,
    errors: Record<string, string>,
    touched: Record<string, boolean>
  ) => {
    if (fieldName === "policyCover") {
      values[fieldName] = value;

      const [
        claimCoverNameError,
        claimCoverExternalCodeError,
        claimCoverPrintingOrderError,
      ] = await Promise.all([
        onCustomValidate("claimCoverName", values),
        onCustomValidate("claimCoverExternalCode", values),
        onCustomValidate("claimCoverPrintingOrder", values),
      ]);
      errors.claimCoverName = claimCoverNameError;
      errors.claimCoverExternalCode = claimCoverExternalCodeError;
      errors.claimCoverPrintingOrder = claimCoverPrintingOrderError;

      touched.claimCoverName = true;
      touched.claimCoverExternalCode = true;
      touched.claimCoverPrintingOrder = true;
    }
  };

  const onCustomBlur = async (
    fieldName: string,
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (
      fieldName === "claimCoverName" ||
      fieldName === "claimCoverExternalCode" ||
      fieldName === "claimCoverPrintingOrder"
    ) {
      if (!errors[fieldName]) {
        errors[fieldName] = await onCustomValidate(fieldName, values);
      }
    }
    return { values, errors };
  };

  const onCustomValidateForm = async (
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (!errors.claimCoverName) {
      errors.claimCoverName = await onCustomValidate("claimCoverName", values);
    }

    if (!errors.claimCoverExternalCode) {
      errors.claimCoverExternalCode = await onCustomValidate(
        "claimCoverExternalCode",
        values
      );
    }

    if (!errors.claimCoverPrintingOrder) {
      errors.claimCoverPrintingOrder = await onCustomValidate(
        "claimCoverPrintingOrder",
        values
      );
    }
    return errors;
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      const variables = {
        claimCoverInputs: {
          name: data.claimCoverName,
          externalCode: data.claimCoverExternalCode,
          nameArabic: data.arabicName,
          printingOrder: parseInt(
            data.claimCoverPrintingOrder as unknown as string
          ),
          claimCoverDescription: data.claimCoverDescription,
          policyCoverID: data.policyCover,
          lineID: lineId,
        },
      };

      const res = await claimCoverAction({
        variables: claimCoverId
          ? { ...variables, entityId: claimCoverId }
          : variables,
        errorPolicy: "all",
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {claimCoverId
              ? "Claim Cover successfully updated"
              : "Claim Cover successfully created"}
          </ToastSuccessMessage>
        );

        setTimeout(() => {
          setFormDisabled(false);
          setSubmitButtonState("success");
          onSuccess();
          onClose();
        }, 500);
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (error) {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
    }
  };

  return (
    <GenericDrawer
      title={claimCoverId ? "Edit Claim Cover" : "New Claim Cover"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            onCustomBlur={onCustomBlur}
            isSubmitButtonDisabled={!!submitButtonState}
            disableForm={formDisabled}
            title="Information"
            onChange={onChange}
            onCustomValidate={onCustomValidateForm}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ClaimCoverDrawer;
