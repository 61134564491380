import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

const CustomCategoryTreeNode: React.FC<CustomCategoryTreeNode> = ({
  id,
  name,
  children,
  layer,
  onSelectNode,
}) => {
  const { classes, cx } = useStyles({ layer: layer });

  const [open, setOpen] = useState<boolean>(false);

  return layer < 3 ? (
    <Accordion
      classes={{ root: classes.accordionMainContainer }}
      disableGutters
      elevation={0}
      onChange={(e, expanded) => setOpen(expanded)}
    >
      <AccordionSummary
        classes={{
          root: cx(
            classes.accordionSummaryContainer,
            classes.categoryContainer,
            layer === 3 && classes.lastCategoryContainer
          ),
          content: cx(
            classes.accordionSummaryText,
            layer === 1 && classes.accordionSummaryFirstText,
            classes.accordionSummaryIndentedText
          ),
        }}
      >
        <>
          {open ? (
            <ExpandLess fontSize="small" />
          ) : (
            <ExpandMore fontSize="small" />
          )}
          <div
            onClick={(e) => {
              e.stopPropagation();
              onSelectNode();
            }}
            style={{ width: '100%' }}
          >{`${id} ${name}`}</div>
        </>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: cx(
            classes.accordionContentContainer,
            layer === 2 && classes.accordionLastContentContainer
          ),
        }}
      >
        {children.map((node) => (
          <CustomCategoryTreeNode
            id={node.id}
            name={node.name}
            children={node.children}
            layer={node.layer}
            onSelectNode={() => node.onSelectNode()}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  ) : (
    <AccordionSummary
      classes={{
        root: cx(
          classes.accordionSummaryContainer,
          classes.categoryContainer,
          layer === 3 && classes.lastCategoryContainer
        ),
        content: cx(
          classes.accordionSummaryText,
          layer === 1 && classes.accordionSummaryFirstText,
          classes.accordionSummaryIndentedText
        ),
      }}
    >
      <>
        <div
          onClick={(e) => {
            e.stopPropagation();
            onSelectNode();
          }}
          style={{ width: '100%' }}
        >{`${id} ${name}`}</div>
      </>
    </AccordionSummary>
  );
};

const useStyles = makeStyles<{ layer: number }>()((theme, props) => ({
  accordionMainContainer: {
    padding: 0,
    '::before': {
      backgroundColor: 'transparent',
    },
  },
  accordionSummaryContainer: {
    padding: ' 5px',
    minHeight: 'unset',
  },
  accordionSummaryText: {
    margin: 0,
    color: '#231F20',
    fontSize: '13px',
    lineHeight: '16px',
    fontFamily: 'SourceSansPro-Medium',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordionSummaryFirstText: {
    fontSize: '15px',
    lineHeight: '21px',
    fontFamily: 'SourceSansPro-Bold',
  },
  accordionSummaryIndentedText: {
    // - 1 to not indent first layer
    marginLeft: `${(props.layer - 1) * 25}px`,
  },
  accordionSummaryExpanded: {
    margin: 0,
  },
  accordionContentContainer: {
    backgroundColor: '#F9F9F9',
    padding: '1px 0 0',
  },
  accordionLastContentContainer: {
    backgroundColor: '#EFEFEF',
    boxShadow: 'inset 0 1px 3px #00000024',
  },
  categoryContainer: {
    backgroundColor: '#F9F9F9',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#EFEFEF',
    },
  },
  lastCategoryContainer: {
    backgroundColor: '#EFEFEF',
    paddingLeft: '25px',
    ':hover': {
      backgroundColor: '#E0E0E0',
    },
  },
}));

export default CustomCategoryTreeNode;
