import { getEnviromentDetails } from "./env-utils";

export function getRedirectUri(): string {
  return getEnviromentDetails().identityRedirectUrl;
}

export function getClient() {
  return {
    client_id: "graphql",
    client_secret: "06BD941-1437-402C-A09F-A9BDA23044BD",
  };
}
