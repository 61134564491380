export interface IClauseOption {
    id: string;
    externalCode: string;
    name: string;
    description: string;
}

export function extractClauseOptions(jsonData: any): IClauseOption[] {
    const items = jsonData.data.PlanConfigManagement.queries.getClauseList.items;

    const clauseOptions: IClauseOption[] = items.map((item: any) => ({
        id: item.planConfigManagement_Clause_Id,
        externalCode: item.planConfigManagement_Clause_ExternalCode,
        name: item.planConfigManagement_Clause_Name,
        description: item.planConfigManagement_Clause_Description
    }));

    return clauseOptions;
}