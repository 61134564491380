import React, { useCallback, useMemo, useState } from 'react';
import UpdatedEnhancedTable, {
  IEnhancedTableProps,
} from '../../../../../../components/updated-enhanced-table/EnhancedTable';

interface ITableWithFiltersProps
  extends Pick<
    IEnhancedTableProps,
    'tableFilters' | 'tableTitle' | 'headers' | 'data' | 'actions'
  > {
  applyFilters: (
    values: Record<string, Date[] | string[]>,
    page?: number
  ) => void;
}

const TableWithFilters: React.FC<ITableWithFiltersProps> = ({
  tableFilters,
  tableTitle,
  headers,
  data,
  actions,
  applyFilters,
}) => {
  const [filterValues, setFilterValues] = useState<
    Record<string, Date[] | string[]>
  >({});

  const filters: ITableWithFiltersProps['tableFilters'] = useMemo(() => {
    let filtersWithValues: ITableWithFiltersProps['tableFilters'] = {};
    let key = '';
    Object.keys(tableFilters).forEach((filter) => {
      switch (tableFilters[filter].type) {
        case 'select': {
          key = 'selectedValues';
          break;
        }
        default: {
          key = 'value';
          break;
        }
      }
      filtersWithValues[filter] = {
        ...tableFilters[filter],
        [key]: filterValues[filter],
      };
    });
    return filtersWithValues;
  }, [filterValues, tableFilters]);

  const clearFilter = useCallback(() => {
    const resetFilterValues = Object.keys(filterValues).reduce(
      (acc: Record<string, Date[] | string[]>, key) => {
        acc[key] = undefined;
        return acc;
      },
      {}
    );
    setFilterValues(resetFilterValues);
    applyFilters(resetFilterValues);
  }, []);

  return (
    <UpdatedEnhancedTable
      name={tableTitle}
      tableTitle={tableTitle}
      hideHeader
      tableFilters={filters}
      headers={headers}
      data={data}
      actions={actions}
      embeddedToolbar
      handleFilterChange={(name, value) => {
        setTimeout(() => {
          setFilterValues((oldFilterValues) => ({
            ...oldFilterValues,
            [name]: value,
          }));
        }, 200);
      }}
      handleFilterClear={clearFilter}
      handleFilterAction={() => applyFilters(filterValues)}
      handlePageChange={(page) => {
        applyFilters(filterValues, page);
      }}
    />
  );
};

export default TableWithFilters;
