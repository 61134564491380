import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Production.queries.getProposalList.items.forEach(
    (obj: IGenericProposalsTableData, i: number) => {
      const subline =
        obj.production_Proposal_SublineID?.views?.PlanConfigManagement_all
          ?.properties;
      const line =
        obj.production_Proposal_LineID?.views?.PlanConfigManagement_all
          ?.properties;
      const plan =
        obj.production_Proposal_PlanID?.views?.PlanConfigManagement_all
          ?.properties;
      const insured =
        obj.production_Proposal_InsuredID?.views?.Production_all?.properties;

      list[i] = {
        id: obj.production_Proposal_Id,
        proposalNumber: obj.production_Proposal_InternalCode,
        name: obj.production_Proposal_ProposalName,
        line: `${line.ExternalCode} - ${line.Name}`,
        subline: `${subline.ExternalCode} - ${subline.Name}`,
        plan: `${plan.ExternalCode} - ${plan.Name}`,
        insured: insured?.FullName,
        status: capitalizeFirstLetter(
          obj.production_Proposal_Status?.replaceAll("_", " ")
        ),
        statusReason: capitalizeFirstLetter(
          obj?.production_Proposal_StatusReason,
          "_"
        ),
        statusReasonTitle: capitalizeFirstLetter(
          obj?.production_Proposal_StatusReason,
          "_"
        ),
        effectiveDate: obj.production_Proposal_PolicyEffectiveDate,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Production.queries.getProposalList?.paging?.pageSize,
    pageNumber: data.Production.queries.getProposalList?.paging?.pageNumber,
    totalCount: data.Production.queries.getProposalList?.paging?.totalCount,
  };
}

export function dataToLovs(
  data: any,
  linesData: any,
  filterV: Record<string, any>
): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    line: {},
    subline: {},
    plan: {},
    businessPartner: {},
    insured: {},
    status: {},
    statusReason: {},
  };

  data?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBPPlan_BusinessPartnerID: {
        id: string;
        views: {
          SalesforceManagement_all: {
            properties: {
              PersonID: {
                views: {
                  SalesforceManagement_all: {
                    properties: {
                      FullName: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      lovs.businessPartner[
        obj?.salesforceManagement_AssignedBPPlan_BusinessPartnerID?.id
      ] =
        obj?.salesforceManagement_AssignedBPPlan_BusinessPartnerID?.views?.SalesforceManagement_all?.properties?.PersonID?.views?.SalesforceManagement_all?.properties?.FullName;
    }
  );

  linesData?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBPPlan_BusinessPartnerID: {
        id: string;
        views: {
          SalesforceManagement_all: {
            properties: {
              PersonID: {
                views: {
                  SalesforceManagement_all: {
                    properties: {
                      FullName: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
      salesforceManagement_AssignedBPPlan_LineID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              Id: string;
              Name: string;
              ExternalCode: string;
            };
          };
        };
      };
    }) => {
      lovs.line[
        obj?.salesforceManagement_AssignedBPPlan_LineID?.views?.PlanConfigManagement_all?.properties?.Id
      ] = `${obj?.salesforceManagement_AssignedBPPlan_LineID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj?.salesforceManagement_AssignedBPPlan_LineID?.views?.PlanConfigManagement_all?.properties?.Name}`;
    }
  );

  data?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBPPlan_PlanID: {
        views: {
          PlanConfigManagement_all: {
            properties: {
              SublineID: {
                id: string;
                views: {
                  PlanConfigManagement_all: {
                    properties: {
                      Name: string;
                      ExternalCode: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      lovs.subline[
        obj?.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.SublineID?.id
      ] = `${obj?.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.SublineID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.SublineID?.views?.PlanConfigManagement_all?.properties?.Name}`;
    }
  );

  data?.SalesforceManagement?.queries?.getAssignedBPPlanListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBPPlan_PlanID: {
        id: string;
        views: {
          PlanConfigManagement_all: {
            properties: {
              Name: string;
              ExternalCode: string;
              SublineID: {
                id: string;
                views: {
                  PlanConfigManagement_all: {
                    properties: {
                      Name: string;
                      ExternalCode: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      if (!filterV?.subline || filterV?.subline?.length === 0) {
        if (
          lovs.subline[
          obj?.salesforceManagement_AssignedBPPlan_PlanID?.views
            ?.PlanConfigManagement_all?.properties?.SublineID?.id
          ]
        ) {
          lovs.plan[
            obj?.salesforceManagement_AssignedBPPlan_PlanID?.id
          ] = `${obj?.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.Name}`;
        }
      } else {
        if (filterV?.subline?.includes(obj?.salesforceManagement_AssignedBPPlan_PlanID?.views
          ?.PlanConfigManagement_all?.properties?.SublineID?.id)) {
          lovs.plan[
            obj?.salesforceManagement_AssignedBPPlan_PlanID?.id
          ] = `${obj?.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.ExternalCode} - ${obj.salesforceManagement_AssignedBPPlan_PlanID?.views?.PlanConfigManagement_all?.properties?.Name}`;
        }
      }
    }
  );

  data?.Production?.queries?.GetAssignedPersons?.forEach(
    (obj: {
      salesforceManagement_AssignedPerson_PersonID: { id: string };
      salesforceManagement_AssignedPerson_FullName: string;
      salesforceManagement_AssignedPerson_PersonCode: string;
    }) => {
      lovs.insured[
        obj.salesforceManagement_AssignedPerson_PersonID?.id
      ] = `${obj.salesforceManagement_AssignedPerson_PersonCode} - ${obj.salesforceManagement_AssignedPerson_FullName}`;
    }
  );

  lovs.status = enumListAsRecordObject(
    data?.Production_ProposalStatuses?.enumValues
  );

  lovs.statusReason = lookupListAsRecordObject(
    data?.Production?.lookups?.proposalStatusReasons
  );

  return lovs;
}
