import { gql } from "@apollo/client";
export function getCustomerEnums() {
  return gql(/* GraphQL */ `
    query GetCustomerEnums($SelectedCompanyID: String)
    {
  Accounting {
    queries {
      GetAccountsOfCompany(SelectedCompanyID: $SelectedCompanyID){
        accounting_ChartOfAccounts_Id,
        accounting_ChartOfAccounts_AccountName
      }
    },
    lookups {
      accountType {
        Id,
        Title
      }
    }
  },
  Core {
    lookups {
      currencies {
        Id,
        Title
      }
    }
  },
  Insurance {
    lookups {
      countries {
        Id,
        Code,
        Title
      }
    }
  }
  SalesforceManagement {
    lookups{
      relatedCompanies {
              Code
              Title
              Id
          }
    }
  }
}
  `);
}

export function validateCustomerName() {
    return gql (
        `query validateCustomerName($EnteredCustomerName: String!,
        $SelectedCompany: String) {
          Accounting {
            queries {
              EnteredCustomerNameExists(
                EnteredCustomerName: $EnteredCustomerName
                SelectedCompany: $SelectedCompany
              ) {
                accounting_Customers_Id
                accounting_Customers_createdOn
              }
            }
          }
        }
        `
    )
}

export function validateNameOnUpdate() {
    return gql (
        `query validateNameUpdate($Id: String, $Name: String, $CurrentAccountID: String, $SelectedCompanyID: String) {
            Accounting {
                queries {
                UpdatedAccountNameOrIDExists(Id: $Id, Name: $Name, CurrentAccountID: $CurrentAccountID, SelectedCompanyID: $SelectedCompanyID) {
                    accounting_ChartOfAccounts_Id
                }
                }
            }
        }
        `
    )
}

export function getAccountInfo() {
    return gql(/* GraphQL */ `
        query GetAccountInfo($id: String!)
{
  Accounting {
    entities {
      chartOfAccounts(id: $id) {
        id,
        views {
          Accounting_all {
            properties {
              Id,
              AccountType{
                Id
              },
              AccountCurrency{
                Id
              },
              createdOn,
              modifiedOn,
              AccountCompany{
                Id
              },
              AccountName,
              AccountID,
              AccountDescription,
              IsSubAccount,
              ParentAccount{
                id
              },
              ParentAccountLayer2 {
                id,
                views {
                  Accounting_all {
                    properties {
                      Id,
                      AccountGroup,
                      AccountGroupName,
                      AccountGroupLayerLevel
                    }
                  }
                }
              },
              ParentAccountLayer3 {
                id,
                views {
                  Accounting_all {
                    properties {
                      Id,
                      AccountGroup,
                      AccountGroupName,
                      AccountGroupLayerLevel
                    }
                  }
                }
              },
              ParentAccountLayer4 {
                id,
                views {
                  Accounting_all {
                    properties {
                      Id,
                      AccountGroup,
                      AccountGroupName,
                      AccountGroupLayerLevel
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
      `);
}

export function createCustomer() {
  return gql`
    mutation createCustomer($EnteredAddressBuilding: String,
$EnteredAddressCity: String,
$EnteredAddressCountry: String,
$EnteredAddressDetails: String,
$EnteredAddressStreet: String,
$EnteredContactEmail: String,
$EnteredContactFirstName: String,
$EnteredContactLastName: String,
$EnteredContactPhone: String,
$EnteredCustomerName: String!,
$EnteredNote: String,
$EnteredReferenceNumber: String,
$EnteredWebsite: String,
$LinkedAccount: String,
$LinkToExistingAccount: Boolean!,
$MakeThisASubAccount: Boolean!,
$SelectedAccountCurrency: String,
$SelectedAccountType: String,
$SelectedCompany: String!,
$SelectedParentAccount: String
) {
  accounting {
    actions {
      createCustomer(
        EnteredAddressBuilding: $EnteredAddressBuilding,
        EnteredAddressCity: $EnteredAddressCity,
        EnteredAddressCountry: $EnteredAddressCountry,
        EnteredAddressDetails: $EnteredAddressDetails,
        EnteredAddressStreet: $EnteredAddressStreet,
        EnteredContactEmail: $EnteredContactEmail,
        EnteredContactFirstName: $EnteredContactFirstName,
        EnteredContactLastName: $EnteredContactLastName,
        EnteredContactPhone: $EnteredContactPhone,
        EnteredCustomerName: $EnteredCustomerName,
        EnteredNote: $EnteredNote,
        EnteredReferenceNumber: $EnteredReferenceNumber,
        EnteredWebsite: $EnteredWebsite,
        LinkedAccount: $LinkedAccount,
        LinkToExistingAccount: $LinkToExistingAccount,
        MakeThisASubAccount: $MakeThisASubAccount,
        SelectedAccountCurrency: $SelectedAccountCurrency,
        SelectedAccountType: $SelectedAccountType,
        SelectedCompany: $SelectedCompany,
        SelectedParentAccount: $SelectedParentAccount
      ){
        id
      }
    }
  }
}
  `;
}

export function updateAccount() {
  return gql`
    mutation modifyAccountOnCustomer($CurrentAccountID: String!, $UpdatedName: String!) {
  accounting {
    actions {
      modifyAccount(
        UpdatedName: $UpdatedName
        CurrentAccountID: $CurrentAccountID
      )
    }
  }
}
  `;
}
