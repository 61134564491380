import _ from "lodash";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import EnhancedButton from "../components/EnhancedButton";

import EnhancedCheckbox from "../components/EnhancedCheckbox";
// import EnhancedChipInput from "../components/enhanced-form/EnhancedChipInput";
import EnhancedDatePicker from "../components/enhanced-form/EnhancedDatePicker";
import EnhancedInput from "../components/enhanced-form/EnhancedInput";
import EnhancedInternationalPhoneInput from "../components/enhanced-form/EnhancedInternationalPhoneInput";
import EnhancedNumberInput from "../components/enhanced-form/EnhancedNumberInput";
import EnhancedSwitch from "../components/enhanced-form/EnhancedSwitch";
import EnhancedUploader from "../components/enhanced-form/EnhancedUploader";
import { MAIN_ONE_THEME } from "../constants";
import { useAppSelector } from "../redux/hooks";
import { validateDynamicForm } from "../utils/dynamic-utils";
import { isEmpty } from "../utils/validationUtils";
import {
  DynamicFormInputType,
  FormInputTypes,
  IDynamicForm,
} from "./index";
import EnhancedCurrencyInput from "../components/enhanced-form/EnhancedCurrencyInput";
import EnhancedInlinePaginatedChipInput from "../components/enhanced-form/EnhancedInlinePaginatedChipInput";
import EnhancedPercentageInput from "../components/enhanced-form/EnhancedPercentageInput";
import EnhancedFormattedNumberInput from "../components/enhanced-form/EnhancedFormattedNumberInput";
import EnhancedTinyMCEEditor from "../components/enhanced-form/EnhancedTinyMCEEditor";
import EnhancedLink from "../components/enhanced-form/EnhancedLink";
import NewChipsInput from "../components/enhanced-form/NewChipsInput";

const useStyles = makeStyles<{ secondaryButton: boolean }>()(
  (theme, { secondaryButton }) => ({
    form: {
      width: "100%",
    },
    submitBtn: {
      margin: "0 auto 25px auto!important",
      display: "flex!important",
    },
    footerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: secondaryButton ? "space-between" : "center",
    },
    informationTitle: {
      padding: "10px 0px",
      fontSize: "16px",
      fontFamily: "SourceSansPro-Regular",
      fontWeight: 600,
      boxSizing: "border-box",
      minHeight: "58px",
      lineHeight: "18px",
      borderBottom: "1px #E4E4E4 solid",
      marginBottom: "20px",
    },
  })
);


interface IDynamicFormState {
  values: Record<string, any>,
  errors: Record<string, string>,
  touched: Record<string, boolean>
}


const DynamicForm: React.FC<
  IDynamicForm & {
    children?: React.ReactNode;
    onCustomBlur?: (fieldName: string, values: Record<string, any>, errors: Record<string, any>) => Promise<{ values: Record<string, any>; errors: Record<string, any> }>
    onCustomValidate?: (values: Record<string, any>, errors: Record<string, any>) => Promise<Record<string, string>>
    onSearch?: (fieldName: string, value: string) => Promise<void>;
  }
> = ({
  title,
  inputs,
  buttonText,
  onSubmit,
  disableForm = false,
  submitButtonState = null,
  isSubmitButtonDisabled = false,
  children,
  secondaryButton = false,
  secondaryButtonText = "",
  onClickSecondaryButton = () => { },
  mainButtonBgColor = "",
  loadingFields = {},
  customStyles,
  noteSection,
  onChange,
  hasDoprdownSpecificBehavior = false,
  itemsPerPage,
  areNextToEachOther = false,
  popUpStyling = false,
  onCustomBlur,
  onCustomValidate,
  onSearch,
  ...restOfProps
}) => {


    const tenant = useAppSelector((state) => state.tenant);
    const [state, setState] = useState<IDynamicFormState>();
    const { classes } = useStyles({ secondaryButton });


    const [validatingForm, setValidatingForm] = useState<boolean>(false);

    useEffect(() => {
      const initialValues: Record<string, any> = state?.values || {};
      const initialErrors: Record<string, string> = state?.errors || {};
      const initialTouched: Record<string, boolean> = state?.touched || {};

      let mappedValues = _.cloneDeep(state?.values);

      if (!mappedValues) {
        mappedValues = {};

        Object.keys(inputs).forEach((inputKey) => {
          mappedValues[inputKey] = inputs[inputKey]?.value;
        });
      } else {
        Object.keys(mappedValues).forEach((inputKey) => {
          if (
            inputs[inputKey] && // Check if inputs[inputKey] is defined
            inputs[inputKey].value !== undefined // Check if inputs[inputKey].value is defined
          ) {
            if (
              (isEmpty(mappedValues[inputKey]) &&
                inputs[inputKey] &&
                inputs[inputKey].value) ||
              (!isEmpty(mappedValues[inputKey]) &&
                !isEmpty(inputs[inputKey].value))
            ) {
              if (inputs[inputKey] && inputs[inputKey].value !== undefined) {
                mappedValues[inputKey] = inputs[inputKey].value;
              }
              return;
            }

            if (
              inputs[inputKey].type === FormInputTypes.date ||
              inputs[inputKey].type === FormInputTypes.number
            ) {
              if (
                (isEmpty(mappedValues[inputKey]) ||
                  isEmpty(inputs[inputKey].value)) &&
                inputs[inputKey]
              ) {
                mappedValues[inputKey] = inputs[inputKey].value;
                return;
              }
            }
          }
        });
      }

      Object.values(inputs).forEach((input) => {
        initialValues[input.name] = mappedValues[input.name];
        initialErrors[input.name] = initialErrors[input.name] || input.error || "";
        initialTouched[input.name] = initialTouched[input.name] || !!input.error;
      });


      setState({
        values: initialValues,
        errors: initialErrors,
        touched: initialTouched
      });

    }, [inputs]);

    const onFieldBlur = async (name: string) => {
      const newPageState = _.cloneDeep(state);

      newPageState.touched[name] = true;

      if (onCustomBlur) {
        setValidatingForm(true);
        const result = await onCustomBlur(name, state?.values, state?.errors);
        setValidatingForm(false);
        newPageState.values = result.values;
        newPageState.errors = result.errors;
      }
      setState(newPageState);
    };

    const onFieldChange = async (name: string, value: any, blur: boolean = false) => {
      let errorsTemp = Object.assign({}, state.errors);
      let valuesTemp = Object.assign({}, state.values);
      let touchedTemp = Object.assign({}, state.touched);

      valuesTemp[name] = value;
      errorsTemp[name] = validateDynamicForm(value, inputs[name], valuesTemp);
      if (blur) {
        touchedTemp[name] = true;
      }
      if (onChange) {
        // to do change acrose all the onChange in all fields to return the inputs with new values
       await onChange(name, value, valuesTemp, errorsTemp, touchedTemp);
      }
      setState(
        {
          values: valuesTemp,
          errors: errorsTemp,
          touched: touchedTemp,
        }
      )
    };

    const onFieldChangeByEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
      onFieldChange(event.target.name, event.target.value);
    };

    const validate = async () => {
      const newPageState = _.cloneDeep(state);
      Object.values(inputs).forEach((input) => {
        newPageState.errors[input.name] = validateDynamicForm(
          state.values[input.name],
          input,
          state.values
        );
      });

      if (onCustomValidate) {
        newPageState.errors = await onCustomValidate(newPageState.values, newPageState.errors);
      }

      return newPageState;
    };

    const submit = async () => {
      if (!disableForm && !validatingForm) {
        setValidatingForm(true);
        const validateState = await validate();
        setValidatingForm(false);
        const isValid = Object.values(validateState.errors).every((v) => isEmpty(v));
        if (isValid) {
          onSubmit(state.values);
        } else {
          validateState.touched = {};
          Object.values(inputs).forEach((input) => {
            validateState.touched[input.name] = true;
          });

          setState(validateState);
        }
      }
    };

    const renderInitialInput = (input: DynamicFormInputType) => {

      if (state) {

        const { values, touched, errors } = state;

        const inputDisabled = input.disabled || (input.conditionalDisable && input.conditionalDisable(values)) || disableForm || validatingForm;

        const title =
          (input.required || (input.conditionalRequired && input.conditionalRequired(values))) && !isEmpty(input.title) ? `${input.title}*` : input.title;


        switch (input.type) {
          case FormInputTypes.text:
            return input.isEditor ? (
              <EnhancedTinyMCEEditor
                key={input.name}
                name={input.name}
                title={title}
                placeholder={input.placeholder}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                onChange={(name, value) => {
                  onFieldChange(name, value);
                }}
                disabled={inputDisabled}
                addButton={input.addButton}
              />
            ) : (
              <EnhancedInput
                key={input.name}
                name={input.name}
                title={title}
                type="text"
                placeholder={input.placeholder}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                onBlur={(event) => {
                  // Extract the new value when the input FOCUS OUT
                  const newValue = event.target.value;
                  // Call the onFieldBlur function for addition handling the BLUR
                  onFieldBlur(input.name);
                  // The needed logic to catch the final value
                  if (!isEmpty(newValue)) {
                    onFieldChange(input.name, newValue);
                    if (input.onChange) {
                      input.onChange(event);
                    }
                  }
                }}
                onChange={(event) => {
                  // State Update is now Simplified (lightweight operations)
                  const newValue = event.target.value;
                  onFieldChange(input.name, newValue);
                }}
                //OLD IMPLEMENTATION
                // onBlur={(v) => onFieldBlur(input.name)}
                // onChange={(v) => onFieldChange(input.name, v.target.value)}
                //OLD IMPLEMENTATION
                disabled={inputDisabled}
                description={input.description}
                multiline={input.multiline}
                material={input.material}
                customStyles={input.customStyles}
                hidden={input.hidden}
              />
            );

          case FormInputTypes.password:
            return (
              <EnhancedInput
                key={input.name}
                name={input.name}
                title={title}
                type="password"
                placeholder={input.placeholder}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                onBlur={(v) => onFieldBlur(input.name)}
                onChange={(v) => onFieldChange(input.name, v.target.value)}
                disabled={inputDisabled}
                material={input.material}
                description={input.description}
                includePasswordhint={input.includePasswordHint}
                includePasswordVisibility={input.includePasswordVisibility}
                includeCapsLockCheck={input.includeCapsLockCheck}
                customStyles={input.customStyles}
                autoComplete={input.autoCompelte}
                hidden={input.hidden}
              />
            );

          case FormInputTypes.date:
            return (
              <EnhancedDatePicker
                key={input.name}
                name={input.name}
                title={title}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                onBlur={() => {}}
                onDateChange={(dateValue) => {
                  onFieldChange(input.name, dateValue, true);
                  input.value = dateValue;
                  if (input.onChange) {
                    input.onChange(dateValue);
                  }
                }}
                // OLD IMPLEMENTATION
                // onDateChange={(v) => onFieldChange(input.name, v)}
                // OLD IMPLEMENTATION
                disabled={inputDisabled}
                material={input.material}
                minDate={input.minDate}
                maxDate={input.maxDate}
                placeholder={input.placeholder}
                format={tenant.dateFormat}
                hidden={input.hidden}
                canClearDate={input.canClearDate}
              />
            );

          case FormInputTypes.imageFile:
            return (
              <EnhancedUploader
                key={input.name}
                name={input.name}
                title={title}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                onBlur={(v) => onFieldBlur(input.name)}
                onUpload={(v) => onFieldChange(input.name, v)}
                disabled={inputDisabled}
                allowedFileTypes={input.allowedFileTypes}
                type={""}
              />
            );

          case FormInputTypes.select:
            return <span>deprecated</span>;
          case FormInputTypes.number:
            return (
              <EnhancedNumberInput
                key={input.name}
                name={input.name}
                title={title}
                placeholder={input.placeholder}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                onBlur={(event) => {
                  const newValue = event.target.value;
                  if (!isEmpty(newValue)) {
                    onFieldBlur(input.name);
                    if (input.onChange) {
                      input.onChange(event);
                    }
                  }
                }}
                onChange={(v) => onFieldChangeByEvent(v)}
                disabled={inputDisabled}
                material={input.material}
                minValue={input.minNumber}
                maxValue={input.maxNumber}
                hasBetweenValidation={input.hasBetweenValidation}
                hidden={input.hidden}
                hasPrefix={input.hasPrefix}
                prefixValue={input.prefixValue}
                maxDecimalPrecision={input.maxDecimalPrecision}
              />
            );
          case FormInputTypes.formattedNumber:
            return (
              <EnhancedFormattedNumberInput
                key={input.name}
                name={input.name}
                title={title}
                placeholder={input.placeholder}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                onBlur={(event) => {
                  const newValue = event.target.value;
                  onFieldBlur(input.name);
                  if (!isEmpty(newValue)) {
                    onFieldChange(input.name, newValue);
                    if (input.onChange) {
                      input.onChange(event);
                    }
                  }
                }}
                onChange={(v) => onFieldChangeByEvent(v)}
                disabled={inputDisabled}
                maxDecimalPercision={input.maxDecimalPercision}
                type={""}
                onFocus={(v) => onFieldBlur(input.name)}
                hidden={input.hidden}
                minValue={input.minValue}
                maxValue={input.maxValue}
              />
            );
          case FormInputTypes.percentage:
            return (
              <EnhancedPercentageInput
                key={input.name}
                name={input.name}
                title={title}
                placeholder={input.placeholder}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                onBlur={(event) => {
                  // Extract the new value when the input FOCUS OUT
                  const newValue = event.target.value;
                  // Call the onFieldBlur function for addition handling the BLUR
                  onFieldBlur(input.name);
                  // The needed logic to catch the final value
                  if (!isEmpty(newValue)) {
                    onFieldChange(input.name, newValue);
                    if (input.onChange) {
                      input.onChange(event);
                    }
                  }
                }}
                onChange={(v) => onFieldChangeByEvent(v)}
                disabled={inputDisabled}
                material={input.material}
                hasBetweenValidation={input.hasBetweenValidation}
                hidden={input.hidden}
              />
            );

          case FormInputTypes.switch:
            return (
              <EnhancedSwitch
                key={input.name}
                name={input.name}
                title={title}
                value={values[input.name]}
                onBlur={(v) => onFieldBlur(input.name)}
                onChange={(name, val) => onFieldChange(input.name, val)}
                disabled={inputDisabled}
                required={input.required}
                information={input.information}
                className={input.className}
              />
            );

          case FormInputTypes.multiSelect:
            return <span>deprecated</span>;
          case FormInputTypes.chips:
            return hasDoprdownSpecificBehavior && input.hasPagination ? (
              <EnhancedInlinePaginatedChipInput
                key={input.name}
                name={input.name}
                title={title}
                placeholder={input.placeholder}
                value={values[input.name]}
                preselectedValues={input.preselectedValues}
                error={touched[input.name] ? errors[input.name] : ""}
                onChange={(v) => {
                  onFieldChange(input.name, v);
                  if (!isEmpty(v) && input.onSelect) {
                    input.onSelect(v as string);
                  }
                  const newValue = v;
                  onFieldBlur(input.name);
                  if (!isEmpty(newValue)) {
                    onFieldChange(input.name, newValue);
                    if (input.onChange) {
                      input.onChange(newValue);
                    }
                  }
                }}
                disabled={inputDisabled}
                customStyles={input.customStyles}
                selectOptions={input.selectOptions}
                required={input.required}
                freeSolo={input.freeSolo}
                multiple={input.multiple}
                material={input.material}
                loader={loadingFields[input.name] || false}
                showSelectAll={input.showSelectAll}
                strongStyledOption={input.strongStyledOption}
                hidden={input.hidden}
                itemsPerPage={input.itemsPerPage}
              />
            ) : (
              <NewChipsInput
                key={input.name}
                name={input.name}
                title={title}
                multiple={input.multiple}
                required={input.required}
                value={values[input.name]}
                values={values[input.name] || []}
                disabled={inputDisabled}
                items={input.selectOptions}
                material={input.material}
                onSearch={onSearch ? async (v) => {
                  if(onSearch){
                    await onSearch(input.name, v)
                  }
                } : null}
                placeholder={input.placeholder}
                showSelectAll={input.showSelectAll}
                error={touched[input.name] ? errors[input.name] : ""}
                onChange={(v) => {
                  onFieldChange(input.name, v);
                  if (!input.multiple) {
                    if (!isEmpty(v) && input.onSelect) {
                      input.onSelect(v as string);
                    }
                  }
                }}
              />
            );
          case FormInputTypes.phoneNumber:
            return (
              <EnhancedInternationalPhoneInput
                key={input.name}
                name={input.name}
                title={title}
                placeholder={input.placeholder}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                onChange={(v) => onFieldChange(input.name, v)}
                onBlur={(v) => onFieldBlur(input.name)}
                disabled={inputDisabled}
                countriesToShow={input.countriesToShow}
                disableDropDown={input.disableDropDown}
                editCountryCode={input.editCountryCode}
                defaultCountry={input.defaultCountry}
                customFormat={input.customFormat}
                material={input.material}
              />
            );
          case FormInputTypes.fileuploader:
            return (
              <EnhancedUploader
                key={input.name}
                name={input.name}
                title={title}
                placeholder={input.placeholder}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                // onBlur={(v) => onFieldBlur(input.name)}
                onUpload={(v) => onFieldChange(input.name, v, true)}
                disabled={inputDisabled}
                allowedFileTypes={input.allowedFileTypes}
                type={""}
                // downloadLinks={input.downloadLinks || []}
                iconUrl={`${tenant.cdnUrl}/icons/upload-field-secondary.svg`}
              />
            );
          case FormInputTypes.checkbox:
            return (
              <EnhancedCheckbox
                key={input.name}
                name={input.name}
                title={title}
                checked={values[input.name]}
                onChange={(name, val) => onFieldChange(input.name, val)}
                disabled={inputDisabled}
                className={input.className}
                classes={input.classes}
              />
            );
          case FormInputTypes.currency:
            return (
              <EnhancedCurrencyInput
                key={input.name}
                name={input.name}
                title={title}
                placeholder={input.placeholder}
                value={values[input.name]}
                error={touched[input.name] ? errors[input.name] : ""}
                onBlur={(v) => onFieldBlur(input.name)}
                onChange={(v) => onFieldChangeByEvent(v)}
                disabled={inputDisabled}
                type={""}
                currencyTitle="$"
                currencyIcon={tenant.cdnUrl + "/icons/dollar-primary.svg"}
                onFocus={(v) => onFieldBlur(input.name)}
                hidden={input.hidden}
              />
            );
          case FormInputTypes.link:
            return (
              <EnhancedLink
                key={input.name}
                name={input.name}
                title={title}
                onClick={() => {
                  onFieldChange(input.name, null);
                  if (input.onClick) input.onClick();
                }}
                hidden={input.hidden}
              />
            );
          default:
            return null;
        }
      }



      return <></>
    };


    const renderInput = (input: DynamicFormInputType) => {
      if (state) {
        const hidden = input.hidden || (input.conditionalHidden && input.conditionalHidden(state.values));

        if(hidden){
          return <></>
        }
        return <div key={input.name}>{renderInitialInput(input)}</div>
      }

      return <></>
    }

    return state ? (
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        {!isEmpty(title) && (
          <div className={classes.informationTitle}>
            <span>{title}</span>
          </div>
        )}
        {popUpStyling ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {Object.values(inputs).map((input) =>
              input.popUpStyling ? (
                <div
                  style={{
                    width: "45%",
                    minWidth: "45%",
                    maxWidth: "45%",
                    marginRight: input.popUpStyling ? "20px" : "0",
                  }}
                >
                  {renderInput(input)}
                </div>
              ) : (
                <div style={{ width: "100%" }}>{renderInput(input)}</div>
              )
            )}
          </div>
        ) : (
          Object.values(inputs).map((input) => renderInput(input))
        )}

        {children}
        {noteSection}
        <div className={classes.footerContainer}>
          {secondaryButton && (
            <EnhancedButton
              onClick={() => onClickSecondaryButton()}
              disabled={disableForm}
              variant="contained"
              className={classes.submitBtn}
              state={submitButtonState}
            >
              {secondaryButtonText}
            </EnhancedButton>
          )}

          <EnhancedButton
            type="submit"
            disabled={disableForm || isSubmitButtonDisabled || validatingForm}
            variant="contained"
            backgroundColor={
              mainButtonBgColor
                ? mainButtonBgColor
                : MAIN_ONE_THEME.palette.primary5.main
            }
            color="rgba(255, 255, 255, 1)"
            style={{ width: "108px" }}
            state={submitButtonState}
            className={customStyles && customStyles.submitButtonStyles}
          >
            {buttonText}
          </EnhancedButton>
        </div>
      </form>
    ) : (
      <></>
    );
  };

export default DynamicForm;
