// TODO Refactor this component to support enabling and disabling formatting
import { makeStyles } from "tss-react/mui";
import React from "react";
import { IEnhancedFormInputBaseProps } from ".";

export interface IEnhancedLinkProps
  extends IEnhancedFormInputBaseProps,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {
  name: string;
  title: string;
  onClick: () => void;
  hidden?: boolean;
}

const useStyles = makeStyles()(() => ({
  container: {
    display: "block",
    marginBottom: "27px",
  },
  link: {
    color: "rgba(211, 13, 43, 0.8)",
    cursor: "pointer",
    textAlign: "left",
    margin: "0 0 27px",
    fontFamily: "SourceSansPro-Regular",
    fontSize: "14px",
    textDecoration: "underline",
  },
}));

const EnhancedLink: React.FC<IEnhancedLinkProps> = ({
  title,
  onClick,
  hidden = false,
}) => {
  const { classes } = useStyles();

  if (hidden) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <a onClick={() => onClick()} className={classes.link}>
        {title}
      </a>
    </div>
  );
};

export default EnhancedLink;
