import React from "react";
import { makeStyles } from "tss-react/mui";
import { EnhancedDisplayType } from "../../../components/enhanced-form/EnhancedDisplay";
import { IEntityInfoView } from "../../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../../components/widgets/entity-info/EntityInfoWidget";
import { capitalizeFirstLetterLowerOthers } from "../../../utils/formatting-utils";
import { isEmpty } from "../../../utils/validationUtils";
import { IProposalDetailsSummary } from "./index2";

export const useStyles = makeStyles()(() => ({
  paperClass: {
    boxShadow: "none !important",
  },
  updateBtn: {
    margin: "15px auto 0",
  },
}));

interface IPolicyDetailsEntityInfoProps {
  data: IProposalDetailsSummary;
}

const PolicyDetailsEntityInfo: React.FC<IPolicyDetailsEntityInfoProps> = ({
  data,
}) => {
  const newEntityView: IEntityInfoView = {
    iconUrl: "",
    name: "Company",
    socialMedia: {
      facebook: "",
      twitter: "",
      linkedIn: "",
    },
    title: data.ProposalName,
    sections: [
      {
        name: "BasicInformation",
        title: "",
        properties: {
          StatusReason: {
            multiline: false,
            value: data.StatusReason,
            name: "Status Reason",
            title: "Status Reason",
          },
          PolicyNumber: {
            multiline: false,
            value: data?.PricingOption?.PolicyNumber,
            name: "PolicyNumber",
            title: "Policy Number",
            hidden: data.Status != "CLOSED_WON",
            type: EnhancedDisplayType.RelativeLink,
            url: `/production/policies/${data.PricingOption.PolicyId}`,
          },
          Line: {
            multiline: false,
            value: data.LineId.ExternalCode + " - " + data.LineId.Name,
            name: "Line",
            title: "Line",
          },
          Subline: {
            multiline: false,
            value: data.SubLineId.ExternalCode + " - " + data.SubLineId.Name,
            name: "Subline",
            title: "Subline",
          },
          RelatedCompany: {
            multiline: false,
            value: data?.PrimaryBPID?.RelatedCompany,
            name: "RelatedCompany",
            title: "Related Company",
          },
        },
      },
      {
        name: "Section2",
        title: "",
        hidden: ![51, 50, 4].includes(Number(data.LineId.ExternalCode)),
        properties: {
          AcalCategory: {
            multiline: false,
            value: data?.PlanID?.AcalCategory?.Title,
            name: "AcalCategory",
            title: "Acal Category",
            hidden: isEmpty(data?.PlanID?.AcalCategory?.Code),
          },
          Scratching: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(data?.PlanID?.Scratching),
            name: "Scratching",
            title: "Scratching",
            hidden: isEmpty(data?.PlanID?.Scratching),
          },
          SOSService: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              data?.PlanID?.SOSService || "",
              "_"
            ),
            name: "SOSService",
            title: "SOS Service",
            hidden: isEmpty(data?.PlanID?.SOSService),
          },
          ReplacementCarApplied: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              data?.PlanID?.ReplacementCarApplied || ""
            ),
            name: "ReplacementCarApplied",
            title: "Replacement Car Applied",
            hidden: isEmpty(data?.PlanID?.ReplacementCarApplied),
          },
          ReplacementCarValue: {
            multiline: false,
            value: `${
              data?.PolicyCurrency?.Symbol
            } ${data?.PlanID?.ReplacementCarValue?.toString()}`,
            name: "ReplacementCarValue",
            title: "Replacement Car Value",
            hidden: isEmpty(data?.PlanID?.ReplacementCarValue),
          },
          ReplacementCarDays: {
            multiline: false,
            value: `${data?.PlanID?.ReplacementCarDays?.toString()} days`,
            name: "ReplacementCarDays",
            title: "Replacement Car Days",
            hidden: isEmpty(data?.PlanID?.ReplacementCarDays),
          },
          // NoDepreciationYear: {
          //   multiline: false,
          //   value: data?.PlanID?.NoDepreciationYear,
          //   name: 'NoDepreciationYear',
          //   title: 'Depreciation Year',
          //   hidden: isEmpty(data?.PlanID?.NoDepreciationYear)
          // },
        },
      },
      {
        name: "Section3",
        title: "",
        properties: {
          BusinessUser: {
            multiline: false,
            value:
              data.BusinessUserID.Code + " - " + data.BusinessUserID.FullName,
            name: "BusinessUser",
            title: "Business User",
          },
          MainBP: {
            multiline: false,
            value: data.PrimaryBPID.Code + " - " + data.PrimaryBPID.FullName,
            name: "MainBP",
            title: "Main Business Partner",
          },
        },
      },
      {
        name: "Section4",
        title: "",
        properties: {
          CreatedBy: {
            multiline: false,
            value: data.createdBy,
            name: "CreatedBy",
            title: "Created By",
          },
          CreatedOn: {
            multiline: false,
            value: data.createdOn,
            name: "CreatedOn",
            title: "Created On",
            type: EnhancedDisplayType.Date,
          },
          ModifiedBy: {
            multiline: false,
            value: data.lastModifiedBy,
            name: "ModifiedBy",
            title: "Modified By",
          },
          ModifiedOn: {
            multiline: false,
            value: data.createdOn,
            name: "ModifiedOn",
            title: "Modified On",
            type: EnhancedDisplayType.Date,
          },
        },
      },
    ],
  };

  return (
    <EntityInfoWidget
      background="rgb(193, 0, 0)"
      classification={{ classifications: {}, value: "" }}
      progress={100}
      supportsClassification={true}
      supportsProgressBar={true}
      // title={businessUserDetailsInfo.name}
      // title={"Proposal"}
      title={data.ProposalName}
      // view={entityView}
      view={newEntityView}
    ></EntityInfoWidget>
  );
};

export default PolicyDetailsEntityInfo;
