import {
  convertToFixedNumber,
  numberToPercentage,
} from "../../../../../utils/helper-utils";
import { IMedicalInsuredDetails } from "./custom-widgets/insured-details";
import { IProposalDetailsSummary } from "./index2";

export interface IPayerData {
  payerId: string;
  fullName?: string;
  payerAddress: string;
  ownerIsDifferent: boolean;
}

export interface IOwnerData {
  ownerId?: string;
  fullName?: string;
  ownerAddress?: string;
}

export interface IInsuredData {
  id: string;
  order: string;
  fullName?: string;
  address?: string;
  addressDetails?: string;
}

export interface IBeneficiaryData {
  id: string;
  address: string;
  percentage: number;
  fullName?: string;
  addressDetails?: string;
}

export interface IPolicyPersons {
  payer: IPayerData;
  owner?: IOwnerData;
  insured: IInsuredData[];
  beneficiaries: IBeneficiaryData[];
}

export interface IProposalPageFormData {
  policyPersons: {
    payer: IPayerData;
    owner?: IOwnerData;
    insured: IInsuredData[];
    beneficiaries: IBeneficiaryData[];
  };
  costs: IProposalCostCharges;
  medicalDetails: IProposalMedicalDetails;
  insuredDetails: {
    principal: IMedicalInsuredDetails;
    insured: IMedicalInsuredDetails[];
  };
}
export interface IProposalCover {
  isMain: boolean;
  isMandatory: boolean;
  isEditable: boolean;
  policyCover: string;
  coverPremiumType: string;
  coverPremiumPercentage?: number;
  coverPremiumValue?: number;
  currency: string;
  coverSumInsured?: number;
  sumInsuredIsAdditive: boolean;
  excessOnClaimType: string;
  excessOnClaimAmount?: number;
  excessOnClaimPercentage?: number;
  excessOnClaimDays?: number;
  name?: string;
  clauseID: string;
  coverEffectiveFrom: string;
  coverEffectiveTo: string;
}

export interface IProposalCostCharges {
  policyCost: number;
  chargesPercentage: number;
  tpaFeesType: string;
  tpaFeesAmount?: number;
  tpaFeesPercentage?: number;
  tpaFeesApplicableOn: string;
}

export interface IProposalMedicalDetails {
  class: string;
  level: string;
  cnss: string;
  policyCover: string[];
}

export interface IProposalPageFormState {
  values: IProposalPageFormData;
  errors: {
    policyPersons: {
      payer: Record<string, string>;
      owner: Record<string, string>;
    };
    costs: Record<string, string>;
    medicalDetails: Record<string, string>;
  };
  touched: {
    policyPersons: {
      payer: Record<string, boolean>;
      owner: Record<string, boolean>;
    };
    costs: Record<string, boolean>;
    medicalDetails: Record<string, boolean>;
  };
}

export const getProposalPageFormState = (
  summary?: IProposalDetailsSummary,
  policyPersons?: IPolicyPersons
): IProposalPageFormState => ({
  values: {
    policyPersons,
    costs: {
      policyCost: convertToFixedNumber(summary?.PricingOption.PolicyCost),
      chargesPercentage: numberToPercentage(
        summary?.PricingOption.ChargesPercentage
      ),
      tpaFeesType: summary?.PricingOption.TPAFeesType,
      tpaFeesAmount: convertToFixedNumber(summary?.PricingOption.TPAFeesAmount),
      tpaFeesPercentage: numberToPercentage(
        summary?.PricingOption.TPAFeesPercentage
      ),
      tpaFeesApplicableOn: summary?.PricingOption.TPAFeesApplicableOn,
    },
    medicalDetails: {
      class: "",
      level: "",
      cnss: "",
      policyCover: [],
    },
    insuredDetails: {
      principal: {
        insuredID: "",
        insuredAddressID: "",
        grLimitDays: null as any,
        cardNumber: "",
        continuity: false,
        tpaReference1: "",
        tpaReference2: "",
        exclusions: "",
        remarks: "",
      },
      insured: [],
    },
  },
  errors: {
    policyPersons: {
      payer: {
        payerId: "",
        payerAddress: "",
        ownerIsDifferent: "",
      },
      owner: {
        ownerId: "",
        ownerAddress: "",
      },
    },
    costs: {},
    medicalDetails: {
      class: "",
      level: "",
      cnss: "",
      policyCover: "",
    },
  },
  touched: {
    policyPersons: {
      payer: {
        payerId: false,
        payerAddress: false,
        ownerIsDifferent: false,
      },
      owner: {
        ownerId: false,
        ownerAddress: false,
      },
    },
    costs: {},
    medicalDetails: {
      class: false,
      level: false,
      cnss: false,
      policyCover: false,
    },
  },
});
