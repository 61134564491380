import _ from "lodash";
import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import WidgetPaper from "../../../components/common/WidgetPaper";
import WidgetSection from "../../../components/common/WidgetSection";
import EnhancedChipInput from "../../../components/enhanced-form/EnhancedChipInput";
import {
  IProposalMedicalDetails,
  IProposalPageFormState,
} from "../../../modules/production/proposal/page/medical/form";
import { IProposalDetailsSummary } from "../../../modules/production/proposal/page/expat/index2";
import { validateMedicalDetailsField } from "../../../modules/production/proposal/page/medical/validation";

interface IProposalMedicalDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  defaultPlanCoversList: Record<string, any>;
  allowPlanCoverEdits: boolean;
  status: string;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
  },
  section: {
    padding: "23px 0 25px",
  },
  fieldRow: {
    display: "grid",
    gridTemplateColumns: `repeat(4, 23%)`,
    gap: "2%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

const ProposalMedicalDetailsWidget: React.FC<
IProposalMedicalDetailsWidgetProps
> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  data,
  lovs,
}) => {
  const { classes } = useStyles();

  const values = pageState.values.medicalDetails;
  const errors = pageState.errors.medicalDetails;
  const touched = pageState.touched.medicalDetails;

  const initialize = async () => {};

  useEffect(() => {
    initialize();
  }, []);

  // const onFieldBlur = async (fieldName: string) => {
  //   const newPageState = _.cloneDeep(pageState);
  //   newPageState.touched.expatDetails[fieldName] = true;
  //   onPageStateUpdate(newPageState);
  // };

  const onFieldUpdate = (
    fieldName: keyof IProposalMedicalDetails,
    value: any,
    touchedField = false
  ) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.medicalDetails[fieldName] = value;
    newPageState.errors.medicalDetails[fieldName] = validateMedicalDetailsField(
      fieldName,
      value
    );
    if (touchedField) {
      newPageState.touched.medicalDetails[fieldName] = true;
    }
    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper style={{ marginTop: "1em" }}>
      <WidgetSection
        title="Medical Details"
        hasTitleSpecificDesign={false}
        classes={{
          container: classes.section,
        }}
        useSeparator={false}
      >
        <div className={classes.fieldRow}>
          <EnhancedChipInput
            key="class"
            name="class"
            title="Class*"
            placeholder="Class"
            value={values.class}
            error={touched.class ? errors.class : ""}
            onChange={(v) => onFieldUpdate("class", v, true)}
            disabled={
              ["CLOSED_WON", "CLOSED_LOST"].includes(data.Status) || disabledForm
            }
            selectOptions={lovs.classes}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
          />

          <EnhancedChipInput
            key="level"
            name="level"
            title="Level*"
            placeholder="Level"
            value={values.level}
            error={touched.level ? errors.level : ""}
            onChange={(v) => onFieldUpdate("level", v, true)}
            disabled={
              ["CLOSED_WON", "CLOSED_LOST"].includes(data.Status) || disabledForm
            }
            selectOptions={lovs.levels}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
          />

          <EnhancedChipInput
            key="cnss"
            name="cnss"
            title="CNSS*"
            placeholder="CNSS"
            value={values.cnss}
            error={touched.cnss ? errors.cnss : ""}
            onChange={(v) => onFieldUpdate("cnss", v, true)}
            disabled={
              ["CLOSED_WON", "CLOSED_LOST"].includes(data.Status) || disabledForm
            }
            selectOptions={lovs.cnssOptions}
            required={true}
            multiple={false}
            material={true}
            canClearSingleValueSelection={false}
          />

          <EnhancedChipInput
            key="policyCover"
            name="policyCover"
            title="Policy Cover*"
            placeholder="Policy Cover"
            value={values.policyCover}
            error={touched.policyCover ? errors.policyCover : ""}
            onChange={(v) => onFieldUpdate("policyCover", v, true)}
            disabled={
              ["CLOSED_WON", "CLOSED_LOST"].includes(data.Status) || disabledForm
            }
            selectOptions={lovs.policyCovers}
            required={true}
            multiple={true} // Enable multi-select
            material={true}
            canClearSingleValueSelection={false}
          />
        </div>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ProposalMedicalDetailsWidget;
