import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery, useQuery } from "@apollo/client";
import { filterQuery, listQuery } from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { actions, filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import dayjs from "dayjs";

const CurrencyExchangeRatesPage: React.FC = () => {
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState<boolean>(false);

  const FILTER_SESSION_KEY = "currencyExchangeRatesFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
        currency: [],
        date: null,
      };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [booted, setBooted] = useState<boolean>(false);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getTaxationsLazy] = useLazyQuery(listQuery());

  const loadList = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues,
  ) => {
    setLoadingState(true); // Set loading state

    const dateValue = dayjs(filterV?.date).isValid() ? dayjs(filterV?.date).toDate()?.toString() : null;

    const result = await getTaxationsLazy({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: tableData.pageSize,
        selectedCurrencies:
          filterV?.currency && filterV.currency.length > 0
            ? filterV.currency
            : [],
        selectedDate:
          dateValue && dateValue.length > 0
            ? dayjs(dateValue).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
        isSelectedDate:
          dateValue && dateValue.length > 0 ? true : false,
      },
      errorPolicy: "all",
    });

    const mappedPlanList = await mapToListingData(result.data);

    if (result.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }

    setTableData({ ...mappedPlanList, pageNumber: currentPage, pageSize });
    setLoadingState(false); // Reset loading state

    return result;
  };

  const initialize = async () => {
    try {
      await loadList();
      const filterDataResponse = await filterResponse.refetch();
      const lovs = dataToLovs(filterDataResponse.data);
      const savedFilters = getFilter(FILTER_SESSION_KEY) || filterValues;

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterValues(savedFilters);
      setFilterSections(newFilterSections);
      setBooted(true);
    } catch (error) {
      console.error("Error initializing data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const filterResponse = useQuery(filterQuery(), {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (filterResponse?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (filterResponse?.data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = dataToLovs(filterResponse.data);
      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);
    }
  }, [filterResponse]);

  const handlePageChange = async (
    page: number,
    pageSize: number
  ) => {
    await loadList(page, pageSize, filterValues);
  };

  actions[0].onClick = () => {
    navigate(`/configuration/currency-exchange-rate/add-ons`);
  };

  const renderMainChildren = () => {
    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedTable
            title="Currency Exchange Rate"
            name="currencyExchangeRate"
            orderByAscendingByDefault
            inline={false}
            data={tableData}
            headers={headers}
            entityIdColumnName={"id"}
            handlePageChange={(page: number) =>
              handlePageChange(page, tableData.pageSize)
            }
            handleRowsPerPageChange={(pageSize: number) =>
              handlePageChange(0, pageSize)
            }
            currentPage={tableData.pageNumber}
            hideToolbar
            usePagination
            disableSelection
            actions={actions}
            loader={loadingState}
          />
        </div>
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    if (_.isEqual(v, filterValues)) {
      return;
    }
    setFilter(v, FILTER_SESSION_KEY);
    loadList(0, tableData.pageSize, v);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={""}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
          disabled={loadingState}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={!booted}
      name={"Applications"}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default CurrencyExchangeRatesPage;
