import _ from "lodash";
import React, { useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import WidgetPaper from "../../../components/common/WidgetPaper";
import WidgetSection from "../../../components/common/WidgetSection";
import EnhancedInput from "../../../components/enhanced-form/EnhancedInput";
import {
  IProposalMarineHullDetails,
  IProposalPageFormState,
} from "../../../modules/production/proposal/page/generic/form";
import { validateMarineHullDetailsField } from "../../../modules/production/proposal/page/generic/validation";
import { IProposalDetailsSummary } from "../../../modules/production/proposal/page/generic/index2";

interface IProposalMarineHullDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  status: string;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
    backgroundColor: "#F9F9F9",
    border: "1px solid #E5E5E5",
    borderRadius: "4px",
    padding: "4px 20px 20px 23px",
    marginBottom: "14px",
  },
  section: {
    padding: "23px 0 25px",
  },
  fieldRow: {
    display: "grid",
    gridTemplateColumns: `repeat(4, 23%)`,
    gap: "2%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  fieldRowBodyDetails: {
    display: "grid",
    gridTemplateColumns: "2.75fr 2.75fr 2.75fr 3.75fr",
    gap: "2%",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  fullFieldRow: {
    gridColumn: "1 / -1",
    width: "100%",
  },
  label: {
    color: "#000000",
    margin: "0px!important",
    padding: "0px!important",
    fontSize: "14px !important",
    fontFamily: "SourceSansPro-SemiBold !important",
  },
  headerContainer: {
    border: 0,
    padding: 0,
  },
  title: {
    fontSize: "16px !important",
  },
}));

const ProposalMarineHullDetailsWidget: React.FC<
  IProposalMarineHullDetailsWidgetProps
> = ({ pageState, onPageStateUpdate, disabledForm, data, lovs, status }) => {
  const { classes } = useStyles();

  const values = pageState.values.marineHullDetails;
  const errors = pageState.errors.marineHullDetails;
  const touched = pageState.touched.marineHullDetails;

  const initialize = async () => {};

  useEffect(() => {
    initialize();
  }, []);

  const onFieldBlur = async (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.marineHullDetails[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IProposalMarineHullDetails,
    value: any,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);
    (newPageState.values.marineHullDetails as any)[fieldName] = value;
    (newPageState.errors.marineHullDetails as any)[fieldName] =
      validateMarineHullDetailsField(fieldName, value);

    if (touched) {
      newPageState.touched.marineHullDetails[fieldName] = true;
    }

    onPageStateUpdate(newPageState);
  };

  return (
    <WidgetPaper style={{ marginTop: "1em" }}>
      <WidgetSection
        title="Marine Hull Details"
        useSeparator={false}
        classes={{
          container: classes.container,
          headerContainer: classes.headerContainer,
        }}
        hasTitleSpecificDesign={false}
      >
        <WidgetSection>
          <div className={classes.fieldRow}>
            <EnhancedInput
              key="vesselName"
              name="vesselName"
              title="Vessel Name*"
              placeholder="Vessel Name"
              value={values.vesselName}
              error={touched.vesselName ? errors.vesselName : ""}
              onBlur={() => onFieldBlur("vesselName")}
              onChange={(v) => onFieldUpdate("vesselName", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              customStyles={{
                labelStyles: classes.label,
              }}
            />

            <EnhancedInput
              key="connectionPort"
              name="connectionPort"
              title="Connection Port*"
              placeholder="Connection Port"
              value={values.connectionPort}
              error={touched.connectionPort ? errors.connectionPort : ""}
              onBlur={() => onFieldBlur("connectionPort")}
              onChange={(v) => onFieldUpdate("connectionPort", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              customStyles={{
                labelStyles: classes.label,
              }}
            />

            <EnhancedInput
              key="territorialWaters"
              name="territorialWaters"
              title="Territorial Waters*"
              placeholder="Territorial Waters"
              value={values.territorialWaters}
              error={touched.territorialWaters ? errors.territorialWaters : ""}
              onBlur={() => onFieldBlur("territorialWaters")}
              onChange={(v) =>
                onFieldUpdate("territorialWaters", v.target.value)
              }
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              customStyles={{
                labelStyles: classes.label,
              }}
            />
          </div>
        </WidgetSection>
        <WidgetSection
          title="Body Details"
          classes={{
            headerContainer: classes.headerContainer,
            title: classes.title,
          }}
          narrowSpacing
        >
          <div className={classes.fieldRowBodyDetails}>
            <EnhancedInput
              key="usage"
              name="usage"
              title="Usage*"
              placeholder="Usage"
              value={values.usage}
              error={touched.usage ? errors.usage : ""}
              onBlur={() => onFieldBlur("usage")}
              onChange={(v) => onFieldUpdate("usage", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              customStyles={{
                labelStyles: classes.label,
              }}
            />

            <EnhancedInput
              key="builder"
              name="builder"
              title="Builder*"
              placeholder="Builder"
              value={values.builder}
              error={touched.builder ? errors.builder : ""}
              onBlur={() => onFieldBlur("builder")}
              onChange={(v) => onFieldUpdate("builder", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              customStyles={{
                labelStyles: classes.label,
              }}
            />

            <EnhancedInput
              key="constructionYear"
              name="constructionYear"
              title="Year of Construction*"
              placeholder="Year of Construction"
              value={values.constructionYear}
              error={touched.constructionYear ? errors.constructionYear : ""}
              onBlur={() => onFieldBlur("constructionYear")}
              onChange={(v) =>
                onFieldUpdate("constructionYear", v.target.value)
              }
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              customStyles={{
                labelStyles: classes.label,
              }}
            />

            <EnhancedInput
              key="registeryPort"
              name="registeryPort"
              title="Port of Registery & Number*"
              placeholder="Port of Registery & Number"
              value={values.registeryPort}
              error={touched.registeryPort ? errors.registeryPort : ""}
              onBlur={() => onFieldBlur("registeryPort")}
              onChange={(v) => onFieldUpdate("registeryPort", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              customStyles={{
                labelStyles: classes.label,
              }}
              type="text"
            />

            <EnhancedInput
              key="length"
              name="length"
              title="Length"
              placeholder="Length"
              value={values.length}
              error={touched.length ? errors.length : ""}
              onBlur={() => onFieldBlur("length")}
              onChange={(v) => onFieldUpdate("length", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              customStyles={{
                labelStyles: classes.label,
              }}
              type="text"
            />

            <EnhancedInput
              key="weight"
              name="weight"
              title="Weight"
              placeholder="Weight"
              value={values.weight}
              error={touched.weight ? errors.weight : ""}
              onBlur={() => onFieldBlur("weight")}
              onChange={(v) => onFieldUpdate("weight", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              customStyles={{
                labelStyles: classes.label,
              }}
              type="text"
            />

            <EnhancedInput
              key="height"
              name="height"
              title="Height"
              placeholder="Height"
              value={values.height}
              error={touched.height ? errors.height : ""}
              onBlur={() => onFieldBlur("height")}
              onChange={(v) => onFieldUpdate("height", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              customStyles={{
                labelStyles: classes.label,
              }}
              type="text"
            />
          </div>
        </WidgetSection>
        <WidgetSection
          title="Engine Details"
          classes={{
            headerContainer: classes.headerContainer,
            title: classes.title,
          }}
          narrowSpacing
        >
          <div className={classes.fieldRow}>
            <EnhancedInput
              key="engineType"
              name="engineType"
              title="Type*"
              placeholder="Type"
              value={values.engineType}
              error={touched.engineType ? errors.engineType : ""}
              onBlur={() => onFieldBlur("engineType")}
              onChange={(v) => onFieldUpdate("engineType", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              customStyles={{
                labelStyles: classes.label,
              }}
            />

            <EnhancedInput
              key="engineNumber"
              name="engineNumber"
              title="Engine Number*"
              placeholder="Engine Number"
              value={values.engineNumber}
              error={touched.engineNumber ? errors.engineNumber : ""}
              onBlur={() => onFieldBlur("engineNumber")}
              onChange={(v) => onFieldUpdate("engineNumber", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              customStyles={{
                labelStyles: classes.label,
              }}
            />

            <EnhancedInput
              key="engineYearBuilt"
              name="engineYearBuilt"
              title="Year Built*"
              placeholder="Year Built"
              value={values.engineYearBuilt}
              error={touched.engineYearBuilt ? errors.engineYearBuilt : ""}
              onBlur={() => onFieldBlur("engineYearBuilt")}
              onChange={(v) => onFieldUpdate("engineYearBuilt", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              customStyles={{
                labelStyles: classes.label,
              }}
            />

            <EnhancedInput
              key="engineHorsePower"
              name="engineHorsePower"
              title="Horse Power*"
              placeholder="Horse Power"
              value={values.engineHorsePower}
              error={touched.engineHorsePower ? errors.engineHorsePower : ""}
              onBlur={() => onFieldBlur("engineHorsePower")}
              onChange={(v) =>
                onFieldUpdate("engineHorsePower", v.target.value)
              }
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              customStyles={{
                labelStyles: classes.label,
              }}
              type="text"
            />

            <EnhancedInput
              key="engineMaxDesignedSpeed"
              name="engineMaxDesignedSpeed"
              title="Max. Designed Speed"
              placeholder="Max. Designed Speed"
              value={values.engineMaxDesignedSpeed}
              error={
                touched.engineMaxDesignedSpeed
                  ? errors.engineMaxDesignedSpeed
                  : ""
              }
              onBlur={() => onFieldBlur("engineMaxDesignedSpeed")}
              onChange={(v) =>
                onFieldUpdate("engineMaxDesignedSpeed", v.target.value)
              }
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              customStyles={{
                labelStyles: classes.label,
              }}
              type="text"
            />

            <EnhancedInput
              key="engineFuel"
              name="engineFuel"
              title="Fuel"
              placeholder="Fuel"
              value={values.engineFuel}
              error={touched.engineFuel ? errors.engineFuel : ""}
              onBlur={() => onFieldBlur("engineFuel")}
              onChange={(v) => onFieldUpdate("engineFuel", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              customStyles={{
                labelStyles: classes.label,
              }}
            />

            <EnhancedInput
              key="remarks"
              name="remarks"
              title="Remarks"
              placeholder="Remarks"
              value={values.remarks}
              error={touched.remarks ? errors.remarks : ""}
              onBlur={() => onFieldBlur("remarks")}
              onChange={(v) => onFieldUpdate("remarks", v.target.value)}
              disabled={
                disabledForm || ["CLOSED_WON", "CLOSED_LOST"].includes(status)
              }
              material={true}
              type="text"
              className={classes.fullFieldRow}
              customStyles={{
                labelStyles: classes.label,
              }}
            />
          </div>
        </WidgetSection>
      </WidgetSection>
    </WidgetPaper>
  );
};

export default ProposalMarineHullDetailsWidget;
