import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery, useQuery } from "@apollo/client";
import { filterQuery, listQuery } from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _ from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import {
  IEnhancedTableMenuItem,
  IEnhanceTableHeaderClickable,
} from "../../components/enhanced-table";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import dayjs from "dayjs";
import { useAppSelector } from "../../redux/hooks";
import PolicyCoverDrawer from "../../forms/policy-cover-drawer/PolicyCoverDrawer";
import { capitalizeFirstCharacter } from "../../utils/formatting-utils";
import { ITableOrder, TableSortOrder } from "../../utils/table-utils";
import Loader from "../../components/Loader";

interface ICoversPage {}

const CoversPage: React.FC<ICoversPage> = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const [booted, setBooted] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [policyCoversDrawerOpen, setPolicyCoversDrawerOpen] =
    useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState<string>("");

  const isAdmin = user.userRoles.includes("Insurance-Admin");
  const actions: IEnhancedTableMenuItem[] = [
    {
      title: "+ New",
      onClick: () => {
        setPolicyCoversDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin,
    },
  ];

  const FILTER_SESSION_KEY = "policyCoversFilter";

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
          createdOn: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableOrder, setTableOrder] = useState<Record<string, ITableOrder>>({
    cover: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  let setSearchTimeout: NodeJS.Timeout;

  const [loadCoversLazy] = useLazyQuery(listQuery());
  const filterResponse = useQuery(filterQuery(), { fetchPolicy: "no-cache" });

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    orders = tableOrder,
    filterV = filterValues,
    searchKeyword = keywordSearch
  ) => {
    setLoadingState(true);
    try {
      const [coverListResult, filterDataResult] = await Promise.all([
        loadCoversLazy({
          variables: {
            currentPage: currentPage + 1,
            currentPageSize: pageSize,
            SelectedLineId:
              filterV?.line && filterV?.line.length > 0 ? filterV.line : [],
            FromCreatedOn: filterV?.createdOn?.[0]
              ? dayjs(filterV.createdOn[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
              : null,
            ToCreatedOn: filterV?.createdOn?.[1]
              ? dayjs(filterV.createdOn[1]).format(SEND_TO_BACKEND_DATE_FORMAT)
              : null,
            Attribute: capitalizeFirstCharacter(
              orders.cover.orderBy || "planConfigManagement_PolicyCover_Name"
            ),
            Descending: orders.cover.orderDirection !== "asc",
            keywordSearch: searchKeyword || null,
          },
          errorPolicy: "all",
        }),
        filterResponse.refetch(),
      ]);

      if (coverListResult.error) {
        toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      }
      if (filterDataResult.error) {
        toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      }

      const mappedCoverList = mapToListingData(coverListResult.data);
      setTableData({ ...mappedCoverList, pageNumber: currentPage });

      const lovs = dataToLovs(filterDataResult.data);

      const newFilterSections = filterSectionsContent(lovs, filterV);
      setFilterSections(newFilterSections);

      setFilterValues(filterV);
      setFilter(filterV, FILTER_SESSION_KEY);
    } catch (error) {
      console.error("Error loading data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoadingState(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    await loadData(0, tableData.pageSize, tableOrder, savedFilters);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrder, filterValues, val);
    }, 1000);
  };

  const handleSortChange = (orderBy: string, order: string) => {
    const newOrder = _.cloneDeep(tableOrder);
    newOrder.cover.orderBy = orderBy;
    newOrder.cover.orderDirection = order as TableSortOrder;
    handlePageChange(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder,
      filterValues,
      keywordSearch
    );
    setTableOrder(newOrder);
  };

  const handlePageChange = async (
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrder,
    filterV = filterValues,
    searchKeyword: string = keywordSearch
  ) => {
    if (!loadingState) {
      await loadData(page, pageSize, orders, filterV, searchKeyword);
    }
  };

  const onFilterUpdate = (newFilters: Record<string, any>) => {
    loadData(0, tableData.pageSize, tableOrder, newFilters, keywordSearch);
  };

  (
    headers.planConfigManagement_PolicyCover_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/plan/covers/" + payload.columns.planConfigManagement_PolicyCover_Id
    );
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: "20px" }}>
      <EnhancedTable
        title="Policy Covers"
        name="count"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={(page: number) =>
          handlePageChange(page, tableData.pageSize)
        }
        handleSearchChange={delaySearch}
        handleSort={handleSortChange}
        handleRowsPerPageChange={(pageSize: number) =>
          handlePageChange(0, pageSize)
        }
        currentPage={tableData.pageNumber}
        hideToolbar={false}
        usePagination
        isServerSide
        disableSelection
        actions={actions}
        showCellFullData={true}
        isToolbarAction={false}
        ordering={tableOrder.cover.orderDirection as TableSortOrder}
        orderingBy={tableOrder.cover.orderBy}
        loader={loadingState}
      />
      {policyCoversDrawerOpen && (
        <PolicyCoverDrawer
          open={policyCoversDrawerOpen}
          onClose={() => setPolicyCoversDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0, tableData.pageSize);
          }}
          isLineNameDisabled={false}
          canNavigateToDetailsPage={true}
        />
      )}
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name="Policy Covers"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default CoversPage;
