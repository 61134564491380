import { lookupListAsRecordObject } from "../../utils/graph-utils";
import { IInvoice, IInvoiceFormState, IProduct } from "./form";
import {ITransactionInfo} from "../transaction-popup/index"

export function LookupToList(data: any) {
  const result: Record<string, Record<string, string>> = {};

  result["relatedCompanies"] = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.relatedCompanies
  );

  result["currencies"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  let lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.GetSellAndBuySellProducts?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_Product_Id) {
        lookuplistAsRecordObject[element.accounting_Product_Id] =
          element.accounting_Product_ProductName;
      }
    }
  );

  result["products"] = lookuplistAsRecordObject;

  let currencies: Record<string, string> = {};

  data?.Accounting?.queries?.GetSellAndBuySellProducts?.forEach(
    (element: { [key: string]: any }) => {
      if (element.accounting_Product_Id) {
        currencies[element.accounting_Product_Id] =
          element.accounting_Product_RelatedIncomeAccount?.views?.Accounting_all?.properties?.AccountCurrency?.Code;
      }
    }
  );

  result["productCurrencies"] = currencies;


  return result;
}

export function getSystemCurrency(data: any) {
  return data?.Core?.queries?.getSystemCurrencies[0]
    ?.core_SystemCurrency_BasicCurrency?.Symbol;
}

export function getCustomersOfCompanyToList(data: any) {
  let lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.GetCustomersOfCompany?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_Customers_Id) {
        lookuplistAsRecordObject[element.accounting_Customers_Id] =
          element.accounting_Customers_CustomerName;
      }
    }
  );

  return lookuplistAsRecordObject;
}

export const getInvoicePageFormState = (
  invoice?: IInvoice,
  product: IProduct[] = []
): IInvoiceFormState => ({
  values: {
    invoice: invoice,
    product: product
  },
  errors: {
    invoice: {
      company: "",
      reference: "",
      date: "",
      description: "",
      note: "",
    },
    product: product.map((a) => ({
      productName: "",
      description: "",
      amount: "",
      systemAmount: ""
    })),
  },
  touched: {
    invoice: {
      company: false,
      reference: false,
      date: false,
      description: false,
      note: false,
    },
    product: product.map((_a) => ({
      productName: false,
      description: false,
      amount: false,
      systemAmount: false
    })),
  },
});

export function graphqlEntityToTransactionInfo(
  data: any
): ITransactionInfo {
  return {
    CurrentTransactionID: data?.Accounting.entities.transactions.id,
    ReferenceNumber: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.ReferenceNumber,
    TransactionType: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TransactionType,
    TransactionDate: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TransactionDate,
    Description: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.Description,
    Note: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.Note,
    RelatedCompany: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.RelatedCompany,
    TotalSystemCredit: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TotalSystemCredit,
    TotalSystemDebit: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TotalSystemDebit,
    TransactionNumber: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TransactionNumber,
    JournalTransactions: data?.Accounting.entities.transactions.queries?.accounting?.GetJournalTransactions,
    TransactionDocuments: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TransactionDocuments,
  };
}

