import { gql } from "@apollo/client";

export function listQuery() {
  return gql`
    query LIST_CLAUSES(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedLineId: [String]
      $SelectedStatuses: [PlanConfigManagement_ClauseStatuses]
      $FromCreatedOn: Date
      $ToCreatedOn: Date
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getClauseList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedLineIds: $SelectedLineId
            selectedStatuses: $SelectedStatuses
            fromCreatedOn: $FromCreatedOn
            toCreatedOn: $ToCreatedOn
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_Clause_Id
              planConfigManagement_Clause_createdOn
              planConfigManagement_Clause_modifiedOn
              planConfigManagement_Clause_ExternalCode
              planConfigManagement_Clause_Name
              planConfigManagement_Clause_Description
              planConfigManagement_Clause_Status
              planConfigManagement_Clause_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      Status
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function filterQuery() {
  return gql`
    query CLAUSES_LISTING_LOVS {
      PlanConfigManagement {
        queries {
          allLines {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
            planConfigManagement_Line_Status
          }
        }
      }
      PlanConfigManagement_ClauseStatuses: __type(
        name: "PlanConfigManagement_ClauseStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
