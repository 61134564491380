import React, { useState, useEffect } from 'react';
import {
  IInformationBoxRowCurrency,
  IInformationBoxRowSummaryList,
  IInformationBoxTab,
  IInformationBoxTabHeader,
  InformationDataType,
} from '.';
import InformationBoxBodyCell from './InformationBoxBodyCell';
import { isEmpty } from '../../utils/validationUtils';
import { formatDate } from '../../utils/formatting-utils';
import { IAbstractRecord } from '../../models';
import { makeStyles } from 'tss-react/mui';
import { ITheme } from '../../redux/tenant/types';
import { useAppSelector } from '../../redux/hooks';
import { MAIN_ONE_THEME } from '../../constants';

interface IInformationBoxProps {
  onCollapseClick: (status: boolean) => void;
  collapsed: boolean;
  displayNoData?: boolean;
  currency: string;
  tab: IInformationBoxTab;
  collapsible: boolean;
  visibleRows: number;
  cdnUrl: string;
  dateFormat: string;
  noDataMessage?: string;
}

const useStyles = makeStyles<{ theme: ITheme, colCount: number }>()((_, { theme, colCount }) => ({
  container: {
      display: 'block',
      width: '100%',
    },
    row: {
      padding: '12px 0',
      borderBottom: '1px solid #DFE3EBC9',
      textAlign: 'left',
      display: 'grid',
      gridTemplateColumns: `40% repeat(${colCount}, 1fr)`,
      gridGap: '5px',
      gridAutoRows: 'auto',
    },
    title: {
      color: MAIN_ONE_THEME.palette.primary5.main,
      display: 'inline-block',
      textAlign: 'left',
      fontSize: '13px',
      lineHeight: '15px'
    },
    seeMore: {
      display: 'block',
      margin: '10px auto',
      textAlign: 'center',
      textDecoration: 'underline',
      color: theme.palette.primary1.main,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      border: 0,
      outline: 0,
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    headerData: {
      marginRight: '5px',
    },
    summaryListTitleContainer: {
      gridColumn: '1 / -1',
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    summaryListTitle: {
      color: '#F5533D',
      fontSize: '16px',
      letterSpacing: '0.19px',
      fontFamily: 'DMSans-Regular',
    },
    summaryListHeaderData: {
      fontFamily: 'DMSans-Medium',
      fontSize: '15px',
    },
    summaryListHeader: {
      fontFamily: 'DMSans-Regular',
      fontSize: '14px',
      width: '100%',
      gridColumn: '1 / -1',
    },
    header: {
      width: '100%',
    },
    noData: {
      margin: '70px auto 50px auto',
      textAlign: 'center',
      color: theme.palette.secondary2.main,
      fontStyle: 'italic',
    },
  }),
);

const InformationBoxBody: React.FC<IInformationBoxProps> = ({
  collapsed,
  displayNoData,
  collapsible,
  currency,
  tab,
  onCollapseClick,
  visibleRows,
  cdnUrl,
  dateFormat,
  noDataMessage,
}) => {

  const theme = useAppSelector(state => state.tenant.theme);
  const [colCount, setColCount] = useState<number>(1);
  const [rowCount, setRowCount] = useState<number>(0);
  const classes = useStyles({ theme, colCount }).classes;
  useEffect(() => {
    const rowValue = Object.keys(tab.headers).length;
    const colValue = Object.keys(tab.data).length;
    setColCount(colValue);
    setRowCount(rowValue);
  }, [tab.data, tab.headers]);

  const renderBoxCell = () => {
    const output: JSX.Element[] = [];
    if (collapsed) {
      if (visibleRows > 0) {
        const headers = Object.values(tab.headers);
        const limit =
          headers.length > visibleRows ? visibleRows : headers.length;
        if (limit === 0 && displayNoData) {
          output.push(renderEmptyRow());
        } else {
          for (let i = 0; i < limit; i += 1) {
            output.push(renderRow(headers[i]));
          }
        }
      }
    } else {
      const headers = Object.values(tab.headers);
      headers.forEach(header => output.push(renderRow(header)));
    }
    return output;
  };

  function getValueByType(
    tab: IInformationBoxTab,
    column: string,
    header: IInformationBoxTabHeader,
  ) {
    switch (tab.headers[header.name].type) {
      case InformationDataType.SummaryList:
        return {
          headerDataValue: formatDate(
            (tab.headerData as Record<string, IAbstractRecord>)?.[column]?.[
              header.name
            ]?.[(header as IInformationBoxRowSummaryList)?.date],
            dateFormat,
          ),
          headerValue: (tab.headerData as Record<string, IAbstractRecord>)?.[
            column
          ]?.[header.name]?.[
            (header as IInformationBoxRowSummaryList)?.description
          ],
        };
      default:
        return {
          headerDataValue: (tab.headerData as Record<
            string,
            IAbstractRecord
          >)?.[column]?.[header.name],
          headerValue: tab.data?.[column]?.[header.name],
        };
    }
  }

  function getClassNameByType(type?: InformationDataType) {
    switch (type) {
      case InformationDataType.SummaryList:
        return {
          titleContainer: classes.summaryListTitleContainer,
          title: classes.summaryListTitle,
          headerData: classes.summaryListHeaderData,
          header: classes.summaryListHeader,
        };
      default:
        return {
          titleContainer: classes.titleContainer,
          title: classes.title,
          headerData: classes.headerData,
          header: classes.header,
        };
    }
  }

  function renderEmptyRow() {
    return (
      <p className={classes.noData}>
        {!isEmpty(noDataMessage)
          ? noDataMessage
          : "No Data"}
      </p>
    );
  }

  function renderRow(header: IInformationBoxTabHeader) {
    return (
      <div className={classes.row} key={header.title}>
        <div
          className={getClassNameByType(header.headerDataType).titleContainer}
        >
          {header.headerDataType &&
            tab.headerData &&
            tab.groupingValues.map(
              column =>
                (tab.headerData as Record<string, IAbstractRecord>)?.[column]?.[
                  header.name
                ] && (
                  <div
                    key={
                      column +
                      (tab.headerData as Record<string, IAbstractRecord>)[
                        column
                      ]?.[header.name]
                    }
                    className={
                      getClassNameByType(
                        tab.headers[header.name].headerDataType,
                      ).headerData
                    }
                  >
                    <InformationBoxBodyCell
                      type={tab.headers[header.name].headerDataType}
                      value={
                        getValueByType(tab, column, header).headerDataValue
                      }
                      currency={header.headerDataType === InformationDataType.Currency ? (header as IInformationBoxRowCurrency).currencySymbol : currency}
                      cdnUrl={cdnUrl}
                      dateFormat={dateFormat}
                    />
                  </div>
                ),
            )}
          <div
            className={
              getClassNameByType(tab.headers[header.name].headerDataType).title
            }
          >
            {header.title}
          </div>
        </div>
        {tab.groupingValues.map(column => (
          <div
            key={column + tab.data?.[column]?.[header.name]}
            className={getClassNameByType(tab.headers[header.name].type).header}
          >
            <InformationBoxBodyCell
              type={tab.headers[header.name].type}
              value={getValueByType(tab, column, header).headerValue}
              currency={header.type === InformationDataType.Currency ? (header as IInformationBoxRowCurrency).currencySymbol : currency}
              cdnUrl={cdnUrl}
              dateFormat={dateFormat}
            />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {tab.headers && renderBoxCell()}
      {collapsible && !!visibleRows && rowCount > visibleRows && (
        <button
          type="button"
          className={classes.seeMore}
          onClick={() => onCollapseClick(!collapsed)}
        >
          {collapsed
            ? "See More"
            : "See Less"}
        </button>
      )}
    </div>
  );
};

export default InformationBoxBody;
