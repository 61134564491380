import React, { useEffect, useState } from 'react';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import { GetAccountAuditTrails, listQuery } from './queries';
import {
  convertAuditTrailsToActivities,
  mapToListingData,
  toLookupsData,
} from './utils';
import { filterSectionsContent, headers } from './content';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _, { isEmpty } from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import { toast } from 'react-toastify';
import { IEnhanceTableHeaderClickable } from '../../components/enhanced-table';
import { IEnhancedMenuItem } from '../../components';
import { MAIN_ONE_THEME, SEND_TO_BACKEND_DATE_FORMAT } from '../../constants';
import dayjs from 'dayjs';
import AccountDrawer from '../../forms/account-drawer/AccountDrawer';
import AccountImportDrawer from '../../forms/account-import-drawer/AccountImportDrawer';
import GroupTable from '../../components/updated-enhanced-table/GroupTable';
import ActionBar from '../../components/ActionBar';
import { makeStyles } from 'tss-react/mui';
import CustomActivities from '../../activities/CustomActivities';
import { IActivityEntityBase } from '../../activities';
import CategoryDrawer from '../../forms/category-drawer/CategoryDrawer';

interface IAccountsPage {}

const useStyles = makeStyles()(() => ({
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: '37px',
  },
  button: {
    backgroundColor: '#C00020',
    '&:focus': {
      outline: 'none',
    },
  },
}));

interface IAccountPageAuditTrailInfo {
  title: string;
  items: IActivityEntityBase[];
  loading: boolean;
}

const AccountsPage: React.FC<IAccountsPage> = ({}) => {
  const { classes } = useStyles();

  const [categoryDrawerOpen, setCategoryDrawerOpen] = useState<boolean>(false);
  const [accountDrawerOpen, setAccountDrawerOpen] = useState<boolean>(false);
  const [accountImportDrawerOpen, setAccountImportDrawerOpen] =
    useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>('');

  const actions: IEnhancedMenuItem[] = [
    {
      title: '+ New Auxiliary',
      onClick: () => {
        setChosenEditId(undefined);
        setAccountDrawerOpen(true);
      },
      isSecondary: true,
      backgroundColor: '#000',
      color: '#fff',
    },
    {
      title: '+ New Ledger',
      onClick: () => {
        setCategoryDrawerOpen(true);
      },
      isSecondary: true,
      backgroundColor: '#000',
      color: '#fff',
    },
  ];

  const FILTER_SESSION_KEY = 'accountsFilter';

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          createdOn: [],
          company: '41',
          accountCategory: '',
          accountSubCategory: '',
        };

  const [loading, setLoading] = useState<boolean>(false);

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [auditTrailInfo, setAuditTrailInfo] =
    useState<IAccountPageAuditTrailInfo>();

  const [accounts, setAccountsData] =
    useState<Record<string, Record<string, IAccountsGenericTableData>>>();

  const [getAccountsLazy] = useLazyQuery(listQuery());
  const [getAccountAuditTrails] = useLazyQuery(GetAccountAuditTrails());

  const initialize = async (values: IAbstractRecord = filterValues) => {
    setLoading(true);
    setAccountDrawerOpen(false);
    setCategoryDrawerOpen(false);
    setAccountImportDrawerOpen(false);
    const result = await getAccountsLazy({
      variables: {
        SelectedCompany:
          values?.company && values?.company?.length > 0
            ? values?.company
            : '41',
        CreatedOnFromDate: values?.createdOn[0]
          ? dayjs(values.createdOn[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        CreatedOnToDate: values?.createdOn[1]
          ? dayjs(values.createdOn[1])
              .add(1, 'day')
              .format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        SelectedAccountCategory: null,
        SelectedAccountSubCategory:
          values?.accountSubCategory && values?.accountSubCategory
            ? values?.accountSubCategory
            : '',
      },
    });

    if (result.error) {
      toast.error('Error');
      return;
    }

    let tableData = mapToListingData(result.data);

    let savedFilters = getFilter(FILTER_SESSION_KEY);
    if (isEmpty(savedFilters)) {
      savedFilters = _.cloneDeep(values);
    }
    setFilterValues(savedFilters);

    const lovs = toLookupsData(result.data);

    if (Object.keys(lovs.accountCategory).length && !values?.accountCategory) {
      savedFilters.accountCategory = Object.keys(lovs.accountCategory)[0];
    }

    if (!Object.keys(lovs.accountSubCategory).length) {
      lovs.accountSubCategory = values?.accountSubCategory;
    }

    if (!Object.keys(lovs.company).length) {
      lovs.company = values?.company;
    }

    const newFilterSections = filterSectionsContent(lovs, savedFilters);
    setFilterSections(newFilterSections);

    setAccountsData(tableData);
    setLoading(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  const viewHistoryClick = async (id: string, title: string) => {
    setAuditTrailInfo({
      items: [],
      loading: true,
      title: title,
    });

    const result = await getAccountAuditTrails({
      variables: {
        id: id,
      },
    });

    if (result.data) {
      setAuditTrailInfo({
        items: convertAuditTrailsToActivities(result.data),
        loading: false,
        title: title,
      });
    }
  };

  (
    headers.accounting_ChartOfAccounts_AccountID as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    setChosenEditId(payload.columns.accounting_ChartOfAccounts_Id);
    setAccountDrawerOpen(true);
  };

  (headers.viewHistory as IEnhanceTableHeaderClickable).callback = (
    payload: any
  ) => {
    const id = payload.key;
    viewHistoryClick(
      id,
      payload.columns.accounting_ChartOfAccounts_AccountName
    );
  };

  const renderMainChildren = () => {
    return (
      <>
        {loading || !accounts ? (
          <div></div>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <ActionBar
              className={classes.actionsContainer}
              items={actions}
              classes={{
                button: classes.button,
              }}
            />

            <GroupTable
              tables={
                Object.entries(accounts)?.map(([key, value]) => ({
                  title: key,
                  data: {
                    pagedItems: value,
                    pageSize: 10000,
                    pageNumber: 0,
                    totalCount: 0,
                  },
                  headers: headers as any,
                })) as any
              }
            />

            {categoryDrawerOpen && (
              <CategoryDrawer
                open={categoryDrawerOpen}
                onClose={() => setCategoryDrawerOpen(false)}
                onSuccess={() => initialize()}
              />
            )}

            {accountDrawerOpen && (
              <AccountDrawer
                open={accountDrawerOpen}
                onClose={() => setAccountDrawerOpen(false)}
                onSuccess={() => {
                  // handlePageChange(0);
                  initialize();
                }}
                accountId={chosenEditId}
                accountInfo={undefined}
              />
            )}
            {accountImportDrawerOpen && (
              <AccountImportDrawer
                open={accountImportDrawerOpen}
                onClose={() => setAccountImportDrawerOpen(false)}
                onSuccess={() => {
                  // handlePageChange(0);
                  initialize();
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    initialize(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={''}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={loading}
      name={'Applications'}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
      rightChildren={
        <CustomActivities
          loader={auditTrailInfo?.loading || false}
          title={auditTrailInfo?.title || ''}
          items={auditTrailInfo?.items || []}
        />
      }
      onRightCollapseClick={(v) => {
        if (auditTrailInfo) {
          setAuditTrailInfo(undefined);
        }
      }}
      config={{
        leftColumn: {
          backgroundColor: '#FFFFFF',
          collapsable: false,
          collapsed: false,
          width: 2,
        },
        mainColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: false,
          collapsed: false,
          width: auditTrailInfo ? 7 : 10,
        },
        rightColumn: {
          backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
          collapsable: true,
          collapsed: !auditTrailInfo,
          width: auditTrailInfo ? 3 : 0,
        },
      }}
    />
  );
};
export default AccountsPage;
