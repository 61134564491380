import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery, useQuery } from "@apollo/client";
import { filterQuery, listQuery } from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _ from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import {
  IEnhancedTableMenuItem,
  IEnhanceTableHeaderClickable,
} from "../../components/enhanced-table";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import dayjs from "dayjs";
import { useAppSelector } from "../../redux/hooks";
import PersonDrawer from "../../forms/person-drawer/PersonDrawer";
import { capitalizeFirstCharacter } from "../../utils/formatting-utils";
import { ITableOrder, TableSortOrder } from "../../utils/table-utils";
import Loader from "../../components/Loader";

interface IPersonsPage {}

const PersonsPage: React.FC<IPersonsPage> = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const [booted, setBooted] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [personsDrawerOpen, setPersonsDrawerOpen] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState<string>("");

  const isAdmin = user.userRoles.includes("Insurance-Admin");
  const actions: IEnhancedTableMenuItem[] = [
    {
      title: "+ New",
      onClick: () => {
        setPersonsDrawerOpen(true);
      },
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin,
    },
  ];

  const FILTER_SESSION_KEY = "personsFilter";

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          selectedTypes: [],
          personStatus: [],
          createdOn: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableOrder, setTableOrder] = useState<Record<string, ITableOrder>>({
    person: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  let setSearchTimeout: NodeJS.Timeout;

  const [loadPersonsLazy] = useLazyQuery(listQuery());
  const filterResponse = useQuery(filterQuery(), { fetchPolicy: "no-cache" });

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    orders = tableOrder,
    filterV = filterValues,
    searchKeyword = keywordSearch
  ) => {
    setLoadingState(true);
    try {
      const [personListResult, filterDataResult] = await Promise.all([
        loadPersonsLazy({
          variables: {
            currentPage: currentPage + 1,
            currentPageSize: pageSize,
            selectedTypes:
              filterV?.type && filterV?.type?.length > 0
                ? filterV?.type
                : [],
            personStatus:
              filterV?.status && filterV?.status?.length > 0
                ? filterV?.status
                : [],
            createdDateFrom: filterV?.createdOn?.[0]
              ? dayjs(filterV.createdOn[0]).format(
                  SEND_TO_BACKEND_DATE_FORMAT
                )
              : null,
            createdDateTo: filterV?.createdOn?.[1]
              ? dayjs(filterV.createdOn[1]).format(
                  SEND_TO_BACKEND_DATE_FORMAT
                )
              : null,
            Attribute: capitalizeFirstCharacter(
              orders.person.orderBy || "SalesforceManagement_Person_FullName"
            ),
            Descending: orders.person.orderDirection !== "asc",
            keywordSearch: searchKeyword || null,
          },
          errorPolicy: "all",
        }),
        filterResponse.refetch(),
      ]);

      if (personListResult.error) {
        toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      }
      if (filterDataResult.error) {
        toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      }

      const mappedPersonList = mapToListingData(personListResult.data);
      setTableData({ ...mappedPersonList, pageNumber: currentPage });

      const lovs = dataToLovs(filterDataResult.data);
      const newFilterSections = filterSectionsContent(lovs, filterV);
      setFilterSections(newFilterSections);

      setFilterValues(filterV);
      setFilter(filterV, FILTER_SESSION_KEY);
    } catch (error) {
      console.error("Error loading data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoadingState(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    await loadData(0, tableData.pageSize, tableOrder, savedFilters);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrder, filterValues, val);
    }, 1000);
  };

  const handleSortChange = (orderBy: string, order: string) => {
    const newOrder = _.cloneDeep(tableOrder);
    newOrder.person.orderBy = orderBy;
    newOrder.person.orderDirection = order as TableSortOrder;
    handlePageChange(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder,
      filterValues,
      keywordSearch
    );
    setTableOrder(newOrder);
  };

  const handlePageChange = async (
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrder,
    filterV = filterValues,
    searchKeyword: string = keywordSearch
  ) => {
    if (!loadingState) {
      await loadData(page, pageSize, orders, filterV, searchKeyword);
    }
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    handlePageChange(0, tableData.pageSize, tableOrder, newFilters, keywordSearch);
  };

  (
    headers.salesforceManagement_Person_PersonCode as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/salesforce/persons/" + payload.columns.salesforceManagement_Person_Id
    );
  };

  (
    headers.salesforceManagement_Person_FullName as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/salesforce/persons/" + payload.columns.salesforceManagement_Person_Id
    );
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: "20px" }}>
      <EnhancedTable
        title="Persons"
        name="count"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={(page: number) =>
          handlePageChange(page, tableData.pageSize)
        }
        handleSort={handleSortChange}
        handleRowsPerPageChange={(pageSize: number) =>
          handlePageChange(0, pageSize)
        }
        handleSearchChange={delaySearch}
        currentPage={tableData.pageNumber}
        hideToolbar={false}
        usePagination
        disableSelection
        isServerSide
        actions={actions}
        loader={loadingState}
        showCellFullData={true}
        isToolbarAction={false}
        ordering={tableOrder.person.orderDirection as TableSortOrder}
        orderingBy={tableOrder.person.orderBy}
      />
      {personsDrawerOpen && (
        <PersonDrawer
          open={personsDrawerOpen}
          onClose={() => setPersonsDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0, tableData.pageSize);
          }}
        />
      )}
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
        disabled={loadingState}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name="Persons"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default PersonsPage;
