import React, { useEffect, useState } from "react";
import { IPolicyCoverDetails } from "..";
import { EnhancedDisplayType } from "../../../../../components/enhanced-form/EnhancedDisplay";
import {
  IDynamicDiplayView,
  IDynamicSection,
} from "../../../../../components/widgets/dynamic-display";
import DynamicWidget from "../../../../../components/widgets/dynamic-display/DynamicWidget";
import { capitalizeFirstLetterLowerOthers } from "../../../../../utils/formatting-utils";

export interface IProposalCoverInfo {
  isMain: boolean;
  isMandatory: boolean;
  isEditable: boolean;
  policyCover: string;
  coverPremiumType: string;
  coverPremiumPercentage?: number;
  coverPremiumValue?: number;
  currency: string;
  coverSumInsured?: number;
  sumInsuredIsAdditive: boolean;
  excessOnClaimType: string;
  excessOnClaimAmount?: number;
  excessOnClaimPercentage?: number;
  excessOnClaimDays?: number;
  name?: string;
  coverEffectiveFrom?: string;
  coverEffectiveTo?: string;
}

interface IProductionPolicyCoversInfoProps {
  data: IPolicyCoverDetails[];
  lineExternalCode: number;
  currencySymbol: string;
}

const ProductionPolicyCoversInfo: React.FC<
  IProductionPolicyCoversInfoProps
> = ({ data, lineExternalCode, currencySymbol }) => {
  const [view, setView] = useState<IDynamicDiplayView>();

  useEffect(() => {
    setViewData();
  }, [data, lineExternalCode]);

  const setViewData = () => {
    const dynamicView: IDynamicDiplayView = {
      name: "PolicyCovers",
      sections: data.map(
        (item, index) =>
          ({
            name: "PolicyDetails" + index,
            title: index === 0 ? "Policy Covers" : "",
            properties: {
              ["PolicyCover" + index]: {
                multiline: false,
                value: item.name,
                name: "PolicyCover" + index,
                title: "Policy Cover",
                tooltip: false,
              },
              ["isMain" + index]: {
                multiline: false,
                value: item.isMain ? "Yes" : "No",
                name: "isMain" + index,
                title: "Main",
                tooltip: false,
              },
              ["isMandatory" + index]: {
                multiline: false,
                value: item.isMandatory ? "Yes" : "No",
                name: "isMandatory" + index,
                title: "Mandatory",
                tooltip: false,
              },
              ["coverPremiumType" + index]: {
                multiline: false,
                value: capitalizeFirstLetterLowerOthers(item.coverPremiumType),
                name: "coverPremiumType" + index,
                title: "Premium Type",
                tooltip: false,
              },
              ["coverPremiumPercentage" + index]: {
                multiline: false,
                value: item.coverPremiumPercentage?.toString(),
                name: "coverPremiumPercentage" + index,
                title: "Premium Percentage",
                tooltip: false,
                type: EnhancedDisplayType.Percentage,
                hidden: item.coverPremiumType !== "PERCENTAGE",
              },
              ["coverPremiumValue" + index]: {
                multiline: false,
                value: item.coverPremiumValue?.toString(),
                name: "coverPremiumValue" + index,
                title: "Premium",
                tooltip: false,
                type: EnhancedDisplayType.Currency,
                hidden: item.coverPremiumType !== "VALUE",
                currencySymbol: currencySymbol,
              },
              ["coverSumInsured" + index]: {
                multiline: false,
                value: item.coverSumInsured?.toString(),
                name: "coverSumInsured" + index,
                title: "SumInsured",
                tooltip: false,
                type: EnhancedDisplayType.Currency,
                currencySymbol: currencySymbol,
              },
              ["sumInsuredIsAdditive" + index]: {
                multiline: false,
                value: item.sumInsuredIsAdditive ? "Yes" : "No",
                name: "sumInsuredIsAdditive" + index,
                title: "Additive SumInsured",
                tooltip: false,
              },
              ["excessOnClaimType" + index]: {
                multiline: false,
                value: capitalizeFirstLetterLowerOthers(item.excessOnClaimType),
                name: "excessOnClaimType" + index,
                title: "Excess On Claim Type",
                tooltip: false,
              },
              ["excessOnClaimAmount" + index]: {
                multiline: false,
                value: item.excessOnClaimAmount?.toString(),
                name: "excessOnClaimAmount" + index,
                title: "Excess On Claim",
                tooltip: false,
                type: EnhancedDisplayType.Currency,
                currencySymbol: currencySymbol,
                hidden: item.excessOnClaimType !== "VALUE",
              },
              ["excessOnClaimPercentage" + index]: {
                multiline: false,
                value: item.excessOnClaimPercentage?.toString(),
                name: "excessOnClaimPercentage" + index,
                title: "Excess On Claim (Percentage)",
                tooltip: false,
                type: EnhancedDisplayType.Percentage,
                hidden: item.excessOnClaimType !== "PERCENTAGE",
              },
              ["excessOnClaimDays" + index]: {
                multiline: false,
                value: item.excessOnClaimDays?.toString(),
                name: "excessOnClaimDays" + index,
                title: "Excess On Claim (Days)",
                tooltip: false,
                hidden: item.excessOnClaimType !== "DAYS",
              },
              ["coverEffectiveFrom" + index]: {
                multiline: false,
                value: item.coverEffectiveFrom,
                name: "coverEffectiveFrom" + index,
                title: "Effective From",
                tooltip: false,
                type: EnhancedDisplayType.Date,
              },
              ["coverEffectiveTo" + index]: {
                multiline: false,
                value: item.coverEffectiveTo,
                name: "coverEffectiveTo" + index,
                title: "Effective To",
                tooltip: false,
                type: EnhancedDisplayType.Date,
              },
            },
          } as IDynamicSection)
      ),
    };

    setView(dynamicView);
  };

  if (!view) {
    return <></>;
  }

  return <DynamicWidget hasFourFields={true} view={view} />;
};

export default ProductionPolicyCoversInfo;
