import { makeStyles } from 'tss-react/mui';
import React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { Backdrop } from '@mui/material';
import clsx from 'clsx';
import EnhancedBackdrop from './EnhancedBackdrop';

interface IEnhancedDialogProps extends DialogProps {
  blur?: boolean;
  dialogClassName?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles()(() => ({
  modalDocument: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    overflow: 'Visible',
    overflowY: 'visible',
  },
}));

const EnhancedDialog: React.FC<IEnhancedDialogProps> = ({
  open = false,
  children,
  onClose,
  blur,
  dialogClassName,
  ...props
}) => {
  const { classes } = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown
      BackdropComponent={blur ? EnhancedBackdrop : Backdrop}
      fullWidth
      transitionDuration={500}
      scroll="body"
      maxWidth="md"
      BackdropProps={{
        onClick: (event) => event.stopPropagation()
      }}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          overflowY: 'visible',
          overflow: 'Visible',
        },
      }}
      {...props}
    >
      <section className={clsx(classes.modalDocument, dialogClassName)}>
        {children}
      </section>
    </Dialog>
  );
};

export default EnhancedDialog;
