import React from "react";
import _ from "lodash";
import EnhancedToolbar from "../EnhancedToolbar";
import { EnhancedTableFilterType } from ".";
import { IEnhancedMenuItem } from "..";
import EnhancedMenu from "./EnhancedMenu";
import EnhancedTableFilter from "./EnhancedTableFilter";
import { makeStyles } from "tss-react/mui";
import { MAIN_ONE_THEME } from "../../constants";
import EnhancedIconButton from "../EnhancedIconButton";
import EnhancedButton from "../EnhancedButton";

const useStyles = makeStyles()(() => ({
  actions: {
    gridGap: "1em",
    display: "grid",
    gridAutoFlow: "column",
  },
  action: {
    "& span": {
      margin: "0.5em auto 0",
    },
  },
  separator: {
    width: "1px",
    height: "25px",
    opacity: 0.5,
    backgroundColor: MAIN_ONE_THEME.palette.secondary3.main,
    marginLeft: "5px",
    marginRight: "5px",
    display: "inline-block",
    verticalAlign: "text-bottom",
    alignSelf: "center",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
  },
  secondaryActions: {
    boxSizing: "border-box",
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-end",
    color: MAIN_ONE_THEME.palette.primary1.main,
    margin: "0 0 0 8px",
  },
  dropdown: {
    width: "unset",
  },
  dropdownField: {
    lineHeight: 1.5,
    textAlign: "left",
    color: MAIN_ONE_THEME.palette.primary1.main,
    display: "flex",
  },
  actionIcon: {
    opacity: 0.5,
  },
}));

export interface EnhancedTableToolbarProps {
  selected: string[];
  handleChange: (value: string) => void;
  cdnUrl: string;
  title?: string;
  inline?: boolean;
  filters?: Record<string, EnhancedTableFilterType>;
  onFilterClick(name: string, value: any): void;
  onFilterClear?(): void;
  // forceClearSearchValue?: boolean;
  actions: IEnhancedMenuItem[];
  secondaryActions: IEnhancedMenuItem[];
  areSecondaryActions?: boolean;
  specificDesign?: boolean;
  fieldNextToTitle?: JSX.Element;
  isToolbarAction?: boolean;
  searchValue?: string;
  showInlineFilter?: boolean;
}

const EnhancedTableToolbar: React.FC<EnhancedTableToolbarProps> = ({
  actions,
  secondaryActions,
  selected,
  handleChange,
  cdnUrl,
  title,
  filters,
  onFilterClear = () => {},
  onFilterClick = () => {},
  areSecondaryActions = false,
  specificDesign = false,
  fieldNextToTitle,
  isToolbarAction = true,
  searchValue,
  showInlineFilter = true,
}) => {
  const { classes } = useStyles();

  const renderActions = () => {
    const output: JSX.Element[] = [];
    if (!areSecondaryActions && isToolbarAction) {
      actions.forEach((action) => {
        if (!action.hidden) {
          if (action.icon) {
            output.push(
              <EnhancedIconButton
                key={action.title}
                onClick={action.onClick}
                disabled={action.disabled}
                className={classes.action}
                title={action.title}
                iconUrl={action.icon || ""}
              />
            );
          } else {
            output.push(
              <EnhancedButton
                key={action.title}
                disabled={action.disabled}
                color={(action.isSecondary && "white")}
                backgroundColor={
                 (action.isSecondary && "rgb(0,0,0)")
                }
                onClick={action.onClick}
                hasImage={action.icon ? true : false}
              >
                {action.title}
              </EnhancedButton>
            );
          }
        }
      });
    }
    return output;
  };

  const renderDropdownActions = () => {
    if (secondaryActions && secondaryActions.length > 0) {
      return (
        <div className={classes.secondaryActions}>
          <EnhancedMenu title="Actions" items={secondaryActions}></EnhancedMenu>
        </div>
      );
    } else if (
      areSecondaryActions &&
      secondaryActions.length === 0 &&
      actions.length > 0
    ) {
      return (
        <div className={classes.secondaryActions}>
          <EnhancedMenu
            title="Actions"
            items={actions}
            specificDesign={specificDesign}
          ></EnhancedMenu>
        </div>
      );
    }
    return <></>;
  };
  return (
    <EnhancedToolbar
      onChange={handleChange}
      keywordSearch={searchValue}
      cdnUrl={cdnUrl}
      title={title}
      fieldNextToTitle={fieldNextToTitle}
      classes={{ rightSection: classes.toolbar }}
      filterElem={
        filters && Object.keys(filters).length > 0 ? (
          <EnhancedTableFilter
            onFilterClick={onFilterClick}
            filters={filters}
            onClear={onFilterClear}
          />
        ) : undefined
      }
      specificDesign={specificDesign}
      showInlineFilter={showInlineFilter}
      // forceClearSearchValue={forceClearSearchValue}
    >
      <div className={classes.actions}>{renderActions()}</div>
      {renderDropdownActions()}
    </EnhancedToolbar>
  );
};

export default EnhancedTableToolbar;
