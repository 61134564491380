import React, { useEffect, useState } from 'react';
import { DEFAULT_ERROR_TEXT } from '../../../../../constants';
import EnhancedTable from '../../../../../components/enhanced-table/EnhancedTable';
import { IListingData } from '../../../../../models/listing';
import { headers } from './content';
import { useLazyQuery } from '@apollo/client';
import { businessPartnerQuery, getPolicyBills } from '../queries';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../../../../components/ToastErrorMessage';
import Loader from '../../../../../components/Loader';
import { mapToListingData } from './utils';
import {
  IEnhancedTableMenuItem,
  IEnhanceTableHeaderClickable,
} from '../../../../../components/enhanced-table';
import { makeStyles } from 'tss-react/mui';
import PaymentTermsPopup from '../../../../../forms/payment-terms/PaymentTermsPopup';

interface IPolicyPaymentTermsSchedule {
  policyId: string;
}

const useStyles = makeStyles()(() => ({
  paperClass: {
    color: 'red',
  },
}));

const ProductionPolicyPaymentTermsSchedule: React.FC<
  IPolicyPaymentTermsSchedule
> = ({ policyId }) => {
  const { classes } = useStyles();
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [chosenBreakdownId, setChosenBreakdownId] = useState<string>();
  const [showTermsPopup, setShowTermsPopup] = useState<boolean>(false);
  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const tableAction: IEnhancedTableMenuItem[] = [
    {
      title: 'Change Payment Terms',
      onClick: () => {
        setShowTermsPopup(true);
      },
      isEntity: false,
      isBulk: false,
      disabled: false,
    },
  ];

  const [getPolicyBillsLazy] = useLazyQuery(getPolicyBills());
  const [getBusinessPartnerDetailsLazy] = useLazyQuery(
    businessPartnerQuery(),
    {}
  );

  const loadData = async (currentPage = 0, pageSize = tableData.pageSize) => {
    setLoading(true);

    try {
      const result = await getPolicyBillsLazy({
        variables: {
          policyId: policyId,
          pageNumber: 1,
          pageSize: tableData.pageSize,
        },
      });

      if (result.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      let data = result.data;

      const newTableData = mapToListingData(data);
      setTableData({ ...newTableData, pageNumber: currentPage, pageSize });
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    await loadData(0, tableData.pageSize);
  };

  useEffect(() => {
    initialize();
  }, []);

  const handlePageChange = async (page: number) => {
    await loadData(page, tableData.pageSize);
  };

  const handleRowsPerPageChange = async (numberOfRecordsPerPage: number) => {
    await loadData(0, numberOfRecordsPerPage);
  };

  async function handleTooltipFetch(id: string): Promise<Record<string, any>> {
    let popoverRows: Record<string, any> = {};
    await getBusinessPartnerDetailsLazy({
      variables: {
        billID: id,
      },
    }).then((response) => {
      response?.data?.Accounting?.queries?.GetBusinessPartnersCommissions?.forEach(
        (obj: any, i: number) => {
          popoverRows[i] = {
            BusinessPartner:
              obj.accounting_Bills_BillingAccountID?.views
                .SalesforceManagement_all.properties.PersonID.views
                .SalesforceManagement_all.properties.FullName,
            Share: obj.policyBusinessPartner_CommissionPercentage,
            Commission: obj.accounting_Bills_TotalCommission,
            Currency: obj.accounting_Bills_Currency?.Symbol,
          };
        }
      );
    });
    return popoverRows;
  }

  (
    headers.accounting_Bills_TotalCommission as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    setChosenBreakdownId(payload.accounting_Bills_BillNumber);
  };

  headers.accounting_Bills_TotalCommission.tooltipSettings.tooltipFetch =
    handleTooltipFetch;

  if (!booted) {
    return <Loader />;
  }

  return (
    <>
      <EnhancedTable
        inlineTitle='Payment Terms Schedule'
        name='paymentTermsSchedule'
        data={tableData}
        headers={headers}
        handlePageChange={(page: number) => handlePageChange(page)}
        handleRowsPerPageChange={(page: number) => {
          handleRowsPerPageChange(page);
        }}
        currentPage={tableData.pageNumber}
        loader={loading}
        tableClasses={classes}
        actions={tableAction}
        showInlineFilter={false}
        hideToolbar={false}
        inline
        isTitlePascalCase
        orderByAscendingByDefault
        disableSelection
        usePagination
        showCellFullData
      />
      {chosenBreakdownId && <div>Hello</div>}
      {showTermsPopup && (
        <PaymentTermsPopup
          policyId={policyId}
          open={showTermsPopup}
          onClose={() => setShowTermsPopup(false)}
        />
      )}
    </>
  );
};

export default ProductionPolicyPaymentTermsSchedule;
