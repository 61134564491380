import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery, useQuery } from "@apollo/client";
import { filterQuery, listQuery } from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from "../../components/enhanced-table";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import dayjs from "dayjs";
import { useAppSelector } from "../../redux/hooks";
import SublineDrawer from "../../forms/subline-drawer/SublineDrawer";
import { capitalizeFirstCharacter } from "../../utils/formatting-utils";
import { ITableOrder, TableSortOrder } from "../../utils/table-utils";

interface ISublinesPage {}

const SublinesPage: React.FC<ISublinesPage> = ({}) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const [booted, setBooted] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [sublineDrawerOpen, setSublineDrawerOpen] = useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>("");

  const [keywordSearch, setKeywordSearch] = useState<string>("");

  const isAdmin = user.userRoles.includes("Insurance-Admin");
  const actions: IEnhancedTableMenuItem[] = [
    {
      title: "+ New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin,
      disabled: false,
    },
  ];

  let setSearchTimeout: NodeJS.Timeout;

  const FILTER_SESSION_KEY = "sublinesFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
          SelectedStatuses: [],
          createdOn: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableOrder, setTableOrder] = useState<Record<string, ITableOrder>>({
    subline: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getSublinesLazy] = useLazyQuery(listQuery());

  const loadSublineList = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    orders = tableOrder,
    searchKeyword = keywordSearch
  ) => {
    const result = await getSublinesLazy({
      variables: {
        keywordSearch: searchKeyword || null,
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        SelectedLineId:
          filterValues?.line && filterValues?.line?.length > 0
            ? filterValues?.line
            : [],
        SelectedStatuses:
          filterValues?.status && filterValues?.status?.length > 0
            ? filterValues.status
            : null,
        FromCreatedOn: filterValues?.createdOn?.[0]
          ? dayjs(filterValues?.createdOn?.[0]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
        ToCreatedOn: filterValues?.createdOn?.[1]
          ? dayjs(filterValues?.createdOn?.[1]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
        Attribute: capitalizeFirstCharacter(
          orders.subline.orderBy || "PlanConfigManagement_Subline_Name"
        ),
        Descending: orders.subline.orderDirection !== "asc",
      },
      errorPolicy: "all",
    });

    return result;
  };

  const initialize = async () => {
    try {
      const sublineListData = await loadSublineList();
      const mappedSublineList = await mapToListingData(sublineListData.data);
      setTableData({ ...mappedSublineList, pageNumber: 0 });

      if (sublineListData.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }

      const filterDataResponse = await filterResponse.refetch();
      const lovs = dataToLovs(filterDataResponse.data);

      const savedFilters = getFilter(FILTER_SESSION_KEY) || filterValues;
      setFilterValues(savedFilters);

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);

      setBooted(true);
    } catch (error) {
      console.error("Error initializing data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (booted) {
      handlePageChange(0, tableData.pageSize, tableOrder, keywordSearch);
    }
  }, [filterValues]);

  const filterResponse = useQuery(filterQuery(), { fetchPolicy: "no-cache" });
  useEffect(() => {
    if (filterResponse?.loading) setLoadingState(true);
    if (filterResponse?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (filterResponse?.data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = dataToLovs(filterResponse.data);

      if (!Object.keys(lovs.line).length) {
        lovs.line = filterValues?.line;
      }

      if (!Object.keys(lovs.status).length) {
        lovs.status = filterValues?.status;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [filterResponse]);

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrder, val);
    }, 1000);
  };

  const handleSortChange = (orderBy: string, order: string) => {
    const newOrder = _.cloneDeep(tableOrder);

    newOrder.subline.orderBy = orderBy;
    newOrder.subline.orderDirection = order as any;
    handlePageChange(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder,
      keywordSearch
    );
    setTableOrder(newOrder);
  };

  const handlePageChange = async (
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrder,
    searchKeyword: string = keywordSearch
  ) => {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadSublineList(
        page,
        pageSize,
        orders,
        searchKeyword
      );
      const mappedSubList = await mapToListingData(result.data);
      setTableData({ ...mappedSubList, pageNumber: page, pageSize });
      setLoadingState(false);
    }
  };

  actions[0].onClick = () => {
    setChosenEditId(undefined);
    setSublineDrawerOpen(true);
  };

  (
    headers.planConfigManagement_Subline_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/plan/sublines/" + payload.columns.planConfigManagement_Subline_Id
    );
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: "20px" }}>
      <EnhancedTable
        title="Sublines"
        name="count"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={(page: number) =>
          handlePageChange(page, tableData.pageSize)
        }
        handleSearchChange={delaySearch}
        handleSort={handleSortChange}
        handleRowsPerPageChange={(pageSize: number) =>
          handlePageChange(0, pageSize)
        }
        currentPage={tableData.pageNumber}
        hideToolbar={false}
        usePagination
        disableSelection
        actions={actions}
        isToolbarAction={false}
        ordering={tableOrder.subline.orderDirection as TableSortOrder}
        orderingBy={tableOrder.subline.orderBy}
        //searchValue={keywordSearch}
      />
      {sublineDrawerOpen && (
        <SublineDrawer
          open={sublineDrawerOpen}
          onClose={() => setSublineDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0, 10);
          }}
          isLineNameDisabled={false}
          canNavigateToDetailsPage={true}
          sublineId={chosenEditId}
        />
      )}
    </div>
  );

  const onFilterUpdate = (newFilters: Record<string, any>) => {
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        filters={filterSections}
        onApplyFilter={(v) => {
          onFilterUpdate(v);
        }}
      />
    );

  return (
    <StaticLayout
      loading={!booted}
      name="Applications"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default SublinesPage;
