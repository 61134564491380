import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  businessPartnerDetails: {
    specialCommissionApplicable: "",
    specialCommissionType: "",
    specialCommissionValue: "",
    specialCommissionValueCurrency: "",
    specialCommissionRate: "",
    specialCommissionApplicableOn: "",
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  specialCommissionApplicable: {
    name: "specialCommissionApplicable",
    title: "Special Commission Applicable",
    type: FormInputTypes.chips,
    placeholder: "Special Commission Applicable",
    value: !isEmpty(
      initialValues.businessPartnerDetails.specialCommissionApplicable
    )
      ? initialValues.businessPartnerDetails.specialCommissionApplicable
      : "",
    multiple: false,
    disabled: false,
    required: true,
  },
  specialCommissionType: {
    name: "specialCommissionType",
    title: "Special Commission Type",
    type: FormInputTypes.chips,
    placeholder: "Special Commission Type",
    value: !isEmpty(initialValues.businessPartnerDetails.specialCommissionType)
      ? initialValues.businessPartnerDetails.specialCommissionType
      : "",
    multiple: false,
    disabled: false,
    required: false,
    hidden: false,
    conditionalDisable: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      return !isApplicable
    },
    conditionalHidden: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      return !isApplicable
    },
    conditionalRequired: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      return isApplicable
    }
  },
  specialCommissionValue: {
    name: "specialCommissionValue",
    title: "Special Commission Value",
    type: FormInputTypes.formattedNumber,
    placeholder: "Special Commission Value",
    value: initialValues.businessPartnerDetails.specialCommissionValue,
    minValue: 0,
    disabled: false,
    required: false,
    conditionalDisable: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";

      const hideIsCurrent = !isApplicable || !isCurrentValue;

      return hideIsCurrent
    },
    conditionalHidden: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";

      const hideIsCurrent = !isApplicable || !isCurrentValue;

      return hideIsCurrent
    },
    conditionalRequired: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";
      const hideIsCurrent = !isApplicable || !isCurrentValue;

      return !hideIsCurrent
    }
  },
  specialCommissionValueCurrency: {
    name: "specialCommissionValueCurrency",
    title: "Special Commission Value Currency",
    type: FormInputTypes.chips,
    placeholder: "Special Commission Value Currency",
    value: !isEmpty(
      initialValues.businessPartnerDetails.specialCommissionValueCurrency
    )
      ? initialValues.businessPartnerDetails.specialCommissionValueCurrency
      : "",
    multiple: false,
    disabled: false,
    hidden: false,
    required: false,
    conditionalDisable: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";

      const hideIsCurrent = !isApplicable || !isCurrentValue;

      return hideIsCurrent
    },
    conditionalHidden: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";

      const hideIsCurrent = !isApplicable || !isCurrentValue;

      return hideIsCurrent
    },
    conditionalRequired: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";
      const hideIsCurrent = !isApplicable || !isCurrentValue;

      return !hideIsCurrent
    }
  },
  specialCommissionRate: {
    name: "specialCommissionRate",
    title: "Special Commission Rate",
    type: FormInputTypes.percentage,
    placeholder: "Special Commission Rate",
    value: initialValues.businessPartnerDetails.specialCommissionRate,
    disabled: false,
    hidden: false,
    required: false,
    conditionalDisable: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";

      const hideIsRate = !isApplicable || isCurrentValue;

      return hideIsRate;
    },
    conditionalHidden: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";

      const hideIsRate = !isApplicable || isCurrentValue;

      return hideIsRate;
    },
    conditionalRequired: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";

      const hideIsRate = !isApplicable || isCurrentValue;

      return !hideIsRate;
    }
  },
  specialCommissionApplicableOn: {
    name: "specialCommissionApplicableOn",
    title: "Special Commission Applicable On",
    type: FormInputTypes.chips,
    placeholder: "Special Commission Applicable On",
    value: !isEmpty(
      initialValues.businessPartnerDetails.specialCommissionApplicableOn
    )
      ? initialValues.businessPartnerDetails.specialCommissionApplicableOn
      : "",
    disabled: false,
    hidden: false,
    required: false,
    conditionalDisable: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";

      const hideIsRate = !isApplicable || isCurrentValue;

      return hideIsRate;
    },
    conditionalHidden: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";

      const hideIsRate = !isApplicable || isCurrentValue;

      return hideIsRate;
    },
    conditionalRequired: (values) => {
      const isApplicable = values?.specialCommissionApplicable?.toLowerCase() === "yes";
      const isCurrentValue = values.specialCommissionType?.toLowerCase() === "value";

      const hideIsRate = !isApplicable || isCurrentValue;

      return !hideIsRate;
    }
  },
};
