import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import _ from "lodash";
import dayjs from "dayjs";

import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { listQuery } from "./queries";
import { mapToListingData } from "./utils";
import { filterSectionsContent, getHeaders } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { TableSortOrder } from "../../utils/table-utils";
import { capitalizeFirstCharacter } from "../../utils/formatting-utils";

interface ITableOrder {
  orderBy: string;
  orderDirection: "asc" | "desc";
}

const LinesPage: React.FC = () => {
  const navigate = useNavigate();

  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [booted, setBooted] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();
  let setSearchTimeout: NodeJS.Timeout;

  const FILTER_SESSION_KEY = "linesFilter";

  const [filterValues, setFilterValues] = useState<IAbstractRecord>(() => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    return _.isEmpty(savedFilters)
      ? { lineStatus: [], createdDate: [] }
      : savedFilters;
  });

  const [tableOrder, setTableOrder] = useState<Record<string, ITableOrder>>({
    line: {
      orderBy: "",
      orderDirection: "desc",
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [lineListQuery] = useLazyQuery(listQuery());

  const loadLineList = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    orders = tableOrder,
    searchKeyword = keywordSearch
  ) => {
    const result = await lineListQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        lineStatus: filterValues?.status?.length ? filterValues?.status : null,
        createdDateFrom: filterValues?.createdDate?.[0]
          ? dayjs(filterValues?.createdDate?.[0])?.format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
        createdDateTo: filterValues?.createdDate?.[1]
          ? dayjs(filterValues?.createdDate?.[1])?.format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
        Attribute:
          capitalizeFirstCharacter(orders?.line?.orderBy) ||
          "PlanConfigManagement_Line_Name",
        Descending: orders?.line?.orderDirection !== "desc",
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: "all",
    });

    return result;
  };

  const initialize = async () => {
    try {
      const lineListData = await loadLineList();
      const mappedLineList = await mapToListingData(lineListData.data);
      setTableData({ ...mappedLineList, pageNumber: 0 });

      if (lineListData.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }

      const savedFilters = getFilter(FILTER_SESSION_KEY) || filterValues;
      setFilterValues(savedFilters);

      const newFilterSections = filterSectionsContent(
        lineListData.data,
        savedFilters
      );
      setFilterSections(newFilterSections);

      setBooted(true);
    } catch (error) {
      console.error("Error initializing data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (booted) {
      handlePageChange(0, tableData.pageSize, tableOrder, keywordSearch);
    }
  }, [filterValues]);

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrder, val);
    }, 1000);
  };

  const handleSortChange = (orderBy: string, order: string) => {
    const newOrder = _.cloneDeep(tableOrder);

    newOrder.line.orderBy = orderBy;
    newOrder.line.orderDirection = order as any;
    handlePageChange(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder,
      keywordSearch
    );
    setTableOrder(newOrder);
  };

  const handlePageChange = async (
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrder,
    searchKeyword: string = keywordSearch
  ) => {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadLineList(page, pageSize, orders, searchKeyword);
      const mappedSubList = await mapToListingData(result.data);
      setTableData({ ...mappedSubList, pageNumber: page, pageSize });
      setLoadingState(false);
    }
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: "20px" }}>
      <EnhancedTable
        title="Lines"
        name="count"
        orderByAscendingByDefault
        inline={false}
        isServerSide
        data={tableData}
        headers={getHeaders(navigate)}
        handlePageChange={(page: number) =>
          handlePageChange(page, tableData.pageSize)
        }
        handleSearchChange={delaySearch}
        handleSort={handleSortChange}
        handleRowsPerPageChange={(pageSize: number) =>
          handlePageChange(0, pageSize)
        }
        currentPage={tableData.pageNumber}
        hideToolbar={false}
        usePagination
        disableSelection
        loader={loadingState}
        actions={[]}
        ordering={tableOrder.line.orderDirection as TableSortOrder}
        orderingBy={tableOrder.line.orderBy}
        // searchValue={keywordSearch}
      />
    </div>
  );

  const onFilterUpdate = (newFilters: Record<string, any>) => {
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        disabled={loadingState}
        filters={filterSections}
        onApplyFilter={(v) => {
          onFilterUpdate(v);
        }}
      />
    );

  return (
    <StaticLayout
      loading={!booted}
      name="Applications"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default LinesPage;
