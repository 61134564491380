import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";

export const initialValues = {
  addressType: "",
  country: "",
  zone: "",
  city: "",
  street: "",
  building: "",
  addressDetails: "",
  addressDetailsArabic: "",
  isPrimary: false,
};

export const getInputs = (
  values: typeof initialValues = initialValues,
  lovs: Record<string, Record<string, any>> = {}
): Record<string, DynamicFormInputType> => ({
  addressType: {
    name: "addressType",
    title: "Address Type",
    type: FormInputTypes.chips,
    placeholder: "Type",
    value: values.addressType,
    multiple: false,
    disabled: false,
    required: true,
    selectOptions: lovs.addressType,
  },
  country: {
    name: "country",
    title: "Country",
    placeholder: "Country",
    type: FormInputTypes.chips,
    value: values.country,
    multiple: false,
    disabled: false,
    required: true,
    selectOptions: lovs.country,
  },
  zone: {
    name: "zone",
    title: "Zone",
    placeholder: "Zone",
    type: FormInputTypes.chips,
    value: values.zone,
    multiple: false,
    disabled: false,
    required: false,
    allowDeleteValue: true,
    selectOptions: lovs.zone,
  },
  city: {
    name: "city",
    title: "City",
    placeholder: "City",
    type: FormInputTypes.text,
    value: values.city,
    required: true,
    minCharacters: 2,
    maxCharacters: 100,
  },
  street: {
    name: "street",
    title: "Street",
    placeholder: "Street",
    type: FormInputTypes.text,
    value: values.street,
    required: false,
    minCharacters: 2,
    maxCharacters: 100,
  },
  building: {
    name: "building",
    title: "Building",
    placeholder: "Building",
    type: FormInputTypes.text,
    value: values.building,
    required: false,
    minCharacters: 2,
    maxCharacters: 100,
  },
  addressDetails: {
    name: "addressDetails",
    title: "Address Details",
    placeholder: "Address Details",
    type: FormInputTypes.text,
    value: values.addressDetails,
    required: false,
    minCharacters: 2,
    maxCharacters: 100,
  },
  addressDetailsArabic: {
    name: "addressDetailsArabic",
    title: "Address Details Arabic",
    type: FormInputTypes.text,
    placeholder: "Address Details Arabic",
    value: values.addressDetailsArabic,
    required: false,
    minCharacters: 2,
    maxCharacters: 100,
  },
  isPrimary: {
    name: "isPrimary",
    title: "Is Primary",
    type: FormInputTypes.switch,
    placeholder: "Is Primary",
    value: values.isPrimary,
    required: false,
    information:
      "Person can have one primary address only; adding this address as primary will make others secondary.",
  },
});
