import React, { useEffect, useState } from 'react';
import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useLazyQuery } from '@apollo/client';
import {
  getBusinessPartnersQuery,
  getCompaniesListQuery,
  getPolicyBusinessPartnerListQuery,
} from './queries';
import { mapToListingData, toLookupsData } from './utils';
import { filterSectionsContent, headers } from './content';
import EnhancedTable from '../../components/enhanced-table/EnhancedTable';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import ListingFilterWidget from '../../components/widgets/custom-listing-filter/ListingFilterWidget';
import { IAbstractRecord } from '../../models';
import _ from 'lodash';
import { getFilter, setFilter } from '../../utils/filter-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { toast } from 'react-toastify';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import Loader from '../../components/Loader';
import { IEnhanceTableHeaderClickable } from '../../components/enhanced-table';
import { useNavigate } from 'react-router-dom';

interface IReceivablesPayablesPage {}

const ReceivablesPayablesPage: React.FC<IReceivablesPayablesPage> = () => {
  const navigate = useNavigate();
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState('');
  let setSearchTimeout: NodeJS.Timeout;

  const FILTER_SESSION_KEY = 'receivablesPayablesFilter';

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          company: '41',
          businessPartner: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getCompaniesListLazy] = useLazyQuery(getCompaniesListQuery());
  const [getBusinessPartnersLazy] = useLazyQuery(getBusinessPartnersQuery());
  const [getPolicyBusinessPartnerListLazy] = useLazyQuery(
    getPolicyBusinessPartnerListQuery()
  );

  const loadBusinessPartners = async (companyId: string) => {
    const businessPartnersResult = await getBusinessPartnersLazy({
      variables: {
        companyId,
        pageNumber: 1,
        pageSize: 99999999,
      },
    });

    return businessPartnersResult;
  };

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setLoading(true);
    try {
      const companiesListResult = await getCompaniesListLazy();
      if (companiesListResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const businessPartnersResult = await loadBusinessPartners(
        filterV.company || '41'
      );
      if (businessPartnersResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const policyBusinessPartnerListResult =
        await getPolicyBusinessPartnerListLazy({
          variables: {
            keywordSearch: keywordSearch,
            selectedBroker: filterV.businessPartner || [],
            selectedCompany: filterV.company || '41',
            pageNumber: currentPage + 1,
            pageSize,
          },
        });

      if (policyBusinessPartnerListResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      // policyBusinessPartnerListResult.data.Accounting.queries.PolicyBusinessPartnerList.items =
      // 	[
      // 		{
      // 			salesforceManagement_BusinessPartner_Id: "PARTNER_ID_HERE",
      // 			salesforceManagement_BusinessPartner_FullName: "NAME HERE",
      // 			policyBusinessPartnerDetails_PolicyID: "ABD123",
      // 			receivableBills_OutstandingBalanceBasicSystemCurrency: 500,
      // 			receivableBills_OutstandingBalanceSecondarySystemCurrency: 200,
      // 			payableBills_OutstandingBalanceBasicSystemCurrency: 300,
      // 			payableBills_OutstandingBalanceSecondarySystemCurrency: 400,
      // 		},
      // 	];

      const newTableData = mapToListingData(
        policyBusinessPartnerListResult.data
      );
      setTableData({ ...newTableData, pageNumber: currentPage, pageSize });

      const lovs = toLookupsData(
        companiesListResult.data,
        businessPartnersResult.data
      );

      const savedFilters = filterV || getFilter(FILTER_SESSION_KEY);
      setFilterValues(savedFilters);

      if (!Object.keys(lovs.company).length) {
        lovs.company = filterV?.company;
      }

      if (!Object.keys(lovs.businessPartner).length) {
        lovs.businessPartner = filterV?.businessPartner;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);

      setFilter(savedFilters, FILTER_SESSION_KEY);
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    if (_.isEmpty(filterValues) || Object.keys(filterValues).length === 0) {
      await loadData(0, tableData.pageSize, initialFilterValues);
    } else {
      await loadData(0, tableData.pageSize, filterValues);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const handlePageChange = (page: number) => {
    loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = (numberOfRecordsPerPage: number) => {
    loadData(0, numberOfRecordsPerPage, filterValues);
  };

  const onFilterUpdate = (v: Record<string, any>) => {
    if (_.isEqual(v, filterValues)) {
      return;
    }
    const newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    loadData(0, tableData.pageSize, newFilters);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      setKeywordSearch(val);
      handlePageChange(0);
    }, 1000);
  };

  (headers.businessPartnerName as IEnhanceTableHeaderClickable).callback = (
    payload: any
  ) => {
    navigate(
      '/accounting/receivables-payables/' + payload.columns.businessPartnerId
    );
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: '20px' }}>
      <EnhancedTable
        title='Receivables & Payables By Partner'
        name='receivables-payables'
        orderByAscendingByDefault
        hideToolbar={false}
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        handleSearchChange={delaySearch}
        currentPage={tableData.pageNumber}
        loader={loading}
        showInlineFilter
        disableSelection
        usePagination
        showCellFullData
      />
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=''
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name='Transfers'
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ReceivablesPayablesPage;
