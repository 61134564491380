import React, { useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { EnhancedDisplayType } from "../../../components/enhanced-form/EnhancedDisplay";
import { IEntityInfoView } from "../../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../../components/widgets/entity-info/EntityInfoWidget";
import { ITenant } from "../../../redux/tenant/types";

export const useStyles = makeStyles()(() => ({
  paperClass: {
    boxShadow: "none !important",
  },
  updateBtn: {
    margin: "15px auto 0",
  },
}));

interface IPolicyCoverEntityInfoWidgetProps {
  data: Record<string, any>;
  tenant: ITenant;
  handleIconClick: () => void;
}

const PolicyCoverEntityInfoWidget: React.FC<
  IPolicyCoverEntityInfoWidgetProps
> = ({ data, tenant, handleIconClick }) => {
  const entityView = useMemo(() => {
    const result: IEntityInfoView = {
      name: "section1",
      title: "Policy Cover Details",
      iconUrl: "",
      sections: [
        {
          name: "policyCoverDetailsSection1",
          title: "",
          properties: {
            lineName: {
              value: data?.lineName,
              multiline: false,
              name: "lineName",
              title: "Line",
              type: EnhancedDisplayType.RelativeLink,
              tooltip: false,
              url: "/plan/lines/" + data?.lineId,
            },
            externalCode: {
              value: data?.externalCode,
              multiline: false,
              name: "externalCode",
              title: "External Code",
            },
            arabicName: {
              value: data?.arabicName,
              multiline: false,
              name: "arabicName",
              title: "Arabic Name",
            },
            printingOrder: {
              value: data?.printingOrder,
              multiline: false,
              name: "printingOrder",
              title: "Printing Order",
            },
            policyCoverDescription: {
              value: data?.policyCoverDescription,
              multiline: false,
              name: "policyCoverDescription",
              title: "Description",
            },
          },
        },
        {
          name: "policyCoverDetailsSection2",
          title: "",
          properties: {
            createdBy: {
              value: data?.createdBy,
              multiline: false,
              name: "createdBy",
              title: "Created By",
            },
            createdOn: {
              value: data?.createdOn,
              multiline: false,
              type: EnhancedDisplayType.Date,
              name: "createdOn",
              title: "Created On",
            },
            modifiedBy: {
              value: data?.modifiedBy,
              multiline: false,
              name: "modifiedBy",
              title: "Modified By",
            },
            modifiedOn: {
              value: data?.modifiedOn,
              multiline: false,
              type: EnhancedDisplayType.Date,
              name: "modifiedOn",
              title: "Modified On",
            },
          },
        },
      ],
      socialMedia: {
        facebook: "",
        linkedIn: "",
        twitter: "",
      },
    };

    return result;
  }, [data]);

  return (
    <EntityInfoWidget
      background="rgb(193, 0, 0)"
      classification={{ classifications: {}, value: "" }}
      progress={100}
      supportsClassification={true}
      supportsProgressBar={true}
      title={data.policyCoverName}
      view={entityView}
      iconUrl={tenant.cdnUrl + "/icons/edit-secondary.svg"}
      onClickIcon={handleIconClick}
    ></EntityInfoWidget>
  );
};

export default PolicyCoverEntityInfoWidget;
