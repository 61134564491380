import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  agencyInfo: {
    agencyId: "",
    agencyName: "",
    agencyStatus: "",
    agencyType: "",
    agencyCode: "",
    mtpCommissionPercentage: "",
    mbiCommissionPercentage: "",
    expatCommissionPercentage: "",
    ufaCode: "",
  },
};

export const inputs: Record<string, DynamicFormInputType> = {
  agencyName: {
    name: "agencyName",
    title: "Name",
    type: FormInputTypes.text,
    placeholder: "Name",
    value: initialValues.agencyInfo.agencyName,
    required: true,
  },
  agencyType: {
    name: "agencyType",
    title: "Type",
    type: FormInputTypes.chips,
    placeholder: "Type",
    value: !isEmpty(initialValues.agencyInfo.agencyType)
      ? initialValues.agencyInfo.agencyType
      : [],
    required: true,
    multiple: false,
  },
  agencyStatus: {
    name: "agencyStatus",
    title: "Status",
    type: FormInputTypes.chips,
    placeholder: "Status",
    value: !isEmpty(initialValues.agencyInfo.agencyStatus)
      ? initialValues.agencyInfo.agencyStatus
      : [],
    required: true,
    multiple: false,
  },
  agencyCode: {
    name: "agencyCode",
    title: "Company Code",
    type: FormInputTypes.chips,
    placeholder: "Company Code",
    value: !isEmpty(initialValues.agencyInfo.agencyCode)
      ? initialValues.agencyInfo.agencyCode
      : [],
    required: true,
    multiple: false,
  },
  mtpCommissionPercentage: {
    name: "mtpCommissionPercentage",
    title: "MTP Commission Percentage",
    type: FormInputTypes.number,
    placeholder: "MTP Commission Percentage",
    value: initialValues.agencyInfo.mtpCommissionPercentage,
    required: true,
    minNumber: 0,
    maxNumber: 100,
  },
  mbiCommissionPercentage: {
    name: "mbiCommissionPercentage",
    title: "MBI Commission Percentage",
    type: FormInputTypes.number,
    placeholder: "MBI Commission Percentage",
    value: initialValues.agencyInfo.mbiCommissionPercentage,
    required: true,
    minNumber: 0,
    maxNumber: 100,
  },
  expatCommissionPercentage: {
    name: "expatCommissionPercentage",
    title: "Expat Commission Percentage",
    type: FormInputTypes.number,
    placeholder: "Expat Commission Percentage",
    value: initialValues.agencyInfo.expatCommissionPercentage,
    required: true,
    minNumber: 0,
    maxNumber: 100,
  },
  ufaCode: {
    name: "ufaCode",
    title: "UFA Code",
    placeholder: "UFA Code",
    type: FormInputTypes.text,
    value: initialValues.agencyInfo.ufaCode,
    required: true,
  },
};
