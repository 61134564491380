import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { REGEX } from '../../utils/validationUtils';

export const initialValues = {
  person: '',
  email: '',
  password: '',
  type: '',
  account: '',
  access: '',
  SalesforceManagement_BusinessUserStatuses: 'NEW',
};

export const inputs = (
  businessUserId: string,
  defaultValues: Record<string, any> = initialValues,
  lookups: Record<string, Record<string, string>>,
): Record<string, DynamicFormInputType> => {
  return {
    person: {
      name: 'person',
      title: 'Person',
      type: FormInputTypes.chips,
      placeholder: 'Type to search for Person',
      value: defaultValues.person,
      multiple: false,
      disabled: false,
      required: !businessUserId,
      hasPagination: false,
      itemsPerPage: 15,
      hidden: !!businessUserId,
      // onChange: (option) => customPersonOnChange(option),
      // error: customPersonError,
      selectOptions: lookups['person'],
    },
    email: {
      name: 'email',
      title: 'Email',
      placeholder: 'Email',
      type: FormInputTypes.text,
      value: defaultValues.email,
      required: false,
      disabled: true,
      hidden: !!businessUserId,
    },
    password: {
      name: 'password',
      title: 'Password',
      placeholder: 'Password',
      type: FormInputTypes.password,
      value: defaultValues.password,
      regex: REGEX.password,
      includePasswordHint: true,
      includePasswordVisibility: true,
      includeCapsLockCheck: false,
      required: false,
      disabled: false,
      autoCompelte: 'new-password',
      hidden: !!businessUserId,
    },
    type: {
      name: 'type',
      title: 'Type',
      type: FormInputTypes.chips,
      placeholder: 'Type',
      value: defaultValues.type,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lookups['type'],
    },
    account: {
      name: 'account',
      title: 'Account',
      placeholder: 'Account',
      type: FormInputTypes.text,
      value: defaultValues.account,
      required: false,
      disabled: false,
    },
    access: {
      name: 'access',
      title: 'Access',
      type: FormInputTypes.chips,
      placeholder: 'Access',
      value: defaultValues.access,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lookups['access'],
    },
    SalesforceManagement_BusinessUserStatuses: {
      name: 'SalesforceManagement_BusinessUserStatuses',
      title: 'Status',
      type: FormInputTypes.chips,
      value: defaultValues.SalesforceManagement_BusinessUserStatuses || "",
      required: !businessUserId,
      multiple: false,
      disabled: true,
      hidden: !!businessUserId,
      selectOptions: lookups['SalesforceManagement_BusinessUserStatuses'],
    },
  };
};
