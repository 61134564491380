import {
  convertToFixedNumber,
  numberToPercentage,
} from "../../../../../utils/helper-utils";
import { IProposalDetailsSummary } from "./index2";

export interface IPayerData {
  payerId: string;
  fullName?: string;
  payerAddress: string;
  ownerIsDifferent: boolean;
  addressDetails?: string;
}

export interface IOwnerData {
  ownerId?: string;
  fullName?: string;
  ownerAddress?: string;
  addressDetails?: string;
}

export interface IInsuredData {
  id: string;
  order: string;
  fullName?: string;
  address?: string;
  addressDetails?: string;
}

export interface IBeneficiaryData {
  id: string;
  address: string;
  percentage: number;
  fullName?: string;
  addressDetails?: string;
}

export interface IPolicyPersons {
  payer: IPayerData;
  owner?: IOwnerData;
  insured: IInsuredData[];
  beneficiaries?: IBeneficiaryData[];
}

export interface IProposalPageFormData {
  policyPersons: {
    payer: IPayerData;
    owner?: IOwnerData;
    insured: IInsuredData[];
    beneficiaries?: IBeneficiaryData[];
  };
  covers: IProposalCover[];
  costs: IProposalCostCharges;
  bankersDetails?: IProposalBankerDetails[];
  marineDetails?: IProposalMarineDetails;
  marineHullDetails?: IProposalMarineHullDetails;
  // expatDetails: IProposalExpatDetails;
}

export interface IProposalCover {
  isMain: boolean;
  isMandatory: boolean;
  isEditable: boolean;
  policyCover: string;
  coverPremiumType: string;
  coverPremiumPercentage?: number;
  coverPremiumValue?: number;
  currency: string;
  coverSumInsured?: number;
  sumInsuredIsAdditive: boolean;
  excessOnClaimType: string;
  excessOnClaimAmount?: number;
  excessOnClaimPercentage?: number;
  excessOnClaimDays?: number;
  name?: string;
  clauseID: string;
  coverEffectiveFrom: string;
  coverEffectiveTo: string;
}

export interface IProposalCostCharges {
  policyCost: number;
  chargesPercentage: number;
  tpaFeesType: string;
  tpaFeesAmount?: number;
  tpaFeesPercentage?: number;
  tpaFeesApplicableOn: string;
}

export interface IProposalBankerDetails {
  branchName: string;
  isMain: string;
  isAgency: string;
  numberOfEmployees: number;
  underwritingYear: number;
  infidelity: number;
  burglary: number;
  holdUp: number;
  inSafe: number;
  inTransit: number;
  forgedChecks: number;
  counterfeitCurrencies: number;
  clause6: number;
  falsification: number;
}

export interface IProposalMarineDetails {
  matter: string;
  description: string;
  vesselName: string;
  vesselAge: number;
  from: string;
  to: string;
  via: string;
  survey: string;
  cargoContract: string;
  sumPerShipment: number;
  lcNumber: string;
  lcIssueDate: string;
  bank: string;
  claimsPayableBy: string;
  loading: number;
  remarks: string;
}

export interface IProposalMarineHullDetails {
  vesselName: string;
  connectionPort: string;
  territorialWaters: string;
  usage: string;
  builder: string;
  constructionYear: string;
  registeryPort: string;
  length: string;
  weight: string;
  height: string;
  engineType: string;
  engineNumber: string;
  engineYearBuilt: string;
  engineHorsePower: string;
  engineMaxDesignedSpeed: string;
  engineFuel: string;
  remarks: string;
}

export interface IProposalPageFormState {
  values: IProposalPageFormData;
  errors: {
    policyPersons: {
      payer: Record<string, string>;
      owner: Record<string, string>;
      insured: Record<string, string>[];
      beneficiaries?: Record<string, string>[];
    };
    covers: Record<string, string>[];
    costs: Record<string, string>;
    bankersDetails: Record<string, string>[];
    marineDetails: Record<string, string>;
    marineHullDetails: Record<string, string>;
  };
  touched: {
    policyPersons: {
      payer: Record<string, boolean>;
      owner: Record<string, boolean>;
      insured: Record<string, boolean>[];
      beneficiaries?: Record<string, boolean>[];
    };
    covers: Record<string, boolean>[];
    costs: Record<string, boolean>;
    bankersDetails: Record<string, boolean>[];
    marineDetails: Record<string, boolean>;
    marineHullDetails: Record<string, boolean>;
    // expatDetails: Record<string, boolean>;
  };
}

export const getProposalPageFormState = (
  summary?: IProposalDetailsSummary,
  covers: IProposalCover[] = [],
  policyPersons?: IPolicyPersons,
  bankersDetails: IProposalBankerDetails[] = []
): IProposalPageFormState => ({
  values: {
    policyPersons,
    covers: covers,
    bankersDetails: bankersDetails,
    costs: {
      policyCost: convertToFixedNumber(summary?.PricingOption.PolicyCost),
      chargesPercentage: numberToPercentage(
        summary?.PricingOption.ChargesPercentage
      ),
      tpaFeesType: summary?.PricingOption.TPAFeesType,
      tpaFeesAmount: convertToFixedNumber(summary?.PricingOption.TPAFeesAmount),
      tpaFeesPercentage: numberToPercentage(
        summary?.PricingOption.TPAFeesPercentage
      ),
      tpaFeesApplicableOn: summary?.PricingOption.TPAFeesApplicableOn,
    },
    marineDetails: {
      matter: summary?.MarineDetails?.Matter?.Code || "",
      description: summary?.MarineDetails?.Description || "",
      vesselName: summary?.MarineDetails?.VesselName || "",
      vesselAge: summary?.MarineDetails?.VesselAge || null,
      from: summary?.MarineDetails?.From || "",
      to: summary?.MarineDetails?.To || "",
      via: summary?.MarineDetails?.Via || "",
      survey: summary?.MarineDetails?.Survey || "",
      cargoContract: summary?.MarineDetails?.CargoContract || "",
      sumPerShipment: summary?.MarineDetails?.SumPerShipment || null,
      lcNumber: summary?.MarineDetails?.LCNumber || "",
      lcIssueDate: summary?.MarineDetails?.LCIssueDate || "",
      bank: summary?.MarineDetails?.Bank || "",
      claimsPayableBy: summary?.MarineDetails?.ClaimsPayableBy || "",
      loading: summary?.MarineDetails?.Loading || null,
      remarks: summary?.MarineDetails?.Remarks || "",
    },
    marineHullDetails: {
      vesselName: summary?.MarineHullDetails?.VesselName || "",
      connectionPort: summary?.MarineHullDetails?.ConnectionPort || "",
      territorialWaters: summary?.MarineHullDetails?.TerritorialWaters || "",
      usage: summary?.MarineHullDetails?.Usage || "",
      builder: summary?.MarineHullDetails?.Builder || "",
      constructionYear: summary?.MarineHullDetails?.ConstructionYear || "",
      registeryPort: summary?.MarineHullDetails?.RegisteryPort || "",
      length: summary?.MarineHullDetails?.Length || "",
      weight: summary?.MarineHullDetails?.Weight || "",
      height: summary?.MarineHullDetails?.Height || "",
      engineType: summary?.MarineHullDetails?.EngineType || "",
      engineNumber: summary?.MarineHullDetails?.EngineNumber || "",
      engineYearBuilt: summary?.MarineHullDetails?.EngineYearBuilt || "",
      engineHorsePower: summary?.MarineHullDetails?.EngineHorsePower || "",
      engineMaxDesignedSpeed:
        summary?.MarineHullDetails?.EngineMaxDesignedSpeed || "",
      engineFuel: summary?.MarineHullDetails?.EngineFuel || "",
      remarks: summary?.MarineHullDetails?.Remarks || "",
    },
  },
  errors: {
    policyPersons: {
      payer: {
        payerId: "",
        payerAddress: "",
        ownerIsDifferent: "",
      },
      owner: {
        ownerId: "",
        ownerAddress: "",
      },
      insured: [
        {
          id: "",
          order: "",
          address: "",
        },
      ],
      beneficiaries: [
        {
          id: "",
          order: "",
          address: "",
          percentage: "",
        },
      ],
    },
    covers: covers.map((c) => ({
      isMain: "",
      isMandatory: "",
      coverPremiumType: "",
      coverPremiumPercentage: "",
      coverPremiumValue: "",
      currency: "",
      coverSumInsured: "",
      sumInsuredIsAdditive: "",
      excessOnClaimType: "",
      excessOnClaimAmount: "",
      excessOnClaimPercentage: "",
      excessOnClaimDays: "",
      coverEffectiveFrom: "",
      coverEffectiveTo: "",
    })),
    costs: {},
    bankersDetails: bankersDetails.map((b) => ({
      branchName: "",
      isMain: "",
      isAgency: "",
      numberOfEmployees: "",
      underwritingYear: "",
      infidelity: "",
      burglary: "",
      holdUp: "",
      inSafe: "",
      inTransit: "",
      forgedChecks: "",
      counterfeitCurrencies: "",
      clause6: "",
      falsification: "",
    })),
    marineDetails: {},
    marineHullDetails: {
      vesselName: "",
      connectionPort: "",
      territorialWaters: "",
      usage: "",
      builder: "",
      constructionYear: "",
      registeryPort: "",
      length: "",
      weight: "",
      height: "",
      engineType: "",
      engineNumber: "",
      engineYearBuilt: "",
      engineHorsePower: "",
      engineMaxDesignedSpeed: "",
      engineFuel: "",
      remarks: "",
    },
  },
  touched: {
    policyPersons: {
      payer: {
        payerId: false,
        payerAddress: false,
        ownerIsDifferent: false,
      },
      owner: {
        ownerId: false,
        ownerAddress: false,
      },
      insured: [
        {
          id: false,
          order: false,
          address: false,
        },
      ],
      beneficiaries: [
        {
          id: false,
          address: false,
          percentage: false,
        },
      ],
    },
    covers: covers.map((_c) => ({
      isMain: false,
      isMandatory: false,
      coverPremiumType: false,
      coverPremiumPercentage: false,
      coverPremiumValue: false,
      currency: false,
      coverSumInsured: false,
      sumInsuredIsAdditive: false,
      excessOnClaimType: false,
      excessOnClaimAmount: false,
      excessOnClaimPercentage: false,
      excessOnClaimDays: false,
      coverEffectiveFrom: false,
      coverEffectiveTo: false,
    })),
    costs: {},
    bankersDetails: bankersDetails.map((_b) => ({
      branchName: false,
      isMain: false,
      isAgency: false,
      numberOfEmployees: false,
      underwritingYear: false,
      infidelity: false,
      burglary: false,
      holdUp: false,
      inSafe: false,
      inTransit: false,
      forgedChecks: false,
      counterfeitCurrencies: false,
      clause6: false,
      falsification: false,
    })),
    marineDetails: {},
    marineHullDetails: {
      vesselName: false,
      connectionPort: false,
      territorialWaters: false,
      usage: false,
      builder: false,
      constructionYear: false,
      registeryPort: false,
      length: false,
      weight: false,
      height: false,
      engineType: false,
      engineNumber: false,
      engineYearBuilt: false,
      engineHorsePower: false,
      engineMaxDesignedSpeed: false,
      engineFuel: false,
      remarks: false,
    },
  },
});
