import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const personEnums: Record<string, Record<string, string>> = {};

  personEnums[data.SalesforceManagement_PersonTypes.name] =
    enumListAsRecordObject(data.SalesforceManagement_PersonTypes.enumValues);

  if (personEnums[data.SalesforceManagement_PersonTypes.name]["PERSON"]) {
    personEnums[data.SalesforceManagement_PersonTypes.name]["PERSON"] =
      "Individual";
  }

  personEnums["phoneType"] = enumListAsRecordObject(
    data?.Insurance_PhoneType?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );
  personEnums["genders"] = enumListAsRecordObject(
    data?.Insurance_Genders?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  personEnums["titles"] = lookupListAsRecordObject(
    data.Insurance.lookups.titles
  );

  personEnums["nationalities"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.nationalities
  );

  personEnums["occupationClasses"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.occupationClasses
  );

  personEnums["addressType"] = enumListAsRecordObject(
    data?.Core_AddressTypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  personEnums["countries"] = lookupListAsRecordObject(
    data?.Insurance?.lookups?.countries
  );

  return personEnums;
}

export function graphqlEntityToPersonInfo(data: any): IPolicyPersonInfo {
  return {
    personId: "",
    type: data?.SalesforceManagement?.entities?.person?.views
      ?.SalesforceManagement_all?.properties?.PersonType,
    companyName:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.CompanyName,
    title:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.Title?.Code,
    firstName:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.FirstName,
    middleName:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.MiddleName,
    lastName:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.LastName,
    dateOfBirth:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.DateOfBirth,
    email:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.Email,
    phoneType:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.PhoneDetails?.PhoneType,
    phoneNumber:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.PhoneDetails?.PhoneNumber,
    nationalityOne:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,

    isTaxable:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
    occupationClass:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
    profession:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
    employer:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
    dateOfEmployment:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,

    addressType:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
    country:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
    zone: data?.SalesforceManagement?.entities?.person?.views
      ?.SalesforceManagement_all?.properties?.NationalityOne,
    city: data?.SalesforceManagement?.entities?.person?.views
      ?.SalesforceManagement_all?.properties?.NationalityOne,
    street:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
    building:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
    addressDetails:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
    addressDetailsArabic:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
    isPrimary:
      data?.SalesforceManagement?.entities?.person?.views
        ?.SalesforceManagement_all?.properties?.NationalityOne,
  };
}
