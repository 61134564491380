import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  createAccount,
  getAccountingEnums,
  updateAccount,
  getAccountInfo,
  getLayer4Groups
} from "./queries";
import { LookupToList, graphqlEntityToAccountInfo, EntityLayer4ToList } from "./utils";
import Loader from "../../components/Loader";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { isEmpty } from "../../utils/validationUtils";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { getError } from "../../utils/graph-utils";
import { useNavigate } from "react-router-dom";

const AccountDrawer: React.FC<IAccountDrawerProps> = ({
  accountId,
  open,
  onSuccess,
  onClose,
}) => {

  const navigate = useNavigate();

  // const accountEnumResults = useQuery(getAccountingEnums(), {
  //   fetchPolicy: "no-cache",
  // });


  const [accountEnumResultsQeury] = useLazyQuery(getAccountingEnums());
  const [layer4] = useLazyQuery(getLayer4Groups());

  const [accountAction] = useMutation(
    accountId ? updateAccount() : createAccount()
  );

  const [accountInfoQuery] = useLazyQuery(getAccountInfo());

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    currency: {},
    relatedCompanies: {}
  });

  const [layer2Group, setLayer2Group] = useState<Record<string, string>>({

  });
  const [layer3Group, setLayer3Group] = useState<Record<string, string>>({

  });
  const [layer4Group, setLayer4Group] = useState<Record<string, string>>({

  });


  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>();

  const [booted, setBooted] = useState<boolean>(false);

  const initialize = async () => {
    setBooted(false);
    try {

      const values = {
        company: '',
        parentCategory: '',
        category: '',
        subCategory: '',
        name: '',
        id: '',
        currency: '',
        subCategoryCode: ''
      };

      let newLovs: Record<string, Record<string, string>> = {};
      let newLayer2Group: Record<string, string> = {};
      let newLayer3Group: Record<string, string> = {};
      let newLayer4Group: Record<string, string> = {};

      const promises: Promise<any>[] = [];
      const accountEnumResultsQeuryPromise = accountEnumResultsQeury({
        fetchPolicy: "no-cache"
      });

      promises.push(accountEnumResultsQeuryPromise);

      if (accountId) {
        const accountInfoResultPromise = accountInfoQuery({
          variables: { id: accountId },
          fetchPolicy: "no-cache",
        });
        promises.push(accountInfoResultPromise);
      }

      const result = await Promise.all(promises);

      if (result[0]?.data) {
        const newAccountEnums = LookupToList(result[0].data);

        newLovs = {
          type: newAccountEnums["Type"],
          currency: newAccountEnums["Currency"],
          relatedCompanies: newAccountEnums["relatedCompanies"]
        }
      }

      if (accountId && result[1]?.data) {
        if (result[1]?.data) {
          const accountEntity = graphqlEntityToAccountInfo(result[1]?.data);
          if (accountEntity) {
            values.name = accountEntity.name;
            values.subCategory = accountEntity.subCategory === "" ? null : accountEntity.subCategory;
            values.id = accountEntity.id.substring(4, 8);
            values.currency =
              accountEntity.currency;
            values.company =
              accountEntity.company;
            values.subCategoryCode = isEmpty(accountEntity.subCategory) ? "" : accountEntity.subCategory.substring(0, 4);
          }
        }
      }
      setLovs(newLovs);
      setLayer2Group(newLayer2Group);
      setLayer3Group(newLayer3Group);
      setLayer4Group(newLayer4Group);
      const newInputForm = inputs(!!accountId ? "edit" : "add", values, newLovs, newLayer2Group, newLayer3Group, newLayer4Group);

      setInputsForm(newInputForm);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setBooted(true);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputsForm, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variables = {
        EnteredID: data.subCategoryCode + data.id,
        EnteredName: data.name,
        SelectedCompany: data.company,
        SelectedCurrency: data.currency,
        SubCategory: data.subCategory === '' ? null : data.subCategory,
      };

      accountAction({
        variables: accountId ? { UpdatedName: data.name, CurrentAccountID: accountId } : variables,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {accountId
                ? "Account successfully updated"
                : "Account successfully created"}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
            if (!accountId) {
              navigate(`/accounting/accounts`);
            }
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const onCustomChange = async (fieldName: string, value: any, allValues: Record<string, any>, errors: Record<string, any>) => {
    const currentMode = !!accountId ? "edit" : "add";
    const newLovs = { ...lovs };
    let newLayer2Group = { ...layer2Group }
    let newLayer3Group = { ...layer3Group }
    let newLayer4Group = { ...layer4Group }
    let newInputForm = inputs(currentMode, allValues, newLovs, layer2Group, layer3Group, layer4Group);

    if (fieldName === "company") {
      const promises: Promise<any>[] = [];
      allValues.subCategory = '';
      allValues.subCategoryCode = ''
      newLayer2Group = {};
      newLayer3Group = {};
      newLayer4Group = {};

      setLayer2Group(newLayer2Group);
      setLayer3Group(newLayer3Group);
      setLayer4Group(newLayer4Group);
      const layer4Promise = layer4({
        variables: { SelectedCompany: allValues.company === '' ? null : allValues.company },
        fetchPolicy: "no-cache"
      });

      promises.push(layer4Promise);

      const result = await Promise.all(promises);

      if (result[0]?.data) {
        newLayer4Group = EntityLayer4ToList(result[0].data);
      }
      setLayer4Group(newLayer4Group);
    }

    if (fieldName === "subCategory") {
      allValues.subCategoryCode = newLayer4Group[allValues.subCategory].substring(0,4);
    }
    
    newInputForm = inputs(currentMode, allValues, newLovs, newLayer2Group, newLayer3Group, newLayer4Group);

    setInputsForm(newInputForm);
  }


  const onCustomBlur = async (fieldName: string, values: Record<string, any>, errors: Record<string, any>) => {

    const newValues = { ...values };
    const newErrors = { ...errors };
    const currentMode = !!accountId ? "edit" : "add";

    if (fieldName === "id") {
      setFormDisabled(true);
      if((newValues.id.length > 4 || newValues.id.length < 4) && currentMode === "add") {
        newErrors[fieldName] = 'Entered Account ID must have a total of 4 digits';
      }
      else {
        setSubmitButtonState(undefined);
      }
      setFormDisabled(false);
    }

    return { values: newValues, errors: newErrors };
  }

  const onCustomValidateForm = async (
    v: Record<string, any>,
    errors: Record<string, string>
  ) => {

    const currentMode = !!accountId ? "edit" : "add";
    if (!errors.id) {
      if((v.id.length > 4 || v.id.length < 4) && currentMode === "add") {
        errors.id = 'Entered Account ID must have a total of 4 digits'
      }
    }

    return errors;
  };

  return (
    <GenericDrawer
      title={accountId ? "Modify Auxiliary" : "Add New Auxiliary"}
      onClose={() => onClose()}
      isOpen={open}
    >


      {(!booted || !inputsForm) && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            onChange={onCustomChange}
            onCustomBlur={onCustomBlur}
            onCustomValidate = {onCustomValidateForm}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default AccountDrawer;
