import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import EnhancedButton from "../components/EnhancedButton";
import EnhancedDatePicker from "../components/enhanced-form/EnhancedDatePicker";
import EnhancedInput from "../components/enhanced-form/EnhancedInput";
import EnhancedInternationalPhoneInput from "../components/enhanced-form/EnhancedInternationalPhoneInput";
import EnhancedNumberInput from "../components/enhanced-form/EnhancedNumberInput";
import EnhancedSwitch from "../components/enhanced-form/EnhancedSwitch";
import EnhancedUploader from "../components/enhanced-form/EnhancedUploader";
import { MAIN_ONE_THEME, mainFontFamilyBold } from "../constants";
import { useAppSelector } from "../redux/hooks";
import { validateDynamicForm } from "../utils/dynamic-utils";
import { isEmpty } from "../utils/validationUtils";
import {
  DynamicFormInputType,
  FormInputTypes,
  IDynamicSection,
  IDynamicWithSectionsForm,
} from "./index";
import EnhancedCurrencyInput from "../components/enhanced-form/EnhancedCurrencyInput";
import EnhancedPercentageInput from "../components/enhanced-form/EnhancedPercentageInput";
import EnhancedLink from "../components/enhanced-form/EnhancedLink";
import EnhancedInlinePaginatedChipInput from "../components/enhanced-form/EnhancedInlinePaginatedChipInput";
import EnhancedCheckbox from "../components/EnhancedCheckbox";
import NewChipsInput from "../components/enhanced-form/NewChipsInput";

const useStyles = makeStyles<{ secondaryButton: boolean }>()(
  (theme, { secondaryButton }) => ({
    form: {
      width: "100%",
    },
    submitBtn: {
      margin: "0 auto 25px auto!important",
      display: "flex!important",
    },
    footerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: secondaryButton ? "space-between" : "center",
    },
    titleStyling: {
      fontFamily: mainFontFamilyBold,
      lineHeight: "18px",
    },
  })
);

const SectionDynamicForm: React.FC<
  IDynamicWithSectionsForm & {
    children?: React.ReactNode;
    onCustomBlur?: (fieldName: string, values: Record<string, any>, errors: Record<string, any>) => Promise<{ values: Record<string, any>; errors: Record<string, any> }>
    onCustomValidate?: (values: Record<string, any>, errors: Record<string, any>) => Promise<Record<string, string>>
    onSearch?: (fieldName: string, value: string) => Promise<void>;
  }
> = ({
  title,
  sections,
  buttonText,
  onSubmit,
  disableForm = false,
  submitButtonState = null,
  isSubmitButtonDisabled = false,
  children,
  secondaryButton = false,
  secondaryButtonText = "",
  onClickSecondaryButton = () => {},
  mainButtonBgColor = "",
  loadingFields = {},
  customStyles,
  onChange,
  hasDoprdownSpecificBehavior = false,
  itemsPerPage,
  areNextToEachOther = false,
  popUpStyling = false,
  onCustomBlur,
  onCustomValidate,
  onSearch,
  ...restOfProps
}) => {
  const tenant = useAppSelector((state) => state.tenant);

  const [values, setValues] = useState<Record<string, any>>();
  const [errors, setErrors] = useState<Record<string, any>>();
  const [touched, setTouched] = useState<Record<string, boolean>>();

  const [validatingForm, setValidatingForm] = useState<boolean>(false);

  const { classes } = useStyles({ secondaryButton });

  const inputs = useMemo(() => {
    let inputs: Record<string, DynamicFormInputType> = {};

    Object.values(sections).forEach((section) => {
      inputs = { ...inputs, ...section.inputs };
    });
    return inputs;
  }, [sections]);

  useEffect(() => {
    const initialValues: Record<string, any> = values || {};
    const initialErrors: Record<string, string> =  errors || {};
    const initialTouched: Record<string, boolean> = touched || {};
    let sectionInputs: Record<string, DynamicFormInputType> = {};

    let mappedValues = _.cloneDeep(values);

    if (!mappedValues) {
      mappedValues = {};

      Object.keys(inputs).forEach((inputKey) => {
        mappedValues[inputKey] = inputs[inputKey]?.value;
      });

    Object.values(sections).forEach((section) => {
      sectionInputs = { ...sectionInputs, ...section.inputs };
    });

    Object.values(sectionInputs).forEach((input) => {
      initialValues[input.name] = mappedValues[input.name];
      initialErrors[input.name] = initialErrors[input.name] || input.error || "";
      initialTouched[input.name] = initialTouched[input.name] || !!input.error;
    });

    setValues(initialValues);
    setErrors(initialErrors);
    setTouched(initialTouched);
  }
}, [sections]);

  const onFieldBlur = async (name: string) => {
    let touchedTemp = Object.assign({}, touched);
    touchedTemp[name] = true;


    if (onCustomBlur) {
      setValidatingForm(true);
      const result = await onCustomBlur(name, {...values}, {...errors});
      setValidatingForm(false);
      setValues(result.values);
      setErrors(result.errors);
    }

    setTouched(touchedTemp);
  };

  const onFieldChange = (name: string, value: any) => {
    let errorsTemp = Object.assign({}, errors);
    let valuesTemp = Object.assign({}, values);
    let touchedTemp = Object.assign({}, touched);
    valuesTemp[name] = value;
    errorsTemp[name] = validateDynamicForm(value, inputs[name], values);
    if (onChange) {
      onChange(name, value, valuesTemp, errorsTemp, touchedTemp);
    }

    setValues(valuesTemp);
    setErrors(errorsTemp);
    setTouched(touchedTemp);
  };

  const onFieldChangeByEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFieldChange(event.target.name, event.target.value);
  };

  const validate = async (): Promise<Record<string, string>> => {
    let newErrors: Record<string, string> = {};
    Object.values(inputs).forEach((input) => {
      newErrors[input.name] = validateDynamicForm(
        values[input.name],
        input,
        values
      );
    });

    if (onCustomValidate) {
      newErrors = await onCustomValidate(values, newErrors);
    }

    return newErrors;
  };

  const submit = async () => {
    if (!disableForm && !validatingForm) {
      setValidatingForm(true);
      const validateErrors = await validate();
      setValidatingForm(false);
      const isValid = Object.values(validateErrors).every((v) => isEmpty(v));
      if (isValid) {
        onSubmit(values);
      } else {
        const initialTouched: Record<string, boolean> = {};
        Object.values(inputs).forEach((input) => {
          initialTouched[input.name] = true;
        });
        setErrors(validateErrors);
        setTouched(initialTouched);
      }
    }
  };

  const renderInput = (input: DynamicFormInputType) => {

    const disabledInput = input.disabled || (input.conditionalDisable && input.conditionalDisable(values)) || disableForm || validatingForm;

    const hidden = input.hidden || (input.conditionalHidden && input.conditionalHidden(values));

    if (hidden) {
      return <></>;
    }

    const isRequired = (input.required || (input.conditionalRequired && input.conditionalRequired(values)));
    const title = isRequired ? `${input.title}*` : input.title;

    switch (input.type) {
      case FormInputTypes.text:
        return (
          <EnhancedInput
            key={input.name}
            name={input.name}
            title={title}
            type="text"
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(event) => {
              const newValue = event.target.value;
              onFieldBlur(input.name);
              if (!isEmpty(newValue)) {
                onFieldChange(input.name, newValue);
                if (input.onChange) {
                  input.onChange(event);
                }
              }
            }}
            onChange={(v) => onFieldChange(input.name, v.target.value)}
            disabled={disabledInput}
            description={input.description}
            multiline={input.multiline}
            material={input.material}
            customStyles={input.customStyles}
          />
        );
        case FormInputTypes.checkbox:
            return (
              <EnhancedCheckbox
                key={input.name}
                name={input.name}
                title={title}
                checked={values[input.name]}
                onChange={(name, val) => onFieldChange(input.name, val)}
                disabled={disabledInput || disableForm}
                className={input.className}
                classes={input.classes}
              />
            );
      case FormInputTypes.password:
        return (
          <EnhancedInput
            key={input.name}
            name={input.name}
            title={title}
            type="password"
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChange(input.name, v.target.value)}
            disabled={disabledInput}
            material={input.material}
            description={input.description}
            includePasswordhint={input.includePasswordHint}
            includePasswordVisibility={input.includePasswordVisibility}
            includeCapsLockCheck={input.includeCapsLockCheck}
            customStyles={input.customStyles}
          />
        );

      case FormInputTypes.date:
        return (
          <EnhancedDatePicker
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={() => onFieldBlur(input.name)}
            onDateChange={(dateValue) => {
              onFieldChange(input.name, dateValue);

              input.value = dateValue;

              if (input.onChange) {
                input.onChange(dateValue);
              }
            }}
            disabled={disabledInput}
            material={input.material}
            minDate={input.minDate}
            maxDate={input.maxDate}
            placeholder={input.placeholder}
            format={tenant.dateFormat}
            canClearDate={input.canClearDate}
          />
        );

      case FormInputTypes.imageFile:
        return (
          <EnhancedUploader
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onUpload={(v) => onFieldChange(input.name, v)}
            disabled={disabledInput}
            allowedFileTypes={input.allowedFileTypes}
            type={""}
          />
        );

      case FormInputTypes.select:
        return <span>deprecated</span>;
      case FormInputTypes.number:
        return (
          <EnhancedNumberInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={disabledInput}
            material={input.material}
            minValue={input.minNumber}
            maxValue={input.maxNumber}
          />
        );
      case FormInputTypes.percentage:
        return (
          <EnhancedPercentageInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={disabledInput}
            material={input.material}
          />
        );

      case FormInputTypes.switch:
        return (
          <EnhancedSwitch
            key={input.name}
            name={input.name}
            title={title}
            value={values[input.name]}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(name, val) => onFieldChange(input.name, val)}
            disabled={disabledInput}
            required={isRequired}
          />
        );

      case FormInputTypes.multiSelect:
        return <span>deprecated</span>;
      case FormInputTypes.chips:
        return hasDoprdownSpecificBehavior && input.hasPagination ? (
          <EnhancedInlinePaginatedChipInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            preselectedValues={input.preselectedValues}
            error={touched[input.name] ? errors[input.name] : ""}
            onChange={(v) => {
              onFieldChange(input.name, v);
              if (!isEmpty(v) && input.onSelect) {
                input.onSelect(v as string);
              }
              const newValue = v;
              onFieldBlur(input.name);
              if (!isEmpty(newValue)) {
                onFieldChange(input.name, newValue);
                if (input.onChange) {
                  input.onChange(newValue);
                }
              }
            }}
            disabled={disabledInput}
            customStyles={input.customStyles}
            selectOptions={input.selectOptions}
            required={isRequired}
            freeSolo={input.freeSolo}
            multiple={input.multiple}
            material={input.material}
            loader={loadingFields[input.name] || false}
            showSelectAll={input.showSelectAll}
            strongStyledOption={input.strongStyledOption}
            hidden={hidden}
            itemsPerPage={input.itemsPerPage}
          />
        ) : (
          // <EnhancedChipInput
          //   key={input.name}
          //   name={input.name}
          //   title={title}
          //   placeholder={input.placeholder}
          //   value={values[input.name]}
          //   error={errors[input.name]}
          //   onChange={(v) => {
          //     onFieldChange(input.name, v);
          //     if (!isEmpty(v) && input.onSelect != undefined) {
          //       input.onSelect(v as string);
          //     }
          //   }}
          //   disabled={disabledInput}
          //   customStyles={input.customStyles}
          //   selectOptions={input.selectOptions}            
          //   required={isRequired}
          //   freeSolo={input.freeSolo}
          //   multiple={input.multiple}
          //   material={input.material}
          //   loader={loadingFields[input.name] || false}
          //   canClearSingleValueSelection={input.canClearSingleValueSelection}
          // />
          <NewChipsInput
            key={input.name}
            name={input.name}
            title={title}
            multiple={input.multiple}
            required={input.required}
            value={values[input.name]}
            values={values[input.name] || []}
            disabled={disabledInput}
            items={input.selectOptions}
            material={input.material}
            onSearch={onSearch ? async (v) => {
              if(onSearch){
                await onSearch(input.name, v)
              }
            } : null}
            placeholder={input.placeholder}
            showSelectAll={input.showSelectAll}
            error={touched[input.name] ? errors[input.name] : ""}
            onChange={(v) => {
              onFieldChange(input.name, v);
              if (!input.multiple) {
                if (!isEmpty(v) && input.onSelect) {
                  input.onSelect(v as string);
                }
              }
            }}
        />
        );
      case FormInputTypes.phoneNumber:
        return (
          <EnhancedInternationalPhoneInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={errors[input.name]}
            onChange={(v) => onFieldChange(input.name, v)}
            onBlur={(v) => onFieldBlur(input.name)}
            disabled={disabledInput}
            countriesToShow={input.countriesToShow}
            disableDropDown={input.disableDropDown}
            editCountryCode={input.editCountryCode}
            defaultCountry={input.defaultCountry}
            customFormat={input.customFormat}
            material={input.material}
          />
        );
      case FormInputTypes.currency:
        return (
          <EnhancedCurrencyInput
            key={input.name}
            name={input.name}
            title={title}
            placeholder={input.placeholder}
            value={values[input.name]}
            error={touched[input.name] ? errors[input.name] : ""}
            onBlur={(v) => onFieldBlur(input.name)}
            onChange={(v) => onFieldChangeByEvent(v)}
            disabled={disabledInput}
            type={""}
            currencyTitle="$"
            currencyIcon={tenant.cdnUrl + "/icons/dollar-primary.svg"}
            onFocus={(v) => onFieldBlur(input.name)}
          />
        );
      case FormInputTypes.link:
        return (
          <EnhancedLink
            key={input.name}
            name={input.name}
            title={title}
            onClick={() => {
              onFieldChange(input.name, null);
              if (input.onClick) input.onClick();
            }}
            hidden={hidden}
          />
        );
      default:
        return <></>;
    }
  };

  const renderSection = (section: IDynamicSection) => {
    return (
      <>
        <div className={classes.titleStyling}>
          <p>
            {section.hasTitleSpecificDesign
              ? section.specificTitleDesign()
              : section.title}
          </p>
          <hr style={{ opacity: "0.25" }} />
          <br />
        </div>
        {Object.values(section.inputs).map((input) => renderInput(input))}
      </>
    );
  };

  return values ? (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      <p>{title}</p>
      {Object.values(sections).map((section) => renderSection(section))}

      {children}
      <div className={classes.footerContainer}>
        {secondaryButton && (
          <EnhancedButton
            onClick={() => onClickSecondaryButton()}
            disabled={disableForm}
            variant="contained"
            className={classes.submitBtn}
            state={submitButtonState}
          >
            {secondaryButtonText}
          </EnhancedButton>
        )}

        <EnhancedButton
          type="submit"
          disabled={disableForm || isSubmitButtonDisabled}
          variant="contained"
          backgroundColor={
            mainButtonBgColor
              ? mainButtonBgColor
              : MAIN_ONE_THEME.palette.primary5.main
          }
          color="rgba(255, 255, 255, 1)"
          style={{ width: "108px" }}
          state={submitButtonState}
          className={customStyles && customStyles.submitButtonStyles}
        >
          {buttonText}
        </EnhancedButton>
      </div>
    </form>
  ) : (
    <></>
  );
};

export default SectionDynamicForm;
