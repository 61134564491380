import React from "react";
import { IProductionDetailsSummary } from "..";
import { EnhancedDisplayType } from "../../../../../components/enhanced-form/EnhancedDisplay";
import { IDynamicDiplayView } from "../../../../../components/widgets/dynamic-display";
import DynamicWidget from "../../../../../components/widgets/dynamic-display/DynamicWidget";
import { capitalizeFirstLetterLowerOthers } from "../../../../../utils/formatting-utils";

interface IProductionCostChargesWidgetProps {
  data: IProductionDetailsSummary;
}

const ProductionCostChargesWidget: React.FC<
  IProductionCostChargesWidgetProps
> = ({ data }) => {
  const dynamicView: IDynamicDiplayView = {
    name: "CostAndCharges",
    sections: [
      {
        name: "CostAndCharges",
        title: "Costs and Charges",
        properties: {
          policyCost: {
            multiline: false,
            value: data.PricingOption.PolicyCost?.toString(),
            name: "policyCost",
            title: "Policy Cost",
            type: EnhancedDisplayType.Currency,
            currencySymbol: data?.PolicyCurrency?.Symbol || "",
          },
          chargesPercentage: {
            multiline: false,
            value: data.PricingOption.ChargesPercentage?.toString(),
            name: "chargesPercentage",
            title: "Charges Percentage",
            type: EnhancedDisplayType.Percentage,
          },
          tPAFeesType: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              data.PricingOption.TPAFeesType || ""
            ),
            name: "tPAFeesType",
            title: "TPA Fees Type",
          },
          tPAFeesAmount: {
            multiline: false,
            value: data.PricingOption.TPAFeesAmount?.toString(),
            name: "tPAFeesAmount",
            title: "TPA Fees Amount",
            type: EnhancedDisplayType.Currency,
            hidden: data.PricingOption.TPAFeesType !== "AMOUNT",
            currencySymbol: data?.PolicyCurrency?.Symbol || "",
          },
          tPAFeesPercentage: {
            multiline: false,
            value: data.PricingOption.TPAFeesPercentage?.toString(),
            name: "tPAFeesPercentage",
            title: "TPA Fees Percentage",
            hidden: data.PricingOption.TPAFeesType !== "PERCENTAGE",
            type: EnhancedDisplayType.Percentage,
          },
          tPAFeesApplicableOn: {
            multiline: false,
            value: capitalizeFirstLetterLowerOthers(
              data.PricingOption.TPAFeesApplicableOn || "",
              "_"
            ),
            name: "tPAFeesApplicableOn",
            title: "TPA Fees ApplicableOn",
          },
        },
      },
    ],
  };

  return <DynamicWidget hasFourFields={true} view={dynamicView} />;
};

export default ProductionCostChargesWidget;
