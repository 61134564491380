import { gql } from "@apollo/client";

export function getExpatPlanRatesEnums() {
  return gql`
    query getExpatPlanRatesEnums {
      Insurance_Genders: __type(name: "Insurance_Genders") {
        name
        enumValues {
          name
        }
      }
      PlanConfigManagement_EmergencyOptions: __type(
        name: "PlanConfigManagement_EmergencyOptions"
      ) {
        name
        enumValues {
          name
        }
      }
      Insurance {
        lookups {
          nationalities {
            Id
            Title
          }
          occupationClasses {
            Id
            Title
          }
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
          medicalClasses {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function getPlanSpecificExpatInfo() {
  return gql`
    query getPlanSpecificExpatInfo($id: String!) {
      PlanConfigManagement {
        entities {
          planSpecificExpat(id: $id) {
            id
            views {
              PlanConfigManagement_all {
                properties {
                  Id
                  PlanID {
                    id
                  }
                  AgeFrom
                  AgeTo
                  Gender
                  Nationality {
                    Id
                    Title
                  }
                  OccupationClass {
                    Id
                    Title
                  }
                  Premium
                  Emergency
                  MedicalClass {
                    Id
                    Title
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function createPlanSpecificExpat() {
  return gql`
    mutation createPlanSpecificExpat(
      $planSpecificExpatInputs: PlanConfigManagement_PlanSpecificExpatInputs!
      $nationalities: [String!]
    ) {
      planConfigManagement {
        actions {
          createPlanSpecificExpat(
            nationalities: $nationalities
            planSpecificExpatInputs: $planSpecificExpatInputs
          ) {
            id
          }
        }
      }
    }
  `;
}

export function updatePlanSpecificExpat() {
  return gql`
    mutation updatePlanSpecificExpat(
      $entityId: String!
      $planSpecificExpatInputs: PlanConfigManagement_PlanSpecificExpatInputs!
      $nationalities: [String!]
    ) {
      planConfigManagement {
        entities {
          planSpecificExpat {
            planConfigManagement {
              updatePlanSpecificExpat(
                entityId: $entityId
                nationalities: $nationalities
                planSpecificExpatInputs: $planSpecificExpatInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
