import { IDynamicSection, FormInputTypes } from '../../DynamicForm';
import { isEmpty, REGEX } from '../../utils/validationUtils';

export const initialValues = {
    company: '',
    name: '',
    accountLink: false,
    accountLinked: '',
    type: '',
    isSubAccount: false,
    parentAccount: '',
    currency: '',
    referenceNumber: '',
    website: '',
    notes: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    building: '',
    street: '',
    country: '',
    city: ''
};

export const getSections = (mode: "add" | "edit" = "add", values: Record<string, any> = initialValues, lovs: Record<string, Record<string, string>>, accounts: Record<string, string>) : Record<string, IDynamicSection> => ({
    section1: {
        title: "Customer Details",
        inputs: {
            company: {
            name: 'company',
            title: 'Company',
            type: FormInputTypes.chips,
            disabled: mode === "edit",
            value: !isEmpty(values.company)
            ? values.company
            :'',
            selectOptions: lovs?.relatedCompanies,
            required: true,
            multiple: false
        },
        name: {
            name: 'name',
            title: 'Customer Name',
            type: FormInputTypes.text,
            value: values.name,
            required: true,
        },
        accountLink: {
            name: 'accountLink',
            title: 'Link to an existing account',
            type: FormInputTypes.checkbox,
            value: values.accountLink,
            required: false,
            disabled: mode === "edit",
        },
        accountLinked: {
            name: 'accountLinked',
            title: 'Linked Account',
            type: FormInputTypes.chips,
            value: !isEmpty(values.accountLinked)? 
            values.accountLinked
            : '',
            required: values.accountLink,
            multiple: false,
            hidden: !values.accountLink,
            disabled: mode === "edit",
            selectOptions: accounts
        },
        type: {
            name: 'type',
            title: 'Type',
            disabled: mode === "edit",
            type: FormInputTypes.chips,
            value: !isEmpty(values.type)
            ? values.type
            : '',
            selectOptions: lovs?.type,
            hidden: values.accountLink,
            required: !values.accountLink,
            multiple: false,
        },
        isSubAccount: {
            name: 'isSubAccount',
            title: 'Make this a sub-account',
            type: FormInputTypes.checkbox,
            value: values.isSubAccount,
            hidden: values.accountLink,
            required: false,
            disabled: mode === "edit",
        },
        parentAccount: {
            name: 'parentAccount',
            title: 'Parent Account',
            type: FormInputTypes.chips,
            value: !isEmpty(values.parentAccount)? 
            values.parentAccount
            : '',
            required: values.isSubAccount && !values.accountLink,
            multiple: false,
            hidden: !values.isSubAccount || values.accountLink,
            disabled: mode === "edit",
            selectOptions: accounts
        },
        currency: {
            name: 'currency',
            title: 'Account Currency',
            type: FormInputTypes.chips,
            value: !isEmpty(values.currency)
            ? values.currency
            : '',
            required: !values.isSubAccount && !values.accountLink,
            multiple: false,
            hidden: values.isSubAccount || values.accountLink,
            disabled: mode === "edit",
            selectOptions: lovs?.currency,
        },
        referenceNumber: {
            name: 'referenceNumber',
            title: 'Reference Number',
            type: FormInputTypes.text,
            value: values.referenceNumber,
            required: false,
        },
        website: {
            name: 'website',
            title: 'Website',
            type: FormInputTypes.text,
            value: values.website,
            required: false,
        },
        notes: {
            name: 'notes',
            title: 'Notes',
            type: FormInputTypes.text,
            value: values.notes,
            required: false,
            multiline: true,
        },
    }
},
section2: {
    title: "Primary Contact Details",
    inputs: {
        firstName: {
            name: 'firstName',
            title: 'First Name',
            type: FormInputTypes.text,
            value: values.firstName,
            required: true,
        },
        lastName: {
            name: 'lastName',
            title: 'Last Name',
            type: FormInputTypes.text,
            value: values.lastName,
            required: true,
        },
        email: {
            name: 'email',
            title: 'Email',
            type: FormInputTypes.text,
            value: values.email,
            required: true,
            regex: REGEX.email,
            regexError: "Invalid e-mail",
        },
        phoneNumber: {
            name: 'phoneNumber',
            title: 'Phone Number',
            type: FormInputTypes.phoneNumber,
            value: values.phoneNumber,
            required: false,
            editCountryCode: false,
            defaultCountry: "lb",
            disableDropDown: true,
            customFormat: { lb: ".. ......" },
            regexError: "Invalid phone number"
        },
    }
},
section3: {
    title: "Billing Details",
    inputs: {
        address: {
            name: 'address',
            title: 'Address Details',
            type: FormInputTypes.text,
            value: values.address,
            required: false,
        },
        building: {
            name: 'building',
            title: 'Building',
            type: FormInputTypes.text,
            value: values.building,
            required: false,
        },
        street: {
            name: 'street',
            title: 'Street',
            type: FormInputTypes.text,
            value: values.street,
            required: false,
        },
        country: {
            name: 'country',
            title: 'Country',
            type: FormInputTypes.chips,
            value: !isEmpty(values.country)
            ? values.country
            : '',
            required: true,
            multiple: false,
            selectOptions: lovs?.countries,
        },
        city: {
            name: 'city',
            title: 'City',
            type: FormInputTypes.text,
            value: values.city,
            required: false,
        },
    }
}
});
