import { isEmpty, isValidNumber } from "../../../../../utils/validationUtils";
import {
  IProposalCostCharges,
  IProposalPageFormState,
} from "./form";
import { IProposalDetailsSummary } from "./index2";

export const validateCostWidgetField = (
  fieldName: keyof IProposalCostCharges,
  value: any,
  cover: IProposalCostCharges
): string => {
  if (fieldName === "policyCost") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "tpaFeesType") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "chargesPercentage") {
    if (isEmpty(value)) {
      return "Required";
    } else {
      if (!isValidNumber(value)) {
        return "Invalid Number";
      }

      const numberValue = Number(value);

      if (numberValue < 0 || numberValue > 100) {
        return "Value should be between 0 and 100";
      }
    }
  }

  if (fieldName === "tpaFeesPercentage") {
    if (cover.tpaFeesType === "PERCENTAGE") {
      if (isEmpty(value)) {
        return "Required";
      } else {
        if (!isValidNumber(value)) {
          return "Invalid Number";
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return "Value should be between 0 and 100";
        }
      }
    }
  }

  if (fieldName === "tpaFeesAmount") {
    if (cover.tpaFeesType === "AMOUNT") {
      if (isEmpty(value)) {
        return "Required";
      } else {
        if (!isValidNumber(value)) {
          return "Invalid Number";
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return "Value should be greater than 0";
        }
      }
    }
  }

  return "";
};

export const validatePolicyPayerField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return "Required";
  }

  if (fieldName === "payerId") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "payerAddress") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  return "";
};

export const validatePolicyOwnerField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return "Required";
  }

  if (fieldName === "ownerId") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "ownerAddress") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  return "";
};

export const validatePolicyInsuredField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return "Required";
  }

  if (fieldName === "id") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "order") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "address") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  return "";
};

export const validatePolicyBeneficiaryField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return "Required";
  }

  if (fieldName === "id") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "percentage") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "address") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  return "";
};

export const validateMedicalDetailsField = (
  fieldName: keyof IProposalPageFormState["values"]["medicalDetails"],
  value: any
): string => {
  if (isEmpty(value)) {
    return "Required";
  }

  if(fieldName === "policyCover"){
    if((value || []).length === 0) {
      return "Required";
    }
  }

  return "";
};

export const validateProposalPageForm = (
  data: IProposalDetailsSummary,
  values: IProposalPageFormState["values"]
) => {
  const _lineExternalCode: string = data?.LineId?.ExternalCode?.toString();
  console.log(_lineExternalCode);
  const { policyPersons, costs, medicalDetails } = values;
  medicalDetails;

  const errors: IProposalPageFormState["errors"] = {
    policyPersons: {
      payer: {
        payerId: validatePolicyPayerField(
          "payerId",
          policyPersons.payer.payerId
        ),
        payerAddress: validatePolicyPayerField(
          "payerAddress",
          policyPersons.payer.payerAddress
        ),
        ownerIsDifferent: validatePolicyPayerField(
          "ownerIsDifferent",
          policyPersons.payer.ownerIsDifferent
        ),
      },
      owner: {
        ownerId: policyPersons.payer.ownerIsDifferent
          ? validatePolicyOwnerField("ownerId", policyPersons.owner.ownerId)
          : "",
        ownerAddress: policyPersons.payer.ownerIsDifferent
          ? validatePolicyOwnerField(
            "ownerAddress",
            policyPersons.owner.ownerAddress
          )
          : "",
      }
    },
    costs: {
      policyCost: validateCostWidgetField(
        "policyCost",
        costs.policyCost,
        costs
      ),
      chargesPercentage: validateCostWidgetField(
        "chargesPercentage",
        costs.chargesPercentage,
        costs
      ),
      tpaFeesType: validateCostWidgetField(
        "tpaFeesType",
        costs.tpaFeesType,
        costs
      ),
      tpaFeesAmount: validateCostWidgetField(
        "tpaFeesAmount",
        costs.tpaFeesAmount,
        costs
      ),
      tpaFeesPercentage: validateCostWidgetField(
        "tpaFeesPercentage",
        costs.tpaFeesPercentage,
        costs
      ),
      tpaFeesApplicableOn: validateCostWidgetField(
        "tpaFeesApplicableOn",
        costs.tpaFeesApplicableOn,
        costs
      ),
    },
    medicalDetails: {
      class: validateMedicalDetailsField("class", medicalDetails.class),
      level: validateMedicalDetailsField("level", medicalDetails.level),
      cnss: validateMedicalDetailsField("cnss", medicalDetails.cnss),
      policyCover: validateMedicalDetailsField("policyCover", medicalDetails.policyCover)
    },
  };

  const touched: IProposalPageFormState["touched"] = {
    policyPersons: {
      payer: {
        payerId: true,
        payerAddress: true,
        ownerIsDifferent: true,
      },
      owner: {
        ownerId: true,
        ownerAddress: true,
      },
    },
    costs: {
      policyCost: true,
      chargesPercentage: true,
      tpaFeesType: true,
      tpaFeesAmount: true,
      tpaFeesPercentage: true,
      tpaFeesApplicableOn: true,
    },
    // motorDetails: {
    //   nameOnLicenseEn: true,
    //   nameOnLicenseAr: true,
    //   vignetteCode: true,
    //   vignette: true,
    //   plateCode: true,
    //   plateNumber: true,
    //   usageType: true,
    //   bodyType: true,
    //   engineType: true,
    //   brand: true,
    //   model: true,
    //   yearOfMake: true,
    //   motor: true,
    //   weight: true,
    //   engineSize: true,
    //   seats: true,
    //   chassis: true,
    //   horsePower: true,
    //   carValue: true,
    //   agencyRepairYear: true,
    // },
    medicalDetails: {
      class: true,
      level: true,
      cnss: true,
      policyCover: true
    },
  };

  const isValid =
    Object.values(errors.costs).every((e) => isEmpty(e)) &&
    // Object.values(errors.covers).every((c) =>
    //   Object.values(c).every((cv) => isEmpty(cv))
    // ) &&
    Object.values(errors.policyPersons.payer).every((e) => isEmpty(e)) &&
    Object.values(errors.policyPersons.owner).every((e) => isEmpty(e))
  // Object.values(errors.motorDetails).every((e) => isEmpty(e));

  return { errors, touched, isValid };
};
