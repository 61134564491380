import React from "react";
import { makeStyles } from "tss-react/mui";
import clsx from "clsx";
import _ from "lodash";
import { removeObjectAtIndex } from "../../../utils/helper-utils";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ClearIcon from '@mui/icons-material/Clear';
import NewChipsInput from "../../../components/enhanced-form/NewChipsInput";
import EnhancedInput from "../../../components/enhanced-form/EnhancedInput";
import { IProduct, IInvoiceFormState } from "../form";
import { useMutation } from "@apollo/client";
import { convertAccountToSystemCurrency } from "../queries";
import { isEmpty } from "../../../utils/validationUtils";
import EnhancedCurrencyInput from "../../../components/enhanced-form/EnhancedCurrencyInput";

const useStyles = makeStyles()(() => ({
    container: {
        boxShadow: '0px 0px 10px #DFE3EBC9',
        border: '1px solid #E8E8E8',
        paddingTop: '11px',
        marginBottom: '21px',
        marginTop: '38px',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: '4.5fr 3.5fr 3.5fr 0.5fr',
        gridColumnGap: '18px',
        paddingLeft: '39px',
        paddingRight: '39px'
    },
    gridItem: {
        display: 'grid',
        gridTemplateColumns: '6fr 6fr',
        gridColumnGap: '18px',
        height: '56px'
    },
    titlesContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '4.5px',
        paddingBottom: '4.5px',
        lineHeight: '21px',
    },
    titles: {
        paddingTop: '15px',
        paddingBottom: '13px',
        lineHeight: '21px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    firstTitles: {
        display: 'flex',
        justifyContent: 'start',
    },
    span: {
        fontSize: '15px',
        fontFamily: 'SourceSansPro-bold',
    },
    accountCurrencyTitle: {
        backgroundColor: '#ECF7F5',
        color: '#586E6A',
    },
    accountCurrencyTitles: {
        color: '#586E6A',
    },
    systemCurrencyTitle: {
        backgroundColor: '#F4F8EF',
        color: '#525A49',
    },
    systemCurrencyTitles: {
        color: '#525A49',
    },

    addBtn: {
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        cursor: "pointer",
        padding: "0",
        margin: "21px auto 25px",
        display: "block",
    },
    removeBtn: {
        backgroundColor: "transparent",
        border: "none",
        outline: "none",
        cursor: "pointer",
        paddingTop: '5px',
        display: "block",
    },
    containerStyles: {
        paddingTop: 0,
        minHeight: '32px'
    },
    evenRow: {
        backgroundColor: '#F9F9F9',
    },
    oddRow: {
    },
    column: {
        paddingTop: 0,
        '&.MuiGrid-item': {
            paddingTop: '0 !important',
        }
    },
    field: {
        '& span:first-of-type': {
            minHeight: 0
        },
        '& input': {
            height: '32px'
        }
    },
    labelSelector: {
        minHeight: 0
    },
    inputSelector: {
        height: '32px'
    },
    total: {
        display: 'flex',
        justifyContent: 'end',
        fontSize: '16px',
        marginRight: '40px',
        marginBottom: '40px'
    },
    totalSpan: {
        paddingTop: '15px',
        marginRight: '30px',
    }
}));

export const initialValues = {
    initialError: {
        productName: '',
        description: '',
        accountDebit: '',
        accountCredit: '',
        systemDebit: '',
        systemCredit: '',
    },
    initialTouched: {
        productName: false,
        description: false,
        accountDebit: false,
        accountCredit: false,
        systemDebit: false,
        systemCredit: false,
    }
};

interface IAccountCurrencyProps {
    systemCurrency: string;
    totalAmount: number;
    pageState: IInvoiceFormState;
    onPageStateUpdate: (pageState: IInvoiceFormState) => void;
    lovs: Record<string, Record<string, string>>;
}

const Product: React.FC<IAccountCurrencyProps> = (
    { systemCurrency, totalAmount, pageState, onPageStateUpdate, lovs }
) => {
    const { classes } = useStyles();

    const [convertAccountToSystem] = useMutation(convertAccountToSystemCurrency());

    const values = pageState.values.product;
    const errors = pageState.errors.product;
    const touched = pageState.touched.product;

    const convertAccountToSys = (amount: number, rowIndex: number) => {
        const newPageState = _.cloneDeep(pageState);

        convertAccountToSystem({
            variables: {
                transactionDate: new Date(),
                accountDebitAmount: parseFloat(amount.toString()),
                accountCurrency: [lovs.productCurrencies[pageState.values.product[rowIndex].productName]],
            },
            errorPolicy: "all",
        }).then((res) => {
            if (isEmpty(res.errors)) {
                const newAmount = res?.data?.accounting?.actions?.convertAccountToSystemCurrency?.SystemDebit;

                // recheck it, it needs fix
                (newPageState.values.product[rowIndex] as any)["amount"] = amount;
                (newPageState.values.product[rowIndex] as any)["systemAmount"] = newAmount;
                onPageStateUpdate(newPageState)
            }
        })
    }

    const onFieldBlur = (fieldName: string, rowIndex: number) => {

        const newPageState = _.cloneDeep(pageState);
        newPageState.touched.product[rowIndex][fieldName] = true;
        onPageStateUpdate(newPageState)
    }

    const onFieldUpdate = (fieldName: keyof IProduct, value: any, rowIndex: number, touched = false) => {
        const newPageState = _.cloneDeep(pageState);

        if (fieldName === "amount") {
            (newPageState.values.product[rowIndex] as any)["accountCredit"] = 0;
            convertAccountToSys(value, rowIndex);
        }

        (newPageState.values.product[rowIndex] as any)[fieldName] = value;

        // newPageState.errors.account[rowIndex][fieldName] = validateCoverRepeaterField(fieldName, value, newPageState.values.covers[rowIndex]);
        if (touched) {
            newPageState.touched.product[rowIndex][fieldName] = true;
        }
        onPageStateUpdate(newPageState)
    }

    const renderRow = (row: IProduct) => {
        
        const filteredPlanLov = { ...lovs.products };

        const rowIndex = values.indexOf(row);

        // const isLast = rowIndex + 1 === values.length;

        return <div className={clsx("container", classes.grid, rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow)}>
            <div className={clsx("item", classes.gridItem)}>
                <NewChipsInput
                    key="productName"
                    name="productName"
                    value={values[rowIndex]?.productName}
                    error={touched[rowIndex]?.productName ? errors[rowIndex].productName : ""}
                    onChange={(v) => {
                        onFieldUpdate("productName", v, rowIndex)
                    }}
                    customStyles={{ containerStyles: classes.containerStyles, labelStyles: classes.labelSelector, inputStyles: classes.inputSelector }}
                    items={filteredPlanLov}
                    required={true}
                />
                <EnhancedInput
                    type="text"
                    key="description"
                    name="description"
                    title=""
                    value={values[rowIndex]?.description || ""}
                    error={touched[rowIndex]?.description ? errors[rowIndex].description : ""}
                    onBlur={(v) => onFieldBlur("description", rowIndex)}
                    onChange={(v) => onFieldUpdate("description", v.target.value, rowIndex)}
                    customStyles={{ inputStyles: classes.containerStyles }}
                    className={classes.field}
                />
            </div>
            <div className={clsx("item", classes.gridItem)}>
                <EnhancedCurrencyInput
                    type="number"
                    key="amount"
                    name="amount"
                    title=""
                    value={Number(values[rowIndex]?.amount).toFixed(2) || ""}
                    error={touched[rowIndex]?.amount ? errors[rowIndex]?.amount : ""}
                    onBlur={(v) => onFieldBlur("amount", rowIndex)}
                    onChange={(v) => onFieldUpdate("amount", v.target.value, rowIndex)}
                    customStyles={{ inputStyles: classes.containerStyles }}
                    disabled={!values[rowIndex]?.productName}
                    className={classes.field}
                />
            </div>
            <div className={clsx("item", classes.gridItem)}>
                <EnhancedCurrencyInput
                    type="number"
                    key="systemAmount"
                    name="systemAmount"
                    title=""
                    currencyTitle={systemCurrency}
                    currencyIcon={systemCurrency}
                    value={Number(values[rowIndex]?.systemAmount).toFixed(2) || ""}
                    error={touched[rowIndex]?.systemAmount ? errors[rowIndex]?.systemAmount : ""}
                    onBlur={(v) => onFieldBlur("systemAmount", rowIndex)}
                    onChange={(v) => onFieldUpdate("systemAmount", v.target.value, rowIndex)}
                    customStyles={{ inputStyles: classes.containerStyles }}
                    className={classes.field}
                    disabled
                />
            </div>
            <div className={clsx("item", classes.gridItem)}>
                {values.length > 1 &&
                    <button className={classes.removeBtn} onClick={() => onRowDelete(rowIndex)}>
                        <ClearIcon fontSize="medium" />
                    </button>
                }
            </div>
        </div >
    }

    const onRowAdd = () => {
        const newPageState = _.cloneDeep(pageState);
        newPageState.values.product.push({
            id: null,
            productName: '',
            description: '',
            amount: 0,
            systemAmount: 0
        });

        newPageState.errors.product.push({ ...initialValues.initialError });

        newPageState.touched.product.push({ ...initialValues.initialTouched });

        onPageStateUpdate(newPageState);
    }

    const onRowDelete = (rowIndex: number) => {
        const newPageState = _.cloneDeep(pageState);
        newPageState.values.product = removeObjectAtIndex(newPageState.values.product, rowIndex);
        newPageState.errors.product = removeObjectAtIndex(newPageState.errors.product, rowIndex);
        newPageState.touched.product = removeObjectAtIndex(newPageState.touched.product, rowIndex);
        //newPageState.values.delete.push(values[rowIndex]?.id)
        onPageStateUpdate(newPageState);
    }


    const shouldDisplayPlus = () => {
        const filteredPlanLov = { ...lovs.products };

        values.forEach((r) => {
            if (r.productName) {
                delete filteredPlanLov[r.productName];
            }
        });

        return Object.keys(filteredPlanLov).length > 0;

    };


    return (
        <div className={classes.container}>
            <div className={clsx("container", classes.grid)}>
                <div className="item">
                </div>
            </div>
            <div className={clsx("container", classes.grid)}>
                <div className={clsx("item", classes.gridItem)}>
                    <div className={clsx(classes.titles, classes.firstTitles)}>
                        <span className={classes.span}>Product*</span>
                    </div>
                    <div className={clsx(classes.titles, classes.firstTitles)}>
                        <span className={classes.span}>Description</span>
                    </div>
                </div>
                <div className={clsx("item", classes.gridItem)}>
                    <div className={clsx(classes.titles, classes.firstTitles)}>
                        <span className={classes.span}>Amount*</span>
                    </div>
                    <div className={clsx(classes.titles, classes.firstTitles)}>
                        <span className={classes.span}>System Currency*</span>
                    </div>
                </div>
                <div className="item">
                </div>
            </div>
            {
                values.map(v => renderRow(v))
            }

            <button className={classes.addBtn} onClick={() => onRowAdd()} disabled={!shouldDisplayPlus()}>
                <AddCircleOutlineIcon color={!shouldDisplayPlus() ? "disabled" : "error"} fontSize="medium" />
            </button>
            <div className={clsx("item", classes.total)}>
                <span className={classes.totalSpan}>Total</span>
                <div>    
                    <EnhancedCurrencyInput
                        type="number"
                        key="amount"
                        name="amount"
                        title=""
                        value={Number(totalAmount).toFixed(2) || ""}
                        onBlur={(v) => v}
                        onChange={(v) => v}
                        customStyles={{  }}
                        disabled= {true}
                    />
                </div>   
            </div>
        </div>
    )
};

export default Product;

