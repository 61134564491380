import { IListingData } from '../../models/listing';
import {
    lookupListAsRecordObject,
  } from "../../utils/graph-utils";
  import { IActivityEntityBase } from "../../activities";

export function mapToListingData(data: any): IListingData<any> {
  const list: Record<string, ITransactionsGenericTableData> = {};
  data.Accounting.queries.TransactionsList?.items?.forEach(
    (obj: ITransactionsGenericTableData, i: number) => {
      list[i] = {
        accounting_Transactions_Id: obj.accounting_Transactions_Id,
        accounting_Transactions_createdOn: obj.accounting_Transactions_createdOn,
        accounting_Transactions_TransactionNumber: obj.accounting_Transactions_TransactionNumber,
        accounting_Transactions_ReferenceNumber: obj.accounting_Transactions_ReferenceNumber,
        accounting_Transactions_Description: obj.accounting_Transactions_Description,
        accounting_Transactions_TransactionDate: obj.accounting_Transactions_TransactionDate
      };
    },
  );
  

  return {
    pagedItems: list,
    pageSize: data.Accounting.queries.TransactionsList?.paging.pageSize,
    pageNumber: data.Accounting.queries.TransactionsList?.paging.pageNumber,
    totalCount: data.Accounting.queries.TransactionsList?.paging.totalCount,
  };
}

export function EntityAccountToList(data: any) {
  let lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.GetAccountsOfCompany?.forEach((element: { [key: string]: string }) => {
      if (element.accounting_ChartOfAccounts_Id) {
      lookuplistAsRecordObject[element.accounting_ChartOfAccounts_Id] = element.accounting_ChartOfAccounts_AccountName;
      }
  });

return lookuplistAsRecordObject;
}

  export function toLookupsData(data: any) {
    let lovs: Record<string, Record<string, string>> = {
      company: {},
      account: {}
    };

      lovs.company = lookupListAsRecordObject(
        data?.SalesforceManagement?.lookups?.relatedCompanies
      );

      data?.Accounting?.queries?.GetAccountsOfCompany?.forEach((element: { [key: string]: string }) => {
        if (element.accounting_ChartOfAccounts_Id) {
          lovs.account[element.accounting_ChartOfAccounts_Id] = element.accounting_ChartOfAccounts_AccountID + " " + element.accounting_ChartOfAccounts_AccountName;
        }
    });
  
    return lovs;
  }

  export function convertAuditTrailsToActivities(data: any): IActivityEntityBase[] {
    return data.Accounting.queries.GetTransactionsAuditTrails.map((auditTrail: any) => ({
        id: auditTrail.accounting_AuditTrail_Id,
        createdAt: new Date(auditTrail.accounting_AuditTrail_createdOn),
        htmlBody: auditTrail.accounting_AuditTrail_Message,
        iconUrl: extractIconUrl(auditTrail.accounting_AuditTrail_Message)
    }));
}

function extractIconUrl(html: string): string | undefined {
  const match = html.match(/background-image: url\('(.+?)'\)/);
  return match ? match[1] : undefined;
}
