import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { IAllRiskPlanRatesDrawerInfo, IAllRiskPlanRatesDrawerProps } from ".";
import {
  createPlanSpecificAllRisk,
  getAllRiskPlanRatesEnums,
  getPlanSpecificAllRiskInfo,
  updatePlanSpecificAllRisk,
} from "./queries";
import { LookupToList, graphqlToAllRiskPlanRatesInfo } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { isValidNumber } from "../../utils/validationUtils";

const AllRiskPlanRatesDrawer: React.FC<IAllRiskPlanRatesDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  planId,
  planCurrency,
  allRiskPlanRateId,
}) => {

  const [booted, setBooted] = useState<boolean>(false);

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [allRiskPlanRatesDetailsListResults] = useLazyQuery(
    getAllRiskPlanRatesEnums()
  );

  const [allRiskPlanRatesAction] = useMutation(
    allRiskPlanRateId
      ? updatePlanSpecificAllRisk()
      : createPlanSpecificAllRisk()
  );

  const [allRiskPlanRateInfoQuery] = useLazyQuery(getPlanSpecificAllRiskInfo());

  const loadLovList = async () => {
    const result = await allRiskPlanRatesDetailsListResults();
    const list = LookupToList(result.data);
    return list;
  }

  const getEntityInfo = async () => {

    let allRiskPlanRatesDetailsInfo: IAllRiskPlanRatesDrawerInfo = {
      planConfigManagement_PlanSpecificAllRisk_CarAgeFrom: "",
      planConfigManagement_PlanSpecificAllRisk_CarAgeTo: "",
      planConfigManagement_PlanSpecificAllRisk_CarValueFrom: "",
      planConfigManagement_PlanSpecificAllRisk_CarValueTo: "",
      planConfigManagement_PlanSpecificAllRisk_CarCategory: "",
      planConfigManagement_PlanSpecificAllRisk_MinimumPremium: "",
      planConfigManagement_PlanSpecificAllRisk_Rate: "",
      planConfigManagement_PlanSpecificAllRisk_AgencyYear: "",
      planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear: "",
    };

    if (!isEmpty(allRiskPlanRateId)) {
      const apiResult = await allRiskPlanRateInfoQuery({
        variables: { id: allRiskPlanRateId },
      });
      if (apiResult.data) {
        allRiskPlanRatesDetailsInfo = graphqlToAllRiskPlanRatesInfo(
          apiResult?.data
        );
      }
    }

    return allRiskPlanRatesDetailsInfo;
  }

  const handleCarChange = (
    carFrom: string,
    carTo: string,
    type: "Age" | "Value",
    operand: "less" | "greater"
  ) => {
    let error = "";
    if (carTo && carFrom) {
      if (operand === "less") {
        if (parseInt(carFrom) > parseInt(carTo)) {
          error = `Car ${type} From must be less than Car ${type} To`;
        }
      } else {
        if (parseInt(carTo) < parseInt(carFrom)) {
          error = `Car ${type} To must be greater than Car ${type} From`;
        }
      }
    }

    return error;
  };

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);
      updatedInputs.planCurrency.value = planCurrency;

      const [entityData, lovData] = await Promise.all([getEntityInfo(), loadLovList()]);

      (updatedInputs.planCurrency as IFormSelectDynamicProps).selectOptions = lovData["currencies"];
      (updatedInputs.carCategory as IFormSelectDynamicProps).selectOptions = lovData["carCategories"];

      if (entityData) {
        updatedInputs.carAgeFrom.value =
          entityData.planConfigManagement_PlanSpecificAllRisk_CarAgeFrom;
        updatedInputs.carAgeTo.value =
          entityData.planConfigManagement_PlanSpecificAllRisk_CarAgeTo;
        updatedInputs.carValueFrom.value =
          entityData.planConfigManagement_PlanSpecificAllRisk_CarValueFrom;
        updatedInputs.carValueTo.value =
          entityData.planConfigManagement_PlanSpecificAllRisk_CarValueTo;
        updatedInputs.carCategory.value =
          entityData.planConfigManagement_PlanSpecificAllRisk_CarCategory;
        updatedInputs.minimumPremium.value =
          entityData.planConfigManagement_PlanSpecificAllRisk_MinimumPremium;
        updatedInputs.rate.value =
          entityData.planConfigManagement_PlanSpecificAllRisk_Rate;
        updatedInputs.agencyYear.value =
          entityData.planConfigManagement_PlanSpecificAllRisk_AgencyYear;
        updatedInputs.noDepreciationYear.value =
          entityData.planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear;
      }
      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      console.log(err)
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);


  const onChange = async (fieldName: string, value: any, values: Record<string, any>, errors: Record<string, string>, touched: Record<string, boolean>) => {

    if (fieldName === "noDepreciationYear") {
      if (isValidNumber(value)) {
        errors.noDepreciationYear = errors.noDepreciationYear || parseInt(value) > 10 ? "No Depreciation Year can not exceed 10" : "";
      }
    }

    if (fieldName === "agencyYear") {
      if (isValidNumber(value)) {
        errors.agencyYear = parseInt(value) > 10 ? "Agency Year can not exceed 10" : "";
      }
    }

    if (fieldName === "carValueFrom" || fieldName === "carValueTo") {
      errors.carValueFrom = handleCarChange(values.carValueFrom, values.carValueTo, "Value", "less");
      errors.carValueTo = handleCarChange(values.carValueFrom, values.carValueTo, "Value", "greater");

      touched.carValueFrom = true;
      touched.carValueTo = true;
    }

    if (fieldName === "carAgeFrom" || fieldName === "carAgeTo") {
      errors.carAgeFrom = handleCarChange(values.carAgeFrom, values.carAgeTo, "Age", "less");
      errors.carAgeTo = handleCarChange(values.carAgeFrom, values.carAgeTo, "Age", "greater");

      touched.carAgeFrom = true;
      touched.carAgeTo = true;
    }
  }

  const onCustomValidateForm = async (values: Record<string, any>, errors: Record<string, string>) => {
    if (!errors.noDepreciationYear) {
      errors.noDepreciationYear = isValidNumber(values.noDepreciationYear) && (parseInt(values.noDepreciationYear) > 10) ? "No Depreciation Year can not exceed 10" : "";;
    }

    if (!errors.agencyYear) {
      errors.agencyYear = isValidNumber(values.agencyYear) && (parseInt(values.agencyYear) > 10) ? "Agency Year can not exceed 10" : "";;
    }

    if (!errors.carValueFrom) {
      errors.carValueFrom = handleCarChange(values.carValueFrom, values.carValueTo, "Value", "less");
    }

    if (!errors.carValueTo) {
      errors.carValueTo = handleCarChange(values.carValueFrom, values.carValueTo, "Value", "greater");
    }

    if (!errors.carAgeFrom) {
      errors.carAgeFrom = handleCarChange(values.carAgeFrom, values.carAgeTo, "Age", "less");
    }

    if (!errors.carAgeTo) {
      errors.carAgeTo = handleCarChange(values.carAgeFrom, values.carAgeTo, "Age", "greater");
    }

    return errors;
  }

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");
  
    try {
      const variablesMutation = {
        planSpecificAllRiskInputs: {
          planID: planId,
          agencyYear: Number(data.agencyYear),
          carAgeFrom: Number(data.carAgeFrom),
          carAgeTo: Number(data.carAgeTo),
          carValueFrom: Number(data.carValueFrom),
          carValueTo: Number(data.carValueTo),
          carCategory: data.carCategory,
          minimumPremium: Number(data.minimumPremium),
          noDepreciationYear: Number(data.noDepreciationYear),
          rate: Number(data.rate) / 100,
        },
      };
  
      const res = await allRiskPlanRatesAction({
        variables: allRiskPlanRateId
          ? { ...variablesMutation, entityId: allRiskPlanRateId }
          : variablesMutation,
        errorPolicy: "all",
      });
  
      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {allRiskPlanRateId
              ? "Plan Rate successfully updated."
              : "Plan Rate successfully added."}
          </ToastSuccessMessage>
        );
  
        setTimeout(() => {
          setSubmitButtonState("success");
          onSuccess();
          onClose();
          setFormDisabled(false);
        }, 500);
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (err) {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
    } finally {
    }
  };
  

  return (
    <GenericDrawer
      title={allRiskPlanRateId ? "Modify Plan Rate" : "Add Plan Rate"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            onCustomValidate={onCustomValidateForm}
            hasDoprdownSpecificBehavior={true}
            onChange={onChange}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default AllRiskPlanRatesDrawer;
