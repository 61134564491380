import React from "react";
import { makeStyles } from "tss-react/mui";

interface TestingWatermarkProps {
  value?: string;
}

const useStyles = makeStyles()(() => ({
  watermark: {
    position: "relative",
    left: "17.5%",
    fontSize: "30px",
    color: "#FFFFFF",
    fontStyle: "italic",
  },
}));

const TestingWatermark: React.FC<TestingWatermarkProps> = ({
  value = "Testing",
}) => {
  const { classes } = useStyles();

  return <div className={classes.watermark}>{value}</div>;
};

export default TestingWatermark;
