import React from 'react';
import { Tooltip } from '@mui/material';
import { IEntityClassificationData } from '.';
import EntityInfoClassification from './EntityInfoClassification';
import ProgressBar from '../../common/ProgressBar';
import { isEmpty } from '../../../utils/validationUtils';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME } from '../../../constants';

export interface IEntityInfoHeaderProps {
  classification: IEntityClassificationData;
  progress: number;
  supportsClassification: boolean;
  supportsProgressBar: boolean;
  title: string;
  iconUrl?: string;
  background: string;
  onClickIcon?: () => void;
}

const useStyles = makeStyles()((theme) => ({
  headerContainer: {
    width: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '0 0 1em',
  },
  badge: {
    textAlign: 'left',
    alignSelf: 'flex-start',
    justifySelf: 'flex-start',
    minWidth: 70,
  },
  actions: {
    minWidth: 15,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
  title: {
    display: 'block',
    textAlign: 'center',
    font: 'normal 22px/27px HelveticaNeue-Medium',
    color: MAIN_ONE_THEME.palette.primary2.main,
    alignSelf: 'center',
    width: '70%',
    margin: '0.75em',
    wordBreak: "break-word",
  },
  progressBar: {
    margin: '0 0 1em',
  },
  img: {
    margin: '0',
    maxWidth: '139px',
    maxHeight: '139px',
    display: 'block',
    objectFit: 'contain',
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'visible',
  },
}));

const EntityInfoHeader: React.FC<IEntityInfoHeaderProps> = ({
  title,
  iconUrl,
  supportsClassification,
  classification,
  background,
  supportsProgressBar,
  progress,
  onClickIcon,
}) => {
  const { classes } = useStyles();

  return (
    <>
      {!isEmpty(iconUrl) && (
        <div className={classes.container}>
          <Tooltip title={title}>
            <span className={classes.title}>{title}</span>
          </Tooltip>
          {supportsClassification && !isEmpty(classification.value) && (
            <span className={classes.badge}>
              <EntityInfoClassification
                value={classification.value}
                classifications={classification.classifications}
              />
            </span>
          )}
          <img
            className={classes.img}
            src={iconUrl}
            alt="Edit"
            onClick={onClickIcon}
          />
        </div>
      )}

      {isEmpty(iconUrl) &&
        (supportsClassification && !isEmpty(classification.value) ? (
          <>
            <Tooltip title={title}>
              <span className={classes.title}>{title}</span>
            </Tooltip>
            <div className={classes.headerContainer}>
              <span className={classes.badge}>
                <EntityInfoClassification
                  value={classification.value}
                  classifications={classification.classifications}
                />
              </span>
            </div>
          </>
        ) : (
          <div style={{ display: 'flex' }}>
            <Tooltip title={title}>
              <span className={classes.title}>{title}</span>
            </Tooltip>
          </div>
        ))}

      {supportsProgressBar && (
        <ProgressBar
          background={background}
          percentage={progress}
          className={classes.progressBar}
        />
      )}
    </>
  );
};

export default EntityInfoHeader;
