import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  createPartnerVignetteRange,
  getSalesforceVignetteRangeInfo,
  getSalesforceVignetteStatusesEnums,
  updatePartnerVignetteRange,
} from "./queries";
import { cloneDeep } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { isEmpty } from "../../utils/validationUtils";
import { getError } from "../../utils/graph-utils";
import { VignetteLookupsToList, graphqlToVignetteRangeInfo } from "./utils";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";

const SalesforceVignetteRangeDrawer: React.FC<
  ISalesforceVignetteRangeDrawerProps
> = ({ open, onSuccess, onClose, vignetteRangeId }) => {

  const params = useParams();

  const [booted, setBooted] = useState<boolean>(false);

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [vignetteRangeAction] = useMutation(
    vignetteRangeId
      ? updatePartnerVignetteRange()
      : createPartnerVignetteRange()
  );

    const [vignetteRangeInfoQuery] = useLazyQuery(getSalesforceVignetteRangeInfo());


  const [vignetteStatusesEnumsResults] = useLazyQuery(
    getSalesforceVignetteStatusesEnums()
  );

  const loadLovList = async () => {
    const result = await vignetteStatusesEnumsResults();
    const list = VignetteLookupsToList(result.data);
    return list;
  }

  const getEntityInfo = async () => {
    let vignetteRangeInfo: IVignetteRangeInfo = {
      rangeCode: "",
      rangeYear: null,
      rangeFrom: null,
      rangeTo: null,
      rangeStatus: "ACTIVE",
    };

    if (!isEmpty(vignetteRangeId)) {
      const apiResult = await vignetteRangeInfoQuery({
        variables: { id: vignetteRangeId },
      });
      if (apiResult.data) {
        vignetteRangeInfo = graphqlToVignetteRangeInfo(
          apiResult?.data
        );
      }
    }

    return vignetteRangeInfo;
  }

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      const [entityData, lovData] = await Promise.all([getEntityInfo(), loadLovList()]);

      (updatedInputs.rangeStatus as IFormSelectDynamicProps).selectOptions = lovData["SalesforceManagement_VignetteRangeStatuses"];
      if (entityData) {
        updatedInputs.rangeYear.value = entityData.rangeYear;
        updatedInputs.rangeCode.value = entityData.rangeCode;
        updatedInputs.rangeFrom.value = entityData.rangeFrom;
        updatedInputs.rangeTo.value = entityData.rangeTo;
        updatedInputs.rangeStatus.value = entityData.rangeStatus;
        setInputsForm(updatedInputs);
      }
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");
  
    try {
      const variables = {
        entityId: params.id,
        rangeCode: data.rangeCode,
        rangeFrom: parseInt(data.rangeFrom as unknown as string),
        rangeStatus: data.rangeStatus,
        rangeTo: parseInt(data.rangeTo as unknown as string),
        rangeYear: parseInt(data.rangeYear as unknown as string),
      };
  
      const res = await vignetteRangeAction({
        variables: vignetteRangeId
          ? {
              ...variables,
              selectedBusinessPartnerVignetteRangeId: vignetteRangeId,
            }
          : variables,
        errorPolicy: "all",
      });
  
      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {vignetteRangeId
              ? "Vignette Range successfully updated"
              : "Vignette Range successfully created"}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState("success");
          setFormDisabled(false);
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        setFormDisabled(false);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (err) {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  return (
    <GenericDrawer
      title={vignetteRangeId ? "Modify Vignette Range" : "New Vignette Range"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {
        !booted ?
        <Loader /> :
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      }
    </GenericDrawer>
  );
};

export default SalesforceVignetteRangeDrawer;
