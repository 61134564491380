import { IListingData } from '../../models/listing';
import {
    lookupListAsRecordObject,
  } from "../../utils/graph-utils";
  import { IActivityEntityBase } from "../../activities";

  const SellBuy: Record<string, string> =  {"BUY": "Buy", "SELL": "Sell", "BUY_AND_SELL": "Buy & Sell"}

export function mapToListingData(data: any): IListingData<any> {
  const list: Record<string, IProductsGenericTableData> = {};
  data.Accounting.queries.ProductsList?.items?.forEach(
    (obj: IProductsGenericTableData, i: number) => {

      const buySellValue = SellBuy[obj.accounting_Product_BuySell];

      const buySellArray: string[] = [];

      if(buySellValue === "Buy & Sell"){
        buySellArray.push("Sell", "Buy");
      } else {
        buySellArray.push(buySellValue);
      }

      list[i] = {
        accounting_Product_Id: obj.accounting_Product_Id,
        accounting_Product_ProductName: obj.accounting_Product_ProductName,
        accounting_Product_ProductDescription: obj.accounting_Product_ProductDescription,
        accounting_Product_ProductPrice: obj.accounting_Product_ProductPrice,
        accounting_Product_BuySell: buySellArray as any,
        accounting_Product_createdOn: obj.accounting_Product_createdOn,
        accounting_Product_modifiedOn: obj.accounting_Product_modifiedOn,
        accounting_Product_RelatedIncomeAccount: obj.accounting_Product_RelatedIncomeAccount?.Id,
        accounting_Product_RelatedExpenseAccount: obj.accounting_Product_RelatedExpenseAccount?.Id,
        accounting_Product_RelatedCompany: obj.accounting_Product_RelatedCompany.Id,
      };
    },
  );
  

  return {
    pagedItems: list,
    pageSize: data.Accounting.queries.ProductsList?.paging.pageSize,
    pageNumber: data.Accounting.queries.ProductsList?.paging.pageNumber,
    totalCount: data.Accounting.queries.ProductsList?.paging.totalCount,
  };
}

export function toLookups(data: any) {
    let lovs: Record<string, Record<string, string>> = {
      accounts: {}
    };
    lovs.account = lookupListAsRecordObject(
      data?.Accounting?.queries?.GetAccountsOfCompany
    );
  
    return lovs;
  }

  export function toLookupsData(data: any) {
    let lovs: Record<string, Record<string, string>> = {
      company: {},
      SellBuy: {}
    };
    
      lovs.company = lookupListAsRecordObject(
        data?.SalesforceManagement?.lookups?.relatedCompanies
      );
      lovs.SellBuy =  SellBuy;
    return lovs;
  }

  export function convertAuditTrailsToActivities(data: any): IActivityEntityBase[] {
    return data.Accounting.queries.GetProductAuditTrails.map((auditTrail: any) => ({
        id: auditTrail.accounting_AuditTrail_Id,
        createdAt: new Date(auditTrail.accounting_AuditTrail_createdOn),
        htmlBody: auditTrail.accounting_AuditTrail_Message,
        iconUrl: extractIconUrl(auditTrail.accounting_AuditTrail_Message)
    }));
}

function extractIconUrl(html: string): string | undefined {
  const match = html.match(/background-image: url\('(.+?)'\)/);
  return match ? match[1] : undefined;
}
