import { gql } from "@apollo/client";

export function listQuery() {
  return gql`
    query LIST_BUSINESSPARTNERSAPPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedTypes: [String]
      $businessPartnerStatus: [SalesforceManagement_BusinessPartnerStatuses]
      $isCompany: [Boolean]
      $isLocal: [Boolean]
      $isRegistered: [Boolean]
      $selectedRelatedCompany: [String]
      $createdDateFrom: Date
      $createdDateTo: Date
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      SalesforceManagement {
        queries {
          getBusinessPartnerList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            IsCompany: $isCompany
            IsLocal: $isLocal
            IsRegistered: $isRegistered
            selectedRelatedCompany: $selectedRelatedCompany
            selectedTypes: $selectedTypes
            SelectedStatuses: $businessPartnerStatus
            FromCreatedOn: $createdDateFrom
            ToCreatedOn: $createdDateTo
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_BusinessPartner_Id
              salesforceManagement_BusinessPartner_IsCompany
              salesforceManagement_BusinessPartner_IsLocal
              salesforceManagement_BusinessPartner_IsRegistered
              salesforceManagement_BusinessPartner_RegisterNumber
              salesforceManagement_BusinessPartner_TaxAccount
              salesforceManagement_BusinessPartner_Account
              salesforceManagement_BusinessPartner_MaxCreditLimitAllowedCurrency {
                Code
                Title
              }
              salesforceManagement_BusinessPartner_MaxCreditLimitAllowed
              salesforceManagement_BusinessPartner_Status
              person_Id
              salesforceManagement_BusinessPartner_createdOn
              person_FullName
              person_PersonType
              salesforceManagement_BusinessPartner_createdBy
              salesforceManagement_BusinessPartner_modifiedOn
              salesforceManagement_BusinessPartner_modifiedBy
              salesforceManagement_BusinessPartner_Code
              salesforceManagement_BusinessPartner_RelatedCompany {
                Id
                Title
              }
              salesforceManagement_BusinessPartner_Type {
                Id
                Title
              }
              salesforceManagement_BusinessPartner_Category {
                Id
                Title
              }
              salesforceManagement_BusinessPartner_PersonID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      FullName
                      Email
                      PhoneDetails {
                        PhoneNumber
                      }
                    }
                  }
                }
              }
            }
          }
        }
        lookups {
          businessPartnerTypes {
            Code
            Title
            Id
          }
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
      SalesforceManagement_BusinessPartnerStatuses: __type(
        name: "SalesforceManagement_BusinessPartnerStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
