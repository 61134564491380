import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery } from "@apollo/client";
import { listQuery, GetTransactionsAuditTrails } from "./queries";
import { mapToListingData, toLookupsData, convertAuditTrailsToActivities } from "./utils";
import { filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { IEnhanceTableHeaderClickable } from '../../components/enhanced-table';
import { toast } from "react-toastify";
import CustomActivities from "../../activities/CustomActivities";
import { IEnhancedMenuItem } from "../../components";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
  MAIN_ONE_THEME
} from "../../constants";
import dayjs from "dayjs";
import AccountDrawer from "../../forms/account-drawer/AccountDrawer";
import AccountImportDrawer from "../../forms/account-import-drawer/AccountImportDrawer";
import { IActivityEntityBase } from "../../activities";
import TransactionPopUp from "../../forms/transaction-popup/TransactionPopUp";

interface ITransactionsPage {}

interface ITransactionPageAuditTrailInfo {
  title: string;
  items: IActivityEntityBase[];
  loading: boolean;
}

const TransactionsPage: React.FC<ITransactionsPage> = ({}) => {

  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);
  const [accountDrawerOpen, setAccountDrawerOpen] =
    useState<boolean>(false);
  const [transactionsPopupOpen, setTransactionsPopupOpen] =
    useState<boolean>(false);
  const [accountImportDrawerOpen, setAccountImportDrawerOpen] =
    useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>("");
  const actions: IEnhancedMenuItem[] = [
    {
      title: '+ New Transaction',
      onClick: () => {},
      isSecondary: true,
        backgroundColor: "#000",
        color: "#fff"
    }
  ];

  const FILTER_SESSION_KEY = "transactionsFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
        createdOn: [],
        company: "41",
        account: []
      };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [auditTrailInfo, setAuditTrailInfo] = useState<ITransactionPageAuditTrailInfo>();

  const [getTransactionsAuditTrails] = useLazyQuery(GetTransactionsAuditTrails());

  const [getTransactionsLazy, { loading, error, data, fetchMore }] =
    useLazyQuery(listQuery(), {
      variables: {
        SelectedCompany:
          filterValues?.company && filterValues?.company?.length > 0
            ? filterValues?.company
            : "41",
        CreatedOnFromDate: filterValues?.createdOn[0]
          ? dayjs(filterValues.createdOn[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        CreatedOnToDate: filterValues?.createdOn[1]
          ? dayjs(filterValues.createdOn[1]).add(1, 'day').format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        SelectedAccounts: filterValues?.account
          ? filterValues?.account
          : null,
        pageNumber: 1,
        pageSize: tableData.pageSize
      },
    });

  useEffect(() => {
    getTransactionsLazy();
  }, []);

  const viewHistoryClick = async (id: string, title: string) => {

    setAuditTrailInfo({
      items: [],
      loading: true,
      title: title,
    });

    const result = await getTransactionsAuditTrails({
      variables: {
        id: id,
      }
    });

    if(result.data){
      setAuditTrailInfo({
        items: convertAuditTrailsToActivities(result.data),
        loading: false,
        title: title,
      });
    }
  };

  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = toLookupsData(data);

      if (!Object.keys(lovs.account).length) {
        lovs.account = filterValues?.account;
      }

      if (!Object.keys(lovs.company).length) {
        lovs.company = filterValues?.company;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [data]);

  useEffect(() => {
    if (loading) setTableLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);

      setTableData({ ...tableData, pageNumber: 0, pageSize: tableData.pageSize });
    }
    setTableLoadingState(false);
  }, [loading, error, data]);

  async function handlePageChange(page: number) {
    setTableLoadingState(true);
    
    try {
      const nextPage = ++page;
      const fetchMoreResult = await fetchMore({
        variables: {
          pageNumber: nextPage,
          pageSize: tableData.pageSize,
        },
      });
  
      if (fetchMoreResult.data) {
        const newApplicationList = mapToListingData(fetchMoreResult.data);
  
        const updatedTableData = {
          ...newApplicationList,
          pageNumber: nextPage - 1,
        };
        setTableData(updatedTableData);
      }
    } catch (error) {
      
    } finally {
      setTableLoadingState(false);
    }
  }

  async function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
  
    try {
      const fetchMoreResult = await fetchMore({
        variables: {
          pageNumber: 1,
          pageSize: numberOfRecordsPerPage,
        },
      });
  
      if (fetchMoreResult.data) {
        const newApplicationList = mapToListingData(fetchMoreResult.data);
  
        const updatedTableData = {
          ...newApplicationList,
          pageNumber: 0,
          pageSize: numberOfRecordsPerPage,
        };
        setTableData(updatedTableData);
      }
    } catch (error) {
      // Handle errors if necessary
    } finally {
      setTableLoadingState(false);
    }
  }  

  actions[0].onClick = () => {
    setChosenEditId(undefined);
    setTransactionsPopupOpen(true);
  };

  (headers.accounting_Transactions_TransactionNumber as IEnhanceTableHeaderClickable).callback =
    (payload: any) => {
      setChosenEditId(payload.columns.accounting_Transactions_Id);
      setTransactionsPopupOpen(true);
    };

  (headers.viewHistory as IEnhanceTableHeaderClickable).callback =
    (payload: any) => {
      const id = payload.columns.accounting_Transactions_Id;
      viewHistoryClick(id, payload.columns.accounting_Transactions_TransactionNumber);
    }

  const renderMainChildren = () => {

    return (
      <>
        {loading ? (
          <div></div>
        ) : (
          <div style={{ marginTop: "20px" }}>
            <EnhancedTable
              title="Transactions"
              name="count"
              orderByAscendingByDefault
              inline={false}
              data={tableData}
              headers={headers}
              handlePageChange={(page: number) => handlePageChange(page)}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              hideToolbar
              currentPage={tableData.pageNumber}
              disableSelection
              usePagination={true}
              actions={actions}
              loader={tableLoadingState}
              showCellFullData={true}
            />
            {accountDrawerOpen && (
              <AccountDrawer
                open={accountDrawerOpen}
                onClose={() => setAccountDrawerOpen(false)}
                onSuccess={() => {
                  handlePageChange(0);
                }}
                accountId={chosenEditId}
                accountInfo={undefined}
              />
            )}
            {accountImportDrawerOpen && (
              <AccountImportDrawer
                open={accountImportDrawerOpen}
                onClose={() => setAccountImportDrawerOpen(false)}
                onSuccess={() => {
                  handlePageChange(0);
                }}
              />
            )}
            {transactionsPopupOpen && (
              <TransactionPopUp
                open={transactionsPopupOpen}
                onClose={() => setTransactionsPopupOpen(false)}
                transactionId={chosenEditId}
                onSuccess={() => {
                  getTransactionsLazy()
                }}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={""}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={loading || loadingState}
      name={"Applications"}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
      rightChildren={<CustomActivities loader={auditTrailInfo?.loading || false} title={auditTrailInfo?.title || ""} items={auditTrailInfo?.items || []} />}
      onRightCollapseClick={(v) => {
        if (auditTrailInfo) {
          setAuditTrailInfo(undefined);
        }
      }}
      config={
        {
          leftColumn: {
            backgroundColor: '#FFFFFF',
            collapsable: false,
            collapsed: false,
            width: 2,
          },
          mainColumn: {
            backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
            collapsable: false,
            collapsed: false,
            width: auditTrailInfo ? 7 : 10,
          },
          rightColumn: {
            backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
            collapsable: true,
            collapsed: !auditTrailInfo,
            width: auditTrailInfo ? 3 : 0,
          },
        }
      }
    />
  );
}
export default TransactionsPage;