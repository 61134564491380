import { RefreshTokenObject } from "../redux/user/types";
import DataService from "../services/dataService";
import { resolveIdentityBaseUrl } from "./tenant-utils";
import { isEmpty } from "./validationUtils";

type StoredRefreshTokenObject = RefreshTokenObject & { savedDate: string };

const USE_TOKEN_KEY = "jwtToken";
const USE_ID_TOKEN_KEY = "idToken";
const USE_REFRESH_TOKEN_OBJECT = "refreshToken_object";

/**
 * Returns the user token
 * @return {string} The user token
 * If the token is not defined returns an empty string
 */
export function getUserToken(): string {
  const token = window.localStorage.getItem(USE_TOKEN_KEY);
  return token || "";
}

/**
 * Sets the user token
 * @param {string} token The user token
 */
export function setUserToken(token: string): void {
  window.localStorage.setItem(USE_TOKEN_KEY, token);
}

/**
 * Removes the user token
 */
export function clearUserTokens(): void {
  window.localStorage.clear();
  window.localStorage.removeItem(USE_TOKEN_KEY);
  window.localStorage.removeItem(USE_ID_TOKEN_KEY);
  window.localStorage.removeItem(USE_REFRESH_TOKEN_OBJECT);
}

/**
 * Sets the user id token (used for logout)
 * @param {string} token The user token
 */
export function setUserIdToken(token: string): void {
  window.localStorage.setItem(USE_ID_TOKEN_KEY, token);
}

/**
 * Returns the user id token
 * @return {string} The user id token
 * If the token is not defined returns an empty string
 */
export function getUserIdToken(): string {
  const token = window.localStorage.getItem(USE_ID_TOKEN_KEY);
  return token || "";
}

/**
 * Sets the user refresh token
 * @param {string} token The user token
 */
export function setUserRefreshTokenObject(
  refreshTokenObject: RefreshTokenObject
): void {
  const newRefreshTokenObject = {
    expiresIn: refreshTokenObject.expiresIn,
    refreshToken: refreshTokenObject.refreshToken,
    savedDate: new Date().toString(),
  } as StoredRefreshTokenObject;
  window.localStorage.setItem(
    USE_REFRESH_TOKEN_OBJECT,
    JSON.stringify(newRefreshTokenObject)
  );
}

/**
 * Returns the user refresh token
 * @return {string} The user refresh token
 * If the token is not defined returns an empty string
 */
export function getUserRefreshTokenObject(): StoredRefreshTokenObject | null {
  const refreshTokenObjectString = window.localStorage.getItem(
    USE_REFRESH_TOKEN_OBJECT
  );
  if (isEmpty(refreshTokenObjectString)) {
    return null;
  }
  return JSON.parse(
    refreshTokenObjectString as string
  ) as StoredRefreshTokenObject;
}

export function setUserTokens(
  jwtToken: string,
  id_token: string,
  refreshTokenObject?: Omit<RefreshTokenObject, "savedDate">
) {
  clearUserTokens();
  setUserToken(jwtToken);
  setUserIdToken(id_token);
  refreshTokenObject && setUserRefreshTokenObject(refreshTokenObject);
}

export async function refreshTokensAsync() {
  if (isEmpty(getUserRefreshTokenObject())) {
    return undefined;
  }
  const currentDate = new Date();
  const refreshTokenSavedDate = new Date(getUserRefreshTokenObject().savedDate);

  const timeDifferenceMs =
    currentDate.getTime() - refreshTokenSavedDate.getTime();
  if (timeDifferenceMs < 10000) {
    return undefined;
  }

  const body = new URLSearchParams();
  body.append("client_id", "graphql");
  body.append("grant_type", "refresh_token");
  body.append("scope", "offline_access openid");
  body.append("client_secret", "06BD941-1437-402C-A09F-A9BDA23044BD");
  body.append("refresh_token", getUserRefreshTokenObject().refreshToken);
  return DataService.postUrlEncoded(
    "/connect/token",
    body,
    {
      credentials: "include",
    },
    undefined,
    undefined,
    undefined,
    resolveIdentityBaseUrl()
  ).then(async (response) => {
    if (response.ok) {
      const result = (await response.json()) as {
        id_token: string;
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
        scope: string;
      };
      setUserTokens(result.access_token, result.id_token, {
        refreshToken: result.refresh_token,
        expiresIn: String(result.expires_in),
      });

      return result;
    }
    return undefined;
  });
}
