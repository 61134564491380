import { isEmpty } from "lodash";
import { enumListAsRecordObject } from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const planEnums: Record<string, Record<string, string>> = {};
  let lines: Record<string, string> = {};
  let sublines: Record<string, string> = {};
  let sublineStatus: Record<string, string> = {};

  planEnums[data.PlanConfigManagement_PlanStatuses.name] =
    enumListAsRecordObject(data.PlanConfigManagement_PlanStatuses.enumValues);

  data.PlanConfigManagement.queries.allSublines.forEach(
    (element: {
      planConfigManagement_Subline_Id: string;
      planConfigManagement_Subline_Name: string;
    }) => {
      sublines[element.planConfigManagement_Subline_Id] =
        element.planConfigManagement_Subline_Name;
    }
  );

  data.PlanConfigManagement.queries.allSublines.forEach(
    (element: {
      planConfigManagement_Subline_Id: string;
      planConfigManagement_Subline_Status: string;
    }) => {
      sublineStatus[element.planConfigManagement_Subline_Id] =
        element.planConfigManagement_Subline_Status;
    }
  );

  planEnums["sublines"] = sublines;
  planEnums["sublineStatus"] = sublineStatus;

  data.PlanConfigManagement.queries.allLines.forEach(
    (element: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lines[element.planConfigManagement_Line_Id] =
        element.planConfigManagement_Line_Name;
    }
  );

  planEnums["lines"] = lines;

  return planEnums;
}

export function graphqlEntityToPlanInfo(
  data: any,
  lineId: string,
  lineName: string,
  sublineID?: string
): IPlanInfo {
  return {
    lineName: lineId,
    sublineName: isEmpty(sublineID)
      ? data?.PlanConfigManagement?.entities?.plan?.views
          ?.PlanConfigManagement_all?.properties?.SublineID?.id
      : sublineID,
    planName:
      data?.PlanConfigManagement?.entities?.plan?.views
        ?.PlanConfigManagement_all?.properties?.Name,
    externalCode:
      data?.PlanConfigManagement?.entities?.plan?.views
        ?.PlanConfigManagement_all?.properties?.ExternalCode,
    arabicName:
      data?.PlanConfigManagement?.entities?.plan?.views
        ?.PlanConfigManagement_all?.properties?.NameArabic,
    abbreviation:
      data?.PlanConfigManagement?.entities?.plan?.views
        ?.PlanConfigManagement_all?.properties?.Abbreviation,
    effectiveFrom:
      data?.PlanConfigManagement?.entities?.plan?.views
        ?.PlanConfigManagement_all?.properties?.EffectiveFrom,
    effectiveTo:
      data?.PlanConfigManagement?.entities?.plan?.views
        ?.PlanConfigManagement_all?.properties?.EffectiveTo,
    planStatus:
      data?.PlanConfigManagement?.entities?.plan?.views
        ?.PlanConfigManagement_all?.properties?.PlanStatus,
  };
}

export function extractSublines(data: any) {
  let sublines: Record<string, string> = {};

  data.PlanConfigManagement.queries.allSublines.forEach(
    (element: {
      planConfigManagement_Subline_Id: string;
      planConfigManagement_Subline_Name: string;
    }) => {
      sublines[element.planConfigManagement_Subline_Id] =
        element.planConfigManagement_Subline_Name;
    }
  );

  return sublines;
}
