import { useApolloClient, useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IPersonDetailsPageProps } from ".";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from "../../constants";
import { headers, steps } from "./content";
import {
  activatePerson,
  deactivatePerson,
  getPersonDetailsAddressList,
  getPersonDetailsInfo,
} from "./queries";
import {
  entityToPersonDetails,
  getPersonStatus,
  mapToPersonAddressesListingData,
} from "./utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import { makeStyles } from "tss-react/mui";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import PersonDrawer from "../person-drawer/PersonDrawer";
import EnhancedButton, {
  EnhancedButtonStatus,
} from "../../components/EnhancedButton";
import { IConfirmation } from "../../redux/confirmation/types";
import { isEmpty } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import WidgetSection from "../../components/common/WidgetSection";
import { IListingData } from "../../models/listing";
import {
  capitalizeFirstCharacter,
  capitalizeFirstLetter,
} from "../../utils/formatting-utils";
import { IEnhancedTableMenuItem } from "../../components/enhanced-table";
import PersonAddressDrawer from "../person-address-drawer/PersonAddressDrawer";
import ShowForUser from "../../components/user/ShowForUser";
import AdditionalInformationDrawer from "../person-additional-iformation-drawer/AdditionalInformationDrawer";
import PersonDetailsDrawer from "../personal-information-drawer/PersonDetailsDrawer";
import { IPersonDetailsDrawerInfo } from "../personal-information-drawer";
import { ITableOrder, TableSortOrder } from "../../utils/table-utils";
import _ from "lodash";
import PersonalInformationDetailsWidget from "./widgets/PersonalInformationDetailsWidget";
import PersonEntityInfoWidget from "./widgets/PersonEntityInfoWidget";
import AdditionalInformationDetailsWidget from "./widgets/AdditionalInformationDetailsWidget";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    ".Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& label": {
      "& span": {
        margin: "-2px 0",
      },
    },
  },
  sectionTitle: {
    padding: "-10x 10x!important",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "15px 20px 10px 0px",
    alignItems: "flex-start",
    display: "grid",
    gap: "5px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "19.5% 19.5% 19.5% 19% 16% 3% 3%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  repeaterField: {
    margin: "0px!important",
  },
  arField: {
    width: "96%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
  },
  actionButton: {
    display: "inline-block",
    marginRight: "5px",
  },
}));

const PersonDetailsPage: React.FC<IPersonDetailsPageProps> = () => {
  const { classes } = useStyles();
  const params = useParams();
  const dispatch = useAppDispatch();

  const tenant = useAppSelector((state) => state.tenant);
  const client = useApolloClient();
  const user = useAppSelector((state) => state.user);
  const entityId = params.id;
  const isAdmin = user.userRoles.includes("Insurance-Admin");

  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [personInfoDrawerOpen, setPersonInfoDrawerOpen] =
    useState<boolean>(false);
  const [personAddressDrawerOpen, setPersonAddressDrawerOpen] =
    useState<boolean>(false);
  const [additionalInfoDrawerOpen, setAdditionalInfoDrawerOpen] =
    useState<boolean>(false);
  const [personDrawerOpen, setPersonDrawerOpen] = useState<boolean>(false);

  const [actionClicked, setActionClicked] = useState(null);
  const [selectedPersonAddressEntityId, setSelectedPersonAddressEntityId] =
    useState("");
  const [selectedPersonAddressItems, setSelectedPersonAddressItems] =
    useState<number>(0);

  const [booted, setBooted] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState("");
  let setSearchTimeout: NodeJS.Timeout;
  const [tableOrders, setTableOrders] = useState<Record<string, ITableOrder>>({
    address: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [personDetailsInfo, setPersonDetailsInfo] =
    useState<Record<string, any>>();

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 2,
    totalCount: 0,
  });

  const [activateAction] = useMutation(activatePerson(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
  });
  const [deactivateAction] = useMutation(deactivatePerson(), {
    variables: {
      entityId: entityId,
    },
  });

  const [entityResultQuery] = useLazyQuery(getPersonDetailsInfo());

  const [personAddressListResultQuery] = useLazyQuery(
    getPersonDetailsAddressList()
  );

  const loadEntityData = async () => {
    const result = await entityResultQuery({
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });

    return result;
  };

  const loadAddressList = async (
    currentPage: number = 0,
    pageSize = tableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword = keywordSearch
  ) => {
    const result = await personAddressListResultQuery({
      variables: {
        selectedPersonID: params.id,
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        Attribute: capitalizeFirstCharacter(
          orders?.address?.orderBy ||
            "SalesforceManagement_PersonAddress_IsPrimary"
        ),
        Descending: orders?.address?.orderDirection == "asc" ? false : true,
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: "all",
    });

    return result;
  };

  const initialize = async () => {
    try {
      const [entityData, addressData] = await Promise.all([
        loadEntityData(),
        loadAddressList(0, 10),
      ]);

      const personDetails = await entityToPersonDetails(entityData.data);
      const mappedAddressList = await mapToPersonAddressesListingData(
        addressData.data
      );

      setPersonDetailsInfo(personDetails);
      setTableData({ ...mappedAddressList, pageNumber: 0 });

      setBooted(true);
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  let personDrawerListingActions: IEnhancedTableMenuItem[] = [];
  personDrawerListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedPersonAddressItems !== 1,
    },
  ];

  personDrawerListingActions[0].onClick = () => {
    setActionClicked("new");
    setPersonAddressDrawerOpen(true);
  };

  personDrawerListingActions[1].onClick = () => {
    setActionClicked("edit");
    setPersonAddressDrawerOpen(true);
  };

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrders, val);
    }, 500);
  };

  function handleSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.address.orderBy = orderBy;
    newOrder.address.orderDirection = order as any;
    handlePageChange(tableData.pageNumber, tableData.pageSize, newOrder);

    setTableOrders(newOrder);
  }

  async function handlePageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = keywordSearch
  ) {
    const result = await loadAddressList(page, pageSize, orders, searchKeyword);
    const mappedAddressList = await mapToPersonAddressesListingData(
      result.data
    );
    setTableData({
      ...mappedAddressList,
      pageNumber: page,
      pageSize: pageSize,
    });
  }

  const handlePersonSectionChange = async () => {
    try {
      client.refetchQueries({
        include: [getPersonDetailsInfo()],
      });
    } catch (error) {
      toast.error(<ToastErrorMessage>{error}</ToastErrorMessage>);
    }
  };

  const renderMainChildren = () => {
    const status = personDetailsInfo?.status?.toLowerCase();
    const isPersonActive = status === "active";

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={getPersonStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isPersonActive ? "Deactivate" : "Activate"}
            buttonState={
              isPersonActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={async () => {
              if (isPersonActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Deactivate",
                  message: `Are you sure you want to deactivate this person?`,
                  callback: async () => {
                    try {
                      setDeactivateButtonState("loading");
                      const result = await deactivateAction();
                      if (isEmpty(result.errors)) {
                        initialize();
                        setDeactivateButtonState("success");
                        toast.success(
                          <ToastSuccessMessage>
                            {
                              "Person successfully deactivated. Relevant Business Partners are deactivated as well."
                            }
                          </ToastSuccessMessage>
                        );
                      } else {
                        setDeactivateButtonState(undefined);
                        toast.error(
                          <ToastErrorMessage>
                            {getError(result)}
                          </ToastErrorMessage>
                        );
                      }
                    } finally {
                      setTimeout(() => {
                        setDeactivateButtonState(undefined);
                      }, 1000);
                    }
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this person?`,
                  callback: async () => {
                    try {
                      setActivateButtonState("loading");
                      const result = await activateAction();
                      if (isEmpty(result.errors)) {
                        initialize();
                        setActivateButtonState("success");
                        toast.success(
                          <ToastSuccessMessage>
                            {"Person successfully activated."}
                          </ToastSuccessMessage>
                        );
                      } else {
                        setActivateButtonState(undefined);
                        toast.error(
                          <ToastErrorMessage>
                            {getError(result)}
                          </ToastErrorMessage>
                        );
                      }
                    } finally {
                      setTimeout(() => {
                        setActivateButtonState(undefined);
                      }, 1000);
                    }
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
          {personDrawerOpen && (
            <PersonDrawer
              open={personDrawerOpen}
              onClose={() => setPersonDrawerOpen(false)}
              onSuccess={() => {
                handlePersonSectionChange();
              }}
              personId={entityId}
            />
          )}
          <PersonalInformationDetailsWidget
            data={personDetailsInfo}
            actions={
              <ShowForUser allowedRoles={["Insurance-Admin"]}>
                {/* {status != "inactive" && ( */}
                  <div>
                    <EnhancedButton
                      type="button"
                      backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                      color="#FFF"
                      onClick={() => setPersonInfoDrawerOpen(true)}
                      className={classes.actionButton}
                    >
                      Modify
                    </EnhancedButton>
                  </div>
                {/* )} */}
              </ShowForUser>
            }
          />
          {personInfoDrawerOpen && (
            <PersonDetailsDrawer
              open={personInfoDrawerOpen}
              onClose={() => setPersonInfoDrawerOpen(false)}
              onSuccess={() => {
                initialize();
              }}
              personDetailsInfo={
                personDetailsInfo as unknown as IPersonDetailsDrawerInfo
              }
              personId={params.id}
            />
          )}
          {personDetailsInfo?.type?.toUpperCase() === "PERSON" && (
            <div style={{ marginTop: "20px" }}>
              <AdditionalInformationDetailsWidget
                data={personDetailsInfo}
                actions={
                  <ShowForUser allowedRoles={["Insurance-Admin"]}>
                    {/* {status != "inactive" && ( */}
                      <div>
                        <EnhancedButton
                          type="button"
                          backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                          color="#FFF"
                          onClick={() => setAdditionalInfoDrawerOpen(true)}
                          className={classes.actionButton}
                        >
                          Modify
                        </EnhancedButton>
                      </div>
                    {/* )} */}
                  </ShowForUser>
                }
              />
              {additionalInfoDrawerOpen && (
                <AdditionalInformationDrawer
                  open={additionalInfoDrawerOpen}
                  onClose={() => setAdditionalInfoDrawerOpen(false)}
                  onSuccess={() => {
                    initialize();
                  }}
                  personId={entityId}
                  personType={personDetailsInfo?.type}
                />
              )}
            </div>
          )}
          <>
            <WidgetSection style={{ margin: "-23px 0 0 0" }}>
              <form>
                <div
                  style={{
                    marginTop: "20px",
                    padding: "-23px 0 0 0 !important",
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter(" Addresses Information")}
                    inlineTitle={capitalizeFirstLetter(
                      " Addresses Information"
                    )}
                    name="count"
                    entityName={"PersonAddress"}
                    entityIdColumnName={"salesforceManagement_PersonAddress_Id"}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={tableData}
                    headers={headers}
                    handlePageChange={(page: number) =>
                      handlePageChange(page, tableData.pageSize)
                    }
                    handleRowsPerPageChange={(pageSize: number) =>
                      handlePageChange(0, pageSize)
                    }
                    handleSort={(orderBy: string, order: string) =>
                      handleSortChange(orderBy, order)
                    }
                    handleSearchChange={delaySearch}
                    currentPage={tableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={false}
                    actions={personDrawerListingActions}
                    showTablePagination={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setSelectedPersonAddressItems(
                        newDetailedSelection.length
                      );
                      if (newDetailedSelection.length > 0) {
                        setSelectedPersonAddressEntityId(
                          newDetailedSelection[0].id
                        );
                      } else {
                        setSelectedPersonAddressEntityId("");
                      }
                    }}
                    ordering={
                      tableOrders.address.orderDirection as TableSortOrder
                    }
                    orderingBy={tableOrders.address.orderBy}
                  />

                  {personAddressDrawerOpen && (
                    <PersonAddressDrawer
                      open={personAddressDrawerOpen}
                      personAddressId={
                        actionClicked === "new"
                          ? null
                          : selectedPersonAddressEntityId
                      }
                      onClose={() => setPersonAddressDrawerOpen(false)}
                      onSuccess={() => {
                        handlePageChange(0, 10);
                      }}
                      personId={entityId}
                    />
                  )}
                </div>
              </form>
            </WidgetSection>
          </>
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return <PersonEntityInfoWidget data={personDetailsInfo} />;
  };

  return !booted ? (
    <Loader />
  ) : (
    <StaticLayout
      name={"Person Details"}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default PersonDetailsPage;
