import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import {
  ISalesForceLineCreditLimitDrawerInfo,
  ISalesForceLineCreditLimitDrawerProps,
} from ".";
import { getListForm, updateLineCreditLimit } from "./queries";
import { graphqlToDefaultLineCreditLimitInfo, toLookups } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { getAssignedLineInfo } from "../set-default-commission-drawer/queries";

const SalesForceLineCreditLimitDrawer: React.FC<
  ISalesForceLineCreditLimitDrawerProps
> = ({
  open,
  onSuccess,
  onClose,
  businessPartnerId,
  businessPartnerLinesId,
}) => {
  
    const [booted, setBooted] = useState<boolean>(false);
    const [formDisabled, setFormDisabled] = useState(false);
    const [submitButtonState, setSubmitButtonState] =
      useState<EnhancedButtonStatus>();
    const [inputsForm, setInputsForm] =
      useState<Record<string, DynamicFormInputType>>(inputs);

    const [lineCreditLimitsDetailsListResults] = useLazyQuery(getListForm());

    const [defaultLineCreditLimitInfoQuery] = useLazyQuery(getAssignedLineInfo());

    const [lineCreditLimitAction] = useMutation(updateLineCreditLimit());

    const loadLovList = async () => {
      const result = await lineCreditLimitsDetailsListResults();
      const list = toLookups(result.data);
      return list;
    }

    const getEntityInfo = async () => {
      let defaultLineCreditLimit: ISalesForceLineCreditLimitDrawerInfo = {
        creditLimit: null,
        creditLimitCurrency: "39735",
        gracePeriod: null,
      };

      if (businessPartnerLinesId && businessPartnerLinesId.length === 1) {
        const apiResult = await defaultLineCreditLimitInfoQuery({
          variables: { id: businessPartnerLinesId[0] },
        });
        if (apiResult.data) {
          defaultLineCreditLimit = graphqlToDefaultLineCreditLimitInfo(
            apiResult?.data
          );
        }
      }

      return defaultLineCreditLimit;
    }

    useEffect(() => {
      initialize();
    }, []);

    const initialize = async () => {
      try {
        const updatedInputs = cloneDeep(inputsForm);

        const [entityData, lovData] = await Promise.all([getEntityInfo(), loadLovList()]);

        (updatedInputs.creditLimitCurrency as IFormSelectDynamicProps).selectOptions = lovData["creditLimitCurrency"];

        if (entityData) {
          updatedInputs.creditLimitCurrency.value =
            entityData.creditLimitCurrency || "39735";
          updatedInputs.creditLimit.value = entityData.creditLimit;
          updatedInputs.gracePeriod.value = entityData.gracePeriod;
          setInputsForm(updatedInputs);
        }
        setBooted(true);
      } catch (err) {
        toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      }
    };

    const submitForm = async (values: Record<string, any>) => {
      const [data] = normaliseDynamicValues(inputs, values);
    
      setFormDisabled(true);
      setSubmitButtonState("loading");
    
      try {
        const variablesMutation = {
          entityId: businessPartnerId,
          selectedBusinessPartnerLineIds: businessPartnerLinesId,
          creditLimit: parseInt(data.creditLimit as unknown as string),
          creditLimitCurrency: data.creditLimitCurrency || null,
          gracePeriod: parseInt(data.gracePeriod as unknown as string),
        };
    
        const res = await lineCreditLimitAction({
          variables: variablesMutation,
          errorPolicy: "all",
        });
    
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              Credit Limit successfully updated.
            </ToastSuccessMessage>
          );
    
          setTimeout(() => {
            setFormDisabled(false);
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          setFormDisabled(false);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      } catch (err) {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(err)}</ToastErrorMessage>);
      } finally {
      }
    };
    

    return (
      <GenericDrawer
        title={"Modify Line Credit Limit"}
        onClose={() => onClose()}
        isOpen={open}
      >
        {!booted ? (
          <Loader />
        ) : (
          <>
            <DynamicForm
              inputs={inputsForm}
              onSubmit={(values) => submitForm(values)}
              buttonText={"Submit"}
              submitButtonState={submitButtonState}
              disableForm={formDisabled}
              title="Information"
              hasDoprdownSpecificBehavior={true}
            />
          </>
        )}
      </GenericDrawer>
    );
  };

export default SalesForceLineCreditLimitDrawer;
