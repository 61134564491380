import { gql } from "@apollo/client";

export function listQuery() {
  return gql`
    query LIST_LINESAPPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $lineStatus: [PlanConfigManagement_LineStatuses!]
      $createdDateFrom: Date
      $createdDateTo: Date
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getLineList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            SelectedStatuses: $lineStatus
            FromCreatedOn: $createdDateFrom
            ToCreatedOn: $createdDateTo
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_Line_Id
              planConfigManagement_Line_AbbreviationArabic
              planConfigManagement_Line_Status
              planConfigManagement_Line_ExternalCode
              planConfigManagement_Line_Name
              planConfigManagement_Line_NameArabic
              planConfigManagement_Line_Abbreviation
              planConfigManagement_Line_createdOn
              planConfigManagement_Line_createdBy
              planConfigManagement_Line_modifiedOn
              planConfigManagement_Line_modifiedBy
            }
          }
        }
      }
      PlanConfigManagement_LineStatuses: __type(
        name: "PlanConfigManagement_LineStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
