import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty, REGEX } from "../../utils/validationUtils";

export const HEADQUARTER = "Headquarter";

export const initialValues = {
  agentId: "",
  agencyName: "",
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  ufaCode: "",
  mobileNumber: "",
  type: "EXTERNAL",
  agentAccess: "",
  status: "INACTIVE",
  linesOfBusiness: [] as string[],
  products: [] as string[],
  eligiblePlans: [] as string[],
  agentICCNumber: "",
  agentRegistrationNumber: "",
  agentRegistrationExpiryDate: "",
  agentAddress: "",
  canViewCommission: false,
};

export const getInputs = (
  values: typeof initialValues = initialValues,
  lovs: Record<string, Record<string, any>> = {},
  isHeadQuater: boolean
): Record<string, DynamicFormInputType> => ({
  agencyName: {
    name: "agencyName",
    title: "Business Partner",
    type: FormInputTypes.chips,
    placeholder: "Business Partner",
    value: values.agencyName || [],
    selectOptions: lovs.agencyName || {},
    required: true,
    multiple: true,
    order: 1,
    showSelectAll: true,
    controlled: true,
  },
  firstName: {
    name: "firstName",
    title: "First Name",
    placeholder: "First Name",
    type: FormInputTypes.text,
    value: values.firstName || "",
    required: true,
    order: 2,
  },
  lastName: {
    name: "lastName",
    title: "Last Name",
    placeholder: "Last Name",
    type: FormInputTypes.text,
    value: values.lastName || "",
    required: true,
    order: 3,
  },
  email: {
    name: "email",
    title: "Email",
    placeholder: "Email",
    type: FormInputTypes.text,
    value: values.email || "",
    disabled: !isEmpty(values.agentId),
    required: true,
    regex: REGEX.email,
    regexError: "Invalid Email",
    order: 4,
  },
  agentPassword: {
    name: "agentPassword",
    title: "Password",
    placeholder: "Password",
    type: FormInputTypes.password,
    value: values?.password || "",
    includePasswordHint: true,
    includePasswordVisibility: true,
    includeCapsLockCheck: false,
    regex: REGEX.password,
    hidden: !isEmpty(values.agentId),
    order: 5,
  },
  ufaCode: {
    name: "ufaCode",
    title: "UFA Code",
    placeholder: "UFA Code",
    type: FormInputTypes.text,
    value: values.ufaCode || "",
    required: true,
    order: 6,
  },
  mobileNumber: {
    name: "mobileNumber",
    title: "Mobile Number",
    placeholder: "Mobile Number",
    type: FormInputTypes.phoneNumber,
    value: values.mobileNumber || "",
    required: true,
    editCountryCode: false,
    defaultCountry: "lb",
    disableDropDown: true,
    customFormat: { lb: ".. ......" },
    order: 7,
  },
  agentAddress: {
    name: "agentAddress",
    title: "Address",
    placeholder: "Address",
    type: FormInputTypes.text,
    value: values.agentAddress || "",
    required: true,
    order: 8,
  },
  agentType: {
    name: "agentType",
    title: "Type",
    placeholder: "Type",
    type: FormInputTypes.chips,
    value: values.type || [],
    required: true,
    multiple: false,
    order: 9,
    selectOptions: lovs.agentType || {},
  },
  agentAccess: {
    name: "agentAccess",
    title: "Access",
    placeholder: "Access",
    type: FormInputTypes.chips,
    value: values.agentAccess || [],
    required: true,
    multiple: true,
    order: 10,
    selectOptions: lovs.agentAccess || {},
  },
  linesOfBusiness: {
    name: "linesOfBusiness",
    title: "Line Of Business",
    placeholder: "Line Of Business",
    type: FormInputTypes.chips,
    value: values.linesOfBusiness || [],
    required: true,
    multiple: true,
    order: 11,
    selectOptions: lovs.linesOfBusiness || {},
  },
  products: {
    name: "products",
    title: "Products",
    placeholder: "Products",
    type: FormInputTypes.chips,
    value: values.products || [],
    required: true,
    multiple: true,
    order: 12,
    selectOptions: lovs.products || {},
  },
  eligiblePlans: {
    name: "eligiblePlans",
    title: "Plans",
    placeholder: "Plans",
    type: FormInputTypes.chips,
    value: values.eligiblePlans || [],
    required: Object.keys(lovs?.eligiblePlans || {}).length > 0,
    multiple: true,
    showSelectAll: true,
    strongStyledOption: true,
    order: 13,
    selectOptions: lovs.eligiblePlans || {},
  },
  agentStatus: {
    name: "agentStatus",
    title: "Status",
    placeholder: "Status",
    type: FormInputTypes.chips,
    value: values.status || [],
    required: true,
    multiple: false,
    order: 14,
    selectOptions: lovs.agentStatus || {},
  },
  agentICCNumber: {
    name: "agentICCNumber",
    title: "ICC Number",
    placeholder: "ICC Number",
    type: FormInputTypes.text,
    value: values.agentICCNumber || "",
    required: false,
    hidden: isHeadQuater,
    order: 15,
  },
  agentRegistrationNumber: {
    name: "agentRegistrationNumber",
    title: "Registration Number",
    placeholder: "Registration Number",
    type: FormInputTypes.text,
    value: values.agentRegistrationNumber || "",
    required: false,
    hidden: isHeadQuater,
    order: 16,
  },
  agentRegistrationExpiryDate: {
    name: "agentRegistrationExpiryDate",
    title: "Registration Expiry Date",
    placeholder: "Registration Expiry Date",
    type: FormInputTypes.date,
    value: values.agentRegistrationExpiryDate || "",
    required: false,
    hidden: isHeadQuater,
    order: 17,
  },
  canViewCommission: {
    name: "canViewCommission",
    title: "View Commission",
    placeholder: "View Commission",
    type: FormInputTypes.switch,
    value: values.canViewCommission || false,
    required: true,
    order: 18,
  },
});
