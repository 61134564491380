import React, { useEffect, useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { filterQuery, listQuery } from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { filterSectionsContent, getHeaders } from "./content";
import _ from "lodash";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { IListingFilterWidgetSection } from "../../../../components/widgets/custom-listing-filter";
import { getFilter, setFilter } from "../../../../utils/filter-utils";
import { IAbstractRecord } from "../../../../models";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../../../constants";
import { capitalizeFirstCharacter } from "../../../../utils/formatting-utils";
import ToastErrorMessage from "../../../../components/ToastErrorMessage";
import { ITableOrder, TableSortOrder } from "../../../../utils/table-utils";
import ListingFilterWidget from "../../../../components/widgets/custom-listing-filter/ListingFilterWidget";
import StaticLayout from "../../../../page-layout/static-layout/StaticLayout";
import EnhancedTable from "../../../../components/enhanced-table/EnhancedTable";
import { IListingData } from "../../../../models/listing";
import Loader from "../../../../components/Loader";

interface IInsuredPage { }

const InsuredPage: React.FC<IInsuredPage> = () => {
  const navigate = useNavigate();
  const headers = getHeaders(navigate);

  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const FILTER_SESSION_KEY = "insuredFilter";

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
        businessPartner: [],
        createdOn: [],
      };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableOrder, setTableOrder] = useState<Record<string, ITableOrder>>({
    insured: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getInsuredLazy] = useLazyQuery(listQuery());
  const [filterQueryLazy] = useLazyQuery(filterQuery());

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    orders = tableOrder,
    filterV = filterValues
  ) => {
    setLoading(true);
    try {

      const filterResult = await filterQueryLazy({ fetchPolicy: "no-cache" });

      const insuredResult = await
        getInsuredLazy({
          variables: {
            currentPage: currentPage + 1,
            currentPageSize: pageSize,
            selectedBusinessPartners:
              filterV?.businessPartner && filterV?.businessPartner?.length > 0
                ? filterV?.businessPartner
                : [],
            FromCreatedOn: filterV?.createdOn?.[0]
              ? dayjs(filterV?.createdOn?.[0]).format(
                SEND_TO_BACKEND_DATE_FORMAT
              )
              : null,
            ToCreatedOn: filterV?.createdOn?.[1]
              ? dayjs(filterV?.createdOn?.[1]).format(
                SEND_TO_BACKEND_DATE_FORMAT
              )
              : null,
            Attribute: capitalizeFirstCharacter(
              orders?.insured?.orderBy || "Production_Insured_FullName"
            ),
            Descending: orders?.insured?.orderDirection !== "asc",
          },
          errorPolicy: "all",
          fetchPolicy: "no-cache",
        });

      const lovs = dataToLovs(filterResult.data);


      if (filterResult.error || insuredResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }


      const mappedInsuredList = await mapToListingData(
        insuredResult.data,
        filterV?.businessPartner?.length > 0 ? filterV?.businessPartner : Object.keys(lovs?.businessPartner || [])
      );
      setTableData({ ...mappedInsuredList, pageNumber: currentPage });

      const newFilterSections = filterSectionsContent(lovs, filterV);
      setFilterValues(filterV);
      setFilter(filterV, FILTER_SESSION_KEY);
      setFilterSections(newFilterSections);
    } catch (error) {
      console.error("Error loading data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    const savedFilters = { ...filterValues };
    await loadData(0, tableData.pageSize, tableOrder, savedFilters);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSortChange = (orderBy: string, order: string) => {
    const newOrder = _.cloneDeep(tableOrder);
    newOrder.insured.orderBy = orderBy;
    newOrder.insured.orderDirection = order as any;
    setTableOrder(newOrder);
    handlePageChange(tableData.pageNumber, tableData.pageSize, newOrder);
  };

  const handlePageChange = async (
    page: number,
    pageSize: number = tableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrder,
    filterV = filterValues
  ) => {
    await loadData(page, pageSize, orders, filterV);
  };

  const onFilterUpdate = async (newFilters: Record<string, any>) => {
    const updatedFilters = _.cloneDeep(newFilters);

    if (_.isEqual(updatedFilters, filterValues)) {
      // Do nothing if filters are the same
      return;
    }

    setFilterValues(updatedFilters);
    setFilter(updatedFilters, FILTER_SESSION_KEY);
    await handlePageChange(0, tableData.pageSize, tableOrder, updatedFilters);
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: "20px" }}>
      <EnhancedTable
        title="Insured"
        name="count"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={(page: number) =>
          handlePageChange(page, tableData.pageSize)
        }
        handleSort={handleSortChange}
        handleRowsPerPageChange={(pageSize: number) =>
          handlePageChange(0, pageSize)
        }
        currentPage={tableData.pageNumber}
        hideToolbar={false}
        usePagination
        disableSelection
        loader={loading}
        ordering={tableOrder.insured.orderDirection as TableSortOrder}
        orderingBy={tableOrder.insured.orderBy}
      />
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
        disabled={loading}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      loading={!booted}
      name="Applications"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default InsuredPage;
