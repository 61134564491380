import Tooltip from "@mui/material/Tooltip";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { IListingFilterWidgetSection } from ".";
import { DynamicFormInputType, FormInputTypes } from "../../../DynamicForm";
import {
  MAIN_ONE_THEME,
  contentFontFamilyBold,
  contentFontFamilyRegular,
  mainFontFamilyRegular,
} from "../../../constants";
import { useAppSelector } from "../../../redux/hooks";
import EnhancedChipInput from "../../enhanced-form/EnhancedChipInput";
import EnhancedDatePicker from "../../enhanced-form/EnhancedDatePicker";
import EnhancedDateRangePicker from "../../enhanced-form/EnhancedDateRangePicker";

export interface IListingFilterWidgetProps {
  name: string;
  filters: IListingFilterWidgetSection[];
  onApplyFilter: (values: Record<string, any>) => void;
  onClearFilter?: () => void;
  disabled?: boolean;
}

const useStyles = makeStyles()(() => ({
  title: {
    display: "block",
    textAlign: "left",
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    fontFamily: mainFontFamilyRegular,
    letterSpacing: 0,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontWeight: "normal",
    marginLeft: "5px",
  },
  container: {
    padding: "30px 20px 20px;",
    display: "flex",
    marginTop: "20px",
  },
  sectionTitle: {
    fontSize: MAIN_ONE_THEME.typography.medium.med2.fontSize,
    color: MAIN_ONE_THEME.palette.primary1.main,
    fontFamily: contentFontFamilyBold,
    textAlign: "left",
    display: "block",
    width: "100%",
    borderColor: "red",
  },
  sectionMessage: {
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    padding: "5px 0 10px",
    color: "grey",
    fontStyle: "italic",
    fontFamily: contentFontFamilyRegular,
    textAlign: "left",
    display: "block",
    width: "100%",
  },
  clearFilterBtn: {
    display: "flex",
    justifyContent: "center",
  },
  sectionTitleContainer: {
    color: "red",
    backgroundColor: "red",
  },
  clearFilterIconButton: {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
  },
}));

const InlineFilterWidget: React.FC<IListingFilterWidgetProps> = ({
  filters,
  disabled,
  onClearFilter,
  onApplyFilter,
}) => {
  const tenant = useAppSelector((state) => state.tenant);
  const [values, setValues] = useState<Record<string, any>>();
  const [touched, setTouched] = useState<Record<string, boolean>>();
  // const [focused, setFocused] = useState<Record<string, boolean>>({});

  const { classes } = useStyles();

  useEffect(() => {
    let seperateInputs = filters.map((i) => i.inputs);
    let inputs: Record<string, DynamicFormInputType> = {};

    seperateInputs.forEach((input) => {
      inputs = { ...inputs, ...input };
    });
    const initialValues: Record<string, any> = {};
    const initialTouched: Record<string, boolean> = {};
    const initialFocused: Record<string, boolean> = {};
    Object.values(inputs).forEach((input) => {
      initialValues[input.name] = input.value;
      initialTouched[input.name] = !!input.error;
      initialFocused[input.name] = false;
    });
    setValues(initialValues);
    setTouched(initialTouched);
    // setFocused(initialFocused);
  }, [filters]);

  const onFieldBlur = (name: string, extraValue?: string) => {
    let touchedTemp = Object.assign({}, touched);
    touchedTemp[name] = true;
    setTouched(touchedTemp);

    let seperateInputs = filters.map((i) => i.inputs);
    let inputs: Record<string, DynamicFormInputType> = {};

    seperateInputs.forEach((input) => {
      inputs = { ...inputs, ...input };
    });

    switch (inputs[name].type) {
      case FormInputTypes.multiSelect:
      case FormInputTypes.chips:
        if (extraValue) {
          let valuesTemp = Object.assign({}, values);
          valuesTemp[name].push(extraValue);
          setValues(valuesTemp);
        }
      case FormInputTypes.text:
      case FormInputTypes.phoneNumber:
      case FormInputTypes.number:
        onApplyFilter(values);
    }
  };

  // const onFieldFocus = (name: string) => {
  //   let focusedTemp = Object.assign({}, focused);
  //   focusedTemp[name] = true;
  //   setFocused(focusedTemp);
  // };

  const onFieldChange = (name: string, value: any) => {
    let seperateInputs = filters.map((i) => i.inputs);
    let inputs: Record<string, DynamicFormInputType> = {};

    seperateInputs.forEach((input) => {
      inputs = { ...inputs, ...input };
    });

    let valuesTemp = Object.assign({}, values);
    valuesTemp[name] = value;
    setValues(valuesTemp);

    switch (inputs[name].type) {
      case FormInputTypes.select:
      case FormInputTypes.date:
      case FormInputTypes.switch:
      case FormInputTypes.daterange:
        applyFilter(valuesTemp);
    }
  };

  const applyFilter = (v: Record<string, any>) => {
    let seperateInputs = filters.map((i) => i.inputs);
    let inputs: Record<string, DynamicFormInputType> = {};

    seperateInputs.forEach((input) => {
      inputs = { ...inputs, ...input };
    });
    const initialTouched: Record<string, boolean> = {};
    Object.values(inputs).forEach((input) => {
      initialTouched[input.name] = !!input.error;
    });
    setTouched(initialTouched);

    onApplyFilter(v);
  };

  // const onFieldChangeByEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   onFieldChange(event.target.name, event.target.value);
  // };

  const renderInput = (input: DynamicFormInputType) => {
    switch (input.type) {
      case FormInputTypes.date:
        return (
          <EnhancedDatePicker
            key={input.name}
            name={input.name}
            title={input.title}
            value={values[input.name]}
            onBlur={() => onFieldBlur(input.name)}
            onDateChange={(v) => onFieldChange(input.name, v)}
            disabled={input.disabled || disabled}
            material={input.material}
            minDate={input.minDate}
            maxDate={input.maxDate}
            format={tenant.dateFormat}
          />
        );
      case FormInputTypes.daterange:
        return (
          <EnhancedDateRangePicker
            key={input.name}
            name={input.name}
            title={input.title}
            value={values[input.name]}
            // onBlur={() => onFieldBlur(input.name)}
            onDateRangeChange={(v) => onFieldChange(input.name, v)}
            disabled={input.disabled || disabled}
            // material={input.material}
            minDate={input.minDate}
            maxDate={input.maxDate}
          />
        );
      case FormInputTypes.chips:
        return (
          <EnhancedChipInput
            key={input.name}
            name={input.name}
            title={input.title}
            placeholder={input.placeholder}
            value={values[input.name]}
            onChange={(v) => onFieldChange(input.name, v)}
            onBlur={(extraValue) => onFieldBlur(input.name, extraValue)}
            disabled={input.disabled || disabled}
            selectOptions={input.selectOptions}
            required={input.required}
            freeSolo={input.freeSolo}
            error=""
            onBlurSaveNewData={input.onBlurSaveNewData}
            material={true}
            limitTags={2}
            multiple={input.multiple}
            canClearSingleValueSelection={input.canClearSingleValueSelection}
            hasSpecificStyle={true}
            className={classes.sectionTitleContainer}
            isInlineButton={true}
            inline={true}
            style={{ width: "20%", marginTop: "30px" }}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <div className={classes.container}>
      {/* {onClearFilter && (
        <div className={classes.clearFilterBtn}>
          <EnhancedButton
            color="#fff"
            onClick={() => onClearFilter()}
            backgroundColor="#353535"
          >
            Clear Filter
          </EnhancedButton>
        </div>
      )} */}

      {values &&
        touched &&
        filters.map((filter) => {
          return (
            <>
              {/* <div className={classes.sectionTitleContainer}> */}
              {filter.clearAbility && (
                <Tooltip title={"Clear filter"}>
                  <button
                    className={classes.clearFilterIconButton}
                    onClick={() => onClearFilter()}
                  >
                    <svg
                      style={{ width: "24px", height: "24px" }}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#353535"
                        d="M14.73,20.83L17.58,18L14.73,15.17L16.15,13.76L19,16.57L21.8,13.76L23.22,15.17L20.41,18L23.22,20.83L21.8,22.24L19,19.4L16.15,22.24L14.73,20.83M13,19.88C13.04,20.18 12.94,20.5 12.71,20.71C12.32,21.1 11.69,21.1 11.3,20.71L7.29,16.7C7.06,16.47 6.96,16.16 7,15.87V10.75L2.21,4.62C1.87,4.19 1.95,3.56 2.38,3.22C2.57,3.08 2.78,3 3,3V3H17V3C17.22,3 17.43,3.08 17.62,3.22C18.05,3.56 18.13,4.19 17.79,4.62L13,10.75V19.88M5.04,5L9,10.06V15.58L11,17.58V10.05L14.96,5H5.04Z"
                      />
                    </svg>
                  </button>
                </Tooltip>
              )}
              {/* </div> */}
              {Object.values(filter.inputs).map((input) => renderInput(input))}
            </>
          );
        })}
    </div>
  );
};

export default InlineFilterWidget;
