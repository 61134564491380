import { gql } from "@apollo/client";

export function listQuery() {
  return gql`
    query getTaxationList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedLines: [String]
      $selectedDate: Date
      $createdDateFrom: Date
      $createdDateTo: Date
      $isSelectedDate: Boolean!
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      Configuration {
        queries {
          getTaxationList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            SelectedLines: $selectedLines
            SelectedDate: $selectedDate
            FromCreatedOn: $createdDateFrom
            ToCreatedOn: $createdDateTo
            isSelectedDate: $isSelectedDate
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              configuration_Taxation_Id
              line_Id
              line_Name
              line_ExternalCode
              configuration_Taxation_FixedStamp
              configuration_Taxation_ProportionalStamp
              configuration_Taxation_MunicipalityTax
              configuration_Taxation_TaxOnCommission
              configuration_Taxation_ReinsuranceTax
              configuration_Taxation_EffectiveFrom
              configuration_Taxation_EffectiveTo
              configuration_Taxation_createdOn
              configuration_Taxation_modifiedOn
              configuration_Taxation_IsLatest
              configuration_Taxation_FixedStampCurrency {
                Code
                Title
              }
            }
          }
        }
      }
    }
  `;
}

export function filterQuery() {
  return gql`
    query TAXATIONS_LISTING_LOVS {
      PlanConfigManagement {
        queries {
          allLines {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
            planConfigManagement_Line_Status
          }
        }
      }
    }
  `;
}
