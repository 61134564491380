import { gql } from "@apollo/client";

export function closeProposal() {
  return gql`
    mutation closeProposal($entityId: String!, $closingReason: String!) {
      production {
        entities {
          proposal {
            production {
              closeProposal(
                entityId: $entityId
                closingReason: $closingReason
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}
