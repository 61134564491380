import { IPaymentTermsPopupFormState } from ".";
import {
	EnhancedHeaderInputTypes,
	EnhancedTableHeader,
	EnhancedTableHeaderType,
} from "../../components/enhanced-table";

export const paymentTermsHeaders: (
	pageState: IPaymentTermsPopupFormState
) => Record<string, EnhancedTableHeader> = (pageState) => {
	const values = pageState.values;
	const customPaymentType = values.paymentType === "CUSTOM";

	return {
		billNumber: {
			name: "billNumber",
			title: "Bill #",
			type: EnhancedTableHeaderType.Text,
		},
		...(customPaymentType
			? {
					dueDate: {
						name: "dueDate",
						title: "Due Date",
						type: EnhancedTableHeaderType.Input,
						inputType: EnhancedHeaderInputTypes.String,
						disabled: true,
					},
			  }
			: {
					dueDate: {
						name: "dueDate",
						title: "Due Date",
						type: EnhancedTableHeaderType.Date,
					},
			  }),
		totalPremium: {
			name: "totalPremium",
			title: "Total Premium",
			type: EnhancedTableHeaderType.Currency,
		},
		totalCommission: {
			name: "totalCommission",
			title: "Total Commission",
			type: EnhancedTableHeaderType.Currency,
		},
		...(customPaymentType
			? {
					amountDue: {
						name: "amountDue",
						title: "Amount Due",
						type: EnhancedTableHeaderType.Input,
						inputType: EnhancedHeaderInputTypes.String,
						error: pageState.errors.amountDue,
					},
			  }
			: {
					amountDue: {
						name: "amountDue",
						title: "Amount Due",
						type: EnhancedTableHeaderType.Currency,
					},
			  }),
		outstandingAmount: {
			name: "outstandingAmount",
			title: "Outstanding Amount",
			type: EnhancedTableHeaderType.Currency,
		},
		status: {
			name: "status",
			title: "Status",
			type: EnhancedTableHeaderType.Text,
		},
	};
};
