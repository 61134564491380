import { gql } from "@apollo/client";

export function getPlanDetailsEnums() {
  return gql`
    query getPlanDetailsEnums {
      Core_SOSServiceOptions: __type(name: "Core_SOSServiceOptions") {
        name
        enumValues {
          name
        }
      }
      PlanConfigManagement_ScratchingOptions: __type(
        name: "PlanConfigManagement_ScratchingOptions"
      ) {
        name
        enumValues {
          name
        }
      }
      Core {
        lookups {
          currencies {
            Id
            Title
          }
          acalCategories {
            Id
            Title
          }
        }
      }
    }
  `;
}

export function updatePlanAndAdditionalDetails() {
  return gql`
    mutation updatePlanDetails(
      $entityId: String!
      $planDetailInputs: PlanConfigManagement_PlanDetailInputs!
      $planAdditionalDetailInputs: PlanConfigManagement_PlanAdditionalDetailInputs!
    ) {
      planConfigManagement {
        entities {
          plan {
            planConfigManagement {
              updatePlanAndAdditionalDetails(
                entityId: $entityId
                planDetailInputs: $planDetailInputs
                planAdditionalDetailInputs: $planAdditionalDetailInputs
              ) {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                      NameArabic
                      NameFrench
                      Abbreviation
                      EffectiveFrom
                      EffectiveTo
                      PlanStatus
                      SublineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                              __typename
                            }
                            __typename
                          }
                          __typename
                        }
                        __typename
                      }
                      LineID {
                        id
                        views {
                          PlanConfigManagement_all {
                            properties {
                              Name
                              ExternalCode
                              __typename
                            }
                            __typename
                          }
                          __typename
                        }
                        __typename
                      }
                      createdOn
                      createdBy
                      modifiedOn
                      modifiedBy
                      __typename
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
    }
  `;
}
