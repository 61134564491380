import IReceivablesPayablesTableData, {
	IReceivablesPayablesResponseData,
} from ".";
import { IListingData } from "../../models/listing";
import { lookupListAsRecordObject } from "../../utils/graph-utils";

export function mapToListingData(data: any): IListingData<any> {
	let list: Record<string, IReceivablesPayablesTableData> = {};
	data.Accounting.queries.PolicyBusinessPartnerList?.items?.forEach(
		(obj: IReceivablesPayablesResponseData, i: number) => {
			const businessPartnerId = obj.salesforceManagement_BusinessPartner_Id;

			if (list[businessPartnerId]) {
				list[businessPartnerId].totalPoliciesIssued += 1;
				list[businessPartnerId].totalBalanceBasicCurrency +=
					obj.receivableBills_OutstandingBalanceBasicSystemCurrency -
					obj.payableBills_OutstandingBalanceBasicSystemCurrency;
				list[businessPartnerId].totalBalanceSecondaryCurrency +=
					obj.receivableBills_OutstandingBalanceSecondarySystemCurrency -
					obj.payableBills_OutstandingBalanceSecondarySystemCurrency;
			} else {
				list[businessPartnerId] = {
					businessPartnerId: businessPartnerId,
					businessPartnerName:
						obj.salesforceManagement_BusinessPartner_FullName,
					totalPoliciesIssued: 1,
					totalBalanceBasicCurrency:
						obj.receivableBills_OutstandingBalanceBasicSystemCurrency -
						obj.payableBills_OutstandingBalanceBasicSystemCurrency,
					totalBalanceSecondaryCurrency:
						obj.receivableBills_OutstandingBalanceSecondarySystemCurrency -
						obj.payableBills_OutstandingBalanceSecondarySystemCurrency,
				};
			}
		}
	);

	return {
		pagedItems: Object.values(list),
		pageSize:
			data.Accounting.queries.PolicyBusinessPartnerList?.paging.pageSize,
		pageNumber:
			data.Accounting.queries.PolicyBusinessPartnerList?.paging.pageNumber,
		totalCount:
			data.Accounting.queries.PolicyBusinessPartnerList?.paging.totalCount,
	};
}

export function toLookupsData(
	companiesData: Record<string, any>,
	businessPartnerData: Record<string, any>
) {
	let lovs: Record<string, Record<string, string>> = {
		company: {},
		businessPartner: {},
	};

	lovs.company = lookupListAsRecordObject(
		companiesData?.SalesforceManagement?.lookups?.relatedCompanies
	);

	businessPartnerData?.SalesforceManagement?.queries?.GetAllBusinessPartners?.items?.forEach(
		(element: any) => {
			if (element.salesforceManagement_BusinessPartner_Id) {
				lovs.businessPartner[element.salesforceManagement_BusinessPartner_Id] =
					element.salesforceManagement_BusinessPartner_FullName;
			}
		}
	);

	return lovs;
}
