import { FormInputTypes, IDynamicSection } from "../../DynamicForm";
import { REGEX } from "../../utils/validationUtils";

export const initialValues = {
  personId: "",
  type: "",
  companyName: "",
  title: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateOfBirth: "",
  email: "",
  phoneType: "MOBILE",
  phoneNumber: "",
  nationalityOne: "",
  isTaxable: true,
  occupationClass: "",
  profession: "",
  employer: "",
  dateOfEmployment: "",
  addressType: "",
  country: "",
  zone: "",
  city: "",
  street: "",
  building: "",
  addressDetails: "",
  addressDetailsArabic: "",
  isPrimary: true,
};

export const getSections = (
  data: Record<string, any>,
  lists: Record<string, Record<string, string>>,
  section: string,
  lineExternalCode: string
): Record<string, IDynamicSection> => {
  const isInsured = section === "insured";
  const isLineExpat = lineExternalCode === "43";
  const isPerson = data?.type === "PERSON";

  return {
    section1: {
      title: "General Details",
      inputs: {
        type: {
          name: "type",
          title: "Type",
          type: FormInputTypes.chips,
          placeholder: "Type",
          value: data?.type,
          multiple: false,
          disabled: false,
          required: true,
          selectOptions: lists.SalesforceManagement_PersonTypes,
        },
        companyName: {
          name: "companyName",
          title: "Company Name",
          placeholder: "Company Name",
          type: FormInputTypes.text,
          value: data?.companyName,
          required: data?.type === "COMPANY",
          disabled: data?.type != "COMPANY",
          hidden: data?.type != "COMPANY",
          minCharacters: 2,
          maxCharacters: 300,
        },
        title: {
          name: "title",
          title: "Title",
          placeholder: "Title",
          type: FormInputTypes.chips,
          value: data?.title,
          multiple: false,
          required: data?.type === "PERSON",
          disabled: data?.type != "PERSON",
          hidden: data?.type != "PERSON",
          selectOptions: lists.titles,
        },
        firstName: {
          name: "firstName",
          title: "First Name",
          placeholder: "First Name",
          type: FormInputTypes.text,
          value: data?.firstName,
          required: data?.type === "PERSON",
          disabled: data?.type != "PERSON",
          hidden: data?.type != "PERSON",
          minCharacters: 2,
          maxCharacters: 100,
        },
        middleName: {
          name: "middleName",
          title: "Middle Name",
          placeholder: "Middle Name",
          type: FormInputTypes.text,
          value: data?.middleName,
          required: data?.type === "PERSON",
          disabled: data?.type != "PERSON",
          hidden: data?.type != "PERSON",
          minCharacters: 1,
          maxCharacters: 100,
        },
        lastName: {
          name: "lastName",
          title: "Last Name",
          placeholder: "Last Name",
          type: FormInputTypes.text,
          value: data?.lastName,
          required: data?.type === "PERSON",
          disabled: data?.type != "PERSON",
          hidden: data?.type != "PERSON",
          minCharacters: 2,
          maxCharacters: 100,
        },
        dateOfBirth: {
          name: "dateOfBirth",
          title: "Date of Birth",
          placeholder: "Date of Birth",
          type: FormInputTypes.date,
          value: data?.dateOfBirth,
          disabled: data?.type != "PERSON",
          hidden: data?.type != "PERSON",
          maxDate: new Date(),
          required: isInsured && isLineExpat,
        },
        gender: {
          name: "gender",
          title: "Gender",
          type: FormInputTypes.chips,
          placeholder: "Gender",
          value: data?.gender,
          multiple: false,
          hidden: !isPerson,
          disabled: false,
          required: isInsured && isLineExpat,
          selectOptions: lists?.genders || {},
        },
        email: {
          name: "email",
          title: "Email",
          type: FormInputTypes.text,
          placeholder: "Email",
          value: data?.email,
          regex: REGEX.email,
          regexError: "Invalid Email",
          required: false,
          disabled: false,
        },
        phoneType: {
          name: "phoneType",
          title: "Phone Type",
          type: FormInputTypes.chips,
          placeholder: "Phone Type",
          value: data?.phoneType,
          required: false,
          multiple: false,
          selectOptions: lists.phoneType,
        },
        phoneNumber: {
          name: "phoneNumber",
          title: "Phone Number",
          type: FormInputTypes.phoneNumber,
          placeholder: "Phone Number",
          value: data?.phoneNumber,
          required: false,
          editCountryCode: false,
        },
        nationalityOne: {
          name: "nationalityOne",
          title: "Nationality",
          placeholder: "Nationality",
          type: FormInputTypes.chips,
          value: data?.nationalityOne,
          required: isInsured && isLineExpat,
          disabled: data?.type != "PERSON",
          hidden: data?.type != "PERSON",
          multiple: false,
          selectOptions: lists.nationalities,
        },
      },
    },
    section2: {
      title: "Business Details",
      inputs: {
        taxable: {
          name: "taxable",
          title: "Taxable",
          placeholder: "Taxable",
          type: FormInputTypes.switch,
          value: data?.isTaxable,
          required: isInsured && isLineExpat,
        },
        occupationClass: {
          name: "occupationClass",
          title: "Occupation Class",
          placeholder: "Occupation Class",
          type: FormInputTypes.chips,
          value: data?.occupationClass,
          multiple: false,
          required:
            isInsured &&
            ["43", "5", "9", "13", "21", "38"].includes(lineExternalCode),
          disabled: data?.type != "PERSON",
          hidden: data?.type != "PERSON",
          selectOptions: lists.occupationClasses,
        },
        profession: {
          name: "profession",
          title: "Profession",
          placeholder: "Profession",
          type: FormInputTypes.text,
          value: data?.profession,
          required:
            isInsured &&
            ["43", "5", "9", "13", "21", "38"].includes(lineExternalCode),
          disabled: data?.type != "PERSON",
          hidden: data?.type != "PERSON",
          minCharacters: 2,
          maxCharacters: 100,
        },
        employer: {
          name: "employer",
          title: "Employer / Institute",
          placeholder: "Employer / Institute",
          type: FormInputTypes.text,
          value: data?.employer,
          required: false,
          disabled: data?.type != "PERSON",
          hidden: data?.type != "PERSON",
          minCharacters: 2,
          maxCharacters: 100,
        },
        dateOfEmployment: {
          name: "dateOfEmployment",
          title: "Date of Employment",
          placeholder: "Date of Employment",
          type: FormInputTypes.date,
          value: data?.dateOfEmployment,
          required: false,
          disabled: data?.type != "PERSON",
          hidden: data?.type != "PERSON",
        },
      },
    },
  };
};
