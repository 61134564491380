import { IPersonDetailsDrawerInfo } from ".";
import { FormInputTypes, IDynamicSection } from "../../DynamicForm";
import { REGEX } from "../../utils/validationUtils";

export const initialValues: IPersonDetailsDrawerInfo = {
  type: "",
  companyName: "",
  title: "",
  titleId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  surname: "",
  initials: "",
  dateOfBirth: "",
  nationality: "",
  nationalityId: "",
  email: "",
  phoneType: "",
  phoneNumber: "",
  relativeOf: "",
  smoker: "NO",
  weight: 0,
  height: 0,
  dateOfDeath: "",
  dateOfDeathNotified: "",
  taxable: false,
  financialReference: "",
  occupationClass: "",
  occupationClassCode: "",
  profession: "",
  employer: "",
  dateOfEmployment: "",
  salary: 0,
  dateOfSelfCertificateReceived: "",
  dateOfSelfCertificateValidated: "",
  category: "",
  dateOfKYC: "",
  comments: "",
};

export const getSections = (
  data: IPersonDetailsDrawerInfo,
  lists: Record<string, Record<string, string>>
): Record<string, IDynamicSection> => {

  const isCompany = data?.type?.toUpperCase() === "COMPANY";
  const isPerson = data?.type?.toUpperCase() === "PERSON";
  
  return {
    section1: {
      title: "General Details",
      inputs: {
        type: {
          name: "type",
          title: "Type",
          type: FormInputTypes.chips,
          placeholder: "Type",
          value: data?.type,
          multiple: false,
          disabled: false,
          required: true,
          selectOptions: lists.SalesforceManagement_PersonTypes,
        },
        companyName: {
          name: "companyName",
          title: "Company Name",
          placeholder: "Company Name",
          type: FormInputTypes.text,
          value: data?.companyName,
          required: isCompany,
          disabled: !isCompany,
          hidden: !isCompany,
          minCharacters: 2,
          maxCharacters: 300,
        },
        title: {
          name: "title",
          title: "Title",
          placeholder: "Title",
          type: FormInputTypes.chips,
          value: data?.titleId || data?.title,
          multiple: false,
          required: isPerson,
          disabled: !isPerson,
          hidden: !isPerson,
          selectOptions: lists.titles,
        },
        firstName: {
          name: "firstName",
          title: "First Name",
          placeholder: "First Name",
          type: FormInputTypes.text,
          value: data?.firstName,
          required: isPerson,
          disabled: !isPerson,
          hidden: !isPerson,
          minCharacters: 2,
          maxCharacters: 100,
        },
        middleName: {
          name: "middleName",
          title: "Middle Name",
          placeholder: "Middle Name",
          type: FormInputTypes.text,
          value: data?.middleName,
          required: isPerson,
          disabled: !isPerson,
          hidden: !isPerson,
          minCharacters: 1,
          maxCharacters: 100,
        },
        lastName: {
          name: "lastName",
          title: "Last Name",
          placeholder: "Last Name",
          type: FormInputTypes.text,
          value: data?.lastName,
          required: isPerson,
          disabled: !isPerson,
          hidden: !isPerson,
          minCharacters: 2,
          maxCharacters: 100,
        },
        surname: {
          name: "surname",
          title: "Maiden Name",
          placeholder: "Maiden Name",
          type: FormInputTypes.text,
          value: data?.surname,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
          minCharacters: 2,
          maxCharacters: 100,
        },
        initials: {
          name: "initials",
          title: "Initials",
          placeholder: "Initials",
          type: FormInputTypes.text,
          value: data?.initials,
          required: false,
        },
        dateOfBirth: {
          name: "dateOfBirth",
          title: "Date of Birth",
          placeholder: "Date of Birth",
          type: FormInputTypes.date,
          value: data?.dateOfBirth,
          disabled: !isPerson,
          hidden: !isPerson,
          maxDate: new Date(),
        },
        nationality: {
          name: "nationality",
          title: "Nationality",
          placeholder: "Nationality",
          type: FormInputTypes.chips,
          value: data?.nationalityId || data?.nationality,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
          multiple: false,
          selectOptions: lists.nationalities,
        },
      },
    },
    section2: {
      title: "Contact Details",
      inputs: {
        email: {
          name: "email",
          title: "Email",
          type: FormInputTypes.text,
          placeholder: "Email",
          value: data?.email,
          regex: REGEX.email,
          regexError: "Invalid Email",
          required: false,
          disabled: false,
        },
        phoneType: {
          name: "phoneType",
          title: "Phone Type",
          type: FormInputTypes.chips,
          placeholder: "Phone Type",
          value: data?.phoneType,
          required: false,
          multiple: false,
          selectOptions: lists.phoneType,
        },
        phoneNumber: {
          name: "phoneNumber",
          title: "Phone Number",
          type: FormInputTypes.phoneNumber,
          placeholder: "Phone Number",
          value: data?.phoneNumber,
          required: false,
          editCountryCode: false,
        },
        relativeOf: {
          name: "relativeOf",
          title: "Relative of",
          type: FormInputTypes.text,
          placeholder: "Relative of",
          value: data?.relativeOf,
          required: false,
          minCharacters: 2,
          maxCharacters: 200,
        },
      },
    },
    section3: {
      title: "Health Details",
      inputs: {
        smoker: {
          name: "smoker",
          title: "Smoker",
          type: FormInputTypes.chips,
          placeholder: "Smoker",
          value: data?.smoker,
          multiple: false,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
          selectOptions: lists.isSmoker,
        },
        weight: {
          name: "weight",
          title: "Weight (kg)",
          type: FormInputTypes.number,
          placeholder: "Weight (kg)",
          value: data?.weight,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
          hasBetweenValidation: true,
          minNumber: 0,
          maxNumber: 300,
        },
        height: {
          name: "height",
          title: "Height (cm)",
          type: FormInputTypes.number,
          placeholder: "Height (cm)",
          value: data?.height,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
          hasBetweenValidation: true,
          minNumber: 0,
          maxNumber: 300,
        },
        dateOfDeath: {
          name: "dateOfDeath",
          title: "Date of Death",
          placeholder: "Date of Death",
          type: FormInputTypes.date,
          value: data?.dateOfDeath,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
        },
        dateOfDeathNotified: {
          name: "dateOfDeathNotified",
          title: "Date of Death Notified",
          placeholder: "Date of Death Notified",
          type: FormInputTypes.date,
          value: data?.dateOfDeathNotified,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
        },
      },
    },
    section4: {
      title: "Business Details",
      inputs: {
        taxable: {
          name: "taxable",
          title: "Taxable",
          placeholder: "Taxable",
          type: FormInputTypes.switch,
          value: data?.taxable,
        },
        financialReference: {
          name: "financialReference",
          title: "Financial Reference",
          placeholder: "Financial Reference",
          type: FormInputTypes.text,
          value: data?.financialReference,
        },
        occupationClass: {
          name: "occupationClass",
          title: "Occupation Class",
          placeholder: "Occupation Class",
          type: FormInputTypes.chips,
          value: data?.occupationClassCode || data?.occupationClass,
          multiple: false,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
          selectOptions: lists.occupationClasses,
        },
        profession: {
          name: "profession",
          title: "Profession",
          placeholder: "Profession",
          type: FormInputTypes.text,
          value: data?.profession,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
          minCharacters: 2,
          maxCharacters: 100,
        },
        employer: {
          name: "employer",
          title: "Employer / Institute",
          placeholder: "Employer / Institute",
          type: FormInputTypes.text,
          value: data?.employer,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
          minCharacters: 2,
          maxCharacters: 100,
        },
        dateOfEmployment: {
          name: "dateOfEmployment",
          title: "Date of Employment",
          placeholder: "Date of Employment",
          type: FormInputTypes.date,
          value: data?.dateOfEmployment,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
        },
        salary: {
          name: "salary",
          title: "Salary",
          type: FormInputTypes.currency,
          placeholder: "Salary",
          value: data?.salary,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
        },
        dateOfSelfCertificateReceived: {
          name: "dateOfSelfCertificateReceived",
          title: "Date of Self Certificate Received",
          placeholder: "Date of Self Certificate Received",
          type: FormInputTypes.date,
          value: data?.dateOfSelfCertificateReceived,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
        },
        dateOfSelfCertificateValidated: {
          name: "dateOfSelfCertificateValidated",
          title: "Date of Self Certificate Validated",
          placeholder: "Date of Self Certificate Validated",
          type: FormInputTypes.date,
          value: data?.dateOfSelfCertificateValidated,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
        },
        category: {
          name: "category",
          title: "Category",
          placeholder: "Category",
          type: FormInputTypes.chips,
          value: data?.category,
          multiple: false,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
          selectOptions: lists.personsCategories,
        },
        dateOfKYC: {
          name: "dateOfKYC",
          title: "Date of KYC",
          placeholder: "Date of KYC",
          type: FormInputTypes.date,
          value: data?.dateOfKYC,
          required: false,
          disabled: !isPerson,
          hidden: !isPerson,
        },
        comments: {
          name: "comments",
          title: "Comments",
          placeholder: "Comments",
          type: FormInputTypes.text,
          value: data?.comments,
          multiline: true,
        },
      },
    },
  };
};
