import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
    accounting_Transactions_TransactionNumber: {
    name: 'accounting_Transactions_TransactionNumber',
    title: 'Transaction #',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  accounting_Transactions_ReferenceNumber: {
    name: 'accounting_Transactions_ReferenceNumber',
    title: 'Reference #',
    type: EnhancedTableHeaderType.Text,
  },
  accounting_Transactions_TransactionDate: {
    name: 'accounting_Transactions_TransactionDate',
    title: 'Transaction Date',
    type: EnhancedTableHeaderType.Date,
  },
  accounting_Transactions_Description: {
    name: 'accounting_Transactions_Description',
    title: 'Description',
    type: EnhancedTableHeaderType.Text,
  },
  accounting_Transactions_createdOn: {
    name: 'accounting_Transactions_createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  },
  viewHistory: {
    name: 'viewHistory',
    title: ' ',
    buttonLabel: "View History",
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
};

export function filterSectionsContent(
    lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord,
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        company: {
            name: 'company',
            title: 'Company',
            type: FormInputTypes.chips,
            value: filterValues?.company || null,
            placeholder: 'Company',
            triggerUpdate: true,
            selectOptions: { ...lovs.company },
            multiple: false,
            canClearSingleValueSelection: false
        },
        account: {
            name: 'account',
            title: 'Account',
            type: FormInputTypes.chips,
            value: filterValues?.account || null,
            placeholder: 'Account',
            triggerUpdate: true,
            selectOptions: { ...lovs.account },
            multiple: true,
            canClearSingleValueSelection: true
        },
        createdOn: {
            name: 'createdOn',
            title: 'Created On',
            type: FormInputTypes.daterange,
            value: filterValues.createdOn || [],
            placeholder: 'Created On',
            triggerUpdate: true,
          }
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: 'New Transaction',
    onClick: () => {},
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  }
];
