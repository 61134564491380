import {
  Button,
  ButtonClassKey,
  ButtonProps,
  CircularProgress,
  Fade,
  lighten,
} from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import CheckIcon from '@mui/icons-material/Check';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../constants';

export type EnhancedButtonStatus = 'loading' | 'success';

export interface IEnhancedButtonProps extends Omit<ButtonProps, 'color'> {
  classes?: Partial<Record<ButtonClassKey | 'success' | 'loader', string>>;
  state?: EnhancedButtonStatus;
  color?: string;
  backgroundColor?: string;
  progressSize?: number;
  children?: React.ReactNode;
  hasImage?: boolean;
}

// interface IEnhancedButtonStyleProps {
//   backgroundColor: string;
//   color?: string;
//   state: EnhancedButtonStatus | undefined;
// }

const useStyles = makeStyles<{
  backgroundColor: string;
  color: string;
  state: EnhancedButtonStatus;
  hasImage: boolean;
}>()((theme, { backgroundColor, color, state, hasImage }) => ({
  root: {
    position: 'relative !important' as any,
    borderRadius: '3px !important',
    minHeight: hasImage ? '30px !important' : '32px !important',
    minWidth: hasImage ? '30px !important' : '80px !important',
    border: hasImage ? 0 : `1px ${MAIN_ONE_THEME.palette.secondary3.main} solid !important`,
    boxSizing: 'border-box !important' as any,
    padding: hasImage ? '0px !important' : '0 15px !important',
    backgroundColor: `${backgroundColor} !important`,
    textTransform: 'unset !important' as any,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg3.fontSize} !important`,
    lineHeight: '15px !important',
    textAlign: 'center',
    color: color
      ? `${color} !important`
      : `${MAIN_ONE_THEME.palette.primary1.main} !important`,
    boxShadow: 'none !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: `${lighten(backgroundColor, 0.25)} !important`,
      fontFamily: contentFontFamilyRegular,
      '&:disabled': {
        backgroundColor: `${lighten(backgroundColor, 0.25)} !important`,
      },
    },
    '&:disabled': {
      cursor: 'not-allowed !important',
      opacity: 0.4,
      pointerEvents: 'all !important' as any,
      fontFamily: contentFontFamilyRegular,
    },
    fontFamily: contentFontFamilyRegular,
  },
  buttonProgress: {
    color: color
      ? `${color} !important`
      : `${MAIN_ONE_THEME.palette.primary1.main} !important`,
    position: 'absolute',
    left: 'calc(50% - 10px)',
    top: 'calc(50% - 10px)',
    fontFamily: contentFontFamilyRegular,
  },
  labelProgress: {
    visibility:
      state === 'loading' || state === 'success' ? 'hidden' : 'visible',
  },
  buttonIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: backgroundColor ? `${backgroundColor} !important` : undefined,
    color: color
      ? `${color} !important`
      : `${MAIN_ONE_THEME.palette.primary1.main} !important`,
    width: '100%',
    height: '100%',
  },
}));

const EnhancedButton: React.FC<IEnhancedButtonProps> = ({
  state,
  children,
  classes = {},
  disabled,
  color,
  progressSize = 20,
  backgroundColor = 'rgba(255, 255, 255, 1)',
  hasImage = false,
  ref,
  ...props
}) => {
  // TODO make state internal and change onClick function to return a promise
  const { classes: buttonClasses } = useStyles({
    backgroundColor,
    color,
    state,
    hasImage,
  });
  const { loader, root, text, ...restOfClasses } = classes;

  return (
    <Button
      ref={ref}
      classes={{
        root: clsx(buttonClasses.root, root),
        ...restOfClasses,
      }}
      disabled={state === 'loading' || state === 'success' || disabled}
      {...props}
    >
      <span className={clsx(buttonClasses.labelProgress, loader)}>
        {children}
      </span>
      {state === 'loading' && (
        <CircularProgress
          className={clsx(buttonClasses.buttonProgress, loader)}
          size={progressSize}
          thickness={3}
        />
      )}

      <Fade in={state === 'success'} timeout={250}>
        <CheckIcon className={clsx(buttonClasses.buttonIcon)} />
      </Fade>
    </Button>
  );
};

export default EnhancedButton;
