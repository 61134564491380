import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { getInputs } from './content';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  getAssignedLineInfo,
  getDefaultCommissionEnums,
  setBusinessPartnerLineCommission,
} from './queries';
import { LookupToList, graphqlToDefaultCommissionInfo } from './utils';
import Loader from '../../components/Loader';
import EnhancedNote from '../../components/enhanced-form/EnhancedNote';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import { EnhancedButtonStatus } from '../../components/EnhancedButton';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { getError } from '../../utils/graph-utils';

const useStyles = makeStyles()(() => ({
  note: {
    color: 'red !important',
    fontSize: '12px !important',
    fontStyle: 'italic',
  },
}));

const SetDefaultCommissionDrawer: React.FC<
  ISetDefaultCommissionDrawerProps
> = ({
  businessPartnerId,
  businessPartnerType,
  assignedLineId,
  open,
  onSuccess,
  onClose,
}) => {
  const { classes } = useStyles();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [booted, setBooted] = useState<boolean>(false);

  const [values, setValues] = useState<Record<string, any>>();
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

  const [defaultCommissionInfoQuery] = useLazyQuery(getAssignedLineInfo());
  let [defaultCommissionEnumResults] = useLazyQuery(
    getDefaultCommissionEnums(),
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [defaultCommissionAction] = useMutation(
    setBusinessPartnerLineCommission()
  );

  const loadLovList = async () => {
    const result = await defaultCommissionEnumResults();
    const list = LookupToList(result.data);
    return list;
  };

  const getEntityInfo = async () => {
    let defaultCommissionEntity: ISetDefaultCommissionInfo = {
      assignedLineId: '',
      assignedLine: '',
      commissionType: '',
      lineCommissionValue: null,
      ValueCurrency: '',
      lineCommissionRate: null,
      ApplicableOn: '',
    };

    if (!isEmpty(assignedLineId)) {
      const apiResult = await defaultCommissionInfoQuery({
        variables: { id: assignedLineId },
      });
      if (apiResult.data) {
        defaultCommissionEntity = graphqlToDefaultCommissionInfo(
          apiResult?.data
        );
      }
    }

    return defaultCommissionEntity;
  };

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const [entityData, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      const newList: Record<string, Record<string, string>> = {
        commissionType: lovData['commissionTypes'],
        ValueCurrency: lovData['currencies'],
        ApplicableOn: lovData['commissionApplicableOnOptions'],
      };

      entityData.commissionType = entityData.commissionType || 'RATE';
      entityData.lineCommissionRate = Math.round(
        parseFloat((entityData.lineCommissionRate * 100).toFixed(10))
      );

      entityData.ValueCurrency = entityData.ValueCurrency || '39735';
      setLovs(newList);
      setValues(entityData);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (formValues: Record<string, any>) => {
    const isValue = formValues?.commissionType?.toLowerCase() === 'value';

    const [data] = normaliseDynamicValues(
      getInputs(formValues as any, lovs, isValue),
      formValues
    );
    setFormDisabled(true);
    setSubmitButtonState('loading');
    try {
      const isRate = data.commissionType === 'RATE';
      let variables = {
        lineCommissionInputs: {
          lineCommissionType: data.commissionType,
          lineCommissionValue: isRate ? null : Number(data.lineCommissionValue),
          lineCommissionValueCurrency: isRate
            ? null
            : data.ValueCurrency || '39735',
          lineCommissionRate: !isRate
            ? 0
            : Number(data.lineCommissionRate) / 100,
          lineCommissionApplicableOn: isRate ? data.ApplicableOn : null,
        },
      };
      defaultCommissionAction({
        variables: { ...variables, entityId: assignedLineId },
        errorPolicy: 'all',
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {
                'Line default commissions successfully updated. Note that this change will not affected previously assigned plans'
              }
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setFormDisabled(false);
            setSubmitButtonState('success');
            onSuccess();
            onClose();
          }, 500);
        } else {
          setFormDisabled(false);
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
    }
  };

  const onChange = async (
    fieldName: string,
    value: any,
    newValues: Record<string, any>,
    errors: Record<string, string>,
    touched: Record<string, boolean>
  ) => {
    newValues[fieldName] = value;
    if (fieldName === 'commissionType') {
      if (value?.toLowerCase() === 'value') {
        newValues.ApplicableOn = '';
        errors.ApplicableOn = '';
      }
    }

    setValues(newValues);
  };

  const inputsForm = useMemo(() => {
    const isValue = values?.commissionType?.toLowerCase() === 'value';
    const result = getInputs((values as any) || undefined, lovs, isValue);
    return result;
  }, [values, lovs]);

  return (
    <GenericDrawer
      title={'Set Default Commission'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title='Information'
            onChange={onChange}
            noteSection={
              <EnhancedNote
                name='note'
                title=''
                text='Note that changing the default commissions will only affect assigned plans in the future. This change will not affected previously assigned plans.'
                className={classes.note}
              />
            }
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SetDefaultCommissionDrawer;
