import { gql } from "@apollo/client";

export function getListForm() {
  return gql(/* GraphQL */ `
    query getPersonalInfoEnums {
      SalesforceManagement_PersonTypes: __type(
        name: "SalesforceManagement_PersonTypes"
      ) {
        name
        enumValues {
          name
          __typename
        }
        __typename
      }
      Insurance_PhoneType: __type(name: "Insurance_PhoneType") {
        name
        enumValues {
          name
        }
      }
      Insurance_Genders: __type(name: "Insurance_Genders") {
        name
        enumValues {
          name
        }
      }
      Core_MaritalStatuses: __type(name: "Core_MaritalStatuses") {
        name
        enumValues {
          name
        }
      }
      Core_IsSmoker: __type(name: "Core_IsSmoker") {
        name
        enumValues {
          name
        }
      }
      SalesforceManagement {
        lookups {
          personsCategories {
            Id
            Title
          }
        }
      }
      Insurance {
        lookups {
          titles {
            Id
            Title
          }
          countries {
            Id
            Title
          }
          nationalities {
            Id
            Title
          }
          occupationClasses {
            Id
            Title
          }
        }
      }
    }
  `);
}

export function updatePersonInfo() {
  return gql`
    mutation updatePersonPersonalInfo(
      $entityId: String!
      $personDetailsInputs: SalesforceManagement_PersonInitialDetailsInputs!
      $personnalInfoInputs: SalesforceManagement_PersonPersonalInfoDetailsInputs!
      $dateOfBirth: Date
      $dateOfDeath: Date
      $dateOfDeathNotified: Date
      $dateOfEmployment: Date
      $dateOfKYC: Date
      $dateOfSelfCertificateReceived: Date
      $dateOfSelfCertificateValidated: Date
    ) {
      salesforceManagement {
        entities {
          person {
            salesforceManagement {
              updatePersonPersonalInfo(
                entityId: $entityId
                dateOfBirth: $dateOfBirth
                dateOfDeath: $dateOfDeath
                dateOfDeathNotified: $dateOfDeathNotified
                dateOfEmployment: $dateOfEmployment
                dateOfKYC: $dateOfKYC
                dateOfSelfCertificateReceived: $dateOfSelfCertificateReceived
                dateOfSelfCertificateValidated: $dateOfSelfCertificateValidated
                personDetailsInputs: $personDetailsInputs
                personnalInfoInputs: $personnalInfoInputs
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function checkPersonExists() {
  return gql`
    query checkPersonDetailsExists(
      $firstName: String!
      $middleName: String!
      $lastName: String!
      $dateOfBirth: Date
    ) {
      SalesforceManagement {
        queries {
          checkPersonExists(
            firstName: $firstName
            middleName: $middleName
            lastName: $lastName
            dateOfBirth: $dateOfBirth
          ) {
            salesforceManagement_Person_Id
          }
        }
      }
    }
  `;
}

export function checkCompanyExists() {
  return gql`
    query checkCompanyDetailsExists($companyName: String!, $personID: String) {
      SalesforceManagement {
        queries {
          checkCompanyNameExists(
            companyName: $companyName
            personID: $personID
          ) {
            salesforceManagement_Person_Id
          }
        }
      }
    }
  `;
}
