import _, { isArray, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { IPayerData, IProposalPageFormState } from "./form";
import ProposalPolicyPersonDrawer from "../../proposal-policy-person-drawer/ProposalPolicyPersonDrawer";
import EnhancedSwitch from "../../../components/enhanced-form/EnhancedSwitch";
import { useLazyQuery } from "@apollo/client";
import { fetchedPersonAddressList, fetchedPersons } from "../queries";
import { extractPersons, extractPersonsAddresses } from "../utils";
import { useParams } from "react-router-dom";
import { isGuid } from "../../../utils/validationUtils";
import NewChipsInput from "../../../components/enhanced-form/NewChipsInput";
import { validatePolicyPayerField } from "../../../modules/production/proposal/page/expat/validation";
import { IProposalDetailsSummary } from "./index2";
import PersonAddressDrawer from "../../person-address-drawer/PersonAddressDrawer";

interface IPolicyDetailsWidgetProps {
  pageState: IProposalPageFormState;
  onPageStateUpdate: (pageState: IProposalPageFormState) => void;
  disabledForm?: boolean;
  lovs: Record<string, Record<string, string>>;
  data: IProposalDetailsSummary;
}

const useStyles = makeStyles()(() => ({
  container: {
    width: "100%",
  },
  fieldRow: {
    display: "grid",
    gridTemplateColumns: `repeat(2, 49%)`,
    gap: "2%",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "ce",
  },
  field: {
    width: "100%",
    marginRight: "25px !important",
    minWidth: "195px",
  },
  orderField: {
    width: "100%",
    marginRight: "25px !important",
    minWidth: "20px",
  },
  sumInsuredRow: {
    width: "100%",
  },
  section: {
    display: "grid",
    "grid-template-columns": "23.5% 23.5% 23.5% 23.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& label": {
      "& span": {
        margin: "0 0 0",
      },
    },
  },
  addBtn: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",
    padding: "0",
    margin: "20px auto 0",
    display: "block",
  },
  removeBtn: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    cursor: "pointer",
    padding: "0",
    margin: "0 auto",
    display: "block",
  },
  labelHeader: {
    fontSize: "14px !important",
    lineHeight: "16px !important",
    fontFamily: "SourceSansPro-SemiBold !important",
  }
}));

const PolicyPayerWidget: React.FC<IPolicyDetailsWidgetProps> = ({
  pageState,
  onPageStateUpdate,
  disabledForm,
  lovs,
  data,
}) => {
  const { classes } = useStyles();
  const params = useParams();
  const entityId = params.id;

  const [getPersonAddressListLazy] = useLazyQuery(fetchedPersonAddressList());

  const [newPersonDrawerOpen, setNewPersonDrawerOpen] =
    useState<boolean>(false);
  const [newAddressDrawerOpen, setNewAddressDrawerOpen] =
    useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState<string | null>(null);

  const [lists, setLists] = useState<
    Record<string, Record<string, Record<string, string>>>
  >({
    payerId: {
      payerPersons: {},
    },
    payerAddresses: {},
  });

  const values = pageState.values.policyPersons.payer;
  const errors = pageState.errors.policyPersons.payer;
  const touched = pageState.touched.policyPersons.payer;

  const [fetchedPersonsLazy] = useLazyQuery(fetchedPersons());

  const handleOpenDrawer = (section: string) => {
    setCurrentSection(section);
    setNewPersonDrawerOpen(true);
  };

  const handleOpenAddressDrawer = () => {
    setNewAddressDrawerOpen(true);
  };

  const handlePayerSearch = async (inputValue: string) => {
    if (inputValue?.length > 3) {
      const newData = await fetchedPersonsLazy({
        variables: {
          searchKeyword: inputValue,
          selectedBusinessPartners: [data?.PrimaryBPID?.Id],
        },
      });

      return extractPersons(newData.data);
    }

    return {} as Record<string, string>;
  };

  const initialize = async () => {
    const addressList: Record<string, string> = {};
    addressList[values.payerAddress] = values.addressDetails;
    setLists({
      payerAddresses: addressList as any,
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const handlePayerAddresses = async (personId: string) => {
    const result: any = await getPersonAddressListLazy({
      variables: {
        selectedPersonID: personId,
      },
      errorPolicy: "all",
    });

    return extractPersonsAddresses(result.data);
  };

  const onFieldBlur = (fieldName: string) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.policyPersons.payer[fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = async (
    fieldName: string,
    value: any,
    rowIndex?: number,
    touched = false,
    updatedList: any = lists
  ) => {
    const newPageState = _.cloneDeep(pageState);
    const newLists = { ...updatedList };
    if (fieldName === "payerId") {
      const mainValue: string = isArray(value) ? value[0] : value;
      if (isEmpty(mainValue) || isGuid(mainValue)) {
        (newPageState.values.policyPersons.payer as any)[fieldName] = mainValue;
        newPageState.errors.policyPersons.payer[fieldName] =
          validatePolicyPayerField(fieldName, mainValue);
        if (touched) {
          newPageState.touched.policyPersons.payer[fieldName] = true;
        }

        if (isGuid(mainValue)) {
          const result2 = (await handlePayerAddresses(mainValue)) as Record<
            string,
            Record<string, { address: string; isPrimary: boolean }>
          >;
          const payerAddresses = result2.payerPersonAddress;
          let extractedAddresses: any = {};
          Object.keys(payerAddresses).forEach((key) => {
            extractedAddresses[key] = payerAddresses[key].address;
            if (payerAddresses[key].isPrimary) {
              newPageState.values.policyPersons.payer.payerAddress = key;
            }
          });
          newPageState.errors.policyPersons.payer.payerAddress = "";
          newLists["payerAddresses"] = extractedAddresses;
        }
      } else {
        const result: Record<
          string,
          Record<string, string>
        > = (await handlePayerSearch(value)) as any;

        newLists["payerId"] = result;
      }
    } else {
      (newPageState.values.policyPersons.payer as any)[fieldName] = value;
      newPageState.errors.policyPersons.payer[fieldName] =
        validatePolicyPayerField(fieldName, value);
      if (touched) {
        newPageState.touched.policyPersons.payer[fieldName] = true;
      }
    }
    setLists(newLists);
    onPageStateUpdate(newPageState);
  };

  const onFieldSearch = async (
    fieldName: string,
    value: any,
    touched = false,
    updatedList: any = lists
  ) => {
    const newLists = { ...updatedList };

    if (fieldName === "payerId") {
      const result: Record<
        string,
        Record<string, string>
      > = (await handlePayerSearch(value)) as any;
      newLists["payerId"] = result;
    }

    setLists(newLists);
  };

  const handleDrawerSubmitSuccess = (
    person: any,
    newPersonFullName: string
  ) => {
    const newPersonList: Record<string, string> = {};
    newPersonList[person] = newPersonFullName;
    onFieldUpdate("payerId", [person], 0, false, {
      payerId: { payerPersons: newPersonList },
    });
    setNewPersonDrawerOpen(false);
  };

  const handleAddressSubmitSuccess = (
    address: string,
    addressFullName: string
  ) => {
    onFieldUpdate("payerAddress", address, 0, false, {
      payerAddresses: { [address]: addressFullName },
    });
    setNewAddressDrawerOpen(false);
  };

  const getDefaultPersonListIfEmpty = (
    list: Record<string, string>,
    payer: IPayerData
  ) => {
    if (Object.keys(list || {}).length > 0 || !payer.fullName) {
      return list;
    }

    const newList: Record<string, string> = {
      [payer.payerId]: payer.fullName,
      [payer.payerAddress]: payer.addressDetails,
    };

    return newList;
  };

  return (
    <div>
      <div className={classes.fieldRow}>
        {newPersonDrawerOpen && currentSection === "payer" && (
          <ProposalPolicyPersonDrawer
            open={newPersonDrawerOpen}
            onClose={() => setNewPersonDrawerOpen(false)}
            section={currentSection}
            planId={""}
            lineExternalCode={data?.LineId?.ExternalCode}
            onSuccess={() => {}}
            proposalId={entityId}
            onSubmitSuccess={handleDrawerSubmitSuccess}
          />
        )}

        {newAddressDrawerOpen && (
          <PersonAddressDrawer
            open={newAddressDrawerOpen}
            onClose={() => setNewAddressDrawerOpen(false)}
            personId={values.payerId}
            onSubmitSuccess={handleAddressSubmitSuccess}
            onSuccess={() => {}}
          />
        )}

        <NewChipsInput
          key="payerId"
          name="payerId"
          title="Payer"
          placeholder="Payer"
          value={values.payerId}
          error={touched.payerId ? errors.payerId : ""}
          onChange={(v) => {
            const value = isArray(v) ? v[0] : v;
            onFieldUpdate("payerId", value);
          }}
          onSearch={async (v) => {
            const value = isArray(v) ? v[0] : v;
            onFieldSearch("payerId", value);
          }}
          clearValueOnSearch={false}
          disabled={disabledForm}
          items={getDefaultPersonListIfEmpty(
            lists?.payerId?.payerPersons,
            values
          )}
          required={true}
          multiple={false}
          material={true}
          supportLink={disabledForm ? "" : "Add Person"}
          onLinkClick={(e) => {
            e.preventDefault();
            handleOpenDrawer("payer");
          }}
          customStyles={{
            labelStyles: classes.labelHeader
          }}
        />

        <NewChipsInput
          key="payerAddress"
          name="payerAddress"
          title="Payer Address"
          placeholder="Payer Address"
          value={values.payerAddress}
          error={touched.payerAddress ? errors.payerAddress : ""}
          clearValueOnSearch={false}
          onChange={(v) => {
            onFieldUpdate("payerAddress", v);
          }}
          disabled={disabledForm}
          items={getDefaultPersonListIfEmpty(
            lists.payerAddresses as any,
            values
          )}
          required={true}
          multiple={false}
          material={true}
          supportLink={disabledForm ? "" : "Add Address"}
          onLinkClick={(e) => {
            e.preventDefault();
            handleOpenAddressDrawer();
          }}
          customStyles={{
            labelStyles: classes.labelHeader
          }}
        />
      </div>

      <div style={{ width: "235px" }}>
        <EnhancedSwitch
          key="ownerIsDifferent"
          name="ownerIsDifferent"
          title="Owner is different from Payer"
          value={values.ownerIsDifferent}
          error={touched.ownerIsDifferent ? errors.ownerIsDifferent : ""}
          onBlur={(v) => onFieldBlur("ownerIsDifferent")}
          onChange={(name, value) => {
            onFieldUpdate("ownerIsDifferent", value, undefined, true);
          }}
          className={classes.field}
          disabled={disabledForm}
        />
      </div>
    </div>
  );
};

export default PolicyPayerWidget;
