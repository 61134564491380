import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery } from "@apollo/client";
import { listQuery } from "./queries";
import { mapToListingData, toLookupsData } from "./utils";
import { filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import { IEnhancedMenuItem } from "../../components";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT
} from "../../constants";
import dayjs from "dayjs";
import InvoicePopup from "../../forms/invoice-popup/InvoicePopup";
import {
  IEnhanceTableHeaderClickable,
} from "../../components/enhanced-table";

interface IInvoicePage {}

const InvoicePage: React.FC<IInvoicePage> = ({}) => {

  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [tableLoadingState, setTableLoadingState] = useState<boolean>(false);
  const [InvoiceDrawerOpen, setInvoiceDrawerOpen] =
    useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>("");

  const actions: IEnhancedMenuItem[] = [{
    title: "+ New Invoice",
    onClick: () => {
      setChosenEditId(undefined);
      setInvoiceDrawerOpen(true);
    },
    isSecondary: true,
    backgroundColor: "#000",
    color: "#fff",
  }];

  const FILTER_SESSION_KEY = "invoicesFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          createdOn: [],
          invoiceDate: [],
          paymentDate: [],
          company: "41",
          customer: '',
          status: []
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getInvoicesLazy, { loading, error, data, fetchMore }] =
    useLazyQuery(listQuery(), {
      variables: {
        SelectedCompany:
          filterValues?.company && filterValues?.company?.length > 0
            ? filterValues?.company
            : "41",
        SelectedCustomer: filterValues?.customer
            ? filterValues?.customer
            : null,
        SelectedStatus: filterValues?.status
            ? filterValues?.status
            : null,
        CreatedOnFromDate: filterValues?.createdOn[0]
            ? dayjs(filterValues.createdOn[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
        CreatedOnToDate: filterValues?.createdOn[1]
            ? dayjs(filterValues.createdOn[1]).add(1, 'day').format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
        InvoiceFromDate: filterValues?.invoiceDate[0]
            ? dayjs(filterValues.invoiceDate[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
        InvoiceToDate: filterValues?.invoiceDate[1]
            ? dayjs(filterValues.invoiceDate[1]).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
        PaymentFromDate: filterValues?.paymentDate[0]
            ? dayjs(filterValues.paymentDate[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
        PaymentToDate: filterValues?.paymentDate[1]
            ? dayjs(filterValues.paymentDate[1]).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
        pageNumber: 1,
        pageSize: tableData.pageSize
      },
    });

  useEffect(() => {
    getInvoicesLazy();
  }, []);

  useEffect(() => {
    if (loading) setLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = toLookupsData(data);

      if (!Object.keys(lovs.company).length) {
        lovs.company = filterValues?.company;
      }

      if (savedFilters?.customer in lovs.customer) {
      }
      else {
        savedFilters.customer = ''
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);
    }
    setLoadingState(false);
  }, [data]);

  useEffect(() => {
    if (loading) setTableLoadingState(true);
    if (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (data) {
      const tableData = mapToListingData(data);
      
      setTableData({ ...tableData, pageNumber: 0, pageSize: tableData.pageSize });
    }
    setTableLoadingState(false);
  }, [loading, error, data]);

  function handlePageChange(page: number) {
    
    setTableLoadingState(true);
    const nextPage = ++page;
    fetchMore({
      variables: {
        pageNumber: nextPage,
        pageSize: tableData.pageSize,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToListingData(fetchMoreResult.data);
          
          const updatedTableData = {
            ...newApplicationList,
            pageNumber: nextPage -1,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  function handleRowsPerPageChange(numberOfRecordsPerPage: number) {
    setTableLoadingState(true);
    setTableData({ ...tableData, pageNumber: 1 });
    fetchMore({
      variables: {
        pageNumber: 1,
        pageSize: numberOfRecordsPerPage,
      },
    })
      .then((fetchMoreResult) => {
        if (fetchMoreResult.data) {
          const newApplicationList = mapToListingData(fetchMoreResult.data);

          const updatedTableData = {
            ...newApplicationList,
            pageNumber: 0,
            pageSize: numberOfRecordsPerPage,
          };
          setTableData(updatedTableData);
        }
      })
      .finally(() => {
        setTableLoadingState(false);
      });
  }

  actions[0].onClick = () => {
    setChosenEditId(undefined);
    setInvoiceDrawerOpen(true);
  };

  (
    headers.accounting_Invoices_InvoiceNumber as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/accounting/invoices/" + payload.columns.accounting_Invoices_Id
    );
  };

  const renderMainChildren = () => {
    
    return (
      <>
        {loading ? (
          <div></div>
        ) : (
          <div style={{ marginTop: "20px" }}>
            <EnhancedTable
              title="Invoices"
              name="count"
              orderByAscendingByDefault
              inline={false}
              data={tableData}
              headers={headers}
              handlePageChange={(page: number) => handlePageChange(page)}
              handleRowsPerPageChange={(page: number) =>
                handleRowsPerPageChange(page)
              }
              hideToolbar
              currentPage={tableData.pageNumber}
              disableSelection
              usePagination={true}
              actions={actions}
              loader={tableLoadingState}
              showCellFullData={true}
            />
            {InvoiceDrawerOpen && (
              <InvoicePopup
                open={InvoiceDrawerOpen}
                onClose={() => setInvoiceDrawerOpen(false)}
                onSuccess={() => {
                  handlePageChange(0);
                }}
                invoiceId={chosenEditId}
              />
            )}
          </div>
        )}
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={""}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={loading || loadingState}
      name={"Applications"}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
}
export default InvoicePage;