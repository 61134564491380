import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery } from "@apollo/client";
import { filterQuery, listQuery } from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { actions, filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _ from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import AgentDrawer from "../../forms/agent-drawer/AgentDrawer";
import { IEnhanceTableHeaderClickable } from "../../components/enhanced-table";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import Loader from "../../components/Loader";

interface IAgentsPage {}

const AgentsPage: React.FC<IAgentsPage> = () => {
  const [booted, setBooted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [agentDrawerOpen, setAgentDrawerOpen] = useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>("");

  const FILTER_SESSION_KEY = "agentsFilter";

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          agency: [],
          status: ["ACTIVE"],
          type: ["INTERNAL"],
          access: ["QUOTING", "ISSUE_POLICIES"],
        };

  const [filterValues, setFilterValues] = useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [listQueryLazy] = useLazyQuery(listQuery());
  const [filterQueryLazy] = useLazyQuery(filterQuery());

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setLoading(true);
    try {
      const [listResult, filterResult] = await Promise.all([
        listQueryLazy({
          variables: {
            currentPage: currentPage + 1,
            currentPageSize: pageSize,
            agencyID:
              filterV?.agency && filterV?.agency?.length > 0
                ? filterV?.agency
                : null,
            agentStatus:
              filterV?.status && filterV?.status?.length > 0
                ? filterV?.status
                : [],
            agentType:
              filterV?.type && filterV?.type?.length > 0
                ? filterV.type
                : [],
            agentAccesses:
              filterV?.access && filterV?.access?.length > 0
                ? filterV.access
                : [],
          },
          errorPolicy: "ignore",
          fetchPolicy: "no-cache",
        }),
        filterQueryLazy({
          fetchPolicy: "no-cache",
        }),
      ]);

      if (listResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }
      if (filterResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        return;
      }

      const data = listResult.data;
      const filterData = filterResult.data;

      if (data) {
        const mappedTableData = mapToListingData(data);
        setTableData({ ...mappedTableData, pageNumber: currentPage });
      }

      if (filterData) {
        const savedFilters = getFilter(FILTER_SESSION_KEY) || filterV;
        setFilterValues(savedFilters);

        const lovs = dataToLovs(filterData);

        if (!Object.keys(lovs.agency).length) {
          lovs.agency = filterV?.agency;
        }

        if (!Object.keys(lovs.status).length) {
          lovs.status = filterV?.status;
        }

        if (!Object.keys(lovs.type).length) {
          lovs.type = filterV?.type;
        }

        if (!Object.keys(lovs.access).length) {
          lovs.access = filterV?.access;
        }

        const newFilterSections = filterSectionsContent(lovs, savedFilters);
        setFilterSections(newFilterSections);
      }

      setFilter(filterV, FILTER_SESSION_KEY);
    } catch (error) {
      console.error("Error loading data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoading(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    await loadData(0, tableData.pageSize, savedFilters);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  actions[0].onClick = () => {
    setChosenEditId(undefined);
    setAgentDrawerOpen(true);
  };

  (headers.insurance_Agent_FirstName as IEnhanceTableHeaderClickable).callback =
    (payload: any) => {
      setChosenEditId(payload.columns.insurance_Agent_Id);
      setAgentDrawerOpen(true);
    };

  const handlePageChange = (page: number) => {
    loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = (numberOfRecordsPerPage: number) => {
    loadData(0, numberOfRecordsPerPage, filterValues);
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    const newFilters = _.cloneDeep(v);
    if (_.isEqual(newFilters, filterValues)) {
      return;
    }
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    await loadData(0, tableData.pageSize, newFilters);
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: "20px" }}>
      <EnhancedTable
        title="Business Users"
        name="count"
        orderByAscendingByDefault
        defaultOrderByColumn="name"
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        showCellFullData={true}
        currentPage={tableData.pageNumber}
        hideToolbar
        usePagination
        disableSelection
        actions={actions}
        loader={loading}
      />
      {agentDrawerOpen && (
        <AgentDrawer
          open={agentDrawerOpen}
          onClose={() => setAgentDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
            setAgentDrawerOpen(false);
          }}
          agentId={chosenEditId}
        />
      )}
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name={"Business Users Filter"}
        disabled={loading}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      ></ListingFilterWidget>
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name={"Business Users"}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default AgentsPage;
