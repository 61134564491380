import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data?.SalesforceManagement?.queries?.getBusinessUserList?.items?.forEach(
    (obj: IGenericBusinessUsersTableData, i: number) => {
      list[i] = {
        salesforceManagement_BusinessUser_Id:
          obj?.salesforceManagement_BusinessUser_Id,
        salesforceManagement_BusinessUser_Code:
          obj?.salesforceManagement_BusinessUser_Code,
        person_FullName:
          obj?.salesforceManagement_BusinessUser_PersonID.views
            .SalesforceManagement_all.properties.FullName,
        salesforceManagement_BusinessUser_Type:
          obj?.salesforceManagement_BusinessUser_Type?.Title,
        salesforceManagement_BusinessUser_Access:
          obj?.salesforceManagement_BusinessUser_Access?.Title,
        person_Email:
          obj?.salesforceManagement_BusinessUser_PersonID.views
            .SalesforceManagement_all.properties.Email,
        person_PhoneDetails:
          obj?.salesforceManagement_BusinessUser_PersonID.views
            .SalesforceManagement_all.properties.PhoneDetails?.PhoneNumber,
        salesforceManagement_BusinessUser_Status: capitalizeFirstLetter(
          obj?.salesforceManagement_BusinessUser_Status?.replaceAll("_", " ")
        ),
        salesforceManagement_BusinessUser_createdOn:
          obj?.salesforceManagement_BusinessUser_createdOn,
        salesforceManagement_BusinessUser_modifiedOn:
          obj?.salesforceManagement_BusinessUser_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data.SalesforceManagement.queries.getBusinessUserList?.paging?.pageSize,
    pageNumber:
      data.SalesforceManagement.queries.getBusinessUserList?.paging?.pageNumber,
    totalCount:
      data.SalesforceManagement.queries.getBusinessUserList?.paging?.totalCount,
  };
}

export function toLookups(data: any) {
  let lovs: Record<string, Record<string, string>> = {
    type: {},
    access: {},
    status: {},
    company: {},
  };

  lovs.type = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.businessUserTypes
  );
  lovs.access = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.businessUserAccesses
  );
  lovs.status = enumListAsRecordObject(
    data?.SalesforceManagement_BusinessUserStatuses?.enumValues
  );

  return lovs;
}
