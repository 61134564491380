import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query InvoicesList(
  $CreatedOnFromDate: Date
  $CreatedOnToDate: Date
  $PaymentFromDate: Date
  $PaymentToDate: Date
  $InvoiceFromDate: Date
  $InvoiceToDate: Date
  $DescendingField: Boolean
  $KeywordSearch: String
  $OrderByField: String
  $SelectedCompany: String!
  $SelectedCustomer: String
  $SelectedStatus: [Accounting_InvoiceStatusList]
  $pageNumber: Int!
  $pageSize: Int!
) {
  Accounting {
    queries {
      InvoicesList(
        CreatedOnFromDate: $CreatedOnFromDate
        CreatedOnToDate: $CreatedOnToDate
        DescendingField: $DescendingField
        KeywordSearch: $KeywordSearch
        OrderByField: $OrderByField
        SelectedCompany: $SelectedCompany
        SelectedCustomer: $SelectedCustomer
        SelectedStatus: $SelectedStatus,
        PaymentFromDate: $PaymentFromDate
        PaymentToDate: $PaymentToDate
        InvoiceFromDate: $InvoiceFromDate
        InvoiceToDate: $InvoiceToDate
        pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
      ) {
        items {
          accounting_Invoices_Id
          accounting_Invoices_createdOn
          accounting_Invoices_modifiedOn
          accounting_Invoices_InvoiceDate
          accounting_Invoices_PaymentDate
          accounting_Invoices_InvoiceTotalAmount
          customer_CustomerName
          accounting_Invoices_InvoiceNumber
          accounting_Invoices_InvoiceStatus
        }
        paging {
          pageSize
          pageNumber
          totalCount
        }
      }
      GetCustomersOfCompany(SelectedCompanyID: $SelectedCompany) {
        accounting_Customers_Id
        accounting_Customers_CustomerName
      }
    }
  }
  SalesforceManagement {
    lookups {
      relatedCompanies {
        Code
        Title
        Id
      }
    }
  }
}


  `;
}
