import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import Loader from '../../components/Loader';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { isEmpty, REGEX } from '../../utils/validationUtils';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { getError } from '../../utils/graph-utils';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  checkCodeExistsQuery,
  createCategoryMutation,
  getAccountLayersByCompany,
  getRelatedCompanies,
} from './queries';
import CustomCategoryTreeNode from './CustomCategoryTreeNode';
import { makeStyles } from 'tss-react/mui';
import EnhancedChipInput from '../../components/enhanced-form/EnhancedChipInput';
import EnhancedInput from '../../components/enhanced-form/EnhancedInput';
import Collapse from '@mui/material/Collapse';
import EnhancedNumberInput from '../../components/enhanced-form/EnhancedNumberInput';
import EnhancedButton from '../../components/EnhancedButton';

const useStyles = makeStyles()(() => ({
  formTitle: {
    color: '#231F20',
    fontFamily: 'SourceSansPro-Bold',
    fontSize: '16px',
    lineHeight: '19px',
    padding: '0 0 13px 0',
    margin: '0 0 35px 0',
    borderBottom: '1px solid #E5E5E5',
  },
  accountTreesContainer: {
    maxHeight: '350px',
    overflow: 'auto',
    margin: '0 0 30px',
  },
  accountTreesVisibleContainer: {
    backgroundColor: '#F9F9F9',
    padding: '15px 0 0',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
  },
  disabledInput: {
    pointerEvents: 'none',
  },
  customNumberInputContainer: {
    margin: '0 0 5px',
  },
  customNumberInputsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  customSelectedCategoryBox: {
    border: '1px solid #E5E5E5',
    borderRadius: '5px',
    padding: '9px 9px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '60px',
    margin: '0 10px 0 0',
  },
  customSelectedCategoryId: {
    fontSize: '13px',
    lineHeight: '16px',
    color: '#231F20',
    fontFamily: 'HelveticaNeue-Medium',
    height: '16px',
  },
  customSelectedCategoryLabel: {
    display: 'block',
    color: '#000',
    textAlign: 'left',
    margin: '0 0 13px',
    fontFamily: 'SourceSansPro-Regular',
    fontSize: '14px',
    lineHeight: '15px',
  },
  customSelectedCategoryError: {
    textAlign: 'right',
    fontSize: '11px',
    minHeight: '15px',
    display: 'block',
    color: '#D30D2B',
    margin: '5px 0 0',
    lineHeight: '15px',
  },
  submitButton: {
    margin: '0 auto',
  },
}));

interface ISelectedNode {
  guidID: string;
  id: string;
  name: string;
}

const CategoryDrawer: React.FC<ICategoryDrawerProps> = ({
  open,
  onSuccess,
  onClose,
}) => {
  const { classes } = useStyles();
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [getCompanies, { loading: companiesLoading }] = useLazyQuery(
    getRelatedCompanies()
  );
  const [companies, setCompanies] = useState<Record<string, string>>({});

  const [getAccountLayers, { loading: categoriesLoading }] = useLazyQuery(
    getAccountLayersByCompany()
  );
  const [accountTrees, setAccountsTrees] = useState<CustomCategoryTreeNode[]>(
    []
  );

  const [checkCodeExists, { loading: checkingCodeExists }] = useLazyQuery(
    checkCodeExistsQuery()
  );

  const [createCategory] = useMutation(createCategoryMutation());

  const [categoriesVisible, setCategoriesVisible] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<ISelectedNode>({
    id: '',
    name: '',
    guidID: '',
  });

  const [values, setValues] = useState<Record<string, string>>({
    company: '',
    writtenCode: '',
    categoryName: '',
  });
  const [touched, setTouched] = useState<Record<string, boolean>>({
    company: false,
    writtenCode: false,
    categoryName: false,
  });
  const [errors, setErrors] = useState<Record<string, string>>({
    company: '',
    writtenCode: '',
    categoryName: '',
  });

  const initialize = async () => {
    try {
      const result = await getCompanies();

      let tempCompanies: Record<string, string> = {};

      result.data.SalesforceManagement.lookups.relatedCompanies.forEach(
        (item: any) => (tempCompanies[item.Id] = item.Title)
      );

      setCompanies(tempCompanies);
    } catch (err) {
    } finally {
    }
  };

  const handleChange = async (key: string, value: any) => {
    let tempValues = { ...values };
    let tempTouched = { ...touched };

    tempValues[key] = value;
    tempTouched[key] = true;

    if (key === 'company') {
      setCategoriesVisible(false);
      setSelectedNode({ id: '', name: '', guidID: '' });
      const result = await getAccountLayers({
        variables: { SelectedCompany: value },
      });
      const data: AccountGroup[] =
        result.data.Accounting.queries.GetChartOfAccountGroupLayers123ByCompany;
      // Convert the data to multiple trees
      const trees = buildMultipleTrees(data);
      setAccountsTrees(trees);
    }

    setValues(tempValues);
    setTouched(tempTouched);
    setErrors(
      validateInputs(
        tempValues,
        key,
        key === 'company' ? { id: '', name: '', guidID: '' } : selectedNode
      )
    );
  };

  const handleBlur = async (key: string) => {
    setErrors(validateInputs(values, key, selectedNode));
    let tempTouched = { ...touched };
    tempTouched[key] = true;

    if (key === 'writtenCode') {
      const result = await checkCodeExists({
        variables: { Code: `${selectedNode.id}${values.writtenCode}`, Company: values.company },
      });

      if (result.data.Accounting.queries.CheckIfCategoryCodeExists.length > 0) {
        setErrors((prevState) => {
          return { ...prevState, writtenCode: 'Code already exists' };
        });
      }
    }

    setTouched(tempTouched);
  };

  const handleNodeClick = (id: string, name: string, guidId: string) => {
    setSelectedNode({ id: id, name: name, guidID: guidId });
    setValues((prevstate) => {
      return { ...prevstate, writtenCode: '' };
    });
    setTouched((prevstate) => {
      return { ...prevstate, writtenCode: true };
    });
    setErrors((prevstate) => {
      return { ...prevstate, writtenCode: 'Required' };
    });
  };

  const buildMultipleTrees = (
    data: AccountGroup[]
  ): CustomCategoryTreeNode[] => {
    const roots: CustomCategoryTreeNode[] = [];
    const lookup = new Map<string, CustomCategoryTreeNode>();

    data.forEach((entry) => {
      const layer1GuidId = entry.accounting_ChartOfAccountsGroup_Id;
      const layer1Id = entry.accounting_ChartOfAccountsGroup_AccountGroup;
      const layer1Name = entry.accounting_ChartOfAccountsGroup_AccountGroupName;
      const layer2GuidId = entry.layer2_Id;
      const layer2Id = entry.layer2_AccountGroup;
      const layer2Name = entry.layer2_AccountGroupName;
      const layer3GuidId = entry.layer3_Id;
      const layer3Id = entry.layer3_AccountGroup;
      const layer3Name = entry.layer3_AccountGroupName;

      // Handle Layer 1 (main layers)
      if (!lookup.has(layer1Id)) {
        const rootNode: CustomCategoryTreeNode = {
          id: layer1Id,
          name: layer1Name,
          children: [],
          layer: 1,
          onSelectNode: () => {
            handleNodeClick(layer1Id, layer1Name, layer1GuidId);
          },
        };
        lookup.set(layer1Id, rootNode);
        roots.push(rootNode);
        roots.sort((a, b) => customSort(a.id, b.id))
      }

      const parentNode1 = lookup.get(layer1Id)!;

      // Handle Layer 2
      if (layer2Id) {
        if (!lookup.has(layer2Id)) {
          lookup.set(layer2Id, {
            id: layer2Id,
            name: layer2Name,
            children: [],
            layer: 2,
            onSelectNode: () => {
              handleNodeClick(layer2Id, layer2Name, layer2GuidId);
            },
          });
          parentNode1.children.push(lookup.get(layer2Id)!); // Add only if not exists
          parentNode1.children.sort((a, b) => customSort(a.id, b.id));
        }
      }

      const parentNode2 = layer2Id ? lookup.get(layer2Id)! : null;

      // Handle Layer 3
      if (layer3Id) {
        if (!lookup.has(layer3Id)) {
          lookup.set(layer3Id, {
            id: layer3Id,
            name: layer3Name,
            children: [],
            layer: 3,
            onSelectNode: () => {
              handleNodeClick(layer3Id, layer3Name, layer3GuidId);
            },
          });
          parentNode2?.children.push(lookup.get(layer3Id)!); // Add only if not exists
          parentNode2.children.sort((a, b) => customSort(a.id, b.id));
        }
      }
    });

    return roots;
  };

  const validateInputs = (
    values: Record<string, any>,
    key: string,
    selectedNode: ISelectedNode,
    validateAll: boolean = false
  ) => {
    let tempErrors = { ...errors };

    if (key === 'company' || validateAll) {
      if (isEmpty(values.company)) {
        tempErrors.company = 'Required';
      } else {
        tempErrors.company = '';
      }
    }

    if (key === 'writtenCode' || validateAll) {
      if (isEmpty(selectedNode.id)) {
        tempErrors.writtenCode = 'You must select a parent category.';
      } else if (isEmpty(values.writtenCode)) {
        tempErrors.writtenCode = 'Required';
      } else if (!REGEX.numbersOnly.test(values.writtenCode)) {
        tempErrors.writtenCode = 'Only numebrs are allowed';
      } else if (values.writtenCode.length > 1) {
        tempErrors.writtenCode = 'There should be a maximum of 1 number';
      } else {
        tempErrors.writtenCode = '';
      }
    }

    if (key === 'categoryName' || validateAll) {
      if (isEmpty(values.categoryName)) {
        tempErrors.categoryName = 'Required';
      } else {
        tempErrors.categoryName = '';
      }
    }

    setTouched({ company: true, writtenCode: true, categoryName: true });

    return tempErrors;
  };

  const submitForm = async () => {
    setFormDisabled(true);
    setSubmitButtonState('loading');
    let errors = validateInputs(values, '', selectedNode, true);
    setErrors(errors);
    let isValid = Object.keys(errors).every((key) => isEmpty(errors[key]));
    if (isValid) {
      try {
        createCategory({
          variables: {
            SelectedCompany: values.company,
            ParentCategory: selectedNode.guidID,
            CategoryCode: `${selectedNode.id}${values.writtenCode}`,
            CategoryName: values.categoryName,
          },
          errorPolicy: 'all',
        }).then((res) => {
          if (isEmpty(res.errors)) {
            toast.success(
              <ToastSuccessMessage>
                Category successfully created
              </ToastSuccessMessage>
            );
            setTimeout(() => {
              setSubmitButtonState('success');
              onSuccess();
              onClose();
            }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        });
      } catch {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      } finally {
        setFormDisabled(false);
      }
    } else {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const customSort = (firstId: string, secondId: string) => {
    const parsedFirstId = parseInt(firstId);
    const parsedSecondId = parseInt(secondId);

    if (parsedFirstId < parsedSecondId) {
      return -1;
    }
    if (parsedFirstId > parsedSecondId) {
      return 1;
    }
    return 0;
  };

  return (
    <GenericDrawer
      title={'New Ledger'}
      onClose={() => onClose()}
      isOpen={open}
    >
      {companiesLoading || categoriesLoading ? (
        <Loader />
      ) : (
        <>
          <EnhancedChipInput
            name={'company'}
            title={'Company'}
            value={values.company}
            error={touched.company ? errors.company : ''}
            required
            placeholder="Company Name"
            selectOptions={companies}
            onChange={(v) => handleChange('company', v)}
            multiple={false}
            onBlur={() => handleBlur('company')}
          />
          <div
            onClick={(e) => {
              e.preventDefault();
              setCategoriesVisible(!categoriesVisible);
            }}
          >
            <EnhancedInput
              type={'text'}
              value={
                isEmpty(selectedNode.id) || isEmpty(selectedNode.name)
                  ? ''
                  : `${selectedNode.id} ${selectedNode.name}`
              }
              onBlur={() => {}}
              onChange={() => {}}
              name={'parentCategory'}
              title={'Parent Category*'}
              hideError
              disabled
              className={classes.disabledInput}
            />
          </div>
          <div className={classes.accountTreesContainer}>
            <Collapse in={categoriesVisible} timeout="auto" unmountOnExit>
              <div className={classes.accountTreesVisibleContainer}>
                {accountTrees.map((layer) => (
                  <CustomCategoryTreeNode
                    id={layer.id}
                    name={layer.name}
                    children={layer.children}
                    layer={layer.layer}
                    onSelectNode={() => layer.onSelectNode()}
                  />
                ))}
              </div>
            </Collapse>
          </div>

          <EnhancedNumberInput
            value={values.writtenCode}
            onBlur={() => handleBlur('writtenCode')}
            onChange={(e) => handleChange('writtenCode', e.target.value)}
            title={'Category Code*'}
            name={'writtenCode'}
            style={{ flexGrow: 1 }}
            error={touched.writtenCode ? errors.writtenCode : ''}
            hasPrefix
            prefixValue={selectedNode.id}
          />

          <EnhancedInput
            type={'text'}
            value={values.categoryName}
            onBlur={() => handleBlur('categoryName')}
            onChange={(e) => handleChange('categoryName', e.target.value)}
            name={'categoryName'}
            title={'Category Name*'}
            error={touched.categoryName ? errors.categoryName : ''}
          />
          <EnhancedButton
            state={submitButtonState}
            disabled={formDisabled || checkingCodeExists}
            onClick={() => submitForm()}
            className={classes.submitButton}
            backgroundColor="#D30D2B"
            color="#FFF"
          >
            Submit
          </EnhancedButton>
        </>
      )}
    </GenericDrawer>
  );
};

export default CategoryDrawer;
