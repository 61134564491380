import { gql } from "@apollo/client";

export function getListForm() {
  return gql`
    query getSalesForceBusinessUsersInfoEnums {
      SalesforceManagement_BusinessUserStatuses: __type(
        name: "SalesforceManagement_BusinessUserStatuses"
      ) {
        name
        enumValues {
          name
        }
      }

      SalesforceManagement {
        lookups {
          businessUserTypes {
            Code
            Title
            Id
          }
          businessUserAccesses {
            Code
            Title
            Id
          }
        }
      }
    }
  `;
}

export function createBusinessUser() {
  return gql`
    mutation createBusinessUser(
      $businessUserInputs: SalesforceManagement_BusinessUserInputs!
    ) {
      salesforceManagement {
        actions {
          createBusinessUser(businessUserInputs: $businessUserInputs) {
            id
          }
        }
      }
    }
  `;
}

export function updateBusinessUser() {
  return gql`
    mutation updateBusinessUser(
      $entityId: String!
      $personID: String!
      $type: String!
      $access: String!
      $account: String
    ) {
      salesforceManagement {
        entities {
          businessUser {
            salesforceManagement {
              updateBusinessUser(
                entityId: $entityId
                personID: $personID
                type: $type
                access: $access
                account: $account
              ) {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Account
                      Type {
                        Code
                        Title
                      }
                      Access {
                        Code
                        Title
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function checkBusinessUserByPerson() {
  return gql`
    query checkBusinessUserByPerson($selectedPersonID: String!) {
      SalesforceManagement {
        queries {
          checkBusinessUserByPerson(selectedPersonID: $selectedPersonID) {
            salesforceManagement_BusinessUser_Id
            salesforceManagement_BusinessUser_PersonID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    FullName
                    PersonCode
                  }
                }
              }
            }
            salesforceManagement_BusinessUser_Status
          }
        }
      }
    }
  `;
}

export function getPersonEmailDetailsInfo() {
  return gql`
    query getPersonEmailDetailsInfo($id: String!) {
      SalesforceManagement {
        entities {
          person(id: $id) {
            views {
              SalesforceManagement_all {
                properties {
                  Email
                }
              }
            }
          }
        }
      }
    }
  `;
}
