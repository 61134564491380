
import _ from 'lodash';
import { IAccountImportDrawerProps, AccountObject } from './index';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import React, { useState, useEffect } from 'react';
import DynamicForm from '../../DynamicForm/DynamicForm';
import { DynamicFormInputType } from '../../DynamicForm';
import { isEmpty } from "../../utils/validationUtils";
import { inputs } from './content';
import { LookupToList } from "./utils";
import { EnhancedButtonStatus } from '../../components/EnhancedButton';
import { resolveGraphqlBaseUrl } from '../../utils/tenant-utils';
import Loader from "../../components/Loader";
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getError } from '../../utils/graph-utils';
import { getUserToken } from '../../utils/userUtils';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { generateDownloadLink } from '../../utils/file-utils';
import DataService from '../../services/dataService';
import {
    getAccountingEnums, postFile, getFile
  } from "./queries";
import { excelToJson } from './ExcelReader';

const AddDocumentDrawer: React.FC<IAccountImportDrawerProps> = ({
  open,
  onSuccess,
  onClose,
}) => {
    const [accountEnumResultsQeury] = useLazyQuery(getAccountingEnums());
    const [postFileQuery] = useMutation(postFile());

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =  useState<EnhancedButtonStatus>();
  const [getFileQuery] = useLazyQuery(getFile());
  const [inputsForm, setInputsForm] =
  useState<Record<string, DynamicFormInputType>>();
  const navigate = useNavigate();
  const allowedExtensions = ['xls', 'xlsx'];

  const values = {
    company: '',
  };
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    relatedCompanies: {},
    currencies: {}
  });

  const initialize = async () => {
    
    let newLovs: Record<string, Record<string, string>> = {};
    const promises: Promise<any>[] = [];
      const accountEnumResultsQeuryPromise = accountEnumResultsQeury({
        fetchPolicy: "no-cache"
      });
      promises.push(accountEnumResultsQeuryPromise);
        const result = await Promise.all(promises);

            if (result[0]?.data) {
                const newAccountEnums = LookupToList(result[0].data);

                newLovs = {
                relatedCompanies: newAccountEnums["relatedCompanies"],
                currencies: newAccountEnums["Currency"],
                type: newAccountEnums["Type"]
                }
            }
            const newInputForm = inputs(values, newLovs, downloadTemplate);
            setLovs(newLovs);
            setInputsForm(newInputForm);
      };
    
    useEffect(() => {
        initialize();
    }, []);

    const onCustomChange = async (fieldName: string, value: any, allValues: Record<string, any>) => {
        const newLovs = {...lovs};
        let newInputForm = inputs(allValues, newLovs, downloadTemplate);

        setInputsForm(newInputForm);
    }

  const downloadTemplate = async () => {

    getFileQuery({
      variables: {},
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    }).then((res) => {
      if (isEmpty(res.error)) {
        const file = res?.data?.Accounting?.queries?.Templates[0];
        DataService.downloadAndOpenFileWithToken(
          generateDownloadLink(
            file.accounting_AccountTemplate_ChartOfAccountsTemplate.id,
            "Accounting-AccountTemplate",
            file.accounting_AccountTemplate_Id,
            "Accounting-all",
            "ChartOfAccountsTemplate"
          )
        )
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    });
  }

  const simulateFileUpload = async (company: string,file: File): Promise<void> => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    setSubmitButtonState('loading');
    setFormDisabled(true);
    try {
      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        toast.error(
          <ToastErrorMessage>
            {'Invalid file format. Please upload a PDF or DOCX or JPEG or PNG file.'}
          </ToastErrorMessage>
        );
        throw new Error('Invalid file format. Please upload a PDF or DOCX or JPEG or PNG file.');
      }
 
      const formData = new FormData();
 
      formData.append('formFile', file, file.name);
      formData.append(
        'operations',
        JSON.stringify({
          query: `mutation ImportAccounts ($SelectedCompany: String!, $ChartOfAccountsFile: Upload!) {
            accounting {
                actions {
                importAccount(SelectedCompany: $SelectedCompany, ChartOfAccountsFile: $ChartOfAccountsFile)
                }
            }
            }
        `,
          variables: {
            ChartOfAccountsFile: null as string,
            SelectedCompany: company
          },
        })
      );
      formData.append(
        'map',
        JSON.stringify({ formFile: ['variables.ChartOfAccountsFile'] })
      );
 
      const options = {
        method: 'POST',
        headers: {
          authorization: `Bearer ${getUserToken()}` || null,
          "GraphQL-preflight": "1",
        },
        body: formData,
      };
 
      fetch(`${resolveGraphqlBaseUrl()}/graphql`, options)
        .then(async (response) => {
          if (response.ok) { 
            toast.success(
              <ToastSuccessMessage>
                {'Document successfully uploaded'}
              </ToastSuccessMessage>
            );
            setTimeout(() => {
                setSubmitButtonState("success");
                onSuccess();
                onClose();
                navigate(`/accounting/accounts`);
              }, 500);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(response)}</ToastErrorMessage>);
          }
        })
        .then((data) => {
          return data;
        })
        .catch((error) => {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
        });
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
      throw error;
    } finally {
      setFormDisabled(false);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    if(values && values.document){
    //await simulateFileUpload(values.company,values.document);
    const result = await excelToJson(values.document);

    let AccountsInput: AccountObject[] = [];
    result['Chart of Accounts'].forEach((element) => {
      
      const currency = (Object.keys(lovs.currencies)).find(key => lovs.currencies[key] === element['Currency*']);
      const type = (Object.keys(lovs.type)).find(key => lovs.type[key] === element['Account Type*']);
      let AccountInput: AccountObject = new AccountObject();
      AccountInput.AccountName = element['Account Name*']
      AccountInput.AccountID = element['Account ID'] === '' ? null : element['Account ID']?.toString()
      AccountInput.AccountDescription = element['Description']
      AccountInput.AccountType = type
      AccountInput.DebitOrCredit = element['Debit Or Credit*'] == 'Debit' ? "DEBIT" : element['Debit Or Credit*'] == 'Credit' ? "CREDIT" : null
      AccountInput.OpeningBalance = element['Opening Balance*'] === '' || element['Opening Balance*'] === null || element['Opening Balance*'] === undefined ? 0 : element['Opening Balance*']
      AccountInput.ParentAccount = element['Parent Account'] === '' ? null : element['Parent Account']
      AccountInput.IsSubAccount = element['Is Sub Account*'] === '' || element['Is Sub Account*'] === null || element['Is Sub Account*'] === undefined ? false : element['Is Sub Account*'] === 'Yes' ? true : false
      AccountInput.AccountCurrency = currency

      AccountsInput.push(AccountInput);
    });
    
      postFileQuery({
        variables: { SelectedCompany: values.company, ChartOfAccountsFile: AccountsInput},
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });




    if(false) {
      await simulateFileUpload(values.company,values.document);
    }
    }
  };

  return (
    <GenericDrawer
      title={'Import New Account'}
      onClose={() => onClose()}
      isOpen={open}
    >
        {(!inputsForm) && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title='Upload your Accounts'
            onChange={onCustomChange}
          />
        </>
        
      )}
    </GenericDrawer>
  );
};

export default AddDocumentDrawer;
