import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { toast } from "react-toastify";
import { normaliseDynamicValues } from "../../../../../utils/dynamic-utils";
import ToastErrorMessage from "../../../../../components/ToastErrorMessage";
import { DEFAULT_ERROR_TEXT } from "../../../../../constants";
import { EnhancedButtonStatus } from "../../../../../components/EnhancedButton";
import Loader from "../../../../../components/Loader";
import {
  DynamicFormInputType,
  FormInputTypes,
} from "../../../../../DynamicForm";
import GenericDrawer from "../../../../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../../../../DynamicForm/DynamicForm";
import { FileType } from "../../../../../models/file";
import { getUserToken } from "../../../../../utils/userUtils";
import { resolveGraphqlBaseUrl } from "../../../../../utils/tenant-utils";
import { getError } from "../../../../../utils/graph-utils";
import ToastSuccessMessage from "../../../../../components/ToastSuccessMessage";

const inputs: Record<string, DynamicFormInputType> = {
  documentName: {
    name: "documentName",
    title: "Document Name",
    type: FormInputTypes.text,
    value: "",
    required: true,
  },
  document: {
    name: "document",
    title: "Document",
    type: FormInputTypes.fileuploader,
    value: null,
    required: true,
    disabled: false,
    maxFileSizeInMB: 5,
    allowedFileTypes: [
      FileType.pdf,
      FileType.docx,
      FileType.jpeg,
      FileType.png,
    ],
    imageUrl: "",
  },
};

interface IAddDocumentDrawerProps {
  onClose: () => void;
  onSuccess: () => void;
  open: boolean;
  entityId: string;
}

const AddDocumentDrawer: React.FC<IAddDocumentDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  entityId
}) => {
  let [submitButtonDisabled, _setSubmitButtonDisabled] =
    useState<boolean>(false);

  //const [selectedSublineStatus, setSelectedSublineStatus] = useState<string>("");

  // const [personAction] = useMutation(
  //   personId ? updatePerson() : createPerson()
  // );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);
      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const simulateFileUpload = async (documentName: string, file: File): Promise<void> => {
    const allowedExtensions = ['pdf', 'docx', 'jpeg', 'jpg', 'png'];

    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    setSubmitButtonState('loading');
    setFormDisabled(true);
    try {
      if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        toast.error(
          <ToastErrorMessage>
            {'Invalid file format. Please upload a PDF or DOCX or JPEG or PNG file.'}
          </ToastErrorMessage>
        );
        throw new Error('Invalid file format. Please upload a PDF or DOCX or JPEG or PNG file.');
      }

      const formData = new FormData();

      formData.append('formFile', file, file.name);
      formData.append(
        'operations',
        JSON.stringify({
          query: `
          mutation ($uploadedDocument:Upload!){
            production{
              entities{
                policy{
                  production{
                    uploadAdditionalDocument(entityId: \"${entityId}\"
                    documentName: \"${documentName}\"
                    uploadedDocument: $uploadedDocument) {
                      id
                    }
                  }
                }
              }
            }
          }
        `,
          variables: {
            uploadedDocument: null as string,
          },
        })
      );
      formData.append(
        'map',
        JSON.stringify({ formFile: ['variables.uploadedDocument'] })
      );

      const options = {
        method: 'POST',
        headers: {
          authorization: `Bearer ${getUserToken()}` || null,
          "GraphQL-preflight": "1",
        },
        body: formData,
      };

      try {
        const response = await fetch(`${resolveGraphqlBaseUrl()}/graphql`, options);

        if (response.ok) {
          toast.success(
            <ToastSuccessMessage>
              {'Document successfully uploaded'}
            </ToastSuccessMessage>
          );

          setTimeout(() => {
            setSubmitButtonState('success');
            onSuccess();
            onClose();
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(response)}</ToastErrorMessage>);
        }
      } catch (error) {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
      throw error;
    } finally {
      setFormDisabled(false);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [_data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");
    try {
      await simulateFileUpload(values.documentName, values.document);
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer title={"Add Drawer"} onClose={() => onClose()} isOpen={open}>
      {!open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(v: any) => submitForm(v)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Documents"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default AddDocumentDrawer;
