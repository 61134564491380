import { IListingData } from '../../models/listing';
import {
    lookupListAsRecordObject,
  } from "../../utils/graph-utils";

export function mapToListingData(data: any): IListingData<any> {
  const list: Record<string, ICustomersGenericTableData> = {};
  data.Accounting.queries.CustomersList?.items?.forEach(
    (obj: ICustomersGenericTableData, i: number) => {
      list[i] = {
        accounting_Customers_Id: obj.accounting_Customers_Id,
        accounting_Customers_createdOn: obj.accounting_Customers_createdOn,
        accounting_Customers_BalanceDue: obj.accounting_Customers_BalanceDue,
        accounting_Customers_CustomerName: obj.accounting_Customers_CustomerName,
        accounting_Customers_ReferenceNumber: obj.accounting_Customers_ReferenceNumber,
        accounting_Customers_Website: obj.accounting_Customers_Website,
        parentAccount_AccountName: obj.parentAccount_AccountName
      };
    },
  );
  

  return {
    pagedItems: list,
    pageSize: data.Accounting.queries.CustomersList?.paging.pageSize,
    pageNumber: data.Accounting.queries.CustomersList?.paging.pageNumber,
    totalCount: data.Accounting.queries.CustomersList?.paging.totalCount,
  };
}

  export function toLookupsData(data: any) {
    let lovs: Record<string, Record<string, string>> = {
      company: {},
      account: {}
    };

      lovs.company = lookupListAsRecordObject(
        data?.SalesforceManagement?.lookups?.relatedCompanies
      );
  
    return lovs;
  }
