import { FormInputTypes } from "../../DynamicForm";
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from "../../components/enhanced-table";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import { IAbstractRecord } from "../../models";
import { enumListAsRecordObject } from "../../utils/graph-utils";

// export const headers: Record<string, EnhancedTableHeader> = {
export const getHeaders = (
  navigate: (path: string) => void
): Record<string, EnhancedTableHeader> => ({
  planConfigManagement_Line_Name: {
    name: "planConfigManagement_Line_Name",
    title: "Line Name",
    type: EnhancedTableHeaderType.Clickable,
    callback: (payload: any) => {
      navigate("/plan/lines/" + payload.columns.planConfigManagement_Line_Id);
    },
  },
  planConfigManagement_Line_ExternalCode: {
    name: "planConfigManagement_Line_ExternalCode",
    title: "External Code",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Line_Abbreviation: {
    name: "planConfigManagement_Line_Abbreviation",
    title: "Abbreviation",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Line_NameArabic: {
    name: "planConfigManagement_Line_NameArabic",
    title: "Arabic Name",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Line_Status: {
    name: "planConfigManagement_Line_Status",
    title: "Status",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Line_createdOn: {
    name: "planConfigManagement_Line_createdOn",
    title: "Created on",
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Line_modifiedOn: {
    name: "planConfigManagement_Line_modifiedOn",
    title: "Modified on",
    type: EnhancedTableHeaderType.Date,
  },
});

export function filterSectionsContent(
  data: any,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: "Filters",
      inputs: {
        status: {
          name: "status",
          title: "Status",
          type: FormInputTypes.chips,
          value: filterValues?.status || [],
          placeholder: "Status",
          triggerUpdate: true,
          selectOptions: enumListAsRecordObject(
            data?.PlanConfigManagement_LineStatuses?.enumValues
          ),
        },
        createdDate: {
          name: "createdDate",
          title: "Created On",
          type: FormInputTypes.daterange,
          value: filterValues.createdDate || [],
          placeholder: "Created On",
          triggerUpdate: true,
        },
      },
    },
  ];
  return newFilterSections;
}
