import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  person: "",
  relatedCompany: "",
  type: "",
  category: "",
  isCompany: false,
  isLocal: false,
  isRegistered: false,
  registerNumber: "",
  taxAccount: "",
  account: "",
  maxCreditLimitAllowedCurrency: "39735",
  maxCreditLimitAllowed: 0,
  SalesforceManagement_BusinessPartnerStatuses: "NEW",
  allowAccessToAllInsured: false,
};

export const inputs = (
  businessPartnerId: string,
  defaultValues: Record<string, any>,
  customClasses: Record<string, string>,
  lookups: Record<string, Record<string, string>>
): Record<string, DynamicFormInputType> => {
  return {
    person: {
      name: "person",
      title: "Person",
      type: FormInputTypes.chips,
      placeholder: "Type to search for Person",
      value: defaultValues?.person,
      multiple: false,
      disabled: false,
      required: !businessPartnerId,
      hasPagination: false,
      itemsPerPage: 15,
      hidden: !!businessPartnerId,
      selectOptions: lookups["person"] || {},
    },
    relatedCompany: {
      name: "relatedCompany",
      title: "Related Company",
      type: FormInputTypes.chips,
      placeholder: "Related Company",
      value:
        !isEmpty(defaultValues) && !isEmpty(defaultValues.relatedCompany)
          ? defaultValues.relatedCompany
          : initialValues.relatedCompany,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lookups["relatedCompany"],
    },
    type: {
      name: "type",
      title: "Type",
      type: FormInputTypes.chips,
      placeholder: "Type",
      value:
        !isEmpty(defaultValues) && !isEmpty(defaultValues.type)
          ? defaultValues.type
          : initialValues.type,
      multiple: false,
      disabled: false,
      required: true,
      selectOptions: lookups["type"],
    },
    category: {
      name: "category",
      title: "Category",
      type: FormInputTypes.chips,
      placeholder: "Category",
      value:
        !isEmpty(defaultValues) && !isEmpty(defaultValues.category)
          ? defaultValues.category
          : initialValues.category,
      multiple: false,
      disabled: false,
      required: false,
      selectOptions: lookups["category"],
    },
    allowAccessToAllInsured: {
      name: "allowAccessToAllInsured",
      title: "Allow Access To All Insured",
      type: FormInputTypes.switch,
      value:
        !isEmpty(defaultValues) &&
        !isEmpty(defaultValues.allowAccessToAllInsured)
          ? defaultValues.allowAccessToAllInsured
          : initialValues.allowAccessToAllInsured,
      required: false,
      disabled: false,
    },
    isCompany: {
      name: "isCompany",
      title: "Is Company",
      placeholder: "Is Company",
      type: FormInputTypes.switch,
      value:
        !isEmpty(defaultValues) && !isEmpty(defaultValues.isCompany)
          ? defaultValues.isCompany
          : initialValues.isCompany,
      required: false,
      disabled: false,
    },
    isLocal: {
      name: "isLocal",
      title: "Is Local",
      placeholder: "Is Local",
      type: FormInputTypes.switch,
      value:
        !isEmpty(defaultValues) && !isEmpty(defaultValues.isLocal)
          ? defaultValues.isLocal
          : initialValues.isLocal,
      required: false,
      disabled: false,
    },
    isRegistered: {
      name: "isRegistered",
      title: "Is Registered",
      placeholder: "Is Registered",
      type: FormInputTypes.switch,
      value:
        !isEmpty(defaultValues) && !isEmpty(defaultValues.isRegistered)
          ? defaultValues.isRegistered
          : initialValues.isRegistered,
      required: false,
      disabled: false,
      className: customClasses.isRegisteredStyle,
    },
    registerNumber: {
      name: "registerNumber",
      title: "Register Number",
      placeholder: "Register Number",
      type: FormInputTypes.text,
      value:
        !isEmpty(defaultValues) && !isEmpty(defaultValues.registerNumber)
          ? defaultValues.registerNumber
          : initialValues.registerNumber,
      required: false,
      disabled: false,
    },
    taxAccount: {
      name: "taxAccount",
      title: "Tax Account",
      placeholder: "Tax Account",
      type: FormInputTypes.text,
      value:
        !isEmpty(defaultValues) && !isEmpty(defaultValues.taxAccount)
          ? defaultValues.taxAccount
          : initialValues.taxAccount,
      required: false,
      disabled: false,
    },
    account: {
      name: "account",
      title: "Account",
      placeholder: "Account",
      type: FormInputTypes.text,
      value:
        !isEmpty(defaultValues) && !isEmpty(defaultValues.account)
          ? defaultValues.account
          : initialValues.account,
      required: false,
      disabled: false,
    },
    maxCreditLimitAllowedCurrency: {
      name: "maxCreditLimitAllowedCurrency",
      title: "Max Credit Limit Allowed (Currency)",
      type: FormInputTypes.chips,
      placeholder: "Max Credit Limit Allowed (Currency)",
      value:
        !isEmpty(defaultValues) &&
        !isEmpty(defaultValues.maxCreditLimitAllowedCurrency)
          ? defaultValues.maxCreditLimitAllowedCurrency
          : initialValues.maxCreditLimitAllowedCurrency,
      multiple: false,
      disabled: false,
      required: !businessPartnerId,
      hidden: !!businessPartnerId,
      selectOptions: lookups["maxCreditLimitAllowedCurrency"],
    },
    maxCreditLimitAllowed: {
      name: "maxCreditLimitAllowed",
      title: "Max Credit Limit Allowed",
      type: FormInputTypes.formattedNumber,
      placeholder: "Max Credit Limit Allowed",
      value:
        !isEmpty(defaultValues) && !isEmpty(defaultValues.maxCreditLimitAllowed)
          ? defaultValues.maxCreditLimitAllowed
          : initialValues.maxCreditLimitAllowed,
      required: !businessPartnerId,
      disabled: false,
      hidden: !!businessPartnerId,
    },
    SalesforceManagement_BusinessPartnerStatuses: {
      name: "SalesforceManagement_BusinessPartnerStatuses",
      title: "Status",
      type: FormInputTypes.chips,
      value:
        !isEmpty(defaultValues) &&
        !isEmpty(defaultValues.SalesforceManagement_BusinessPartnerStatuses)
          ? defaultValues.SalesforceManagement_BusinessPartnerStatuses
          : initialValues.SalesforceManagement_BusinessPartnerStatuses,
      required: !businessPartnerId,
      multiple: false,
      disabled: true,
      hidden: !!businessPartnerId,
      selectOptions: lookups["SalesforceManagement_BusinessPartnerStatuses"],
    },
  };
};
