import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Configuration.queries.getTaxationList.items.forEach(
    (obj: IGenericTaxationsTableData, i: number) => {
      list[obj.configuration_Taxation_Id] = {
        Id: obj.configuration_Taxation_Id,
        lineId: obj.line_Id,
        line_Name: obj.line_Name,
        line_ExternalCode: obj.line_ExternalCode,
        configuration_Taxation_FixedStamp: `${obj.configuration_Taxation_FixedStampCurrency?.Title} ${obj.configuration_Taxation_FixedStamp}`,
        configuration_Taxation_ProportionalStamp: obj.configuration_Taxation_ProportionalStamp,
        configuration_Taxation_MunicipalityTax: obj.configuration_Taxation_MunicipalityTax,
        configuration_Taxation_TaxOnCommission: obj.configuration_Taxation_TaxOnCommission,
        configuration_Taxation_ReinsuranceTax: obj.configuration_Taxation_ReinsuranceTax,
        configuration_Taxation_EffectiveFrom:
          obj.configuration_Taxation_EffectiveFrom,
        configuration_Taxation_EffectiveTo:
          obj.configuration_Taxation_EffectiveTo,
        configuration_Taxation_createdOn: obj.configuration_Taxation_createdOn,
        configuration_Taxation_modifiedOn:
          obj.configuration_Taxation_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Configuration.queries.getTaxationList?.paging?.pageSize,
    pageNumber: data.Configuration.queries.getTaxationList?.paging?.pageNumber,
    totalCount: data.Configuration.queries.getTaxationList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    line: {},
  };

  data?.PlanConfigManagement?.queries?.allLines?.forEach(
    (obj: {
      planConfigManagement_Line_Id: string;
      planConfigManagement_Line_Name: string;
    }) => {
      lovs.line[obj.planConfigManagement_Line_Id] =
        obj.planConfigManagement_Line_Name;
    }
  );

  return lovs;
}
