import {
  convertToFixedNumber,
  numberToPercentage,
} from "../../../../../utils/helper-utils";
import { IProposalDetailsSummary } from "./index2";

export interface IPayerData {
  payerId: string;
  fullName?: string;
  payerAddress: string;
  ownerIsDifferent: boolean;
  addressDetails?: string;
}

export interface IOwnerData {
  ownerId?: string;
  fullName?: string;
  ownerAddress?: string;
  addressDetails?: string;
}

export interface IInsuredData {
  id: string;
  order: string;
  fullName?: string;
  address?: string;
  addressDetails?: string;
}

export interface IBeneficiaryData {
  id: string;
  address: string;
  percentage: number;
  fullName?: string;
  addressDetails?: string;
}

export interface IPolicyPersons {
  payer: IPayerData;
  owner?: IOwnerData;
  insured: IInsuredData[];
  beneficiaries: IBeneficiaryData[];
}

export interface IProposalPageFormData {
  policyPersons: {
    payer: IPayerData;
    owner?: IOwnerData;
    insured: IInsuredData[];
    beneficiaries: IBeneficiaryData[];
  };
  covers: IProposalCover[];
  costs: IProposalCostCharges;
  expatDetails: IProposalExpatDetails;
}

export interface IProposalCover {
  isMain: boolean;
  isMandatory: boolean;
  isEditable: boolean;
  policyCover: string;
  coverPremiumType: string;
  coverPremiumPercentage?: number;
  coverPremiumValue?: number;
  currency: string;
  coverSumInsured?: number;
  sumInsuredIsAdditive: boolean;
  excessOnClaimType: string;
  excessOnClaimAmount?: number;
  excessOnClaimPercentage?: number;
  excessOnClaimDays?: number;
  name?: string;
  clauseID: string;
  coverEffectiveFrom: string;
  coverEffectiveTo: string;
}

export interface IProposalCostCharges {
  policyCost: number;
  chargesPercentage: number;
  tpaFeesType: string;
  tpaFeesAmount?: number;
  tpaFeesPercentage?: number;
  tpaFeesApplicableOn: string;
}

export interface IProposalExpatDetails {
  medicalClass: string;
  continuity: string;
  continuityNumber: number;
  exclusion: string;
  remarks: string;
}

export interface IProposalPageFormState {
  values: IProposalPageFormData;
  errors: {
    policyPersons: {
      payer: Record<string, string>;
      owner: Record<string, string>;
      insured: Record<string, string>[];
      beneficiaries: Record<string, string>[];
    };
    covers: Record<string, string>[];
    costs: Record<string, string>;
    expatDetails: Record<string, string>;
  };
  touched: {
    policyPersons: {
      payer: Record<string, boolean>;
      owner: Record<string, boolean>;
      insured: Record<string, boolean>[];
      beneficiaries: Record<string, boolean>[];
    };
    covers: Record<string, boolean>[];
    costs: Record<string, boolean>;
    expatDetails: Record<string, boolean>;
  };
}

export const getProposalPageFormState = (
  summary?: IProposalDetailsSummary,
  covers: IProposalCover[] = [],
  policyPersons?: IPolicyPersons
): IProposalPageFormState => ({
  values: {
    policyPersons,
    covers: covers,
    costs: {
      policyCost: convertToFixedNumber(summary?.PricingOption.PolicyCost),
      chargesPercentage: numberToPercentage(
        summary?.PricingOption.ChargesPercentage
      ),
      tpaFeesType: summary?.PricingOption.TPAFeesType,
      tpaFeesAmount: convertToFixedNumber(summary?.PricingOption.TPAFeesAmount),
      tpaFeesPercentage: numberToPercentage(
        summary?.PricingOption.TPAFeesPercentage
      ),
      tpaFeesApplicableOn: summary?.PricingOption.TPAFeesApplicableOn,
    },
    expatDetails: {
      medicalClass: summary?.PolicyExpat?.MedicalClass?.Code,
      continuity: summary?.PolicyExpat?.Continuity,
      continuityNumber: summary?.PolicyExpat?.ContinuityNumber,
      exclusion: summary?.PolicyExpat?.Exclusion,
      remarks: summary?.PolicyExpat?.Remarks,
    },
  },
  errors: {
    policyPersons: {
      payer: {
        payerId: "",
        payerAddress: "",
        ownerIsDifferent: "",
      },
      owner: {
        ownerId: "",
        ownerAddress: "",
      },
      insured:
        [policyPersons?.insured || []]?.length > 0
          ? policyPersons?.insured.map((v) => ({
              id: "",
              order: "",
              address: "",
            }))
          : [
              {
                id: "",
                order: "",
                address: "",
              },
            ],
      beneficiaries:
        [policyPersons?.beneficiaries || []]?.length > 0
          ? policyPersons?.beneficiaries.map((v) => ({
              id: "",
              order: "",
              address: "",
              percentage: "",
            }))
          : [
              {
                id: "",
                order: "",
                address: "",
                percentage: "",
              },
            ],
    },
    covers: covers.map((c) => ({
      isMain: "",
      isMandatory: "",
      coverPremiumType: "",
      coverPremiumPercentage: "",
      coverPremiumValue: "",
      currency: "",
      coverSumInsured: "",
      sumInsuredIsAdditive: "",
      excessOnClaimType: "",
      excessOnClaimAmount: "",
      excessOnClaimPercentage: "",
      excessOnClaimDays: "",
      coverEffectiveFrom: "",
      coverEffectiveTo: "",
    })),
    costs: {},
    expatDetails: {},
  },
  touched: {
    policyPersons: {
      payer: {
        payerId: false,
        payerAddress: false,
        ownerIsDifferent: false,
      },
      owner: {
        ownerId: false,
        ownerAddress: false,
      },
      insured:
        [policyPersons?.insured || []]?.length > 0
          ? policyPersons?.insured.map((v) => ({
              id: false,
              order: false,
              address: false,
            }))
          : [
              {
                id: false,
                order: false,
                address: false,
              },
            ],
      beneficiaries:
        [policyPersons?.beneficiaries || []]?.length > 0
          ? policyPersons?.beneficiaries.map((v) => ({
              id: false,
              order: false,
              address: false,
              percentage: false,
            }))
          : [
              {
                id: false,
                order: false,
                address: false,
                percentage: false,
              },
            ],
    },
    covers: covers.map((_c) => ({
      isMain: false,
      isMandatory: false,
      coverPremiumType: false,
      coverPremiumPercentage: false,
      coverPremiumValue: false,
      currency: false,
      coverSumInsured: false,
      sumInsuredIsAdditive: false,
      excessOnClaimType: false,
      excessOnClaimAmount: false,
      excessOnClaimPercentage: false,
      excessOnClaimDays: false,
      coverEffectiveFrom: false,
      coverEffectiveTo: false,
    })),
    costs: {},
    expatDetails: {},
  },
});
