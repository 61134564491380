import { IFile } from '../../models/file';
export interface IAccountImportDrawerProps {
    open?: boolean;
    onClose: () => void;
    onSuccess: () => void;
  }
  
   export interface IAccountImportInfo {
    document: IFile;
    company: string;
  }

  export class AccountObject {
    AccountName: string
    AccountID: string
    AccountDescription: string
    IsSubAccount: boolean
    ParentAccount: string
    OpeningBalance:number
    DebitOrCredit: string
    AccountType: string
    AccountCurrency: string
  }