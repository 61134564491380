import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';
import { FileType } from '../../models/file';
import {IAccountImportInfo} from './index'

export const initialValues : IAccountImportInfo = {
    company: '',
    document:{
        id: '',
        location: '',
        path: '',
        fileName: '',
        length: undefined,
        contentType: '',
      },
};


export const inputs = (values: Record<string, any> = initialValues, lovs: Record<string, Record<string, string>>, downloadFuction: () => any) : Record<string, DynamicFormInputType> => ({
    company: {
    name: 'company',
    title: 'Company',
    type: FormInputTypes.chips,
    value: !isEmpty(values.company)
      ? values.company
      : [],
    selectOptions: lovs?.relatedCompanies,
    required: true,
    multiple: false,
  },
  document: {
    name: 'document',
    title: 'Chart of Accounts',
    type: FormInputTypes.fileuploader,
    value: null,
    required: true,
    disabled: false,
    maxFileSizeInMB: 200,
    allowedFileTypes: [FileType.xls, FileType.xlsx],
    imageUrl:'',
    downloadLinks: [{
      displayName: "Download Template",
      downloadUrl: "example",
      action: downloadFuction,
    }]
  }
});
