import { makeStyles } from 'tss-react/mui';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import EnhancedInputsWrapper from './EnhancedInputsWrapper';
import { isEmpty, isValidNumber } from '../../utils/validationUtils';
import { MAIN_ONE_THEME, contentFontFamilyRegular } from '../../constants';
import { IEnhancedFormInputBaseProps } from '.';
import { NumericFormat } from 'react-number-format';

export interface IEnhancedFormattedNumberInputProps
  extends IEnhancedFormInputBaseProps,
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    > {
  name: string;
  title: string;
  description?: string;
  error?: string;
  material?: boolean;
  maxDecimalPercision?: number;
  value: string | number;
  inline?: boolean;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  inputClass?: string;
  wrapperClass?: string;
  hidden?: boolean;
  minValue?: number;
  maxValue?: number;
  isEditable?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  input: {
    fontSize: 13,
    lineHeight: '15px',
    height: 35,
    padding: '0 15px',
    margin: '0 auto',
    backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
    border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    boxSizing: 'border-box',
    borderRadius: '5px',
    '&:disabled': {
      cursor: 'not-allowed',
      color: 'rgba(0, 0, 0, 0.5)!important',
      opacity: `0.5 !important`,
      textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
    },
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.20)',
      fontStyle: 'italic',
    },
    fontFamily: contentFontFamilyRegular,
    '&:focus': {
      outline: 'none',
    },
  },
  materialInput: {
    backgroundColor: 'unset',
    border: 'none',
    borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
    color: MAIN_ONE_THEME.palette.primary2.main,
    margin: '0 auto',
    borderRadius: 0,
    padding: '10px 15px 4px 4px',
    fontFamily: contentFontFamilyRegular,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
  },
  currencyInput: {
    fontFamily: contentFontFamilyRegular,
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px !important`,
  },
  inputError: {
    borderColor: MAIN_ONE_THEME.palette.error.main,
    outlineColor: MAIN_ONE_THEME.palette.error.main,
    fontFamily: contentFontFamilyRegular,
  },
  inlineContainer: {
    position: 'relative',
    fontFamily: contentFontFamilyRegular,
  },
  inputWrapper: {
    height: '88.6px',
  },
}));

const EnhancedFormattedNumberInput: React.FC<
  IEnhancedFormattedNumberInputProps
> = ({
  inline,
  className,
  inputClass,
  error,
  name,
  title,
  description,
  value,
  style,
  placeholder,
  disabled,
  maxDecimalPercision = 2,
  material,
  onChange,
  onFocus,
  onBlur,
  wrapperClass,
  customStyles,
  step,
  hidden = false,
  minValue,
  maxValue,
  isEditable = false,
}) => {
  const { classes, cx } = useStyles();

  const handleValueChange = useCallback(
    (values: any) => {
      let newValue = parseFloat(values.value);
      if (isValidNumber(minValue) && newValue < minValue) {
        newValue = minValue;
      }
      if (isValidNumber(maxValue) && newValue > maxValue) {
        newValue = maxValue;
      }
      const syntheticEvent = {
        target: {
          name,
          value: isNaN(newValue) ? '' : newValue.toString(),
        },
      } as React.ChangeEvent<HTMLInputElement>;
      onChange(syntheticEvent);
    },
    [minValue, maxValue, onChange, name]
  );

  const handleBlur = useCallback(
    (e: any) => {
      let newValue = parseFloat(e.target.value.replace(/,/g, ''));
      if (isValidNumber(minValue) && newValue < minValue) {
        newValue = minValue;
      }
      if (isValidNumber(maxValue) && newValue > maxValue) {
        newValue = maxValue;
      }
      const syntheticEvent = {
        ...e,
        target: {
          name: e.target.name,
          value: isNaN(newValue) ? '' : newValue.toString(),
        },
      } as React.FocusEvent<HTMLInputElement>;
      onBlur(syntheticEvent);
    },
    [minValue, maxValue, onBlur]
  );

  const renderInput = () => {
    return (
      <NumericFormat
        className={cx(
          classes.input,
          inline ? className : inputClass,
          material ? classes.materialInput : classes.currencyInput,
          error ? classes.inputError : undefined
        )}
        style={{
          width: '100%',
        }}
        title={disabled && !isEmpty(value) ? value.toString() : title}
        id={name}
        name={name}
        aria-invalid={!!error}
        aria-describedby={`errorMsg-${name}`}
        value={value}
        onValueChange={handleValueChange}
        onBlur={handleBlur}
        onFocus={onFocus}
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={maxDecimalPercision}
        disabled={disabled}
        placeholder={placeholder}
        step={step}
        hidden={hidden}
        min={minValue}
        max={maxValue}
        data-testid={name}
      />
    );
  };

  return inline ? (
    <span className={classes.inlineContainer}>{renderInput()}</span>
  ) : (
    <EnhancedInputsWrapper
      title={title}
      description={description}
      error={error}
      name={name}
      className={clsx(classes.inputWrapper, className)}
      style={style}
      childrenClass={wrapperClass}
      customStyles={customStyles}
      hidden={hidden}
    >
      <div style={{ position: 'relative' }}>{renderInput()}</div>
    </EnhancedInputsWrapper>
  );
};

export default EnhancedFormattedNumberInput;
