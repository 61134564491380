import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../utils/graph-utils";

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.SalesforceManagement.queries.getBusinessPartnerList.items.forEach(
    (obj: IGenericBusinessPartnersTableData, i: number) => {
      list[i] = {
        salesforceManagement_BusinessPartner_Id:
          obj.salesforceManagement_BusinessPartner_Id,
        salesforceManagement_BusinessPartner_Code:
          obj.salesforceManagement_BusinessPartner_Code,
        person_FullName:
          obj.salesforceManagement_BusinessPartner_PersonID.views
            .SalesforceManagement_all.properties.FullName,
        salesforceManagement_BusinessPartner_Type:
          obj.salesforceManagement_BusinessPartner_Type?.Title,
        person_Email:
          obj.salesforceManagement_BusinessPartner_PersonID.views
            .SalesforceManagement_all.properties.Email,
        salesforceManagement_BusinessPartner_RelatedCompany:
          obj.salesforceManagement_BusinessPartner_RelatedCompany.Title,
        person_PhoneDetails:
          obj.salesforceManagement_BusinessPartner_PersonID.views
            .SalesforceManagement_all.properties.PhoneDetails?.PhoneNumber,
        salesforceManagement_BusinessPartner_Status: capitalizeFirstLetter(
          obj.salesforceManagement_BusinessPartner_Status?.replaceAll("_", " ")
        ),
        salesforceManagement_BusinessPartner_createdOn:
          obj.salesforceManagement_BusinessPartner_createdOn,
        salesforceManagement_BusinessPartner_modifiedOn:
          obj.salesforceManagement_BusinessPartner_modifiedOn,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data.SalesforceManagement.queries.getBusinessPartnerList?.paging
        ?.pageSize,
    pageNumber:
      data.SalesforceManagement.queries.getBusinessPartnerList?.paging
        ?.pageNumber,
    totalCount:
      data.SalesforceManagement.queries.getBusinessPartnerList?.paging
        ?.totalCount,
  };
}

export function toLookups(data: any) {
  let lovs: Record<string, Record<string, string>> = {
    type: {},
    status: {},
    company: {},
  };

  const yesOrNoArray = [
    { Id: "true", Title: "Yes" },
    { Id: "false", Title: "No" },
  ];

  lovs.type = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.businessPartnerTypes
  );
  lovs.company = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.relatedCompanies
  );
  lovs.status = enumListAsRecordObject(
    data?.SalesforceManagement_BusinessPartnerStatuses?.enumValues
  );
  lovs.isCompany = lookupListAsRecordObject(yesOrNoArray);
  lovs.isLocal = lookupListAsRecordObject(yesOrNoArray);
  lovs.isRegistered = lookupListAsRecordObject(yesOrNoArray);

  return lovs;
}
