import React, { useEffect, useState } from "react";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { IProposalDetailsPageProps } from ".";
import { makeStyles } from "tss-react/mui";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  getNonSelectedPlanCover,
  // getNonSelectedPlanCover,
  getProposalDetails,
} from "./queries";
import { MAIN_ONE_THEME } from "../../constants";
import Loader from "../../components/Loader";
import { useParams } from "react-router-dom";
import EnhancedStepper from "../../components/common/EnhancedStepper";
import { getProposalStatus, LookupToList } from "./utils";
import { gridWidthToPercentage } from "../../utils/grid-utils";
import {
  convertToDetailsSummary,
  convertToPolicyPersons,
  convertToPolicyPlanProposalCovers,
  convertToProposalCover,
  getPlanCoversLov,
  IProposalDetailsPageResponse,
  IProposalDetailsSummary,
} from "./custom-widgets/index2";
import PolicyDetailsEntityInfo from "./custom-widgets/PolicyDetailsEntityInfo";
import { PolicyDetailsWidget } from "./custom-widgets/PolicyDetailsWidget";
import {
  getProposalPageFormState,
  IProposalCover,
  IProposalPageFormState,
} from "./custom-widgets/form";
import PolicyCoversRepeaterWidget from "./custom-widgets/PolicyCoversRepeaterWidget";
import PolicyCostChargesWidget from "./custom-widgets/PolicyCostChargesWidget";
import PolicyInsuredWidget from "./custom-widgets/PolicyInsuredWidget";
import ProposalMotorDetailsWidget from "./custom-widgets/ProposalMotorDetailsWidget";
import { validateProposalPageForm } from "./validation";
import {
  convertProposalPageStateToSubmission,
  IProposalDetailsPageSubmissionModel,
} from "./submission";
import {
  saveAndCalculationProposalPage,
  saveProposalDraftMotor,
} from "./mutation";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import PolicyProposalQuoteWidget from "./custom-widgets/PolicyProposalQuoteWidget";
import PreviewProposalClauseModal from "./modals/PreviewProposalClauseModal";
import SimpleActionBar from "../../components/SimpleActionBar";
import { IEnhancedMenuItem } from "../../components";
import { isEmpty, isValidNumber } from "../../utils/validationUtils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { ComissionProposalWidget } from "./custom-widgets/ComissionProposalWidget";
import CloseProposalDrawer from "../close-proposal-drawer/CloseProposalDrawer";
import {
  downloadFileFromUrl,
  generateDownloadLink,
} from "../../utils/file-utils";
import { downloadProposalDocument, issuePolicyMotor } from "./modals/queries";
import { IConfirmation } from "../../redux/confirmation/types";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import { useAppDispatch } from "../../redux/hooks";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "95%",
    width: "95%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  buttonsContainer: {
    width: "95%",
    margin: "10px auto 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  widgetTopMargin: {
    marginTop: "1em",
  },
}));

const ProposalDetailsPage: React.FC<IProposalDetailsPageProps> = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const [closeProposalDrawerOpen, setCloseProposalDrawerOpen] =
    useState<boolean>(false);
  const [issueModalOpen, setIssueModalOpen] = useState<boolean>(false);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const entityId = params.id;

  const [pageState, onPageStateUpdate] = useState<IProposalPageFormState>(
    getProposalPageFormState()
  );

  const [data, setData] = useState<IProposalDetailsSummary>();

  // const ownerIsDifferent: boolean =
  //   pageState?.values?.policyPersons?.payer?.ownerIsDifferent || false;

  const [coversList, setCoversList] = useState<IProposalCover[]>([]);

  const [planCoversList, setPlanCoversList] = useState<
    Record<string, IProposalCover>
  >({});

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    planCovers: {},
  });

  const [saveAndCalculationAction] = useMutation(
    saveAndCalculationProposalPage()
  );

  const [saveAsDraftAction] = useMutation(saveProposalDraftMotor());

  const [issuePolicyMotorAction] = useMutation(issuePolicyMotor());

  const [downloadAction] = useMutation(downloadProposalDocument());

  useEffect(() => {
    initialize();
  }, []);

  const isClosed =
    data?.Status === "CLOSED_WON" || data?.Status === "CLOSED_LOST";

  const disabledPage = isClosed || submitting || loading;

  const [getProposalDetailsLazy] = useLazyQuery(getProposalDetails());

  const [getNonSelectedPlanCoverLazy] = useLazyQuery(getNonSelectedPlanCover());

  const getPlanCoverDetails = async (
    planId: string,
    planCoverIds: string[]
  ) => {
    const planCoverList: { data: any } = await getNonSelectedPlanCoverLazy({
      variables: {
        selectedPlanID: planId,
        selectedPlanCoverIDs: planCoverIds,
      },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });

    return planCoverList.data;
  };

  const initialize = async () => {
    setLoading(true);
    const result: { data: IProposalDetailsPageResponse } =
      await getProposalDetailsLazy({
        variables: { id: entityId },
        errorPolicy: "all",
        fetchPolicy: "no-cache",
      });

    const summaryData = convertToDetailsSummary(result.data);

    const initialCovers = convertToPolicyPlanProposalCovers(result.data);
    const initialPersons = convertToPolicyPersons(result.data);

    const planCoverDetails = await getPlanCoverDetails(
      summaryData.PlanID.Id,
      []
    );

    const lists = LookupToList(result.data);
    setLovs({
      currency: lists.currency,
      plateCodes: lists.plateCodes,
      usageTypes: lists.usageTypes,
      bodyTypes: lists.bodyTypes,
      engineTypes: lists.engineTypes,
      brands: lists.brands,
      colors: lists.colors,
      planCovers: getPlanCoversLov(planCoverDetails),
    });

    setPlanCoversList(
      convertToProposalCover(
        planCoverDetails,
        summaryData.PolicyCurrency?.Code,
        summaryData.PricingOption.PlanToPolicyRate,
        summaryData.PolicyEffectiveDate,
        summaryData.PolicyExpiryDate
      )
    );
    setCoversList(initialCovers);
    onPageStateUpdate(
      getProposalPageFormState(summaryData, initialCovers, initialPersons)
    );
    setData(summaryData);
    setLoading(false);
  };

  const renderLeftSection = () => {
    if (data) {
      return <PolicyDetailsEntityInfo data={data} />;
    }

    return <></>;
  };

  const onSubmit = async () => {
    const validationResult = validateProposalPageForm(data, pageState.values);

    if (!submitting) {
      setSubmitting(true);
      const newPageState = {
        ...pageState,
        errors: validationResult.errors,
        touched: validationResult.touched,
      };

      onPageStateUpdate(newPageState);
      if (validationResult.isValid) {
        const dataToSubmit = convertProposalPageStateToSubmission(
          data,
          pageState
        );
        const result = await saveAndCalculationAction({
          variables: { ...dataToSubmit },
          errorPolicy: "all",
        });

        if (isEmpty(result.errors)) {
          // setDeactivateButtonState("success");
          toast.success(
            <ToastSuccessMessage>
              {"Proposal successfully saved."}
            </ToastSuccessMessage>
          );

          initialize();
        } else {
          // setRegenerateButtonState(undefined);
          toast.error(
            <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
          );
        }
      } else {
        toast.error(<ToastErrorMessage>Incomple Form</ToastErrorMessage>);
      }
      setSubmitting(false);
    }
  };

  const onSaveDraft = async () => {
    if (!submitting) {
      setSubmitting(true);
      const dataToSubmit = convertProposalPageStateToSubmission(
        data,
        pageState
      ) as Partial<IProposalDetailsPageSubmissionModel>;

      if (isEmpty(dataToSubmit.policyPersonInput[0]?.personId)) {
        dataToSubmit.policyPersonInput = [];
      }

      const result = await saveAsDraftAction({
        variables: { ...dataToSubmit },
        errorPolicy: "all",
      });

      if (isEmpty(result.errors)) {
        // setDeactivateButtonState("success");
        toast.success(
          <ToastSuccessMessage>
            {"Proposal successfully saved."}
          </ToastSuccessMessage>
        );

        initialize();
      } else {
        // setRegenerateButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
      }
    }
    setSubmitting(false);
  };

  const onIssuePolicyAction = async () => {
    if (!submitting) {
      setSubmitting(true);
      const dataToSubmit = {
        entityId: data.Id,
        clauseText: data.ClauseText || "",
      };
      const result = await issuePolicyMotorAction({
        variables: { ...dataToSubmit },
        errorPolicy: "all",
      });

      if (isEmpty(result.errors)) {
        // setDeactivateButtonState("success");
        toast.success(
          <ToastSuccessMessage>
            {"Policy successfully issued."}
          </ToastSuccessMessage>
        );

        const filesInfo = getFileDetailsList(result.data);

        for (const fileInfo of filesInfo) {
          const downloadLink = generateDownloadLink(
            fileInfo.fileId,
            fileInfo.EntityTypeId,
            fileInfo.EntityId,
            fileInfo.EntityViewId,
            fileInfo.PropertyId
          );
          await downloadFileFromUrl(downloadLink, fileInfo.fileName);
        }
        setSubmitting(false);
        initialize();
      } else {
        // setRegenerateButtonState(undefined);
        setSubmitting(false);
        toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
      }
    }
  };

  const downloadDocument = async () => {
    if (!submitting) {
      setSubmitting(true);
      const dataToSubmit = {
        entityId: data.Id,
        clauseText: data.ClauseText,
      };
      const result = await downloadAction({
        variables: { ...dataToSubmit },
        errorPolicy: "all",
      });
      if (isEmpty(result.errors)) {
        // setDeactivateButtonState("success");
        const fileInfo = getFileDetails(result.data);
        const downloadLink = generateDownloadLink(
          fileInfo.fileId,
          fileInfo.EntityTypeId,
          fileInfo.EntityId,
          fileInfo.EntityViewId,
          fileInfo.PropertyId
        );

        await downloadFileFromUrl(downloadLink, fileInfo.fileName);

        toast.success(
          <ToastSuccessMessage>
            {"Policy sample successfully downloaded."}
          </ToastSuccessMessage>
        );
        // onSuccess();
      } else {
        // setRegenerateButtonState(undefined);
        setSubmitting(false);
        toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
      }
    }

    setSubmitting(false);
  };

  const renderActions = () => {
    const actions: IEnhancedMenuItem[] = [
      {
        title: "Save Draft",
        onClick: () => {
          onSaveDraft();
        },
        isSecondary: false,
        backgroundColor: "#000",
        color: "#fff",
        disabled: disabledPage,
        hidden:
          isClosed ||
          data?.Status?.toLowerCase() !== "new" ||
          !(
            isValidNumber(data?.LineId?.ExternalCode) &&
            [51, 50, 4].includes(Number(data.LineId.ExternalCode))
          ),
      },
      {
        title: "Calculate & Save",
        onClick: () => {
          onSubmit();
        },
        isSecondary: false,
        backgroundColor: "#000",
        color: "#fff",
        disabled: disabledPage,
        hidden:
          isClosed ||
          !(
            isValidNumber(data?.LineId?.ExternalCode) &&
            [51, 50, 4].includes(Number(data.LineId.ExternalCode))
          ),
      },
      {
        title: "Issue Policy",
        onClick: () => {
          if (Number(data?.LineId?.ExternalCode) === 50) {
            const confirmation: IConfirmation = {
              open: true,
              title: "Confirmation",
              message: `Are you sure you want to issue the selected policy?`,
              callback: async () => {
                onIssuePolicyAction();
              },
              submitButtonText: "Yes",
              cancelButtonText: "No",
            };
            dispatch(OpenConfirmationAction(confirmation));
          } else {
            setIssueModalOpen(true);
          }
        },
        isSecondary: false,
        hidden:
          isClosed ||
          !(
            data?.Status?.toLowerCase() === "in_progress" &&
            isValidNumber(data?.LineId?.ExternalCode) &&
            [51, 50, 4].includes(Number(data.LineId.ExternalCode))
          ),
        backgroundColor: "#000",
        color: "#fff",
        disabled: disabledPage,
      },
      {
        title: "Download Policy Sample",
        onClick: () => {
          downloadDocument();
        },
        isSecondary: Number(data?.LineId?.ExternalCode) === 50,
        hidden:
          isClosed ||
          !(
            data?.Status?.toLowerCase() === "in_progress" &&
            isValidNumber(data?.LineId?.ExternalCode) &&
            [50].includes(Number(data.LineId.ExternalCode))
          ),
        backgroundColor: "#000",
        color: "#fff",
        disabled: disabledPage,
      },
      {
        title: "Close Proposal",
        onClick: () => {
          setCloseProposalDrawerOpen(true);
        },
        isSecondary: Number(data?.LineId?.ExternalCode) === 50,
        backgroundColor: "#000",
        color: "#fff",
        disabled: disabledPage,
        hidden: isClosed,
      },
    ];

    return <SimpleActionBar items={actions} />;
  };

  const renderMainChildren = () => {
    if (data) {
      // const isClosed = status === "closedwon" || "closedlost";
      const isProposalWon = data.Status?.toUpperCase() === "CLOSED_WON";
      const isProposalUserNew = data.Status?.toLowerCase() === "new";
      const steps = isProposalUserNew
        ? ["New", "In Progress", "Closed Won"]
        : ["New", "In Progress", isProposalWon ? "Closed Won" : "Closed Lost"];

      return (
        <>
          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div style={{ width: `${gridWidthToPercentage(8)}%` }}>
                <EnhancedStepper
                  activeStep={getProposalStatus(steps, data.Status)}
                  steps={steps}
                  buttonTitle="Calculate & Save"
                  buttonOnClick={() => { }}
                  className={classes.stepper}
                />
              </div>
              <div style={{ width: `${gridWidthToPercentage(4)}%` }}>
                {renderActions()}
              </div>
            </div>
            <div
              style={{
                marginTop: "18px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  width: `${gridWidthToPercentage(8)}%`,
                  padding: "0px 1em 1em 0",
                }}
              >
                <PolicyDetailsWidget data={data} />
                <PolicyCoversRepeaterWidget
                  defaultPlanCoversList={planCoversList}
                  pageState={pageState}
                  disabledForm={disabledPage}
                  status={data.Status}
                  allowPlanCoverEdits={data.BusinessUserID.AllowPlanCoverEdits}
                  onPageStateUpdate={onPageStateUpdate}
                  lovs={lovs}
                  data={data}
                />
                <PolicyCostChargesWidget
                  pageState={pageState}
                  disabledForm={disabledPage}
                  status={data.Status}
                  editableLine={data.BusinessUserID?.EditableLineChargesIDs?.includes(
                    data?.LineId.Id
                  )}
                  line={data?.LineId.ExternalCode?.toString() || ""}
                  onPageStateUpdate={onPageStateUpdate}
                />
                {/* <WidgetPaper style={{ marginTop: "1em" }}>
                  <WidgetSection
                    title="Payer & Owner"
                    hasTitleSpecificDesign={false}
                    classes={
                      {
                        // container: classes.section,
                      }
                    }
                    useSeparator={false}
                  >
                  </WidgetSection>
                </WidgetPaper> */}

                <PolicyInsuredWidget
                  pageState={pageState}
                  onPageStateUpdate={onPageStateUpdate}
                  lovs={lovs}
                  disabledForm={disabledPage}
                  maxNumber={data.PlanID.MaxNbInsured}
                  data={data}
                />

                <ProposalMotorDetailsWidget
                  defaultPlanCoversList={planCoversList}
                  pageState={pageState}
                  status={data.Status}
                  allowPlanCoverEdits={data.BusinessUserID.AllowPlanCoverEdits}
                  onPageStateUpdate={onPageStateUpdate}
                  lovs={lovs}
                  data={data}
                  disabledForm={disabledPage}
                />
                {closeProposalDrawerOpen && (
                  <CloseProposalDrawer
                    open={closeProposalDrawerOpen}
                    onClose={() => {
                      setCloseProposalDrawerOpen(false);
                    }}
                    onSuccess={() => {
                      initialize();
                      setCloseProposalDrawerOpen(false);
                    }}
                    id={entityId}
                  />
                )}
              </div>
              <div
                style={{
                  width: `${gridWidthToPercentage(4)}%`,
                  padding: "0px",
                }}
              >
                <PolicyProposalQuoteWidget data={data} covers={coversList} />
                {data?.Status && data?.Status?.toLowerCase() !== "new" && (
                  <ComissionProposalWidget
                    className={classes.widgetTopMargin}
                    data={data}
                    onUpdateSuccess={() => {
                      initialize();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      );
    }

    return <></>;
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <StaticLayout
        name={"Proposal Details"}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
      />
      {issueModalOpen && (
        <PreviewProposalClauseModal
          data={data}
          onSuccess={() => {
            setIssueModalOpen(false);
            initialize();
          }}
          onClose={() => setIssueModalOpen(false)}
        />
      )}
    </>
  );
};

export default ProposalDetailsPage;

function getFileDetailsList(data: any): FileDetail[] {
  const fileDetails: FileDetail[] = [];

  const items =
    data.production.entities.proposal.production.issuePolicyMotor.Items;
  for (const item of items) {
    const fileDetail: FileDetail = {
      EntityTypeId: item.EntityTypeId,
      EntityId: item.EntityId,
      EntityViewId: item.EntityViewId,
      PropertyId: item.PropertyId,
      fileId: item.File.id,
      fileName: item.File.fileName,
    };
    fileDetails.push(fileDetail);
  }

  return fileDetails;
}

function getFileDetails(data: any) {
  const downloadPolicySampleMotor =
    data.production.entities.proposal.production.downloadPolicySampleMotor;
  const { EntityTypeId, EntityId, EntityViewId, PropertyId, File } =
    downloadPolicySampleMotor;
  const { id: fileId, fileName } = File;

  return {
    EntityTypeId,
    EntityId,
    EntityViewId,
    PropertyId,
    fileId,
    fileName,
  };
}

type FileDetail = {
  EntityTypeId: string;
  EntityId: string;
  EntityViewId: string;
  PropertyId: string;
  fileId: string;
  fileName: string;
};
