import React from 'react';
import clsx from 'clsx';
import { IEnhancedCommonProps, IEnhancedMenuItem } from '.';
import EnhancedButton from './EnhancedButton';
import { makeStyles } from 'tss-react/mui';
import SimpleEnhancedMenu from './enhanced-table/SimpleEnhancedMenu';

export interface IActionBarProps extends IEnhancedCommonProps {
  buttons?: JSX.Element;
  items?: IEnhancedMenuItem[];
  disabled?: boolean;
  classes?: { container?: string; button?: string };
}

const useStyles = makeStyles()(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  button: {
    margin: '0 0 0 5px !important',
  },
  simpleActionBar: {
    marginLeft: "5px"
  }
}),
);

const SimpleActionBar: React.FC<IActionBarProps> = ({
  buttons,
  className,
  style,
  items = [],
  classes = {},
}) => {
  const actionClasses = useStyles().classes;

  const primaryActions = items.filter(i => !i.isSecondary && !i.hidden);

  const secondaryActions = items.filter(i => i.isSecondary && !i.hidden);

  return (
    <section
      title={"Actions"}
      className={clsx(actionClasses.container, classes.container, className)}
      style={style}
    >
      {!!primaryActions && primaryActions.filter(i => !i.hidden).map(item => (
        <EnhancedButton
          key={item.title}
          className={clsx(actionClasses.button, classes.button)}
          disabled={item.disabled}
          color={item.color}
          backgroundColor={item.backgroundColor}
          onClick={item.onClick}
        >
          {item.title}
        </EnhancedButton>
      ))}
      {
        secondaryActions.length > 0 && <SimpleEnhancedMenu className={actionClasses.simpleActionBar} title="Actions" items={secondaryActions} />
      }
      {buttons}
    </section>
  );
};

export default SimpleActionBar;
