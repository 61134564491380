import { FormInputTypes } from "../../DynamicForm";
import {
	EnhancedTableHeader,
	EnhancedTableHeaderType,
} from "../../components/enhanced-table";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import { IAbstractRecord } from "../../models";

export const headers: Record<string, EnhancedTableHeader> = {
	businessPartnerName: {
		name: "businessPartnerName",
		title: "Business Partner",
		type: EnhancedTableHeaderType.Clickable,
		callback: () => {},
	},
	totalPoliciesIssued: {
		name: "totalPoliciesIssued",
		title: "Total Policies Issued",
		type: EnhancedTableHeaderType.Text,
	},
	totalBalanceBasicCurrency: {
		name: "totalBalanceBasicCurrency",
		title: "Total Balance (Basic Currency)",
		type: EnhancedTableHeaderType.Currency,
	},
	totalBalanceSecondaryCurrency: {
		name: "totalBalanceSecondaryCurrency",
		title: "Total Balance (Secondary Currency)",
		type: EnhancedTableHeaderType.Currency,
	},
};

export function filterSectionsContent(
	lovs: Record<string, Record<string, string>>,
	filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
	const newFilterSections: IListingFilterWidgetSection[] = [
		{
			title: "Filters",
			inputs: {
				company: {
					name: "company",
					title: "Company",
					type: FormInputTypes.chips,
					value: filterValues?.company || null,
					placeholder: "Company",
					triggerUpdate: true,
					selectOptions: { ...lovs.company },
					multiple: false,
					canClearSingleValueSelection: false,
				},
				businessPartner: {
					name: "businessPartner",
					title: "Business Partner",
					type: FormInputTypes.chips,
					value: filterValues?.businessPartner || null,
					placeholder: "Business Partner",
					triggerUpdate: true,
					selectOptions: { ...lovs.businessPartner },
					multiple: true,
					canClearSingleValueSelection: true,
					disabled: !filterValues?.company,
				},
			},
		},
	];
	return newFilterSections;
}
