import { ComponentProps } from "react";
import EnhancedCheckbox from "../components/EnhancedCheckbox";
import { EnhancedButtonStatus } from "../components/common/EnhancedButton";
import { FileType } from "../models/file";
import { ValidatorTypes } from "./validators";

export interface IFormCommonInputProps {
  name: string;
  title: string;
  type: FormInputTypes;
  placeholder?: string;
  value?: any;
  preselectedValues?: any;
  error?: string;
  regex?: RegExp;
  regexError?: string;
  required?: boolean;
  disabled?: boolean;
  material?: boolean;
  controlled?: boolean;
  validators?: ValidatorTypes[];
  description?: string;
  triggerUpdate?: boolean;
  subLink?: string;
  onSubLinkClick?: () => void;
  imageUrl?: string;
  customDisplayRule?: (
    optionValue: string,
    values: Record<string, any>
  ) => boolean;
  customStyles?: {
    containerStyles?: any;
    labelStyles?: string;
    inputStyles?: any;
    errorStyles?: any;
    selectOptionStyles?: any;
  };
  hidden?: boolean;
  order?: number;
  areNextToEachOther?: boolean;
  nextToEachOtherIndex?: number;
  allowDeleteValue?: boolean;
  popUpStyling?: boolean;
  canClearDate?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
  customValidator?: (values: Record<string, any>) => string;
  
  conditionalDisable?: (values?: Record<string, any>) => boolean;
  conditionalHidden?: (values?: Record<string, any>) => boolean;
  conditionalRequired?: (values: Record<string, any>) => boolean;
}

export interface IFormTextDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.text;
  minCharacters?: number;
  maxCharacters?: number;
  multiline?: boolean;
  minRows?: number;
  maxRows?: number;
  onChange?: (value: any) => void;
  isEditor?: boolean;
  addButton?: boolean;
}

export interface IFormPasswordDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.password;
  includePasswordHint?: boolean;
  includePasswordVisibility?: boolean;
  includeCapsLockCheck?: boolean;
  autoCompelte?: string;
}
export interface IFormDateDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.date;
  maxDate?: Date;
  minDate?: Date;
  dateFormat?: string;
  onChange?: (value: any) => void;
  canClearDate?: boolean;
}

export interface IFormDateRangeDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.daterange;
  maxDate?: Date;
  minDate?: Date;
}

export interface IFormFileImageDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.imageFile;
  allowedFileTypes?: FileType[];
  maxFileSizeInMB?: number;
  preview?: boolean;
  editMode?: boolean;
  fileName?: string;
  widthImageRatio?: number;
  heightImageRatio?: number;
  imageUrl?: string;
}

export interface IFormNumberDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.number;
  minNumber?: number;
  maxNumber?: number;
  hasBetweenValidation?: boolean;
  onChange?: (value: any) => void;
  isYear?: boolean;
  minYear?: number;
  maxYear?: number;
  hasPrefix?: boolean;
  prefixValue?: string;
  maxDecimalPrecision?: number;
}

export interface IFormFormattedNumberDynamicProps
  extends IFormCommonInputProps {
  type: FormInputTypes.formattedNumber;
  minValue?: number;
  maxValue?: number;
  hasBetweenValidation?: boolean;
  onChange?: (value: any) => void;
  maxDecimalPercision?: number;
}

export interface IFormSelectDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.select;
  searchable?: boolean;
  selectOptions?: Record<string, string>;
  selectOrder?: string[];
  onSelect?: (selectedOption: string) => void;
}

export interface IFormSwitchDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.switch;
  information?: string;
  onChange?: (value: any) => void;
  className?: string;
}

export interface IFormMultipleSelectDynamicProps extends IFormCommonInputProps {
  minChoices?: number;
  maxChoices?: number;
  chip?: boolean;
  type: FormInputTypes.multiSelect;
  searchable?: boolean;
  selectOptions?: Record<string, string>;
  selectOrder?: string[];
}

export interface IFormChipsDynamicProps extends IFormCommonInputProps {
  value: string[] | string;
  preselectedValues?: string;
  options?: Record<string, any>;
  type: FormInputTypes.chips;
  minChoices?: number;
  maxChoices?: number;
  freeSolo?: boolean;
  selectOptions?: Record<string, string>;
  selectOrder?: string[];
  onBlurSaveNewData?: boolean;
  onSelect?: (selectedOptions: string) => void;
  multiple?: boolean;
  showSelectAll?: boolean;
  strongStyledOption?: boolean;
  onChange?: (value: any) => void;
  onFocus?: (value: any) => void;
  hasPagination?: boolean;
  itemsPerPage?: number;
  keyToClear?: string[];
  canClearSingleValueSelection?: boolean;
  hasTooltip?: boolean;
  oldBehavior?: boolean;
  tooltipText?: string;
}

export interface IFormPhoneNumberDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.phoneNumber;
  countriesToShow?: string[];
  disableDropDown?: boolean;
  editCountryCode?: boolean;
  defaultCountry?: string;
  customFormat?: Record<string, string>;
}

export interface IFileUploaderProps extends IFormCommonInputProps {
  type: FormInputTypes.fileuploader;
  allowedFileTypes?: FileType[];
  maxFileSizeInMB?: number;
  placeholder?: string;
  fileName?: string;
  iconUrl?: string;
  downloadLinks?: {
    displayName: string;
    downloadUrl: string;
    action?: () => Response;
  }[];
}

export interface IFormCheckboxDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.checkbox;
  className?: string;
  classes?: ComponentProps<typeof EnhancedCheckbox>["classes"];
}

export interface IFormCurrencyDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.currency;
  placeholder?: string;
  minNumber?: number;
  maxNumber?: number;
  onChange?: (value: any) => void;
}

export interface IFormPercentageDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.percentage;
  hasBetweenValidation?: boolean;
  onChange?: (value: any) => void;
}

export interface IFormLinkDynamicProps extends IFormCommonInputProps {
  type: FormInputTypes.link;
  onClick?: () => void;
}

export enum FormInputTypes {
  date = "date",
  password = "password",
  text = "text",
  imageFile = "imageFile",
  select = "select",
  number = "number",
  switch = "switch",
  multiSelect = "multiSelect",
  chips = "chips",
  phoneNumber = "phoneNumber",
  daterange = "daterange",
  fileuploader = "fileuploader",
  checkbox = "checkbox",
  currency = "currency",
  percentage = "percentage",
  formattedNumber = "formattedNumber",
  link = "link",
}

export type DynamicFormInputType =
  | IFormTextDynamicProps
  | IFormPasswordDynamicProps
  | IFormDateDynamicProps
  | IFormFileImageDynamicProps
  | IFormSelectDynamicProps
  | IFormNumberDynamicProps
  | IFormSwitchDynamicProps
  | IFormMultipleSelectDynamicProps
  | IFormChipsDynamicProps
  | IFormPhoneNumberDynamicProps
  | IFormDateRangeDynamicProps
  | IFileUploaderProps
  | IFormCheckboxDynamicProps
  | IFormCurrencyDynamicProps
  | IFormPercentageDynamicProps
  | IFormFormattedNumberDynamicProps
  | IFormLinkDynamicProps;

export interface IDynamicForm {
  title?: string;
  inputs: Record<string, DynamicFormInputType>;
  buttonText?: string;
  onSubmit: (values: Record<string, any>) => void;
  disableForm?: boolean;
  submitButtonState?: EnhancedButtonStatus;
  isSubmitButtonDisabled?: boolean;
  secondaryButton?: boolean;
  secondaryButtonText?: string;
  onClickSecondaryButton?: () => void;
  mainButtonBgColor?: string;
  dateFormat?: string;
  loadingFields?: Record<string, boolean>;
  customStyles?: {
    submitButtonStyles?: string;
  };
  onChange?: (
    fieldName: string,
    value: unknown,
    values: Record<string, any>,
    errors?: Record<string, string>,
    touched?: Record<string, boolean>,
  ) => void;
  isDrawer?: boolean;
  submitButtonSpace?: boolean;
  noteSection?: React.ReactNode;
  hasDoprdownSpecificBehavior?: boolean;
  itemsPerPage?: number;
  areNextToEachOther?: boolean;
  popUpStyling?: boolean;
  canClearDate?: boolean;
}

export interface IDynamicWithSectionsForm {
  title?: string;
  sections: Record<string, IDynamicSection>;
  buttonText?: string;
  onSubmit: (values: Record<string, any>) => void;
  disableForm?: boolean;
  submitButtonState?: EnhancedButtonStatus;
  isSubmitButtonDisabled?: boolean;
  inputs?: Record<string, DynamicFormInputType>;
  secondaryButton?: boolean;
  secondaryButtonText?: string;
  onClickSecondaryButton?: () => void;
  mainButtonBgColor?: string;
  dateFormat?: string;
  loadingFields?: Record<string, boolean>;
  customStyles?: {
    submitButtonStyles?: string;
  };
  onChange?: (
    fieldName: string,
    value: unknown,
    values: Record<string, any>,
    errors: Record<string, string>,
    touched: Record<string, boolean>,
  ) => void;
  hasDoprdownSpecificBehavior?: boolean;
  itemsPerPage?: number;
  areNextToEachOther?: boolean;
  popUpStyling?: boolean;
  canClearDate?: boolean;
}

export interface IDynamicSection {
  title?: string;
  inputs: Record<string, DynamicFormInputType>;
  specificTitleDesign?: () => JSX.Element;
  hasTitleSpecificDesign?: boolean;
}
