import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ILineDetails, ILineDetailsPageProps, initialValues } from ".";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import { MAIN_ONE_THEME, contentFontFamilyBold } from "../../constants";
import {
  headers,
  lineDetailsTabs,
  planHeaders,
  claimCoverHeaders,
  steps,
  policyCoverHeaders,
  clauseHeaders,
  getEntityView,
} from "./content";
import {
  activateLine,
  deactivateLine,
  getSubLineList,
  getLineDetailsInfo,
  getPlanList,
  getClaimCoverList,
  getPolicyCoverList,
  getClauseList,
} from "./queries";
import {
  entityToLineDetails,
  getLineStatus,
  mapToClaimCoversListingData,
  mapToClausesListingData,
  mapToLSublinesListingData,
  mapToPlansListingData,
  mapToPolicyCoversListingData,
} from "./utils";
import {
  capitalizeFirstCharacter,
  capitalizeFirstLetter,
} from "../../utils/formatting-utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingData } from "../../models/listing";
import WidgetSection from "../../components/common/WidgetSection";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import { makeStyles } from "tss-react/mui";
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from "../../components/enhanced-table";
import TabsLayout from "../../page-layout/tabs-layout/TabsLayout";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import SublineDrawer from "../subline-drawer/SublineDrawer";
import { getError } from "../../utils/graph-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { isEmpty } from "../../utils/validationUtils";
import { IConfirmation } from "../../redux/confirmation/types";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import PlanDrawer from "../plan-drawer/PlanDrawer";
import ClaimCoverDrawer from "../claim-cover-drawer/ClaimCoverDrawer";
import PolicyCoverDrawer from "../policy-cover-drawer/PolicyCoverDrawer";
import ClausePopUp from "../clause-popup/ClausePopUp";
import { TableSortOrder } from "../../utils/table-utils";
import _ from "lodash";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    ".Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& label": {
      "& span": {
        margin: "-2px 0",
      },
    },
  },
  sectionTitle: {
    padding: "-10x 10x!important",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "15px 20px 10px 0px",
    alignItems: "flex-start",
    display: "grid",
    gap: "5px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "19.5% 19.5% 19.5% 19% 16% 3% 3%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  repeaterField: {
    margin: "0px!important",
  },
  arField: {
    width: "96%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
    justifyContent: "flex-start",
  },
}));

interface ITableOrder {
  orderBy: string;
  orderDirection: "asc" | "desc";
}

const LineDetailsPage: React.FC<ILineDetailsPageProps> = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [sublineDrawerOpen, setSublineDrawerOpen] = useState<boolean>(false);
  const [planDrawerOpen, setPlanDrawerOpen] = useState<boolean>(false);
  const [claimCoverDrawerOpen, setClaimCoverDrawerOpen] =
    useState<boolean>(false);
  const [policyCoverDrawerOpen, setPolicyCoverDrawerOpen] =
    useState<boolean>(false);
  const [clausePopUpOpen, setClausePopUpOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [selectedSublineItems, setSelectedSublineItems] = useState<number>(0);
  const [selectedPlanItems, setSelectedPlanItems] = useState<number>(0);
  const [selectedClaimCoverItems, setSelectedClaimCoverItems] =
    useState<number>(0);
  const [selectedPolicyCoverItems, setSelectedPolicyCoverItems] =
    useState<number>(0);
  const [selectedClauseItems, setSelectedClauseItems] = useState<number>(0);
  //#endregion

  //#region component hooks and states
  const [lineDetailsInfo, setLineDetailsInfo] =
    useState<ILineDetails>(initialValues);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const [booted, setBooted] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [planKeywordSearch, setPlanKeywordSearch] = useState("");
  const [policyKeywordSearch, setPolicyKeywordSearch] = useState("");
  const [claimKeywordSearch, setClaimKeywordSearch] = useState("");
  const [clauseKeywordSearch, setClauseKeywordSearch] = useState("");
  let setSearchTimeout: NodeJS.Timeout;
  let setPlanSearchTimeout: NodeJS.Timeout;
  let setPolicySearchTimeout: NodeJS.Timeout;
  let setClaimSearchTimeout: NodeJS.Timeout;
  let setClauseSearchTimeout: NodeJS.Timeout;

  const [tableOrders, setTableOrders] = useState<Record<string, ITableOrder>>({
    subline: {
      orderBy: "",
      orderDirection: "asc",
    },
    plan: {
      orderBy: "",
      orderDirection: "asc",
    },
    policy: {
      orderBy: "",
      orderDirection: "asc",
    },
    cover: {
      orderBy: "",
      orderDirection: "asc",
    },
    clause: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [selectedSublineEntityId, setSelectedSublineEntityId] = useState("");
  const [selectedPlanEntityId, setSelectedPlanEntityId] = useState("");
  const [selectedClaimCoverEntityId, setSelectedClaimCoverEntityId] =
    useState("");
  const [selectedPolicyCoverEntityId, setSelectedPolicyCoverEntityId] =
    useState("");
  const [selectedClauseEntityId, setSelectedClauseEntityId] = useState("");

  const [actionClicked, setActionClicked] = useState(null);

  const entityId = params.id;

  const [selectedPlanSublineStatus, setSelectedPlanSublineStatus] =
    useState<string>(null);
  const [selectedPlanSublineID, setSelectedPlanSublineID] =
    useState<string>(null);

  //#endregion

  (
    headers.planConfigManagement_Subline_Name as IEnhanceTableHeaderClickable
  ).callback = (sublinePayload: any) => {
    navigate(
      "/plan/sublines/" + sublinePayload.columns.planConfigManagement_Subline_Id
    );
  };
  (
    planHeaders.planConfigManagement_Plan_Name as IEnhanceTableHeaderClickable
  ).callback = (planPayload: any) => {
    navigate("/plan/plans/" + planPayload.columns.planConfigManagement_Plan_Id);
  };
  (
    policyCoverHeaders.planConfigManagement_PolicyCover_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/plan/covers/" + payload.columns.planConfigManagement_PolicyCover_Id
    );
  };

  const isAdmin = user.userRoles.includes("Insurance-Admin");
  let sublineListingActions: IEnhancedTableMenuItem[] = [];
  sublineListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedSublineItems !== 1,
    },
  ];

  let planListingActions: IEnhancedTableMenuItem[] = [];
  planListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedPlanItems !== 1,
    },
  ];

  let claimCoverListingActions: IEnhancedTableMenuItem[] = [];
  claimCoverListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedClaimCoverItems !== 1,
    },
  ];

  let policyCoverListingActions: IEnhancedTableMenuItem[] = [];
  policyCoverListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedPolicyCoverItems !== 1,
    },
  ];

  let clauseListingActions: IEnhancedTableMenuItem[] = [];
  clauseListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedClauseItems !== 1,
    },
  ];

  //#region graphql hooks
  const [entityResultQuery] = useLazyQuery(getLineDetailsInfo());

  const [activateAction] = useMutation(activateLine(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getLineDetailsInfo()],
  });
  const [deactivateAction] = useMutation(deactivateLine(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getLineDetailsInfo(), getSubLineList(), getPlanList()],
  });

  sublineListingActions[0].onClick = () => {
    setActionClicked("new");
    setSublineDrawerOpen(true);
  };
  sublineListingActions[1].onClick = () => {
    setActionClicked("edit");
    setSublineDrawerOpen(true);
  };

  planListingActions[0].onClick = () => {
    setActionClicked("new");
    setPlanDrawerOpen(true);
  };
  planListingActions[1].onClick = () => {
    setActionClicked("edit");
    setPlanDrawerOpen(true);
  };

  claimCoverListingActions[0].onClick = () => {
    setActionClicked("new");
    setClaimCoverDrawerOpen(true);
  };
  claimCoverListingActions[1].onClick = () => {
    setActionClicked("edit");
    setClaimCoverDrawerOpen(true);
  };

  policyCoverListingActions[0].onClick = () => {
    setActionClicked("new");
    setPolicyCoverDrawerOpen(true);
  };
  policyCoverListingActions[1].onClick = () => {
    setActionClicked("edit");
    setPolicyCoverDrawerOpen(true);
  };

  clauseListingActions[0].onClick = () => {
    setActionClicked("new");
    setClausePopUpOpen(true);
  };
  clauseListingActions[1].onClick = () => {
    setActionClicked("edit");
    setClausePopUpOpen(true);
  };

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const [planTableData, setPlanTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const [claimCoverTableData, setClaimCoverTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const [policyCoverTableData, setPolicyCoverTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });
  const [clauseTableData, setClauseTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [sublineListQuery] = useLazyQuery(getSubLineList());
  const [planListResultQuery] = useLazyQuery(getPlanList());
  const [claimCoverListQuery] = useLazyQuery(getClaimCoverList());
  const [policyCoverListQuery] = useLazyQuery(getPolicyCoverList());
  const [clauseListQuery] = useLazyQuery(getClauseList());

  //#endregion
  const loadEntityData = async () => {
    const result = await entityResultQuery({
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });

    return result;
  };

  const loadSubLineList = async (
    currentPage: number = 0,
    pageSize = tableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword = keywordSearch
  ) => {
    const result = await sublineListQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        SelectedLineId: params.id,
        Attribute: capitalizeFirstCharacter(
          orders?.subline?.orderBy || "PlanConfigManagement_Subline_Name"
        ),
        Descending: orders?.subline?.orderDirection == "asc" ? false : true,
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: "all",
    });

    return result;
  };

  const loadPlanList = async (
    currentPage: number = 0,
    pageSize = planTableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    planSearchKeyword = planKeywordSearch
  ) => {
    const result = await planListResultQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        SelectedLineId: params.id,
        Attribute: capitalizeFirstCharacter(
          orders?.plan?.orderBy || "PlanConfigManagement_Plan_Name"
        ),
        Descending: orders?.plan?.orderDirection == "asc" ? false : true,
        keywordSearch: planSearchKeyword,
      },
      errorPolicy: "all",
    });

    return result;
  };

  const loadCoverList = async (
    currentPage: number = 0,
    pageSize = claimCoverTableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    claimSearchKeyword = claimKeywordSearch
  ) => {
    const result = await claimCoverListQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        SelectedLineId: params.id,
        Attribute: capitalizeFirstCharacter(
          orders?.cover?.orderBy || "PlanConfigManagement_ClaimCover_Name"
        ),
        Descending: orders?.cover?.orderDirection == "asc" ? false : true,
        keywordSearch: claimSearchKeyword,
      },
      errorPolicy: "all",
    });
    return result;
  };

  const loadPolicyCoverList = async (
    currentPage: number = 0,
    pageSize = policyCoverTableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    policySearchKeyword = policyKeywordSearch
  ) => {
    const result = await policyCoverListQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        SelectedLineId: params.id,
        Attribute: capitalizeFirstCharacter(
          orders?.policy?.orderBy || "PlanConfigManagement_PolicyCover_Name"
        ),
        Descending: orders?.policy?.orderDirection == "asc" ? false : true,
        keywordSearch: policySearchKeyword,
      },
      errorPolicy: "all",
    });
    return result;
  };

  const loadClauseList = async (
    currentPage: number = 0,
    pageSize = policyCoverTableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    clausekeywordSearch = clauseKeywordSearch
  ) => {
    const result = await clauseListQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        selectedLineIds: params.id,
        Attribute: capitalizeFirstCharacter(
          orders?.clause?.orderBy || "PlanConfigManagement_Clause_Name"
        ),
        Descending: orders?.clause?.orderDirection == "asc" ? false : true,
        keywordSearch: clausekeywordSearch,
      },
      errorPolicy: "all",
    });
    return result;
  };

  const initialize = async () => {
    const [
      entityData,
      subListData,
      planData,
      coverData,
      policyCoverData,
      clauseData,
    ] = await Promise.all([
      loadEntityData(),
      loadSubLineList(0, 10),
      loadPlanList(0, 10),
      loadCoverList(0, 10),
      loadPolicyCoverList(0, 10),
      loadClauseList(0, 10),
    ]);

    const lineDetails = await entityToLineDetails(entityData.data);
    const mappedSubList = await mapToLSublinesListingData(subListData.data);
    const mappedPlanList = await mapToPlansListingData(planData.data);
    const mappedCoverList = await mapToClaimCoversListingData(coverData.data);
    const mappedPolicyCoverList = await mapToPolicyCoversListingData(
      policyCoverData.data
    );
    const mappedClauseDataList = await mapToClausesListingData(clauseData.data);

    setLineDetailsInfo(lineDetails);
    setTableData({ ...mappedSubList, pageNumber: 0 });

    setPlanTableData({ ...mappedPlanList, pageNumber: 0 });
    setClaimCoverTableData({ ...mappedCoverList, pageNumber: 0 });
    setPolicyCoverTableData({ ...mappedPolicyCoverList, pageNumber: 0 });
    setClauseTableData({ ...mappedClauseDataList, pageNumber: 0 });
    setLoadingState(false);
    setBooted(true);
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrders, val);
    }, 1000);
  };

  //#region functions
  async function handlePageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = keywordSearch
  ) {
    try {
      if (!loadingState) {
        setLoadingState(true);
        const result = await loadSubLineList(
          page,
          pageSize,
          orders,
          searchKeyword
        );
        const mappedSubList = await mapToLSublinesListingData(result.data);
        setTableData({
          ...mappedSubList,
          pageNumber: page,
          pageSize: pageSize,
        });

        setLoadingState(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function handlePlanPageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = planKeywordSearch
  ) {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadPlanList(page, pageSize, orders, searchKeyword);
      const mappedSubList = await mapToPlansListingData(result.data);
      setPlanTableData({
        ...mappedSubList,
        pageNumber: page,
        pageSize: pageSize,
      });

      setLoadingState(false);
    }
  }

  async function handleClaimCoverPageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = claimKeywordSearch
  ) {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadCoverList(page, pageSize, orders, searchKeyword);
      const mappedSubList = await mapToClaimCoversListingData(result.data);
      setClaimCoverTableData({
        ...mappedSubList,
        pageNumber: page,
        pageSize: pageSize,
      });
      setLoadingState(false);
    }
  }

  async function handlePolicyCoverPageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = policyKeywordSearch
  ) {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadPolicyCoverList(
        page,
        pageSize,
        orders,
        searchKeyword
      );
      const mappedSubList = await mapToPolicyCoversListingData(result.data);
      setPolicyCoverTableData({
        ...mappedSubList,
        pageNumber: page,
        pageSize: pageSize,
      });
      setLoadingState(false);
    }
  }
  async function handleClausePageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = clauseKeywordSearch
  ) {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadClauseList(
        page,
        pageSize,
        orders,
        searchKeyword
      );
      const mappedSubList = await mapToClausesListingData(result.data);
      setClauseTableData({
        ...mappedSubList,
        pageNumber: page,
        pageSize: pageSize,
      });
      setLoadingState(false);
    }
  }

  const handlePlanSearchChange = (search: string) => {
    setPlanKeywordSearch(search);
  };

  const delayPlanSearch = (val: string) => {
    clearTimeout(setPlanSearchTimeout);
    setPlanSearchTimeout = setTimeout(() => {
      handlePlanSearchChange(val);
      handlePlanPageChange(0, planTableData.pageSize, tableOrders, val);
    }, 1000);
  };

  const handlePolicySearchChange = (search: string) => {
    setPolicyKeywordSearch(search);
  };

  const delayPolicySearch = (val: string) => {
    clearTimeout(setPolicySearchTimeout);
    setPolicySearchTimeout = setTimeout(() => {
      handlePolicySearchChange(val);
      handlePolicyCoverPageChange(
        0,
        policyCoverTableData.pageSize,
        tableOrders,
        val
      );
    }, 1000);
  };

  const handleClaimSearchChange = (search: string) => {
    setClaimKeywordSearch(search);
  };

  const delayClaimSearch = (val: string) => {
    clearTimeout(setClaimSearchTimeout);
    setClaimSearchTimeout = setTimeout(() => {
      handleClaimSearchChange(val);
      handleClaimCoverPageChange(
        0,
        claimCoverTableData.pageSize,
        tableOrders,
        val
      );
    }, 1000);
  };

  const handleClauseSearchChange = (search: string) => {
    setClauseKeywordSearch(search);
  };

  const delayClauseSearch = (val: string) => {
    clearTimeout(setClauseSearchTimeout);
    setClauseSearchTimeout = setTimeout(() => {
      handleClauseSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrders, val);
    }, 1000);
  };

  function handleSublineSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.subline.orderBy = orderBy;
    newOrder.subline.orderDirection = order as any;
    handlePageChange(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder,
      keywordSearch
    );

    setTableOrders(newOrder);
  }

  function handlePlanSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.plan.orderBy = orderBy;
    newOrder.plan.orderDirection = order as any;
    handlePlanPageChange(
      planTableData.pageNumber,
      planTableData.pageSize,
      newOrder,
      planKeywordSearch
    );

    setTableOrders(newOrder);
  }

  function handlePolicyCoverSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    if (orderBy.includes("_PolicyCover_")) {
      newOrder.policy.orderBy = orderBy;
      newOrder.policy.orderDirection = order as any;
      handlePolicyCoverPageChange(
        policyCoverTableData.pageNumber,
        policyCoverTableData.pageSize,
        newOrder,
        policyKeywordSearch
      );
    }

    setTableOrders(newOrder);
  }

  function handleClaimCoverSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.cover.orderBy = orderBy;
    newOrder.cover.orderDirection = order as any;
    handleClaimCoverPageChange(
      claimCoverTableData.pageNumber,
      claimCoverTableData.pageSize,
      newOrder,
      claimKeywordSearch
    );

    setTableOrders(newOrder);
  }

  function handleClauseSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.clause.orderBy = orderBy;
    newOrder.clause.orderDirection = order as any;
    handleClausePageChange(
      clauseTableData.pageNumber,
      clauseTableData.pageSize,
      newOrder,
      clauseKeywordSearch
    );

    setTableOrders(newOrder);
  }

  const renderMainChildren = () => {
    const status = lineDetailsInfo?.status?.toLowerCase();
    const isLineActive = status === "active";

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={getLineStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isLineActive ? "Deactivate" : "Activate"}
            buttonState={
              isLineActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={() => {
              if (isLineActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Deactivate",
                  message: `Are you sure you want to deactivate this line?`,
                  callback: async () => {
                    setDeactivateButtonState("loading");
                    deactivateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setDeactivateButtonState("success");
                          initialize();
                          toast.success(
                            <ToastSuccessMessage>
                              {
                                "Line successfully deactivated. Relevant sublines and plans are deactivated as well."
                              }
                            </ToastSuccessMessage>
                          );
                        } else {
                          setDeactivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setDeactivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this line?`,
                  callback: async () => {
                    setActivateButtonState("loading");
                    activateAction()
                      .then((result) => {
                        if (isEmpty(result.errors)) {
                          setActivateButtonState("success");
                          initialize();
                          toast.success(
                            <ToastSuccessMessage>
                              {"Line successfully activated."}
                            </ToastSuccessMessage>
                          );
                        } else {
                          setActivateButtonState(undefined);
                          toast.error(
                            <ToastErrorMessage>
                              {getError(result)}
                            </ToastErrorMessage>
                          );
                        }
                      })
                      .finally(() => {
                        setTimeout(() => {
                          setActivateButtonState(undefined);
                        }, 1000);
                      });
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={lineDetailsInfo.lineName}
          view={getEntityView(lineDetailsInfo)}
        ></EntityInfoWidget>
      </>
    );
  };

  const tabs = () => {
    lineDetailsTabs.tabs[0].widgets[0].children = (
      <>
        <WidgetSection style={{ margin: "-23px 0 0 0" }}>
          <form>
            {false ? (
              <div></div>
            ) : (
              <div
                style={{
                  marginTop: "20px",
                  padding: "-23px 0 0 0 !important",
                }}
              >
                <EnhancedTable
                  title={capitalizeFirstLetter("Sublines")}
                  inlineTitle={capitalizeFirstLetter("Sublines")}
                  name="count"
                  entityName={"Subline"}
                  entityIdColumnName={"planConfigManagement_Subline_Id"}
                  showCellFullData={true}
                  isTitlePascalCase={true}
                  orderByAscendingByDefault
                  defaultOrderByColumn="PlanConfigManagement_Subline_Name"
                  inline={true}
                  data={tableData}
                  headers={headers}
                  handlePageChange={(page: number) =>
                    handlePageChange(page, tableData.pageSize)
                  }
                  handleRowsPerPageChange={(pageSize: number) =>
                    handlePageChange(0, pageSize)
                  }
                  handleSort={handleSublineSortChange}
                  handleSearchChange={delaySearch}
                  currentPage={tableData.pageNumber}
                  hideToolbar={false}
                  usePagination
                  disableSelection={false}
                  actions={sublineListingActions}
                  // loader={tableLoadingState}
                  showTablePagination={true}
                  onSelectionChange={(newDetailedSelection) => {
                    setSelectedSublineItems(newDetailedSelection.length);
                    if (newDetailedSelection.length > 0) {
                      setSelectedSublineEntityId(newDetailedSelection[0].id);
                    } else {
                      setSelectedSublineEntityId("");
                    }
                  }}
                  ordering={
                    tableOrders.subline.orderDirection as TableSortOrder
                  }
                  orderingBy={tableOrders.subline.orderBy}
                  //searchValue={keywordSearch}
                />
                {sublineDrawerOpen && (
                  <SublineDrawer
                    open={sublineDrawerOpen}
                    lineId={entityId}
                    lineName={lineDetailsInfo.lineName}
                    sublineId={
                      actionClicked === "new" ? null : selectedSublineEntityId
                    }
                    lineStatus={lineDetailsInfo.status?.toLowerCase()}
                    onClose={() => setSublineDrawerOpen(false)}
                    onSuccess={() => {
                      handlePageChange(0, tableData.pageSize);
                    }}
                  />
                )}
              </div>
            )}
          </form>
          {
            <form>
              {false ? (
                <div></div>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "-23px 0 0 0 !important",
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter("Plans")}
                    inlineTitle={capitalizeFirstLetter("Plans")}
                    name="count"
                    entityName={"Plan"}
                    entityIdColumnName={"planConfigManagement_Plan_Id"}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="planConfigManagement_Plan_Name"
                    inline={true}
                    data={planTableData}
                    headers={planHeaders}
                    handlePageChange={(page: number) =>
                      handlePlanPageChange(page, planTableData.pageSize)
                    }
                    handleRowsPerPageChange={(pageSize: number) =>
                      handlePlanPageChange(0, pageSize)
                    }
                    handleSort={(orderBy: string, order: string) =>
                      handlePlanSortChange(orderBy, order)
                    }
                    currentPage={planTableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={false}
                    actions={planListingActions}
                    // loader={planTableLoadingState}
                    showTablePagination={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setSelectedPlanItems(newDetailedSelection.length);
                      if (newDetailedSelection.length > 0) {
                        setSelectedPlanEntityId(newDetailedSelection[0].id);
                        setSelectedPlanSublineID(
                          newDetailedSelection[0].sublineID
                        );
                        setSelectedPlanSublineStatus(
                          newDetailedSelection[0].sublineStatus
                        );
                      } else {
                        setSelectedPlanEntityId("");
                        setSelectedPlanSublineID("");
                        setSelectedPlanSublineStatus("");
                      }
                    }}
                    ordering={tableOrders.plan.orderDirection as TableSortOrder}
                    orderingBy={tableOrders.plan.orderBy}
                    handleSearchChange={delayPlanSearch}
                    //searchValue={planKeywordSearch}
                  />
                  {planDrawerOpen && (
                    <PlanDrawer
                      open={planDrawerOpen}
                      lineId={entityId}
                      lineName={lineDetailsInfo.lineName}
                      planId={
                        actionClicked === "new" ? null : selectedPlanEntityId
                      }
                      lineStatus={lineDetailsInfo.status?.toLowerCase()}
                      sublineStatus={selectedPlanSublineStatus}
                      isSublineNameDisabled={
                        actionClicked === "edit" ? true : false
                      }
                      sublineID={selectedPlanSublineID}
                      onClose={() => setPlanDrawerOpen(false)}
                      onSuccess={() => {
                        handlePlanPageChange(0, planTableData.pageSize);
                      }}
                    />
                  )}
                </div>
              )}
            </form>
          }
        </WidgetSection>
      </>
    );

    lineDetailsTabs.tabs[1].widgets[0].children = (
      <>
        <WidgetSection style={{ margin: "-23px 0 0 0" }}>
          {
            <form>
              {false ? (
                <div></div>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "-23px 0 0 0 !important",
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter("Policy Covers")}
                    inlineTitle={capitalizeFirstLetter("Policy Covers")}
                    name="count"
                    entityName={"PolicyCover"}
                    entityIdColumnName={"planConfigManagement_PolicyCover_Id"}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="planConfigManagement_PolicyCover_Name"
                    inline={true}
                    data={policyCoverTableData}
                    headers={policyCoverHeaders}
                    handlePageChange={(page: number) =>
                      handlePolicyCoverPageChange(
                        page,
                        policyCoverTableData.pageSize
                      )
                    }
                    handleRowsPerPageChange={(pageSize: number) =>
                      handlePolicyCoverPageChange(0, pageSize)
                    }
                    handleSort={(orderBy: string, order: string) =>
                      handlePolicyCoverSortChange(orderBy, order)
                    }
                    currentPage={policyCoverTableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={false}
                    actions={policyCoverListingActions}
                    // loader={policyCoverTableLoadingState}
                    showTablePagination={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setSelectedPolicyCoverItems(newDetailedSelection.length);
                      if (newDetailedSelection.length > 0) {
                        setSelectedPolicyCoverEntityId(
                          newDetailedSelection[0].id
                        );
                      } else {
                        setSelectedPolicyCoverEntityId("");
                      }
                    }}
                    ordering={
                      tableOrders.policy.orderDirection as TableSortOrder
                    }
                    orderingBy={tableOrders.policy.orderBy}
                    handleSearchChange={delayPolicySearch}
                    //searchValue={policyKeywordSearch}
                  />
                  {policyCoverDrawerOpen && (
                    <PolicyCoverDrawer
                      open={policyCoverDrawerOpen}
                      lineId={entityId}
                      lineName={lineDetailsInfo.lineName}
                      policyCoverId={
                        actionClicked === "new"
                          ? null
                          : selectedPolicyCoverEntityId
                      }
                      onClose={() => setPolicyCoverDrawerOpen(false)}
                      onSuccess={() => {
                        handlePolicyCoverPageChange(
                          0,
                          policyCoverTableData.pageSize
                        );
                      }}
                    />
                  )}
                </div>
              )}
            </form>
          }
          {
            <form>
              {false ? (
                <div></div>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "-23px 0 0 0 !important",
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter("Claim Covers")}
                    inlineTitle={capitalizeFirstLetter("Claim Covers")}
                    name="count"
                    entityName={"ClaimCover"}
                    entityIdColumnName={"planConfigManagement_ClaimCover_Id"}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="planConfigManagement_ClaimCover_Name"
                    inline={true}
                    data={claimCoverTableData}
                    headers={claimCoverHeaders}
                    handlePageChange={(page: number) =>
                      handleClaimCoverPageChange(
                        page,
                        claimCoverTableData.pageSize
                      )
                    }
                    handleRowsPerPageChange={(page: number) =>
                      handleClaimCoverPageChange(0, page)
                    }
                    handleSort={(orderBy: string, order: string) =>
                      handleClaimCoverSortChange(orderBy, order)
                    }
                    currentPage={claimCoverTableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={false}
                    actions={claimCoverListingActions}
                    // loader={claimCoverTableLoadingState}
                    showTablePagination={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setSelectedClaimCoverItems(newDetailedSelection.length);
                      if (newDetailedSelection.length > 0) {
                        setSelectedClaimCoverEntityId(
                          newDetailedSelection[0].id
                        );
                      } else {
                        setSelectedClaimCoverEntityId("");
                      }
                    }}
                    ordering={
                      tableOrders.cover.orderDirection as TableSortOrder
                    }
                    orderingBy={tableOrders.cover.orderBy}
                    handleSearchChange={delayClaimSearch}
                    //searchValue={claimKeywordSearch}
                  />
                  {claimCoverDrawerOpen && (
                    <ClaimCoverDrawer
                      open={claimCoverDrawerOpen}
                      lineId={entityId}
                      lineName={lineDetailsInfo.lineName}
                      claimCoverId={
                        actionClicked === "new"
                          ? null
                          : selectedClaimCoverEntityId
                      }
                      onClose={() => setClaimCoverDrawerOpen(false)}
                      onSuccess={() => {
                        handleClaimCoverPageChange(
                          0,
                          claimCoverTableData.pageSize
                        );
                      }}
                    />
                  )}
                </div>
              )}
            </form>
          }
        </WidgetSection>
      </>
    );

    lineDetailsTabs.tabs[2].widgets[0].children = (
      <>
        <WidgetSection style={{ margin: "-23px 0 0 0" }}>
          <form>
            {false ? (
              <div></div>
            ) : (
              <div
                style={{
                  marginTop: "20px",
                  padding: "-23px 0 0 0 !important",
                }}
              >
                <EnhancedTable
                  title={capitalizeFirstLetter("Clauses")}
                  inlineTitle={capitalizeFirstLetter("Clauses")}
                  name="count"
                  entityName={"Clause"}
                  entityIdColumnName={"planConfigManagement_Clause_Id"}
                  showCellFullData={false}
                  isTitlePascalCase={true}
                  orderByAscendingByDefault
                  defaultOrderByColumn="planConfigManagement_Clause_Name"
                  inline={true}
                  data={clauseTableData}
                  headers={clauseHeaders}
                  handlePageChange={(page: number) =>
                    handleClausePageChange(page, clauseTableData.pageSize)
                  }
                  handleRowsPerPageChange={(page: number) =>
                    handleClausePageChange(0, page)
                  }
                  handleSort={(orderBy: string, order: string) =>
                    handleClauseSortChange(orderBy, order)
                  }
                  currentPage={clauseTableData.pageNumber}
                  hideToolbar={false}
                  usePagination
                  disableSelection={false}
                  actions={clauseListingActions}
                  isServerSide
                  // loader={clauseTableLoadingState}
                  showTablePagination={true}
                  onSelectionChange={(newDetailedSelection) => {
                    setSelectedClauseItems(newDetailedSelection.length);
                    if (newDetailedSelection.length > 0) {
                      setSelectedClauseEntityId(newDetailedSelection[0].id);
                    } else {
                      setSelectedClauseEntityId("");
                    }
                  }}
                  ordering={tableOrders.clause.orderDirection as TableSortOrder}
                  orderingBy={tableOrders.clause.orderBy}
                  handleSearchChange={delayClauseSearch}
                  //searchValue={clauseKeywordSearch}
                />
                {clausePopUpOpen && (
                  <ClausePopUp
                    open={clausePopUpOpen}
                    lineId={entityId}
                    lineName={lineDetailsInfo.lineName || ""}
                    clauseId={
                      actionClicked === "new" ? null : selectedClauseEntityId
                    }
                    onClose={() => setClausePopUpOpen(false)}
                    onSuccess={() => {
                      // handleClausePageChange(0, clauseTableData.pageSize);
                      initialize();
                    }}
                  />
                )}
              </div>
            )}
          </form>
        </WidgetSection>
      </>
    );

    return lineDetailsTabs;
  };

  return !booted ? (
    <Loader />
  ) : (
    <TabsLayout
      name="lineDetailsPage"
      layout={tabs()}
      theme={tenant.theme}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
      cdnUrl={tenant.cdnUrl}
      userInfo={user["info"]}
      firstTabAsActiveTab={true}
      tabPanelClassName={classes.tabPanelStyle}
    />
  );
};

export default LineDetailsPage;
