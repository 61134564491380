import { IAbstractRecord } from '../../../../models';

const BillStatusMapper = {
  NOT_DUE: 'Not Due',
  DUE: 'Due',
  BILLED: 'Billed',
  OVERDUE: 'Overdue',
  PARTIALLY_PAID: 'Partially Paid',
  PAID: 'Paid',
};

export const transformPolicyArrayToObject = (dataArray: IAbstractRecord[]) => {
  if (!dataArray.length) {
    return {};
  }
  return dataArray?.reduce((acc, item) => {
    acc[item.policy_Id] = {
      Id: item.policy_Id,
      PolicyName: item.policy_PolicyName,
      PaymentDivisionType: item.policy_PaymentDivisionType,
      PaymentMode: item.policy_PaymentMode?.Title,
      TotalPremium: item.receivableBills_TotalPremium,
      AmountReceived: '',
      OutstandingBalanceReceivable: item.receivableBills_OutstandingBalance,
      TotalCommission: item.receivableBills_TotalCommission,
      TaxOnCommission: item.receivableBills_TaxOnCommission,
      AmountPaid: '',
      OutstandingBalancePayable: item.payableBills_OutstandingBalance,
      OutstandingPayable:
        Number(item.receivableBills_OutstandingBalance) -
        Number(item.payableBills_OutstandingBalance),
    };
    return acc;
  }, {});
};

export const transformReceivableBillsArrayToObject = (
  dataArray: IAbstractRecord[]
) => {
  if (!dataArray?.length) {
    return {};
  }
  return dataArray?.reduce((acc, item) => {
    acc[item.accounting_Bills_Id] = {
      Id: item.accounting_Bills_Id,
      BillNumber: item.accounting_Bills_BillNumber,
      DueDate: item.accounting_Bills_DueDate,
      NetPremium: item.accounting_Bills_NetPremium,
      TotalPremium: item.accounting_Bills_TotalPremium,
      AmountReceived: '',
      OutstandingBalance: item.accounting_Bills_OutstandingBalance,
      BillStatus:
        BillStatusMapper[
          item.accounting_Bills_BillStatus as keyof typeof BillStatusMapper
        ],
    };
    return acc;
  }, {});
};

export const transformPayableBillsArrayToObject = (
  dataArray: IAbstractRecord[]
) => {
  if (!dataArray?.length) {
    return {};
  }
  return dataArray?.reduce((acc, item) => {
    acc[item.accounting_Bills_Id] = {
      Id: item.accounting_Bills_Id,
      BillNumber: item.accounting_Bills_BillNumber,
      DueDate: item.accounting_Bills_DueDate,
      Discount: item.accounting_Bills_Discount,
      TotalCommission: item.accounting_Bills_TotalCommission,
      AmountReceived: '',
      OutstandingBalance: item.accounting_Bills_OutstandingBalance,
      BillStatus:
        BillStatusMapper[
          item.accounting_Bills_BillStatus as keyof typeof BillStatusMapper
        ],
    };
    return acc;
  }, {});
};
