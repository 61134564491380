export const USER_ROLES = {
  "Insurance-Admin": "Admin",
  "Insurance-Underwriter": "Underwriter",
  "Insurance-IssuingAgent": "Issuing Business User",
  "Insurance-QuotingAgent": "Quoting Business User",
  "Core-BusinessUser": "Business User",
  "Insurance-Accountant": "Accountant"
} as const;

export const AGENT_LINEOFBUSINESS = {
  Health: "Health",
  Motor: "Motor",
  Expat: "Expat",
} as const;

export interface IUserDetails {
  userRoles: (keyof typeof USER_ROLES)[];
  haveWeakPassword: boolean;
  info: IUserInfo;
  linesOfBusinessStatuses: LinesOfBusinessStatuses;
}

export type LinesOfBusinessStatuses = {
  Code?: string;
  Title?: string;
  Active?: boolean;
};

export interface IUserMeta extends IUserDetails {
  isAuthenticated: boolean;
}

export interface IUserInfo {
  id: string;
  firstName: string;
  lastName: string;
  businessUser_Id?: string;
  businessUser_Code?: string;
  email: string;
  agentId?: string;
  agencyId?: string[];
  linesOfBusiness?: string[];
  products?: string[];
  eligiblePlans?: string[];
  agentType?: string;
}

export enum UserActionTypes {
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS",
  LOGOUT_OUT_USER = "LOGOUT_OUT_USER",
}

export type RefreshTokenObject = {
  expiresIn: string;
  refreshToken: string;
};

export interface LoginSuccessAction {
  type: typeof UserActionTypes.LOGIN_SUCCESS;
  jwtToken: string;
  id_token: string;
  refreshTokenObject?: RefreshTokenObject;
}

export interface LoadUserSuccessAction {
  type: typeof UserActionTypes.LOAD_USER_SUCCESS;
  user: IUserDetails;
}
export interface SignOutUserAction {
  type: typeof UserActionTypes.LOGOUT_OUT_USER;
}
