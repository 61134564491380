import React from "react";
import { IGroupTableProps } from ".";
import UpdatedEnhancedTable from "./EnhancedTable";
import GroupTableToolbar from "./GroupTableToolbar";

const GroupTable: React.FC<IGroupTableProps> = ({ tables }) => {
    return <>{tables?.map((t) => {
        return <GroupTableToolbar title={t.title}
            children={
                <UpdatedEnhancedTable
                    name={""}
                    hideHeader
                    pagination={false}
                    headers={t.headers}
                    data={t.data}
                    disableSelection
                />
            } />
    })}
    </>
}


export default GroupTable;