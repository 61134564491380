import {
  enumListAsRecordObject,
  lookupListAsRecordObject,
} from "../../../../../utils/graph-utils";

export function LookupToList(data: any) {
  const result: Record<string, Record<string, string>> = {};

  result["currency"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies,
    true
  );
  result["medicalClasses"] = lookupListAsRecordObject(
    data?.Core?.lookups?.medicalClasses
  );
  result["continuityOptions"] = enumListAsRecordObject(
    data.Production_ContinuityOptions.enumValues
  );

  return result;
}

export const getProposalStatus = (steps: string[], status: string) => {
  switch (status?.toUpperCase()) {
    case "CLOSED_WON":
      return steps[2];
    case "CLOSED_LOST":
      return steps[2];
    case "IN_PROGRESS":
      return steps[1];
    default:
      return steps[0];
  }
};

export function extractLovs(data: any): Record<string, Record<string, string>> {
  const lovs: Record<string, Record<string, string>> = {
    policyCurrencies: {},
    tpaTypes: {},
    tpaApplicableOnOptions: {},
  };
  lovs["policyCurrencies"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  lovs["tpaTypes"] = enumListAsRecordObject(
    data?.Core_TPATypes?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  lovs["tpaApplicableOnOptions"] = enumListAsRecordObject(
    data?.Core_TPAApplicableOnOptions?.enumValues as {
      __typename: string;
      name: string;
    }[]
  );

  return lovs;
}

export function extractActiveAssignedBPPlan(data: any) {
  const personLookups: Record<string, Record<string, string>> = {};
  let payerPersons: Record<string, string> = {};

  data.SalesforceManagement.queries.AllActivePersons.forEach(
    (element: {
      salesforceManagement_Person_Id: string;
      salesforceManagement_Person_FullName: string;
      salesforceManagement_Person_PersonCode: string;
    }) => {
      payerPersons[
        element.salesforceManagement_Person_Id
      ] = `${element.salesforceManagement_Person_PersonCode} - ${element.salesforceManagement_Person_FullName}`;
    }
  );

  personLookups["payerPersons"] = payerPersons;

  return personLookups;
}

export function extractPersons(data: any) {
  const personLookups: Record<string, Record<string, string>> = {};
  let payerPersons: Record<string, string> = {};
  let ownerPersons: Record<string, string> = {};
  let insuredPersons: Record<string, string> = {};
  let beneficiaryPersons: Record<string, string> = {};

  data.SalesforceManagement.queries.AllActivePersons.forEach(
    (element: {
      salesforceManagement_Person_Id: string;
      salesforceManagement_Person_FullName: string;
      salesforceManagement_Person_PersonCode: string;
    }) => {
      payerPersons[
        element.salesforceManagement_Person_Id
      ] = `${element.salesforceManagement_Person_PersonCode} - ${element.salesforceManagement_Person_FullName}`;
    }
  );
  data.SalesforceManagement.queries.AllActivePersons.forEach(
    (element: {
      salesforceManagement_Person_Id: string;
      salesforceManagement_Person_FullName: string;
      salesforceManagement_Person_PersonCode: string;
    }) => {
      ownerPersons[
        element.salesforceManagement_Person_Id
      ] = `${element.salesforceManagement_Person_PersonCode} - ${element.salesforceManagement_Person_FullName}`;
    }
  );
  data.SalesforceManagement.queries.AllActivePersons.forEach(
    (element: {
      salesforceManagement_Person_Id: string;
      salesforceManagement_Person_FullName: string;
      salesforceManagement_Person_PersonCode: string;
    }) => {
      insuredPersons[
        element.salesforceManagement_Person_Id
      ] = `${element.salesforceManagement_Person_PersonCode} - ${element.salesforceManagement_Person_FullName}`;
    }
  );
  data.SalesforceManagement.queries.AllActivePersons.forEach(
    (element: {
      salesforceManagement_Person_Id: string;
      salesforceManagement_Person_FullName: string;
      salesforceManagement_Person_PersonCode: string;
    }) => {
      beneficiaryPersons[
        element.salesforceManagement_Person_Id
      ] = `${element.salesforceManagement_Person_PersonCode} - ${element.salesforceManagement_Person_FullName}`;
    }
  );

  personLookups["payerPersons"] = payerPersons;
  personLookups["ownerPersons"] = ownerPersons;
  personLookups["insuredPersons"] = insuredPersons;
  personLookups["beneficiaryPersons"] = beneficiaryPersons;

  return personLookups;
}

export function extractPersonsAddresses(data: any) {
  const personLookups: Record<
    string,
    Record<string, { address: string; isPrimary: boolean }>
  > = {};
  let payerPersonAddress: Record<
    string,
    { address: string; isPrimary: boolean }
  > = {};
  let ownerPersonAddress: Record<
    string,
    { address: string; isPrimary: boolean }
  > = {};
  let insuredPersonAddress: Record<
    string,
    { address: string; isPrimary: boolean }
  > = {};
  let beneficiaryPersonAddress: Record<
    string,
    { address: string; isPrimary: boolean }
  > = {};

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ""
      );
      const address = parts.join(", ");

      payerPersonAddress[element.salesforceManagement_PersonAddress_Id] = {
        address,
        isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
      };
    }
  );

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ""
      );
      const address = parts.join(", ");

      ownerPersonAddress[element.salesforceManagement_PersonAddress_Id] = {
        address,
        isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
      };
    }
  );

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ""
      );
      const address = parts.join(", ");

      insuredPersonAddress[element.salesforceManagement_PersonAddress_Id] = {
        address,
        isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
      };
    }
  );

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ""
      );
      const address = parts.join(", ");

      beneficiaryPersonAddress[element.salesforceManagement_PersonAddress_Id] =
        {
          address,
          isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
        };
    }
  );

  personLookups["payerPersonAddress"] = payerPersonAddress;
  personLookups["ownerPersonAddress"] = ownerPersonAddress;
  personLookups["insuredPersonAddress"] = insuredPersonAddress;
  personLookups["beneficiaryPersonAddress"] = beneficiaryPersonAddress;

  return personLookups;
}

export function extractPersonsFixed(data: any) {
  let result: Record<string, string> = {};

  data.SalesforceManagement.queries.AllActivePersons.forEach(
    (element: {
      salesforceManagement_Person_Id: string;
      salesforceManagement_Person_FullName: string;
      salesforceManagement_Person_PersonCode: string;
    }) => {
      result[
        element.salesforceManagement_Person_Id
      ] = `${element.salesforceManagement_Person_PersonCode} - ${element.salesforceManagement_Person_FullName}`;
    }
  );

  return result;
}

export function extractPersonsAddressesFixed(data: any) {
  let result: Record<string, { address: string; isPrimary: boolean }> = {};

  data?.SalesforceManagement?.queries?.getPersonAddressListWithoutPagination?.forEach(
    (element: {
      salesforceManagement_PersonAddress_Id: string;
      salesforceManagement_PersonAddress_Address: {
        Country: {
          Title: string;
        };
        City: string;
        Street: string;
        Building: string;
      };
      salesforceManagement_PersonAddress_IsPrimary: boolean;
    }) => {
      const { Building, Street, City, Country } =
        element.salesforceManagement_PersonAddress_Address;
      const parts = [Building, Street, City, Country?.Title].filter(
        (part) => part != null && part !== ""
      );
      const address = parts.join(", ");

      result[element.salesforceManagement_PersonAddress_Id] = {
        address,
        isPrimary: element.salesforceManagement_PersonAddress_IsPrimary,
      };
    }
  );

  return result;
}
