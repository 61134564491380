import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  line: "",
  plan: "",
  activeFrom: "",
  activeTo: "",
  commissionType: "RATE",
  lineCommissionValue: 0,
  ValueCurrency: "39735",
  lineCommissionRate: 0,
  ApplicableOn: "",
};

export const getInputs = (
  values: Record<string, any> = initialValues,
  lovs: Record<string, Record<string, string>>,
  businessPartnerType: string
): Record<string, DynamicFormInputType> => {
  const isRate = values.commissionType?.toLowerCase() === "rate";
  const isDirectSalesforce = businessPartnerType === "direct salesforce";

  return {
    line: {
      name: "line",
      title: "Line",
      type: FormInputTypes.chips,
      placeholder: "Line",
      value: !isEmpty(values.line) ? values.line : "",
      required: true,
      multiple: false,
      selectOptions: lovs.line || {},
    },
    plan: {
      name: "plan",
      title: "Plan(s)",
      type: FormInputTypes.chips,
      placeholder: "Plan(s)",
      value: !isEmpty(values.plan) ? values.plan : "",
      required: true,
      multiple: true,
      selectOptions: lovs.plan || {},
    },
    commissionType: {
      name: "commissionType",
      title: "Commission Type",
      placeholder: "Commission Type",
      type: FormInputTypes.chips,
      value: !isEmpty(values.commissionType) ? values.commissionType : "",
      multiple: false,
      required: false,
      selectOptions: lovs.commissionType || {},
    },
    lineCommissionValue: {
      name: "lineCommissionValue",
      title: "Commission",
      type: FormInputTypes.formattedNumber,
      placeholder: "Commission",
      value: values.lineCommissionValue,
      required: !isRate,
      hidden: isRate || isDirectSalesforce,
      disabled: isRate,
    },
    ValueCurrency: {
      name: "ValueCurrency",
      title: "Commission Currency",
      placeholder: "Commission Currency",
      type: FormInputTypes.chips,
      value: !isEmpty(values.ValueCurrency) ? values.ValueCurrency : "",
      multiple: false,
      required: !isRate,
      hidden: isRate || isDirectSalesforce,
      disabled: isRate,
      selectOptions: lovs.ValueCurrency || {},
    },
    lineCommissionRate: {
      name: "lineCommissionRate",
      title: "Commission Rate",
      type: FormInputTypes.percentage,
      placeholder: "Commission Rate",
      value: values.lineCommissionRate,
      required: isRate,
      hidden: !isRate || isDirectSalesforce,
      disabled: !isRate,
      hasBetweenValidation: true,
    },
    ApplicableOn: {
      name: "ApplicableOn",
      title: "Commission Applicable On",
      placeholder: "Commission Applicable On",
      type: FormInputTypes.chips,
      value: !isEmpty(values.ApplicableOn) ? values.ApplicableOn : "",
      multiple: false,
      required: isRate || !isDirectSalesforce,
      hidden: !isRate || isDirectSalesforce,
      disabled: !isRate || isDirectSalesforce,
      selectOptions: lovs.ApplicableOn || {},
    },
    activeFrom: {
      name: "activeFrom",
      title: "Active From",
      placeholder: "Active From",
      type: FormInputTypes.date,
      value: values.activeFrom,
      required: true,
    },
    activeTo: {
      name: "activeTo",
      title: "Active To",
      placeholder: "Active To",
      type: FormInputTypes.date,
      value: values.activeTo,
      required: true,
    },
  };
};
