import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import PageTitle from './shared/PageTitle';
import TableWithFilters from './shared/TableWithFilters';
import { headers } from '../content';
import { useLazyQuery } from '@apollo/client';
import { getLovs, getPolicies } from '../queries';
import { IEnhancedTableProps } from '../../../../../components/updated-enhanced-table/EnhancedTable';
import { IAbstractRecord } from '../../../../../models';
import { isEmpty } from '../../../../../utils/validationUtils';
import { transformPolicyArrayToObject } from '../utils';
import { formatDate } from '../../../../../utils/formatting-utils';
import { SEND_TO_BACKEND_DATE_FORMAT } from '../../../../../constants';
import { IEnhancedUpdatedTableMenuItem } from '../../../../../components/updated-enhanced-table';
import ViewBreakdown, { IFormValues } from './ViewBreakdown';

interface IMainColumnProps {
  mainEntityId: string;
}

const useStyles = makeStyles()(() => ({
  container: {
    marginTop: '36px',
  },
}));

const MainColumn: React.FC<IMainColumnProps> = ({ mainEntityId }) => {
  const { classes } = useStyles();
  const [fetchLovs, { data: lovs }] = useLazyQuery(getLovs);
  const [fetchPolicies, { data }] = useLazyQuery(getPolicies);
  const [viewBreakdownPopup, setViewBreakdownPopup] = useState<{
    open: boolean;
    selected?: {
      id: string;
    } & IFormValues;
  }>({
    open: false,
  });

  useEffect(() => {
    fetchLovs();
    fetchPolicies({
      variables: {
        BusinessPartnerID: mainEntityId,
        pagination: {
          pageSize: 10,
          pageNumber: 1,
        },
      },
    });
  }, [fetchLovs, fetchPolicies, mainEntityId]);

  const tableFilters: IEnhancedTableProps['tableFilters'] = useMemo(() => {
    if (!lovs) return {};

    const allLinesArray: Record<string, string>[] =
      lovs?.PlanConfigManagement?.queries?.allLines;
    const allLines = allLinesArray?.reduce((acc, item) => {
      acc[item.planConfigManagement_Line_Id] =
        item.planConfigManagement_Line_Name;
      return acc;
    });

    const allCurrenciesArray: Record<string, string>[] =
      lovs?.Core?.lookups?.currencies;
    const allCurrencies = allCurrenciesArray?.reduce((acc, item) => {
      acc[item.Code] = item.Symbol;
      return acc;
    });

    return {
      Line: {
        name: 'Line',
        type: 'select',
        options: allLines,
        placeholder: 'Line',
        selectedValues: [],
      },
      PolicyIssuanceDate: {
        name: 'PolicyIssuanceDate',
        type: 'daterange',
        placeholder: 'Policy Issuance Date',
        value: [],
      },
      Currency: {
        name: 'Currency',
        type: 'select',
        placeholder: 'Currency',
        options: allCurrencies,
        selectedValues: [],
      },
    };
  }, [lovs]);

  const tableData: IEnhancedTableProps['data'] = useMemo(() => {
    if (!data)
      return {
        pagedItems: {},
        pageSize: 10,
        pageNumber: 0,
        totalCount: 0,
      };

    const policiesData = data?.Accounting?.queries?.BrokerPoliciesList;
    const items: IAbstractRecord[] = policiesData?.items;

    return {
      pagedItems: transformPolicyArrayToObject(items),
      ...policiesData.paging,
    };
  }, [data]);

  const getDateValue = (date: string | Date | undefined) => {
    if (!date) {
      return undefined;
    }
    return formatDate(date, SEND_TO_BACKEND_DATE_FORMAT);
  };

  const actions: IEnhancedUpdatedTableMenuItem[] = useMemo(() => {
    return [
      {
        name: 'ViewBreakdown',
        title: 'View Breakdown',
        isEntity: true,
        isBulk: false,
        onClick: (selected: string[]) => {
          setViewBreakdownPopup({
            open: true,
            selected: {
              id: selected?.[0],
              ...tableData.pagedItems?.[selected?.[0]],
            },
          });
        },
      },
    ];
  }, [data]);

  return (
    <div>
      {viewBreakdownPopup.open && (
        <ViewBreakdown
          open={viewBreakdownPopup.open}
          policyId={viewBreakdownPopup.selected?.id}
          businessPartnerId={mainEntityId}
          data={viewBreakdownPopup.selected}
          onClose={() => {
            setViewBreakdownPopup({
              open: false,
              selected: undefined,
            });
          }}
        />
      )}
      <PageTitle text={'Receivables & Payables'} />
      <div className={classes.container}>
        <TableWithFilters
          tableTitle='Policies'
          actions={actions}
          headers={headers}
          data={tableData}
          tableFilters={tableFilters}
          applyFilters={(values, page) => {
            fetchPolicies({
              variables: {
                SelectedLine: values.Line || undefined,
                SelectedCurrency: values.Currency || undefined,
                PolicyIssuanceFrom: getDateValue(
                  values.PolicyIssuanceDate?.[0]
                ),
                PolicyIssuanceTo: getDateValue(values.PolicyIssuanceDate?.[1]),
                BusinessPartnerID: mainEntityId,
                pagination: {
                  pageSize: tableData.pageSize,
                  pageNumber: !isEmpty(page) ? page : 1,
                },
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default MainColumn;
