import { isEmpty, isValidNumber } from "../../../../../utils/validationUtils";
import { IProposalPageFormState } from "./form";
import { IProposalDetailsSummary } from "./index2";

export interface IProposalDetailsExpatPageSubmissionModel {
  entityId: string;
  planId: string;
  policyCurrency: string;
  policyEffectiveDate: string;
  policyExpiryDate: string;
  // agencyRepairYear: number;
  policyPersonInput: {
    personId: string;
    personAddressId: string;
    type: string;
    percentage?: number;
    orderNumber?: number;
    ownerIsDifferent?: boolean;
  }[];
  policyPlanCoverInput: {
    coverPremiumPercentage: number;
    coverPremiumType: string;
    coverPremiumValue: number;
    excessOnClaimAmount: number;
    excessOnClaimDays: number;
    excessOnClaimPercentage: number;
    excessOnClaimType: string;
    planCoverID: string;
  }[];
  costAndChargeInput: {
    chargesPercentage: number;
    policyCost: number;
    tpaFeesAmount: number;
    tpaFeesApplicableOn: string;
    tpaFeesPercentage: number;
    tpaFeesType: string;
  };
  expatInput: {
    medicalClass: string;
    continuity: string;
    continuityNumber: number;
    exclusion: string;
    remarks: string;
  };
}

export function convertProposalPageStateToSubmission(
  summary: IProposalDetailsSummary,
  pageState: IProposalPageFormState
) {
  const { values } = pageState;

  const policyPersons: IProposalDetailsExpatPageSubmissionModel["policyPersonInput"] =
    [];

  if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: "PAYER",
      ownerIsDifferent: values.policyPersons.payer.ownerIsDifferent,
    });
  }
  if (values.policyPersons.owner?.ownerId) {
    policyPersons.push({
      personId: values.policyPersons.owner.ownerId,
      personAddressId: values.policyPersons.owner.ownerAddress,
      type: "OWNER",
    });
  } else if (values.policyPersons?.payer?.payerId) {
    policyPersons.push({
      personId: values.policyPersons.payer.payerId,
      personAddressId: values.policyPersons.payer.payerAddress,
      type: "OWNER",
    });
  }

  values.policyPersons.insured.forEach((person) => {
    if (person.id) {
      policyPersons.push({
        personId: person.id,
        personAddressId: person.address,
        type: "INSURED",
        orderNumber: isValidNumber(person.order)
          ? Number(person.order)
          : undefined,
      });
    }
  });

  values.policyPersons.beneficiaries.forEach((person) => {
    if (person.id) {
      policyPersons.push({
        personId: person.id,
        personAddressId: person.address,
        type: "BENEFICIARY",
        percentage: isValidNumber(person.percentage)
          ? person.percentage / 100
          : 0,
      });
    }
  });

  const data: IProposalDetailsExpatPageSubmissionModel = {
    entityId: summary.Id,
    planId: summary.PlanID.Id,
    policyCurrency: summary.PolicyCurrency.Code,
    policyEffectiveDate: summary.PolicyEffectiveDate,
    policyExpiryDate: summary.PolicyExpiryDate,
    // agencyRepairYear: isValidNumber(values.motorDetails.agencyRepairYear)
    //     ? Number(values.motorDetails.agencyRepairYear)
    //     : null,
    policyPersonInput: policyPersons,
    policyPlanCoverInput: values.covers
      .filter((c) => !isEmpty(c.policyCover))
      .map((cover) => ({
        coverPremiumPercentage: isValidNumber(cover.coverPremiumPercentage)
          ? cover.coverPremiumPercentage / 100
          : null,
        coverPremiumType: cover.coverPremiumType,
        coverPremiumValue: isValidNumber(cover.coverPremiumValue)
          ? Number(cover.coverPremiumValue)
          : null,
        excessOnClaimAmount: isValidNumber(cover.excessOnClaimAmount)
          ? Number(cover.excessOnClaimAmount)
          : null,
        excessOnClaimDays: isValidNumber(cover.excessOnClaimDays)
          ? Number(cover.excessOnClaimDays)
          : null,
        excessOnClaimPercentage: isValidNumber(cover.excessOnClaimPercentage)
          ? cover.excessOnClaimPercentage / 100
          : null,
        excessOnClaimType: cover.excessOnClaimType,
        planCoverID: cover.policyCover,
        isEditable: cover.isEditable,
        isMain: cover.isMain,
        coverSumInsured: isValidNumber(cover.coverSumInsured)
          ? Number(cover.coverSumInsured)
          : 0,
        sumInsuredIsAdditive: cover.sumInsuredIsAdditive,
        isMandatory: cover.isMandatory,
        clauseID: cover.clauseID,
        coverEffectiveFrom: cover.coverEffectiveFrom,
        coverEffectiveTo: cover.coverEffectiveTo,
      })),
    costAndChargeInput: {
      chargesPercentage: isValidNumber(values.costs.chargesPercentage)
        ? Number(values.costs.chargesPercentage) / 100
        : 0,
      policyCost: isValidNumber(values.costs.policyCost)
        ? Number(values.costs.policyCost)
        : 0,
      tpaFeesAmount: isValidNumber(values.costs.tpaFeesAmount)
        ? Number(values.costs.tpaFeesAmount)
        : 0,
      tpaFeesApplicableOn: values.costs.tpaFeesApplicableOn,
      tpaFeesPercentage: isValidNumber(values.costs.tpaFeesPercentage)
        ? values.costs.tpaFeesPercentage / 100
        : 0,
      tpaFeesType: values.costs.tpaFeesType,
    },
    expatInput: {
      medicalClass: values?.expatDetails?.medicalClass,
      continuity: values?.expatDetails?.continuity,
      continuityNumber: isValidNumber(values.expatDetails?.continuityNumber)
        ? Number(values.expatDetails?.continuityNumber)
        : null,
      exclusion: values?.expatDetails?.exclusion,
      remarks: values?.expatDetails?.remarks,
    },
  };

  return data;
}
