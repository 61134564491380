export interface ILineDetailsPageProps {}

export interface ILineDetails {
  lineName: string;
  externalCode: string;
  abbreviation: string;
  arabicName: string;
  status: string;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
}

export const initialValues: ILineDetails = {
  lineName: "",
  externalCode: "",
  abbreviation: "",
  arabicName: "",
  createdBy: "",
  createdOn: "",
  modifiedBy: "",
  modifiedOn: "",
  status: "",
};

export interface IGenericSublineTableData {
  planConfigManagement_Subline_Id: string;
  planConfigManagement_Subline_Name: string;
  planConfigManagement_Subline_ExternalCode: string;
  planConfigManagement_Subline_NameArabic: string;
  planConfigManagement_Subline_Status: string;
  planConfigManagement_Subline_createdOn: string;
  planConfigManagement_Subline_modifiedOn: string;
}

export interface IGenericPlanTableData {
  planConfigManagement_Plan_Id: string;
  planConfigManagement_Plan_EffectiveFrom: string;
  planConfigManagement_Plan_EffectiveTo: string;
  planConfigManagement_Plan_PlanStatus: string;
  planConfigManagement_Plan_createdOn: string;
  planConfigManagement_Plan_modifiedOn: string;
  planConfigManagement_Plan_ExternalCode: string;
  planConfigManagement_Plan_Name: string;
  planConfigManagement_Plan_NameArabic: string;
  planConfigManagement_Plan_SublineID: {
    id: string;
    views: {
      PlanConfigManagement_all: {
        properties: {
          Name: string;
          Status: string;     
        };
      };
    };
  };
}
export interface IGenericClaimCoverTableData {
  planConfigManagement_ClaimCover_Id: string;
  planConfigManagement_ClaimCover_Name: string;
  planConfigManagement_ClaimCover_ExternalCode: string;
  planConfigManagement_ClaimCover_PolicyCoverID: {
    views: {
      PlanConfigManagement_all: {
        properties: {
          Name: string;          
        };
      };
    };
  };
  planConfigManagement_ClaimCover_NameArabic: string;
  planConfigManagement_ClaimCover_PrintingOrder: string;
  planConfigManagement_ClaimCover_ClaimCoverDescription: string;
  planConfigManagement_ClaimCover_createdOn: string;
  planConfigManagement_ClaimCover_modifiedOn: string;
}

export interface IGenericPolicyCoverTableData {
  planConfigManagement_PolicyCover_Id: string;
  planConfigManagement_PolicyCover_Name: string;
  planConfigManagement_PolicyCover_ExternalCode: string;
  planConfigManagement_PolicyCover_NameArabic: string;
  planConfigManagement_PolicyCover_PrintingOrder: string;
  planConfigManagement_PolicyCover_PolicyCoverDescription: string;
  planConfigManagement_PolicyCover_createdOn: string;
  planConfigManagement_PolicyCover_modifiedOn: string;
  planConfigManagement_PolicyCover_LineID: {    
    views: {
      PlanConfigManagement_all: {
        properties: {
          ExternalCode: string;
          Name: string;          
        };
      };
    };
  };
}

export interface IGenericClauseTableData {
  planConfigManagement_Clause_Id: string;
  planConfigManagement_Clause_Name: string;
  planConfigManagement_Clause_ExternalCode: string;
  planConfigManagement_Clause_Description: string;
  planConfigManagement_Clause_Status: string;
  planConfigManagement_Clause_createdOn: string;
  planConfigManagement_Clause_modifiedOn: string;
  planConfigManagement_Clause_LineID: {    
    views: {
      PlanConfigManagement_all: {
        properties: {
          ExternalCode: string;
          Name: string;          
        };
      };
    };
  };
}