import { gql } from "@apollo/client";
export function getTransfersEnums() {
  return gql(/* GraphQL */ `
    query GetTransfersEnums
    {
  Accounting {
    lookups {
      transferType {
        Id,
        Title,
        Code
      }
    }
  },
  SalesforceManagement {
    lookups{
      relatedCompanies {
              Code
              Title
              Id
          }
    }
  }
}
  `);
}

export function getProductInfo() {
    return gql(/* GraphQL */ `
        query GetProductsInfo($id: String!)
{
  Accounting {
    entities {
      product(id: $id) {
        id,
        views {
          Accounting_all {
            properties {
              ProductName,
              ProductDescription,
              ProductPrice,
              BuySell,
              RelatedIncomeAccount{
                id
              },
              RelatedExpenseAccount{
                id
              },
              RelatedCompany {
                Id
              }
            }
          }
        }
      }
    }
  }
}
      `);
}

export function createTransfer() {
  return gql`
    mutation createProductionTransfer(
  $SelectedCompany: String!
  $TransferType: String!
  $TransferFromDate: Date!
  $TransferToDate: Date!
) {
  accounting {
    actions {
      createProductionTransfer(
        SelectedCompany: $SelectedCompany
        TransferType: $TransferType
        TransferFromDate: $TransferFromDate
        TransferToDate: $TransferToDate
      )
    }
  }
}
  `;
}

export function updateProduct() {
  return gql`
    mutation UpdateProducts(
  $EnteredProductDescription: String
  $EnteredProductName: String!
  $EnteredProductPrice: Decimal
  $CurrentProductID: String!
) {
  accounting {
    actions {
      modifyProduct(
        EnteredProductDescription: $EnteredProductDescription
        EnteredProductName: $EnteredProductName
        EnteredProductPrice: $EnteredProductPrice
        CurrentProductID: $CurrentProductID
      ) 
    }
  }
}
  `;
}
