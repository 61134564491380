export interface IInvoiceDetailsPageProps {}

export interface IInvoiceDetails {
  InvoiceNumber: string;
}

export const initialValues: IInvoiceDetails = {
  InvoiceNumber: '',
};

export interface IGenericInvoiceRecordsTableData {
  invoiceRecord_Id: any;
  invoiceRecord_Product: any;
  invoiceRecord_Description: string;
  invoiceRecord_Amount: number;
  invoiceRecord_SystemAmount: number;
}
