import { configureStore } from "@reduxjs/toolkit";
import confirmationReducer from "./confirmation/reducer";
import tenantReducer from "./tenant/reducer";
import userReducer from "./user/reducer";
import appReducer from "./app-settings/reducer";

const store = configureStore({
  reducer: {
    user: userReducer,
    tenant: tenantReducer,
    confirmation: confirmationReducer,
    app: appReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
