import React, { useCallback } from "react";
import {
  isEmpty,
  isValidNumber,
  isValidNumberInput,
} from "../../utils/validationUtils";
import { IEnhancedInputProps } from "./EnhancedInput";
import EnhancedInputsWrapper from "./EnhancedInputsWrapper";
import EnhancedIcon from "../EnhancedIcon";
import { useAppSelector } from "../../redux/hooks";
import clsx from "clsx";
import { makeStyles } from "tss-react/mui";
import { MAIN_ONE_THEME, contentFontFamilyRegular } from "../../constants";

export interface IEnhancedPercentageInputProps
  extends Omit<IEnhancedInputProps, "type"> {
  value: string | number;
  loader?: boolean;
  hasBetweenValidation?: boolean;
  disableNegativeValues?: boolean;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hidden?: boolean;
}

const useStyles = makeStyles<{ material: boolean }>()(
  (theme, { material }) => ({
    input: {
      fontSize: MAIN_ONE_THEME.typography.regular.reg2.fontSize,
      lineHeight: "15px",
      height: 35,
      margin: "0 auto",
      backgroundColor: MAIN_ONE_THEME.palette.secondary4.main,
      border: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
      boxSizing: "border-box",
      borderRadius: "5px",
      "&:disabled": {
        cursor: "not-allowed",
        color: "rgba(0, 0, 0, 0.5)!important",
        opacity: `0.6 !important`,
        textShadow: `0.2px 0.3px 0.5px rgba(0, 0, 0, 0.5) !important`,
      },
      "&::placeholder": {
        color: "rgba(0, 0, 0, 0.20)",
        fontStyle: "italic",
      },
      fontFamily: contentFontFamilyRegular,
      "&:focus": {
        outline: "none",
      },
      "::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    materialInput: {
      backgroundColor: "unset",
      border: "none",
      borderBottom: `1px solid ${MAIN_ONE_THEME.palette.secondary3.main}`,
      color: MAIN_ONE_THEME.palette.primary2.main,
      margin: "0 auto",
      borderRadius: 0,
      padding: "10px 15px 4px 4px",
      fontFamily: contentFontFamilyRegular,
      fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
    },
    percentageIcon: {
      position: "absolute",
      right: material ? "5px" : "10px",
      top: "10px",
      minHeight: "12px",
      minWidth: "12px",
      height: "12px",
      width: "12px",
      fontFamily: contentFontFamilyRegular,
    },
    formPercentageIcon: {
      height: "11px",
      width: "15px",
      marginTop: "2px",
      fontFamily: contentFontFamilyRegular,
      "& > img": {
        maxWidth: "12px !important",
        maxHeight: "12px !important",
        width: "12px !important",
        height: "12px !important",
      },
    },
    percentageInput: {
      paddingLeft: "15px",
      fontFamily: contentFontFamilyRegular,
    },
    percentageInputContainer: {
      position: "relative",
      display: "inline-block",
      width: "100%",
    },
    inputError: {
      borderColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
      outlineColor: `${MAIN_ONE_THEME.palette.error.main} !important`,
      fontFamily: contentFontFamilyRegular,
    },
    inlineContainer: {
      position: "relative",
      "& $formPercentageIcon": {
        height: "10px",
        width: "10px",
        display: "block",
      },

      fontFamily: contentFontFamilyRegular,
    },
    inputWrapper: {
      height: "88.6px",
    },
    disabledInput: {
      "& .MuiInputBase-root.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.5)!important",
        WebkitTextFillColor: "rgba(0, 0, 0, 0.5)!important",
      },
      "& .MuiInputBase-input.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.5)!important",
        WebkitTextFillColor: "rgba(0, 0, 0, 0.5)!important",
      },
    },
  })
);

const EnhancedPercentageInput: React.FC<IEnhancedPercentageInputProps> = ({
  name,
  title,
  value,
  placeholder,
  onChange,
  onBlur,
  material,
  disableNegativeValues,
  error,
  hidden = false,
  inline,
  className,
  description,
  style,
  disabled,
  onFocus,
  customStyles,
}) => {
  const tenant = useAppSelector((state) => state.tenant);
  const { classes, cx } = useStyles({ material });

  const minValue = 0;
  const maxValue = 100;

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let validation = false;
      if (!isEmpty(event.target.value)) {
        if (Number(event.target.value) < 0 && disableNegativeValues) {
          event.target.value = minValue?.toString() || "0";
        }
        const inputValue = Number(event.target.value);
        if (isValidNumber(minValue) && isValidNumber(maxValue)) {
          validation = isValidNumberInput(
            inputValue,
            Number(minValue),
            Number(maxValue)
          );
        } else if (isValidNumber(maxValue)) {
          validation = inputValue <= Number(maxValue);
        } else if (isValidNumber(minValue)) {
          validation = inputValue >= Number(minValue);
        } else {
          validation = true;
        }
      } else {
        validation = true;
      }
      if (validation) {
        onChange(event);
      }
    },
    [maxValue, minValue, onChange]
  );

  const renderInput = () => {
    const className = cx(
      classes.input,
      material ? classes.materialInput : classes.percentageInput,
      error ? classes.inputError : undefined,
      disabled ? classes.disabledInput : undefined
    );

    return (
      <div className={classes.percentageInputContainer}>
        <input
          className={className}
          // className={clsx(classes.input, {
          //   [classes.materialInput]: material,
          //   [classes.percentageInput]: !material,
          //   [classes.inputError]: error,
          //   [classes.disabledInput]: disabled,
          // })}
          style={{
            width: "100%",
          }}
          title={disabled && !isEmpty(value) ? value.toString() : title}
          id={name}
          name={name}
          aria-invalid={!!error}
          aria-describedby={`errorMsg-${name}`}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
          type="number"
          disabled={disabled}
          placeholder={placeholder}
          onWheel={(e) => (e.target as any).blur()}
          hidden={hidden}
        />
        <EnhancedIcon
          className={classes.percentageIcon}
          title={"%"}
          iconUrl={tenant.cdnUrl + "/icons/percentage-primary.png"}
          hidden={hidden}
        />
      </div>
    );
  };

  return inline ? (
    <span className={classes.inlineContainer}>{renderInput()}</span>
  ) : (
    <EnhancedInputsWrapper
      title={title}
      description={description}
      error={error}
      name={name}
      className={clsx(classes.inputWrapper, className)}
      style={style}
      customStyles={customStyles}
      hidden={hidden}
    >
      <div>{renderInput()}</div>
    </EnhancedInputsWrapper>
  );
};

export default EnhancedPercentageInput;
