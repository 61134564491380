import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  createProduct,
  getAccountingEnums,
  updateProduct,
  getProductInfo,
  GetIncomeAccounts,
  GetExpenseAccounts,
  validateName,
  validateNameOnUpdate
} from "./queries";
import { LookupToList, graphqlEntityToProductInfo, IncomeAccountEntityToList, ExpenseAccountEntityToList, EntityProductToList, UpdateEntityProductToList } from "./utils";
import Loader from "../../components/Loader";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { isEmpty } from "../../utils/validationUtils";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { getError } from "../../utils/graph-utils";
import { useNavigate } from "react-router-dom";

const ProductDrawer: React.FC<IProductDrawerProps> = ({
  productId,
  open,
  onSuccess,
  onClose,
}) => {

  const navigate = useNavigate();

  // const accountEnumResults = useQuery(getAccountingEnums(), {
  //   fetchPolicy: "no-cache",
  // });


  const [accountEnumResultsQeury] = useLazyQuery(getAccountingEnums());
  const [incomeAccountsQeury] = useLazyQuery(GetIncomeAccounts());
  const [expenseAccountsQeury] = useLazyQuery(GetExpenseAccounts());
  const [validationQuery] = useLazyQuery(validateName());
  const [validationQueryOnUpdate] = useLazyQuery(validateNameOnUpdate());

  const [accountAction] = useMutation(
    productId ? updateProduct() : createProduct()
  );

  const [productInfoQuery] = useLazyQuery(getProductInfo());

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    currency: {},
    relatedCompanies: {}
  });

  const [incomeAccounts, setIncomeAccounts] = useState<Record<string, string>>({

  });

  const [expenseAccounts, setExpenseAccounts] = useState<Record<string, string>>({

  });

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>();

  const [booted, setBooted] = useState<boolean>(false);

  const initialize = async () => {
    setBooted(false);
    try {

      const values = {
        company: '',
        name: '',
        description: '',
        price: 0,
        incomeAccount: '',
        expenseAccount: '',
        BuySell: '',
        BuyThis: false,
        SellThis: false
      };

      let newLovs: Record<string, Record<string, string>> = {};
      let incomeAccounts: Record<string, string> = {};
      let expenseAccounts: Record<string, string> = {};

      const promises: Promise<any>[] = [];
      const accountEnumResultsQeuryPromise = accountEnumResultsQeury({
        fetchPolicy: "no-cache"
      });

      promises.push(accountEnumResultsQeuryPromise);

      const incomeAccountsQeuryPromise = incomeAccountsQeury({
        variables: { SelectedCompanyID: values.company === '' ? null : values.company },
        fetchPolicy: "no-cache"
      });

      promises.push(incomeAccountsQeuryPromise);

      const expenseAccountsQeuryPromise = expenseAccountsQeury({
        variables: { SelectedCompanyID: values.company === '' ? null : values.company },
        fetchPolicy: "no-cache"
      });

      promises.push(expenseAccountsQeuryPromise);

      if (productId) {
        const productInfoResultPromise = productInfoQuery({
          variables: { id: productId },
          fetchPolicy: "no-cache",
        });
        promises.push(productInfoResultPromise);
      }


      const result = await Promise.all(promises);

      if (result[0]?.data) {
        const newAccountEnums = LookupToList(result[0].data);

        newLovs = {
          type: newAccountEnums["Type"],
          currency: newAccountEnums["Currency"],
          relatedCompanies: newAccountEnums["relatedCompanies"]
        }
      }
      if (result[1]?.data) {
        incomeAccounts = IncomeAccountEntityToList(result[1].data);
      }

      if (result[2]?.data) {
        expenseAccounts = ExpenseAccountEntityToList(result[2].data);
      }

      if (productId && result[3]?.data) {

        if (result[3]?.data) {
          const productEntity = graphqlEntityToProductInfo(result[3]?.data);
          if (productEntity) {
            values.name = productEntity.name;
            values.description = productEntity.description;
            values.price = productEntity.price;
            values.description = productEntity.description;
            values.BuySell = productEntity.BuySell;
            values.BuyThis = productEntity.BuyThis;
            values.SellThis = productEntity.SellThis;
            values.incomeAccount =
            productEntity.incomeAccount === "" ? null : productEntity.incomeAccount;
            values.expenseAccount =
            productEntity.expenseAccount === "" ? null : productEntity.expenseAccount;
            values.company = productEntity.company;
          }
        }
      }
      const newInputForm = inputs(!!productId ? "edit" : "add", values, newLovs, incomeAccounts, expenseAccounts);
      setLovs(newLovs);
      setIncomeAccounts(incomeAccounts);
      setExpenseAccounts(expenseAccounts);
      setInputsForm(newInputForm);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setBooted(true);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputsForm, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variables = {
        EnteredProductDescription: data.description,
        EnteredProductName: data.name,
        EnteredProductPrice: data.price === '' ? null  : Number(data.price),
        RelatedExpenseAccount: data.expenseAccount === '' ? null : data.expenseAccount,
        RelatedIncomeAccount: data.incomeAccount === '' ? null : data.incomeAccount,
        SelectedCompany: data.company,
        ProductBuy: data.BuyThis === '' ? false : data.BuyThis,
        ProductSell: data.SellThis === '' ? false : data.SellThis,
      };

      accountAction({
        variables: productId ? { EnteredProductDescription: data.description, EnteredProductName: data.name, EnteredProductPrice: data.price === '' ? null  : Number(data.price), CurrentProductID: productId } : variables,
        errorPolicy: "all",
      }).then((res) => {
        if (isEmpty(res.errors)) {
          toast.success(
            <ToastSuccessMessage>
              {productId
                ? "Product successfully updated"
                : "Product successfully created"}
            </ToastSuccessMessage>
          );
          setTimeout(() => {
            setSubmitButtonState("success");
            onSuccess();
            onClose();
            if (!productId) {
              navigate(`/accounting/products`);
            }
          }, 500);
        } else {
          setSubmitButtonState(undefined);
          toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
        }
      });
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const onCustomChange = async (fieldName: string, value: any, allValues: Record<string, any>, errors: Record<string, any>) => {
    const currentMode = !!productId ? "edit" : "add";
    const newLovs = { ...lovs };
    let incomeAccount = { ...incomeAccounts }
    let expenseAccount = { ...expenseAccounts }
    let newInputForm = inputs(currentMode, allValues, newLovs, incomeAccount, expenseAccount);
    
    if (fieldName === "company" || ((fieldName === "SellThis" || fieldName === "BuyThis")) && allValues.company && allValues.company.length !== 0) {
        
        const promises: Promise<any>[] = [];
        const incomeAccountsQeuryPromise = incomeAccountsQeury({
            variables: { SelectedCompanyID: allValues.company === '' ? null : allValues.company },
            fetchPolicy: "no-cache"
          });
  
        promises.push(incomeAccountsQeuryPromise);

        const expenseAccountsQeuryPromise = expenseAccountsQeury({
            variables: { SelectedCompanyID: allValues.company === '' ? null : allValues.company },
            fetchPolicy: "no-cache"
          });
  
        promises.push(expenseAccountsQeuryPromise);
  
        const result = await Promise.all(promises);
  
        if (result[0]?.data) {
            allValues.incomeAccount = '';
            incomeAccount = IncomeAccountEntityToList(result[0].data);
        }
        else{
            allValues.incomeAccount = '';
            incomeAccount = {};
        }

        if (result[1]?.data) {
            allValues.expenseAccount = '';
            expenseAccount = ExpenseAccountEntityToList(result[1].data);
        }
        else {
            allValues.expenseAccount = '';
            expenseAccount = {};
        }

        if (currentMode === "add" && allValues.name) {
            setFormDisabled(true);
            const promises: Promise<any>[] = [];
            const validationPromise = validationQuery({
              variables: { Name: allValues.name === '' ? null : allValues.name, ProductCompany: allValues.company.length === 0 ? null : allValues.company },
              fetchPolicy: "no-cache"
            });
    
            promises.push(validationPromise);
            const result = await Promise.all(promises);
            if (result[0]?.data) {
              const sameAccounts = EntityProductToList(result[0].data);
              if (Object.keys(sameAccounts).length > 0)
                errors["name"] = "Product name already exists";
              else
                errors["name"] = null;
            }
          }

          setFormDisabled(false);

        newInputForm = inputs(currentMode, allValues, newLovs, incomeAccount, expenseAccount);
      }

    setInputsForm(newInputForm);
  }


  const onCustomBlur = async (fieldName: string, values: Record<string, any>, errors: Record<string, any>) => {

    const newValues = { ...values };
    const newErrors = { ...errors };

    const currentMode = !!productId ? "edit" : "add";

    if (fieldName === "name") {

      if (fieldName === "name" && isEmpty(values.name)) {
        return { values: newValues, errors: newErrors };
      }

      if (currentMode === "add") {
        setFormDisabled(true);
        const promises: Promise<any>[] = [];
        const validationPromise = validationQuery({
          variables: { Name: values.name === '' ? null : values.name, Id: values.id === '' ? null : values.id, ProductCompany: values.company.length === 0 ? null : values.company },
          fetchPolicy: "no-cache"
        });

        promises.push(validationPromise);
        const result = await Promise.all(promises);
        if (result[0]?.data) {
          const sameAccounts = EntityProductToList(result[0].data);
          if (Object.keys(sameAccounts).length > 0)
            newErrors[fieldName] = "Product name already exists";
          else
            newErrors[fieldName] = null;
        }
      }
      else if (currentMode === "edit") {
        setFormDisabled(true);
        const promises: Promise<any>[] = [];
        const validationPromise = validationQueryOnUpdate({
          variables: { Name: values.name === '' ? null : values.name, Id: values.id === '' ? null : values.id, ProductID: productId, ProductCompany: values.company.length === 0 ? null : values.company },
          fetchPolicy: "no-cache"
        });

        promises.push(validationPromise);
        const result = await Promise.all(promises);
        if (result[0]?.data) {
          const sameAccounts = UpdateEntityProductToList(result[0].data);
          if (Object.keys(sameAccounts).length > 0)
            newErrors[fieldName] = "Product name already exists";
          else
            newErrors[fieldName] = null;
        }
      }

      setFormDisabled(false);
    }

    return { values: newValues, errors: newErrors };
  }

  return (
    <GenericDrawer
      title={productId ? "Modify Product" : "Add New Product"}
      onClose={() => onClose()}
      isOpen={open}
    >


      {(!booted || !inputsForm) && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Product Information"
            onChange={onCustomChange}
            onCustomBlur={onCustomBlur}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default ProductDrawer;
