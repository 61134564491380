import dayjs from "dayjs";
import React, { useMemo } from "react";
import { IDynamicDiplayView } from "../../../components/widgets/dynamic-display";
import DynamicWidget from "../../../components/widgets/dynamic-display/DynamicWidget";
import { capitalizeFirstLetter } from "../../../utils/formatting-utils";

interface IProviderDetailsWidgetProps {
  data: Record<string, any>;
  actions?: JSX.Element;
}

const ProviderDetailsWidget: React.FC<IProviderDetailsWidgetProps> = ({ data, actions }) => {

  const view = useMemo(() => {
    const dynamicView: IDynamicDiplayView = {
      name: "Provider Information",
      sections: [
        {
          name: "providerDetails",
          title: "Provider Details",
          showActionButtons: true,
          properties: {
            type: {
              multiline: false,
              value: capitalizeFirstLetter(data.typeTitle),
              name: "type",
              title: "Type",
            },
            isCompany: {
              multiline: false,
              value: data.isCompany ? "Yes" : "No",
              name: "isCompany",
              title: "Is Company",
            },
            isLocal: {
              multiline: false,
              value: data.isLocal ? "Yes" : "No",
              name: "isLocal",
              title: "Is Local",
            },
            effectiveDate: {
              multiline: false,
              value: dayjs(new Date(data.effectiveDate)).format(
                "DD/MM/YYYY"
              ),
              name: "effectiveDate",
              title: "Effective Date",
            },
            expiryDate: {
              multiline: false,
              value: dayjs(new Date(data.expiryDate)).format(
                "DD/MM/YYYY"
              ),
              name: "expiryDate",
              title: "Expiry Date",
              hidden: !(data.status === "inactive"),
            },
          },
        },
      ],
    };

    return dynamicView
  }, [data]);



  return (
    <DynamicWidget
      hasFourFields={true}
      view={view}
      actions={actions}
    />
  )
}

export default ProviderDetailsWidget;