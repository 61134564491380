import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery, useQuery } from "@apollo/client";
import { filterQuery, listQuery } from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { actions, filterSectionsContent, getHeaders } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import dayjs from "dayjs";
import TaxationDrawer from "../../forms/taxation-drawer/TaxationDrawer";
import { ITableOrder, TableSortOrder } from "../../utils/table-utils";
import { capitalizeFirstCharacter } from "../../utils/formatting-utils";

const TaxationsPage: React.FC = () => {
  const navigate = useNavigate();

  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [taxationDrawerOpen, setTaxationDrawerOpen] = useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>("");

  let setSearchTimeout: NodeJS.Timeout;

  const FILTER_SESSION_KEY = "taxationsFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
        line: [],
        date: null,
        createdOn: [],
      };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [booted, setBooted] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState("");

  const [tableOrder, setTableOrder] = useState<Record<string, ITableOrder>>({
    tax: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getTaxationsLazy] = useLazyQuery(listQuery());

  const loadTaxationsList = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    orders = tableOrder,
    search = keywordSearch,
    filterV = filterValues,
  ) => {
    setLoadingState(true); // Set loading state

    const dateValue = dayjs(filterV?.date).isValid() ? dayjs(filterV?.date).toDate()?.toString() : null;

    const result = await getTaxationsLazy({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        selectedLines:
          filterV?.line && filterV.line.length > 0 ? filterV.line : [],
        selectedDate:
          dateValue && dateValue.length > 0
            ? dayjs(dateValue).format(SEND_TO_BACKEND_DATE_FORMAT)
            : null,
        isSelectedDate:
          dateValue && dateValue.length > 0 ? true : false,
        createdDateFrom:
          filterV?.createdOn && filterV?.createdOn?.[0]
            ? dayjs(filterV?.createdOn?.[0]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
            : null,
        createdDateTo:
          filterV?.createdOn && filterV?.createdOn?.[1]
            ? dayjs(filterV?.createdOn?.[1]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
            : null,
        Attribute: capitalizeFirstCharacter(orders.tax.orderBy || "line_Name"),
        Descending: orders.tax.orderDirection !== "asc",
        keywordSearch: search,
      },
      errorPolicy: "all",
    });

    const mappedPlanList = await mapToListingData(result.data);

    if (result.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }

    setKeywordSearch(search);
    setTableData({ ...mappedPlanList, pageNumber: currentPage, pageSize });
    setLoadingState(false); // Reset loading state

    return result;
  };

  const initialize = async () => {
    try {
      await loadTaxationsList();
      const filterDataResponse = await filterResponse.refetch();
      const lovs = dataToLovs(filterDataResponse.data);
      const savedFilters = getFilter(FILTER_SESSION_KEY) || filterValues;

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterValues(savedFilters);
      setFilterSections(newFilterSections);
      setBooted(true);
    } catch (error) {
      console.error("Error initializing data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const filterResponse = useQuery(filterQuery(), {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (filterResponse?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (filterResponse?.data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = dataToLovs(filterResponse.data);
      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);
    }
  }, [filterResponse]);

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      loadTaxationsList(0, tableData.pageSize, tableOrder, val, filterValues);
    }, 1000);
  };

  const handleSortChange = (orderBy: string, order: string) => {
    const newOrder = _.cloneDeep(tableOrder);
    newOrder.tax.orderBy = orderBy;
    newOrder.tax.orderDirection = order as any;
    loadTaxationsList(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder,
      keywordSearch,
      filterValues
    );
    setTableOrder(newOrder);
  };

  const handlePageChange = async (
    page: number,
    pageSize: number
  ) => {
    await loadTaxationsList(page, pageSize, tableOrder, keywordSearch, filterValues);
  };

  const renderMainChildren = () => {
    const headers = getHeaders(navigate);
    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedTable
            title="Taxation"
            name="count"
            orderByAscendingByDefault
            inline={false}
            data={tableData}
            headers={headers}
            entityIdColumnName={"Id"}
            handlePageChange={(page: number) =>
              handlePageChange(page, tableData.pageSize)
            }
            handleSearchChange={delaySearch}
            handleSort={handleSortChange}
            handleRowsPerPageChange={(pageSize: number) =>
              handlePageChange(0, pageSize)
            }
            currentPage={tableData.pageNumber}
            hideToolbar={false}
            isToolbarAction={false}
            usePagination
            disableSelection={false}
            actions={actions(setTaxationDrawerOpen, setChosenEditId)}
            loader={loadingState}
            isServerSide
            ordering={tableOrder.tax.orderDirection as TableSortOrder}
            orderingBy={tableOrder.tax.orderBy}
          />
          {taxationDrawerOpen && (
            <TaxationDrawer
              open={taxationDrawerOpen}
              onClose={() => {
                setChosenEditId("");
                setTaxationDrawerOpen(false)
              }}
              onSuccess={() => {
                setChosenEditId("");
                handlePageChange(0, 10);
              }}
              taxationId={chosenEditId}
            />
          )}
        </div>
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {

    if (_.isEqual(v, filterValues)) {
      return;
    }

    setFilter(v, FILTER_SESSION_KEY);
    loadTaxationsList(0, tableData.pageSize, tableOrder, keywordSearch, v);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={""}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
          disabled={loadingState}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={!booted}
      name={"Applications"}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default TaxationsPage;
