import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery } from "@apollo/client";
import { filterQuery, lineFilter, listQuery } from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { actions, filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _ from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import { IEnhanceTableHeaderClickable } from "../../components/enhanced-table";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import dayjs from "dayjs";
import { useAppSelector } from "../../redux/hooks";
import ProposalDrawer from "../../forms/proposal-drawer/ProposalDrawer";
import Loader from "../../components/Loader";

interface IProposalPage { }

const ProposalPage: React.FC<IProposalPage> = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const businessUserId = user.info.businessUser_Id;

  const [booted, setBooted] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [proposalDrawerOpen, setProposalDrawerOpen] = useState<boolean>(false);
  const [chosenEditId, setChosenEditId] = useState<string>("");

  const FILTER_SESSION_KEY = "proposalsFilter";

  const initialFilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
        line: [],
        subline: [],
        plan: [],
        businessPartner: [],
        insured: [],
        SelectedStatuses: [],
        SelectedStatusReason: [],
        effectiveOn: [],
      };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialFilterValues);

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getProductionPoliciesLazy] = useLazyQuery(listQuery(), {
    fetchPolicy: "no-cache",
  });
  const [filterResponseLazy] = useLazyQuery(filterQuery(), {
    fetchPolicy: "no-cache",
  });
  const [filterLineLazy] = useLazyQuery(lineFilter(), {
    fetchPolicy: "no-cache",
  });

  const loadData = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    filterV = filterValues
  ) => {
    setLoadingState(true);
    try {
      const [proposalsResult, filterResponseResult, filterLineResult] =
        await Promise.all([
          getProductionPoliciesLazy({
            variables: {
              currentPage: currentPage + 1,
              currentPageSize: pageSize,
              selectedLines:
                filterV?.line && filterV?.line?.length > 0 ? filterV?.line : [],
              selectedSublines:
                filterV?.subline && filterV?.subline?.length > 0
                  ? filterV?.subline
                  : [],
              selectedPlans:
                filterV?.plan && filterV?.plan?.length > 0
                  ? filterV?.plan
                  : [],
              selectedBusinessPartners:
                filterV?.businessPartner &&
                  filterV?.businessPartner?.length > 0
                  ? filterV?.businessPartner
                  : [],
              selectedInsuredIDs:
                filterV?.insured && filterV?.insured?.length > 0
                  ? filterV?.insured
                  : [],
              SelectedStatuses:
                filterV?.status && filterV?.status?.length > 0
                  ? filterV.status
                  : null,
              selectedStatusReasons:
                filterV?.statusReason && filterV?.statusReason?.length > 0
                  ? filterV.statusReason
                  : null,
              FromEffectiveOn: filterV?.effectiveOn?.[0]
                ? dayjs(filterV?.effectiveOn?.[0]).format(
                  SEND_TO_BACKEND_DATE_FORMAT
                )
                : null,
              ToEffectiveOn: filterV?.effectiveOn?.[1]
                ? dayjs(filterV?.effectiveOn?.[1]).format(
                  SEND_TO_BACKEND_DATE_FORMAT
                )
                : null,
            },
          }),
          filterResponseLazy({
            variables: {
              selectedBusinessUserID: businessUserId,
              selectedLineID: filterV.line,
              selectedBusinessPartners: filterV?.businessPartner,
            },
          }),
          filterLineLazy({
            variables: {
              selectedBusinessUserID: businessUserId,
            },
          }),
        ]);

      if (proposalsResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }
      if (filterResponseResult.error || filterLineResult.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }

      const proposalsData = proposalsResult.data;
      const filtersData = filterResponseResult.data;
      const lineFiltersData = filterLineResult.data;

      // Update table data
      const newTableData = mapToListingData(proposalsData);
      setTableData({ ...newTableData, pageNumber: currentPage, pageSize });

      const savedFilters = filterV;

      // Update filters
      const lovs = dataToLovs(filtersData, lineFiltersData, savedFilters);

      setFilterValues(savedFilters);

      if (!Object.keys(lovs.line).length) {
        lovs.line = filterV?.line;
      }

      if (!Object.keys(lovs.subline).length) {
        lovs.subline = filterV?.subline;
      }

      if (!Object.keys(lovs.plan).length) {
        lovs.plan = filterV?.plan;
      }
      if (savedFilters.subline) {
        savedFilters.subline = savedFilters.subline.filter((val: string) =>
          Object.keys(lovs.subline).includes(val)
        );
      }
      if (savedFilters.plan) {
        savedFilters.plan = savedFilters.plan.filter((val: string) =>
          Object.keys(lovs.plan).includes(val)
        );
      }

      if (!Object.keys(lovs.businessPartner).length) {
        lovs.businessPartner = filterV?.businessPartner;
      }

      if (!Object.keys(lovs.insured).length) {
        lovs.insured = filterV?.insured;
      }

      if (!Object.keys(lovs.status).length) {
        lovs.status = filterV?.status;
      }

      if (!Object.keys(lovs.statusReason).length) {
        lovs.statusReason = filterV?.statusReason;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);

      setFilter(savedFilters, FILTER_SESSION_KEY);
    } catch (error) {
      console.error("Error loading data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoadingState(false);
      setBooted(true);
    }
  };

  const initialize = async () => {
    const savedFilters = getFilter(FILTER_SESSION_KEY);
    await loadData(0, tableData.pageSize, savedFilters);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (page: number) => {
    loadData(page, tableData.pageSize, filterValues);
  };

  const handleRowsPerPageChange = (numberOfRecordsPerPage: number) => {
    loadData(0, numberOfRecordsPerPage, filterValues);
  };

  const onFilterUpdate = async (v: Record<string, any>) => {

    if (_.isEqual(v, filterValues)) {
      // Do nothing if filters are the same
      return;
    }
    const newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    await loadData(0, tableData.pageSize, newFilters);
  };

  const isBusinessUser = user.userRoles.includes("Core-BusinessUser");
  const isAdmin = user.userRoles.includes("Insurance-Admin");
  actions[0].hidden = !(isBusinessUser || isAdmin);

  actions[0].onClick = () => {
    setChosenEditId(undefined);
    setProposalDrawerOpen(true);
  };

  (headers.name as IEnhanceTableHeaderClickable).callback = (payload: any) => {
    navigate("/production/proposal/" + payload.columns.id);
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: "20px" }}>
      <EnhancedTable
        title="Proposals"
        name="proposals"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        currentPage={tableData.pageNumber}
        hideToolbar
        usePagination
        disableSelection
        actions={actions}
        loader={loadingState}
      />
      {proposalDrawerOpen && (
        <ProposalDrawer
          open={proposalDrawerOpen}
          onClose={() => setProposalDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0);
          }}
          proposalId={chosenEditId}
          proposalDetailsInfo={undefined}
        />
      )}
    </div>
  );

  const renderFilter = () =>
    filterSections && (
      <ListingFilterWidget
        name=""
        disabled={loadingState}
        filters={filterSections}
        onApplyFilter={onFilterUpdate}
      />
    );

  if (!booted) {
    return <Loader />;
  }

  return (
    <StaticLayout
      name="Proposals"
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ProposalPage;
