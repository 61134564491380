import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/Loader";
import { gridWidthToPercentage } from "../../../../utils/grid-utils";
import EnhancedStepper from "../../../../components/common/EnhancedStepper";
import ProductionPolicyEntityInfo from "./ProductionPolicyEntityInfo";
import { MAIN_ONE_THEME } from "../../../../constants";
import ProductionPolicyDetailsWidget from "./SummaryTab/PolicyDetailsWidget";
import TabsLayout from "../../../../page-layout/tabs-layout/TabsLayout";
import { useAppSelector } from "../../../../redux/hooks";
import { productionPolicyPage } from "./content";
import _ from "lodash";
import ProductionPolicyCoversInfo from "./SummaryTab/ProductionPolicyCoversInfo";
import ProductionCostChargesWidget from "./SummaryTab/ProductionCostChargesWidget";
import ProductionPolicyMotorDetailsWidget from "./SummaryTab/ProductionPolicyMotorDetailsWidget";
import { getProductionDocuments, getProductionPolicyDetails } from "./queries";
import { useLazyQuery } from "@apollo/client";
import { GetDocumentsData, GetProductionPolicyData } from "./functions";
import { IDocumentsDetails, IProductionDetailsSummary } from ".";
import ProductionPolicyQuoteWidget from "./SummaryTab/PolicyProposalQuoteWidget";
import PersonsWidget from "./custom-components/PersonsWidget";
import { ProductionComissionWidget } from "./custom-components/commission-widget/ProductionComissionWidget";
import DocumentWidget from "../../../../components/widgets/file-card/DocumentWidget";
import {
  FileType,
  FileUploadStatus,
  IFile,
  IFileCardProps,
} from "../../../../models/file";
import Separator from "../../../../components/common/Separator";
import EnhancedButton from "../../../../components/EnhancedButton";
import AddDocumentDrawer from "./drawers/AddDocumentDrawer";
import ProductionPolicyExpatDetailsWidget from "./SummaryTab/ProductionPolicyExpatDetailsWidget";
import ProductionPolicyPaymentTermsSchedule from "./PaymentTermsTab/ProductionPolicyPaymentTermsSchedule";
import ProductionPolicyBankersDetailsInfo from "./ProductionPolicyBankersInfo";
import ProductionPolicyMarineDetailsWidget from "./SummaryTab/ProductionPolicyMarineDetailsWidget";
import ProductionPolicyMarineHullDetailsWidget from "./SummaryTab/ProductionPolicyMarineHullDetailsWidget";
interface IProductionPolicyPageProps {}

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "95%",
    width: "95%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  buttonsContainer: {
    width: "95%",
    margin: "10px auto 0",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  widgetTopMargin: {
    marginTop: "1em",
  },
  tabPanelClassName: {
    padding: "24px 0",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
}));

const ProductionPolicyPage: React.FC<IProductionPolicyPageProps> = () => {
  const { classes } = useStyles();

  const user = useAppSelector((state) => state.user);
  const tenant = useAppSelector((state) => state.tenant);

  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const entityId = params.id;

  const [data, setData] = useState<IProductionDetailsSummary>();
  const [documentsData, setDocumentsData] = useState<IDocumentsDetails>();

  const [getProposalDetailsLazy] = useLazyQuery(getProductionPolicyDetails());
  const [geDocumentsDetailsLazy] = useLazyQuery(getProductionDocuments());

  const [addDocumentDrawerOpen, setAddDocumentDrawerOpen] =
    useState<boolean>(false);

  const initialize = async () => {
    setLoading(true);
    const result: { data: any } = await getProposalDetailsLazy({
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });

    const summaryData = GetProductionPolicyData(result.data);

    setData(summaryData);
    setLoading(false);
  };

  const loadDocuments = async () => {
    const result: { data: any } = await geDocumentsDetailsLazy({
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });

    const summaryData = GetDocumentsData(result.data);

    setDocumentsData(summaryData);
  };

  useEffect(() => {
    initialize();
    loadDocuments();
  }, []);

  const renderLeftSection = () => {
    if (data) {
      return <ProductionPolicyEntityInfo data={data} />;
    }

    return <></>;
  };

  const renderMainChildren = () => {
    if (data) {
      const steps: string[] = ["Bound", "Inforce", "Cancelled"];

      const activeStep = steps.find(
        (a) => a.toLowerCase() === data.Status?.toLowerCase()
      );

      return (
        <>
          <div style={{ marginTop: "20px" }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div style={{ width: `${gridWidthToPercentage(8)}%` }}>
                <EnhancedStepper
                  activeStep={activeStep}
                  steps={steps}
                  className={classes.stepper}
                />
              </div>
            </div>
          </div>
        </>
      );
    }

    return <></>;
  };

  const addFileToDocumentWidgetFiles = (
    file: IFile,
    propertyId: string,
    entityTypeId: string,
    hideReviewedLabel: boolean = true,
    createdOn?: string,
    version?: number,
    documentCode: string = ""
  ) => {
    let newFile: IFileCardProps = {
      title: documentCode,
      status: FileUploadStatus.Uploaded,
      createdOn: createdOn,
      version: version,
      fileInfo: {
        id: file?.id,
        location: file?.location,
        path: file?.path,
        fileName: file?.fileName,
        length: file?.length,
        contentType: file?.contentType,
      },
      allowedFileTypes: [
        FileType.pdf,
        FileType.docx,
        FileType.jpeg,
        FileType.png,
      ],
      entityViewId: "Production-download",
      propertyId: propertyId,
      entityId: file?.path?.split("/")[2],
      entityTypeId: entityTypeId,
      hideReviewedLabel: hideReviewedLabel,
    };

    return newFile;
  };
  const renderTabs = useMemo(() => {
    if (!data) {
      return null;
    }

    const documentWidgetFiles: IFileCardProps[] = [];
    const additionalDocumentWidgetFiles: IFileCardProps[] = [];

    if (documentsData) {
      if (
        documentsData.regeneratedDocuments &&
        documentsData.regeneratedDocuments.length > 0
      ) {
        documentsData.regeneratedDocuments.forEach((policyDocument) => {
          documentWidgetFiles.push(
            addFileToDocumentWidgetFiles(
              policyDocument.file,
              "Document",
              "Production-PolicyDocument",
              true,
              policyDocument.createdOn || "",
              policyDocument.version,
              policyDocument.code
            )
          );
        });
      }

      if (
        documentsData.additionalDocuments &&
        documentsData.additionalDocuments.length > 0
      ) {
        documentsData.additionalDocuments.forEach((policyDocument) => {
          additionalDocumentWidgetFiles.push(
            addFileToDocumentWidgetFiles(
              policyDocument.file,
              "Document",
              "Production-PolicyDocument",
              true,
              policyDocument.createdOn,
              policyDocument.version,
              policyDocument.code
            )
          );
        });
      }
    }
    const tabs = _.cloneDeep(productionPolicyPage);

    tabs.tabs[0].widgets[0].children = (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            width: `${gridWidthToPercentage(8)}%`,
            padding: "0px 1em 1em 0",
          }}
        >
          <ProductionPolicyDetailsWidget data={data} />
          {["51", "50", "4"].includes(data.LineId.ExternalCode) && (
            <>
              <div style={{ height: "1em" }} />
              <ProductionPolicyMotorDetailsWidget data={data} />
            </>
          )}
          {data?.LineId?.ExternalCode === "43" && (
            <>
              <div style={{ height: "1em" }} />
              <ProductionPolicyExpatDetailsWidget data={data} />
            </>
          )}

          {data?.LineId?.ExternalCode === "3" && (
            <>
              <div style={{ height: "1em" }} />
              <ProductionPolicyMarineDetailsWidget data={data} />
            </>
          )}

          {(data?.LineId?.ExternalCode === "33" ||
            data?.LineId?.ExternalCode === "20") && (
            <>
              <div style={{ height: "1em" }} />
              <ProductionPolicyMarineHullDetailsWidget data={data} />
            </>
          )}

          {data?.Covers?.length > 0 && (
            <>
              <div style={{ height: "1em" }} />
              <ProductionPolicyCoversInfo
                currencySymbol={data?.PolicyCurrency?.Symbol || ""}
                data={data.Covers}
                lineExternalCode={Number(data.LineId.ExternalCode)}
              />
            </>
          )}
          {data?.Bankers?.length > 0 && data?.LineId?.ExternalCode === "12" && (
            <>
              <div style={{ height: "1em" }} />
              <ProductionPolicyBankersDetailsInfo data={data.Bankers} />
            </>
          )}
          <div style={{ height: "1em" }} />
          <ProductionCostChargesWidget data={data} />
        </div>

        <div
          style={{
            width: `${gridWidthToPercentage(4)}%`,
            padding: "0",
          }}
        >
          <ProductionPolicyQuoteWidget data={data} />
          <ProductionComissionWidget
            className={classes.widgetTopMargin}
            data={data}
          />
        </div>
      </div>
    );

    tabs.tabs[1].widgets[0].children = (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            width: `${gridWidthToPercentage(12)}%`,
            padding: "0px 1em 1em 0",
          }}
        >
          <PersonsWidget data={data} />
        </div>
      </div>
    );

    tabs.tabs[2].widgets[0].children = (
      <>
        <DocumentWidget
          title="Policy Documents"
          cdnUrl={tenant.cdnUrl}
          files={documentWidgetFiles}
        />
        <Separator className={classes.thickSeperator} />
        <DocumentWidget
          title="Additional Documents"
          cdnUrl={tenant.cdnUrl}
          files={additionalDocumentWidgetFiles}
          disabledAddDocumentAction={false}
          actions={
            <EnhancedButton
              type="button"
              backgroundColor={"#FFF"}
              color={MAIN_ONE_THEME.palette.primary4.main}
              style={{ display: "inline" }}
              onClick={() => {
                setAddDocumentDrawerOpen(true);
              }}
              disabled={addDocumentDrawerOpen}
            >
              Add Document
            </EnhancedButton>
          }
          policyEntityId={params.id}
        />
      </>
    );

    tabs.tabs[3].widgets[0].children = (
      <>
        <ProductionPolicyPaymentTermsSchedule policyId={params.id} />
      </>
    );

    return tabs;
  }, [data, documentsData]);

  return loading || !data ? (
    <Loader />
  ) : (
    <>
      <TabsLayout
        name="productionPolicyPage"
        layout={renderTabs}
        theme={MAIN_ONE_THEME}
        tabPanelClassName={classes.tabPanelClassName}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
        firstTabAsActiveTab={true}
        cdnUrl={tenant.cdnUrl}
        userInfo={user.info}
      />
      {addDocumentDrawerOpen && (
        <AddDocumentDrawer
          open={addDocumentDrawerOpen}
          onClose={() => {
            setAddDocumentDrawerOpen(false);
          }}
          onSuccess={() => {
            setAddDocumentDrawerOpen(false);
            loadDocuments();
          }}
          entityId={params.id}
        />
      )}
    </>
  );
};

export default ProductionPolicyPage;
