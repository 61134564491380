import { IListingData } from '../../models/listing';
import {
    lookupListAsRecordObject,
  } from "../../utils/graph-utils";

export function mapToListingData(data: any): IListingData<any> {
  const list: Record<string, ITransfersGenericTableData> = {};
  data.Accounting.queries.TransfersList?.items?.forEach(
    (obj: ITransfersGenericTableData, i: number) => {
      list[i] = {
        accounting_AccountingTransfers_Id: obj.accounting_AccountingTransfers_Id,
        accounting_AccountingTransfers_RelatedCompany: obj.accounting_AccountingTransfers_RelatedCompany.id,
        accounting_AccountingTransfers_TransferMonth: obj.accounting_AccountingTransfers_TransferMonth,
        accounting_AccountingTransfers_TransferNumber: obj.accounting_AccountingTransfers_TransferNumber,
        accounting_AccountingTransfers_TransferPeriodFrom: obj.accounting_AccountingTransfers_TransferPeriodFrom,
        accounting_AccountingTransfers_TransferPeriodTo: obj.accounting_AccountingTransfers_TransferPeriodTo,
        accounting_AccountingTransfers_TransferType: obj.accounting_AccountingTransfers_TransferType.Title,
        accounting_AccountingTransfers_TransferYear: obj.accounting_AccountingTransfers_TransferYear,
        accounting_AccountingTransfers_createdOn: obj.accounting_AccountingTransfers_createdOn,
        accounting_AccountingTransfers_modifiedOn: obj.accounting_AccountingTransfers_modifiedOn,
      };
    },
  );
  

  return {
    pagedItems: list,
    pageSize: data.Accounting.queries.TransfersList?.paging.pageSize,
    pageNumber: data.Accounting.queries.TransfersList?.paging.pageNumber,
    totalCount: data.Accounting.queries.TransfersList?.paging.totalCount,
  };
}

  export function toLookupsData(data: any) {
    let lovs: Record<string, Record<string, string>> = {
      company: {},
      Type: {}
    };

      lovs.company = lookupListAsRecordObject(
        data?.SalesforceManagement?.lookups?.relatedCompanies
      );

      lovs.Type = lookupListAsRecordObject(
        data?.Accounting?.lookups?.transferType
      );
  
    return lovs;
  }
