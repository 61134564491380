import { IGenericPlanTableData } from ".";
import { IAbstractRecord } from "../../models";
import { IListingData } from "../../models/listing";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import { steps } from "./content";

export function entityToSublineDetails(data: any): any {
  const sublineDetailsInfo =
    data?.PlanConfigManagement?.entities?.subline?.views
      ?.PlanConfigManagement_all?.properties;

  return {
    lineId: sublineDetailsInfo?.LineID?.id,
    sublineName: sublineDetailsInfo?.Name,
    lineName:
      sublineDetailsInfo?.LineID?.views?.PlanConfigManagement_all?.properties
        ?.Name,
    externalCode: sublineDetailsInfo?.ExternalCode,
    arabicName: sublineDetailsInfo?.NameArabic,
    lineStatus:
      sublineDetailsInfo?.LineID?.views?.PlanConfigManagement_all?.properties
        ?.Status,
    status: sublineDetailsInfo?.Status,
    createdBy: `${data?.PlanConfigManagement?.entities?.subline?.queries?.planConfigManagement?.relatedUsers[0]?.createdBy_firstName} ${data?.PlanConfigManagement?.entities?.subline?.queries?.planConfigManagement?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: sublineDetailsInfo?.createdOn,
    modifiedBy: `${data?.PlanConfigManagement?.entities?.subline?.queries?.planConfigManagement?.relatedUsers[0]?.modifiedBy_firstName} ${data?.PlanConfigManagement?.entities?.subline?.queries?.planConfigManagement?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: sublineDetailsInfo?.modifiedOn,
  };
}

export const getSublineStatus = (status: string) => {
  switch (status) {
    case "inactive":
      return steps[0];
    case "active":
      return steps[1];
    default:
      return steps[0];
  }
};

export function mapToPlansListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.PlanConfigManagement.queries.getPlanList.items.forEach(
    (obj: IGenericPlanTableData, i: number) => {
      const sublineName =
        obj.planConfigManagement_Plan_SublineID?.views?.PlanConfigManagement_all
          ?.properties?.Name;
      list[i] = {
        planConfigManagement_Plan_Id: obj.planConfigManagement_Plan_Id,
        planConfigManagement_Plan_EffectiveFrom:
          obj.planConfigManagement_Plan_EffectiveFrom,
        planConfigManagement_Plan_EffectiveTo:
          obj.planConfigManagement_Plan_EffectiveTo,
        planConfigManagement_Plan_createdOn:
          obj.planConfigManagement_Plan_createdOn,
        planConfigManagement_Plan_modifiedOn:
          obj.planConfigManagement_Plan_modifiedOn,
        planConfigManagement_Plan_ExternalCode:
          obj.planConfigManagement_Plan_ExternalCode,
        planConfigManagement_Plan_Name: obj.planConfigManagement_Plan_Name,
        planConfigManagement_Plan_NameArabic:
          obj.planConfigManagement_Plan_NameArabic,
        planConfigManagement_Plan_PlanStatus: capitalizeFirstLetter(
          obj.planConfigManagement_Plan_PlanStatus?.replaceAll("_", " ")
        ),
        planConfigManagement_Plan_SublineID: sublineName,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize:
      data?.PlanConfigManagement?.queries?.getPlanList?.paging?.pageSize,
    pageNumber:
      data?.PlanConfigManagement?.queries?.getPlanList?.paging?.pageNumber,
    totalCount:
      data?.PlanConfigManagement?.queries?.getPlanList?.paging?.totalCount,
  };
}
