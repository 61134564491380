import { gql } from "@apollo/client";

export function getInsuredDetailsInfo() {
  return gql`
    query getInsuredDetailsInfo($id: String!) {
      Production {
        entities {
          insured(id: $id) {
            id
            views {
              Production_all {
                properties {
                  Id
                  FullName
                  InsuredCode
                  modifiedOn
                  modifiedBy
                  createdOn
                  createdBy
                  DateOfBirth
                  PhoneDetails {
                    PhoneType
                    PhoneNumber
                  }
                  NationalityOne {
                    Title
                    Code
                  }
                  Gender
                  OccupationClass {
                    Title
                    Code
                  }
                  Profession
                  PassportNumber
                  AddressType
                  Address {
                    Country {
                      Code
                      Title
                    }
                    Zone {
                      Code
                      Title
                    }
                    City
                    Street
                    Building
                    AddressDetails
                    AddressDetailsArabic
                  }
                  IsTaxable
                  LinkedBusinessPartnerIDs {
                    views {
                      SalesforceManagement_all {
                        properties {
                          Id
                          Code
                          PersonID {
                            views {
                              SalesforceManagement_all {
                                properties {
                                  FullName
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  AssignedToBusinessPartnerID {
                    id
                    views {
                      SalesforceManagement_all {
                        properties {
                          Id
                          Code
                          PersonID {
                            views {
                              SalesforceManagement_all {
                                properties {
                                  FullName
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            queries {
              production {
                relatedUsers {
                  createdBy_firstName
                  createdBy_lastName
                  modifiedBy_firstName
                  modifiedBy_lastName
                }
              }
            }
          }
        }
        queries {
          getAssignedBPListWithoutPagination {
            salesforceManagement_AssignedBP_Id
            salesforceManagement_AssignedBP_BusinessPartnerID {
              id
              views {
                SalesforceManagement_all {
                  properties {
                    Code
                    PersonID {
                      id
                      views {
                        SalesforceManagement_all {
                          properties {
                            FullName
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function getPolicyList() {
  return gql`
    query getPolicyList(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedInsuredIDs: [String]
    ) {
      Production {
        queries {
          getPolicyList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            selectedInsuredIDs: $selectedInsuredIDs
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              production_Policy_Id
              production_Policy_PolicyName
              production_Policy_PolicyNumber
              production_Policy_PolicyEffectiveDate
              production_Policy_PolicyExpiryDate
              production_Policy_Status
              production_Policy_StatusReason {
                Code
                Title
              }
              production_Policy_InsuredID {
                id
                views {
                  Production_all {
                    properties {
                      PersonCode
                      FullName
                    }
                  }
                }
              }
              production_Policy_createdOn
              production_Policy_modifiedOn
              line_Id
              line_Name
              line_ExternalCode
              production_Policy_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Policy_SublineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Policy_PlanID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      ExternalCode
                    }
                  }
                }
              }
              production_Policy_BusinessUserID {
                id
                views {
                  SalesforceManagement_all {
                    properties {
                      Account
                    }
                  }
                }
              }
              production_Policy_PolicyBPID {
                id
              }
            }
          }
        }
      }
    }
  `;
}
