import React, { useEffect, useState } from "react";
import EnhancedStepper from "../../components/common/EnhancedStepper";
import {
  businessPartnerTabs,
  getHeaders,
  getAssignedPLansHeaders,
  vignetteRangeheaders,
  assignedLinesActions,
  assignedPlansActions,
  vignetteRangeActions,
  filterSectionsContent,
} from "./content";
import { makeStyles } from "tss-react/mui";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  activateBusinessPartner,
  activateBusinessPartnerLine,
  deactivateBusinessPartner,
  deactivateBusinessPartnerLine,
  getBusinessPartnerLinesList,
  getBusinessPartnerLinesListWithoutPagination,
  getBusinessPartnerPlanList,
  activateBusinessPartnerPlan,
  deactivateBusinessPartnerPlan,
  getBusinessPartnerVignetteRangeList,
} from "./queries";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import {
  ISalesforceBusinessPartnerDetailsPageProps,
  IDetailedSelection,
} from ".";
import { MAIN_ONE_THEME, contentFontFamilyBold } from "../../constants";
import TabsLayout from "../../page-layout/tabs-layout/TabsLayout";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  capitalizeFirstCharacter,
  capitalizeFirstLetter,
} from "../../utils/formatting-utils";
import EnhancedButton, {
  EnhancedButtonStatus,
} from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { IConfirmation } from "../../redux/confirmation/types";
import { getBusinessPartnerDetailsInfo } from "./queries";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import {
  entityToBusinessPartnerDetails,
  extractAssignedBP,
  getBusinessPartnerStatus,
  mapToAssignedLinesListingData,
  mapToAssignedPlanListingData,
  mapToVignetteRangeListingData,
} from "./utils";
import WidgetSection from "../../components/common/WidgetSection";
import { IListingData } from "../../models/listing";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import SetDefaultCommissionDrawer from "../set-default-commission-drawer/SetDefaultCommissionDrawer";
import ShowForUser from "../../components/user/ShowForUser";
import SalesForceBusinessPartnerDrawer from "../salesforce-business-partners-drawer/SalesForceBusinessPartnerDrawer";
import { isEmpty } from "../../utils/validationUtils";
import BusinessPartnerAddPlansDrawer from "../business-partner-add-plans-drawer/BusinessPartnerAddPlansDrawer";
import SalesForceMaxCreditLimitDrawer from "../salesforce-max-credit-limit-drawer/SalesForceMaxCreditLimitDrawer";
import SalesForceLineCreditLimitDrawer from "../salesforce-line-credit-limit-drawer/SalesForceLineCreditLimitDrawer";
import SalesforceBusinessPartnerCommissionDrawer from "../set-default-commission-drawer copy/SalesforceBusinessPartnerCommissionDrawer";
import SalesforceVignetteRangeDrawer from "../salesforce-vignette-range-drawer/SalesforceVignetteRangeDrawer";
import { ITableOrder, TableSortOrder } from "../../utils/table-utils";
import _ from "lodash";
import BusinessPartnerEntityInfoWidget from "./widgets/BusinessPartnerEntityInfoWidget";
import BusinessPartnerDetailsWidget from "./widgets/BusinessPartnerDetailsWidget";
import MaxCreditLimitDetailsWidget from "./widgets/MaxCreditLimitDetailsWidget";
import InlineFilterWidget from "../../components/widgets/custom-listing-filter/InlineFilterWidget";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import { getFilter, setFilter } from "../../utils/filter-utils";
import { IAbstractRecord } from "../../models";
import BusinessPartnersPaymentTerms from "./BusinessPartnersPaymentTermsTab";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "32% 32% 32%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "10px",
    alignItems: "center",
    display: "flex",
    gap: "10px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
      maxWidth: "25%",
    },
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "100%",
    marginRight: "10px !important",
    backgroundColor: "white",
  },
  arField: {
    width: "100%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
  },
  dirtyChip: {
    margin: 0,
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  actionButton: {
    display: "inline-block",
    marginRight: "5px",
  },
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "0px",
  },
}));

const SalesforceBusinessPartnerDetailsPage: React.FC<
  ISalesforceBusinessPartnerDetailsPageProps
> = () => {
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);
  const isAdmin = user.userRoles.includes("Insurance-Admin");

  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [businessPartnerInfoDrawerOpen, setBusinessPartnerInfoDrawerOpen] =
    useState<boolean>(false);
  const [defaultCommissionDrawerOpen, setDefaultCommissionDrawerOpen] =
    useState<boolean>(false);
  const [maximumCreditLimitDrawerOpen, setMaximumCreditLimitDrawerOpen] =
    useState<boolean>(false);
  const [lineCreditLimitDrawerOpen, setLineCreditLimitDrawerOpen] =
    useState<boolean>(false);
  const [addPlansDrawerOpen, setAddPlansDrawerOpen] = useState<boolean>(false);
  const [
    assignedPlansCommissionDrawerOpen,
    setAssignedPlansCommissionDrawerOpen,
  ] = useState<boolean>(false);

  const [selectedDetailedSelection, setSelectedDetailedSelection] = useState<
    IDetailedSelection[]
  >([]);
  const [vignetteRangeDrawerOpen, setVignetteRangeDrawerOpen] =
    useState<boolean>(false);

  const [businessPartnerDetailsInfo, setBusinessPartnerDetailsInfo] =
    useState<Record<string, any>>();

  const [selectedBusinessLineId, setSelectedBusinessLineId] =
    useState<string>("");

  const [selectedBusinessPlanIds, setSelectedBusinessPlanIds] = useState<
    string[]
  >([]);

  const [selectedLineCreditLimitItemsIds, setSelectedLineCreditLimitItemsIds] =
    useState<string[]>([]);

  const [selectedVignetteRangeId, setSelectedVignetteRangeId] =
    useState<string>("");

  const [actionClicked, setActionClicked] = useState(null);

  const dispatch = useAppDispatch();
  const entityId = params.id;
  const navigate = useNavigate();

  const [booted, setBooted] = useState<boolean>(false);
  const [lineKeywordSearch, setLineKeywordSearch] = useState("");
  let setLineSearchTimeout: NodeJS.Timeout;

  const [planKeywordSearch, setPlanKeywordSearch] = useState("");
  let setPlanSearchTimeout: NodeJS.Timeout;

  const [vignetteKeywordSearch, setVignetteKeywordSearch] = useState("");
  let setVignetteSearchTimeout: NodeJS.Timeout;

  const [tableOrders, setTableOrders] = useState<Record<string, ITableOrder>>({
    line: {
      orderBy: "",
      orderDirection: "asc",
    },
    plan: {
      orderBy: "",
      orderDirection: "asc",
    },
    vignette: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const FILTER_SESSION_KEY = "salesforceBusinessPartnersDetailsFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const activateAssignedLine = (selectedIds: string[]) => {
    const confirmation: IConfirmation = {
      open: true,
      title: "Activate",
      message: `Are you sure you want to activate the selected assigned line?`,
      callback: async () => {
        try {
          const result = await activateBusinessPartnerLineAction({
            variables: {
              entityId: selectedIds,
            },
            errorPolicy: "all",
          });

          if (isEmpty(result.errors)) {
            initialize();
            toast.success(
              <ToastSuccessMessage>
                {
                  "Assigned line successfully activated. Ensure to update Line Credit Limit. Note that activating the assigned line will not activate the relevant plans."
                }
              </ToastSuccessMessage>
            );
          } else {
            toast.error(
              <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
            );
          }
        } catch (error) {
          toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
        }
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };

    dispatch(OpenConfirmationAction(confirmation));
  };

  const deactivateAssignedLine = (selectedIds: string[]) => {
    const confirmation: IConfirmation = {
      open: true,
      title: "Deactivate",
      message: `Are you sure you want to deactivate the selected assigned line?
      Note that deactivating the assigned line will deactivate all assigned plans.`,
      callback: async () => {
        try {
          const result = await deactivateBusinessPartnerLineAction({
            variables: {
              entityId: selectedIds[0],
            },
            errorPolicy: "all",
          });

          if (isEmpty(result.errors)) {
            initialize();
            toast.success(
              <ToastSuccessMessage>
                {
                  "Assigned line successfully deactivated. Note that Credit Limit is set to 0 and all relevant plans are deactivated as well."
                }
              </ToastSuccessMessage>
            );
          } else {
            toast.error(
              <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
            );
          }
        } catch (error) {
          toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
        }
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };

    dispatch(OpenConfirmationAction(confirmation));
  };

  const activateAssignedPlan = () => {
    const confirmation: IConfirmation = {
      open: true,
      title: "Activate",
      message: `Are you sure you want to activate the selected assigned plan(s)?`,
      callback: async () => {
        try {
          const result = await activateBusinessPartnerPlanAction({
            variables: {
              entityId: entityId,
              selectedBusinessPartnerPlanIds: selectedBusinessPlanIds,
            },
            errorPolicy: "all",
            refetchQueries: [getBusinessPartnerPlanList()],
          });

          if (isEmpty(result.errors)) {
            initialize();
            toast.success(
              <ToastSuccessMessage>
                {"Assigned plan successfully activated."}
              </ToastSuccessMessage>
            );
          } else {
            toast.error(
              <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
            );
          }
        } catch (error) {
          toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
        }
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };

    dispatch(OpenConfirmationAction(confirmation));
  };

  const deactivateAssignedPlan = () => {
    const confirmation: IConfirmation = {
      open: true,
      title: "Deactivate",
      message: `Are you sure you want to deactivate the selected assigned plan(s)?`,
      callback: async () => {
        try {
          const result = await deactivateBusinessPartnerPlanAction({
            variables: {
              entityId: entityId,
              selectedBusinessPartnerPlanIds: selectedBusinessPlanIds,
            },
            errorPolicy: "all",
            refetchQueries: [getBusinessPartnerPlanList()],
          });

          if (isEmpty(result.errors)) {
            initialize();
            toast.success(
              <ToastSuccessMessage>
                {"Assigned plan successfully deactivated."}
              </ToastSuccessMessage>
            );
          } else {
            toast.error(
              <ToastErrorMessage>{getError(result)}</ToastErrorMessage>
            );
          }
        } catch (error) {
          toast.error(<ToastErrorMessage>{getError(error)}</ToastErrorMessage>);
        }
      },
      submitButtonText: "Submit",
      cancelButtonText: "Cancel",
    };

    dispatch(OpenConfirmationAction(confirmation));
  };

  const [getBusinessPartnerLinesListWithoutPaginationLazy] = useLazyQuery(
    getBusinessPartnerLinesListWithoutPagination()
  );

  const [activateAction] = useMutation(activateBusinessPartner(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    // onCompleted: () => {
    //   setBusinessPartnerStatus("");
    // },
  });
  const [deactivateAction] = useMutation(deactivateBusinessPartner(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    // onCompleted: () => {
    //   setBusinessPartnerStatus("");
    // },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [assignedPlansTableData, setAssignedPlansTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [vignetteRangeTableData, setVignetteRangeTableData] = useState<
    IListingData<any>
  >({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 1,
    totalCount: 0,
  });

  const [entityResultQuery] = useLazyQuery(getBusinessPartnerDetailsInfo());

  const loadEntityData = async () => {
    const result = await entityResultQuery({
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });

    return result;
  };

  const [assignedLineListResultQuery] = useLazyQuery(
    getBusinessPartnerLinesList()
  );

  const loadAssignedLineList = async (
    currentPage: number = 0,
    pageSize = tableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword = lineKeywordSearch
  ) => {
    const result = await assignedLineListResultQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        selectedBusinessPartnerID: entityId,
        Attribute: capitalizeFirstCharacter(
          orders?.line?.orderBy || "Line_ExternalCode"
        ),
        Descending: orders?.line?.orderDirection == "asc" ? false : true,
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: "all",
    });

    return result;
  };

  const [assignedPlanListResultQuery] = useLazyQuery(
    getBusinessPartnerPlanList()
  );
  const loadAssignedPlanList = async (
    currentPage: number = 0,
    pageSize = tableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword = planKeywordSearch,
    filterV = filterValues
  ) => {
    const result = await assignedPlanListResultQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        selectedBusinessPartnerID: entityId,
        selectedBusinessPartnerLineID:
          filterV?.line && filterV?.line?.length > 0 ? filterV?.line : [],
        Attribute: capitalizeFirstCharacter(
          orders?.plan?.orderBy || "Line_ExternalCode"
        ),
        Descending: orders?.plan?.orderDirection == "asc" ? false : true,
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: "all",
    });

    return result;
  };

  const [assignedVignetteRangeListResultQuery] = useLazyQuery(
    getBusinessPartnerVignetteRangeList()
  );
  const loadVignetteRangeList = async (
    currentPage: number = 0,
    pageSize = tableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword = vignetteKeywordSearch
  ) => {
    const result = await assignedVignetteRangeListResultQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        selectedBusinessPartnerID: entityId,
        Attribute: capitalizeFirstCharacter(
          orders?.vignette?.orderBy ||
            "SalesforceManagement_BusinessPartnerVignetteRange_RangeCode"
        ),
        Descending: orders?.vignette?.orderDirection == "asc" ? false : true,
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: "all",
    });

    return result;
  };

  const [activateBusinessPartnerLineAction] = useMutation(
    activateBusinessPartnerLine()
  );
  const [deactivateBusinessPartnerLineAction] = useMutation(
    deactivateBusinessPartnerLine()
  );

  const [activateBusinessPartnerPlanAction] = useMutation(
    activateBusinessPartnerPlan()
  );
  const [deactivateBusinessPartnerPlanAction] = useMutation(
    deactivateBusinessPartnerPlan()
  );

  const getLovs = async () => {
    const assignedBP = await getBusinessPartnerLinesListWithoutPaginationLazy({
      fetchPolicy: "no-cache",
      variables: { selectedBusinessPartnerID: entityId },
    });

    const lovs = extractAssignedBP(assignedBP.data);

    if (!Object.keys(lovs.line).length) {
      lovs.line = filterValues?.line;
    }

    return lovs;
  };

  const initialize = async () => {
    const [entityData, lineData, planData, vignetteData] = await Promise.all([
      loadEntityData(),
      loadAssignedLineList(0, 10),
      loadAssignedPlanList(0, 10),
      loadVignetteRangeList(0, 10),
    ]);

    const entityDetails = await entityToBusinessPartnerDetails(entityData.data);
    const mappedAssignedLineList = await mapToAssignedLinesListingData(
      lineData.data
    );
    const mappedAssignedPlanList = await mapToAssignedPlanListingData(
      planData.data
    );

    const mappedVignetteRangeList = await mapToVignetteRangeListingData(
      vignetteData.data
    );
    let savedFilters = getFilter(FILTER_SESSION_KEY);
    if (isEmpty(savedFilters)) {
      savedFilters = _.cloneDeep(filterValues);
    }
    setFilterValues(savedFilters);

    const lovs = await getLovs();

    const newFilterSections = filterSectionsContent(lovs, savedFilters);
    setFilterSections(newFilterSections);

    setBusinessPartnerDetailsInfo(entityDetails);
    setTableData({ ...mappedAssignedLineList, pageNumber: 0 });

    setAssignedPlansTableData({ ...mappedAssignedPlanList, pageNumber: 0 });
    setVignetteRangeTableData({ ...mappedVignetteRangeList, pageNumber: 0 });

    setBooted(true);
    setLoadingState(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  //#region functions
  async function handlePageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = lineKeywordSearch
  ) {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadAssignedLineList(
        page,
        pageSize,
        orders,
        searchKeyword
      );
      const mappedSubList = await mapToAssignedLinesListingData(result.data);
      setTableData({ ...mappedSubList, pageNumber: page, pageSize: pageSize });

      setLoadingState(false);
    }
  }

  async function handleAssignedPlanPageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = planKeywordSearch,
    filterV = filterValues
  ) {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadAssignedPlanList(
        page,
        pageSize,
        orders,
        searchKeyword,
        filterV
      );
      const mappedSubList = await mapToAssignedPlanListingData(result.data);
      setAssignedPlansTableData({
        ...mappedSubList,
        pageNumber: page,
        pageSize: pageSize,
      });

      setLoadingState(false);
    }
  }

  async function handleVignetteRangePageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = vignetteKeywordSearch
  ) {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadVignetteRangeList(
        page,
        pageSize,
        orders,
        searchKeyword
      );
      const mappedSubList = await mapToVignetteRangeListingData(result.data);
      setVignetteRangeTableData({
        ...mappedSubList,
        pageNumber: page,
        pageSize: pageSize,
      });
      setLoadingState(false);
    }
  }

  const handleLineSearchChange = (search: string) => {
    setLineKeywordSearch(search);
  };

  const lineDelaySearch = (val: string) => {
    clearTimeout(setLineSearchTimeout);
    setLineSearchTimeout = setTimeout(() => {
      handleLineSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrders, val);
    }, 1000);
  };

  function handleLineSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.line.orderBy = orderBy;
    newOrder.line.orderDirection = order as any;
    handlePageChange(tableData.pageNumber, tableData.pageSize, newOrder);

    setTableOrders(newOrder);
  }

  const handlePlanSearchChange = (search: string) => {
    setPlanKeywordSearch(search);
  };

  const planDelaySearch = (val: string) => {
    clearTimeout(setPlanSearchTimeout);
    setPlanSearchTimeout = setTimeout(() => {
      handlePlanSearchChange(val);
      handleAssignedPlanPageChange(0, tableData.pageSize, tableOrders, val);
    }, 1000);
  };

  function handlePlanSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.plan.orderBy = orderBy;
    newOrder.plan.orderDirection = order as any;
    handleAssignedPlanPageChange(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder
    );

    setTableOrders(newOrder);
  }

  const handleVignetteSearchChange = (search: string) => {
    setVignetteKeywordSearch(search);
  };

  const vignettaDelaySearch = (val: string) => {
    clearTimeout(setVignetteSearchTimeout);
    setVignetteSearchTimeout = setTimeout(() => {
      handleVignetteSearchChange(val);
      handleVignetteRangePageChange(0, tableData.pageSize, tableOrders, val);
    }, 1000);
  };

  function handleVignetteSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.vignette.orderBy = orderBy;
    newOrder.vignette.orderDirection = order as any;
    handleVignetteRangePageChange(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder
    );

    setTableOrders(newOrder);
  }

  const renderMainChildren = () => {
    const isBusinessPartnerActive =
      businessPartnerDetailsInfo?.status === "active";
    const isBusinessPartnerNew = businessPartnerDetailsInfo?.status === "new";
    const steps = isBusinessPartnerNew
      ? ["New", "Active"]
      : ["New", isBusinessPartnerActive ? "Active" : "Inactive"];

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepper
            activeStep={getBusinessPartnerStatus(
              steps,
              businessPartnerDetailsInfo?.status
            )}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isBusinessPartnerActive ? "Deactivate" : "Activate"}
            buttonState={
              isBusinessPartnerActive
                ? activateButtonState
                : deactivateButtonState
            }
            buttonOnClick={async () => {
              if (isBusinessPartnerActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Deactivate",
                  message: `Are you sure you want to deactivate this business partner?`,
                  callback: async () => {
                    try {
                      setDeactivateButtonState("loading");
                      const result = await deactivateAction();
                      if (isEmpty(result.errors)) {
                        setDeactivateButtonState("success");
                        initialize();
                        toast.success(
                          <ToastSuccessMessage>
                            {"Business Partner successfully deactivated."}
                          </ToastSuccessMessage>
                        );
                      } else {
                        setDeactivateButtonState(undefined);
                        toast.error(
                          <ToastErrorMessage>
                            {getError(result)}
                          </ToastErrorMessage>
                        );
                      }
                    } finally {
                      setTimeout(() => {
                        setDeactivateButtonState(undefined);
                      }, 1000);
                    }
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this business partner?`,
                  callback: async () => {
                    try {
                      setActivateButtonState("loading");
                      const result = await activateAction();
                      if (isEmpty(result.errors)) {
                        setActivateButtonState("success");
                        initialize();
                        toast.success(
                          <ToastSuccessMessage>
                            {"Business Partner successfully activated."}
                          </ToastSuccessMessage>
                        );
                      } else {
                        setActivateButtonState(undefined);
                        toast.error(
                          <ToastErrorMessage>
                            {getError(result)}
                          </ToastErrorMessage>
                        );
                      }
                    } finally {
                      setTimeout(() => {
                        setActivateButtonState(undefined);
                      }, 1000);
                    }
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <BusinessPartnerEntityInfoWidget data={businessPartnerDetailsInfo} />
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
    handleAssignedPlanPageChange(
      0,
      assignedPlansTableData.pageSize,
      tableOrders,
      planKeywordSearch,
      newFilters
    );
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <InlineFilterWidget
          name={""}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></InlineFilterWidget>
      )
    );
  };

  //#region tabs
  const tabs = () => {
    businessPartnerTabs.tabs[0].widgets[0].children = (
      <BusinessPartnerDetailsWidget
        data={businessPartnerDetailsInfo}
        actions={
          <ShowForUser allowedRoles={["Insurance-Admin"]}>
            {businessPartnerDetailsInfo?.status != "inactive" && (
              <div>
                <EnhancedButton
                  type="button"
                  backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                  color="#FFF"
                  onClick={() => setBusinessPartnerInfoDrawerOpen(true)}
                  className={classes.actionButton}
                >
                  Modify
                </EnhancedButton>
              </div>
            )}
          </ShowForUser>
        }
      />
    );

    businessPartnerTabs.tabs[1].widgets[0].children = (
      <>
        {businessPartnerDetailsInfo?.businessPartnerType !==
          "Direct_Salesforce" && (
          <MaxCreditLimitDetailsWidget
            data={businessPartnerDetailsInfo}
            actions={
              <ShowForUser allowedRoles={["Insurance-Admin"]}>
                {businessPartnerDetailsInfo?.status != "inactive" && (
                  <div>
                    <EnhancedButton
                      type="button"
                      backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                      color="#FFF"
                      onClick={() => setMaximumCreditLimitDrawerOpen(true)}
                      className={classes.actionButton}
                    >
                      Modify
                    </EnhancedButton>
                  </div>
                )}
              </ShowForUser>
            }
          />
        )}
        <WidgetSection style={{ margin: "-23px 0 0 0" }}>
          {
            <form>
              <div
                style={{
                  marginTop: "20px",
                  padding: "-23px 0 0 0 !important",
                }}
              >
                <EnhancedTable
                  title={capitalizeFirstLetter("Assigned Lines")}
                  inlineTitle={capitalizeFirstLetter("Assigned Lines")}
                  name="count"
                  entityName={"AssignedLines"}
                  entityIdColumnName={
                    "salesforceManagement_BusinessPartnerLine_Id"
                  }
                  showCellFullData={true}
                  isTitlePascalCase={true}
                  orderByAscendingByDefault
                  defaultOrderByColumn="name"
                  inline={true}
                  data={tableData}
                  headers={getHeaders(navigate)}
                  handlePageChange={(page: number) =>
                    handlePageChange(page, tableData.pageSize)
                  }
                  handleRowsPerPageChange={(pageSize: number) =>
                    handlePageChange(0, pageSize)
                  }
                  handleSort={(orderBy: string, order: string) =>
                    handleLineSortChange(orderBy, order)
                  }
                  handleSearchChange={lineDelaySearch}
                  currentPage={tableData.pageNumber}
                  hideToolbar={false}
                  usePagination
                  disableSelection={false}
                  actions={assignedLinesActions(
                    setDefaultCommissionDrawerOpen,
                    setLineCreditLimitDrawerOpen,
                    activateAssignedLine,
                    deactivateAssignedLine,
                    businessPartnerDetailsInfo,
                    isAdmin
                  )}
                  // loader={assignedLinesTableLoadingState}
                  showTablePagination={true}
                  secondaryActions={true}
                  specificDesign={true}
                  onSelectionChange={(newDetailedSelection) => {
                    const itemIds = [];
                    for (const item of newDetailedSelection) {
                      itemIds.push(item.id);
                    }
                    setSelectedLineCreditLimitItemsIds(itemIds);
                    if (newDetailedSelection.length > 0) {
                      setSelectedBusinessLineId(newDetailedSelection[0].id);
                    }
                  }}
                  ordering={tableOrders.line.orderDirection as TableSortOrder}
                  orderingBy={tableOrders.line.orderBy}
                />
                {defaultCommissionDrawerOpen && (
                  <SetDefaultCommissionDrawer
                    open={defaultCommissionDrawerOpen}
                    businessPartnerId={entityId}
                    onClose={() => setDefaultCommissionDrawerOpen(false)}
                    onSuccess={() => {
                      handlePageChange(0, 10);
                      handleAssignedPlanPageChange(0, 10);
                    }}
                    assignedLineId={selectedBusinessLineId}
                    businessPartnerType={
                      businessPartnerDetailsInfo?.businessPartnerType
                    }
                  />
                )}
              </div>

              <div>
                <div>
                  <div
                    style={{
                      marginTop: "20px",
                      padding: "-23px 0 0 0 !important",
                    }}
                  >
                    <EnhancedTable
                      title={capitalizeFirstLetter("Assigned Plans")}
                      inlineTitle={capitalizeFirstLetter("Assigned Plans")}
                      lineNameController={
                        <>
                          <div className={classes.container}>
                            {renderFilter()}
                          </div>
                        </>
                      }
                      name="count"
                      entityName={"AssignedPlans"}
                      entityIdColumnName={
                        "salesforceManagement_BusinessPartnerPlan_Id"
                      }
                      showCellFullData={true}
                      isTitlePascalCase={true}
                      orderByAscendingByDefault
                      defaultOrderByColumn="name"
                      inline={true}
                      data={assignedPlansTableData}
                      headers={getAssignedPLansHeaders(navigate)}
                      handlePageChange={(page: number) =>
                        handleAssignedPlanPageChange(
                          page,
                          assignedPlansTableData.pageSize
                        )
                      }
                      handleRowsPerPageChange={(pageSize: number) =>
                        handleAssignedPlanPageChange(0, pageSize)
                      }
                      handleSort={(orderBy: string, order: string) =>
                        handlePlanSortChange(orderBy, order)
                      }
                      handleSearchChange={planDelaySearch}
                      currentPage={assignedPlansTableData.pageNumber}
                      hideToolbar={false}
                      usePagination
                      disableSelection={false}
                      actions={assignedPlansActions(
                        setAddPlansDrawerOpen,
                        setAssignedPlansCommissionDrawerOpen,
                        activateAssignedPlan,
                        deactivateAssignedPlan,
                        businessPartnerDetailsInfo,
                        isAdmin
                      )}
                      showTablePagination={true}
                      secondaryActions={true}
                      specificDesign={true}
                      onSelectionChange={(newDetailedSelection) => {
                        if (newDetailedSelection.length > 0) {
                          if (newDetailedSelection.length === 1) {
                            setSelectedDetailedSelection(newDetailedSelection);
                          } else {
                            setSelectedDetailedSelection([]);
                          }
                          const itemIds = [];
                          const businessPartnerPlanStatuses = [];
                          const businessPartnerPlan_BusinessPartnerLine_Status =
                            [];
                          const businessPartnerPlan_Line_Status = [];
                          const businessPartnerPlan_Plan_Status = [];

                          for (const item of newDetailedSelection) {
                            itemIds.push(item.id);
                            businessPartnerPlanStatuses.push(
                              item.businessPartnerPlanStatus.toLowerCase()
                            );
                            businessPartnerPlan_BusinessPartnerLine_Status.push(
                              item.businessPartnerPlan_BusinessPartnerLine_Status.toLowerCase()
                            );
                            businessPartnerPlan_Line_Status.push(
                              item.businessPartnerPlan_Line_Status.toLowerCase()
                            );
                            businessPartnerPlan_Plan_Status.push(
                              item.businessPartnerPlan_Plan_Status.toLowerCase()
                            );
                          }
                          setSelectedBusinessPlanIds(itemIds);
                        }
                      }}
                      ordering={
                        tableOrders.plan.orderDirection as TableSortOrder
                      }
                      orderingBy={tableOrders.plan.orderBy}
                    />
                    {addPlansDrawerOpen && (
                      <BusinessPartnerAddPlansDrawer
                        open={addPlansDrawerOpen}
                        businessPartnerId={entityId}
                        businessPartnerType={
                          businessPartnerDetailsInfo?.businessPartnerType
                        }
                        selectedBusinessPlanIds={selectedBusinessPlanIds}
                        selectedDetailedSelection={selectedDetailedSelection}
                        onClose={() => setAddPlansDrawerOpen(false)}
                        onSuccess={() => {
                          handleAssignedPlanPageChange(0, 10);
                        }}
                      />
                    )}
                    {assignedPlansCommissionDrawerOpen && (
                      <SalesforceBusinessPartnerCommissionDrawer
                        open={assignedPlansCommissionDrawerOpen}
                        businessPartnerId={entityId}
                        businessPartnerLinesIds={selectedBusinessPlanIds}
                        selectedBusinessPlanIds={selectedBusinessPlanIds}
                        selectedDetailedSelection={selectedDetailedSelection}
                        onClose={() =>
                          setAssignedPlansCommissionDrawerOpen(false)
                        }
                        onSuccess={() => {
                          handleAssignedPlanPageChange(0, 10);
                        }}
                        businessPartnerType={
                          businessPartnerDetailsInfo?.businessPartnerType
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </form>
          }
        </WidgetSection>
      </>
    );

    businessPartnerTabs.tabs[2].widgets[0].children = (
      <WidgetSection>
        <form>
          <EnhancedTable
            title={capitalizeFirstLetter("Vignette Range Listing")}
            inlineTitle={capitalizeFirstLetter("Vignette Range Listing")}
            name="count"
            entityName={"Vignette"}
            entityIdColumnName={
              "salesforceManagement_BusinessPartnerVignetteRange_Id"
            }
            showCellFullData={true}
            isTitlePascalCase={true}
            orderByAscendingByDefault
            defaultOrderByColumn="name"
            inline={true}
            data={vignetteRangeTableData}
            headers={vignetteRangeheaders}
            handlePageChange={(page: number) =>
              handleVignetteRangePageChange(
                page,
                vignetteRangeTableData.pageSize
              )
            }
            handleRowsPerPageChange={(pageSize: number) =>
              handleVignetteRangePageChange(0, pageSize)
            }
            handleSort={(orderBy: string, order: string) =>
              handleVignetteSortChange(orderBy, order)
            }
            handleSearchChange={vignettaDelaySearch}
            currentPage={vignetteRangeTableData.pageNumber}
            hideToolbar={false}
            usePagination
            disableSelection={false}
            actions={vignetteRangeActions(
              setActionClicked,
              setVignetteRangeDrawerOpen,
              businessPartnerDetailsInfo,
              isAdmin,
              tenant
            )}
            // loader={vignetteRangeTableLoadingState}
            showTablePagination={true}
            onSelectionChange={(newDetailedSelection) => {
              if (newDetailedSelection.length > 0) {
                setSelectedVignetteRangeId(newDetailedSelection[0].id);
              } else {
                setSelectedVignetteRangeId("");
              }
            }}
            ordering={tableOrders.vignette.orderDirection as TableSortOrder}
            orderingBy={tableOrders.vignette.orderBy}
          />
          {vignetteRangeDrawerOpen && (
            <SalesforceVignetteRangeDrawer
              open={vignetteRangeDrawerOpen}
              onClose={() => setVignetteRangeDrawerOpen(false)}
              onSuccess={() => {
                handleVignetteRangePageChange(0, 10);
              }}
              vignetteRangeId={
                actionClicked === "new" ? null : selectedVignetteRangeId
              }
            />
          )}
        </form>
      </WidgetSection>
    );

    businessPartnerTabs.tabs[3].widgets[0].children = (
      <BusinessPartnersPaymentTerms businessPartnerId={params.id} />
    );

    return businessPartnerTabs;
  };
  //#endregion tabs

  return !booted ? (
    <Loader />
  ) : (
    <>
      <div>
        {businessPartnerInfoDrawerOpen && (
          <SalesForceBusinessPartnerDrawer
            open={businessPartnerInfoDrawerOpen}
            onClose={() => setBusinessPartnerInfoDrawerOpen(false)}
            onSuccess={() => {
              initialize();
            }}
            businessPartnerId={params.id}
            salesForceBusinessPartnerDetailsInfo={
              businessPartnerDetailsInfo as any
            }
          />
        )}
        {maximumCreditLimitDrawerOpen && (
          <SalesForceMaxCreditLimitDrawer
            open={maximumCreditLimitDrawerOpen}
            onClose={() => setMaximumCreditLimitDrawerOpen(false)}
            onSuccess={() => {
              initialize();
            }}
            businessPartnerId={params.id}
            salesForceMaxCreditLimitDetailsInfo={
              businessPartnerDetailsInfo as any
            }
          />
        )}
        {lineCreditLimitDrawerOpen && (
          <SalesForceLineCreditLimitDrawer
            open={lineCreditLimitDrawerOpen}
            businessPartnerId={entityId}
            businessPartnerLinesId={selectedLineCreditLimitItemsIds}
            onClose={() => setLineCreditLimitDrawerOpen(false)}
            onSuccess={() => {
              handlePageChange(0, 10);
              handleAssignedPlanPageChange(0, 10);
            }}
            assignedLineId={selectedBusinessLineId}
          />
        )}
      </div>
      <TabsLayout
        name="policyPageDetails"
        layout={tabs()}
        theme={tenant.theme}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
        cdnUrl={tenant.cdnUrl}
        userInfo={user["info"]}
        tabPanelClassName={classes.tabPanelStyle}
        firstTabAsActiveTab={true}
      />
    </>
  );
};

export default SalesforceBusinessPartnerDetailsPage;
