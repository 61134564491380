import React, { useEffect, useState } from 'react';
// import { useAppDispatch, useAppSelector } from '../../redux/hooks';
// import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
// import { IListingData } from '../../models/listing';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import {
  getAccountIdsByCompany,
  getLastModifiedOnModifiedByTransferLinksQuery,
  getTransferLinks,
  getTransferLinksFilterConfig,
  getUserFullName,
  updateTransferLinksMutation,
} from './queries';
import {
  ITransferLinksFilterConfig,
  ITransferLinksLastModificationInfo,
  ITransferLinksTableData,
} from '.';
import { makeStyles } from 'tss-react/mui';
import NewChipsInput from '../../components/enhanced-form/NewChipsInput';
import EnhancedButton from '../../components/EnhancedButton';
import { isEmpty } from '../../utils/validationUtils';
import { formatDateTime } from '../../utils/formatting-utils';
import { toast } from 'react-toastify';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { getError } from '../../utils/graph-utils';
import { useAppSelector } from '../../redux/hooks';

const useStyles = makeStyles()(() => ({
  leftSideTitle: {
    fontSize: '22px',
    margin: '20px 0 30px',
    padding: '0 0 30px',
    textAlign: 'center',
    borderBottom: '6px solid #D30D2B',
    fontFamily: 'SourceSansPro-SemiBold',
  },
  leftSideItem: {
    margin: '0 0 15px',
  },
  leftSideItemTitle: {
    fontSize: '15px',
    margin: '0',
    fontFamily: 'SourceSansPro-Medium',
  },
  leftSideItemValue: {
    fontSize: '15px',
    margin: '0',
    fontFamily: 'SourceSansPro-Regular',
  },
  mainChildrenContainer: {
    backgroundColor: '#FFF',
    borderRadius: '8px',
    border: '1px solid #E8E8E8',
    padding: '20px 20px 50px 50px',
    height: '85%',
    width: '90%',
  },
  mainChildrenHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '0 0 30px',
    padding: '0 20px',
  },
  mainChildrenHeaderTitle: {
    fontSize: '18px',
    margin: '0',
    fontFamily: 'SourceSansPro-Medium',
  },
  filtersContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: '40px',
    padding: '0 20px',
  },
  tableContainer: {
    display: 'grid',
  },
  tableHeaderTitle: {
    fontSize: '16px',
    margin: '0',
    fontFamily: 'SourceSansPro-SemiBold',
  },
  tableRowContainer: {
    padding: '7px 0 7px 20px',
    margin: '0 20px 0 0',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: '40px',
    alignItems: 'center',
    ':nth-of-type(even)': {
      backgroundColor: '#f7f7f7',
    },
  },
}));

const TransferLinksListing: React.FC = ({}) => {
  const { classes, cx } = useStyles();
  const user = useAppSelector((State) => State.user.info);

  const [
    getLastModifiedOnModifiedByTransferLinks,
    { loading: loadingModificationInfo },
  ] = useLazyQuery(getLastModifiedOnModifiedByTransferLinksQuery());
  const [modificationInfo, setModificationInfo] =
    useState<ITransferLinksLastModificationInfo>({
      accounting_AccountingTransferLinks_modifiedBy: '',
      accounting_AccountingTransferLinks_modifiedOn: '',
    });

  const [getFilterConfigQuery, { loading: loadingFilterConfig }] = useLazyQuery(
    getTransferLinksFilterConfig()
  );
  const [filterConfig, setFilterConfig] = useState<ITransferLinksFilterConfig>({
    transferTypes: {},
    transferLinks: {},
    relatedCompanies: {},
  });

  const [filterValues, setFilterValues] = useState<
    Record<string, string | string[]>
  >({
    company: '',
    transferType: '',
    transferLinks: [],
  });

  const [getTransferLinksQuery, { loading: loadingTransferLinks }] =
    useLazyQuery(getTransferLinks());
  const [tableData, setTableData] = useState<ITransferLinksTableData[]>([]);

  const [getCompanyAccounts, { loading: loadingCompanyAccounts }] =
    useLazyQuery(getAccountIdsByCompany());
  const [companyAccounts, setCompanyAccounts] = useState<
    Record<string, string>
  >({});

  const [updateTransferLinks] = useMutation(updateTransferLinksMutation());

  const [getUserInfo, { loading: userLoading }] = useLazyQuery(
    getUserFullName()
  );

  useEffect(() => {
    getModificationInfo();
    getFilterConfig();
  }, []);

  async function getModificationInfo() {
    const result = await getLastModifiedOnModifiedByTransferLinks({
      variables: {
        pageNumber: 1,
        pageSize: 1,
      },
    });
    if (
      result.data.Accounting.queries.GetTransferLinksModifiedByModifiedOn.items
        .length > 0
    ) {
      let newModificationInfo: ITransferLinksLastModificationInfo = {
        accounting_AccountingTransferLinks_modifiedBy: '',
        accounting_AccountingTransferLinks_modifiedOn: '',
      };

      const userResult = await getUserInfo({ variables: { id: user.id } });
      const userInfo =
        userResult.data.System.entities.user.views.System_all.properties;

      newModificationInfo.accounting_AccountingTransferLinks_modifiedOn =
        result.data.Accounting.queries.GetTransferLinksModifiedByModifiedOn.items[0].accounting_AccountingTransferLinks_modifiedOn;
      newModificationInfo.accounting_AccountingTransferLinks_modifiedBy =
        `${userInfo.firstName} ${userInfo.lastName}`.trim();
      setModificationInfo(newModificationInfo);
    }
  }

  async function getFilterConfig() {
    const result = await getFilterConfigQuery();
    let tempFilterConfig = { ...filterConfig };

    result.data.Accounting.lookups.transferLink.forEach(
      (item: any) => (tempFilterConfig.transferLinks[item.Id] = item.Title)
    );
    result.data.Accounting.lookups.transferType.forEach(
      (item: any) => (tempFilterConfig.transferTypes[item.Id] = item.Title)
    );
    result.data.SalesforceManagement.lookups.relatedCompanies.forEach(
      (item: any) => (tempFilterConfig.relatedCompanies[item.Id] = item.Title)
    );

    setFilterConfig(tempFilterConfig);

    let tempFilterValues = { ...filterValues };
    tempFilterValues.company = Object.keys(
      tempFilterConfig.relatedCompanies
    )[0];
    tempFilterValues.transferType = Object.keys(
      tempFilterConfig.transferTypes
    )[0];
    tempFilterValues.transferLinks = Object.keys(
      tempFilterConfig.transferLinks
    ).filter((key) => key.startsWith(tempFilterValues.transferType as string));

    setFilterValues(tempFilterValues);
    getAccounts(tempFilterValues.company);
    getListingData(tempFilterValues);
  }

  async function getListingData(values: Record<string, string | string[]>) {
    if (
      !isEmpty(values.company) &&
      !isEmpty(values.transferType) &&
      !isEmpty(values.transferLinks)
    ) {
      const result = await getTransferLinksQuery({
        variables: {
          pageNumber: 1,
          pageSize: 1000000,
          company: values.company,
          transferType: values.transferType,
          links: values.transferLinks,
        },
      });
      let tempData: ITransferLinksTableData[] = [];
      result.data.Accounting.queries.GetTransferLinks.items.forEach(
        (item: any) => {
          tempData.push({
            id: item.accounting_AccountingTransferLinks_Id,
            account: item.accounting_AccountingTransferLinks_ChartofAccount,
            line: item.line_Name,
            link: item.accounting_AccountingTransferLinks_TransferLink.Title,
          });
        }
      );
      setTableData(tempData);
    } else {
      setTableData([]);
    }
  }

  const handleFilterValueChange = (key: string, value: string | string[]) => {
    let tempValues = { ...filterValues };
    tempValues[key] = value;

    if (key === 'company') {
      getAccounts(value as string);
    }
    if (key === 'transferType') {
      tempValues.transferLinks = Object.keys(filterConfig.transferLinks).filter(
        (key) => key.startsWith(value as string)
      );
    }
    setFilterValues(tempValues);
    getListingData(tempValues);
  };

  async function getAccounts(companyId: string) {
    const result = await getCompanyAccounts({
      variables: {
        selectedCompanyID: companyId,
      },
    });

    let tempAccounts: Record<string, string> = {};
    result.data.Accounting.queries.GetAccountsOfCompany.forEach(
      (item: any) =>
        {
          if(!Object.keys(tempAccounts).filter(x=>x === item.accounting_ChartOfAccounts_AccountID).length){
            tempAccounts[item.accounting_ChartOfAccounts_AccountID] =
            item.accounting_ChartOfAccounts_AccountID
          }
        }
    );

    setCompanyAccounts(tempAccounts);
  }

  async function updateData() {
    const result = await updateTransferLinks({
      variables: {
        transferLinks: tableData.map((row) => {
          return { ID: row.id, Account: row.account };
        }),
      },
    });

    if (isEmpty(result.errors)) {
      getModificationInfo();
      toast.success(
        <ToastSuccessMessage>
          {'Successfully updated transfer links.'}
        </ToastSuccessMessage>
      );
    } else {
      toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
    }

    getListingData(filterValues);
  }

  const handleAccountChange = (index: number, account: string) => {
    let tempTableData = [...tableData];

    tableData[index].account = account;
    setTableData(tempTableData);
  };

  function filterTransferLinks(transferType: string) {
    let filteredLinks: Record<string, string> = {};
    Object.entries(filterConfig.transferLinks)
      .filter(([key, value]) => key.startsWith(transferType))
      .forEach((link) => {
        filteredLinks[link[0]] = link[1];
      });
    return filteredLinks;
  }

  const renderMainChildren = () => {
    return (
      <div className={classes.mainChildrenContainer}>
        <div className={classes.mainChildrenHeaderContainer}>
          <p className={classes.mainChildrenHeaderTitle}>Transfers</p>
          <EnhancedButton
            onClick={() => {
              updateData();
            }}
          >
            Save
          </EnhancedButton>
        </div>
        <div className={classes.filtersContainer}>
          <NewChipsInput
            name={'company'}
            title="Company"
            items={filterConfig.relatedCompanies}
            value={
              typeof filterValues.company === 'string'
                ? filterValues.company
                : ''
            }
            onChange={(value: string) =>
              handleFilterValueChange('company', value)
            }
          />
          <NewChipsInput
            name={'transferType'}
            title="Transfer Type"
            items={filterConfig.transferTypes}
            value={
              typeof filterValues.transferType === 'string'
                ? filterValues.transferType
                : ''
            }
            onChange={(value: string) =>
              handleFilterValueChange('transferType', value)
            }
          />
          <NewChipsInput
            name={'transferLink'}
            title={'Link'}
            items={filterTransferLinks(filterValues.transferType as string)}
            values={
              typeof filterValues.transferLinks !== 'string'
                ? filterValues.transferLinks
                : []
            }
            onChange={(value: string[]) =>
              handleFilterValueChange('transferLinks', value)
            }
            multiple
            maxTagCount={5}
          />
        </div>
        <div className={classes.tableContainer}>
          <div className={classes.tableRowContainer}>
            <span className={classes.tableHeaderTitle}>Link</span>
            <span className={classes.tableHeaderTitle}>Line</span>
            <span className={classes.tableHeaderTitle}>
              Account (Auxillary)*
            </span>
          </div>
          {tableData.map((row, index) => (
            <div className={classes.tableRowContainer}>
              <span>{row.link}</span>
              <span>{row.line}</span>
              <span>
                <NewChipsInput
                  name=""
                  title=""
                  items={companyAccounts}
                  value={row.account}
                  onChange={(value: string) => {
                    handleAccountChange(index, value);
                  }}
                  hideError
                  hideWrapperHeader
                />
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderLeftSide = () => {
    return (
      <div>
        <p className={classes.leftSideTitle}>TRANSFER LINKS</p>
        <p className={cx(classes.leftSideItem, classes.leftSideItemValue)}>
          <span className={classes.leftSideItemTitle}>Modified By: </span>
          {modificationInfo.accounting_AccountingTransferLinks_modifiedBy}
        </p>
        <p className={cx(classes.leftSideItem, classes.leftSideItemValue)}>
          <span className={classes.leftSideItemTitle}>Modified On: </span>
          {formatDateTime(
            modificationInfo.accounting_AccountingTransferLinks_modifiedOn
          )}
        </p>
      </div>
    );
  };

  return (
    <StaticLayout
      loading={
        loadingModificationInfo ||
        loadingFilterConfig ||
        loadingTransferLinks ||
        loadingCompanyAccounts ||
        userLoading
      }
      name={'Transfer Links'}
      leftChildren={renderLeftSide()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default TransferLinksListing;
