import dayjs from "dayjs";
import { ISublineDetails } from ".";
import { EnhancedDisplayType } from "../../components/enhanced-form/EnhancedDisplay";
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from "../../components/enhanced-table";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";

export const steps = ["Inactive", "Active"];

export const getEntityView = (
  sublineInfo: ISublineDetails
): IEntityInfoView => ({
  name: "section1",
  title: "Subline Details",
  iconUrl: "",
  sections: [
    {
      name: "sublineDetailsSection1",
      title: "",
      properties: {
        lineName: {
          value: sublineInfo.lineName,
          multiline: false,
          name: "lineName",
          title: "Line",
          type: EnhancedDisplayType.RelativeLink,
          url: "/plan/lines/" + sublineInfo?.lineId,
        },
        externalCode: {
          value: sublineInfo.externalCode,
          multiline: false,
          name: "externalCode",
          title: "External Code",
        },
        arabicName: {
          value: sublineInfo.arabicName,
          multiline: false,
          name: "arabicName",
          title: "Arabic Name",
        },
        // status: {
        //   value: '',
        //   multiline: false,
        //   name: 'status',
        //   title: 'Status',
        // },
      },
    },
    {
      name: "sublineDetailsSection2",
      title: "",
      properties: {
        createdBy: {
          value: sublineInfo.createdBy,
          multiline: false,
          name: "createdBy",
          title: "Created By",
        },
        createdOn: {
          value: dayjs(new Date(sublineInfo.createdOn)).format("DD/MM/YYYY"),
          multiline: false,
          name: "createdOn",
          title: "Created On",
        },
        modifiedBy: {
          value: sublineInfo.modifiedBy,
          multiline: false,
          name: "modifiedBy",
          title: "Modified By",
        },
        modifiedOn: {
          value: dayjs(new Date(sublineInfo.modifiedOn)).format("DD/MM/YYYY"),
          multiline: false,
          name: "modifiedOn",
          title: "Modified On",
        },
      },
    },
  ],
  socialMedia: {
    facebook: "",
    linkedIn: "",
    twitter: "",
  },
});

export const planHeaders: Record<string, EnhancedTableHeader> = {
  planConfigManagement_Plan_Name: {
    name: "planConfigManagement_Plan_Name",
    title: "Plan Name",
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  planConfigManagement_Plan_SublineID: {
    name: "planConfigManagement_Plan_SublineID",
    title: "Subline Name",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_ExternalCode: {
    name: "planConfigManagement_Plan_ExternalCode",
    title: "External Code",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_NameArabic: {
    name: "planConfigManagement_Plan_NameArabic",
    title: "Arabic Name",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_EffectiveFrom: {
    name: "planConfigManagement_Plan_EffectiveFrom",
    title: "Effective From",
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Plan_EffectiveTo: {
    name: "planConfigManagement_Plan_EffectiveTo",
    title: "Effective To",
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Plan_PlanStatus: {
    name: "planConfigManagement_Plan_PlanStatus",
    title: "Status",
    type: EnhancedTableHeaderType.Text,
  },
  planConfigManagement_Plan_createdOn: {
    name: "planConfigManagement_Plan_createdOn",
    title: "Created On",
    type: EnhancedTableHeaderType.Date,
  },
  planConfigManagement_Plan_modifiedOn: {
    name: "planConfigManagement_Plan_modifiedOn",
    title: "Modified On",
    type: EnhancedTableHeaderType.Date,
  },
};
