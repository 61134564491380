import { gql } from "@apollo/client";

export function getEnums() {
  return gql`
    query GetTransactionEnums {
      SalesforceManagement {
        lookups {
          relatedCompanies {
            Code
            Title
            Id
          }
        }
      }
      Core {
        queries {
          getSystemCurrencies {
            core_SystemCurrency_Id
            core_SystemCurrency_BasicCurrency {
              Id
              Code
              Title
              Symbol
            },
            core_SystemCurrency_SecondaryCurrency{
              Id
              Code
              Title
              Symbol
            }
          }
        }
      }
			Accounting_TransactionTypeList: __type(
					name: "Accounting_TransactionTypeList"
				) {
					name
					enumValues {
						name
					}
			}
    }
  `;
}

export function getAccountsOfCompany() {
  return gql`
    query GetTransactionAccountsOfCompany($selectedCompanyID: String) {
      Accounting {
        queries {
          GetAccountsOfCompany(SelectedCompanyID: $selectedCompanyID) {
            accounting_ChartOfAccounts_Id
            accounting_ChartOfAccounts_AccountID,
            accounting_ChartOfAccounts_AccountName,
            accounting_ChartOfAccounts_AccountCurrency{
              Id,
              Code,
              Title,
              Symbol
            }
          }
        }
      }
    }
  `;
}

export function convertAccountToSystemCurrency() {
  return gql`
    mutation convertAccountToSystemCurrency(
      $transactionDate: Date!
      $accountDebitAmount: Decimal
      $accountCreditAmount: Decimal
      $accountCurrency: [String]
      $SystemSecondaryCurrency: [String]
    ) {
      accounting {
        actions {
          convertAccountToSystemCurrency(
            TransactionDate: $transactionDate
            AccountDebitAmount: $accountDebitAmount
            AccountCreditAmount: $accountCreditAmount
            AccountCurrency: $accountCurrency
            SystemSecondaryCurrency: $SystemSecondaryCurrency
          ) {
            AccountToSystemRate
            SystemDebit
            SystemCredit
            SystemSecondaryDebit
            SystemSecondaryCredit
          }
        }
      }
    }
  `;
}

export function createTransaction() {
  return gql`
    mutation createTransaction(
      $ReferenceNumber: String
      $SelectedCompany: String!
      $TotalCredit: Decimal!
      $TotalDebit: Decimal!
      $TransactionDate: Date!
      $TransactionDescription: String
      $TransactionList: [Accounting_TransactionList!]!
      $TransactionNote: String
      $UploadedDocuments: [Upload]
    ) {
      accounting {
        actions {
          createTransaction(
            ReferenceNumber: $ReferenceNumber
            SelectedCompany: $SelectedCompany
            TotalCredit: $TotalCredit
            TotalDebit: $TotalDebit
            TransactionDate: $TransactionDate
            TransactionDescription: $TransactionDescription
            TransactionList: $TransactionList
            TransactionNote: $TransactionNote
            UploadedDocuments: $UploadedDocuments
          ) {
            id
          }
        }
      }
    }
  `;
}

export function getTransactionInfo() {
  return gql`
    query getTransactionInfo($id: String!) {
  Accounting {
    entities {
      transactions(id: $id) {
        id
        views {
          Accounting_all {
            properties {
              RelatedCompany {
                Id
                Code
              }
              TransactionNumber
              ReferenceNumber
              TransactionType
              TransactionDate
              Description
              TransactionDocuments {
                id
                location
                path
                fileName
                length
                contentType
              }
              Note
              TotalSystemDebit
              TotalSystemCredit
            }
          }
        }
        queries {
          accounting {
            GetJournalTransactions {
              accounting_Transactions_Id,
              journalTransactions_Id,
              journalTransactions_RelatedAccount {
                id
              },
              journalTransactions_Description,
              journalTransactions_AccountDebit,
              journalTransactions_AccountCredit,
              journalTransactions_SystemDebit,
              journalTransactions_SystemCredit,
              journalTransactions_SystemSecondaryDebit,
              journalTransactions_SystemSecondaryCredit
            }
          }
        }
      }
    }
  }
}
  `;
}

export function modifyTransaction() {
  return gql`
    mutation modifyTransaction(
  $CurrentTransactionID: String!
  $ReferenceNumber: String
  $DeletedTransactionListID: [String]
  $PersistDocuments: [String]
  $TotalCredit: Decimal!
  $TotalDebit: Decimal!
  $TransactionDate: Date!
  $TransactionDescription: String
  $TransactionList: [Accounting_TransactionList!]!
  $TransactionNote: String
  $UploadedDocuments: [Upload]
) {
  accounting {
    actions {
      modifyTransaction(
        CurrentTransactionID: $CurrentTransactionID
        ReferenceNumber: $ReferenceNumber
        TotalCredit: $TotalCredit
        TotalDebit: $TotalDebit
        TransactionDate: $TransactionDate
        TransactionDescription: $TransactionDescription
        TransactionList: $TransactionList
        TransactionNote: $TransactionNote
        UploadedDocuments: $UploadedDocuments
        DeletedTransactionListID: $DeletedTransactionListID
        PersistDocuments: $PersistDocuments
      )
    }
  }
}
  `;
}
