import {
	EnhancedTableHeader,
	EnhancedTableHeaderType,
} from "../../../../../components/enhanced-table";

export const headers: Record<string, EnhancedTableHeader> = {
	accounting_Bills_BillNumber: {
		name: "accounting_Bills_BillNumber",
		title: "Bill #",
		type: EnhancedTableHeaderType.Text,
	},
  accounting_Bills_DueDate: {
    name: "accounting_Bills_DueDate",
    title: "Due Date",
    type: EnhancedTableHeaderType.Date,
  },
  accounting_Bills_TotalPremium: {
    name: "accounting_Bills_TotalPremium",
    title: "Total Premium",
    type: EnhancedTableHeaderType.Currency,
  },
  amountReceived: {
    name: "amountReceived",
    title: "Amount Received",
    type: EnhancedTableHeaderType.Currency,
  },
  accounting_Bills_OutstandingBalance: {
    name: "accounting_Bills_OutstandingBalance",
    title: "Outstanding Receivable",
    type: EnhancedTableHeaderType.Currency,
  },
  accounting_Bills_TotalCommission: {
    name: "accounting_Bills_TotalCommission",
    title: "Commission",
    type: EnhancedTableHeaderType.Currency,
    tooltipSettings: {
      headers: {
        BusinessPartner: {
          name: 'BusinessPartner',
          title: 'Business Partner',
          type: EnhancedTableHeaderType.Text,
          tooltipSettings: null,
        },
        Share: {
          name: 'Share',
          title: 'Share',
          type: EnhancedTableHeaderType.Percentage,
          tooltipSettings: null,
        },
        Commission: {
          name: 'Commission',
          title: 'Commission',
          type: EnhancedTableHeaderType.Currency,
          tooltipSettings: null,
        },
      },
    },
  },
  payableBills_TaxOnCommission: {
    name: "payableBills_TaxOnCommission",
    title: "Tax on Commission",
    type: EnhancedTableHeaderType.Currency,
  },
  amountPaid: {
    name: "amountPaid",
    title: "Amount Paid",
    type: EnhancedTableHeaderType.Currency,
  },
  payableBills_OutstandingBalance: {
    name: "payableBills_OutstandingBalance",
    title: "Outstanding Payable",
    type: EnhancedTableHeaderType.Currency,
  },
  totalBalance: {
    name: "totalBalance",
    title: "Total Balance",
    type: EnhancedTableHeaderType.Currency,
  },

};
