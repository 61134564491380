import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { isEmpty } from '../../utils/validationUtils';

export const initialValues = {
    company: '',
    type: '',
    fromDate: '',
    toDate: ''
};

export const inputs = (mode: "add" | "edit" = "add", values: Record<string, any> = initialValues, lovs: Record<string, Record<string, string>>) : Record<string, DynamicFormInputType> => ({
    company: {
    name: 'company',
    title: 'Company',
    type: FormInputTypes.chips,
    disabled: mode === "edit",
    value: !isEmpty(values.company)
      ? values.company
      : [],
    selectOptions: lovs?.relatedCompanies,
    required: true,
    multiple: false,
  },
  type: {
    name: 'type',
    title: 'Transfer Type',
    type: FormInputTypes.chips,
    disabled: false,
    value: !isEmpty(values.type)
      ? values.type
      : [],
    selectOptions: lovs?.type,
    required: true,
    multiple: false,
  },
  fromDate: {
    name: 'fromDate',
    title: 'Transfer From Date',
    type: FormInputTypes.date,
    value: values.fromDate,
    required: true,
    disabled: false
  },
  toDate: {
    name: 'toDate',
    title: 'Transfer To Date',
    type: FormInputTypes.date,
    value: values.toDate,
    required: true,
    disabled: false
  },
});
