import { IListingData } from "../../../../../models/listing";
import { addZeroesAndSeparatevalue } from "../../../../../utils/formatting-utils";

export function mapToListingData(data: any): IListingData<any> {
	const list: Record<string, IPaymentTermGenericTableData> = {};
	data.Accounting.queries.GetPolicyBills?.items?.forEach(
		(obj: IPaymentTermGenericTableData, i: number) => {

      // TODO: Make the currency dynamic in the total commission
			list[obj.accounting_Bills_BillNumber] = {
				id: obj.accounting_Bills_Id,
				accounting_Bills_Id: obj.accounting_Bills_BillNumber,
				accounting_Bills_BillNumber: obj.accounting_Bills_BillNumber,
				accounting_Bills_DueDate: obj.accounting_Bills_DueDate,
				accounting_Bills_TotalPremium: obj.accounting_Bills_TotalPremium,
				amountReceived: "--",
				accounting_Bills_OutstandingBalance:
					obj.accounting_Bills_OutstandingBalance,
				accounting_Bills_TotalCommission: `$ ${addZeroesAndSeparatevalue(
        Math.abs(obj.accounting_Bills_TotalCommission))}`,
				payableBills_TaxOnCommission: obj.payableBills_TaxOnCommission,
				amountPaid: "--",
				payableBills_OutstandingBalance: obj.payableBills_OutstandingBalance,
				totalBalance:
					obj.accounting_Bills_OutstandingBalance -
					obj.payableBills_OutstandingBalance,
			};
		}
	);

	return {
		pagedItems: list,
		pageSize: data.Accounting.queries.GetPolicyBills?.paging.pageSize,
		pageNumber: data.Accounting.queries.GetPolicyBills?.paging.pageNumber,
		totalCount: data.Accounting.queries.GetPolicyBills?.paging.totalCount,
	};
}
