import { DynamicFormInputType, FormInputTypes } from '../../DynamicForm';
import { REGEX, isEmpty } from '../../utils/validationUtils';

export const initialValues = {
  type: '',
  companyName: '',
  title: '',
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  phoneType: '',
  phoneNumber: '',
};

export const inputs: Record<string, DynamicFormInputType> = {
  type: {
    name: 'type',
    title: 'Type',
    type: FormInputTypes.chips,
    placeholder: 'Type',
    value: !isEmpty(initialValues.type) ? [initialValues.type] : [],
    multiple: false,
    disabled: false,
    required: true,
  },
  companyName: {
    name: 'companyName',
    title: 'Company Name',
    placeholder: 'Company Name',
    type: FormInputTypes.text,
    value: initialValues.companyName,
    required: false,
    disabled: false,
    hidden: false,
    minCharacters: 2,
    maxCharacters: 100,
    conditionalRequired: (values: Record<string, any>) => {
      const isCompany = values?.type?.toLowerCase() === "company";
      return isCompany;
    },
    conditionalDisable: (values: Record<string, any>) => {
      const isCompany = values?.type?.toLowerCase() === "company";
      return !isCompany;
    },
    conditionalHidden: (values: Record<string, any>) => {
      const isCompany = values?.type?.toLowerCase() === "company";
      return !isCompany;
    },
  },
  title: {
    name: 'title',
    title: 'Title',
    placeholder: 'Title',
    type: FormInputTypes.chips,
    value: !isEmpty(initialValues.title) ? [initialValues.title] : [],
    hidden: false,
    required: false,
    disabled: false,
    conditionalRequired: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return isPerson;
    },
    conditionalDisable: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return !isPerson;
    },
    conditionalHidden: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return !isPerson;
    },
  },
  firstName: {
    name: 'firstName',
    title: 'First Name',
    placeholder: 'First Name',
    type: FormInputTypes.text,
    value: initialValues.firstName,
    hidden: false,
    required: false,
    disabled: false,
    minCharacters: 2,
    maxCharacters: 100,
    conditionalRequired: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return isPerson;
    },
    conditionalDisable: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return !isPerson;
    },
    conditionalHidden: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return !isPerson;
    },
  },
  middleName: {
    name: 'middleName',
    title: 'Middle Name',
    placeholder: 'Middle Name',
    type: FormInputTypes.text,
    value: initialValues.middleName,
    hidden: false,
    required: false,
    disabled: false,
    minCharacters: 1,
    maxCharacters: 100,
    conditionalRequired: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return isPerson;
    },
    conditionalDisable: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return !isPerson;
    },
    conditionalHidden: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return !isPerson;
    },
  },
  lastName: {
    name: 'lastName',
    title: 'Last Name',
    placeholder: 'Last Name',
    type: FormInputTypes.text,
    value: initialValues.lastName,
    hidden: false,
    required: false,
    disabled: false,
    minCharacters: 2,
    maxCharacters: 100,
    conditionalRequired: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return isPerson;
    },
    conditionalDisable: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return !isPerson;
    },
    conditionalHidden: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return !isPerson;
    },
  },
  dateOfBirth: {
    name: 'dateOfBirth',
    title: 'Date of Birth',
    placeholder: 'Date of Birth',
    type: FormInputTypes.date,
    value: initialValues.dateOfBirth,
    hidden: false,
    required: false,
    disabled: false,
    conditionalDisable: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return !isPerson;
    },
    conditionalHidden: (values: Record<string, any>) => {
      const isPerson = values?.type?.toLowerCase() === "person";
      return !isPerson;
    },
  },
  email: {
    name: 'email',
    title: 'Email',
    type: FormInputTypes.text,
    placeholder: 'Email',
    value: !isEmpty(initialValues.email) ? initialValues.email : null,
    required: false,
    regex: REGEX.email,
    regexError: 'Invalid Email',
    order: 4,
  },
  phoneType: {
    name: 'phoneType',
    title: 'Phone Type',
    type: FormInputTypes.chips,
    placeholder: 'Phone Type',
    value: !isEmpty(initialValues.phoneType) ? initialValues.phoneType : [],
    required: false,
    multiple: false,
  },
  phoneNumber: {
    name: 'phoneNumber',
    title: 'Phone Number',
    type: FormInputTypes.phoneNumber,
    placeholder: 'Phone Number',
    value: initialValues.phoneNumber,
    required: false,
    editCountryCode: false,
  },
};
