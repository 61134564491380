import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  checkSublineExists,
  createSubline,
  getSublineEnums,
  getSublineInfo,
  updateSubline,
} from "./queries";
import { LookupToList, graphqlEntityToSublineInfo } from "./utils";
import Loader from "../../components/Loader";
import { cloneDeep } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { isEmpty } from "../../utils/validationUtils";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { getError } from "../../utils/graph-utils";
import { useNavigate } from "react-router-dom";

const SublineDrawer: React.FC<ISublineDrawerProps> = ({
  sublineId,
  lineId,
  lineName,
  isLineNameDisabled = true,
  canNavigateToDetailsPage = false,
  open,
  onSuccess,
  onClose,
}) => {
  const navigate = useNavigate();

  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [sublineEnumQuery] = useLazyQuery(getSublineEnums(), {
    fetchPolicy: "no-cache",
  });

  const [sublineInfoQuery] = useLazyQuery(getSublineInfo(), {
    fetchPolicy: "no-cache",
  });

  const [sublineAction] = useMutation(
    sublineId ? updateSubline() : createSubline()
  );

  const [checkSublineExistsLazy] = useLazyQuery(checkSublineExists(), {});

  const loadLovList = async () => {
    const result = await sublineEnumQuery();
    const sublineEnums = LookupToList(result.data);

    return sublineEnums;
  };

  const getEntityInfo = async () => {
    if (sublineId) {
      const apiResult = await sublineInfoQuery({
        variables: { id: sublineId },
      });
      if (apiResult.data) {
        const sublineEntity = graphqlEntityToSublineInfo(
          apiResult.data,
          lineId,
          lineName
        );
        return sublineEntity;
      }
    }

    return null;
  };

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      const [sublineEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      if (sublineId) {
        if (sublineEntity) {
          updatedInputs.lineName.value = sublineEntity.lineId;
          updatedInputs.sublineName.value = sublineEntity.sublineName;
          updatedInputs.sublineExternalCode.value =
            sublineEntity.sublineExternalCode;
          updatedInputs.arabicName.value = sublineEntity.arabicName;
          updatedInputs.sublineStatus.value = sublineEntity.sublineStatus;
        }
      }

      (updatedInputs.lineName as IFormSelectDynamicProps).selectOptions =
        lovData["lines"];
      updatedInputs.lineName.value = lineId;
      updatedInputs.lineName.disabled = isLineNameDisabled;

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variables = {
        subLineInputs: {
          externalCode: data.sublineExternalCode,
          lineID: lineId || data.lineName,
          name: data.sublineName,
          nameArabic: data.arabicName,
          sublineStatus: data.sublineStatus,
        },
      };

      const res = await sublineAction({
        variables: sublineId
          ? { ...variables, entityId: sublineId }
          : variables,
        errorPolicy: "all",
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {sublineId
              ? "Subline successfully updated"
              : "Subline successfully created"}
          </ToastSuccessMessage>
        );

        if (isEmpty(lineId)) {
          const newSublineId =
            res.data.planConfigManagement.actions.createSubline.id;
          setTimeout(() => {
            setSubmitButtonState("success");
            setFormDisabled(false);
            onSuccess();
            onClose();
            navigate(`/plan/sublines/` + newSublineId);
          }, 500);
        } else {
          setTimeout(() => {
            setSubmitButtonState("success");
            setFormDisabled(false);
            onSuccess();
            onClose();
          }, 500);
        }
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (error) {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
    }
  };

  const validateSublineExistsOnNameChange = async (
    selectedLineId?: string,
    sublineName?: string
  ): Promise<string> => {
    if (!isEmpty(lineId || selectedLineId) && !isEmpty(sublineName)) {
      const validationResponse = await checkSublineExistsLazy({
        variables: {
          lineId: lineId || selectedLineId,
          name: sublineName,
          sublineId: sublineId,
          externalCode: "",
        },
      });
      if (
        validationResponse.data.PlanConfigManagement?.queries
          ?.checkSublineExists.length > 0
      ) {
        return "Subline Name already exists under the same Line";
      }
    }
    return "";
  };

  const validateSublineExistsOnExternalCodeChange = async (
    selectedLineId?: string,
    sublineExternalCode?: string
  ): Promise<string> => {
    if (!isEmpty(lineId || selectedLineId) && !isEmpty(sublineExternalCode)) {
      const validationResponse = await checkSublineExistsLazy({
        variables: {
          lineId: lineId || selectedLineId,
          externalCode: sublineExternalCode,
          sublineId: sublineId,
          name: "",
        },
      });
      if (
        validationResponse.data.PlanConfigManagement?.queries
          ?.checkSublineExists.length > 0
      ) {
        return "Subline Name already exists under the same Line";
      }
    }
    return "";
  };

  const onCustomValidate = async (
    fieldName: string,
    values: Record<string, any>
  ) => {
    if (fieldName === "sublineName") {
      return await validateSublineExistsOnNameChange(
        values.lineName,
        values[fieldName]
      );
    }

    if (fieldName === "sublineExternalCode") {
      return await validateSublineExistsOnExternalCodeChange(
        values.lineName,
        values[fieldName]
      );
    }

    return "";
  };

  useEffect(() => {
    initialize();
  }, []);

  const onChange = async (
    fieldName: string,
    value: unknown,
    values: Record<string, any>,
    errors: Record<string, string>,
    touched: Record<string, boolean>
  ) => {
    if (fieldName === "lineName") {
      const [policyCoverError, sublineExternalCodeError] = await Promise.all([
        onCustomValidate("sublineName", values),
        onCustomValidate("sublineExternalCode", values),
      ]);
      errors.sublineName = policyCoverError;
      errors.sublineExternalCode = sublineExternalCodeError;

      touched.sublineName = true;
      touched.sublineExternalCode = true;
    }
  };

  const onCustomBlur = async (
    fieldName: string,
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (fieldName === "sublineName" || fieldName === "sublineExternalCode") {
      if (!errors[fieldName]) {
        errors[fieldName] = await onCustomValidate(fieldName, values);
      }
    }

    if (fieldName === "lineName") {
      if (values.sublineName) {
        errors.sublineName = await onCustomValidate("sublineName", values);
      }

      if (values.sublineExternalCode) {
        errors.sublineExternalCode = await onCustomValidate(
          "sublineExternalCode",
          values
        );
      }
    }
    return { values, errors };
  };

  const onCustomValidateForm = async (
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (!errors.sublineName) {
      errors.sublineName = await onCustomValidate("sublineName", values);
    }

    if (!errors.sublineExternalCode) {
      errors.sublineExternalCode = await onCustomValidate(
        "sublineExternalCode",
        values
      );
    }

    return errors;
  };

  return (
    <GenericDrawer
      title={sublineId ? "Edit Subline" : "New Subline"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted && open ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            onChange={onChange}
            onCustomBlur={(f, v, e) => onCustomBlur(f, v, e)}
            onCustomValidate={onCustomValidateForm}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default SublineDrawer;
