import clsx from "clsx";
import React from "react";
import { makeStyles } from "tss-react/mui";
import { IEnhancedCommonProps } from "..";
import { MAIN_ONE_THEME, contentFontFamilyRegular } from "../../constants";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

export interface IEnhancedInputWrapperProps extends IEnhancedCommonProps {
  name: string;
  title: string;
  description?: string;
  error?: string;
  childrenClass?: string;
  children?: React.ReactNode;
  customStyles?: {
    labelStyles?: string;
  };
  actions?: JSX.Element;
  hidden?: boolean;
  includeCapsLockCheck?: boolean;
  hasTooltip?: boolean;
  tooltipText?: string;
  hideError?: boolean;
  hideHeader?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  labelWrapper: {
    display: "block",
    textAlign: "left",
    fontSize: `${MAIN_ONE_THEME.typography.regular.reg2.fontSize}px`,
    lineHeight: "15px",
  },
  label: {
    display: "block",
    color: MAIN_ONE_THEME.palette.primary1.main,
    textAlign: "left",
    margin: "0 0 13px",
    fontFamily: contentFontFamilyRegular,
  },
  description: {
    fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
    fontStyle: "italic",
    marginLeft: "5px",
    color: MAIN_ONE_THEME.palette.primary1.main,
  },
  error: {
    textAlign: "right",
    fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
    minHeight: "15px",
    display: "block",
    color: MAIN_ONE_THEME.palette.error.main,
    margin: "5px 0 0 ",
  },
  childrenContainer: {
    position: "relative",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    direction: "ltr",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  capsLockWarning: {
    color: "#D30D2B",
    fontSize: MAIN_ONE_THEME.typography.small.sm2.fontSize,
    display: "block",
    marginTop: "-15px",
    textAlign: "left",
  },
  tooltipIcon: {
    marginLeft: "5px",
    cursor: "pointer",
    width: "12px",
    height: "12px",
  },
  tooltip: {
    backgroundColor: "white",
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: "12px",
    padding: "10px",
  },
  arrow: {
    color: "white",
    "&::before": {
      boxShadow: theme.shadows[1],
    },
  },
}));

const EnhancedInputsWrapper: React.FC<IEnhancedInputWrapperProps> = ({
  className,
  error,
  style,
  name,
  title,
  description,
  children,
  childrenClass,
  customStyles,
  actions = <></>,
  hidden = false,
  includeCapsLockCheck = false,
  hasTooltip = false,
  tooltipText,
  hideError = false,
  hideHeader = false,
}) => {
  const { classes } = useStyles();

  return (
    !hidden && (
      <label
        htmlFor={name}
        className={clsx(className, classes.labelWrapper)}
        style={style}
      >
        {!hideHeader && (
          <div className={classes.headerContainer}>
            <span
              className={clsx(
                classes.label,
                customStyles && customStyles.labelStyles
              )}
            >
              {title}
              {hasTooltip && tooltipText !== "" && (
                <Tooltip
                  title={tooltipText || ""}
                  placement="top"
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.arrow,
                  }}
                  arrow
                >
                  <InfoIcon className={classes.tooltipIcon} />
                </Tooltip>
              )}
            </span>
            <div className={classes.actionsContainer}>{actions}</div>
          </div>
        )}
        <div className={clsx(classes.childrenContainer, childrenClass)}>
          {children}
        </div>
        {!hideError && (
          <span id={`errorMsg-${name}`} className={classes.error}>
            {error}
          </span>
        )}
        {includeCapsLockCheck && (
          <span className={classes.capsLockWarning}>Caps Lock is on</span>
        )}
      </label>
    )
  );
};

export default EnhancedInputsWrapper;
