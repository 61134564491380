import React, { useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import _ from 'lodash';
import { removeObjectAtIndex } from '../../../utils/helper-utils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import NewChipsInput from '../../../components/enhanced-form/NewChipsInput';
import EnhancedInput from '../../../components/enhanced-form/EnhancedInput';
import { IAccountCurrency, ITransactionFormState } from '../form';
import { useMutation } from '@apollo/client';
import { convertAccountToSystemCurrency } from '../queries';
import { isEmpty } from '../../../utils/validationUtils';
import EnhancedCurrencyInput from '../../../components/enhanced-form/EnhancedCurrencyInput';

const useStyles = makeStyles()(() => ({
  container: {
    boxShadow: '0px 0px 10px #DFE3EBC9',
    border: '1px solid #E8E8E8',
    paddingTop: '11px',
    marginBottom: '21px',
    marginTop: '38px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '4.0fr 3.0fr 3.0fr 3.0fr 0.5fr',
    gridColumnGap: '18px',
    paddingLeft: '39px',
    paddingRight: '39px',
  },
  gridItem: {
    display: 'grid',
    gridTemplateColumns: '6fr 6fr',
    gridColumnGap: '18px',
    height: '56px',
  },
  titlesContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '4.5px',
    paddingBottom: '4.5px',
    lineHeight: '21px',
  },
  titles: {
    paddingTop: '15px',
    paddingBottom: '13px',
    lineHeight: '21px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstTitles: {
    display: 'flex',
    justifyContent: 'start',
  },
  span: {
    fontSize: '15px',
    fontFamily: 'SourceSansPro-bold',
  },
  accountCurrencyTitle: {
    backgroundColor: '#FCF5F5',
    color: '#C00020',
  },
  accountCurrencyTitles: {
    color: '#C00020',
  },
  systemCurrencyBaseTitle: {
    backgroundColor: '#F4F8EF',
    color: '#525A49',
  },
  systemCurrencyBaseTitles: {
    color: '#525A49',
  },
  systemCurrencySecondaryTitle: {
    backgroundColor: '#ECF7F5',
    color: '#586E6A',
  },
  systemCurrencySecondaryTitles: {
    color: '#586E6A',
  },

  addBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    padding: '0',
    margin: '21px auto 25px',
    display: 'block',
  },
  removeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    paddingTop: '5px',
    display: 'block',
  },
  containerStyles: {
    paddingTop: 0,
    minHeight: '32px',
  },
  evenRow: {
    backgroundColor: '#F9F9F9',
  },
  oddRow: {},
  column: {
    paddingTop: 0,
    '&.MuiGrid-item': {
      paddingTop: '0 !important',
    },
  },
  field: {
    '& span:first-of-type': {
      minHeight: 0,
    },
    '& input': {
      height: '32px',
    },
  },
  labelSelector: {
    minHeight: 0,
  },
  inputSelector: {
    height: '32px',
  },
}));

export const initialValues = {
  initialError: {
    accountName: '',
    description: '',
    accountDebit: '',
    accountCredit: '',
    systemDebit: '',
    systemCredit: '',
    systemSecondaryDebit: '',
    systemSecondaryCredit: '',
  },
  initialTouched: {
    accountName: false,
    description: false,
    accountDebit: false,
    accountCredit: false,
    systemDebit: false,
    systemCredit: false,
    systemSecondaryDebit: false,
    systemSecondaryCredit: false,
  },
};

interface IAccountCurrencyProps {
  systemCurrency: string;
  systemSecondaryCurrency: string;
  systemSecondaryCurrencyCode: string;
  transactionDate: Date;
  pageState: ITransactionFormState;
  onPageStateUpdate: (pageState: ITransactionFormState) => void;
  lovs: {
    companies: Record<string, string>;
    account: Record<string, string>;
    currencies: Record<string, { id: string; symbol: string }>;
  };
}

const AccountCurrency: React.FC<IAccountCurrencyProps> = ({
  systemCurrency,
  systemSecondaryCurrency,
  systemSecondaryCurrencyCode,
  transactionDate,
  pageState,
  onPageStateUpdate,
  lovs,
}) => {
  const { classes } = useStyles();

  const [convertAccountToSystem] = useMutation(
    convertAccountToSystemCurrency()
  );

  const values = pageState.values.account;
  const errors = pageState.errors.account;
  const touched = pageState.touched.account;

  const convertAccountToSys = async (
		debit: number,
		credit: number,
		rowIndex: number,
    localPageState: any = pageState,
		accountName: string = "",
    currencies: Record<string, { id: string; symbol: string }> = lovs.currencies,
    localTransactionDate?: Date,
	) => {
		const newPageState = _.cloneDeep(localPageState);

		const res = await convertAccountToSystem({
			variables: {
				transactionDate: localTransactionDate ?? transactionDate,
				accountDebitAmount: parseFloat(debit.toString()),
				accountCreditAmount: parseFloat(credit.toString()),
				accountCurrency: [
					currencies[accountName].id,
				],
				SystemSecondaryCurrency: [systemSecondaryCurrencyCode],
			},
			errorPolicy: "all",
		});

		if (res) {
			const newDebit =
				res?.data?.accounting?.actions?.convertAccountToSystemCurrency
					?.SystemDebit;
			const newCredit =
				res?.data?.accounting?.actions?.convertAccountToSystemCurrency
					?.SystemCredit;
			const newSecondaryDebit =
				res?.data?.accounting?.actions?.convertAccountToSystemCurrency
					?.SystemSecondaryDebit;
			const newSecondaryCredit =
				res?.data?.accounting?.actions?.convertAccountToSystemCurrency
					?.SystemSecondaryCredit;

			(newPageState.values.account[rowIndex] as any)["accountDebit"] = debit;
			(newPageState.values.account[rowIndex] as any)["accountCredit"] = credit;
			(newPageState.values.account[rowIndex] as any)["systemDebit"] = newDebit;
			(newPageState.values.account[rowIndex] as any)["systemCredit"] =
				newCredit;
			(newPageState.values.account[rowIndex] as any)["systemSecondaryDebit"] =
				newSecondaryDebit;
			(newPageState.values.account[rowIndex] as any)["systemSecondaryCredit"] =
				newSecondaryCredit;

        
			if (!isEmpty(accountName)) {
				(newPageState.values.account[rowIndex] as any)["accountName"] =
					accountName;
			}

      if (isEmpty(res.errors)) {
        (newPageState.errors.account[rowIndex] as any)[
          debit !== 0 ? `accountDebit` : `accountCredit`
        ] = '';
      } else {
        const newError = res.errors[0].message;

        if(debit !== 0 ) {
          (newPageState.errors.account[rowIndex] as any)[`accountDebit`] = newError;
          (newPageState.errors.account[rowIndex] as any)[`accountCredit`] = null;
        }

        if(credit !== 0) {
          (newPageState.errors.account[rowIndex] as any)[`accountCredit`] = newError;
          (newPageState.errors.account[rowIndex] as any)[`accountDebit`] = null;
        }
      }

      onPageStateUpdate(newPageState);
		}
	};

  const debouncedConvertAccountToSys = useCallback(
		_.debounce(
			async (
        debit: number,
        credit: number,
        rowIndex: number,
        pageState: any,
        accountName: string = "",
        currencies: Record<string, { id: string; symbol: string }> = lovs.currencies,
        transactionDate: Date
			) => {
				await convertAccountToSys(debit, credit, rowIndex, pageState, accountName, currencies, transactionDate);
			},
			300,
			{ leading: false, trailing: true }
		),
		[]
	);

  const onFieldBlur = (fieldName: string, rowIndex: number) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.touched.account[rowIndex][fieldName] = true;
    onPageStateUpdate(newPageState);
  };

  const onFieldUpdate = (
    fieldName: keyof IAccountCurrency,
    value: any,
    rowIndex: number,
    touched = false
  ) => {
    const newPageState = _.cloneDeep(pageState);

    if (fieldName === "accountDebit") {
			(newPageState.values.account[rowIndex] as any)["accountCredit"] = 0;
      debouncedConvertAccountToSys(value, 0, rowIndex, pageState, pageState.values.account[rowIndex].accountName, lovs.currencies, transactionDate);
		}
    if (fieldName === 'accountCredit') {
      (newPageState.values.account[rowIndex] as any)['accountDebit'] = 0;
      debouncedConvertAccountToSys(0, value, rowIndex, pageState, pageState.values.account[rowIndex].accountName, lovs.currencies, transactionDate);
    }

    if(fieldName === 'accountName') {
      (newPageState.values.account[rowIndex] as any)['accountCredit'] = 0;
      (newPageState.values.account[rowIndex] as any)['accountDebit'] = 0;
      convertAccountToSys(0, 0, rowIndex, value);
    }

    (newPageState.values.account[rowIndex] as any)[fieldName] = value;

    if (touched) {
      newPageState.touched.account[rowIndex][fieldName] = true;
    }
    onPageStateUpdate(newPageState);
  };

  const getAccountCurrency = (rowIndex: number) => {
    if (
      !isEmpty(lovs.currencies) &&
      !isEmpty(pageState.values.account[rowIndex].accountName)
    ) {
      return lovs.currencies[pageState.values.account[rowIndex].accountName]
        .symbol;
    }
    return '';
  };

  const renderRow = (row: IAccountCurrency) => {
    const filteredPlanLov = { ...lovs.account };

    const rowIndex = values.indexOf(row);

    return (
      <div
        className={clsx(
          'container',
          classes.grid,
          rowIndex % 2 === 0 ? classes.evenRow : classes.oddRow
        )}
      >
        <div className={clsx('item', classes.gridItem)}>
          <NewChipsInput
            key="accountName"
            name="accountName"
            value={values[rowIndex]?.accountName}
            error={
              touched[rowIndex]?.accountName ? errors[rowIndex].accountName : ''
            }
            onChange={(v) => {
              onFieldUpdate('accountName', v, rowIndex);
            }}
            customStyles={{
              containerStyles: classes.containerStyles,
              labelStyles: classes.labelSelector,
              inputStyles: classes.inputSelector,
            }}
            disabled= {!pageState?.values?.transaction?.company || !pageState?.values?.transaction?.date}
            items={filteredPlanLov}
            required={true}
          />
          <EnhancedInput
            type="text"
            key="description"
            name="description"
            title=""
            value={values[rowIndex]?.description || ''}
            error={
              touched[rowIndex]?.description ? errors[rowIndex].description : ''
            }
            onBlur={(v) => onFieldBlur('description', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('description', v.target.value, rowIndex)
            }
            disabled= {!pageState?.values?.transaction?.company || !pageState?.values?.transaction?.date}
            customStyles={{ inputStyles: classes.containerStyles }}
            className={classes.field}
          />
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <EnhancedCurrencyInput
            type="number"
            key="accountDebit"
            name="accountDebit"
            title=""
            value={
              values[rowIndex]?.accountDebit === 0
                ? Number(values[rowIndex]?.accountDebit).toFixed(2)
                : Number(values[rowIndex]?.accountDebit) || ''
            }
            error={
              touched[rowIndex]?.accountDebit
                ? errors[rowIndex]?.accountDebit
                : ''
            }
            onBlur={(v) => onFieldBlur('accountDebit', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('accountDebit', v.target.value, rowIndex)
            }
            customStyles={{ inputStyles: classes.containerStyles }}
            disabled={!values[rowIndex].accountName}
            className={classes.field}
            maxDecimalPercision={3}
            useCurrencyText
            currencyText={getAccountCurrency(rowIndex)}
            currencyTextColor="#C00020"
          />
          <EnhancedCurrencyInput
            type="number"
            key="accountCredit"
            name="accountCredit"
            title=""
            value={values[rowIndex]?.accountCredit === 0
              ? Number(values[rowIndex]?.accountCredit).toFixed(2)
              : Number(values[rowIndex]?.accountCredit) || ''}
            error={
              touched[rowIndex]?.accountCredit
                ? errors[rowIndex]?.accountCredit
                : ''
            }
            onBlur={(v) => onFieldBlur('accountCredit', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('accountCredit', v.target.value, rowIndex)
            }
            customStyles={{ inputStyles: classes.containerStyles }}
            disabled={!values[rowIndex]?.accountName}
            className={classes.field}
            maxDecimalPercision={3}
            useCurrencyText
            currencyText={getAccountCurrency(rowIndex)}
            currencyTextColor="#C00020"
          />
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <EnhancedCurrencyInput
            type="number"
            key="systemDebit"
            name="systemDebit"
            title=""
            currencyTitle={systemCurrency}
            currencyIcon={systemCurrency}
            value={Number(values[rowIndex]?.systemDebit).toFixed(2) || ''}
            error={
              touched[rowIndex]?.systemDebit
                ? errors[rowIndex]?.systemDebit
                : ''
            }
            onBlur={(v) => onFieldBlur('systemDebit', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('systemDebit', v.target.value, rowIndex)
            }
            customStyles={{ inputStyles: classes.containerStyles }}
            className={classes.field}
            disabled
            useCurrencyText
            currencyText={systemCurrency}
          />
          <EnhancedCurrencyInput
            type="number"
            key="systemCredit"
            name="systemCredit"
            title=""
            currencyTitle={systemCurrency}
            currencyIcon={systemCurrency}
            value={Number(values[rowIndex]?.systemCredit).toFixed(2) || ''}
            error={
              touched[rowIndex]?.systemCredit
                ? errors[rowIndex]?.systemCredit
                : ''
            }
            onBlur={(v) => onFieldBlur('systemCredit', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('systemCredit', v.target.value, rowIndex)
            }
            customStyles={{ inputStyles: classes.containerStyles }}
            className={classes.field}
            disabled
            useCurrencyText
            currencyText={systemCurrency}
          />
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <EnhancedCurrencyInput
            type="number"
            key="systemSecondaryDebit"
            name="systemSecondaryDebit"
            title=""
            currencyTitle={systemSecondaryCurrency}
            currencyIcon={systemSecondaryCurrency}
            value={
              Number(values[rowIndex]?.systemSecondaryDebit).toFixed(2) || ''
            }
            error={
              touched[rowIndex]?.systemSecondaryDebit
                ? errors[rowIndex]?.systemDebit
                : ''
            }
            onBlur={(v) => onFieldBlur('systemDebit', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('systemDebit', v.target.value, rowIndex)
            }
            customStyles={{ inputStyles: classes.containerStyles }}
            className={classes.field}
            disabled
            useCurrencyText
            currencyText={systemSecondaryCurrency}
          />
          <EnhancedCurrencyInput
            type="number"
            key="systemSecondaryCredit"
            name="systemSecondaryCredit"
            title=""
            currencyTitle={systemSecondaryCurrency}
            currencyIcon={systemSecondaryCurrency}
            value={
              Number(values[rowIndex]?.systemSecondaryCredit).toFixed(2) || ''
            }
            error={
              touched[rowIndex]?.systemSecondaryCredit
                ? errors[rowIndex]?.systemSecondaryCredit
                : ''
            }
            onBlur={(v) => onFieldBlur('systemCredit', rowIndex)}
            onChange={(v) =>
              onFieldUpdate('systemCredit', v.target.value, rowIndex)
            }
            customStyles={{ inputStyles: classes.containerStyles }}
            className={classes.field}
            disabled
            useCurrencyText
            currencyText={systemSecondaryCurrency}
          />
        </div>
        <div className={clsx('item', classes.gridItem)}>
          {values.length > 2 && (
            <button
              className={classes.removeBtn}
              onClick={() => onRowDelete(rowIndex)}
            >
              <ClearIcon fontSize="medium" />
            </button>
          )}
        </div>
      </div>
    );
  };

  const onRowAdd = () => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.account.push({
      id: null,
      accountName: '',
      description: '',
      accountDebit: 0,
      accountCredit: 0,
      systemDebit: 0,
      systemCredit: 0,
      systemSecondaryDebit: 0,
      systemSecondaryCredit: 0,
    });

    newPageState.errors.account.push({ ...initialValues.initialError });

    newPageState.touched.account.push({ ...initialValues.initialTouched });

    onPageStateUpdate(newPageState);
  };

  const onRowDelete = (rowIndex: number) => {
    const newPageState = _.cloneDeep(pageState);
    newPageState.values.account = removeObjectAtIndex(
      newPageState.values.account,
      rowIndex
    );
    newPageState.errors.account = removeObjectAtIndex(
      newPageState.errors.account,
      rowIndex
    );
    newPageState.touched.account = removeObjectAtIndex(
      newPageState.touched.account,
      rowIndex
    );
    newPageState.values.delete.push(values[rowIndex]?.id);
    onPageStateUpdate(newPageState);
  };

  const shouldDisplayPlus = () => {
    const filteredPlanLov = { ...lovs.account };

    values.forEach((r) => {
      if (r.accountName) {
        delete filteredPlanLov[r.accountName];
      }
    });

    return Object.keys(filteredPlanLov).length > 0;
  };

  return (
    <div className={classes.container}>
      <div className={clsx('container', classes.grid)}>
        <div className="item"></div>
        <div className="item">
          <div
            className={clsx(
              classes.titlesContainer,
              classes.accountCurrencyTitle
            )}
          >
            <span className={classes.span}>Account Currency</span>
          </div>
        </div>
        <div className="item">
          <div
            className={clsx(
              classes.titlesContainer,
              classes.systemCurrencyBaseTitle
            )}
          >
            <span className={classes.span}>System Currency (Basic)</span>
          </div>
        </div>
        <div className="item">
          <div
            className={clsx(
              classes.titlesContainer,
              classes.systemCurrencySecondaryTitle
            )}
          >
            <span className={classes.span}>System Currency (Secondary)</span>
          </div>
        </div>
        <div className="item"></div>
      </div>
      <div className={clsx('container', classes.grid)}>
        <div className={clsx('item', classes.gridItem)}>
          <div className={clsx(classes.titles, classes.firstTitles)}>
            <span className={classes.span}>Account*</span>
          </div>
          <div className={clsx(classes.titles, classes.firstTitles)}>
            <span className={classes.span}>Description*</span>
          </div>
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <div className={clsx(classes.titles, classes.accountCurrencyTitles)}>
            <span className={classes.span}>Debit</span>
          </div>
          <div className={clsx(classes.titles, classes.accountCurrencyTitles)}>
            <span className={classes.span}>Credit</span>
          </div>
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <div
            className={clsx(classes.titles, classes.systemCurrencyBaseTitles)}
          >
            <span className={classes.span}>Debit</span>
          </div>
          <div
            className={clsx(classes.titles, classes.systemCurrencyBaseTitles)}
          >
            <span className={classes.span}>Credit</span>
          </div>
        </div>
        <div className={clsx('item', classes.gridItem)}>
          <div
            className={clsx(
              classes.titles,
              classes.systemCurrencySecondaryTitles
            )}
          >
            <span className={classes.span}>Debit</span>
          </div>
          <div
            className={clsx(
              classes.titles,
              classes.systemCurrencySecondaryTitles
            )}
          >
            <span className={classes.span}>Credit</span>
          </div>
        </div>
        <div className="item"></div>
      </div>
      {values.map((v) => renderRow(v))}

      <button
        className={classes.addBtn}
        onClick={() => onRowAdd()}
        disabled={!shouldDisplayPlus()}
      >
        <AddCircleOutlineIcon
          color={!shouldDisplayPlus() ? 'disabled' : 'error'}
          fontSize="medium"
        />
      </button>
    </div>
  );
};

export default AccountCurrency;
