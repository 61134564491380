import React, { useCallback } from "react";
import {
  isEmpty,
  isValidNumber,
  isValidNumberInput,
} from "../../utils/validationUtils";
import EnhancedInput, { IEnhancedInputProps } from "./EnhancedInput";
import { reduceNumberOfDecimals, removeNumberDelimeter } from "../../utils/formatting-utils";

export interface IEnhancedNumberInputProps
  extends Omit<IEnhancedInputProps, "type"> {
  value: string | number;
  minValue?: number;
  maxValue?: number;
  loader?: boolean;
  hasBetweenValidation?: boolean;
  disableNegativeValues?: boolean;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hidden?: boolean;
  isYear?: boolean;
  isEditable?: boolean;
  hideHeader?: boolean;
  hideError?: boolean;
  hasPrefix?: boolean;
  prefixValue?: string;
  maxDecimalPrecision?: number; // Added prop
}

const EnhancedNumberInput: React.FC<IEnhancedNumberInputProps> = ({
  name,
  title,
  value,
  placeholder,
  onChange,
  onBlur,
  minValue,
  maxValue,
  hasBetweenValidation = false,
  isYear = false,
  material,
  disableNegativeValues,
  error,
  hidden = false,
  isEditable = false,
  hideHeader = false,
  hideError = false,
  hasPrefix = false,
  prefixValue = '',
  maxDecimalPrecision = 2, // Default value set here
  ...props
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let validation = false;
      let currentValue = "";
      if (!isYear) {
        if (!isEmpty(event.target.value)) {
          if (
            Number(event.target.value) < 0 &&
            disableNegativeValues &&
            !isEmpty(event.target.value)
          ) {
            event.target.value = minValue?.toString() || "0";
          }
          currentValue = event.target.value;
          currentValue = limitNumber(currentValue);
          const inputValue = Number(currentValue);
          if (isValidNumber(minValue) && isValidNumber(maxValue)) {
            validation = isValidNumberInput(
              inputValue,
              Number(minValue),
              Number(maxValue)
            );
          } else if (isValidNumber(maxValue)) {
            validation = inputValue <= Number(maxValue);
          } else if (isValidNumber(minValue)) {
            validation = inputValue >= Number(minValue);
          } else {
            validation = true;
          }
        } else {
          validation = true;
        }
        if (validation) {
          onChange({
            ...event,
            target: {
              ...event.target,
              value: currentValue,
              name: event.target.name,
            },
          });
        }
      }
    },
    [
      maxValue,
      minValue,
      onChange,
    ]
  );

  
  const limitNumber = useCallback(
    (targetValue: string) => {
      let newValue = removeNumberDelimeter(targetValue);
      if (isValidNumber(maxDecimalPrecision) &&
        Number(maxDecimalPrecision) >= 0 
      ) {
        newValue = reduceNumberOfDecimals(
          newValue,
          Number(maxDecimalPrecision),
        );
      }
      return newValue;
    },
    [maxDecimalPrecision],
  );

  return (
    <>
      {!hidden && (
        <EnhancedInput
          {...props}
          type="number"
          name={name}
          title={title}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={onBlur}
          material={material}
          error={error}
          minValue={minValue}
          maxValue={maxValue}
          isEditable={isEditable}
          hideHeader={hideHeader}
          hideError={hideError}
          hasPrefix={hasPrefix}
          prefixValue={prefixValue}
        />
      )}
    </>
  );
};

export default EnhancedNumberInput;
