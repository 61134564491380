import { gql } from '@apollo/client';

export const getBusinessPartnerInfo = gql`
  query getBusinessPartnerInfo($id: String!) {
    SalesforceManagement {
      entities {
        businessPartner(id: $id) {
          id
          views {
            SalesforceManagement_all {
              properties {
                PersonID {
                  id
                  views {
                    SalesforceManagement_all {
                      properties {
                        FullName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getLovs = gql`
  query {
    Core {
      lookups {
        currencies {
          Code
          Symbol
        }
      }
    }
    PlanConfigManagement {
      queries {
        allLines {
          planConfigManagement_Line_Name
          planConfigManagement_Line_Id
        }
      }
    }
  }
`;

export const getPolicies = gql(`
  query BrokerPoliciesList($SelectedLine: [String], $SelectedCurrency: [String], $PolicyIssuanceFrom: Date, $PolicyIssuanceTo: Date, $BusinessPartnerID: String, $pagination: PaginationInput!) {
    Accounting {
      queries {
        BrokerPoliciesList(SelectedLine: $SelectedLine, SelectedCurrency: $SelectedCurrency, PolicyIssuanceFrom: $PolicyIssuanceFrom, PolicyIssuanceTo: $PolicyIssuanceTo, BusinessPartnerID: $BusinessPartnerID, pagination: $pagination) {
          items {
            policy_PolicyName
            policy_Id
            policy_PaymentDivisionType
            receivableBills_TotalPremium
            receivableBills_OutstandingBalance
            receivableBills_TotalCommission
            receivableBills_TaxOnCommission
            payableBills_OutstandingBalance
            policy_PaymentMode {
              Title
            }
          }
          paging {
            pageNumber
            pageSize
            totalCount
          }
        }
      }
    }
  }
  `);

  export const getBreakdown = gql(`
  query GetPolicyReceivableBills($PolicyID: String!, $BusinessPartnerID: String!, $pagination: PaginationInput!) {
    Accounting {
      queries {
        GetPolicyReceivableBills(PolicyID: $PolicyID) {
          accounting_Bills_Id
          accounting_Bills_BillNumber
          accounting_Bills_DueDate
          accounting_Bills_NetPremium
          accounting_Bills_TotalPremium
          accounting_Bills_OutstandingBalance
          accounting_Bills_BillStatus
        }
        GetBusinessPartnerBillOfPolicy(PolicyID: $PolicyID, BusinessPartnerID: $BusinessPartnerID, pagination: $pagination) {
          items {
            accounting_Bills_BillNumber
            accounting_Bills_DueDate
            accounting_Bills_Discount
            accounting_Bills_TotalCommission
            accounting_Bills_OutstandingBalance
            accounting_Bills_BillStatus
            accounting_Bills_Id
          }
          paging {
            pageSize
            pageNumber
            totalCount
          }
        }
      }
    }
  }
  `);
