import { IGenericPoliciesData, IInsuredDetails } from ".";
import { IAbstractRecord } from "../../../../models";
import { IListingData } from "../../../../models/listing";
import { capitalizeFirstLetter } from "../../../../utils/formatting-utils";


export function GetInsuredData(response: any): IInsuredDetails | undefined {
  const insured =
    response?.Production?.entities?.insured?.views?.Production_all?.properties;
  const building = insured?.Address?.Building;
  const street = insured?.Address?.Street;
  const city = insured?.Address?.City;
  const zone = insured?.Address?.Zone?.Title;
  const country = insured?.Address?.Country?.Title;
  const address = `${building ? building + ", " : ""} ${
    street ? street + ", " : ""
  } ${city ? city + ", " : ""} ${zone ? zone + ", " : ""} ${
    country ? country : ""
  }`;

  return {
    code: insured?.InsuredCode || "",
    insuredName: insured?.FullName || "",
    modifiedBy: `${response?.Production?.entities?.insured?.queries?.production?.relatedUsers[0]?.modifiedBy_firstName} ${response?.Production?.entities?.insured?.queries?.production?.relatedUsers[0]?.modifiedBy_lastName}`,
    modifiedOn: insured?.modifiedOn || "",
    createdBy: `${response?.Production?.entities?.insured?.queries?.production?.relatedUsers[0]?.createdBy_firstName} ${response?.Production?.entities?.insured?.queries?.production?.relatedUsers[0]?.createdBy_lastName}`,
    createdOn: insured?.createdOn || "",
    DateOfBirth: insured?.DateOfBirth || "",
    PhoneType: insured?.PhoneDetails.PhoneType || "",
    PhoneNumber: insured?.PhoneDetails.PhoneNumber || "",
    Nationality: insured?.NationalityOne?.Title || "",
    Gender: insured?.Gender || "",
    PassportNumber: insured?.PassportNumber || "",
    OccupationClass: insured?.OccupationClass?.Title || "",
    Profession: insured?.Profession || "",
    AddressType: insured?.AddressType || "",
    Address: address || "",
    Taxable: insured?.IsTaxable ? "Yes" : "No",
    AssignedToBusinessPartnerID: insured?.AssignedToBusinessPartnerID,
    LinkedBusinessPartnerIDs: insured?.LinkedBusinessPartnerIDs,
  };
}

export function mapToListingData(data: any): IListingData<any> {
  const list: IAbstractRecord = {};

  data.Production.queries.getPolicyList.items.forEach(
    (obj: IGenericPoliciesData, i: number) => {
      const subline =
        obj.production_Policy_SublineID?.views?.PlanConfigManagement_all
          ?.properties;
      const line =
        obj.production_Policy_LineID?.views?.PlanConfigManagement_all
          ?.properties;
      const plan =
        obj.production_Policy_PlanID?.views?.PlanConfigManagement_all
          ?.properties;

      list[i] = {
        production_Policy_Id: obj.production_Policy_Id,
        production_Policy_PolicyNumber: obj.production_Policy_PolicyNumber,
        production_Policy_PolicyName: obj.production_Policy_PolicyName,
        line: `${line.ExternalCode} - ${line.Name}`,
        subline: `${subline.ExternalCode} - ${subline.Name}`,
        plan: `${plan.ExternalCode} - ${plan.Name}`,
        status: capitalizeFirstLetter(
          obj.production_Policy_Status?.replaceAll("_", " ")
        ),
        statusReason: obj?.production_Policy_StatusReason?.Title,
        statusReasonTitle: obj?.production_Policy_StatusReason?.Title,
        effectiveDate: obj.production_Policy_PolicyEffectiveDate,
      };
    }
  );

  return {
    pagedItems: list,
    pageSize: data.Production.queries.getPolicyList?.paging?.pageSize,
    pageNumber: data.Production.queries.getPolicyList?.paging?.pageNumber,
    totalCount: data.Production.queries.getPolicyList?.paging?.totalCount,
  };
}

export function dataToLovs(data: any): Record<string, Record<string, string>> {
  let lovs: Record<string, Record<string, string>> = {
    businessPartner: {},
  };

  data?.Production?.queries?.getAssignedBPListWithoutPagination?.forEach(
    (obj: {
      salesforceManagement_AssignedBP_Id: string;
      salesforceManagement_AssignedBP_BusinessPartnerID: {
        id: string;
        views: {
          SalesforceManagement_all: {
            properties: {
              Code: string;
              PersonID: {
                id: string;
                views: {
                  SalesforceManagement_all: {
                    properties: {
                      FullName: string;
                    };
                  };
                };
              };
            };
          };
        };
      };
    }) => {
      lovs.businessPartner[
        obj?.salesforceManagement_AssignedBP_BusinessPartnerID?.id
      ] =
        obj?.salesforceManagement_AssignedBP_BusinessPartnerID?.views?.SalesforceManagement_all?.properties?.PersonID?.views?.SalesforceManagement_all?.properties?.FullName;
    }
  );

  return lovs;
}
