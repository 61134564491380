import React, { useState, useEffect } from "react";
import clsx from "clsx";
import DynamicField from "./DynamicField";

import {
  ISimplifiedDynamicSection,
  extractAllPropertiesFromSections,
  extractValuesFromDynamicPayload,
  IEnhancedDynamicDisplay,
  IDynamicDisplayWidgetProps,
} from ".";
import WidgetPaper from "../../common/WidgetPaper";
import WidgetSection from "../../common/WidgetSection";
import { makeStyles } from "tss-react/mui";
import { useAppSelector } from "../../../redux/hooks";
import CollapsibleSection from "../../common/CollapsibleSection";

const useStyles = makeStyles<{ hasFourFields: boolean }>()(
  (theme, { hasFourFields }) => ({
    drawerContainer: {
      padding: "10px",
    },
    actionBar: {
      margin: 0,
    },
    property: {
      minWidth: 100,
      width: hasFourFields ? "25% !important" : "32% !important",
    },
    fullWidthProperty: {
      minWidth: 100,
      width: "100% !important",
    },
    drawerProp: {
      width: "100%",
    },
    propContainer: {
      padding: "23px 0 0 0",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      overflow: "visible",
    },
    actionsSection: {
      width: "100%",
      textAlign: "center",
      margin: "22px 0 0",
    },
  })
);

const DynamicWidget: React.FC<IDynamicDisplayWidgetProps> = ({
  view,
  actions,
  hasFourFields = false,
  isCollapsible = false,
  collapsibleSectionTitle = "Widget",
  onButtonClick,
}) => {
  const { classes } = useStyles({ hasFourFields });
  const tenant = useAppSelector((state) => state.tenant);
  const [ready, setReady] = useState<boolean>(false);
  const [values, setValues] = useState<Record<string, string>>({});
  // ONLY TO BE USED FOR RENDERING
  const [viewSections, setViewSections] = useState<ISimplifiedDynamicSection[]>(
    []
  );
  const [allProperties, setAllProperties] = useState<
    Record<string, IEnhancedDynamicDisplay>
  >({});

  useEffect(() => {
    const { extSections, extProperties } = extractAllPropertiesFromSections(
      view.sections
    );
    const extValues = extractValuesFromDynamicPayload(extProperties);

    setViewSections(extSections);
    setValues(extValues);
    setAllProperties(extProperties);
    setReady(true);
  }, [view.sections]);

  function renderDynamicWidget() {
    return (
      <>
        {isCollapsible && renderWidgetSections()}
        {!isCollapsible && <WidgetPaper>{renderWidgetSections()}</WidgetPaper>}
      </>
    );
  }
  function renderWidgetSections() {
    return ready ? (
      <div>
        {viewSections.map((section, index, array) => {
          return (
            !section.hidden && (
              <WidgetSection
                key={section.name}
                classes={{ container: classes.propContainer }}
                title={isCollapsible ? '' : section.title}
                useSeparator={index < array.length - 1}
                showActionButton={section.showActionButtons && !isCollapsible}
                actions={isCollapsible ? null : actions}
                middle={section.middle}
                isCollapsible={isCollapsible}
                collapsibleSectionTitle={collapsibleSectionTitle}
              >
                {section.properties.map((propertyKey) => {
                  const curProperty = allProperties[propertyKey];
                  if (curProperty) {
                    return (
                      <DynamicField
                        key={curProperty.name}
                        dateFormat={tenant.dateFormat}
                        astAccountName={""}
                        property={curProperty}
                        value={
                          values[curProperty.name] !== undefined
                            ? values[curProperty.name]
                            : ""
                        }
                        className={clsx(curProperty.fullLine ? classes.fullWidthProperty : classes.property)}
                        cdnUrl={tenant.cdnUrl}
                        tooltip={curProperty.tooltip}
                      />
                    );
                  }
                  return <span key={propertyKey} />;
                })}
              </WidgetSection>
            )
          );
        })}
      </div>
    ) : (
      <></>
    );
  }

  return isCollapsible ? (
    <CollapsibleSection title={collapsibleSectionTitle} actions={actions} onButtonClick={onButtonClick}>
      {renderDynamicWidget()}
    </CollapsibleSection>
  ) : (
    renderDynamicWidget()
  );
};

export default DynamicWidget;
