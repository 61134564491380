import { FileType, FILE_TYPES_META } from '../models/file';
import { resolveGraphqlBaseUrl } from './tenant-utils';
import { getUserToken } from './userUtils';

export function isFile(input: any) {
  if ('File' in window && input instanceof File) return true;
  else return false;
}

export function getFileExtension(fileName: string) {
  return fileName
    ? fileName.slice((Math.max(0, fileName.lastIndexOf('.')) || Infinity) + 1)
    : '';
}

export function getFileNameWithoutExtension(fileName: string) {
  return fileName.split('.').slice(0, -1).join('.');
}

export function deriveMimeTypes(fileTypes?: FileType[]): string {
  if (fileTypes) {
    const extMimeTypes = fileTypes.map(
      (type) => FILE_TYPES_META[type].mimetype,
    );
    return extMimeTypes.join(', ');
  }
  return '';
}

export function getFileNameFromContentDisposition(
  disposition?: string | null,
): string {
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return '';
}

export function generateDownloadLink(
  fileId: string,
  entityTypeId: string,
  entityId: string,
  entityViewId: string,
  propertyId: string,
): string {
  return `${resolveGraphqlBaseUrl()}/api/Files/download/entities?entityTypeId=${entityTypeId}&entityId=${entityId}&entityViewId=${entityViewId}&propertyId=${propertyId}&fileId=${fileId}`;
}

export async function downloadFileFromUrl(url: string, fileName: string) {
  try {
    // Fetch the file from the URL with JWT token authorization
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${getUserToken()}`
      }
    });
    if (!response.ok) {
      throw new Error(`Failed to fetch file from ${url}`);
    }

    // Get the file's blob
    const blob = await response.blob();

    // Create a link element
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
}
