import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { inputs } from "./content";
import { cloneDeep, isEmpty } from "lodash";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  checkCompanyExists,
  checkPersonExists,
  createPerson,
  getPersonEnums,
  getPersonInfo,
  updatePerson,
} from "./queries";
import { graphqlEntityToPersonInfo, LookupToList } from "./utils";
import { useNavigate } from "react-router-dom";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import Loader from "../../components/Loader";
import dayjs from "dayjs";
import { isValidDate } from "../../utils/formatting-utils";

const PersonDrawer: React.FC<IPersonDrawerProps> = ({
  personId,
  open,
  onSuccess,
  onClose,
}) => {
  const navigate = useNavigate();

  const [booted, setBooted] = useState<boolean>(false);

  const [personEnumResults] = useLazyQuery(getPersonEnums());

  const [checkPersonExistsLazy] = useLazyQuery(checkPersonExists(), {});
  const [checkCompanyExistsLazy] = useLazyQuery(checkCompanyExists(), {});

  const [personAction] = useMutation(
    personId ? updatePerson() : createPerson()
  );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [personInfoQuery] = useLazyQuery(getPersonInfo(), {
    variables: { id: personId },
  });

  const loadLovList = async () => {
    const result = await personEnumResults({
      fetchPolicy: "no-cache",
    });

    const lovs = LookupToList(result.data);

    return lovs;
  };

  const getEntityInfo = async () => {
    let personEntity: IPersonInfo = {
      personId: "",
      type: "",
      companyName: "",
      title: "",
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      email: "",
      phoneType: "MOBILE",
      phoneNumber: "",
    };

    if (personId) {
      const apiResult = await personInfoQuery({
        variables: { id: personId },
      });
      if (apiResult.data) {
        personEntity = graphqlEntityToPersonInfo(apiResult.data);
      }
    }

    return personEntity;
  };

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      const [entityData, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      if (lovData) {
        (updatedInputs.type as IFormSelectDynamicProps).selectOptions =
          lovData["SalesforceManagement_PersonTypes"];

        (updatedInputs.type as IFormSelectDynamicProps).selectOptions =
          lovData["SalesforceManagement_PersonTypes"];

        (updatedInputs.phoneType as IFormSelectDynamicProps).selectOptions =
          lovData["phoneType"];

        (updatedInputs.title as IFormSelectDynamicProps).selectOptions =
          lovData["titles"];
      }

      if (entityData) {
        updatedInputs.phoneNumber.value = entityData.phoneNumber;
        updatedInputs.type.value = entityData.type;
        updatedInputs.companyName.value = entityData.companyName;
        updatedInputs.title.value = entityData.title;
        updatedInputs.firstName.value = entityData.firstName;
        updatedInputs.middleName.value = entityData.middleName;
        updatedInputs.lastName.value = entityData.lastName;
        updatedInputs.dateOfBirth.value = entityData.dateOfBirth;
        updatedInputs.email.value = entityData.email;
        updatedInputs.phoneType.value = entityData.phoneType;
      }

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const validateCompanyNameExists = async (companyName: string) => {
    if (!isEmpty(companyName)) {
      const result = await checkCompanyExistsLazy({
        variables: {
          companyName: companyName,
          personID: personId,
        },
      });
      if (
        result.data.SalesforceManagement?.queries?.checkCompanyNameExists
          .length > 0
      ) {
        return "Company already exists";
      }
    }
    return "";
  };

  const validatePersonNameExistsOnChange = async (
    firstName: string,
    middleName: string,
    lastName: string,
    dateOfBirth: Date
  ) => {
    if (!isEmpty(firstName) && !isEmpty(middleName) && !isEmpty(lastName)) {
      const result = await checkPersonExistsLazy({
        variables: {
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          dateOfBirth: new Date(
            dayjs(new Date(dateOfBirth)).format("YYYY-MM-DD")
          ),
        },
      });

      if (
        result.data.SalesforceManagement?.queries?.checkPersonExists.length > 0
      ) {
        return "Person already exists";
      }
    }
    return "";
  };

  const onCustomValidate = async (
    fieldName: string,
    values: Record<string, any>
  ) => {

    const isCompany = values.type?.toLowerCase() === "company";
    const isPerson = values?.type?.toLowerCase() === "person";

    if(isCompany){
      if (fieldName === "companyName") {
        return await validateCompanyNameExists(values.companyName);
      }
    }

    if(isPerson){
      if (
        fieldName === "firstName" ||
        fieldName === "middleName" ||
        fieldName === "lastName"
      ) {
        return await validatePersonNameExistsOnChange(
          values.firstName,
          values.middleName,
          values.lastName,
          values.dateOfBirth
        );
      }
    }

    return "";
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variables = {
        dateOfBirth: data.dateOfBirth
          ? new Date(dayjs(new Date(data.dateOfBirth)).format("YYYY-MM-DD"))
          : null,
        personDetailsInputs: {
          personType: data.type,
          companyName: !isEmpty(data.companyName) ? data.companyName : null,
          title: !isEmpty(data.title) ? data.title : null,
          firstName: !isEmpty(data.firstName) ? data.firstName : null,
          middleName: !isEmpty(data.middleName) ? data.middleName : null,
          lastName: !isEmpty(data.lastName) ? data.lastName : null,
          email: !isEmpty(data.email) ? data.email : null,
          phoneType: !isEmpty(data.phoneType) ? data.phoneType : null,
          phoneNumber: !isEmpty(data.phoneNumber) ? data.phoneNumber : null,
        },
      };

      const res = await personAction({
        variables: personId ? { ...variables, entityId: personId } : variables,
        errorPolicy: "all",
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {personId
              ? "Person successfully updated"
              : "Person successfully created"}
          </ToastSuccessMessage>
        );

        const newPersonId =
          res.data.salesforceManagement.actions.createPerson.Person.Id;
        setTimeout(() => {
          setSubmitButtonState("success");
          onSuccess();
          onClose();
          setFormDisabled(false);
          if (!personId) {
            navigate(`/salesforce/persons/` + newPersonId);
          }
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        setFormDisabled(false);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (err) {
      setSubmitButtonState(undefined);
      setFormDisabled(false);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
    }
  };

  const onBlur = async (
    fieldName: string,
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {
    if (
      fieldName === "firstName" ||
      fieldName === "middleName" ||
      fieldName === "lastName"
    ) {
      if (!errors.firstName || !errors.middleName || !errors.lastName) {
        const error = await onCustomValidate(fieldName, values);
        errors.firstName = error;
        errors.middleName = error;
        errors.lastName = error;
      }
    }

    if (fieldName === "companyName") {
      if (!errors.companyName) {
        const error = await onCustomValidate(fieldName, values);
        errors.companyName = error;
      }
    }
    return { values, errors };
  };

  const onCustomValidateForm = async (
    values: Record<string, any>,
    errors: Record<string, string>
  ) => {

    const isCompany = values.type?.toLowerCase() === "company";
    const isPerson = values?.type?.toLowerCase() === "person";

    if(isPerson){
      if (!errors.firstName || !errors.middleName || !errors.lastName) {
        const error = await onCustomValidate("firstName", values);
        errors.firstName = error;
        errors.middleName = error;
        errors.lastName = error;
      }
    }

    if(isCompany){
      if (!errors.companyName) {
        const error = await onCustomValidate("companyName", values);
        errors.companyName = error;
      }
    }

    return errors;
  };

  const onChange = async (
    fieldName: string,
    value: any,
    values: Record<string, any>,
    errors: Record<string, string>, touched: Record<string, boolean>
  ) => {
    if(fieldName === "dateOfBirth") {
      const isPerson = values?.type?.toLowerCase() === "person";

      if(isPerson && (isValidDate(value) || isEmpty(value))){
        values[fieldName] = value;
        if (!isEmpty(values.firstName) && !isEmpty(values.middleName) && !isEmpty(values.lastName)) {
          const error = await onCustomValidate("firstName", values);
          errors.firstName = error;
          errors.middleName = error;
          errors.lastName = error;

          touched.firstName = true;
          touched.middleName = true;
          touched.lastName = true;
        }
      }
    }
  };

  return (
    <GenericDrawer
      title={personId ? "Edit Person" : "New Person"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            onCustomBlur={onBlur}
            onChange={onChange}
            onCustomValidate={onCustomValidateForm}
            submitButtonState={submitButtonState}
            // isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PersonDrawer;
