import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export const initialValues = {
  primaryBP: "",
  primaryPercentage: 0,
  secondaryBP: "",
  secondaryPercentage: 0,
  additionalBP: "",
  additionalPercentage: 0,
};

export const getInitialInputs = (
  data: IUpdateCommissionProposalData,
  partnerLov: Record<string, string>
): Record<string, DynamicFormInputType> => ({
  primaryBP: {
    name: "primaryBP",
    title: "Primary Business Partner",
    type: FormInputTypes.chips,
    placeholder: "Primary Business Partner",
    value: data?.primaryBP || initialValues.primaryBP || "",
    required: true,
    disabled: true,
    selectOptions: partnerLov
  },
  primaryPercentage: {
    name: "primaryPercentage",
    title: "Share",
    type: FormInputTypes.percentage,
    placeholder: "Share",
    value: (data?.primaryPercentage || initialValues.primaryPercentage || 0) * 100,
    required: true,
  },
  secondaryBP: {
    name: "secondaryBP",
    title: "Secondary Business Partner",
    type: FormInputTypes.chips,
    placeholder: "Secondary Business Partner",
    value: data?.secondaryBP || initialValues?.secondaryBP || "",
    required: !isEmpty(data?.secondaryBP),
    hidden: isEmpty(data?.secondaryBP),
    disabled: true,
    selectOptions: partnerLov
  },
  secondaryPercentage: {
    name: "secondaryPercentage",
    title: "Share",
    type: FormInputTypes.percentage,
    placeholder: "Share",
    value: (data?.secondaryPercentage || initialValues.secondaryPercentage || 0) * 100,
    required: !isEmpty(data?.secondaryBP),
    hidden: isEmpty(data?.secondaryBP),
  },
  additionalBP: {
    name: "additionalBP",
    title: "Additional Business Partner",
    type: FormInputTypes.chips,
    placeholder: "Additional Business Partner",
    value: data.additionalBP|| initialValues.additionalBP,
    required: !isEmpty(data?.additionalBP),
    hidden: isEmpty(data?.additionalBP),
    disabled: true,
    selectOptions: partnerLov
  },
  additionalPercentage: {
    name: "Additional Business Partner",
    title: "Share",
    type: FormInputTypes.percentage,
    placeholder: "Share",
    value: (data?.additionalPercentage || initialValues.additionalPercentage || 0) * 100,
    required: !isEmpty(data?.additionalBP),
    hidden: isEmpty(data?.additionalBP),
  },
});
