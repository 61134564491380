import { ITransactionInfo } from '.';
import { capitalizeFirstLetter } from '../../utils/formatting-utils';
import { lookupListAsRecordObject } from '../../utils/graph-utils';
import { IAccountCurrency, ITransaction, ITransactionFormState } from './form';

export function LookupToList(data: any) {
  const result: Record<string, Record<string, string>> = {};

  result["relatedCompanies"] = lookupListAsRecordObject(
    data?.SalesforceManagement?.lookups?.relatedCompanies
  );

  return result;
}

export function getSystemCurrency(data: any) {
  return data?.Core?.queries?.getSystemCurrencies[0]
    ?.core_SystemCurrency_BasicCurrency?.Symbol;
}

export function getSystemSecondaryCurrency(data: any) {
  return data?.Core?.queries?.getSystemCurrencies[0]
    ?.core_SystemCurrency_SecondaryCurrency?.Symbol;
}

export function getSystemSecondaryCurrencyCode(data: any) {
  return data?.Core?.queries?.getSystemCurrencies[0]
    ?.core_SystemCurrency_SecondaryCurrency?.Code;
}

export function getAccountsOfCompanyToList(data: any) {
  let lookuplistAsRecordObject: Record<string, string> = {};

  data?.Accounting?.queries?.GetAccountsOfCompany?.forEach(
    (element: { [key: string]: string }) => {
      if (element.accounting_ChartOfAccounts_Id) {
        lookuplistAsRecordObject[element.accounting_ChartOfAccounts_Id] = element.accounting_ChartOfAccounts_AccountID + " " + element.accounting_ChartOfAccounts_AccountName;
      }
    }
  );

  return lookuplistAsRecordObject;
}

export function getAccountsOfCompanyCurrenciesToList(data: any) {
  let lookuplistAsRecordObject: Record<string, { id: string; symbol: string }> =
    {};

  data?.Accounting?.queries?.GetAccountsOfCompany?.forEach(
    (element: { [key: string]: any }) => {
      if (element.accounting_ChartOfAccounts_Id) {
        lookuplistAsRecordObject[element.accounting_ChartOfAccounts_Id] = {
          id: element.accounting_ChartOfAccounts_AccountCurrency.Id,
          symbol: element.accounting_ChartOfAccounts_AccountCurrency.Symbol,
        };
      }
    }
  );

  return lookuplistAsRecordObject;
}

export const getTransactionPageFormState = (
  transaction?: ITransaction,
  account: IAccountCurrency[] = [],
  deleteAccounts: string[] = []
): ITransactionFormState => ({
  values: {
    transaction: transaction,
    account: account,
    delete: deleteAccounts
  },
  errors: {
    transaction: {
      company: "",
      transactionType: "",
      reference: "",
      date: "",
      description: "",
      note: "",
    },
    account: account.map((a) => ({
      accountName: "",
      description: "",
      accountDebit: "",
      accountCredit: "",
      systemDebit: "",
      systemCredit: "",
    })),
  },
  touched: {
    transaction: {
      company: false,
      reference: false,
      date: false,
      description: false,
      note: false,
    },
    account: account.map((_a) => ({
      accountName: false,
      description: false,
      accountDebit: false,
      accountCredit: false,
      systemDebit: false,
      systemCredit: false,
    })),
  },
});

export function graphqlEntityToTransactionInfo(
  data: any
): ITransactionInfo {
  return {
    CurrentTransactionID: data?.Accounting.entities.transactions.id,
    ReferenceNumber: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.ReferenceNumber,
    TransactionType: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TransactionType,
    TransactionDate: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TransactionDate,
    Description: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.Description,
    Note: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.Note,
    RelatedCompany: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.RelatedCompany,
    TotalSystemCredit: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TotalSystemCredit,
    TotalSystemDebit: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TotalSystemDebit,
    TransactionNumber: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TransactionNumber,
    JournalTransactions: data?.Accounting.entities.transactions.queries?.accounting?.GetJournalTransactions,
    TransactionDocuments: data?.Accounting.entities.transactions.views?.Accounting_all?.properties?.TransactionDocuments || [],
  };
}

export const enumListAsRecordObject = (
  enumList: { __typename: string; name: string }[]
): Record<string, string> => {
  let dropDownList: Record<string, string> = {};
  enumList.forEach((element: { __typename: string; name: string }) => {
    if (element.name) {
      if(element.name === "PROFITAND_LOSS") {
        element.name = "Profit & Loss"
      }
      dropDownList[element.name] = capitalizeFirstLetter(
        element.name.replaceAll("_", " ")
      );
    }
  });

  return dropDownList;
};