import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { IInformationBoxTab } from '.';
import { useAppSelector } from '../../redux/hooks';
import { ITheme } from '../../redux/tenant/types';

interface IInformationBoxHeaderProps {
  tab: IInformationBoxTab;
}
const useStyles = makeStyles<{ theme: ITheme, colCount: number }>()((_, { theme, colCount }) => ({
  container: {
    display: 'block',
    width: '100%',
  },
  row: {
    padding: '12px 0',
    borderBottom: '1px solid #DFE3EBC9',
    textAlign: 'left',
    display: 'grid',
    gridTemplateColumns: `40% repeat(${colCount}, 1fr)`,
    gridGap: '5px',
    gridAutoRows: 'auto',
  },
  // row: ({ colCount }: { colCount: number }) => ({
  //   padding: '12px 0',
  //   borderBottom: '1px solid #DFE3EBC9',
  //   textAlign: 'left',
  //   display: 'grid',
  //   gridTemplateColumns: `40% repeat(${colCount}, 1fr)`,
  //   gridGap: '5px',
  //   gridAutoRows: 'auto',
  // }),
  title: {
    color: theme.palette.primary1.main,
    display: 'inline-block',
    textAlign: 'left',
    fontSize: '11px',
    fontWeight: 'bold',
    height: '13px',
  },
  text: {
    color: theme.palette.primary1.main,
    display: 'inline-block',
    textAlign: 'right',
    fontSize: '11px',
    fontWeight: 'bold',
    lineHeight: '13px',
  },
}),
);

const InformationBoxHeader: React.FC<IInformationBoxHeaderProps> = ({
  tab,
}) => {
  const theme = useAppSelector(state => state.tenant.theme)
  const [colCount, setColCount] = useState<number>(1);
  const classes = useStyles({ theme, colCount }).classes;

  useEffect(() => {
    const value = Object.keys(tab.data).length;
    setColCount(value);
  }, [tab.data]);

  return (
    <>
      {colCount > 0 && tab.supportsGrouping && (
        <div className={classes.row}>
          <div className={classes.title}>{tab.groupingTitle}</div>
          {tab.headers &&
            tab.groupingValues.map(column => (
              <div key={column} className={classes.text}>
                {column}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default InformationBoxHeader;
