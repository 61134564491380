import { FormInputTypes } from "../../DynamicForm";
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
} from "../../components/enhanced-table";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import { IEntityInfoView } from "../../components/widgets/entity-info/EntityInfoFields";
import { IAbstractRecord } from "../../models";
import { ITabsLayout, LayoutType } from "../../page-layout";

export const entityView: IEntityInfoView = {
  name: "section1",
  title: "Business User Details",
  iconUrl: "",
  sections: [
    {
      name: "businessUserDetailsSection1",
      title: "",
      properties: {
        name: {
          value: "",
          multiline: false,
          name: "name",
          title: "Full Name",
        },
        businessUserCode: {
          value: "",
          multiline: false,
          name: "businessUserCode",
          title: "Business User Code",
        },
        dateOfBirth: {
          value: "",
          multiline: false,
          name: "dateOfBirth",
          title: "Date Of Birth",
        },
        email: {
          value: "",
          multiline: false,
          name: "email",
          title: "Email",
        },
        phoneType: {
          value: "",
          multiline: false,
          name: "phoneType",
          title: "Phone Type",
        },
        phoneNumber: {
          value: "",
          multiline: false,
          name: "phoneNumber",
          title: "Phone Number",
        },
        taxable: {
          value: "",
          multiline: false,
          name: "taxable",
          title: "Taxable",
        },
      },
    },
    {
      name: "businessUserDetailsSection2",
      title: "",
      properties: {
        createdBy: {
          value: "",
          multiline: false,
          name: "createdBy",
          title: "Created By",
        },
        createdOn: {
          value: "",
          multiline: false,
          name: "createdOn",
          title: "Created On",
        },
        modifiedBy: {
          value: "",
          multiline: false,
          name: "modifiedBy",
          title: "Modified By",
        },
        modifiedOn: {
          value: "",
          multiline: false,
          name: "modifiedOn",
          title: "Modified On",
        },
      },
    },
  ],
  socialMedia: {
    facebook: "",
    linkedIn: "",
    twitter: "",
  },
};

export const businessUserTabs: ITabsLayout = {
  typeLogicalId: LayoutType.tabslayout,
  leftColumn: {
    backgroundColor: "#FFFFFF",
    collapsable: false,
    width: 2,
    collapsed: false,
  },
  mainColumn: {
    backgroundColor: "#F7F7F7",
    collapsable: false,
    width: 10,
    collapsed: false,
  },
  rightColumn: {
    backgroundColor: "#FFFFFF",
    collapsable: false,
    width: 0,
    collapsed: false,
  },
  tabs: [
    {
      name: "summary",
      title: "Summary",
      iconUrl: "/icons/file-summary-primary.svg",
      widgets: [{}],
    },
    {
      name: "businessPartners&Plans",
      title: "Business Partners & Plans",
      iconUrl: "/icons/file-summary-primary.svg",
      widgets: [{}],
    },
  ],
};

export const businessPartnersPlansHeaders: Record<string, EnhancedTableHeader> =
  {
    businessPartner_Code: {
      name: "businessPartner_Code",
      title: "Business Partner Code",
      type: EnhancedTableHeaderType.Text,
    },
    person_FullName: {
      name: "person_FullName",
      title: "Business Partner Name",
      type: EnhancedTableHeaderType.Clickable,
      callback: () => {},
    },
    line_ExternalCode: {
      name: "line_ExternalCode",
      title: "Line Code",
      type: EnhancedTableHeaderType.Text,
    },
    line_Name: {
      name: "line_Name",
      title: "Line",
      type: EnhancedTableHeaderType.Clickable,
      callback: () => {},
    },
    plan_ExternalCode: {
      name: "plan_ExternalCode",
      title: "Plan Code",
      type: EnhancedTableHeaderType.Text,
    },
    plan_Name: {
      name: "plan_Name",
      title: "Plan",
      type: EnhancedTableHeaderType.Clickable,
      callback: () => {},
    },
    salesforceManagement_AssignedBPPlan_Status: {
      name: "salesforceManagement_AssignedBPPlan_Status",
      title: "",
      type: EnhancedTableHeaderType.Image,
      hasConditionalImage: true,
      variableOneImageKey:
        "https://ufa-cdn.azureedge.net/ufa-app-portal/images/green-correct-img.png",
      variableTwoImageKey:
        "https://ufa-cdn.azureedge.net/ufa-app-portal/images/red-clean-img.png",
    },
  };

export function filterSectionsContent(
  lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: "",
      inputs: {
        bp: {
          name: "bp",
          title: "",
          type: FormInputTypes.chips,
          value: filterValues?.bp || [],
          placeholder: "Filter By Business Partner",
          triggerUpdate: true,
          selectOptions: { ...lovs.bp },
        },
        line: {
          name: "line",
          title: "",
          type: FormInputTypes.chips,
          value: filterValues?.line || [],
          placeholder: "Filter By Line",
          triggerUpdate: true,
          selectOptions: { ...lovs.line },
        },
        plan: {
          name: "plan",
          title: "",
          type: FormInputTypes.chips,
          value: filterValues?.plan || [],
          placeholder: "Filter By Plan",
          triggerUpdate: true,
          selectOptions: { ...lovs.plan },
        },
      },
    },
  ];
  return newFilterSections;
}
