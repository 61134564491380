import { FormInputTypes } from '../../DynamicForm';
import {
  EnhancedTableHeader,
  EnhancedTableHeaderType,
  IEnhancedTableMenuItem,
} from '../../components/enhanced-table';
import { IListingFilterWidgetSection } from '../../components/widgets/custom-listing-filter';
import { IAbstractRecord } from '../../models';

export const headers: Record<string, EnhancedTableHeader> = {
    accounting_Customers_CustomerName: {
    name: 'accounting_Customers_CustomerName',
    title: 'Name',
    type: EnhancedTableHeaderType.Clickable,
    callback: () => {},
  },
  accounting_Customers_ReferenceNumber: {
    name: 'accounting_Customers_ReferenceNumber',
    title: 'Reference Number',
    type: EnhancedTableHeaderType.Text,
  },
  accounting_Customers_Website: {
    name: 'accounting_Customers_Website',
    title: 'Website',
    type: EnhancedTableHeaderType.Text,
  },
  parentAccount_AccountName: {
    name: 'parentAccount_AccountName',
    title: 'Parent Account',
    type: EnhancedTableHeaderType.Text,
  },
  accounting_Customers_BalanceDue: {
    name: 'accounting_Customers_BalanceDue',
    title: 'Balance Due',
    type: EnhancedTableHeaderType.Currency,
  },
  accounting_Customers_createdOn: {
    name: 'accounting_Customers_createdOn',
    title: 'Created on',
    type: EnhancedTableHeaderType.Date,
  }
};

export function filterSectionsContent(
    lovs: Record<string, Record<string, string>>,
  filterValues: IAbstractRecord,
): IListingFilterWidgetSection[] {
  const newFilterSections: IListingFilterWidgetSection[] = [
    {
      title: 'Filters',
      inputs: {
        company: {
            name: 'company',
            title: 'Company',
            type: FormInputTypes.chips,
            value: filterValues?.company || null,
            placeholder: 'Company',
            triggerUpdate: true,
            selectOptions: { ...lovs.company },
            multiple: false,
            canClearSingleValueSelection: false
        },
        createdOn: {
            name: 'createdOn',
            title: 'Date',
            type: FormInputTypes.daterange,
            value: filterValues.createdOn || [],
            placeholder: 'Created On',
            triggerUpdate: true,
          }
      },
    },
  ];
  return newFilterSections;
}

export const actions: IEnhancedTableMenuItem[] = [
  {
    title: '+New Customer',
    onClick: () => {},
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  },
  {
    title: 'Import Customers',
    onClick: () => {},
    isEntity: false,
    isBulk: false,
    iconUrl: '',
  }
];
