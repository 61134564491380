import { isArray } from "lodash";
import { FormInputTypes, IDynamicSection } from "../../DynamicForm";
import { isEmpty } from "../../utils/validationUtils";

export interface IProposalDrawerValues {
  primaryBP: string;
  primaryPercentage: string;
  secondaryBP: string;
  secondaryPercentage: string;
  additionalBP: string;
  additionalPercentage: string;
  line: string;
  subline: string;
  plan: string;
  policyCurrency: string;
  addSecondaryBP: boolean;
  addAdditionalBP: boolean;
}

export const proposalDrawerInitialValues: IProposalDrawerValues = {
  primaryBP: "",
  primaryPercentage: "",
  secondaryBP: "",
  secondaryPercentage: "",
  additionalBP: "",
  additionalPercentage: "",
  line: "",
  subline: "",
  plan: "",
  policyCurrency: "",
  addSecondaryBP: false,
  addAdditionalBP: false,
};

export const getSections = (
  data: IProposalDrawerValues = proposalDrawerInitialValues,
  lists: Record<string, Record<string, string>>
): Record<string, IDynamicSection> => ({
  section1: {
    title: "Business Partner(s)",
    inputs: {
      primaryBP: {
        name: "primaryBP",
        title: "Primary Business Partner",
        type: FormInputTypes.chips,
        placeholder: "Type to search for Business Partners",
        value: data.primaryBP,
        multiple: false,
        disabled: false,
        required: true,
        hasPagination: true,
        selectOptions: lists?.primaryBP,
        itemsPerPage: 15,
      },
      primaryPercentage: {
        name: "primaryPercentage",
        title: "Share",
        type: FormInputTypes.percentage,
        placeholder: "Share",
        value: data.primaryPercentage,
        disabled: false,
        required: true,
      },
      addSecondaryBP: {
        name: "addSecondaryBP",
        title: "+ Add Secondary Business Partner",
        type: FormInputTypes.link,
        hidden: data.addSecondaryBP,
      },
      secondaryBP: {
        name: "secondaryBP",
        title: "Secondary Business Partner",
        type: FormInputTypes.chips,
        placeholder: "Type to search for Business Partners",
        value: !isEmpty(data.secondaryBP) ? data.secondaryBP : "",
        multiple: false,
        disabled: false,
        required: isArray(data.secondaryBP)
          ? !isEmpty(data.secondaryBP?.[0])
          : !isEmpty(data.secondaryBP),
        selectOptions: lists?.secondaryBP,
        hasPagination: true,
        itemsPerPage: 15,
        order: 1,
        hidden: !data.addSecondaryBP,
      },
      secondaryPercentage: {
        name: "secondaryPercentage",
        title: "Share",
        type: FormInputTypes.percentage,
        placeholder: "Share",
        value: data.secondaryPercentage,
        disabled: false,
        required: isArray(data.secondaryBP)
          ? !isEmpty(data.secondaryBP?.[0])
          : !isEmpty(data.secondaryBP),
        hidden: !data.addSecondaryBP,
      },
      addAdditionalBP: {
        name: "addAdditionalBP",
        title: "+ Add Additional Business Partner",
        type: FormInputTypes.link,
        hidden: !(data.addSecondaryBP && !data.addAdditionalBP),
      },
      additionalBP: {
        name: "additionalBP",
        title: "Additional Business Partner",
        type: FormInputTypes.chips,
        placeholder: "Type to search for Business Partners",
        value: !isEmpty(data.additionalBP) ? data.additionalBP : "",
        multiple: false,
        selectOptions: lists?.additionalBP,
        disabled: false,
        hasPagination: true,
        itemsPerPage: 15,
        order: 1,
        required: isArray(data.additionalBP)
          ? !isEmpty(data.additionalBP?.[0])
          : !isEmpty(data.additionalBP),
        hidden: !data.addAdditionalBP,
      },
      additionalPercentage: {
        name: "additionalPercentage",
        title: "Share",
        type: FormInputTypes.percentage,
        placeholder: "Share",
        value: data.additionalPercentage,
        disabled: false,
        required: isArray(data.additionalBP)
          ? !isEmpty(data.additionalBP?.[0])
          : !isEmpty(data.additionalBP),
        hidden: !data.addAdditionalBP,
      },
    },
  },
  section2: {
    title: "Plan",
    inputs: {
      line: {
        name: "line",
        title: "Line",
        type: FormInputTypes.chips,
        placeholder: "Line",
        value: data.line || "",
        selectOptions: lists.line,
        multiple: false,
        disabled: false,
        required: true,
      },
      subline: {
        name: "subline",
        title: "Subline",
        type: FormInputTypes.chips,
        placeholder: "Subline",
        value: !isEmpty(data.subline) ? data.subline : "",
        multiple: false,
        disabled: false,
        required: true,
        selectOptions: lists.subline,
        hidden: isEmpty(data.line),
      },
      plan: {
        name: "plan",
        title: "Plan",
        type: FormInputTypes.chips,
        placeholder: "Plan",
        value: !isEmpty(data.plan) ? data.plan : "",
        multiple: false,
        disabled: false,
        required: true,
        selectOptions: lists.plan,
        hidden: isEmpty(data.subline),
      },
      policyCurrency: {
        name: "policyCurrency",
        title: "Policy Currency",
        type: FormInputTypes.chips,
        placeholder: "Policy Currency",
        value: data.policyCurrency || "",
        multiple: false,
        selectOptions: lists.policyCurrency,
        disabled: false,
        required: true,
      },
    },
  },
});
