import { isEmpty, isValidNumber } from "../../utils/validationUtils";
import {
  IProposalCostCharges,
  IProposalCover,
  IProposalPageFormState,
} from "./custom-widgets/form";
import { IProposalDetailsSummary } from "./custom-widgets/index2";

export const validateCostWidgetField = (
  fieldName: keyof IProposalCostCharges,
  value: any,
  cover: IProposalCostCharges,
  lineId?: string
): string => {
  if (fieldName === "policyCost") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "tpaFeesType" && lineId !== "43") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "chargesPercentage") {
    if (isEmpty(value)) {
      return "Required";
    } else {
      if (!isValidNumber(value)) {
        return "Invalid Number";
      }

      const numberValue = Number(value);

      if (numberValue < 0 || numberValue > 100) {
        return "Value should be between 0 and 100";
      }
    }
  }

  if (fieldName === "tpaFeesPercentage") {
    if (cover.tpaFeesType === "PERCENTAGE") {
      if (isEmpty(value)) {
        return "Required";
      } else {
        if (!isValidNumber(value)) {
          return "Invalid Number";
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return "Value should be between 0 and 100";
        }
      }
    }
  }

  if (fieldName === "tpaFeesAmount") {
    if (cover.tpaFeesType === "AMOUNT") {
      if (isEmpty(value)) {
        return "Required";
      } else {
        if (!isValidNumber(value)) {
          return "Invalid Number";
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return "Value should be greater than 0";
        }
      }
    }
  }

  return "";
};

export const validateMotorDetailsField = (
  fieldName: keyof IProposalPageFormState["values"]["motorDetails"],
  value: any,
  motorDetails: IProposalPageFormState["values"]["motorDetails"]
): string => {
  if (fieldName === "nameOnLicenseEn" || fieldName === "nameOnLicenseAr") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "vignetteCode" || fieldName === "vignette") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "motor") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "plateNumber") {
    if (isEmpty(value)) {
      return "Required";
    } else if (value.length < 2 || value.length > 300) {
      return "Value must have between 2 and 300 characters";
    }
  }

  if (fieldName === "yearOfMake") {
    if (isEmpty(value)) {
      return "Required";
    } else if (Number(value) < 1900 || Number(value) > 2100) {
      return "Invalid Year of Make";
    }
  }

  if (fieldName === "weight") {
    if (
      ["VAN", "PICKUP", "TANKER", "F_TANKER"].includes(motorDetails.bodyType)
    ) {
      if (isEmpty(value)) {
        return "Required";
      } else if (
        !isValidNumber(value) ||
        value.length < 1 ||
        value.length > 2
      ) {
        return "Invalid Weight";
      }
    }
  }

  if (fieldName === "engineSize") {
    if (["MOTORCYCLE"].includes(motorDetails.bodyType)) {
      if (isEmpty(value)) {
        return "Required";
      } else if (
        !isValidNumber(value) ||
        value.length < 1 ||
        value.length > 2
      ) {
        return "Invalid Engine Size";
      }
    }
  }

  if (fieldName === "seats") {
    if (isEmpty(value)) {
      return "Required";
    } else if (!isValidNumber(value) || value.length < 1 || value.length > 2) {
      return "Invalid Seats";
    }
  }

  if (fieldName === "chassis") {
    if (isEmpty(value)) {
      return "Required";
    } else if (value.length < 2 || value.length > 50) {
      return "Value must have between 2 and 50 characters";
    }
  }

  if (fieldName === "agencyRepairYear") {
    if (isEmpty(value)) {
      return "Required";
    } else if (!isValidNumber(value) || value < 1 || value > 10) {
      return "Value must be between 0 and 10 ";
    }
  }
  if (fieldName === "depreciationYears") {
    if (isEmpty(value)) {
      return "Required";
    } else if (!isValidNumber(value) || value < 1 || value > 10) {
      return "Value must be between 0 and 10 ";
    }
  }

  if (
    fieldName === "usageType" ||
    fieldName === "bodyType" ||
    fieldName === "brand" ||
    fieldName === "model" ||
    fieldName === "chassis" ||
    fieldName === "engineType"
  ) {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (
    fieldName === "yearOfMake" ||
    fieldName === "seats" ||
    fieldName === "horsePower" ||
    fieldName === "carValue" ||
    fieldName === "agencyRepairYear"
  ) {
    if (isEmpty(value)) {
      return "Required";
    } else if (!isValidNumber(value)) {
      return "Invalid Number";
    } else {
      const numberValue = Number(value);
      if (numberValue < 0) {
        return "Value should be greater than 0";
      }
    }
  }

  return "";
};

export const validateCoverRepeaterField = (
  fieldName: keyof IProposalCover,
  value: any,
  cover: IProposalCover
): string => {
  if (fieldName === "policyCover") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "coverPremiumType") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "coverPremiumPercentage") {
    if (cover.coverPremiumType === "PERCENTAGE") {
      if (isEmpty(value)) {
        return "Required";
      } else {
        if (!isValidNumber(value)) {
          return "Invalid Number";
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return "Value should be between 0 and 100";
        }
      }
    }
  }

  if (fieldName === "coverPremiumValue") {
    if (cover.coverPremiumType === "VALUE") {
      if (isEmpty(value)) {
        return "Required";
      } else {
        if (!isValidNumber(value)) {
          return "Invalid Number";
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return "Value should be greater than 0";
        }
      }
    }
  }

  if (fieldName === "coverSumInsured") {
    if (isEmpty(value)) {
      return "Required";
    }

    if (!isValidNumber(value)) {
      return "Invalid Number";
    }

    const numberValue = Number(value);

    if (numberValue < 0) {
      return "Value should be greater than 0";
    }
  }

  if (fieldName === "excessOnClaimType") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "excessOnClaimPercentage") {
    if (cover.excessOnClaimType === "PERCENTAGE") {
      if (isEmpty(value)) {
        return "Required";
      } else {
        if (!isValidNumber(value)) {
          return "Invalid Number";
        }

        const numberValue = Number(value);

        if (numberValue < 0 || numberValue > 100) {
          return "Value should be between 0 and 100";
        }
      }
    }
  }

  if (fieldName === "excessOnClaimAmount") {
    if (cover.excessOnClaimType === "VALUE") {
      if (isEmpty(value)) {
        return "Required";
      } else {
        if (!isValidNumber(value)) {
          return "Invalid Number";
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return "Value should be greater than 0";
        }
      }
    }
  }

  if (fieldName === "excessOnClaimDays") {
    if (cover.excessOnClaimType === "DAYS") {
      if (isEmpty(value)) {
        return "Required";
      } else {
        if (!isValidNumber(value)) {
          return "Invalid Number";
        }

        const numberValue = Number(value);

        if (numberValue < 0) {
          return "Value should be greater than 0";
        }
      }
    }
  }

  return "";
};

export const validatePolicyPayerField = (
  fieldName: string,
  value: any
): string => {
  // if (isEmpty(value)) {
  //   return "Required";
  // }

  // if (fieldName === "payerId") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  // if (fieldName === "payerAddress") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  return "";
};

export const validatePolicyOwnerField = (
  fieldName: string,
  value: any
): string => {
  // if (isEmpty(value)) {
  //   return "Required";
  // }

  // if (fieldName === "ownerId") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  // if (fieldName === "ownerAddress") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  return "";
};

export const validatePolicyInsuredField = (
  fieldName: string,
  value: any
): string => {
  if (isEmpty(value)) {
    return "Required";
  }

  if (fieldName === "id") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "order") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  if (fieldName === "address") {
    if (isEmpty(value)) {
      return "Required";
    }
  }

  return "";
};

export const validatePolicyBeneficiaryField = (
  fieldName: string,
  value: any
): string => {
  // if (isEmpty(value)) {
  //   return "Required";
  // }

  // if (fieldName === "id") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  // if (fieldName === "order") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  // if (fieldName === "address") {
  //   if (isEmpty(value)) {
  //     return "Required";
  //   }
  // }

  return "";
};

export const validateProposalPageForm = (
  data: IProposalDetailsSummary,
  values: IProposalPageFormState["values"]
) => {
  const lineExternalCode: string = data?.LineId?.ExternalCode?.toString();
  const { policyPersons, covers, costs, motorDetails } = values;

  const errors: IProposalPageFormState["errors"] = {
    policyPersons: {
      payer: {
        payerId: validatePolicyPayerField(
          "payerId",
          policyPersons.payer.payerId
        ),
        payerAddress: validatePolicyPayerField(
          "payerAddress",
          policyPersons.payer.payerAddress
        ),
        ownerIsDifferent: validatePolicyPayerField(
          "ownerIsDifferent",
          policyPersons.payer.ownerIsDifferent
        ),
      },
      owner: {
        ownerId: policyPersons.payer.ownerIsDifferent
          ? validatePolicyOwnerField("ownerId", policyPersons.owner.ownerId)
          : "",
        ownerAddress: policyPersons.payer.ownerIsDifferent
          ? validatePolicyOwnerField(
              "ownerAddress",
              policyPersons.owner.ownerAddress
            )
          : "",
      },
      insured: policyPersons.insured.map((insured) => ({
        id: validatePolicyInsuredField("id", insured.id),
        order: validatePolicyInsuredField("order", insured.order),
        address: validatePolicyInsuredField("address", insured.address),
      })),
      beneficiaries: policyPersons.beneficiaries.map((ben) => ({
        id: validatePolicyBeneficiaryField("id", ben.id),
        percentage: validatePolicyBeneficiaryField(
          "percentage",
          ben.percentage
        ),
        address: validatePolicyBeneficiaryField("address", ben.address),
      })),
    },
    covers: covers.map((c) => ({
      coverPremiumType: validateCoverRepeaterField(
        "coverPremiumType",
        c.coverPremiumType,
        c
      ),
      coverPremiumPercentage: validateCoverRepeaterField(
        "coverPremiumPercentage",
        c.coverPremiumPercentage,
        c
      ),
      coverPremiumValue: validateCoverRepeaterField(
        "coverPremiumValue",
        c.coverPremiumValue,
        c
      ),
      coverSumInsured: validateCoverRepeaterField(
        "coverSumInsured",
        c.coverSumInsured,
        c
      ),
      sumInsuredIsAdditive: validateCoverRepeaterField(
        "sumInsuredIsAdditive",
        c.sumInsuredIsAdditive,
        c
      ),
      excessOnClaimType: validateCoverRepeaterField(
        "excessOnClaimType",
        c.excessOnClaimType,
        c
      ),
      excessOnClaimAmount: validateCoverRepeaterField(
        "excessOnClaimAmount",
        c.excessOnClaimAmount,
        c
      ),
      excessOnClaimPercentage: validateCoverRepeaterField(
        "excessOnClaimPercentage",
        c.excessOnClaimPercentage,
        c
      ),
      excessOnClaimDays: validateCoverRepeaterField(
        "excessOnClaimDays",
        c.excessOnClaimDays,
        c
      ),
      policyCover: validateCoverRepeaterField(
        "policyCover",
        c.excessOnClaimDays,
        c
      ),
    })),
    costs: {
      policyCost: validateCostWidgetField(
        "policyCost",
        costs.policyCost,
        costs
      ),
      chargesPercentage: validateCostWidgetField(
        "chargesPercentage",
        costs.chargesPercentage,
        costs
      ),
      tpaFeesType: validateCostWidgetField(
        "tpaFeesType",
        costs.tpaFeesType,
        costs
      ),
      tpaFeesAmount: validateCostWidgetField(
        "tpaFeesAmount",
        costs.tpaFeesAmount,
        costs
      ),
      tpaFeesPercentage: validateCostWidgetField(
        "tpaFeesPercentage",
        costs.tpaFeesPercentage,
        costs
      ),
      tpaFeesApplicableOn: validateCostWidgetField(
        "tpaFeesApplicableOn",
        costs.tpaFeesApplicableOn,
        costs
      ),
    },
    motorDetails: {
      nameOnLicenseEn: validateMotorDetailsField(
        "nameOnLicenseEn",
        motorDetails.nameOnLicenseEn,
        motorDetails
      ),
      nameOnLicenseAr: validateMotorDetailsField(
        "nameOnLicenseAr",
        motorDetails.nameOnLicenseAr,
        motorDetails
      ),
      vignetteCode:
        lineExternalCode === "50"
          ? validateMotorDetailsField(
              "vignetteCode",
              motorDetails.vignetteCode,
              motorDetails
            )
          : "",
      vignette:
        lineExternalCode === "50"
          ? validateMotorDetailsField(
              "vignette",
              motorDetails.vignette,
              motorDetails
            )
          : "",
      // plateCode: validateMotorDetailsField(
      //   "plateCode",
      //   motorDetails.plateCode,
      //   motorDetails
      // ),
      // plateNumber: validateMotorDetailsField(
      //   "plateNumber",
      //   motorDetails.plateNumber,
      //   motorDetails
      // ),
      usageType: validateMotorDetailsField(
        "usageType",
        motorDetails.usageType,
        motorDetails
      ),
      bodyType: validateMotorDetailsField(
        "bodyType",
        motorDetails.bodyType,
        motorDetails
      ),
      engineType: validateMotorDetailsField(
        "engineType",
        motorDetails.engineType,
        motorDetails
      ),
      brand: validateMotorDetailsField(
        "brand",
        motorDetails.brand,
        motorDetails
      ),
      model: validateMotorDetailsField(
        "model",
        motorDetails.model,
        motorDetails
      ),
      yearOfMake: validateMotorDetailsField(
        "yearOfMake",
        motorDetails.yearOfMake,
        motorDetails
      ),
      motor: validateMotorDetailsField(
        "motor",
        motorDetails.motor,
        motorDetails
      ),
      weight: validateMotorDetailsField(
        "weight",
        motorDetails.weight,
        motorDetails
      ),
      engineSize: validateMotorDetailsField(
        "engineSize",
        motorDetails.engineSize,
        motorDetails
      ),
      seats: validateMotorDetailsField(
        "seats",
        motorDetails.seats,
        motorDetails
      ),
      chassis: validateMotorDetailsField(
        "chassis",
        motorDetails.chassis,
        motorDetails
      ),
      horsePower: validateMotorDetailsField(
        "horsePower",
        motorDetails.horsePower,
        motorDetails
      ),
      carValue:
        lineExternalCode === "51"
          ? validateMotorDetailsField(
              "carValue",
              motorDetails.carValue,
              motorDetails
            )
          : "",
      depreciationYears:
        lineExternalCode === "51"
          ? validateMotorDetailsField(
              "depreciationYears",
              motorDetails.depreciationYears,
              motorDetails
            )
          : "",
    },
  };

  const touched: IProposalPageFormState["touched"] = {
    policyPersons: {
      payer: {
        payerId: true,
        payerAddress: true,
        ownerIsDifferent: true,
      },
      owner: {
        ownerId: true,
        ownerAddress: true,
      },
      insured: policyPersons.insured.map((insured) => ({
        id: true,
        order: true,
        address: true,
      })),
      beneficiaries: policyPersons.insured.map((ben) => ({
        id: true,
        order: true,
        address: true,
      })),
    },
    covers: covers.map((c) => ({
      isMain: true,
      isMandatory: true,
      coverPremiumType: true,
      coverPremiumPercentage: true,
      coverPremiumValue: true,
      currency: true,
      coverSumInsured: true,
      sumInsuredIsAdditive: true,
      excessOnClaimType: true,
      excessOnClaimAmount: true,
      excessOnClaimPercentage: true,
      excessOnClaimDays: true,
      policyCover: true,
    })),
    costs: {
      policyCost: true,
      chargesPercentage: true,
      tpaFeesType: true,
      tpaFeesAmount: true,
      tpaFeesPercentage: true,
      tpaFeesApplicableOn: true,
    },
    motorDetails: {
      nameOnLicenseEn: true,
      nameOnLicenseAr: true,
      vignetteCode: true,
      vignette: true,
      plateCode: true,
      plateNumber: true,
      usageType: true,
      bodyType: true,
      engineType: true,
      brand: true,
      model: true,
      yearOfMake: true,
      motor: true,
      weight: true,
      engineSize: true,
      seats: true,
      chassis: true,
      horsePower: true,
      carValue: true,
      agencyRepairYear: true,
    },
  };

  const isValid =
    Object.values(errors.costs).every((e) => isEmpty(e)) &&
    Object.values(errors.covers).every((c) =>
      Object.values(c).every((cv) => isEmpty(cv))
    ) &&
    Object.values(errors.policyPersons.payer).every((e) => isEmpty(e)) &&
    Object.values(errors.policyPersons.owner).every((e) => isEmpty(e)) &&
    Object.values(errors.policyPersons.insured).every((c) =>
      Object.values(c).every((cv) => isEmpty(cv))
    ) &&
    Object.values(errors.policyPersons.beneficiaries).every((c) =>
      Object.values(c).every((cv) => isEmpty(cv))
    ) &&
    Object.values(errors.motorDetails).every((e) => isEmpty(e));

  return { errors, touched, isValid };
};
