import React, { useState } from 'react';
import { IEnhancedCommonProps } from '../..';
import { makeStyles } from 'tss-react/mui';
import { MAIN_ONE_THEME } from '../../../constants';
import Tooltip from '@mui/material/Tooltip';
import { isValidDate } from '../../../utils/date-utils';
import { formatDate } from '../../../utils/formatting-utils';
import { isValidNumber } from '../../../utils/validationUtils';
import WidgetPaper from '../../common/WidgetPaper';
import WidgetSection from '../../common/WidgetSection';
import { FileUploadStatus, IFileCardProps } from '../../../models/file';
import Loader from '../../Loader';
import clsx from 'clsx';
import { generateDownloadLink } from '../../../utils/file-utils';
import DataService from '../../../services/dataService';
import EnhancedButton from '../../EnhancedButton';
import AddDocumentDrawer from '../../../forms/add-document-drawer/AddDocumentDrawer';

export interface IDocumentWidget extends IEnhancedCommonProps {
  cardIcon?: string;
  title: string;
  descriptionIcon?: string;
  cdnUrl: string;
  files: IFileCardProps[];
  disabledAddDocumentAction?: boolean;
  actions?: JSX.Element;
  userCanUploadAdditionalDocument?: boolean;
  policyEntityId?: string;
  ondocumentUploaded?: () => void;
}
const useStyles = makeStyles<{
  cardIcon: string;
  descriptionIcon: string;
  cdnUrl: string;
}>()((_, { cardIcon, descriptionIcon, cdnUrl }) => ({
  bodyContainer: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  fileBox: {
    margin: '5px',
    maxWidth: '350px',
    width: 'calc(55% - 10px)',
    backgroundColor: '#FFF',
    padding: '15px',
    boxShadow: '0px 5px 5px 0px #E5E5E5',
    borderRadius: '5px',
  },
  leftSection: {
    alignSelf: 'flex-start',
    boxSizing: 'border-box',
    display: 'inline-block',
    margin: '0 0 0 20px',
    position: 'relative',
    width: 'calc(75% - 40px)',
  },
  rightSection: {
    display: 'inline-block',
    float: 'right',
    verticalAlign: 'middle',
  },
  normalTitle: {
    display: 'block',
    color: MAIN_ONE_THEME.palette.primary2.main,
    fontSize: '13px',
    lineHeight: '17px',
    fontFamily: 'HelveticaNeue-Medium',
  },
  title: {
    display: 'block',
    color: MAIN_ONE_THEME.palette.primary2.main,
    fontSize: '13px',
    lineHeight: '17px',
    fontFamily: 'HelveticaNeue-Medium',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  cardIcon: {
    position: 'absolute',
    height: '15px',
    width: '15px',
    backgroundImage: cardIcon ? `url(${cdnUrl}${cardIcon})` : undefined,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    left: '-18px',
    top: '1px',
    margin: '0 0 3px',
  },
  subTitle: {
    display: 'block',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: 'HelveticaNeue-Medium',
    color: MAIN_ONE_THEME.palette.secondary2.main,
    margin: '0 0 2px',
  },
  icon: {
    display: 'inline-block',
    height: '35px',
    width: '60px',
    backgroundPosition: 'right',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: 0,
  },
  titleWrapper: {
    display: 'flex',
    margin: '0 0 2px',
  },
  descriptionIcon: {
    minWidth: '15px',
    minHeight: '15px',
    backgroundImage: `url(${descriptionIcon})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    margin: '0 0 0 5px',
    cursor: 'text',
    alignSelf: 'center',
  },
  descriptionTooltip: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '15px',
    maxWidth: '250px',
    fontSize: '11px',
  },
  footer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '20px',
    width: '100%',
  },
  statusContainer: {
    display: 'inline-block',
  },
  status: {
    fontSize: '11px',
    lineHeight: '12px',
    margin: '0 0 0 4px',
  },
  statusReviewed: {
    color: '#78b33a',
  },
  statusRejected: {
    color: '#D32F33',
  },
  statusUploaded: {
    color: MAIN_ONE_THEME.palette.primary2.main,
  },
  statusIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    height: '15px',
    width: '15px',
    margin: 0,
    backgroundSize: '90%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  actionsContainer: {
    display: 'inline-block',
    float: 'right',
  },
  actionLink: {
    fontSize: '11px',
    lineHeight: '12px',
    margin: '0 0 0 5px',
    padding: 0,
    display: 'inline-block',
    border: 'none',
    color: MAIN_ONE_THEME.palette.primary1.main,
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  uploadingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  progress: {
    color: MAIN_ONE_THEME.palette.primary2.main,
    fontSize: '10px',
    lineHeight: '11px',
  },
  loader: {
    display: 'block',
    width: '25px',
    height: '20px',
  },
}));

const DocumentWidget: React.FC<IDocumentWidget> = ({
  cardIcon = '/icons/star-secondary.svg',
  title,
  descriptionIcon,
  cdnUrl,
  files,
  disabledAddDocumentAction = false,
  userCanUploadAdditionalDocument = false,
  policyEntityId,
  ondocumentUploaded,
  actions = <></>
}) => {
  const { classes } = useStyles({ cardIcon, descriptionIcon, cdnUrl });
  const [addDocumentDrawerOpen, setAddDocumentDrawerOpen] = useState<boolean>(false);

  function handleAddDocumentAction() {
    setAddDocumentDrawerOpen(true);
  }

  function getIconUrlBasedOnType(file: IFileCardProps) {
    let path = '';
    if (file?.fileInfo?.fileName) {
      const fileExtension = file.fileInfo.fileName.split('.').pop()?.toLowerCase();
      switch (fileExtension) {
        case 'pdf':
          path = '/icons/pdf-icon.svg';
          break;
        case 'docx':
          path = '/icons/doc-icon.png';
          break;
        case 'jpeg':
          path = '/icons/jpg-icon.png';
          break;
        case 'jpg':
          path = '/icons/jpg-icon.png';
          break;
        case 'png':
          path = '/icons/png-icon.png';
          break;
      }
    }
    return path;
  }


  const renderIcon = (cardIconDescription: string) => {
    if (cardIcon) {
      if (cardIconDescription) {
        return (
          <Tooltip title={cardIconDescription}>
            <figure className={classes.cardIcon} />
          </Tooltip>
        );
      }
      return <figure className={classes.cardIcon} />;
    }
    return <></>;
  };

  const renderStatus = (fileElement: IFileCardProps) => {
    if (fileElement.status === FileUploadStatus.Uploading) {
      return (
        <div className={classes.uploadingContainer}>
          <span className={classes.progress}>
            {`Uploading ${fileElement.progress !== undefined
              ? `${fileElement.progress.toFixed(2)}%`
              : ''
              }`}
          </span>
          <Loader className={classes.loader} />
        </div>
      );
    }

    switch (fileElement.status) {
      case FileUploadStatus.Accepted:
        return (
          <>
            <figure
              className={clsx(classes.statusIcon, classes.statusReviewed)}
              style={{
                backgroundImage: `url(${cdnUrl}/icons/accepted-icon.svg)`,
              }}
            />
            <span className={clsx(classes.status, classes.statusReviewed)}>
              Accepted
            </span>
          </>
        );
      case FileUploadStatus.Uploaded:
        return (
          <>
            <figure
              className={clsx(classes.statusIcon, classes.statusUploaded)}
              style={{
                backgroundImage: `url(${cdnUrl}/icons/uploaded-icon.svg)`,
              }}
            />
            <span className={clsx(classes.status, classes.statusUploaded)}>
              Uploaded
            </span>
          </>
        );
      case FileUploadStatus.Missing:
        return (
          <>
            <span className={clsx(classes.status, classes.statusUploaded)}>
              Missing
            </span>
          </>
        );

      case FileUploadStatus.Rejected:
        return (
          <>
            <figure
              className={clsx(classes.statusIcon, classes.statusRejected)}
              style={{
                backgroundImage: `url(${cdnUrl}/icons/rejected-icon.svg)`,
              }}
            />
            <span className={clsx(classes.status, classes.statusRejected)}>
              Rejected
            </span>
          </>
        );
      default:
        return <></>;
    }
  };

  const renderActions = (fileElement: IFileCardProps) => {
    const output: JSX.Element[] = [];

    const downloadLink = generateDownloadLink(
      fileElement.fileInfo.id,
      fileElement.entityTypeId,
      fileElement.entityId,
      fileElement.entityViewId,
      fileElement.propertyId,
    );
    if (!fileElement?.hideDownloadAction && downloadLink) {
      output.push(
        <button
          key={'download'}
          type="button"
          className={classes.actionLink}
          onClick={async () => {
            await DataService.downloadAndOpenFileWithToken(downloadLink);
          }}
        >
          Download
        </button>,
      );
    }

    return output;
  };

  const renderFiles = (files: IFileCardProps[]) => {
    return files.map((fileElement) => (
      <div className={classes.fileBox}>
        <div className={classes.leftSection}>
          {renderIcon(fileElement.cardIconDescription)}
          <div className={classes.titleWrapper}>
            <Tooltip title={fileElement.title}>
              <span
                className={
                  fileElement.wrapDocumentsTitle
                    ? classes.normalTitle
                    : classes.title
                }
              >
                {fileElement.title.split('.')[0]}
              </span>
            </Tooltip>
            {fileElement.description && descriptionIcon && (
              <Tooltip
                classes={{
                  tooltip: classes.descriptionTooltip,
                }}
                title={fileElement.description}
              >
                <figure className={classes.descriptionIcon} />
              </Tooltip>
            )}
          </div>
          <span className={classes.subTitle}>
            {fileElement.createdOn && (
              <>
                {`Created On:  ${fileElement.createdOn && isValidDate(fileElement.createdOn)
                  ? formatDate(fileElement.createdOn)
                  : '-'
                  }`}
                {isValidNumber(fileElement?.version) && (
                  <span> - v.{fileElement?.version}</span>
                )}
              </>
            )}
          </span>
          {!fileElement.hideReviewedLabel && (
            <span className={classes.subTitle}>
              {`Reviewed:  ${fileElement.revisedOn && isValidDate(fileElement.revisedOn)
                ? formatDate(fileElement.revisedOn)
                : '-'
                }`}
            </span>
          )}
        </div>
        <div className={classes.rightSection}>
          <figure
            className={classes.icon}
            style={{
              backgroundImage: fileElement.iconUrl ? `url(${cdnUrl}${fileElement.iconUrl})` : `url(${cdnUrl}${getIconUrlBasedOnType(fileElement)})`,
            }}
          />
        </div>
        <div className={classes.footer}>
          <div className={classes.statusContainer}>
            {renderStatus(fileElement)}
          </div>
          <div className={classes.actionsContainer}>
            {renderActions(fileElement)}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <><WidgetPaper>
      <WidgetSection title={title}
        actions={
          <>
            {userCanUploadAdditionalDocument && (
              <EnhancedButton
                type='button'
                backgroundColor={'#FFF'}
                color={MAIN_ONE_THEME.palette.primary4.main}
                style={{ display: 'inline' }}
                onClick={() => handleAddDocumentAction()}
                disabled={disabledAddDocumentAction}
              >
                Add Document
              </EnhancedButton>
            )}
            {actions}
          </>} >

        <div className={classes.bodyContainer}>{renderFiles(files)}</div>
      </WidgetSection>
    </WidgetPaper><div>
        {addDocumentDrawerOpen && (
          <AddDocumentDrawer
            open={addDocumentDrawerOpen}
            onClose={() => setAddDocumentDrawerOpen(false)}
            onSuccess={ondocumentUploaded}
            id={policyEntityId} />
        )}
      </div></>
  );
};

export default DocumentWidget;
