import React from 'react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { Tooltip } from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
  isPossiblePhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';
import { IEnhancedCommonProps } from '..';
import CopyIconButton from '../CopyIconButton';
import {
  addZeroesAndSeparatevalue,
  changeValueToPercentage,
  formatDate,
  formatDateTime,
  getDateWithoutTime,
  maskValueWithPatternIfMatch,
  normaliseUrl,
  valueCommaSeparated,
} from '../../utils/formatting-utils';
import {
  EMPTY_FIELD_PLACEHOLDER,
  MAIN_ONE_THEME,
  contentFontFamilyRegular,
} from '../../constants';
import { TextMode } from '../widgets/dynamic';
import { isZeroValue } from '../../utils/validationUtils';

export interface IEnhancedDisplayProps extends IEnhancedCommonProps {
  title: string;
  iconUrl?: string;
  value?: string | JSX.Element;
  textFormatting?: EnhancedDisplayTextFormatting[];
  type?: EnhancedDisplayType;
  multiline: boolean;
  url?: string;
  displayInline?: boolean;
  toolTip?: boolean;
  aircall?: boolean;
  currencySymbol?: string;
  dateFormat?: string;
  phoneNumberPattern?: string;
  classes?: { propertyTitle?: string };
  copyClipboardButton?: boolean;
  openUrlInNewTab?: boolean;
  textMode?: TextMode;
  tooltipContents?: JSX.Element;
  fullLine?: boolean;
}

export enum EnhancedDisplayTextFormatting {
  Bold = 'Bold',
  Italic = 'Italic',
  Underlined = 'Underlined',
}

export enum EnhancedDisplayType {
  DateTime = 'DateTime',
  Date = 'Date',
  Email = 'Email',
  RelativeLink = 'RelativeLink',
  ExternalLink = 'ExternalLink',
  PhoneNumber = 'PhoneNumber',
  IntPhoneNumber = 'IntPhoneNumber',
  Currency = 'Currency',
  Percentage = 'Percentage',
}

// type IDisplayStyleProps = Pick<IEnhancedDisplayProps, 'displayInline'> & {
//   singleLine: boolean;
// };

const useStyles = makeStyles<{ displayInline: boolean; singleLine: boolean }>()(
  (theme, { displayInline, singleLine }) => ({
    property: {
      display: singleLine ? 'flex' : undefined,
      alignContent: singleLine ? 'center' : undefined,
      width: '100%',
      textAlign: 'left',
      overflow: 'hidden',
      margin: displayInline ? '0 0 7px' : '0 0 18px',
      minWidth: displayInline ? 100 : 'unset',
      lineHeight: displayInline ? '20px' : 'unset',
    },
    propertyTitle: {
      color: displayInline
        ? MAIN_ONE_THEME.palette.primary2.main
        : MAIN_ONE_THEME.palette.primary1.main,
      fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
      lineHeight: displayInline ? '20px' : '12px',
      flex: singleLine ? '1 0 0' : undefined,
      fontWeight: 'bold',
    },
    propertyValue: {
      display: displayInline ? 'inline' : 'block',
      margin: displayInline ? '0' : '4px 4px 0 0',
      color: displayInline
        ? MAIN_ONE_THEME.palette.primary1.main
        : MAIN_ONE_THEME.palette.primary2.main,
      fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
      lineHeight: displayInline ? '15px' : '17px',
      fontFamily: contentFontFamilyRegular,
      flex: singleLine ? '1 1 0' : undefined,
      textAlign: singleLine ? 'center' : undefined,
    },
    buttonLink: {
      border: 0,
      backgroundColor: 'transparent',
      textDecoration: 'underline',
      padding: '0',
      cursor: 'pointer',
    },
    icon: {
      width: '15px',
      height: '15px',
      display: 'inline-block',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      backgroundColor: 'transparent',
      backgroundPosition: 'center',
      verticalAlign: 'bottom',
      margin: '0 5px 0 0',
    },
    tooltip: {
      backgroundColor: '#fff!important',
      maxWidth: '300px',
      border: '1px solid rgba(0, 0, 0, 0.24)',
      boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.24)',
    },
    tooltipTitle: {
      color: `${MAIN_ONE_THEME.palette.primary1.main}!important`,
    },
  })
);

const EnhancedDisplay: React.FC<IEnhancedDisplayProps> = ({
  className,
  title,
  iconUrl,
  value,
  url,
  type,
  displayInline,
  textFormatting,
  toolTip,
  classes = {},
  currencySymbol,
  dateFormat,
  phoneNumberPattern,
  copyClipboardButton = false,
  openUrlInNewTab = false,
  multiline,
  textMode,
  tooltipContents,
  fullLine = false,
}) => {
  const singleLine = multiline && textMode === TextMode.SingleLine;
  const { classes: displayClasses } = useStyles({ displayInline, singleLine });

  function formatValue(input: string | JSX.Element | undefined) {
    let outputEl: JSX.Element = (
      <p className={displayClasses.propertyValue}>
        {isZeroValue(input) ? 0 : input || EMPTY_FIELD_PLACEHOLDER}
      </p>
    );
    if (input) {
      switch (type) {
        case EnhancedDisplayType.Email:
          outputEl = (
            <a
              className={displayClasses.propertyValue}
              href={`mailto: ${input}`}
            >
              {input}
            </a>
          );
          break;
        case EnhancedDisplayType.PhoneNumber:
          <p className={displayClasses.propertyValue}>{input}</p>;
          break;
        case EnhancedDisplayType.RelativeLink:
          outputEl = (
            <NavLink
              className={displayClasses.propertyValue}
              to={url || '/'}
              target={openUrlInNewTab ? '_blank' : undefined}
            >
              {input}
            </NavLink>
          );
          break;
        case EnhancedDisplayType.ExternalLink:
          outputEl = (
            <a
              className={displayClasses.propertyValue}
              target='_blank'
              rel='noopener noreferrer'
              href={normaliseUrl(url || '')}
            >
              {input}
            </a>
          );
          break;
        default:
          break;
      }
    }

    if (textFormatting) {
      textFormatting.forEach((format) => {
        switch (format) {
          case EnhancedDisplayTextFormatting.Bold:
            outputEl = <b>{outputEl}</b>;
            break;
          case EnhancedDisplayTextFormatting.Italic:
            outputEl = <i>{outputEl}</i>;
            break;
          case EnhancedDisplayTextFormatting.Underlined:
            outputEl = <u>{outputEl}</u>;
            break;
          default:
            break;
        }
      });
    }

    return outputEl;
  }

  const getValue = () => {
    let output: string | JSX.Element | undefined = value;

    if (typeof output === 'string') {
      if (output) {
        switch (type) {
          case EnhancedDisplayType.Date:
            output = formatDate(getDateWithoutTime(output));
            break;
          case EnhancedDisplayType.DateTime:
            output = formatDateTime(output);
            break;
          case EnhancedDisplayType.Currency:
            output = output
              ? `${currencySymbol} ${addZeroesAndSeparatevalue(output)}`
              : output;
            break;
          case EnhancedDisplayType.Percentage:
            output = Number(output)
              ? `${valueCommaSeparated(changeValueToPercentage(output))}%`
              : `${output} %`;
            break;
          case EnhancedDisplayType.PhoneNumber: {
            output =
              output && phoneNumberPattern
                ? maskValueWithPatternIfMatch(output, phoneNumberPattern)
                : output;
            break;
          }
          case EnhancedDisplayType.IntPhoneNumber: {
            if (output && isPossiblePhoneNumber(output)) {
              output = formatPhoneNumberIntl(output as any);
            }
            break;
          }
          default:
            break;
        }
      }
    }
    return output;
  };

  const renderTitle = () => {
    return (
      <>
        {iconUrl && (
          <figure
            className={displayClasses.icon}
            style={{ backgroundImage: `url(${iconUrl})` }}
          />
        )}

        {title && (displayInline ? `${title}: ` : title)}
      </>
    );
  };

  const displayValue = getValue();

  return (
    <div
      className={clsx(displayClasses.property, className)}
      style={multiline ? { width: '100%' } : undefined}
    >
      <span
        className={clsx(displayClasses.propertyTitle, classes.propertyTitle)}
      >
        {copyClipboardButton && value
          ? typeof value === 'string' && (
              <CopyIconButton value={value}>{renderTitle()}</CopyIconButton>
            )
          : renderTitle()}
      </span>
      {!toolTip ? (
        formatValue(displayValue)
      ) : (
        <Tooltip
          placement='bottom-start'
          title={
            tooltipContents
              ? tooltipContents
              : (
                  <span className={displayClasses.tooltipTitle}>
                    {displayValue}
                  </span>
                )
          }
          classes={{ tooltip: displayClasses.tooltip }}
        >
          {formatValue(displayValue)}
        </Tooltip>
      )}
    </div>
  );
};

export default EnhancedDisplay;
