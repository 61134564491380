import { gql } from '@apollo/client';

export function listQuery() {
  return gql`
    query Get_Accounts(
  $SelectedAccountCategory: String
  $SelectedAccountSubCategory: String
  $CreatedOnToDate: Date
  $CreatedOnFromDate: Date
  $SelectedCompany: String!
) {
  Accounting {
    queries {
      ChartOfAccountsList(SelectedAccountCategory: $SelectedAccountSubCategory, CreatedOnFromDate: $CreatedOnFromDate, CreatedOnToDate: $CreatedOnToDate, SelectedCompany: $SelectedCompany) {
        accounting_ChartOfAccounts_Id
        accounting_ChartOfAccounts_AccountCurrency {
          Id
          Code
          Title
          Status
          Symbol
        }
        accounting_ChartOfAccounts_AccountName
        accounting_ChartOfAccounts_AccountCompany{
          Title,
          Code
        }
        accounting_ChartOfAccounts_AccountDescription
        accounting_ChartOfAccounts_createdOn,
        accounting_ChartOfAccounts_AccountID,
        parent_AccountName,
        accounting_ChartOfAccounts_AccountType {
          Id,
          Title,
          Code
        },
        accounting_ChartOfAccounts_AccountCategory {
          Id,
          Title,
          Code
        },
        directParent_Id,
        directParent_AccountGroup,
        directParent_AccountGroupName
      }
    },
    queries {
      GetAccountGroupLayer2ByCompany(SelectedCompany: $SelectedCompany) {
        accounting_ChartOfAccountsGroup_Id,
        accounting_ChartOfAccountsGroup_AccountGroup,
        accounting_ChartOfAccountsGroup_AccountGroupName,
        accounting_ChartOfAccountsGroup_AccountGroupLayerLevel 
      },
      GetAccountGroupLayer3ByCompany(SelectedCompany: $SelectedCompany, SelectedCategory: $SelectedAccountCategory){
        accounting_ChartOfAccountsGroup_Id,
        accounting_ChartOfAccountsGroup_AccountGroup,
        accounting_ChartOfAccountsGroup_AccountGroupName
      }
    }
    lookups {
      accountCategory {
        Id,
        Title,
        Code
      }
    }
  },
  SalesforceManagement {
    lookups{
      relatedCompanies {
              Code
              Title
              Id
          }
    }
  }
},
  `;
}

export function Lovs() {
  return gql`
    query Get_Lookups{
  SalesforceManagement {
    lookups{
      relatedCompanies {
              Code
              Title
              Id
          }
    }
  },
  Accounting {
    lookups {
      accountCategory {
        Id,
        Title
      }
    }
  }
}
  `;
}

export function GetAccountAuditTrails() {
  return gql`
  query GetAccountAuditTrails($id: String!)
  {
    Accounting {
      queries {
        GetAccountAuditTrails(AccountId: $id){
          accounting_AuditTrail_Id,
          accounting_AuditTrail_Title,
          accounting_AuditTrail_Message,
          accounting_AuditTrail_createdOn
        }
      }
    }
  }
`;
}
