import { gql } from "@apollo/client";

export function listQuery() {
  return gql`
    query LIST_SUBLINESAPPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $SelectedLineId: [String]
      $SelectedStatuses: [PlanConfigManagement_SublineStatuses]
      $FromCreatedOn: Date
      $ToCreatedOn: Date
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      PlanConfigManagement {
        queries {
          getSubLineList(
            SelectedLineId: $SelectedLineId
            SelectedStatuses: $SelectedStatuses
            FromCreatedOn: $FromCreatedOn
            ToCreatedOn: $ToCreatedOn
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
              __typename
            }
            items {
              planConfigManagement_Subline_Id
              planConfigManagement_Subline_Name
              planConfigManagement_Subline_ExternalCode
              planConfigManagement_Subline_NameArabic
              planConfigManagement_Subline_NameFrench
              planConfigManagement_Subline_createdOn
              planConfigManagement_Subline_modifiedOn
              planConfigManagement_Subline_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      ExternalCode
                      NameArabic
                      Name
                      NameFrench
                      Abbreviation
                      AbbreviationArabic
                      Status
                      __typename
                    }
                    __typename
                  }
                  __typename
                }
                __typename
              }
              planConfigManagement_Subline_Status
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      PlanConfigManagement_SublineStatuses: __type(
        name: "PlanConfigManagement_SublineStatuses"
      ) {
        name
        enumValues {
          name
          __typename
        }
        __typename
      }
    }
  `;
}

export function filterQuery() {
  return gql`
    query SUBLINES_LISTING_LOVS {
      PlanConfigManagement {
        queries {
          allLines {
            planConfigManagement_Line_Id
            planConfigManagement_Line_Name
            planConfigManagement_Line_Status
          }
        }
      }
      PlanConfigManagement_SublineStatuses: __type(
        name: "PlanConfigManagement_SublineStatuses"
      ) {
        name
        enumValues {
          name
          __typename
        }
        __typename
      }
    }
  `;
}
