import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import Loader from "../../components/Loader";
import { cloneDeep, isEmpty } from "lodash";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import { ITaxationDrawerProps } from ".";
import {
  getListForm,
  createTaxation,
  updateTaxation,
  getLatestTaxationByLine,
  getTaxationDetailsInfo,
} from "./queries";
import {
  extractLatestTaxationByLine,
  graphqlToTaxationInfo,
  toLookups,
} from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import dayjs from "dayjs";

const TaxationDrawer: React.FC<ITaxationDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  taxationId,
}) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [taxationEnumQuery] = useLazyQuery(getListForm(), {
    fetchPolicy: "no-cache",
  });

  const [taxationInfoQuery] = useLazyQuery(getTaxationDetailsInfo(), {
    fetchPolicy: "no-cache",
  });

  const loadLovList = async () => {
    const result = await taxationEnumQuery();
    const taxationEnums = toLookups(result.data);

    return taxationEnums;
  };

  const getEntityInfo = async () => {
    if (taxationId) {
      const apiResult = await taxationInfoQuery({
        variables: { id: taxationId },
      });
      if (apiResult.data) {
        const taxationEntity = graphqlToTaxationInfo(apiResult.data);
        return taxationEntity;
      }
    }

    return null;
  };

  const [selectedLineID, setSelectedLineID] = useState("");

  const [getLatestTaxationByLineDetailsLazy] = useLazyQuery(
    getLatestTaxationByLine()
  );
  const [taxationAction] = useMutation(
    taxationId ? updateTaxation() : createTaxation()
  );

  const getLatestTaxationByLineDetails = async (selectedLineID: string) => {
    if (!isEmpty(selectedLineID)) {
      try {
        const response = await getLatestTaxationByLineDetailsLazy({
          variables: {
            selectedLineID: selectedLineID,
          },
        });

        const latestTaxationInfo = extractLatestTaxationByLine(response?.data);

        setInputsForm((currentInputsForm) => {
          const updatedInputs = { ...currentInputsForm };

          updatedInputs.fixedStamp.value = latestTaxationInfo.fixedStamp;
          updatedInputs.fixedStampCurrency.value =
            latestTaxationInfo.fixedStampCurrency;
          updatedInputs.proportionalStamp.value =
            latestTaxationInfo.proportionalStamp
              ? latestTaxationInfo.proportionalStamp
              : "39735";
          updatedInputs.municipalityTax.value =
            latestTaxationInfo.municipalityTax;
          updatedInputs.taxOnCommission.value =
            latestTaxationInfo.taxOnCommission;
          updatedInputs.reinsuranceTax.value =
            latestTaxationInfo.reinsuranceTax;

          return updatedInputs;
        });
      } catch (error) {
        console.error("Error fetching taxation details:", error);
      }
    }
  };

  function handleLineSelection(selectedOption: any) {
    setSelectedLineID(selectedOption);
    if (!taxationId && selectedOption.length >= 3) {
      getLatestTaxationByLineDetails(selectedOption);
    }
  }

  let updatedInputs: Record<string, DynamicFormInputType> = inputs;

  const initialize = async () => {
    try {
      updatedInputs = cloneDeep(inputsForm);

      const [taxationEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      updatedInputs.line.disabled = !!taxationId;
      updatedInputs.effectiveTo.hidden = !taxationId;

      (updatedInputs.line as IFormSelectDynamicProps).selectOptions =
        lovData["line"];

      (
        updatedInputs.fixedStampCurrency as IFormSelectDynamicProps
      ).selectOptions = lovData["fixedStampCurrency"];

      (updatedInputs.line as IFormSelectDynamicProps).onSelect = (option) => {
        setSelectedLineID(option);
        handleLineSelection(option);
      };

      if (taxationEntity) {
        updatedInputs.line.value = taxationEntity.line;
        updatedInputs.fixedStamp.value = taxationEntity.fixedStamp;
        updatedInputs.fixedStampCurrency.value =
          taxationEntity.fixedStampCurrency;
        updatedInputs.proportionalStamp.value =
          taxationEntity.proportionalStamp;
        updatedInputs.municipalityTax.value = taxationEntity.municipalityTax;
        updatedInputs.taxOnCommission.value = taxationEntity.taxOnCommission;
        updatedInputs.reinsuranceTax.value = taxationEntity.reinsuranceTax;
        updatedInputs.effectiveFrom.value = taxationEntity.effectiveFrom;
        updatedInputs.effectiveTo.value = taxationEntity.effectiveTo;
      }
      setBooted(true);
      setInputsForm(updatedInputs);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const divideByHundred = (x: number | string) => Number(x) / 100;

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variablesMutation = {};
      if (!taxationId) {
        variablesMutation = {
          effectiveFrom: dayjs(new Date(data.effectiveFrom)).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          fixedStamp: parseFloat(data.fixedStamp),
          fixedStampCurrency: data.fixedStampCurrency,
          lineID: selectedLineID,
          municipalityTax: divideByHundred(data.municipalityTax),
          proportionalStamp: divideByHundred(data.proportionalStamp),
          reinsuranceTax: divideByHundred(data.reinsuranceTax),
          taxOnCommission: divideByHundred(data.taxOnCommission),
        };
      } else {
        variablesMutation = {
          entityId: taxationId,
          effectiveFrom: dayjs(new Date(data.effectiveFrom)).format(
            SEND_TO_BACKEND_DATE_FORMAT
          ),
          fixedStamp: parseFloat(data.fixedStamp),
          fixedStampCurrency: data.fixedStampCurrency,
          municipalityTax: divideByHundred(data.municipalityTax),
          proportionalStamp: divideByHundred(data.proportionalStamp),
          reinsuranceTax: divideByHundred(data.reinsuranceTax),
          taxOnCommission: divideByHundred(data.taxOnCommission),
        };
      }

      const res = await taxationAction({
        variables: taxationId
          ? {
              ...variablesMutation,
              entityId: taxationId,
            }
          : variablesMutation,
        errorPolicy: "all",
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {taxationId
              ? "Taxation Record successfully updated."
              : "Taxation Record successfully created."}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState("success");
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  return (
    <GenericDrawer
      title={taxationId ? "Modify Taxation Record" : "New Taxation Record"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default TaxationDrawer;
