import dayjs from "dayjs";
import { DynamicFormInputType, FormInputTypes } from "../../DynamicForm";

export const initialValues = {
    person: "",
    type: "",
    isCompany: false,
    isLocal: false,
    effectiveDate: "",
    expiryDate: "",
    providerStatus: "ACTIVE",
};

export const getInputs = (
  values: typeof initialValues = initialValues,
  lovs: Record<string, Record<string, string>>,
  providerId: string
): Record<string, DynamicFormInputType> => ({
  person: {
    name: "person",
    title: "Person",
    type: FormInputTypes.chips,
    placeholder: "Type to search for Person",
    value: values.person,
    multiple: false,
    disabled: false,
    hasPagination: false,
    itemsPerPage: 15,
    hidden: !!providerId,
    required: !providerId,
    selectOptions: lovs?.person || {}
  },
  type: {
    name: "type",
    title: "Type",
    type: FormInputTypes.chips,
    placeholder: "Type",
    value: values.type,
    multiple: false,
    disabled: !!providerId,
    required: true,
    selectOptions: lovs?.type || {}
  },
  isCompany: {
    name: "isCompany",
    title: "Is Company",
    placeholder: "Is Company",
    type: FormInputTypes.switch,
    value: values.isCompany,
    required: false,
    disabled: false,
  },
  isLocal: {
    name: "isLocal",
    title: "Is Local",
    placeholder: "Is Local",
    type: FormInputTypes.switch,
    value: values.isLocal,
    required: false,
    disabled: false,
  },
  effectiveDate: {
    name: "effectiveDate",
    title: "Effective Date",
    type: FormInputTypes.date,
    placeholder: "Effective Date",
    value: values.effectiveDate,
    disabled: false,
    required: !!providerId,
  },
  expiryDate: {
    name: "expiryDate",
    title: "Expiry Date",
    type: FormInputTypes.date,
    placeholder: "Expiry Date",
    value: values.expiryDate,
    hidden: values?.providerStatus?.toUpperCase() !== "INACTIVE",
    required: false,
    disabled: false,
    customValidator: (values) => {
      const { effectiveDate, expiryDate } = values;
      if (expiryDate &&
        effectiveDate &&
        dayjs(expiryDate).isValid() &&
        dayjs(effectiveDate).isValid() &&
        dayjs(expiryDate).isBefore(dayjs(effectiveDate))) {
          return "Expiry Date shall be equal or greater than Effective Date";
      }
      return "";
    }
  },
  providerStatus: {
    name: "providerStatus",
    title: "Status",
    type: FormInputTypes.chips,
    value: values.providerStatus,
    multiple: false,
    disabled: false,
    hidden: !!providerId,
    required: !providerId,
    selectOptions: lovs?.providerStatus || {}
  },
});
