import { gql } from "@apollo/client";

export function getClauseList() {
  return gql`
    query getClauseListForProposal(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedLineIds: [String]
    ) {
      PlanConfigManagement {
        queries {
          getClauseList(
            selectedLineIds: $selectedLineIds
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              planConfigManagement_Clause_Id
              planConfigManagement_Clause_createdOn
              planConfigManagement_Clause_modifiedOn
              planConfigManagement_Clause_ExternalCode
              planConfigManagement_Clause_Name
              planConfigManagement_Clause_Description

              planConfigManagement_Clause_LineID {
                id
                views {
                  PlanConfigManagement_all {
                    properties {
                      Name
                      Status
                    }
                  }
                }
              }
              planConfigManagement_Clause_Status
            }
          }
        }
      }
    }
  `;
}

export function issuePolicyMotor() {
  return gql`
    mutation issuePolicyGeneric($entityId: String!) {
      production {
        entities {
          proposal {
            production {
              issuePolicyGeneric(entityId: $entityId) {
                EntityTypeId
                EntityId
                EntityViewId
                PropertyId
                File {
                  fileName
                  id
                }
              }
            }
          }
        }
      }
    }
  `;
}
export function downloadProposalDocument() {
  return gql`
    mutation downloadProposalDocument(
      $entityId: String!
      $clauseText: String!
    ) {
      production {
        entities {
          proposal {
            production {
              downloadPolicySampleMotor(
                clauseText: $clauseText
                entityId: $entityId
              ) {
                EntityTypeId
                EntityId
                EntityViewId
                PropertyId
                File {
                  id
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `;
}
