import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery } from "@apollo/client";
import { listQuery } from "./queries";
import { mapToListingData, toLookups } from "./utils";
import { filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from "../../components/enhanced-table";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import dayjs from "dayjs";
import { useAppSelector } from "../../redux/hooks";
import SalesForceBusinessPartnerDrawer from "../../forms/salesforce-business-partners-drawer/SalesForceBusinessPartnerDrawer";
import { capitalizeFirstCharacter } from "../../utils/formatting-utils";
import { ITableOrder, TableSortOrder } from "../../utils/table-utils";
interface IBusinessPartnersPage {}

const BusinessPartnersPage: React.FC<IBusinessPartnersPage> = ({}) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [booted, setBooted] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [businessPartnersDrawerOpen, setBusinessPartnersDrawerOpen] =
    useState<boolean>(false);

  const isAdmin = user.userRoles.includes("Insurance-Admin");
  const actions: IEnhancedTableMenuItem[] = [
    {
      title: "+ New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin,
    },
  ];

  const FILTER_SESSION_KEY = "businessPartnersFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

  const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          isCompany: false,
          isLocal: false,
          isRegistered: false,
          selectedTypes: [],
          selectedRelatedCompany: [],
          businessPartnerStatus: [],
          createdOn: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);
  let setSearchTimeout: NodeJS.Timeout;

  const [tableOrder, setTableOrder] = useState<Record<string, ITableOrder>>({
    ordering: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [getBusinessPartnersLazy] = useLazyQuery(listQuery());

  const loadBusinessPartnerList = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    orders = tableOrder,
    searchKeyword = keywordSearch
  ) => {
    const result = await getBusinessPartnersLazy({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        selectedTypes:
          filterValues?.type && filterValues?.type?.length > 0
            ? filterValues?.type
            : [],
        isCompany:
          filterValues?.isCompany && filterValues?.isCompany?.length > 0
            ? filterValues?.isCompany.map((bool: string) => {
                return bool === "true";
              })
            : [],
        isLocal:
          filterValues?.isLocal && filterValues?.isLocal?.length > 0
            ? filterValues?.isLocal.map((bool: string) => {
                return bool === "true";
              })
            : [],
        isRegistered:
          filterValues?.isRegistered && filterValues?.isRegistered?.length > 0
            ? filterValues?.isRegistered.map((bool: string) => {
                return bool === "true";
              })
            : [],
        selectedRelatedCompany:
          filterValues?.company && filterValues?.company?.length > 0
            ? filterValues?.company
            : [],
        businessPartnerStatus:
          filterValues?.status && filterValues?.status?.length > 0
            ? filterValues?.status
            : [],
        createdDateFrom: filterValues?.createdOn?.[0]
          ? dayjs(filterValues.createdOn[0]).format(SEND_TO_BACKEND_DATE_FORMAT)
          : null,
        createdDateTo: filterValues?.createdOn?.[1]
          ? dayjs(filterValues?.createdOn?.[1]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
        Attribute: capitalizeFirstCharacter(
          orders.ordering.orderBy || "Person_FullName"
        ),
        Descending: orders.ordering.orderDirection !== "asc",
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: "all",
    });

    return result;
  };

  const initialize = async () => {
    try {
      const listData = await loadBusinessPartnerList();
      const mappedList = await mapToListingData(listData.data);
      setTableData({ ...mappedList, pageNumber: 0 });

      if (listData.error) {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
      }

      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = toLookups(listData.data);

      if (!Object.keys(lovs.type).length) {
        lovs.type = filterValues?.type;
      }

      if (!Object.keys(lovs.company).length) {
        lovs.company = filterValues?.company;
      }

      if (!Object.keys(lovs.isCompany).length) {
        lovs.isCompany = filterValues?.isCompany;
      }

      if (!Object.keys(lovs.isLocal).length) {
        lovs.isLocal = filterValues?.isLocal;
      }

      if (!Object.keys(lovs.isRegistered).length) {
        lovs.isRegistered = filterValues?.isRegistered;
      }

      if (!Object.keys(lovs.status).length) {
        lovs.status = filterValues?.status;
      }

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);
      setBooted(true);
    } catch (error) {
      console.error("Error initializing data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setLoadingState(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (booted) {
      handlePageChange(0, tableData.pageSize, tableOrder, keywordSearch);
    }
  }, [filterValues]);

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handlePageChange(0, tableData.pageSize, tableOrder, val);
    }, 1000);
  };

  const handleSortChange = (orderBy: string, order: string) => {
    const newOrder = _.cloneDeep(tableOrder);

    newOrder.ordering.orderBy = orderBy;
    newOrder.ordering.orderDirection = order as any;
    handlePageChange(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder,
      keywordSearch
    );
    setTableOrder(newOrder);
  };

  const handlePageChange = async (
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrder,
    searchKeyword: string = keywordSearch
  ) => {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadBusinessPartnerList(
        page,
        pageSize,
        orders,
        searchKeyword
      );
      const mappedList = await mapToListingData(result.data);
      setTableData({ ...mappedList, pageNumber: page, pageSize });
      setLoadingState(false);
    }
  };

  actions[0].onClick = () => {
    setBusinessPartnersDrawerOpen(true);
  };

  (
    headers.salesforceManagement_BusinessPartner_Code as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate(
      "/salesforce/business-partners/" +
        payload.columns.salesforceManagement_BusinessPartner_Id
    );
  };

  (headers.person_FullName as IEnhanceTableHeaderClickable).callback = (
    payload: any
  ) => {
    navigate(
      "/salesforce/business-partners/" +
        payload.columns.salesforceManagement_BusinessPartner_Id
    );
  };

  const renderMainChildren = () => (
    <div style={{ marginTop: "20px" }}>
      <EnhancedTable
        title="Business Partners"
        name="count"
        orderByAscendingByDefault
        inline={false}
        data={tableData}
        headers={headers}
        handlePageChange={(page: number) =>
          handlePageChange(page, tableData.pageSize)
        }
        handleSearchChange={delaySearch}
        handleSort={handleSortChange}
        handleRowsPerPageChange={(pageSize: number) =>
          handlePageChange(0, pageSize)
        }
        currentPage={tableData.pageNumber}
        hideToolbar={false}
        isToolbarAction={false}
        usePagination
        disableSelection
        actions={actions}
        loader={loadingState}
        showCellFullData={true}
        ordering={tableOrder.ordering.orderDirection as TableSortOrder}
        orderingBy={tableOrder.ordering.orderBy}
        //searchValue={keywordSearch}
      />
      {businessPartnersDrawerOpen && (
        <SalesForceBusinessPartnerDrawer
          open={businessPartnersDrawerOpen}
          onClose={() => setBusinessPartnersDrawerOpen(false)}
          onSuccess={() => {
            handlePageChange(0, 10);
          }}
          salesForceBusinessPartnerDetailsInfo={undefined}
        />
      )}
    </div>
  );

  const onFilterUpdate = async (v: Record<string, any>) => {
    let newFilters = _.cloneDeep(v);
    setFilter(newFilters, FILTER_SESSION_KEY);
    setFilterValues(newFilters);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={""}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={!booted}
      name={"Applications"}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default BusinessPartnersPage;
