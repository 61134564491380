import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { DynamicFormInputType } from "../../DynamicForm";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { getInitialInputs } from "./content";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import { useMutation } from "@apollo/client";
import { updateCommissions } from "./queries";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { getError } from "../../utils/graph-utils";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { isValidNumber } from "../../utils/validationUtils";
import Loader from "../../components/Loader";

const UpdateCommissionProposalDrawer: React.FC<
  IUpdateCommissionProposalDrawerProps
> = ({ id, open, onSuccess, onClose, data, partnerLov }) => {
  const [booted, setBooted] = useState<boolean>(false);

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>();

  const [updateCommissionAction] = useMutation(updateCommissions());

  const initialize = () => {
    const initalData = getInitialInputs(data, partnerLov);
    setInputsForm(initalData);
    setBooted(true);
  };

  useEffect(() => {
    if (open) {
      initialize();
    } else {
      setInputsForm(undefined);
    }
  }, [open]);

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputsForm, values);
    setSubmitButtonState("loading");
    setFormDisabled(true);

    try {
      let variables = {
        entityId: id,
        primaryBP: data.primaryBP,
        primaryPercentage: isValidNumber(data.primaryPercentage)
          ? data.primaryPercentage / 100
          : 0,
        secondaryBP: data.secondaryBP,
        secondaryPercentage: isEmpty(data.secondaryBP)
          ? null
          : isValidNumber(data.secondaryPercentage)
          ? data.secondaryPercentage / 100
          : 0,
        additionalBP: data.additionalBP,
        additionalPercentage: isEmpty(data.additionalBP)
          ? null
          : isValidNumber(data.additionalPercentage)
          ? data.additionalPercentage / 100
          : 0,
      };

      const res = await updateCommissionAction({
        variables: variables,
        errorPolicy: "all",
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {"Commission Split updated successfully"}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState("success");
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  if (!inputsForm) {
    return <></>;
  }

  return (
    <GenericDrawer
      title="Modify Commission Split"
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default UpdateCommissionProposalDrawer;
