import { gql } from "@apollo/client";

export function getAgencyEnums() {
  return gql(/* GraphQL */ `
    query getAgencyEnums {
      Insurance_AgencyStatuses: __type(name: "Insurance_AgencyStatuses") {
        name
        enumValues {
          name
        }
      }
      Insurance {
        lookups {
          agencyTypes {
            Id
            Title
          }
          agencyCodes {
            Id
            Title
          }
        }
      }
    }
  `);
}

export function getAgencyInfo() {
  return gql`
    query getAgencyInfo($id: String!) {
      Insurance {
        entities {
          agency(id: $id) {
            views {
              Insurance_all {
                properties {
                  AgencyName
                  AgencyStatus
                  AgencyType {
                    Id
                    Title
                  }
                  AgencyCode {
                    Id
                    Title
                  }
                  MTPCommissionPercentage
                  MBICommissionPercentage
                  ExpatCommissionPercentage
                  UFACode
                }
              }
            }
          }
        }
      }
    }
  `;
}

export function updateCommissions() {
  return gql`
    mutation UpdateCommissions(
      $additionalPercentage: Decimal
      $entityId: String!
      $primaryPercentage: Decimal!
      $secondaryPercentage: Decimal
    ) {
      production {
        entities {
          proposal {
            production {
              updateCommissions(
                entityId: $entityId
                additionalPercentage: $additionalPercentage
                primaryPercentage: $primaryPercentage
                secondaryPercentage: $secondaryPercentage
              ) {
                id
              }
            }
          }
        }
      }
    }
  `;
}

export function updateAgency() {
  return gql`
    mutation updateAgency(
      $agencyId: String!
      $agencyName: String!
      $agencyStatus: Insurance_AgencyStatuses!
      $agencyType: String!
      $agencyCode: String!
      $mtpCommissionPercentage: Long
      $mbiCommissionPercentage: Long
      $expatCommissionPercentage: Long
      $ufaCode: String!
    ) {
      insurance {
        actions {
          updateAgency(
            agencyId: $agencyId
            agencyName: $agencyName
            agencyStatus: $agencyStatus
            agencyType: $agencyType
            agencyCode: $agencyCode
            mtpCommissionPercentage: $mtpCommissionPercentage
            mbiCommissionPercentage: $mbiCommissionPercentage
            expatCommissionPercentage: $expatCommissionPercentage
            ufaCode: $ufaCode
          ) {
            views {
              Insurance_all {
                properties {
                  AgencyName
                  AgencyStatus
                  AgencyType {
                    Id
                    Title
                  }
                  AgencyCode {
                    Id
                    Title
                  }
                  MTPCommissionPercentage
                  MBICommissionPercentage
                  ExpatCommissionPercentage
                  UFACode
                }
              }
            }
          }
        }
      }
    }
  `;
}
