import React, { useMemo } from "react";
import { makeStyles } from "tss-react/mui";
import { EnhancedDisplayType } from "../../../components/enhanced-form/EnhancedDisplay";
import { IEntityInfoView } from "../../../components/widgets/entity-info/EntityInfoFields";
import EntityInfoWidget from "../../../components/widgets/entity-info/EntityInfoWidget";

export const useStyles = makeStyles()(() => ({
  paperClass: {
    boxShadow: "none !important",
  },
  updateBtn: {
    margin: "15px auto 0",
  },
}));

interface IPersonEntityInfoWidgetProps {
  data: Record<string, any>;
}

const PersonEntityInfoWidget: React.FC<IPersonEntityInfoWidgetProps> = ({
  data,
}) => {

  const getTypeValue = (value: string) => {
    switch(value.toLowerCase()){
      case "company":
        return "Company";
      case "person": 
      return "Individual"
    }
    return "";
  }


  const entityView = useMemo(() => {
    const result: IEntityInfoView = {
      name: "section1",
      title: "Person Details",
      iconUrl: "",
      sections: [
        {
          name: "personDetailsSection1",
          title: "",
          properties: {
            code: {
              value: data?.code,
              multiline: false,
              name: "code",
              title: "Code",
            },
            name: {
              value: data?.name,
              multiline: false,
              name: "name",
              title: "Name",
            },
            type: {
              value: getTypeValue(data?.type),
              multiline: false,
              name: "type",
              title: "Type",
            },
            email: {
              value: data?.email,
              multiline: false,
              name: "email",
              title: "Email",
            },
            phoneNumber: {
              value: data?.phoneNumber,
              multiline: false,
              name: "phoneNumber",
              title: "Phone Number",
              type: EnhancedDisplayType.IntPhoneNumber
            },
          },
        },
        {
          name: "personDetailsSection2",
          title: "",
          properties: {
            createdBy: {
              value: data?.createdBy,
              multiline: false,
              name: "createdBy",
              title: "Created By",
            },
            createdOn: {
              value: data?.createdOn,
              multiline: false,
              type: EnhancedDisplayType.Date,
              name: "createdOn",
              title: "Created On",
            },
            modifiedBy: {
              value: data?.modifiedBy,
              multiline: false,
              name: "modifiedBy",
              title: "Modified By",
            },
            modifiedOn: {
              value: data?.modifiedOn,
              multiline: false,
              type: EnhancedDisplayType.Date,
              name: "modifiedOn",
              title: "Modified On",
            },
          },
        },
      ],
      socialMedia: {
        facebook: "",
        linkedIn: "",
        twitter: "",
      },
    };

    return result;
  }, [data]);

  return (
    <EntityInfoWidget
      background="rgb(193, 0, 0)"
      classification={{ classifications: {}, value: "" }}
      progress={100}
      supportsClassification={true}
      supportsProgressBar={true}
      title={data.name}
      view={entityView}
    ></EntityInfoWidget>
  );
};

export default PersonEntityInfoWidget;
