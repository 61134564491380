import React, { useEffect, useState } from 'react';
import GenericDrawer from '../../components/common/generic-drawer/GenericDrawer';
import DynamicForm from '../../DynamicForm/DynamicForm';
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from '../../DynamicForm';
import { toast } from 'react-toastify';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import { normaliseDynamicValues } from '../../utils/dynamic-utils';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import { inputs } from './content';
import { useLazyQuery, useMutation } from '@apollo/client';
import { createPolicyNumberRange, getRangeEnums } from './queries';
import { LookupToList } from './utils';
import Loader from '../../components/Loader';
import { cloneDeep } from 'lodash';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { DEFAULT_ERROR_TEXT } from '../../constants';
import { isEmpty } from '../../utils/validationUtils';
import { getError } from '../../utils/graph-utils';

const PolicyNumberRangeDrawer: React.FC<IPolicyNumberRangeDrawerProps> = ({
  open,
  onSuccess,
  onClose,
  extension,
}) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [rangeEnumResults] = useLazyQuery(getRangeEnums());
  const [policyNumberRangeAction] = useMutation(createPolicyNumberRange());
  const [formDisabled, setFormDisabled] = useState(false);

  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const getLovs = async () => {
    const data = await rangeEnumResults();
    const newAssignPolicyCoverEnums = LookupToList(data.data);
    const result: Record<string, Record<string, string>> = {
      rangeType: newAssignPolicyCoverEnums['Insurance_RangeTypes'],
    };

    return result;
  };

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);
      const lovData = await getLovs();

      if (lovData) {
        (updatedInputs.rangeType as IFormSelectDynamicProps).selectOptions =
          lovData['rangeType'];
        if (extension.toLowerCase() == 'motor') {
          updatedInputs.rangeType.value = 'MOTOR';
        } else if (extension.toLowerCase() == 'expat') {
          updatedInputs.rangeType.value = 'EXPAT';
        }
      }

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState('loading');

    try {
      const variables = {
        rangeFrom: parseInt(data.rangeFrom as unknown as string),
        rangeTo: parseInt(data.rangeTo as unknown as string),
        rangeType: data.rangeType,
      };

      const res = await policyNumberRangeAction({
        variables: variables,
        errorPolicy: 'all',
      });

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {'Policy Number Range created successfully'}
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState('success');
          setFormDisabled(false);
          onSuccess();
        }, 500);
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (err) {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <GenericDrawer title={'New Range'} onClose={() => onClose()} isOpen={open}>
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={'Submit'}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title='Information'
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PolicyNumberRangeDrawer;
