import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Chip } from '@mui/material';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { makeStyles } from 'tss-react/mui';
import {
  IApplicationDetails,
  IApplicationDetailsPageMotorProps,
  IPlanDetailsProductsCounter,
  initValuesPlanDetailsProductsCounter,
  initialValues,
} from '.';
import EnhancedButton from '../../components/EnhancedButton';
import Loader from '../../components/Loader';
import ToastErrorMessage from '../../components/ToastErrorMessage';
import ToastSuccessMessage from '../../components/ToastSuccessMessage';
import { EnhancedButtonStatus } from '../../components/common/EnhancedButton';
import EnhancedStepper from '../../components/common/EnhancedStepper';
import Separator from '../../components/common/Separator';
import WidgetPaper from '../../components/common/WidgetPaper';
import WidgetSection from '../../components/common/WidgetSection';
import QuotesCards, { quote } from '../../components/custom/QuotesCards';
import EnhancedChipInput from '../../components/enhanced-form/EnhancedChipInput';
import EnhancedCurrencyInput from '../../components/enhanced-form/EnhancedCurrencyInput';
import EnhancedDatePicker from '../../components/enhanced-form/EnhancedDatePicker';
import EnhancedInput from '../../components/enhanced-form/EnhancedInput';
import EnhancedInternationalPhoneInput from '../../components/enhanced-form/EnhancedInternationalPhoneInput';
import EnhancedNumberInput from '../../components/enhanced-form/EnhancedNumberInput';
import ShowForUser from '../../components/user/ShowForUser';
import { IEntityInfoView } from '../../components/widgets/entity-info/EntityInfoFields';
import EntityInfoWidget from '../../components/widgets/entity-info/EntityInfoWidget';
import {
  DEFAULT_ERROR_TEXT,
  MAIN_ONE_THEME,
  contentFontFamilyBold,
} from '../../constants';
import StaticLayout from '../../page-layout/static-layout/StaticLayout';
import { useAppSelector } from '../../redux/hooks';
import DataService from '../../services/dataService';
import { getDefaultDate } from '../../utils/date-utils';
import {
  capitalizeFirstLetter,
  dateStringToISOString,
} from '../../utils/formatting-utils';
import { getError, lookupListAsRecordObject } from '../../utils/graph-utils';
import { isEmpty } from '../../utils/validationUtils';
import { entityView, steps } from './content';
import {
  acceptQuotes,
  calculateAction,
  validateVignette,
  checkPlateNumberHasActiveProductPolicy,
  downloadQuote,
  getApplicationDetails,
  getModelsByBrands,
  issuePolicy,
  previewPolicies,
  sendQuotes,
  getCopyPolicyDetailsQuery,
} from './queries';
import {
  adjustAgencyRepair,
  checkDate,
  checkEmail,
  checkPhoneNumber,
  checkQuoteName,
  entityToApplicationDetails,
  extractLovs,
  filterBodyTypeLOVs,
  filterProductsByAgentProductsLOVS,
  filterTowingServiceLOVs,
  filterUsageTypeLOVs,
  getApplicationProgress,
  getApplicationStatus,
  graphqlEntityToQuoteDetails,
  isVisiblePlanDetailsField,
} from './utils';
import ApplicationCopyPolicyDetailsDrawer from '../application-copy-policy-details-drawer/ApplicationCopyPolicyDetailsDrawer';
import ApplicationDrawer from '../application-drawer/ApplicationDrawer';
import { createActionAsAgent } from '../../pages/applications-listing/queries';
import ApplicationDrawerWithMultipleAgencies from '../application-with-multiple-agencies-drawer/ApplicationDrawerWithMultipleAgencies';

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: 'transparent',
    maxWidth: '90%',
    marginBottom: '20px',
    '& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root .Mui-completed': {
      color: MAIN_ONE_THEME.palette.primary5.main,
    },

    '@media only screen and (min-width: 600px)': {
      maxWidth: '1300px',
    },
  },
  section: {
    display: 'grid',
    'grid-template-columns': '19.5% 19.5% 19.5% 19.5% 19.5%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',

    '& label': {
      '& span': {
        margin: '-2px 0',
      },
    },
  },
  sectionTitle: {
    padding: '-10x 10x!important',
  },
  sectionFullRow: {
    display: 'grid',
    'grid-template-columns': '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  row: {
    flexWrap: 'wrap',
    margin: '15px 20px 10px 0px',
    alignItems: 'flex-start',
    display: 'grid',
    gap: '5px',
    '& label': {
      flexGrow: '1',
      flexBasis: '0',
      minWidth: '0',
    },
    'grid-template-columns': '19.5% 19.5% 19.5% 19% 16% 3% 3%',
  },
  thickSeperator: {
    height: 10,
    margin: '10px 0 10px 0',
    'grid-column-start': '1',
    'grid-column-end': '4',
  },
  field: {
    width: '96%',
    marginRight: '10px !important',
  },
  repeaterField: {
    margin: '0px!important',
  },
  arField: {
    width: '96%',
    marginRight: '10px !important',
    direction: 'rtl',
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    '&:hover': {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: '0',
  },
  dirtyChip: {
    margin: '0 0 -15px 0',
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    '& span': {
      color: '#ffffff',
    },
  },
}));

const ApplicationDetailsPageMotor: React.FC<
  IApplicationDetailsPageMotorProps
> = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const tenant = useAppSelector((state) => state.tenant);
  const params = useParams();
  const navigate = useNavigate();
  //#endregion

  //#region component hooks and states
  const [applicationInfo, setApplicationInfo] =
    useState<IApplicationDetails>(initialValues);

  const [updatedLeftSideInfo, setUpdatedLeftSideInfo] =
    useState<IEntityInfoView>(entityView);

  const [lovs, setLovs] =
    useState<Record<string, Record<string, string>>>(null);

  const [bodyTypes, setBodyTypes] = useState<Record<string, string>>(undefined);

  const [towingService, setTowingService] =
    useState<Record<string, string>>(undefined);

  const [usageTypes, setUsageTypes] =
    useState<Record<string, string>>(undefined);

  const [agencyRepairs, setAgencyRepairs] = useState<Record<string, string>[]>(
    []
  );

  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [lob, setLob] = useState<string>('');

  const [loadingModelsState, setLoadingModelsState] = useState<boolean>(false);

  const [copyPolicyDetailsDrawerOpen, setCopyPolicyDetailsDrawerOpen] =
    useState<boolean>(false);

  const [loadingNameOnLicenseArState, setLoadingNameOnLicenseArState] =
    useState<boolean>(false);
  const [loadingNameOnLicenseEnState, setLoadingNameOnLicenseEnState] =
    useState<boolean>(false);
  const [planDetailsKeysStatus, setPlanDetailsKeysStatus] = useState<
    Record<string, boolean>[]
  >([{ coverageType: false }]);
  const [newButtonState, setNewButtonState] = useState<EnhancedButtonStatus>();

  const [newAppDrawerOpen, setNewAppDrawerOpen] = useState<boolean>(false);
  const [
    newApplicationWithMultipleAgencieDrawerOpen,
    setNewApplicationWithMultipleAgencieDrawerOpen,
  ] = useState<boolean>(false);

  const countryCode = useRef<string>();
  const displayDirtyMessage = useRef<number>(0);
  const dirtyChange = useRef<number>(0);

  const [isUnderwriter, setIsUnderwriter] = useState<boolean>(false);
  const [isIssuingAgent, setIsIssuingAgent] = useState<boolean>(false);
  const [isQuotingAgent, setIsQuotingAgent] = useState<boolean>(false);

  const [agentType, setAgentType] = useState<string>('');

  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { isDirty, errors },
    watch,
  } = useForm<IApplicationDetails>({
    defaultValues: initialValues,
    shouldUnregister: true,
  });

  const disabledSendBtn = isDirty;
  const disabledAcceptBtn = isDirty;
  const disabledIssuePolicyBtn = isDirty;
  dirtyChange.current += 1;
  displayDirtyMessage.current =
    dirtyChange.current > 4 ? 1 : displayDirtyMessage.current;

  useEffect(() => {
    if (!isDirty) {
      displayDirtyMessage.current = 0;
    }
  }, [isDirty]);

  const planDetailsFieldArray = useFieldArray({
    control,
    name: 'planDetails',
  });

  const { replace } = useFieldArray({
    control,
    name: 'planDetails',
  });

  const watchedPlanDetails = watch('planDetails', initialValues['planDetails']);
  let quoteName = '';

  let updatedPlanDetails: any[] = null;

  let planDetailsProducts: any[] = null;
  let prevPlanDetailsProducts: React.MutableRefObject<any[]> =
    useRef(planDetailsProducts);

  const handlePlanDetailsChange = () => {
    if (
      planDetailsProducts &&
      typeof planDetailsProducts === 'object' &&
      Object.keys(planDetailsProducts).length > 0 &&
      (prevPlanDetailsProducts.current === null ||
        (prevPlanDetailsProducts.current != null &&
          (Object.keys(planDetailsProducts).length !==
            Object.keys(prevPlanDetailsProducts.current).length ||
            Object.keys(prevPlanDetailsProducts.current).some(
              (product, index) => product !== planDetailsProducts[index]
            ))))
    ) {
      let productsCounts: Record<string, number> = {};
      let unknownProductsCount = 0;

      if (lovs && planDetailsFieldArray) {
        const newFieldArrayValues = planDetailsFieldArray.fields.map((f) => {
          if (f.product) {
            productsCounts = {
              ...productsCounts,
              [f.product]: (productsCounts[f.product] || 0) + 1,
            };
          } else {
            unknownProductsCount += 1;
          }
          const productsCountSum = Object.values(productsCounts).reduce(
            (acc, curr) => acc + curr,
            0
          );
          if (f.product) {
            const productKey = Object.keys(lovs.products).find(
              (p) => p === f.product
            );
            const productLabel = lovs.products[productKey];
            quoteName = `${productLabel} ${productsCounts[f.product]}`;
          } else {
            quoteName = `Quote ${productsCountSum + unknownProductsCount}`;
          }
          return {
            ...f,
            name: quoteName,
            id: undefined,
          };
        });
        replace(newFieldArrayValues);
      }
      prevPlanDetailsProducts.current = planDetailsProducts;
    }
  };

  const [planDetailsProductCounter, setPlanDetailsProductCounter] =
    useState<IPlanDetailsProductsCounter>(initValuesPlanDetailsProductsCounter);

  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [formDisabled, setFormDisabled] = useState(false);

  const user = useAppSelector((state) => state.user);
  const entityId = params.id;
  //#endregion

  let today = new Date();
  let threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  const userAgentType = user.info.agentType;

  //#region graphql hooks
  const [getApplicationDetailsLazy, entityResult] = useLazyQuery(
    getApplicationDetails(),
    {
      variables: { id: entityId },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    }
  );

  const [acceptAction] = useMutation(acceptQuotes(), {
    refetchQueries: [getApplicationDetails()],
  });

  const [sendQuoteAction] = useMutation(sendQuotes(), {
    refetchQueries: [getApplicationDetails()],
  });

  const [issuePolicyAction] = useMutation(issuePolicy(), {
    refetchQueries: [getApplicationDetails()],
  });

  const [downloadQuoteAction] = useMutation(downloadQuote(), {
    refetchQueries: [getApplicationDetails()],
  });

  const [previewPoliciesAction] = useMutation(previewPolicies(), {
    refetchQueries: [getApplicationDetails()],
  });

  const [checkPlateNumberHasActiveProductPolicyLazy] = useLazyQuery(
    checkPlateNumberHasActiveProductPolicy(),
    {}
  );

  const [getModelsByBrandsLazy, modelByBrandsQueryRes] = useLazyQuery(
    getModelsByBrands()
  );

  const [copyPolicyDetailsLazy] = useLazyQuery(getCopyPolicyDetailsQuery(), {});

  const [validateVignetteLazy] = useMutation(validateVignette(), {});

  const [mutateFunction] = useMutation(calculateAction(), {
    refetchQueries: [getApplicationDetails()],
    errorPolicy: 'all',
  });
  const [createAppMutateFunction] = useMutation(createActionAsAgent(), {
    refetchQueries: [getApplicationDetails()],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
  });

  const getPlanDetailsProductsCounter = (planDetails: any) => {
    if (planDetails !== null) {
      let newPlandetailsProductCounter = { ...planDetailsProductCounter };
      planDetails.forEach((planDetail: any) => {
        newPlandetailsProductCounter = {
          ...newPlandetailsProductCounter,
          [planDetail['product']]:
            newPlandetailsProductCounter[
              planDetail[
                'product'
              ] as keyof typeof initValuesPlanDetailsProductsCounter
            ] + 1,
        };
        setPlanDetailsProductCounter(newPlandetailsProductCounter);
      });
    }
  };

  const isClosed =
    entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.Status.toLowerCase() ===
    'closed';
  const isNew =
    entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.Status.toLowerCase() ===
    'new';
  const isEnabled =
    entityResult?.data?.Insurance?.entities?.application?.actions
      ?.able_To_SimulateAndSaveMotor;
  const buttonLabel = 'Calculate';
  const buttonType = Object.keys(errors).length === 0 ? 'submit' : 'button';

  //#endregion

  //#region useEffect

  useEffect(() => {
    getApplicationDetailsLazy();
    reset({
      ...initialValues,
    });
  }, [entityId]);

  useEffect(() => {
    try {
      dirtyChange.current = 0;
      if (!entityResult.loading) {
        setLoadingState(false);
      }

      if (entityResult?.data) {
        const extractedLovs = extractLovs(entityResult?.data);

        if (
          user.userRoles.includes('Insurance-QuotingAgent') ||
          user.userRoles.includes('Insurance-IssuingAgent')
        ) {
          extractedLovs.products = filterProductsByAgentProductsLOVS(
            extractedLovs.products,
            user.info.products
          );
        }

        setLovs(extractedLovs);
        const applicationDetails = entityToApplicationDetails(
          entityResult?.data
        );

        applicationDetails.createdOn = getDefaultDate(
          dayjs(
            applicationDetails.createdOn
              ? new Date(applicationDetails.createdOn)
              : new Date()
          ).toDate()
        ).toDateString();

        applicationDetails.effectiveDate = getDefaultDate(
          dayjs(
            applicationDetails.effectiveDate
              ? new Date(applicationDetails.effectiveDate)
              : new Date()
          ).toDate()
        ).toDateString();

        if (applicationDetails.expiryDate) {
          applicationDetails.expiryDate = getDefaultDate(
            dayjs(new Date(applicationDetails.expiryDate)).toDate()
          ).toDateString();
        } else {
          applicationDetails.expiryDate = getDefaultDate(
            dayjs(new Date()).add(1, 'y').toDate()
          ).toDateString();
        }

        //get application section properties
        const applicationProperties = entityView.sections[0];
        applicationProperties.properties.stateReason.value =
          capitalizeFirstLetter(
            applicationDetails?.statusReason?.replaceAll('_', ' ')
          );
        applicationProperties.properties.lineOfBusiness.value =
          applicationDetails.lineOfBusiness;
        applicationProperties.properties.agency.value =
          applicationDetails.agencyName;
        applicationProperties.properties.agentName.value =
          applicationDetails.agentName;
        applicationProperties.properties.customerName.value =
          applicationDetails.customerName;

        setLob(applicationDetails.lineOfBusiness);

        //get the system propeties section
        const systemProperties = entityView.sections[1];
        systemProperties.properties.createdBy.value =
          applicationDetails.createdBy;
        //before setting date in date picker, use dayjs to format the datetime in case the value is coming from BE
        systemProperties.properties.createdOn.value = dayjs(
          new Date(applicationDetails.createdOn)
        ).format('DD/MM/YYYY');
        systemProperties.properties.modifiedBy.value =
          applicationDetails.modifiedBy;
        systemProperties.properties.modifiedOn.value = dayjs(
          new Date(applicationDetails.modifiedOn)
        ).format('DD/MM/YYYY');

        const newLeftEntityView = {
          ...entityView,
          sections: [applicationProperties, systemProperties],
        };

        if (applicationDetails.planDetails?.length == 0) {
          applicationDetails.planDetails = initialValues.planDetails;
        }

        if (isEmpty(applicationDetails.engineType)) {
          applicationDetails.engineType = 'Fuel';
        }

        setApplicationInfo(applicationDetails);
        if (applicationDetails.planDetails?.length !== 0) {
          getPlanDetailsProductsCounter(applicationDetails.planDetails);
        }

        setUpdatedLeftSideInfo(newLeftEntityView);

        if (applicationDetails.brand) {
          getModelsByBrandsLazy({
            variables: { modelId: applicationDetails.brand },
          });
        }

        let agencyRepairList: Record<string, string>[] = [];
        let extractedPlanDetailsKeys = cloneDeep(planDetailsKeysStatus);

        applicationDetails.planDetails.forEach(
          (planDetail: Record<string, string | number>, index: number) => {
            extractedPlanDetailsKeys[index] = {};
            if (!isEmpty(planDetail.includeAgencyRepair)) {
              extractedPlanDetailsKeys[index].coverageType = true;
            } else {
              extractedPlanDetailsKeys[index].coverageType = false;
            }

            agencyRepairList.push(
              adjustAgencyRepair(
                planDetail?.carValue as number,
                extractedLovs['agencyRepairs']
              )
            );
          }
        );

        setPlanDetailsKeysStatus(extractedPlanDetailsKeys);
        setAgencyRepairs(agencyRepairList);
        reset({
          ...applicationDetails,
          mobileNumber: applicationDetails.mobileNumber
            ? applicationDetails.mobileNumber
            : countryCode.current,
        });

        const usageType =
          getValues('usageType') || applicationDetails.usageType;
        const bodyType = getValues('bodyType') || applicationDetails.bodyType;
        const plateCode =
          getValues('plateCode') || applicationDetails.plateCode;

        setBodyTypes(filterBodyTypeLOVs(usageType, extractedLovs?.bodyTypes));

        if (!isEmpty(bodyType)) {
          setTowingService(
            filterTowingServiceLOVs(bodyType, extractedLovs?.towingServices)
          );
        }

        setUsageTypes(
          filterUsageTypeLOVs(plateCode, extractedLovs?.usageTypes)
        );

        const isUnderwriteer = user.userRoles.includes('Insurance-Underwriter');
        const isQuotiing = user.userRoles.includes('Insurance-QuotingAgent');
        const isIssuiing = user.userRoles.includes('Insurance-IssuingAgent');
        setIsUnderwriter(isUnderwriteer);
        setIsQuotingAgent(isQuotiing);
        setIsIssuingAgent(isIssuiing);

        setAgentType(
          isUnderwriteer
            ? 'underwriter'
            : isQuotiing || isIssuiing
            ? 'agent'
            : ''
        );

        setFormDisabled(
          entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.Status.toLowerCase() ==
            'closed' || !(isUnderwriteer || isQuotiing || isIssuiing)
        );
      }
    } catch (error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  }, [entityResult?.data]);

  useEffect(() => {
    if (modelByBrandsQueryRes.loading) setLoadingModelsState(true);
    if (modelByBrandsQueryRes.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (modelByBrandsQueryRes.data) {
      const updatedDropDownLists = cloneDeep(lovs);
      updatedDropDownLists.models = lookupListAsRecordObject(
        modelByBrandsQueryRes.data?.Insurance?.lookups?.models
      );

      setLovs(updatedDropDownLists);
      setLoadingModelsState(false);
    }
  }, [modelByBrandsQueryRes]);

  //#endregion

  //#region functions

  const onSubmit: SubmitHandler<IApplicationDetails> = (data) => {
    if (isEnabled && buttonLabel === 'Calculate' && !isClosed) {
      setFormDisabled(true);
      setSubmitButtonState('loading');

      data.planDetails.forEach((item, index) => {
        if (!item.hasOwnProperty('name')) {
          item.name = planDetailsFieldArray.fields[index].name;
          item.product = planDetailsFieldArray.fields[index].product;
        }
        if ('id' in item) {
          delete item['id'];
        }
      });

      let planInputs = data.planDetails;

      for (const planInput of planInputs) {
        planInput.carValue = Number(planInput.carValue);
        planInput.towingService = isVisiblePlanDetailsField(
          planInput.product.toString(),
          'towingService'
        )
          ? planInput.towingService
          : null;
        planInput.vignetteCode = isVisiblePlanDetailsField(
          planInput.product.toString(),
          'vignette'
        )
          ? planInput.vignetteCode
          : null;
        planInput.vignette = isVisiblePlanDetailsField(
          planInput.product.toString(),
          'vignette'
        )
          ? Number(planInput.vignette)
          : null;
        planInput.coverageType = isVisiblePlanDetailsField(
          planInput.product.toString(),
          'coverageType'
        )
          ? planInput.coverageType
          : null;
        planInput.carValue = isVisiblePlanDetailsField(
          planInput.product.toString(),
          'carValue'
        )
          ? planInput.carValue
          : null;

        planInput.includeAgencyRepair = isVisiblePlanDetailsField(
          planInput.product.toString(),
          'includeAgencyRepair'
        )
          ? planInput.includeAgencyRepair
          : null;
      }

      mutateFunction({
        variables: {
          entityId: entityId,
          effectiveDate: dateStringToISOString(data.effectiveDate),
          isRenewal: data.isRenewal,
          renewalNumber: data.renewalNumber,
          vehicleInputs: {
            plateCode: !isEmpty(data.plateCode) ? data.plateCode : undefined,
            plateNumber: !isEmpty(data.plateNumber)
              ? `${data.plateNumber}`
              : undefined,
            usageType: data.usageType,
            bodyType: data.bodyType,
            engineType: data.engineType,
            brand: data.brand,
            model: data.model,
            yearOfMake: parseInt(data.yearOfMake as unknown as string),
            motor: data.motor,
            weight: parseFloat(data.weight as unknown as string),
            engineSize: parseFloat(data.engineSize as unknown as string),
            seats: parseInt(data.seats as unknown as string),
            chassis: data.chassis,
            horsePower: parseFloat(data.horsePower as unknown as string),
            nameOnLicenseEn: data.nameOnLicenseEn,
            nameOnLicenseAr: data.nameOnLicenseAr,
          },
          applicationContactInputs: {
            firstName: data.firstName,
            middleName: data.middleName,
            lastName: data.lastName,
            fullName:
              data.firstName + ' ' + data.middleName + ' ' + data.lastName,
            email: data.email || null,
            mobileNumber:
              data.mobileNumber && data.mobileNumber.length > 3
                ? data.mobileNumber.charAt(0) == '+'
                  ? data.mobileNumber
                  : '+' + data.mobileNumber
                : undefined,
            country: data.country,
            city: data.city,
            street: data.street || null,
            building: data.building || null,
            title: data.title,
            phoneType: data.phoneType,
          },
          plansInputs: planInputs,
        },
      })
        .then((res) => {
          if (isEmpty(res.errors)) {
            setSubmitButtonState('success');
            toast.success(<ToastSuccessMessage>Success</ToastSuccessMessage>);
          } else {
            setSubmitButtonState(undefined);
            toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
          }
        })
        .finally(() => {
          setSubmitButtonState(undefined);
          setFormDisabled(false);
        });
    }
  };

  const checkSubmission = () => {
    if (isEnabled && isClosed) {
      if ((isIssuingAgent || isQuotingAgent) && !isUnderwriter) {
        user.info.agencyId.length === 0 || user.info.agencyId.length === 1
          ? submitFormAsAgent()
          : setNewApplicationWithMultipleAgencieDrawerOpen(true);

        setNewButtonState(undefined);
      } else if (isUnderwriter && !(isIssuingAgent || isQuotingAgent)) {
        user.info.agencyId.length === 0 || user.info.agencyId.length === 1
          ? setNewAppDrawerOpen(true)
          : setNewApplicationWithMultipleAgencieDrawerOpen(true);

        setNewButtonState(undefined);
      }
    }

    reset({
      ...initialValues,
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      city: '',
      street: '',
      building: '',
    });

    resetPartialForm();

    reset(initialValues);
    setValue('title', '');
    setValue('firstName', '');
    setValue('middleName', '');
    setValue('lastName', '');
    setValue('email', '');
    setValue('mobileNumber', '');
    setValue('city', '');
    setValue('street', '');
    setValue('building', '');

    setValue('yearOfMake', initialValues.yearOfMake);
    setValue('seats', initialValues.seats);
    setValue('horsePower', initialValues.horsePower);
  };

  const resetPartialForm = () => {
    reset({
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      city: '',
      street: '',
      building: '',

      yearOfMake: initialValues.yearOfMake,
      seats: initialValues.seats,
      horsePower: initialValues.horsePower,
    });
  };

  const submitFormAsAgent = async () => {
    try {
      const { data, errors } = await createAppMutateFunction({
        variables: { lineOfBusiness: 'Motor' },
      });
      if (isEmpty(errors)) {
        setNewButtonState('success');
        toast.success(
          <ToastSuccessMessage>
            {'Application created successfully'}
          </ToastSuccessMessage>
        );
        const newApplicationId =
          data.insurance.actions.createApplicationAsAgent.id;
        setTimeout(() => {
          navigate('/sales/applications/motor/' + newApplicationId);
        }, 500);
      } else {
        toast.error(<ToastErrorMessage>{getError(data)}</ToastErrorMessage>);
      }
    } catch {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setNewButtonState(undefined);
    }
  };

  const checkTowingServiceValue = (towingServices: Record<string, string>) => {
    const planDetails = planDetailsFieldArray.fields;

    planDetails.forEach((planDetail) => {
      if (!towingServices[planDetail['towingService']]) {
        planDetail['towingService'] = '';
      }
    });

    setValue('planDetails', planDetails);
  };

  const reviewTowingService = () => {
    if (!isEmpty(getValues('bodyType'))) {
      const towingServiceList = filterTowingServiceLOVs(
        getValues('bodyType'),
        lovs.towingServices
      );
      setTowingService(towingServiceList);
      checkTowingServiceValue(towingServiceList);
    }
  };

  const autoSetNameOnLicenseAr = (enTxt: string) => {
    setLoadingNameOnLicenseArState(true);
    DataService.get(`/api/prx/transliterate.qcri.org/en2ar/` + enTxt)
      .then(async (response) => {
        if (response.ok) {
          const result: { results: string } = await response.json();
          setValue('nameOnLicenseAr', result.results);
        }
      })
      .catch(() => {
        toast.error(
          <ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>
        );
        setValue('nameOnLicenseAr', enTxt);
      })
      .finally(() => {
        setLoadingNameOnLicenseArState(false);
        trigger('nameOnLicenseAr' as keyof typeof initialValues);
      });
  };

  const autoSetNameOnLicenseEn = (fnameTxt?: string, lnameTxt?: string) => {
    setLoadingNameOnLicenseEnState(true);
    setValue('nameOnLicenseEn', `${fnameTxt} ${lnameTxt}`);
    setLoadingNameOnLicenseEnState(false);

    autoSetNameOnLicenseAr(`${fnameTxt} ${lnameTxt}`);
  };

  const validatePlateNumberOnProductChange = (
    product: string,
    lineIndex: number
  ) => {
    if (
      !isEmpty(getValues('chassis')) &&
      !isEmpty(getValues('effectiveDate')) &&
      !isEmpty(product)
    ) {
      checkPlateNumberHasActiveProductPolicyLazy({
        variables: {
          plateCode: !isEmpty(getValues('plateCode'))
            ? (getValues('plateCode') as unknown as string)
            : undefined,
          plateNumber: !isEmpty(getValues('plateNumber'))
            ? (getValues('plateNumber') as unknown as string)
            : undefined,
          effectiveDate: dayjs(new Date(getValues('effectiveDate'))).format(
            'YYYY-MM-DD'
          ),
          product: product,
          chassis: getValues('chassis'),
        },
      }).then((response) => {
        if (
          response.data.Insurance?.queries
            ?.checkPlateNumberHasActiveProductPolicy.length > 0
        ) {
          const plateNumberAlreadyExistsErrorMessage = `Current ${
            response.data?.Insurance?.queries
              ?.checkPlateNumberHasActiveProductPolicy[0]
              ?.insurancePolicyMotorDetails_Product.Title
          } 
                policy exists for this plate number with Policy Number ${
                  response.data?.Insurance?.queries
                    ?.checkPlateNumberHasActiveProductPolicy[0]
                    ?.insurance_Policy_FullPolicyNumber
                } that expires on ${dayjs(
            new Date(
              response.data?.Insurance?.queries?.checkPlateNumberHasActiveProductPolicy[0]?.insurance_Policy_PolicyExpiryDate
            )
          ).format('DD/MM/YYYY')}`;
          setError(
            `planDetails.${lineIndex}.product` as keyof typeof initialValues,
            {
              type: 'custom',
              message: plateNumberAlreadyExistsErrorMessage,
            }
          );
        } else {
          clearErrors(
            `planDetails.${lineIndex}.product` as keyof typeof initialValues
          );
        }
      });
    }
  };

  const validateVignetteOnChange = (lineIndex: number) => {
    if (
      !isEmpty(getValues(`planDetails.${lineIndex}.vignetteCode`)) &&
      !isEmpty(getValues(`planDetails.${lineIndex}.vignette`))
    ) {
      validateVignetteLazy({
        variables: {
          entityId: params.id,
          effectiveDate: dayjs(new Date(getValues('effectiveDate'))).format(
            'YYYY-MM-DD'
          ),
          vignetteCode: getValues(`planDetails.${lineIndex}.vignetteCode`),
          vignette: Number(getValues(`planDetails.${lineIndex}.vignette`)),
        },
      })
        .then((response) => {
          if (
            response.data.Insurance?.queries
              ?.checkDuplicateVignetteHasActivePolicy.length > 0
          ) {
            const vignetteAlreadyExistsErrorMessage = 'Vignette already in use';

            setError(
              `planDetails.${lineIndex}.vignette` as keyof typeof initialValues,
              {
                type: 'custom',
                message: vignetteAlreadyExistsErrorMessage,
              }
            );
          } else {
            clearErrors(
              `planDetails.${lineIndex}.vignette` as keyof typeof initialValues
            );
          }
        })
        .catch((error) => {
          if (
            error instanceof ApolloError &&
            error.message === 'Vignette Number is not assigned to you'
          ) {
            const vignetteAlreadyExistsErrorMessage = error.message;

            setError(
              `planDetails.${lineIndex}.vignette` as keyof typeof initialValues,
              {
                type: 'custom',
                message: vignetteAlreadyExistsErrorMessage,
              }
            );
          } else if (
            error instanceof ApolloError &&
            error.message === 'Vignette already in use'
          ) {
            const vignetteAlreadyExistsErrorMessage = error.message;

            setError(
              `planDetails.${lineIndex}.vignette` as keyof typeof initialValues,
              {
                type: 'custom',
                message: vignetteAlreadyExistsErrorMessage,
              }
            );
          } else {
            clearErrors(
              `planDetails.${lineIndex}.vignette` as keyof typeof initialValues
            );
          }
        });
    }
  };

  const getCopyPolicyDetails = (policyNumber: string) => {
    copyPolicyDetailsLazy({
      variables: {
        applicationId: entityId,
        enteredPolicyNumber: policyNumber,
      },
    })
      .then((result) => {
        const getPolicyByNumber =
          result?.data?.Insurance?.queries?.getPolicyByNumber;
        if (getPolicyByNumber.length === 0) {
          toast.error(
            <ToastErrorMessage>
              Policy Number does not exist in the system.
            </ToastErrorMessage>
          );
        } else {
          const policyData = getPolicyByNumber[0];
          setValue(
            'nameOnLicenseEn',
            policyData.insurancePolicyMotorDetails_NameOnLicenseEn
          );
          setValue(
            'nameOnLicenseAr',
            policyData.insurancePolicyMotorDetails_NameOnLicenseAr
          );
          setValue('email', policyData.insurancePolicyHolder_Email);
          setValue(
            'mobileNumber',
            policyData.insurancePolicyHolder_MobileNumber
          );
          setValue(
            'country',
            policyData.insurancePolicyHolder_Address.Country?.Code
          );
          setValue('city', policyData.insurancePolicyHolder_Address?.City);
          setValue('street', policyData.insurancePolicyHolder_Address?.Street);
          setValue(
            'building',
            policyData.insurancePolicyHolder_Address?.Building
          );
          setValue('title', policyData.insurancePolicyHolder_Title?.Code);
          setValue('fullName', policyData.insurancePolicyHolder_FullName);
          setValue('firstName', policyData.insurancePolicyHolder_FirstName);
          setValue('middleName', policyData.insurancePolicyHolder_MiddleName);
          setValue('lastName', policyData.insurancePolicyHolder_LastName);
          setValue('phoneType', policyData.insurancePolicyHolder_PhoneType);
          setValue(
            'plateCode',
            policyData.insurancePolicyMotorDetails_PlateCode?.Code
          );
          setValue(
            'plateNumber',
            policyData.insurancePolicyMotorDetails_PlateNumber
          );
          setValue(
            'usageType',
            policyData.insurancePolicyMotorDetails_UsageType?.Code
          );

          const bodyTypesList = filterBodyTypeLOVs(
            policyData.insurancePolicyMotorDetails_UsageType?.Code,
            lovs.bodyTypes
          );
          setBodyTypes(bodyTypesList);

          setValue(
            'bodyType',
            policyData.insurancePolicyMotorDetails_BodyType?.Code
          );
          setValue(
            'engineType',
            policyData.insurancePolicyMotorDetails_EngineType?.Code
          );
          setValue('brand', policyData.insurancePolicyMotorDetails_Brand?.Code);

          getModelsByBrandsLazy({
            variables: {
              modelId: policyData.insurancePolicyMotorDetails_Brand?.Code,
            },
          });

          setValue('model', policyData.insurancePolicyMotorDetails_Model?.Code);
          setValue(
            'yearOfMake',
            policyData.insurancePolicyMotorDetails_YearOfMake
          );
          setValue('motor', policyData.insurancePolicyMotorDetails_Motor);
          setValue('weight', policyData.insurancePolicyMotorDetails_Weight);
          setValue(
            'engineSize',
            policyData.insurancePolicyMotorDetails_EngineSize
          );
          setValue('seats', policyData.insurancePolicyMotorDetails_Seats);
          setValue('chassis', policyData.insurancePolicyMotorDetails_Chassis);
          setValue(
            'horsePower',
            policyData.insurancePolicyMotorDetails_HorsePower
          );
        }
      })
      .catch((error) => {
        toast.error(<ToastErrorMessage>{error}</ToastErrorMessage>);
      });
  };

  const adjustUsageTypesListAndReview = () => {
    const usageTypes = filterUsageTypeLOVs(
      getValues('plateCode'),
      lovs.usageTypes
    );

    if (!usageTypes[getValues('usageType')]) {
      setValue('usageType', '');
    }

    return usageTypes;
  };

  const renderMainChildren = () => {
    let applicationStatus =
      entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.Status.toLowerCase();

    let quotes: quote[] = [];
    entityResult?.data?.Insurance?.entities?.application?.views?.Insurance_all?.properties?.MotorDetailsID?.queries?.insurance?.plans?.map(
      (plan: any, i: number) => {
        let quoteEntity = graphqlEntityToQuoteDetails(
          entityResult?.data,
          i,
          tenant.currencySymbol,
          lob
        );
        quotes.push(quoteEntity);
      }
    );

    return (
      <>
        <div style={{ marginTop: '20px' }}>
          <EnhancedStepper
            showStepperButton={isClosed}
            buttonTitle={'New'}
            buttonState={newButtonState}
            activeStep={getApplicationStatus(
              entityResult?.data?.Insurance?.entities?.application?.views
                ?.Insurance_all?.properties?.Status
            )}
            steps={steps}
            reverseButton={true}
            className={classes.stepper}
            buttonOnClick={() => {
              setNewButtonState('loading');
              checkSubmission();
            }}
          />
          {applicationStatus != 'new' && (
            <>
              <QuotesCards
                entityId={entityId}
                applicationStatus={applicationStatus}
                quotes={quotes}
                sendQuoteAction={sendQuoteAction}
                acceptAction={acceptAction}
                issuePolicyAction={issuePolicyAction}
                downloadQuoteAction={downloadQuoteAction}
                previewPoliciesAction={previewPoliciesAction}
                customerEmail={getValues('email') || null}
                disabled={formDisabled}
                userCanSend={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_SendQuotes
                }
                disabledSendButton={disabledSendBtn}
                disabledAcceptButton={disabledAcceptBtn}
                disabledIssuePolicyButton={disabledIssuePolicyBtn}
                userCanAccept={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_AcceptQuotes
                }
                userCanIssue={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_IssuePolicy
                }
                userCanDownloadQuote={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_PreviewQuotes
                }
                userCanPreviewPolicies={
                  entityResult?.data?.Insurance?.entities?.application?.actions
                    ?.able_To_PreviewPolicies
                }
                lineOfBusiness={lob}
                quoteInputs={{
                  applicationContactInputs: {
                    building: getValues('building'),
                    city: getValues('city'),
                    country: getValues('country'),
                    email: getValues('email'),
                    fullName:
                      getValues('firstName') +
                      ' ' +
                      getValues('middleName') +
                      ' ' +
                      getValues('lastName'),
                    mobileNumber: getValues('mobileNumber'),
                    street: getValues('street'),
                    title: getValues('title'),
                  },
                }}
              />
              <Separator className={classes.thickSeperator} />
            </>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <WidgetPaper>
              <WidgetSection
                title='Application Details'
                narrowSpacing={true}
                style={{ margin: '-20px 0' }}
                classes={{ container: classes.section }}
                actions={
                  <>
                    {!isClosed && (
                      <ShowForUser allowedRoles={['Insurance-IssuingAgent']}>
                        <EnhancedButton
                          type='button'
                          backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                          color='#FFF'
                          style={{ display: 'inline', margin: '0 0 -15px 5px' }}
                          onClick={() => {
                            setCopyPolicyDetailsDrawerOpen(true);
                          }}
                        >
                          Copy Policy Details
                        </EnhancedButton>
                      </ShowForUser>
                    )}
                    {!isClosed && isEnabled && (
                      <EnhancedButton
                        type={buttonType}
                        state={submitButtonState}
                        backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                        color='#FFF'
                        style={{ display: 'inline', margin: '0 0 -15px 5px' }}
                      >
                        {buttonLabel}
                      </EnhancedButton>
                    )}
                  </>
                }
                middle={
                  isDirty &&
                  displayDirtyMessage.current > 0 &&
                  !isNew &&
                  !isClosed ? (
                    <Chip
                      label={
                        'Form fields changed, please re-calculate to update the application accordingly'
                      }
                      className={classes.dirtyChip}
                    />
                  ) : (
                    <></>
                  )
                }
                useSeparator={false}
              >
                <Controller
                  name='effectiveDate'
                  control={control}
                  rules={{
                    required: 'Required',
                    validate: (value) => checkDate(value) || 'Invalid Date',
                  }}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <EnhancedDatePicker
                      name={'effectiveDate'}
                      onBlur={onBlur}
                      onDateChange={(e: any) => {
                        onChange(e);
                        trigger('effectiveDate' as keyof typeof initialValues);

                        const selectedDate = dayjs(e);
                        const effectiveDate = selectedDate
                          .toDate()
                          .toDateString();
                        let expiryDate;

                        if (selectedDate.month() === 11) {
                          // If the selected month is December, set expiryDate to the last day of December
                          expiryDate = selectedDate
                            .endOf('month')
                            .toDate()
                            .toDateString();
                        } else {
                          // Otherwise, set expiryDate to one year later
                          expiryDate = selectedDate
                            .add(1, 'y')
                            .toDate()
                            .toDateString();
                        }

                        setValue('effectiveDate', effectiveDate);
                        setValue('expiryDate', expiryDate);

                        planDetailsFieldArray.fields.forEach(
                          (data, rowIndex) => {
                            validatePlateNumberOnProductChange(
                              data.product as string,
                              rowIndex
                            );
                          }
                        );
                      }}
                      title='Effective Date*'
                      material
                      format={tenant.dateFormat}
                      value={
                        !isEmpty(value) ? value : initialValues.effectiveDate
                      }
                      disabled={formDisabled}
                      className={classes.field}
                      error={errors?.effectiveDate?.message}
                      minDate={
                        userAgentType === 'INTERNAL'
                          ? threeMonthsAgo
                          : new Date()
                      }
                      maxDate={
                        dayjs().month() === 11
                          ? dayjs().endOf('month').toDate()
                          : dayjs().add(1, 'month').toDate()
                      }
                    />
                  )}
                />
                <Controller
                  name='expiryDate'
                  control={control}
                  render={({ field: { onBlur, onChange, value } }) => (
                    <EnhancedDatePicker
                      name={'expiryDate'}
                      onBlur={onBlur}
                      onDateChange={(e) => {}}
                      title='Expiry Date*'
                      material
                      disabled
                      format={tenant.dateFormat}
                      value={value}
                      className={classes.field}
                    />
                  )}
                />
                <Controller
                  name={'isRenewal'}
                  control={control}
                  rules={{ required: 'required' }}
                  key={'isRenewal'}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedChipInput
                      name={'isRenewal'}
                      title='Renewal*'
                      value={value || ''}
                      onChange={(e) => {
                        const newValue = e as string;
                        onChange(newValue);
                        trigger(`isRenewal` as keyof typeof initialValues);
                      }}
                      onBlur={(e) => {
                        onBlur();
                        trigger('isRenewal' as keyof typeof initialValues);
                      }}
                      selectOptions={lovs?.renewalStatus}
                      material
                      error={errors?.isRenewal?.message}
                      multiple={false}
                      disabled={formDisabled}
                      className={classes.field}
                    />
                  )}
                />
                {getValues('isRenewal') == 'YES' && (
                  <Controller
                    name='renewalNumber'
                    control={control}
                    rules={{
                      required: {
                        value: getValues('isRenewal') == 'YES',
                        message: 'Required',
                      },
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <EnhancedInput
                        type='text'
                        name='renewalNumber'
                        title='Renewal Number*'
                        onChange={(e) => {
                          trigger(
                            'renewalNumber' as keyof typeof initialValues
                          );
                          onChange(e);
                        }}
                        onBlur={(e) => {
                          trigger(
                            'renewalNumber' as keyof typeof initialValues
                          );
                          onBlur();
                          if (isEmpty(getValues('renewalNumber'))) {
                            autoSetNameOnLicenseAr(e.target.value);
                          }
                        }}
                        value={!isEmpty(value) ? value : ''}
                        error={errors?.renewalNumber?.message}
                        className={classes.field}
                        material
                        disabled={formDisabled}
                      />
                    )}
                  />
                )}
              </WidgetSection>
              {lovs != null && (
                <>
                  <WidgetSection
                    title='Vehicle Details'
                    narrowSpacing={true}
                    classes={{ container: classes.section }}
                    useSeparator={false}
                    style={{ margin: '-4px 0' }}
                  >
                    <Controller
                      name={'plateCode'}
                      control={control}
                      key={'plateCode'}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedChipInput
                          name={'plateCode'}
                          title='Plate Code'
                          placeholder={'Plate Code'}
                          value={value || ''}
                          onChange={(e) => {
                            const newValue = e as string;
                            onChange(newValue);
                            trigger(`plateCode` as keyof typeof initialValues);
                            planDetailsFieldArray.fields.forEach(
                              (data, rowIndex) => {
                                validatePlateNumberOnProductChange(
                                  data.product as string,
                                  rowIndex
                                );
                              }
                            );

                            setUsageTypes(adjustUsageTypesListAndReview());
                          }}
                          onBlur={(e) => {
                            onBlur();
                            trigger('plateCode' as keyof typeof initialValues);
                          }}
                          selectOptions={lovs?.plateCodes}
                          material
                          error={errors?.plateCode?.message}
                          multiple={false}
                          disabled={formDisabled}
                          className={classes.field}
                        />
                      )}
                    />
                    <Controller
                      name={'plateNumber'}
                      control={control}
                      rules={{
                        pattern: {
                          value: /^\d+$/,
                          message: 'plate number must contains numbers only',
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedInput
                          type='text'
                          name={'plateNumber'}
                          title='Plate Number'
                          placeholder={'Plate Number'}
                          value={!isEmpty(value) ? value : ''}
                          onChange={(e) => {
                            onChange(e);
                            trigger(
                              `plateNumber` as keyof typeof initialValues
                            );
                            planDetailsFieldArray.fields.forEach(
                              (data, rowIndex) => {
                                validatePlateNumberOnProductChange(
                                  data.product as string,
                                  rowIndex
                                );
                              }
                            );
                          }}
                          onBlur={(e) => {
                            onBlur();
                            trigger(
                              'plateNumber' as keyof typeof initialValues
                            );
                          }}
                          material
                          error={errors?.plateNumber?.message}
                          disabled={formDisabled}
                          className={classes.field}
                        />
                      )}
                    />
                    <Controller
                      name={'usageType'}
                      control={control}
                      rules={{ required: 'required' }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedChipInput
                          name={'usageType'}
                          title='Usage*'
                          placeholder={'Usage'}
                          onChange={(e) => {
                            const newValue = e as string;
                            onChange(newValue);
                            trigger(`usageType` as keyof typeof initialValues);
                            const bodyTypesList = filterBodyTypeLOVs(
                              newValue,
                              lovs.bodyTypes
                            );
                            setBodyTypes(bodyTypesList);
                            const currentBodyType = getValues('bodyType');

                            if (!bodyTypesList[currentBodyType]) {
                              setValue('bodyType', '');
                            }
                          }}
                          multiple={false}
                          onBlur={(e) => {
                            onBlur();
                            trigger('usageType' as keyof typeof initialValues);
                          }}
                          material
                          value={value || ''}
                          selectOptions={usageTypes}
                          error={errors?.usageType?.message}
                          disabled={formDisabled}
                          className={classes.field}
                        />
                      )}
                    />
                    <Controller
                      name={'bodyType'}
                      control={control}
                      rules={{ required: 'required' }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedChipInput
                          name={'bodyType'}
                          title='Body*'
                          placeholder={'Body'}
                          onChange={(e) => {
                            const newValue = e as string;
                            onChange(newValue);
                            trigger(`bodyType` as keyof typeof initialValues);
                            // Trigger validation for seating capacity when body type changes
                            !isEmpty(getValues('seats'))
                              ? trigger('seats' as keyof typeof initialValues)
                              : '';
                            reviewTowingService();
                          }}
                          onBlur={(e) => {
                            onBlur();
                            trigger('bodyType' as keyof typeof initialValues);
                          }}
                          selectOptions={bodyTypes}
                          material
                          value={value || ''}
                          error={errors?.bodyType?.message}
                          multiple={false}
                          disabled={
                            formDisabled ||
                            !bodyTypes ||
                            Object.keys(bodyTypes).length == 0
                          }
                          className={classes.field}
                        />
                      )}
                    />
                    <Controller
                      name={'engineType'}
                      control={control}
                      rules={{ required: 'required' }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedChipInput
                          name={'engineType'}
                          title='Engine Type*'
                          placeholder={'Engine Type'}
                          onChange={(e) => {
                            const newValue = e as string;
                            onChange(newValue);
                            trigger(`engineType` as keyof typeof initialValues);
                          }}
                          onBlur={(e) => {
                            onBlur();
                            trigger('engineType' as keyof typeof initialValues);
                          }}
                          material
                          value={value || ''}
                          selectOptions={lovs.engineTypes}
                          error={errors?.engineType?.message}
                          multiple={false}
                          disabled={formDisabled}
                          className={classes.field}
                        />
                      )}
                    />
                    <Controller
                      name={'brand'}
                      control={control}
                      rules={{ required: 'required' }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedChipInput
                          name={'brand'}
                          title='Make*'
                          placeholder={'Make'}
                          onChange={(e) => {
                            const brandValue = e as string;
                            onChange(brandValue);
                            trigger(`brand` as keyof typeof initialValues);
                            getModelsByBrandsLazy({
                              variables: { modelId: brandValue },
                            });
                          }}
                          onBlur={(e) => {
                            onBlur();
                            trigger('brand' as keyof typeof initialValues);
                          }}
                          material
                          value={value || ''}
                          error={errors?.brand?.message}
                          selectOptions={lovs.brands}
                          multiple={false}
                          disabled={formDisabled}
                          className={classes.field}
                        />
                      )}
                    />
                    <Controller
                      name={'model'}
                      control={control}
                      rules={{ required: 'required' }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedChipInput
                          name={'model'}
                          title='Model*'
                          placeholder={'Model'}
                          onChange={(e) => {
                            const newValue = e as string;
                            onChange(newValue);
                            trigger(`model` as keyof typeof initialValues);
                          }}
                          onBlur={(e) => {
                            onBlur();
                            trigger('model' as keyof typeof initialValues);
                          }}
                          material
                          selectOptions={lovs.models}
                          value={value || ''}
                          error={errors?.model?.message}
                          multiple={false}
                          disabled={formDisabled || isEmpty(getValues('brand'))}
                          loader={loadingModelsState}
                          className={classes.field}
                        />
                      )}
                    />
                    <Controller
                      name={'yearOfMake'}
                      control={control}
                      rules={{
                        required: 'required',
                        min: {
                          value: 1900,
                          message: 'Invalid Year Of Make',
                        },
                        max: {
                          value: 2100,
                          message: 'Invalid Year Of Make',
                        },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedNumberInput
                          name={'yearOfMake'}
                          title='Year of Make*'
                          placeholder={'Year of Make'}
                          onChange={(e) => {
                            onChange(e);
                            trigger(`yearOfMake` as keyof typeof initialValues);
                          }}
                          onBlur={(e) => {
                            onBlur();
                            trigger('yearOfMake' as keyof typeof initialValues);
                          }}
                          material
                          value={
                            !isEmpty(value) ? value : initialValues.yearOfMake
                          }
                          error={errors?.yearOfMake?.message}
                          disabled={formDisabled}
                          className={classes.field}
                          minValue={1}
                          maxValue={2100}
                          disableNegativeValues
                        />
                      )}
                    />
                    <ShowForUser
                      allowedRoles={[
                        'Insurance-IssuingAgent',
                        'Insurance-Underwriter',
                        'Insurance-Admin',
                      ]}
                    >
                      <Controller
                        name={'motor'}
                        control={control}
                        rules={{ required: 'required' }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedInput
                            name={'motor'}
                            type='text'
                            title='Engine*'
                            placeholder={'Engine'}
                            onChange={(e) => {
                              onChange(e);
                              trigger(`motor` as keyof typeof initialValues);
                            }}
                            onBlur={(e) => {
                              onBlur();
                              trigger('motor' as keyof typeof initialValues);
                            }}
                            material
                            value={
                              !isEmpty(value) ? value : initialValues.motor
                            }
                            error={errors?.motor?.message}
                            disabled={formDisabled}
                            className={classes.field}
                          />
                        )}
                      />
                    </ShowForUser>
                    {(getValues('bodyType') === 'VAN' ||
                      getValues('bodyType') === 'PICKUP' ||
                      getValues('bodyType') === 'F_TANKER') && (
                      <Controller
                        name={'weight'}
                        control={control}
                        rules={{
                          required: 'required',
                          min: {
                            value: 0,
                            message: 'Invalid Weight',
                          },
                          max: {
                            value: 99,
                            message: 'Invalid Weight',
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedNumberInput
                            name={'weight'}
                            title='Weight (in Tons)*'
                            placeholder={'Weight'}
                            onChange={(e) => {
                              onChange(e);
                              trigger(`weight` as keyof typeof initialValues);
                              reviewTowingService();
                            }}
                            onBlur={(e) => {
                              onBlur();
                              trigger('weight' as keyof typeof initialValues);
                            }}
                            material
                            value={
                              !isEmpty(value) ? value : initialValues.weight
                            }
                            error={errors?.weight?.message}
                            disabled={formDisabled}
                            className={classes.field}
                            disableNegativeValues
                          />
                        )}
                      />
                    )}
                    {getValues('bodyType') == 'MOTORCYCLE' && (
                      <Controller
                        name={'engineSize'}
                        control={control}
                        rules={{
                          required: 'required',
                          min: {
                            value: 1,
                            message: 'Invalid Engine Size',
                          },
                          max: {
                            value: 99999,
                            message: 'Invalid Engine Size',
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedNumberInput
                            name={'engineSize'}
                            title='Engine Size (in cc)*'
                            placeholder={'Engine Size'}
                            onChange={(e) => {
                              onChange(e);
                              trigger(
                                `engineSize` as keyof typeof initialValues
                              );
                            }}
                            onBlur={(e) => {
                              onBlur();
                              trigger(
                                'engineSize' as keyof typeof initialValues
                              );
                            }}
                            material
                            value={
                              !isEmpty(value) ? value : initialValues.engineSize
                            }
                            error={errors?.engineSize?.message}
                            disabled={formDisabled}
                            className={classes.field}
                            disableNegativeValues
                          />
                        )}
                      />
                    )}
                    <Controller
                      name={'seats'}
                      control={control}
                      rules={{
                        required: 'required',
                        min: {
                          value: 1,
                          message: 'Invalid Seats',
                        },
                        max: {
                          value: 99,
                          message: 'Invalid Seats',
                        },
                        // // validate: (value, { bodyType }) => {
                        // //   if (
                        // //     bodyType.toLowerCase() === "sedan" ||
                        // //     bodyType.toLowerCase() === "station"
                        // //   ) {
                        // //     if (value > 5) {
                        // //       return "Vehicle cannot have more than 5 seats";
                        // //     }
                        // //   }
                        // //   return true;
                        // // },
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <EnhancedNumberInput
                          name={'seats'}
                          title='Number of Seats*'
                          placeholder={'Number of Seats'}
                          onChange={(e) => {
                            onChange(e);
                            trigger(`seats` as keyof typeof initialValues);
                            reviewTowingService();
                          }}
                          onBlur={(e) => {
                            onBlur();
                            trigger('seats' as keyof typeof initialValues);
                          }}
                          material
                          value={!isEmpty(value) ? value : initialValues.seats}
                          error={errors?.seats?.message}
                          disabled={formDisabled}
                          className={classes.field}
                          disableNegativeValues
                        />
                      )}
                    />

                    <ShowForUser
                      allowedRoles={[
                        'Insurance-IssuingAgent',
                        'Insurance-Underwriter',
                        'Insurance-Admin',
                      ]}
                    >
                      <Controller
                        name={'chassis'}
                        control={control}
                        rules={{
                          required: 'required',
                          minLength: {
                            value: 5,
                            message:
                              'value must have between 5 and 20 characters',
                          },
                          maxLength: {
                            value: 20,
                            message:
                              'value must have between 5 and 20 characters',
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedInput
                            name={'chassis'}
                            type='text'
                            title='Chassis*'
                            placeholder={'Chassis'}
                            onChange={(e) => {
                              onChange(e);
                              trigger(`chassis` as keyof typeof initialValues);
                            }}
                            onBlur={(e) => {
                              onBlur();
                              trigger(`chassis` as keyof typeof initialValues);
                              planDetailsFieldArray.fields.forEach(
                                (data, rowIndex) => {
                                  validatePlateNumberOnProductChange(
                                    data.product as string,
                                    rowIndex
                                  );
                                }
                              );
                            }}
                            material
                            value={
                              !isEmpty(value) ? value : initialValues.chassis
                            }
                            error={errors?.chassis?.message}
                            disabled={formDisabled}
                            className={classes.field}
                          />
                        )}
                      />
                      <Controller
                        name={'horsePower'}
                        control={control}
                        rules={{ required: 'required' }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedNumberInput
                            name={'horsePower'}
                            title='Horse Power*'
                            placeholder={'Horse Power'}
                            onChange={(e) => {
                              onChange(e);
                              trigger(
                                `horsePower` as keyof typeof initialValues
                              );
                            }}
                            onBlur={(e) => {
                              onBlur();
                              trigger(
                                'horsePower' as keyof typeof initialValues
                              );
                            }}
                            material
                            value={
                              !isEmpty(value) ? value : initialValues.horsePower
                            }
                            error={errors?.horsePower?.message}
                            disabled={formDisabled}
                            className={classes.field}
                            disableNegativeValues
                          />
                        )}
                      />
                    </ShowForUser>
                  </WidgetSection>
                </>
              )}
              <WidgetSection
                title='Customer Details'
                narrowSpacing={true}
                classes={{
                  container: classes.section,
                }}
                useSeparator={false}
                style={{ margin: '-4px 0' }}
              >
                {lovs != null && (
                  <Controller
                    name='title'
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <EnhancedChipInput
                        name={`title`}
                        title='Title*'
                        placeholder={'Title'}
                        value={!isEmpty(value) ? value : ''}
                        onChange={(e) => {
                          onChange(e as string);
                          trigger(`title` as keyof typeof initialValues);
                        }}
                        onBlur={(e) => {
                          onBlur();
                          trigger('title' as keyof typeof initialValues);
                        }}
                        selectOptions={lovs?.titles}
                        error={
                          errors.title != undefined && !isEmpty(errors?.title)
                            ? errors?.title?.message
                            : null
                        }
                        multiple={false}
                        material
                        disabled={formDisabled}
                        className={classes.field}
                      />
                    )}
                  />
                )}

                <Controller
                  name='firstName'
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='firstName'
                      title='First Name*'
                      placeholder={'First Name'}
                      onChange={(e) => {
                        trigger('firstName' as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        if (!isEmpty(getValues('firstName'))) {
                          autoSetNameOnLicenseEn(
                            e.target.value,
                            getValues('lastName')
                          );
                        }
                        trigger('firstName' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.firstName?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
                <Controller
                  name='middleName'
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='middleName'
                      title='Middle Name*'
                      placeholder={'Middle Name'}
                      onChange={(e) => {
                        trigger('middleName' as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger('middleName' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.middleName?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
                <Controller
                  name='lastName'
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='lastName'
                      title='Last Name*'
                      placeholder={'Last Name'}
                      onChange={(e) => {
                        trigger('lastName' as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        if (!isEmpty(getValues('lastName'))) {
                          autoSetNameOnLicenseEn(
                            getValues('firstName'),
                            e.target.value
                          );
                        }
                        trigger('lastName' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.lastName?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />

                {true && (
                  <>
                    <ShowForUser
                      allowedRoles={[
                        'Insurance-IssuingAgent',
                        'Insurance-Underwriter',
                        'Insurance-Admin',
                      ]}
                    >
                      <Controller
                        name='nameOnLicenseEn'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedInput
                            type='text'
                            name='nameOnLicenseEn'
                            title='Name on License (EN)*'
                            placeholder={'Name on License (EN)'}
                            onChange={(e) => {
                              trigger(
                                'nameOnLicenseEn' as keyof typeof initialValues
                              );
                              onChange(e);
                            }}
                            onBlur={(e) => {
                              trigger(
                                'nameOnLicenseEn' as keyof typeof initialValues
                              );
                              onBlur();
                            }}
                            value={!isEmpty(value) ? value : ''}
                            error={errors?.nameOnLicenseEn?.message}
                            className={classes.field}
                            material
                            disabled={formDisabled}
                            loader={loadingNameOnLicenseEnState}
                          />
                        )}
                      />
                      <Controller
                        name='nameOnLicenseAr'
                        control={control}
                        rules={{ required: 'Required' }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <EnhancedInput
                            type='text'
                            name='nameOnLicenseAr'
                            title='Name on License (AR)*'
                            placeholder={'إسم وشهرة المالك'}
                            onChange={(e) => {
                              onChange(e);

                              trigger(
                                'nameOnLicenseAr' as keyof typeof initialValues
                              );
                            }}
                            onBlur={(e) => {
                              onBlur();
                              trigger(
                                'nameOnLicenseAr' as keyof typeof initialValues
                              );
                            }}
                            value={!isEmpty(value) ? value : ''}
                            error={errors?.nameOnLicenseAr?.message}
                            className={classes.arField}
                            material
                            disabled={formDisabled}
                            loader={loadingNameOnLicenseArState}
                            rtlContent
                          />
                        )}
                      />
                    </ShowForUser>
                  </>
                )}
                <Controller
                  name='mobileNumber'
                  control={control}
                  rules={{
                    validate: {
                      requried: (value) =>
                        checkPhoneNumber(value) || 'required',
                    },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInternationalPhoneInput
                      name={'mobileNumber'}
                      title={'Phone Number*'}
                      placeholder={'Phone Number'}
                      onChange={(e) => {
                        onChange(e);
                        trigger('mobileNumber' as keyof typeof initialValues);

                        if (isEmpty(e)) {
                          setValue('mobileNumber', undefined);
                        }
                      }}
                      onBlur={(e) => {
                        onBlur();

                        if (isEmpty(e)) {
                          setValue('mobileNumber', undefined);
                        }

                        trigger('mobileNumber' as keyof typeof initialValues);
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.mobileNumber?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                      disableDropDown={false}
                      editCountryCode={false}
                      defaultCountry={'lb'}
                    />
                  )}
                />

                {lovs != null && (
                  <Controller
                    name='country'
                    control={control}
                    rules={{ required: 'Required' }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <EnhancedChipInput
                        name={`country`}
                        title='Country*'
                        placeholder={'Country'}
                        value={!isEmpty(value) ? value : ''}
                        onChange={(e) => {
                          onChange(e as string);
                          trigger(`country` as keyof typeof initialValues);
                        }}
                        onBlur={(e) => {
                          onBlur();
                          trigger('country' as keyof typeof initialValues);
                        }}
                        selectOptions={lovs?.countries}
                        error={
                          errors.country != undefined &&
                          !isEmpty(errors?.country)
                            ? errors?.country?.message
                            : null
                        }
                        multiple={false}
                        material
                        disabled={formDisabled}
                        className={classes.field}
                      />
                    )}
                  />
                )}
                <Controller
                  name='city'
                  control={control}
                  rules={{
                    required: 'Required',
                    minLength: { value: 2, message: 'Invalid' },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='city'
                      title='City*'
                      placeholder={'City'}
                      onChange={(e) => {
                        onChange(e);
                        trigger('city' as keyof typeof initialValues);
                      }}
                      onBlur={(e) => {
                        trigger('city' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.city?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
              </WidgetSection>

              <WidgetSection
                title='Additional Details'
                narrowSpacing={true}
                classes={{
                  container: classes.section,
                }}
                useSeparator={false}
                style={{ margin: '-4px 0' }}
              >
                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: false,
                    validate: (value) =>
                      checkEmail(value) || 'Invalid email format',
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='email'
                      title='Email'
                      placeholder={'Email'}
                      onChange={(e) => {
                        trigger('email' as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger('email' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.email?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />

                <Controller
                  name='phoneType'
                  control={control}
                  rules={{ required: 'Required' }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedChipInput
                      name={`phoneType`}
                      title='Phone Type*'
                      placeholder={'Phone Type'}
                      value={!isEmpty(value) ? value : ''}
                      onChange={(e) => {
                        onChange(e as string);
                        trigger(`phoneType` as keyof typeof initialValues);
                      }}
                      onBlur={(e) => {
                        onBlur();
                        trigger('phoneType' as keyof typeof initialValues);
                      }}
                      selectOptions={lovs?.phoneTypes}
                      error={
                        errors.phoneType != undefined &&
                        !isEmpty(errors?.phoneType)
                          ? errors?.phoneType?.message
                          : null
                      }
                      multiple={false}
                      material
                      disabled={formDisabled}
                      className={classes.field}
                    />
                  )}
                />
                <Controller
                  name='street'
                  control={control}
                  rules={{
                    required: false,
                    minLength: { value: 2, message: 'Invalid' },
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='street'
                      title='Street'
                      placeholder={'Street'}
                      onChange={(e) => {
                        onChange(e);
                        trigger('street' as keyof typeof initialValues);
                      }}
                      onBlur={(e) => {
                        trigger('street' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={!isEmpty(value) ? value : ''}
                      error={errors?.street?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
                <Controller
                  name='building'
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <EnhancedInput
                      type='text'
                      name='building'
                      title='Building'
                      placeholder={'Building'}
                      onChange={(e) => {
                        trigger('building' as keyof typeof initialValues);
                        onChange(e);
                      }}
                      onBlur={(e) => {
                        trigger('building' as keyof typeof initialValues);
                        onBlur();
                      }}
                      value={
                        !isEmpty(value) || value !== undefined ? value : null
                      }
                      error={errors?.building?.message}
                      className={classes.field}
                      material
                      disabled={formDisabled}
                    />
                  )}
                />
              </WidgetSection>
              <WidgetSection
                title='Product Details'
                narrowSpacing={true}
                classes={{ container: classes.sectionFullRow }}
                useSeparator={false}
                style={{ margin: '-4px 0' }}
              >
                {lovs != null &&
                  planDetailsFieldArray &&
                  planDetailsFieldArray.fields.map((f, index) => {
                    return (
                      <div className={classes.row} key={f.id}>
                        <Controller
                          name={
                            `planDetails.${index}.planId` as keyof typeof initialValues
                          }
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <EnhancedInput
                              type='text'
                              name={`planDetails.${index}.planId`}
                              title='Plan Id*'
                              value={(value as string) || ''}
                              onChange={(e) => {}}
                              onBlur={(e) => {}}
                              style={{ display: 'none', margin: '-10px 0' }}
                              customStyles={{
                                labelStyles: classes.repeaterField,
                              }}
                            />
                          )}
                        />

                        <EnhancedInput
                          type='text'
                          name={`planDetails.${index}.planId`}
                          placeholder={'Quote Name'}
                          title='Quote Name*'
                          value={f.name}
                          onChange={(e) => {}}
                          onBlur={(e) => {}}
                          material
                          disabled
                          style={{ margin: '-10px 0' }}
                          customStyles={{
                            labelStyles: classes.repeaterField,
                          }}
                        />
                        <Controller
                          name={
                            `planDetails.${index}.product` as keyof typeof initialValues
                          }
                          control={control}
                          rules={{
                            required: 'Required',
                            validate: (value) => checkQuoteName(f.product),
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <EnhancedChipInput
                              name={`planDetails.${index}.product`}
                              title='Product*'
                              placeholder={'Product'}
                              value={(value as string) || ''}
                              onChange={(e) => {
                                const selectedValue = e as string;
                                onChange(selectedValue);

                                trigger(
                                  `planDetails.${index}.product` as keyof typeof initialValues
                                );
                                planDetailsFieldArray.fields[index].product =
                                  selectedValue;
                                updatedPlanDetails =
                                  planDetailsFieldArray.fields.map(
                                    (field, index) => {
                                      return {
                                        ...field,
                                        ...watchedPlanDetails[index],
                                      };
                                    }
                                  );
                                planDetailsProducts = updatedPlanDetails.map(
                                  (p) => p.product
                                );
                                handlePlanDetailsChange();
                                f.name = quoteName;

                                const newPlanDetailsProductCounter = {
                                  ...planDetailsProductCounter,
                                  [selectedValue]:
                                    planDetailsProductCounter[
                                      selectedValue as keyof typeof initValuesPlanDetailsProductsCounter
                                    ] + 1,
                                };

                                setPlanDetailsProductCounter(
                                  newPlanDetailsProductCounter
                                );
                                validatePlateNumberOnProductChange(
                                  selectedValue,
                                  index
                                );

                                let planDetailsConditions = cloneDeep(
                                  planDetailsKeysStatus
                                );

                                planDetailsConditions[index] = {};
                                setPlanDetailsKeysStatus(planDetailsConditions);
                              }}
                              onBlurSaveNewData={true}
                              onBlur={() => {
                                const selectedValue =
                                  planDetailsFieldArray.fields[
                                    index
                                  ].product.toString();
                                validatePlateNumberOnProductChange(
                                  selectedValue,
                                  index
                                );
                                trigger(
                                  `planDetails.${index}.product` as keyof typeof initialValues
                                );

                                const newPlanDetailsProductCounter = {
                                  ...planDetailsProductCounter,
                                  [selectedValue]:
                                    planDetailsProductCounter[
                                      selectedValue as keyof typeof initValuesPlanDetailsProductsCounter
                                    ] + 1,
                                };

                                setPlanDetailsProductCounter(
                                  newPlanDetailsProductCounter
                                );
                                validatePlateNumberOnProductChange(
                                  selectedValue,
                                  index
                                );

                                let planDetailsConditions = cloneDeep(
                                  planDetailsKeysStatus
                                );

                                planDetailsConditions[index] = {};
                                setPlanDetailsKeysStatus(planDetailsConditions);
                              }}
                              selectOptions={lovs.products}
                              material
                              error={
                                errors.planDetails != undefined &&
                                !isEmpty(errors?.planDetails[index]?.product)
                                  ? errors?.planDetails[index]?.product?.message
                                  : null
                              }
                              multiple={false}
                              disabled={formDisabled}
                              style={{ margin: '-10px 0' }}
                              customStyles={{
                                labelStyles: classes.repeaterField,
                              }}
                            />
                          )}
                        />

                        {isVisiblePlanDetailsField(
                          planDetailsFieldArray.fields[
                            index
                          ]?.product?.toString() || f.product?.toString(),
                          'towingService'
                        ) && (
                          <Controller
                            name={
                              `planDetails.${index}.towingService` as keyof typeof initialValues
                            }
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <EnhancedChipInput
                                name={`planDetails.${index}.towingService`}
                                title='Towing Service*'
                                placeholder={'Towing Service'}
                                value={(value as string) || ''}
                                onChange={(e) => {
                                  onChange(e as string);
                                  trigger(
                                    `planDetails.${index}.towingService` as keyof typeof initialValues
                                  );
                                }}
                                onBlur={onBlur}
                                selectOptions={towingService}
                                material
                                error={
                                  errors.planDetails != undefined &&
                                  !isEmpty(
                                    errors?.planDetails[index]?.towingService
                                  )
                                    ? errors?.planDetails[index]?.towingService
                                        ?.message
                                    : null
                                }
                                multiple={false}
                                disabled={formDisabled}
                                className={classes.field}
                                style={{ margin: '-10px 0' }}
                                customStyles={{
                                  labelStyles: classes.repeaterField,
                                }}
                              />
                            )}
                          />
                        )}

                        {isVisiblePlanDetailsField(
                          planDetailsFieldArray.fields[
                            index
                          ]?.product?.toString() || f.product?.toString(),
                          'vignette'
                        ) && (
                          <>
                            <Controller
                              name={
                                `planDetails.${index}.vignetteCode` as keyof typeof initialValues
                              }
                              control={control}
                              rules={{ required: 'Required' }}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <EnhancedInput
                                  type='text'
                                  name={`planDetails.${index}.vignetteCode`}
                                  title='Vignette Code*'
                                  placeholder={'Vignette Code'}
                                  onChange={(e) => {
                                    const newValue = e;
                                    onChange(newValue);
                                    trigger(
                                      `planDetails.${index}.vignetteCode` as keyof typeof initialValues
                                    );
                                    planDetailsFieldArray.fields.forEach(
                                      (data, rowIndex) => {
                                        validateVignetteOnChange(rowIndex);
                                      }
                                    );

                                    setUsageTypes(
                                      adjustUsageTypesListAndReview()
                                    );
                                  }}
                                  onBlur={onBlur}
                                  value={(value as string) || ''}
                                  error={
                                    errors.planDetails != undefined &&
                                    !isEmpty(
                                      errors?.planDetails[index]?.vignetteCode
                                    )
                                      ? errors?.planDetails[index]?.vignetteCode
                                          ?.message
                                      : null
                                  }
                                  disabled={formDisabled}
                                  material
                                  className={classes.field}
                                  style={{ margin: '-10px 0' }}
                                  customStyles={{
                                    labelStyles: classes.repeaterField,
                                  }}
                                />
                              )}
                            />
                            <Controller
                              name={
                                `planDetails.${index}.vignette` as keyof typeof initialValues
                              }
                              control={control}
                              rules={{ required: 'Required' }}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <EnhancedInput
                                  type='number'
                                  name={`planDetails.${index}.vignette`}
                                  title='Vignette*'
                                  placeholder={'Vignette'}
                                  onChange={(e) => {
                                    const newValue = e;
                                    onChange(newValue);
                                    trigger(
                                      `planDetails.${index}.vignette` as keyof typeof initialValues
                                    );
                                    planDetailsFieldArray.fields.forEach(
                                      (data, rowIndex) => {
                                        validateVignetteOnChange(rowIndex);
                                      }
                                    );

                                    setUsageTypes(
                                      adjustUsageTypesListAndReview()
                                    );
                                  }}
                                  onBlur={onBlur}
                                  value={(value as string) || ''}
                                  error={
                                    errors.planDetails != undefined &&
                                    !isEmpty(
                                      errors?.planDetails[index]?.vignette
                                    )
                                      ? errors?.planDetails[index]?.vignette
                                          ?.message
                                      : null
                                  }
                                  disabled={formDisabled}
                                  material
                                  className={classes.field}
                                  style={{ margin: '-10px 0' }}
                                  customStyles={{
                                    labelStyles: classes.repeaterField,
                                  }}
                                />
                              )}
                            />
                          </>
                        )}

                        {isVisiblePlanDetailsField(
                          planDetailsFieldArray.fields[
                            index
                          ]?.product?.toString() || f.product?.toString(),
                          'coverageType'
                        ) && (
                          <Controller
                            name={
                              `planDetails.${index}.coverageType` as keyof typeof initialValues
                            }
                            control={control}
                            rules={{ required: 'Required' }}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <EnhancedChipInput
                                name={`planDetails.${index}.coverageType`}
                                title='Plan*'
                                placeholder={'Plan'}
                                value={(value as string) || ''}
                                onChange={(e) => {
                                  const planValue = e as string;
                                  onChange(planValue);
                                  trigger(
                                    `planDetails.${index}.coverageType` as keyof typeof initialValues
                                  );

                                  let planDetailsConditions = cloneDeep(
                                    planDetailsKeysStatus
                                  );

                                  if (planValue == 'LUX_PLAN') {
                                    planDetailsConditions[index][
                                      'coverageType'
                                    ] = true;
                                  } else {
                                    planDetailsConditions[index][
                                      'coverageType'
                                    ] = false;
                                  }

                                  setPlanDetailsKeysStatus(
                                    planDetailsConditions
                                  );
                                }}
                                onBlur={onBlur}
                                selectOptions={lovs.coverageTypes}
                                material
                                error={
                                  errors.planDetails != undefined &&
                                  !isEmpty(
                                    errors?.planDetails[index]?.coverageType
                                  )
                                    ? errors?.planDetails[index]?.coverageType
                                        ?.message
                                    : null
                                }
                                multiple={false}
                                disabled={formDisabled}
                                className={classes.field}
                                style={{ margin: '-10px 0' }}
                                customStyles={{
                                  labelStyles: classes.repeaterField,
                                }}
                              />
                            )}
                          />
                        )}

                        {isVisiblePlanDetailsField(
                          planDetailsFieldArray.fields[
                            index
                          ]?.product?.toString() || f.product?.toString(),
                          'carValue'
                        ) && (
                          <Controller
                            name={
                              `planDetails.${index}.carValue` as keyof typeof initialValues
                            }
                            control={control}
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <EnhancedCurrencyInput
                                type='number'
                                name={`planDetails.${index}.carValue`}
                                title='Car Value*'
                                placeholder={'Car Value'}
                                onChange={(e) => {
                                  const carValue = e.target
                                    .value as unknown as number;
                                  onChange(carValue);
                                  trigger(
                                    `planDetails.${index}.carValue` as keyof typeof initialValues
                                  );
                                  const agencyRepairList = adjustAgencyRepair(
                                    carValue,
                                    lovs['agencyRepairs']
                                  );

                                  setAgencyRepairs({
                                    ...agencyRepairs,
                                    [index]: agencyRepairList,
                                  });
                                }}
                                onBlur={onBlur}
                                value={value as string}
                                currencyTitle='$'
                                currencyIcon={
                                  tenant.cdnUrl + '/icons/dollar-primary.svg'
                                }
                                material
                                disabled={formDisabled}
                                className={classes.field}
                                style={{ margin: '-10px 0' }}
                                customStyles={{
                                  labelStyles: classes.repeaterField,
                                }}
                              />
                            )}
                          />
                        )}

                        {isVisiblePlanDetailsField(
                          planDetailsFieldArray.fields[
                            index
                          ]?.product?.toString() || f.product?.toString(),
                          'includeAgencyRepair'
                        ) &&
                          getValues('usageType') == 'Private' &&
                          getValues('yearOfMake') >= 2022 &&
                          getValues('yearOfMake') <= 2025 &&
                          planDetailsKeysStatus[index]?.coverageType && (
                            <Controller
                              name={
                                `planDetails.${index}.includeAgencyRepair` as keyof typeof initialValues
                              }
                              control={control}
                              rules={{ required: 'Required' }}
                              render={({
                                field: { onChange, onBlur, value },
                              }) => (
                                <EnhancedChipInput
                                  name={`planDetails.${index}.includeAgencyRepair`}
                                  title='Include Agency Repair*'
                                  placeholder={'Include Agency Repair'}
                                  value={(value as string) || ''}
                                  onChange={(e) => {
                                    onChange(e as string);
                                    trigger(
                                      `planDetails.${index}.includeAgencyRepair` as keyof typeof initialValues
                                    );
                                  }}
                                  onBlur={onBlur}
                                  selectOptions={
                                    agencyRepairs[index] ||
                                    adjustAgencyRepair(
                                      planDetailsFieldArray.fields[index]
                                        ?.carValue,
                                      lovs['agencyRepairs']
                                    )
                                  }
                                  material
                                  error={
                                    errors.planDetails != undefined &&
                                    !isEmpty(
                                      errors?.planDetails[index]
                                        ?.includeAgencyRepair
                                    )
                                      ? errors?.planDetails[index]
                                          ?.includeAgencyRepair?.message
                                      : null
                                  }
                                  multiple={false}
                                  disabled={formDisabled}
                                  className={classes.field}
                                  style={{ margin: '-10px 0' }}
                                  customStyles={{
                                    labelStyles: classes.repeaterField,
                                  }}
                                />
                              )}
                            />
                          )}

                        <div
                          className='btn-box'
                          style={{
                            marginTop: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyItems: 'stretch',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                          }}
                        >
                          {!formDisabled &&
                            planDetailsFieldArray.fields.length < 5 &&
                            planDetailsFieldArray.fields.length - 1 ===
                              index && (
                              <span
                                style={{
                                  paddingRight: '10px',
                                  color: MAIN_ONE_THEME.palette.primary1.main,
                                  cursor: 'pointer',
                                }}
                                onClick={() => {
                                  planDetailsFieldArray.append({
                                    ...initialValues.planDetails[0],
                                  });

                                  let planDetailsConditions = cloneDeep(
                                    planDetailsKeysStatus
                                  );

                                  planDetailsConditions.push({});

                                  setPlanDetailsKeysStatus(
                                    planDetailsConditions
                                  );
                                }}
                              >
                                <AddCircleOutlineIcon fontSize='small' />
                              </span>
                            )}
                          {!formDisabled &&
                            planDetailsFieldArray.fields.length !== 1 && (
                              <span
                                style={{ color: 'red', cursor: 'pointer' }}
                                onClick={() => {
                                  planDetailsFieldArray.remove(index);

                                  let planDetailsConditions = cloneDeep(
                                    planDetailsKeysStatus
                                  );

                                  planDetailsConditions.splice(index, 1);

                                  setPlanDetailsKeysStatus(
                                    planDetailsConditions
                                  );
                                }}
                              >
                                <HighlightOffIcon fontSize='small' />
                              </span>
                            )}
                        </div>
                      </div>
                    );
                  })}
              </WidgetSection>
              {!isClosed && isEnabled && buttonLabel === 'Calculate' && (
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'flex-end',
                    display: 'flex',
                    padding: '15px 0 0',
                  }}
                >
                  <EnhancedButton
                    type={buttonType}
                    state={submitButtonState}
                    backgroundColor={MAIN_ONE_THEME.palette.primary1.main}
                    color='#FFF'
                    style={{ display: 'inline' }}
                  >
                    {buttonLabel}
                  </EnhancedButton>
                </div>
              )}
            </WidgetPaper>
          </form>

          {newAppDrawerOpen && (
            <ApplicationDrawer
              open={newAppDrawerOpen}
              onClose={() => setNewAppDrawerOpen(false)}
              onSuccess={() => {
                setNewAppDrawerOpen(false);
              }}
            />
          )}
          {newApplicationWithMultipleAgencieDrawerOpen && (
            <ApplicationDrawerWithMultipleAgencies
              open={newApplicationWithMultipleAgencieDrawerOpen}
              loggedInAgentId={user.info.agentId}
              loggedInuserFirstName={user.info.firstName}
              loggedInuserLastName={user.info.lastName}
              lineOfBusiness={'Motor'}
              agentType={agentType}
              onClose={() =>
                setNewApplicationWithMultipleAgencieDrawerOpen(false)
              }
              onSuccess={() => {}}
            />
          )}
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background='linear-gradient(98.3deg, rgb(0, 0, 0) 10.6%, rgb(255, 0, 0) 97.7%)'
          classification={{ classifications: {}, value: '' }}
          progress={getApplicationProgress(
            entityResult?.data?.Insurance?.entities?.application?.views
              ?.Insurance_all?.properties?.Status
          )}
          supportsClassification={true}
          supportsProgressBar={true}
          title={applicationInfo.applicationName}
          view={updatedLeftSideInfo}
        ></EntityInfoWidget>
      </>
    );
  };
  //#endregion

  return loadingState ? (
    <Loader />
  ) : (
    <>
      <div>
        {copyPolicyDetailsDrawerOpen && (
          <ApplicationCopyPolicyDetailsDrawer
            open={copyPolicyDetailsDrawerOpen}
            onClose={() => setCopyPolicyDetailsDrawerOpen(false)}
            onSuccess={(policyNumber: string) => {
              getCopyPolicyDetails(policyNumber);
            }}
          />
        )}
      </div>
      <StaticLayout
        name={'Application Details'}
        leftChildren={renderLeftSection()}
        mainChildren={renderMainChildren()}
      />
    </>
  );
};

export default ApplicationDetailsPageMotor;
