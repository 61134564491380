import React from "react";
import { IProductionDetailsSummary } from "..";
import { IDynamicDiplayView } from "../../../../../components/widgets/dynamic-display";
import DynamicWidget from "../../../../../components/widgets/dynamic-display/DynamicWidget";
import { capitalizeFirstLetterLowerOthers } from "../../../../../utils/formatting-utils";

interface IProductionPolicyExpatDetailsWidgetProps {
  data: IProductionDetailsSummary;
}

const ProductionPolicyExpatDetailsWidget: React.FC<
  IProductionPolicyExpatDetailsWidgetProps
> = ({ data }) => {
  const dynamicView: IDynamicDiplayView = {
    name: "ExpatDetails",
    sections: [
      {
        name: "ExpatDetails",
        title: "Expat Details",
        properties: {
          medicalClass: {
            multiline: false,
            value: data?.ExpatDetails?.MedicalClass?.Title,
            name: "medicalClass",
            title: "Medical Class",
          },
          continuity: {
            multiline: false,
            value:  capitalizeFirstLetterLowerOthers(data?.ExpatDetails?.Continuity || ""),
            name: "continuity",
            title: "Continuity",
          },
          continuityNumber: {
            multiline: false,
            value: data?.ExpatDetails?.ContinuityNumber,
            name: "continuityNumber",
            title: "Continuity Number",
          },
          exclusion: {
            multiline: false,
            value: data?.ExpatDetails?.Exclusion,
            name: "exclusion",
            title: "Exclusion",
            fullLine: true,
          },
          remarks: {
            multiline: false,
            value: data?.ExpatDetails?.Remarks,
            name: "remarks",
            title: "Remarks",
            fullLine: true,
          },
        },
      },
    ],
  };

  return <DynamicWidget hasFourFields={true} view={dynamicView} />;
};

export default ProductionPolicyExpatDetailsWidget;
