import React from 'react';
import {TableSortOrder} from '../../utils/table-utils';
import EnhancedCheckbox from './shared/EnhancedCheckbox';
import {EnhancedUpdatedTableHeader} from '.';
import {TableHead, TableRow, TableCell, TableSortLabel} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

export interface HeadRow {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

interface IEnhancedTableProps {
  headRows: Record<string, EnhancedUpdatedTableHeader>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (name: string, checked: boolean) => void;
  order: TableSortOrder;
  orderBy: string | undefined;
  rowCount: number;
  disableSelection?: boolean;
}

const useStyles = makeStyles()((_theme) => ({
  tableHead: {
    padding: '0 0 0 24px',
  },
  cellDesign: {
    color: '#344054',
    fontFamily: 'SourceSansPro-Bold',
    fontSize: '15px',
    lineHeight: '19px',
    padding: '17px 2px 17px 24px',
    borderBottom: '1px solid #EAECF0',
  },
  tooltipContainer: {
    position: 'relative',
  },
  text: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  tooltipIcon: {
    position: 'unset',
    display: 'inline-block',
    verticalAlign: 'middle',
    transform: 'none',
    marginLeft: '8px',
  },
  whiteSpacePre: {
    whiteSpace: 'pre',
  },
}));

const EnhancedTableHead: React.FC<IEnhancedTableProps> = ({
  headRows,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  disableSelection,
}) => {
  const {classes, cx} = useStyles();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const renderContent = (
    value: any,
    whiteSpacePre?: boolean,
  ) => {
    return (
      <div className={classes.tooltipContainer}>
        <span
          className={
            whiteSpacePre
              ? cx(classes.text, classes.whiteSpacePre)
              : classes.text
          }
        >
          {value}{' '}
          {/* {tooltip ? (
            <GenericToolTip
              iconClassName={classes.tooltipIcon}
              description={tooltip}
              placement="top"
            />
          ) : (
            <></>
          )} */}
        </span>
      </div>
    );
  };

  return (
    <TableHead>
      <TableRow>
        {!disableSelection && (
          <TableCell role="checkbox">
            <EnhancedCheckbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount && rowCount !== 0}
              disabled={rowCount === 0}
              onChange={onSelectAllClick}
              name={'Select All'}
              checkboxOnly
            />
          </TableCell>
        )}

        {Object.keys(headRows).map((headerKey, headerIndex) => (
          <TableCell
            key={headerKey}
            padding="none"
            className={classes.cellDesign}
          >
            {headRows[headerKey].sortable ? (
              <TableSortLabel
                active={orderBy === headerKey}
                direction={order}
                onClick={createSortHandler(headerKey)}
              >
                {renderContent(
                  headRows[headerKey].title,
                  // headRows[headerKey].tooltip,
                  headRows[headerKey].whiteSpacePre,
                )}
              </TableSortLabel>
            ) : (
              <>
                {renderContent(
                  headRows[headerKey].title,
                  // headRows[headerKey].tooltip,
                )}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
