import { EnhancedTableHeader, EnhancedTableHeaderType } from "../../../../../../components/enhanced-table";

export interface ICommissionListingData {
    id: string;
    partner: string;
    share: number;
    planCommission: number;
    applicableOn: string;
    totalAmount: number;
    isPrimary: boolean;
}
export const comissionHeaders = (currency?: string) : Record<string, EnhancedTableHeader>  => ({
    partner: {
        name: 'partner',
        title: 'Business Partner',
        type: EnhancedTableHeaderType.Text,
    },
    share: {
        name: 'share',
        title: 'Share',
        type: EnhancedTableHeaderType.Percentage,
    },
    planCommission: {
        name: 'planCommission',
        title: 'Plan Commission',
        type: EnhancedTableHeaderType.Currency,
        currency: currency,
    },
    applicableOn: {
        name: 'applicableOn',
        title: 'Applicable On',
        type: EnhancedTableHeaderType.Date,
    },
    totalAmount: {
        name: 'totalAmount',
        title: 'Total Amount',
        type: EnhancedTableHeaderType.Currency,
        currency: currency,
    }
});