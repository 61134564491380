import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ISublineDetails, ISublineDetailsPageProps, initialValues } from ".";
import Loader from "../../components/Loader";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import EntityInfoWidget from "../../components/widgets/entity-info/EntityInfoWidget";
import { MAIN_ONE_THEME, contentFontFamilyBold } from "../../constants";
import { getEntityView, planHeaders, steps } from "./content";
import {
  activateSubline,
  deactivateSubline,
  getSublineDetailsInfo,
  getSublineDetailsPagePlanList,
} from "./queries";
import {
  entityToSublineDetails,
  getSublineStatus,
  mapToPlansListingData,
} from "./utils";
import { capitalizeFirstLetter } from "../../utils/formatting-utils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingData } from "../../models/listing";
import WidgetSection from "../../components/common/WidgetSection";
import EnhancedStepperSpecificDesign from "../../components/common/EnhancedStepperSpecificDesign";
import { makeStyles } from "tss-react/mui";
import {
  IEnhanceTableHeaderClickable,
  IEnhancedTableMenuItem,
} from "../../components/enhanced-table";
import PlanDrawer from "../plan-drawer/PlanDrawer";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import SublineDrawer from "../subline-drawer/SublineDrawer";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import { IConfirmation } from "../../redux/confirmation/types";
import { isEmpty } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { getError } from "../../utils/graph-utils";
import { OpenConfirmationAction } from "../../redux/confirmation/actions";
import { capitalizeFirstCharacter } from "../../utils/formatting-utils";
import { ITableOrder, TableSortOrder } from "../../utils/table-utils";
import _ from "lodash";

const useStyles = makeStyles()(() => ({
  stepper: {
    backgroundColor: "transparent",
    maxWidth: "90%",
    marginBottom: "20px",
    "& .MuiStepLabel-root .Mui-active, .MuiStepLabel-root ": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    ".Mui-completed": {
      color: MAIN_ONE_THEME.palette.primary5.main,
      "& .MuiStepIcon-text": {
        fontSize: 0,
        lineHeight: "0px",
        fill: "black",
      },
    },

    "@media only screen and (min-width: 600px)": {
      maxWidth: "1300px",
    },
  },
  section: {
    display: "grid",
    "grid-template-columns": "19.5% 19.5% 19.5% 19.5% 19.5%",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& label": {
      "& span": {
        margin: "-2px 0",
      },
    },
  },
  sectionTitle: {
    padding: "-10x 10x!important",
  },
  sectionFullRow: {
    display: "grid",
    "grid-template-columns": "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  row: {
    flexWrap: "wrap",
    margin: "15px 20px 10px 0px",
    alignItems: "flex-start",
    display: "grid",
    gap: "5px",
    "& label": {
      flexGrow: "1",
      flexBasis: "0",
      minWidth: "0",
    },
    "grid-template-columns": "19.5% 19.5% 19.5% 19% 16% 3% 3%",
  },
  thickSeperator: {
    height: 10,
    margin: "10px 0 10px 0",
    "grid-column-start": "1",
    "grid-column-end": "4",
  },
  field: {
    width: "96%",
    marginRight: "10px !important",
  },
  repeaterField: {
    margin: "0px!important",
  },
  arField: {
    width: "96%",
    marginRight: "10px !important",
    direction: "rtl",
  },
  header: {
    fontFamily: contentFontFamilyBold,
    fontSize: MAIN_ONE_THEME.typography.regular.reg3.fontSize,
    color: MAIN_ONE_THEME.palette.primary2.main,
    "&:hover": {
      color: MAIN_ONE_THEME.palette.primary1.main,
    },
  },
  content: {
    padding: "0",
  },
  dirtyChip: {
    margin: "0 0 -15px 0",
    backgroundColor: MAIN_ONE_THEME.palette.primary2.main,
    "& span": {
      color: "#ffffff",
    },
  },
  tabPanelStyle: {
    padding: "unset",
    marginTop: "20px",
  },
}));

const SublineDetailsPage: React.FC<ISublineDetailsPageProps> = () => {
  //#region react and redux hooks
  const { classes } = useStyles();
  const params = useParams();
  const tenant = useAppSelector((state) => state.tenant);
  const user = useAppSelector((state) => state.user);

  const [booted, setBooted] = useState<boolean>(false);
  const [keywordSearch, setKeywordSearch] = useState("");
  let setSearchTimeout: NodeJS.Timeout;

  const [tableOrders, setTableOrders] = useState<Record<string, ITableOrder>>({
    plan: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [activateButtonState, setActivateButtonState] =
    useState<EnhancedButtonStatus>();
  const [deactivateButtonState, setDeactivateButtonState] =
    useState<EnhancedButtonStatus>();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [sublineDrawerOpen, setSublineDrawerOpen] = useState<boolean>(false);
  const [planDrawerOpen, setPlanDrawerOpen] = useState<boolean>(false);

  const [selectedPlanItems, setSelectedPlanItems] = useState<number>(0);
  //#endregion

  //#region component hooks and states
  const [sublineDetailsInfo, setSublineDetailsInfo] =
    useState<ISublineDetails>(initialValues);

  const [loadingState, setLoadingState] = useState<boolean>(true);

  const [selectedPlanEntityId, setSelectedPlanEntityId] = useState("");

  const [actionClicked, setActionClicked] = useState(null);

  const entityId = params.id;

  //#endregion

  (
    planHeaders.planConfigManagement_Plan_Name as IEnhanceTableHeaderClickable
  ).callback = (planPayload: any) => {
    navigate("/plan/plans/" + planPayload.columns.planConfigManagement_Plan_Id);
  };

  const isAdmin = user.userRoles.includes("Insurance-Admin");

  let planListingActions: IEnhancedTableMenuItem[] = [];
  planListingActions = [
    {
      title: "New",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/add-primary-red.svg",
      hidden: !isAdmin,
      disabled: false,
    },
    {
      title: "Edit",
      onClick: () => {},
      isEntity: false,
      isBulk: false,
      iconUrl: tenant.cdnUrl + "/icons/edit-secondary.svg",
      hidden: !isAdmin,
      disabled: selectedPlanItems !== 1,
    },
  ];

  //#region graphql hooks

  const [activateAction] = useMutation(activateSubline(), {
    variables: {
      entityId: entityId,
    },
    errorPolicy: "all",
    refetchQueries: [getSublineDetailsInfo()],
  });
  const [deactivateAction] = useMutation(deactivateSubline(), {
    variables: {
      entityId: entityId,
    },
    refetchQueries: [getSublineDetailsInfo(), getSublineDetailsPagePlanList()],
  });

  planListingActions[0].onClick = () => {
    setActionClicked("new");
    setPlanDrawerOpen(true);
  };
  planListingActions[1].onClick = () => {
    setActionClicked("edit");
    setPlanDrawerOpen(true);
  };

  const [planTableData, setPlanTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const [planListResultQuery] = useLazyQuery(getSublineDetailsPagePlanList());

  const [entityResultQuery] = useLazyQuery(getSublineDetailsInfo());

  const loadEntityData = async () => {
    const result = await entityResultQuery({
      variables: { id: entityId },
      errorPolicy: "all",
      fetchPolicy: "no-cache",
    });

    return result;
  };

  const loadPlanList = async (
    currentPage: number = 0,
    pageSize = planTableData.pageSize,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword = keywordSearch
  ) => {
    const result = await planListResultQuery({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        SelectedSublineID: params.id,
        Attribute: capitalizeFirstCharacter(
          orders?.plan?.orderBy || "PlanConfigManagement_Plan_Name"
        ),
        Descending: orders?.plan?.orderDirection == "asc" ? false : true,
        keywordSearch: searchKeyword || null,
      },
      errorPolicy: "all",
    });

    return result;
  };

  //#endregion

  //#region useEffect
  const initialize = async () => {
    const [entityData, planData] = await Promise.all([
      loadEntityData(),
      loadPlanList(0, 10),
    ]);
    const sublineDetails = await entityToSublineDetails(entityData.data);
    const mappedPlanList = await mapToPlansListingData(planData.data);

    setSublineDetailsInfo(sublineDetails);
    setPlanTableData({ ...mappedPlanList, pageNumber: 0 });

    setBooted(true);
    setLoadingState(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  //#region functions

  const handleSearchChange = (search: string) => {
    setKeywordSearch(search);
  };

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      handleSearchChange(val);
      handlePlanPageChange(0, planTableData.pageSize, tableOrders, val);
    }, 500);
  };

  function handleSortChange(orderBy: string, order: string) {
    const newOrder = _.cloneDeep(tableOrders);

    newOrder.plan.orderBy = orderBy;
    newOrder.plan.orderDirection = order as any;
    handlePlanPageChange(
      planTableData.pageNumber,
      planTableData.pageSize,
      newOrder
    );

    setTableOrders(newOrder);
  }

  async function handlePlanPageChange(
    page: number,
    pageSize: number,
    orders: Record<string, ITableOrder> = tableOrders,
    searchKeyword: string = keywordSearch
  ) {
    if (!loadingState) {
      setLoadingState(true);
      const result = await loadPlanList(page, pageSize, orders, searchKeyword);
      const mappedSubList = await mapToPlansListingData(result.data);
      setPlanTableData({
        ...mappedSubList,
        pageNumber: page,
        pageSize: pageSize,
      });

      setLoadingState(false);
    }
  }

  const renderMainChildren = () => {
    const status = sublineDetailsInfo?.status.toLowerCase();
    const lineStatus = sublineDetailsInfo.lineStatus;
    const isSublineActive = status === "active";

    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedStepperSpecificDesign
            activeStep={getSublineStatus(status)}
            steps={steps}
            className={classes.stepper}
            showStepperButton
            buttonTitle={isSublineActive ? "Deactivate" : "Activate"}
            buttonState={
              isSublineActive ? activateButtonState : deactivateButtonState
            }
            buttonOnClick={async () => {
              if (isSublineActive) {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Deactivate",
                  message: `Are you sure you want to deactivate this subline?`,
                  callback: async () => {
                    try {
                      setDeactivateButtonState("loading");
                      const result = await deactivateAction();

                      if (isEmpty(result.errors)) {
                        setDeactivateButtonState("success");
                        initialize();
                        toast.success(
                          <ToastSuccessMessage>
                            {
                              "Subline successfully deactivated. Relevant plans are deactivated as well."
                            }
                          </ToastSuccessMessage>
                        );
                      } else {
                        setDeactivateButtonState(undefined);
                        toast.error(
                          <ToastErrorMessage>
                            {getError(result)}
                          </ToastErrorMessage>
                        );
                      }
                    } finally {
                      setTimeout(() => {
                        setDeactivateButtonState(undefined);
                      }, 1000);
                    }
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              } else {
                const confirmation: IConfirmation = {
                  open: true,
                  title: "Activate",
                  message: `Are you sure you want to activate this subline?`,
                  callback: async () => {
                    try {
                      setActivateButtonState("loading");
                      const result = await activateAction();

                      if (isEmpty(result.errors)) {
                        setActivateButtonState("success");
                        initialize();
                        toast.success(
                          <ToastSuccessMessage>
                            {"Subline successfully activated."}
                          </ToastSuccessMessage>
                        );
                      } else {
                        setActivateButtonState(undefined);
                        toast.error(
                          <ToastErrorMessage>
                            {getError(result)}
                          </ToastErrorMessage>
                        );
                      }
                    } finally {
                      setTimeout(() => {
                        setActivateButtonState(undefined);
                      }, 1000);
                    }
                  },
                  submitButtonText: "Yes",
                  cancelButtonText: "No",
                };
                dispatch(OpenConfirmationAction(confirmation));
              }
            }}
          />
          <WidgetSection style={{ margin: "-23px 0 0 0" }}>
            <form>
              {false ? (
                <div></div>
              ) : (
                <div
                  style={{
                    marginTop: "20px",
                    padding: "-23px 0 0 0 !important",
                  }}
                >
                  <EnhancedTable
                    title={capitalizeFirstLetter("Plans")}
                    inlineTitle={capitalizeFirstLetter("Plans")}
                    name="count"
                    entityName={"Plan"}
                    entityIdColumnName={"planConfigManagement_Plan_Id"}
                    showCellFullData={true}
                    isTitlePascalCase={true}
                    orderByAscendingByDefault
                    defaultOrderByColumn="name"
                    inline={true}
                    data={planTableData}
                    headers={planHeaders}
                    handlePageChange={(page: number) =>
                      handlePlanPageChange(page, planTableData.pageSize)
                    }
                    handleRowsPerPageChange={(pageSize: number) =>
                      handlePlanPageChange(0, pageSize)
                    }
                    handleSort={(orderBy: string, order: string) =>
                      handleSortChange(orderBy, order)
                    }
                    handleSearchChange={delaySearch}
                    currentPage={planTableData.pageNumber}
                    hideToolbar={false}
                    usePagination
                    disableSelection={false}
                    actions={planListingActions}
                    //loader={planTableLoadingState}
                    showTablePagination={true}
                    onSelectionChange={(newDetailedSelection) => {
                      setSelectedPlanItems(newDetailedSelection.length);
                      if (newDetailedSelection.length > 0) {
                        setSelectedPlanEntityId(newDetailedSelection[0].id);
                      } else {
                        setSelectedPlanEntityId("");
                      }
                    }}
                    ordering={tableOrders.plan.orderDirection as TableSortOrder}
                    orderingBy={tableOrders.plan.orderBy}
                  />
                  {planDrawerOpen && (
                    <PlanDrawer
                      open={planDrawerOpen}
                      lineId={sublineDetailsInfo.lineId}
                      lineName={sublineDetailsInfo.lineName}
                      lineStatus={lineStatus.toLowerCase()}
                      planId={
                        actionClicked === "new" ? null : selectedPlanEntityId
                      }
                      sublineID={entityId}
                      sublineName={sublineDetailsInfo.sublineName}
                      isSublineNameDisabled={true}
                      onClose={() => setPlanDrawerOpen(false)}
                      onSuccess={async () => {
                        await initialize();
                      }}
                    />
                  )}
                  {sublineDrawerOpen && (
                    <SublineDrawer
                      open={sublineDrawerOpen}
                      lineId={sublineDetailsInfo.lineId}
                      lineName={sublineDetailsInfo.lineName}
                      lineStatus={lineStatus.toLowerCase()}
                      sublineId={entityId}
                      onClose={() => setSublineDrawerOpen(false)}
                      onSuccess={() => {
                        initialize();
                      }}
                    />
                  )}
                </div>
              )}
            </form>
          </WidgetSection>
        </div>
      </>
    );
  };

  const renderLeftSection = () => {
    return (
      <>
        <EntityInfoWidget
          background="rgb(193, 0, 0)"
          classification={{ classifications: {}, value: "" }}
          progress={100}
          supportsClassification={true}
          supportsProgressBar={true}
          title={sublineDetailsInfo.sublineName}
          view={getEntityView(sublineDetailsInfo)}
          iconUrl={tenant.cdnUrl + "/icons/edit-secondary.svg"}
          onClickIcon={() => {
            setSublineDrawerOpen(true);
          }}
        ></EntityInfoWidget>
      </>
    );
  };

  //#endregion

  return !booted ? (
    <Loader />
  ) : (
    <StaticLayout
      name={"Subline Details"}
      leftChildren={renderLeftSection()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default SublineDetailsPage;
