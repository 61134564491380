import { IAllRiskPlanRatesDrawerInfo } from ".";
import { lookupListAsRecordObject } from "../../utils/graph-utils";

export function LookupToList(data: any) {
  const allRiskPlanRatesEnums: Record<string, Record<string, string>> = {};

  allRiskPlanRatesEnums["currencies"] = lookupListAsRecordObject(
    data?.Core?.lookups?.currencies
  );

  allRiskPlanRatesEnums["carCategories"] = lookupListAsRecordObject(
    data?.Core?.lookups?.carCategories
  );

  return allRiskPlanRatesEnums;
}

export function graphqlToAllRiskPlanRatesInfo(
  data: any
): IAllRiskPlanRatesDrawerInfo {
  const allRiskPlanRates =
    data?.PlanConfigManagement?.entities?.planSpecificAllRisk?.views
      ?.PlanConfigManagement_all?.properties;

  return {
    planConfigManagement_PlanSpecificAllRisk_CarValueFrom:
      allRiskPlanRates?.CarValueFrom,
    planConfigManagement_PlanSpecificAllRisk_CarValueTo:
      allRiskPlanRates?.CarValueTo,
    planConfigManagement_PlanSpecificAllRisk_CarAgeFrom:
      allRiskPlanRates?.CarAgeFrom,
    planConfigManagement_PlanSpecificAllRisk_CarAgeTo:
      allRiskPlanRates?.CarAgeTo,
    planConfigManagement_PlanSpecificAllRisk_CarCategory:
      allRiskPlanRates?.CarCategory?.Id,
    planConfigManagement_PlanSpecificAllRisk_MinimumPremium:
      allRiskPlanRates?.MinimumPremium,
    planConfigManagement_PlanSpecificAllRisk_Rate: `${
      parseFloat((parseFloat(allRiskPlanRates?.Rate) * 100).toFixed(2))
    }`,
    planConfigManagement_PlanSpecificAllRisk_AgencyYear:
      allRiskPlanRates?.AgencyYear,
    planConfigManagement_PlanSpecificAllRisk_NoDepreciationYear:
      allRiskPlanRates?.NoDepreciationYear,
  };
}
