import dayjs from "dayjs";
import { isValidNumber, isEmpty } from "./validationUtils";
import { DISPLAY_DATE_FORMAT } from "../constants";

export const EMPTY_FIELD_PLACEHOLDER = "- -";

/**
 * Transforms date string into a string with ISO format
 * @param {string} input The date string
 * @return {string} The date in ISO string format
 * If no input or the date cannot be parsed, returns null
 */
export function dateStringToISOString(input: string) {
  if (input && isValidDate(input)) {
    const date = new Date(input);
    date.setHours(date.getTimezoneOffset() / -60, 0, 0);
    return date.toISOString().split("T")[0];
  }
  return null;
}

export function isValidDate(date: string | Date): boolean {
  return dayjs(date).isValid();
}

/**
 * Transforms date object into a string with ISO format
 * @param {Date} date The date object
 * @return {string} The date in ISO string format
 * If no input returns null
 */
export function dateToISOString(date: Date) {
  if (date) {
    date.setHours(date.getTimezoneOffset() / -60, 0, 0);
    return date.toISOString().split("T")[0];
  }
  return null;
}

/**
 * Transforms a date into a local date string format
 * @param {Date | string} toFormat The date to be formatted
 * @return {string} The date in local date string format
 * If input is not a valid date returns the input as it is, and if no input returns undefined
 */
export function formatDate(
  toFormat: Date | string = "",
  dateFormat = DISPLAY_DATE_FORMAT
) {
  let date = "";
  if (toFormat instanceof Date) {
    date = getDateWithoutTime(toFormat.toISOString());
  } else {
    if (!dayjs(toFormat).isValid()) {
      return toFormat;
    }
    date = toFormat;
  }
  return dayjs(date).format(dateFormat.replace(/-/g, "/"));
}

export function formatDateYMD(date: Date | string, dateFormat = "YYYY-MM-DD") {
  return dayjs(date).format(dateFormat);
}

/**
 * Transforms a date into a local date-time string format
 * @param {Date | string} toFormat The date to be formatted
 * @return {string} The date in local date-time string format
 * If input is not a valid date returns the input as it is, and if no input returns undefined
 */
export function formatDateTime(
  toFormat: Date | string = "",
  dateFormat = DISPLAY_DATE_FORMAT
) {
  let date: Date;
  if (toFormat instanceof Date) {
    date = toFormat;
  } else {
    const parsed = Date.parse(toFormat);
    if (isNaN(parsed)) {
      return toFormat;
    }
    date = new Date(parsed);
  }
  return dayjs(date).format(`${dateFormat.replace(/-/g, "/")} HH:mm:ss`);
}

export function convertToDatePickerFormat(value: Date) {
  return dayjs(value).format("YYYY-MM-DD");
}

/**
 * Transforms a number to a string with Intl number format
 * @param {number} value The value to be formatted
 * @return {string} The value in Intl number format
 */
export function formatNumber(value: number) {
  return new Intl.NumberFormat().format(value);
}

/**
 * Truncates a string according to the provided limit
 * @param {string} input The value to be truncated
 * @param {number} limit The max number of characters allowed
 * @return {string} The string after being truncated followed by '...'
 * If the input or limit are not provided returns N/A
 */
export function limitCharacterNumber(input: string, limit: number) {
  let output: string = input;
  if (output && limit) {
    if (output.length > limit) {
      output = `${input.substr(0, limit - 1)}...`;
    }
    return output;
  }
  return EMPTY_FIELD_PLACEHOLDER;
}

/**
 * Removes empty spaces from a string
 * @param {string} input The string
 * @return {string} The string with all empty spaces removed
 */
export function removeEmptySpaces(input: string): string {
  if (input) {
    const chunks = input.split(" ");
    let output = "";
    for (let i = 0; i < chunks.length; i += 1) {
      output += chunks[i];
    }
    return output;
  }
  return "";
}

export function normaliseUrl(url: string): string {
  if (!url) {
    return url;
  }

  const protocols = ["http", "ip", "ftp", "ssh", "ssl"];
  let isValid = false;

  for (let i = 0; i < protocols.length; i += 1) {
    if (url.startsWith(protocols[i])) {
      isValid = true;
      break;
    }
  }

  if (!isValid) {
    return `http://${url}`;
  }
  return url;
}

/**
 * Replaces all substrings between {} in the template string with their
 * matching values in the dictionary values
 * @param {string} template The string to work on
 * @param {string} primaryValue The primaryValue, the pattern {PRIMARY_VARIABLE} will be matched to this value
 * @param {Record<string, string>} values The dictionary with each pattern mapped to a value
 * @return {string} The string after being parsed
 */
export function parseStringTemplate(
  template: string,
  primaryValue: string | number,
  values: Record<string, string>
): string {
  const PRIMARY_VARIABLE = "PRIMARY_VARIABLE";
  let output = template;
  const allPatterns = template.match(/(\{[a-zA-Z_]+\})/gi);
  if (allPatterns && allPatterns.length > 0) {
    allPatterns.forEach((pattern) => {
      const variableName = pattern.slice(1, pattern.length - 1);
      let value = values[variableName];
      if (variableName === PRIMARY_VARIABLE && !isEmpty(primaryValue)) {
        value = primaryValue.toString();
      }
      output = output.replace(pattern, value);
    });
  }
  return output;
}

export const isExternalLink = (link: string) => {
  return link.match(/((https|http):\/\/|www\.)/gi);
};

/**
 * Replaces all substrings between {} in the template string with their
 * matching values in the dictionary values
 * @param {string} template The string to work on
 * @param {string} key The key, the pattern {PRIMARY_VARIABLE} will be matched to this value
 * @param {Record<string, string>} values The dictionary with each pattern mapped to a value
 * @return {string} The string after being parsed
 */

/**
 * Returns dateTime as string in 'MMM d, h:mm a' format
 * @param {string} Date The Date to be converted
 * @return {string} The DateTime formatted string
 */

export function formatDatetimeToString(date: Date): string {
  if (!date) return "undefined";
  const dateString = date
    .toLocaleString("en-US", {
      day: "numeric",
      month: "short",
    })
    .split(" ");
  const timeString = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return `${dateString[1]} ${dateString[0]}, ${timeString} `;
}

export function returnNumberSign(input: number): string {
  const sign = Math.sign(input);
  switch (sign) {
    case -1:
      return "-";
    case 1:
      return "+";
    default:
      return "";
  }
}

export function reduceNumberOfDecimals(
  value: string | number | undefined,
  numberOfDecimals: number
): string {
  const parts = (value || "").toString().split(".");
  if (parts[1]) {
    parts[1] = parts[1].substr(0, numberOfDecimals);
  }
  return parts.join(".");
}

export function valueCommaSeparated(
  value: string | number | undefined
): string {
  if (isEmpty(value)) {
    return "";
  }
  const parts = (value || "0").toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function removeNumberDelimeter(value: string | number): string {
  let returnValue = value.toString();
  returnValue = returnValue.toString().replace(/,/g, "");
  const numberValue = Number(returnValue);
  if (isValidNumber(numberValue) && numberValue >= 0) {
    return returnValue;
  }
  return "0";
}

export function addZeroes(num: string | number, toFixedNumber = 2): string {
  const value = Number(num);
  return value.toFixed(toFixedNumber);
}

export function addZeroesAndSeparatevalue(
  value: string | number | undefined
): string {
  if (value) {
    return valueCommaSeparated(addZeroes(value, 2));
  }
  return "0.00";
}

export function removeSpecialCharacters(input: string) {
  return input ? input.replace(/[^a-zA-Z ]/g, "") : input;
}

export function replaceAllString(
  value: string,
  search: string,
  replacement: string
): string {
  return value.replace(new RegExp(search, "g"), replacement);
}

export const changeValueToPercentage = (value: string | number) => {
  const newVal = Number(value) * 100;
  return handlePrecision(newVal);
};

export const changeValueFromPercentage = (value: string | number) => {
  const newVal = Number(value) / 100;
  return handlePrecision(newVal);
};

export const handlePrecision = (value: number) => {
  return Number(value.toPrecision(9)) * 1;
};

export const cleanValueOfPattern = (
  value: string,
  pattern: string,
  mask: string
): string => {
  let cleanValue = value;
  let k = 0;
  for (let i = 0; i < value.length; i += 1) {
    if (pattern[i] !== mask && value[i] === pattern[i]) {
      if (!isEmpty(cleanValue[i - k])) {
        cleanValue =
          cleanValue.substring(0, i - k) +
          cleanValue.substring(i - k + 1, cleanValue.length);
        k += 1;
      }
    }
  }
  return cleanValue;
};

export const maskValueWithPattern = (
  value: string,
  pattern: string,
  mask = "x"
): string => {
  const cleanValue = cleanValueOfPattern(value, pattern, mask);
  let returnValue = "";
  let j = 0;
  for (let i = 0; i < pattern.length; i += 1) {
    if (pattern[i] !== mask) {
      returnValue += pattern[i];
    } else if (!isEmpty(cleanValue[j])) {
      returnValue += cleanValue[j];
      j += 1;
    } else {
      returnValue += mask;
    }
  }
  return returnValue;
};

export const maskValueWithPatternIfMatch = (
  value: string,
  pattern: string,
  mask = "x"
): string => {
  const cleanValue = cleanValueOfPattern(value, pattern, mask);
  let returnValue = "";
  let j = 0;
  for (let i = 0; i < pattern.length; i += 1) {
    if (pattern[i] !== mask) {
      returnValue += pattern[i];
    } else if (!isEmpty(cleanValue[j])) {
      returnValue += cleanValue[j];
      j += 1;
    } else {
      returnValue += mask;
    }
  }
  return returnValue.toLowerCase().includes(mask.toLowerCase())
    ? value
    : returnValue;
};

export function getDateWithoutTime(date: string | Date) {
  // checking the indexOf T to prevent inproper splitting example when we have Tue
  // the T will be at index 10 example: "2022-03-26T10:58:51"
  if (date) {
    let dateString = '';
    if (date instanceof Date) {
      dateString = dateToISOString(date);
    } else {
      dateString = date;
    }
    if (dateString && dateString.trim() && isValidDate(dateString)) {
      return dateString.includes('T') && dateString.indexOf('T') === 10 ? dateString.split('T')[0] : dateString.split(' ')[0];
    }
    return dateString;
  }

  return date?.toString();
}

export function capitalizeFirstLetter(
  value: string,
  splitCharacter: string = " "
) {
  if (!value) {
    return "";
  }

  return value
    .toLowerCase()
    .split(splitCharacter)
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export function capitalizeFirstCharacter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function removePlusFromMobileNumber(value: string) {
  return value.replace("+", "");
}

export function removeHTMLTagesFromDescription(value: string) {
  if (!value) {
    return "";
  }

  return value.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "");
}

export function getIdsFromMultiselectData(array: []) {
  return array?.map((item: { Id: string }) => item?.Id);
}

export function getTitlesFromMultiselectData(array: any[]) {
  if (!array) {
    return "";
  }

  return array?.map((item: { Title: string }) => item?.Title).join(", ");
}

export function capitalizeFirstLetterLowerOthers(
  value: string,
  splitCharacter: string = ' ',
) {
  if (!value) {
    return '';
  }

  return value
    .toLowerCase()
    .split(splitCharacter)
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)?.toLowerCase();
    })
    .join(' ');
}

export function multiplyAndFormat(num: number): string {
  if(!isValidNumber(num)){
    return "";
  }
  const result = num * 100;
  return Number.isInteger(result)
      ? result.toString()
      : parseFloat(result.toFixed(2)).toString();
}
