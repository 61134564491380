import { gql } from "@apollo/client";

export function listQuery() {
  return gql`
    query LIST_PERSONSAPPLICATIONS(
      $currentPage: Int!
      $currentPageSize: Int!
      $selectedTypes: [SalesforceManagement_PersonTypes!]
      $personStatus: [SalesforceManagement_PersonStatuses!]
      $createdDateFrom: Date
      $createdDateTo: Date
      $Attribute: String!
      $Descending: Boolean!
      $keywordSearch: String
    ) {
      SalesforceManagement {
        queries {
          getPersonList(
            pagination: { pageNumber: $currentPage, pageSize: $currentPageSize }
            OrderBy: { Attribute: $Attribute, Descending: $Descending }
            SelectedTypes: $selectedTypes
            SelectedStatuses: $personStatus
            FromCreatedOn: $createdDateFrom
            ToCreatedOn: $createdDateTo
            KeywordSearch: $keywordSearch
          ) {
            paging {
              pageSize
              pageNumber
              totalCount
            }
            items {
              salesforceManagement_Person_Id
              salesforceManagement_Person_PersonCode
              salesforceManagement_Person_FullName
              salesforceManagement_Person_Title {
                Code
                Title
              }
              salesforceManagement_Person_PersonType
              salesforceManagement_Person_Email
              salesforceManagement_Person_PhoneDetails {
                PhoneType
                PhoneNumber
              }
              salesforceManagement_Person_Status
              salesforceManagement_Person_createdOn
              salesforceManagement_Person_modifiedOn
            }
          }
        }
      }
      SalesforceManagement_PersonTypes: __type(
        name: "SalesforceManagement_PersonTypes"
      ) {
        name
        enumValues {
          name
        }
      }
      SalesforceManagement_PersonStatuses: __type(
        name: "SalesforceManagement_PersonStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}

export function filterQuery() {
  return gql`
    query PERSONS_LISTING_LOVS {
      SalesforceManagement_PersonTypes: __type(
        name: "SalesforceManagement_PersonTypes"
      ) {
        name
        enumValues {
          name
        }
      }
      SalesforceManagement_PersonStatuses: __type(
        name: "SalesforceManagement_PersonStatuses"
      ) {
        name
        enumValues {
          name
        }
      }
    }
  `;
}
