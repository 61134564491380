import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UserFormLookupsToList, graphqlEntityToUserInfo } from "./utils";
import Loader from "../../components/Loader";
import { cloneDeep } from "lodash";
import { createUser, getUserInfo, getUserLookups, updateUser } from "./queries";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { isEmpty } from "../../utils/validationUtils";
import _ from "lodash";
import { getError } from "../../utils/graph-utils";
import { removePlusFromMobileNumber } from "../../utils/formatting-utils";

const UserDrawer: React.FC<IUserDrawerProps> = ({
  id,
  open,
  onSuccess,
  onClose,
}) => {
  const [booted, setBooted] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);

  const [userAction] = useMutation(id ? updateUser() : createUser());

  const [loadUserLookups] = useLazyQuery(getUserLookups(), {
    fetchPolicy: "no-cache",
  });

  const [loadUserInfo] = useLazyQuery(getUserInfo(), {
    fetchPolicy: "no-cache",
  });

  const loadLovList = async () => {
    const result = await loadUserLookups();
    if (result.data) {
      return UserFormLookupsToList(result.data);
    }
    return {};
  };

  const getEntityInfo = async () => {
    if (id) {
      const apiResult = await loadUserInfo({ variables: { userId: id } });
      if (apiResult.data) {
        return graphqlEntityToUserInfo(apiResult.data);
      }
    }
    return null;
  };

  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      const [userEntity, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      if (userEntity) {
        updatedInputs.firstName.value = userEntity.firstName;
        updatedInputs.lastName.value = userEntity.lastName;
        updatedInputs.email.value = userEntity.email;
        updatedInputs.mobile.value = userEntity.mobile;
        updatedInputs.role.value = userEntity.role?.toUpperCase();
        updatedInputs.status.value = userEntity.status?.toUpperCase();
      }

      if (lovData) {
        (updatedInputs.role as IFormSelectDynamicProps).selectOptions =
          lovData["Insurance_UserRoles"];
        (updatedInputs.status as IFormSelectDynamicProps).selectOptions =
          lovData["Insurance_UserStatuses"];
      }

      (updatedInputs.email as IFormSelectDynamicProps).disabled = id
        ? true
        : false;

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    if (open) {
      initialize();
    }
  }, []);

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setSubmitButtonState("loading");
    setFormDisabled(true);

    try {
      const variables = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        mobile: "+" + removePlusFromMobileNumber(data.mobile),
        role: data.role,
        status: data.status,
      };

      const result = await userAction({
        variables: id ? { ...variables, userId: id } : variables,
        errorPolicy: "all",
      });

      if (isEmpty(result.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {id ? "User updated" : "New User created"}
          </ToastSuccessMessage>
        );
        setSubmitButtonState("success");
        setTimeout(() => {
          setFormDisabled(false);
          onSuccess();
          onClose();
        }, 500);
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(result)}</ToastErrorMessage>);
      }
    } catch (error) {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
    }
  };

  return (
    <GenericDrawer
      title={id ? "Edit User" : "New User"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default UserDrawer;
