import React, { useEffect, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import {
  DynamicFormInputType,
  IFormSelectDynamicProps,
} from "../../DynamicForm";
import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { inputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  createAgency,
  getAgencyEnums,
  getAgencyInfo,
  updateAgency,
} from "./queries";
import { LookupToList, graphqlEntityToAgencyInfo } from "./utils";
import Loader from "../../components/Loader";
import { cloneDeep } from "lodash";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { isEmpty } from "../../utils/validationUtils";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { getError } from "../../utils/graph-utils";
import { useNavigate } from "react-router-dom";

const AgencyDrawer: React.FC<IAgencyDrawerProps> = ({
  agencyId,
  open,
  onSuccess,
  onClose,
}) => {

  const navigate = useNavigate();


  const [booted, setBooted] = useState<boolean>(false);


  const [agencyEnumQuery] = useLazyQuery(getAgencyEnums(), {
    fetchPolicy: "no-cache",
  });

  const [agencyAction] = useMutation(
    agencyId ? updateAgency() : createAgency()
  );

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();
  const [inputsForm, setInputsForm] =
    useState<Record<string, DynamicFormInputType>>(inputs);


    const [agencyInfoQuery] = useLazyQuery(getAgencyInfo(), {
      variables: { id: agencyId },
    });

    const getPageLovs =  async () => {
      const agencyEnumResults = await agencyEnumQuery();
      return agencyEnumResults.data;
    }

    const getAgencyInformation = async () => {
      if(agencyId){
        const queryResult = await agencyInfoQuery({
          variables: { id: agencyId },
        });


        if(queryResult.data){
          const result = graphqlEntityToAgencyInfo(queryResult.data);
          return result;
        }
      }
      return null;
    }


  const initialize = async () => {
    try {
      const updatedInputs = cloneDeep(inputsForm);

      const [agencyEntity, lovs] = await Promise.all([getAgencyInformation(), getPageLovs()]);

      if (lovs) {
        const newAgencyEnums = LookupToList(lovs);

        (updatedInputs.agencyType as IFormSelectDynamicProps).selectOptions =
          newAgencyEnums["agencyType"];

        (updatedInputs.agencyStatus as IFormSelectDynamicProps).selectOptions =
          newAgencyEnums["Insurance_AgencyStatuses"];

        (updatedInputs.agencyCode as IFormSelectDynamicProps).selectOptions =
          newAgencyEnums["agencyCode"];
      }

        if (agencyEntity) {
          updatedInputs.agencyName.value = agencyEntity.agencyName;
          updatedInputs.agencyType.value = agencyEntity.agencyType;
          updatedInputs.agencyStatus.value = agencyEntity.agencyStatus;
          updatedInputs.agencyCode.value = agencyEntity.agencyCode;
          updatedInputs.mtpCommissionPercentage.value =
            agencyEntity.mtpCommissionPercentage;
          updatedInputs.mbiCommissionPercentage.value =
            agencyEntity.mbiCommissionPercentage;
          updatedInputs.expatCommissionPercentage.value =
            agencyEntity.expatCommissionPercentage;
          updatedInputs.ufaCode.value =
            agencyEntity.ufaCode;
        }

      setInputsForm(updatedInputs);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputs, values);
    setFormDisabled(true);
    setSubmitButtonState("loading");
  
    try {
      const variables = {
        agencyName: data.agencyName,
        agencyStatus: data.agencyStatus,
        agencyType: data.agencyType,
        agencyCode: data.agencyCode,
        mtpCommissionPercentage: parseInt(
          data.mtpCommissionPercentage as unknown as string
        ),
        mbiCommissionPercentage: parseInt(
          data.mbiCommissionPercentage as unknown as string
        ),
        expatCommissionPercentage: parseInt(
          data.expatCommissionPercentage as unknown as string
        ),
        ufaCode: data.ufaCode,
      };
  
      const res = await agencyAction({
        variables: agencyId ? { ...variables, agencyId: agencyId } : variables,
        errorPolicy: "all",
      });
  
      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {agencyId
              ? "Business Partner successfully updated"
              : "Business Partner successfully created"}
          </ToastSuccessMessage>
        );
  
        const newAgencyId = res?.data?.insurance?.actions?.createAgency?.id;
  
        setTimeout(() => {
          setSubmitButtonState("success");
          onSuccess();
          onClose();
          if (!agencyId) {
            navigate(`/business/partners/` + newAgencyId);
          }
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (error) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <GenericDrawer
      title={agencyId ? "Edit Business Partner" : "New Business Partner"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            disableForm={formDisabled}
            title="Information"
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default AgencyDrawer;
