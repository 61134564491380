import React, { useEffect, useMemo, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";
import DynamicForm from "../../DynamicForm/DynamicForm";
import { getInputs } from "./content";
import { cloneDeep, isEmpty } from "lodash";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  createPersonAddress,
  getPersonAddressEnums,
  updatePersonAddress,
  getPersonAddressDetailsInfo,
  getZonesByCountry,
} from "./queries";
import { LookupToList, graphqlEntityToPersonAddressInfo } from "./utils";
import { toast } from "react-toastify";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { useNavigate } from "react-router-dom";
import { EnhancedButtonStatus } from "../../components/EnhancedButton";
import { getError, lookupListAsRecordObject } from "../../utils/graph-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import Loader from "../../components/Loader";

const PersonAddressDrawer: React.FC<IPersonAddressDrawerProps> = ({
  personAddressId,
  personId,
  open,
  onSuccess,
  onClose,
  onSubmitSuccess,
}) => {
  const [booted, setBooted] = useState<boolean>(false);

  const navigate = useNavigate();

  const [personAddressEnumResults] = useLazyQuery(getPersonAddressEnums());

  const [personAddressUpdateAction] = useMutation(updatePersonAddress());
  const [personAddressCreateAction] = useMutation(createPersonAddress());

  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [values, setValues] = useState<Record<string, any>>();
  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({});

  const [getZonesByCountryLazy] = useLazyQuery(getZonesByCountry(), {
    fetchPolicy: "no-cache",
  });

  const [personAddressInfoQuery] = useLazyQuery(getPersonAddressDetailsInfo());

  const loadLovList = async () => {
    const result = await personAddressEnumResults({
      fetchPolicy: "no-cache",
    });

    const list = LookupToList(result.data);

    return list;
  };

  const loadZoneLov = async (countryId: string) => {
    const result = await getZonesByCountryLazy({
      fetchPolicy: "no-cache",
      variables: { countryID: countryId },
    });

    const zonesData = result.data.Insurance.lookups.zones;

    return lookupListAsRecordObject(zonesData);
  };

  const getEntityInfo = async () => {
    let personAddressEntity: IPersonAddressInfo = {
      personId: "",
      personAddressId: "",
      addressType: "",
      country: "",
      countryId: "",
      zone: "",
      city: "",
      street: "",
      building: "",
      addressDetails: "",
      addressDetailsArabic: "",
      isPrimary: false,
    };

    if (personAddressId) {
      const apiResult = await personAddressInfoQuery({
        variables: { id: personAddressId },
      });
      if (apiResult.data) {
        personAddressEntity = graphqlEntityToPersonAddressInfo(apiResult.data);
      }
    }
    return personAddressEntity;
  };

  const initialize = async () => {
    try {
      const newValues = {
        addressType: "",
        country: "",
        zone: "",
        city: "",
        street: "",
        building: "",
        addressDetails: "",
        addressDetailsArabic: "",
        isPrimary: false,
      };
      const newLovs: Record<string, Record<string, string>> = {};
      const [entityData, lovData] = await Promise.all([
        getEntityInfo(),
        loadLovList(),
      ]);

      if (lovData) {
        newLovs.addressType = lovData.addressType;
        newLovs.country = lovData.countries;
      }

      if (entityData) {
        newValues.addressType = entityData.addressType;
        newValues.country = entityData.countryId;
        newValues.zone = entityData.zone;
        newValues.city = entityData.city;
        newValues.street = entityData.street;
        newValues.building = entityData.building;
        newValues.addressDetails = entityData.addressDetails;
        newValues.addressDetailsArabic = entityData.addressDetailsArabic;
        newValues.isPrimary = entityData.isPrimary;
      }

      if (entityData.countryId) {
        newLovs.zone = await loadZoneLov(entityData.countryId);
      }

      setLovs(newLovs);
      setValues(newValues);
      setBooted(true);
    } catch (err) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  const submitForm = async (vs: Record<string, any>) => {
    const [data] = normaliseDynamicValues(inputsForm, vs);
    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      let variables = {
        entityId: personAddressId,
        addressInputs: {
          addressType: !isEmpty(data.addressType) ? data.addressType : null,
          country: !isEmpty(data.country) ? data.country : null,
          zone: !isEmpty(data.zone) ? data.zone : null,
          city: !isEmpty(data.city) ? data.city : null,
          addressDetails: !isEmpty(data.addressDetails)
            ? data.addressDetails
            : null,
          addressDetailsArabic: !isEmpty(data.addressDetailsArabic)
            ? data.addressDetailsArabic
            : null,
          street: !isEmpty(data.street) ? data.street : null,
          building: !isEmpty(data.building) ? data.building : null,
          isPrimary: data.isPrimary ? data.isPrimary : false,
        },
      };

      let res;
      if (personAddressId) {
        res = await personAddressUpdateAction({
          variables: { ...variables, entityId: personAddressId },
          errorPolicy: "all",
        });
      } else {
        res = await personAddressCreateAction({
          variables: { ...variables, entityId: personId },
          errorPolicy: "all",
        });
      }

      if (isEmpty(res.errors)) {
        toast.success(
          <ToastSuccessMessage>
            {personAddressId
              ? "Person Address successfully updated"
              : "Person Address successfully created"}
          </ToastSuccessMessage>
        );

        const newAddressId =
          res?.data?.salesforceManagement?.entities?.person
            ?.salesforceManagement?.createPersonAddress?.id;
        const country =
          res?.data?.salesforceManagement?.entities?.person
            ?.salesforceManagement?.createPersonAddress?.views
            ?.SalesforceManagement_all?.properties?.Address?.Country?.Title;
        const zone =
          res?.data?.salesforceManagement?.entities?.person
            ?.salesforceManagement?.createPersonAddress?.views
            ?.SalesforceManagement_all?.properties?.Address?.Zone?.Title;
        const city =
          res?.data?.salesforceManagement?.entities?.person
            ?.salesforceManagement?.createPersonAddress?.views
            ?.SalesforceManagement_all?.properties?.Address?.City;
        const street =
          res?.data?.salesforceManagement?.entities?.person
            ?.salesforceManagement?.createPersonAddress?.views
            ?.SalesforceManagement_all?.properties?.Address?.Street;
        const building =
          res?.data?.salesforceManagement?.entities?.person
            ?.salesforceManagement?.createPersonAddress?.views
            ?.SalesforceManagement_all?.properties?.Address?.Building;

        const newAddressFullName = `${building ? building + ", " : ""} ${
          street ? street + ", " : ""
        }${zone ? zone + ", " : ""} ${city ? city + ", " : ""} ${
          country ? country : ""
        }`;

        if (onSubmitSuccess) {
          onSubmitSuccess(newAddressId, newAddressFullName);
        }

        setTimeout(() => {
          setSubmitButtonState("success");
          onSuccess();
          setFormDisabled(false);
          onClose();
          if (!personId) {
            navigate(`/salesforce/persons/` + personId);
          }
        }, 500);
      } else {
        setFormDisabled(false);
        setSubmitButtonState(undefined);
        toast.error(<ToastErrorMessage>{getError(res)}</ToastErrorMessage>);
      }
    } catch (err) {
      setFormDisabled(false);
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const onChange = async (
    fieldName: string,
    value: any,
    newValues: Record<string, any>
  ) => {
    if (fieldName === "country") {
      newValues.zone = "";
      const newLovs = cloneDeep(lovs);
      newLovs.zone = await loadZoneLov(value);
      setLovs(newLovs);
    }
    setValues(newValues);
  };

  const inputsForm = useMemo(() => {
    const result = getInputs((values as any) || undefined, lovs);
    return result;
  }, [values]);

  return (
    <GenericDrawer
      title={personAddressId ? "Edit Address" : "New Person Address"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {!booted ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputsForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            //isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            onChange={onChange}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PersonAddressDrawer;
