import React, { useEffect, useMemo, useState } from "react";
import GenericDrawer from "../../components/common/generic-drawer/GenericDrawer";

import { toast } from "react-toastify";
import { EnhancedButtonStatus } from "../../components/common/EnhancedButton";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { getInputs } from "./content";
import { useLazyQuery, useMutation } from "@apollo/client";
import Loader from "../../components/Loader";
import { isEmpty } from "lodash";
import { DEFAULT_ERROR_TEXT } from "../../constants";
import { IPlanAdditionalFeesAndChargesDrawerProps } from ".";
import { getAdditionalFeesAndChargesEnums, updatePlanFees } from "./queries";
import { LookupToList } from "./utils";
import { getError } from "../../utils/graph-utils";
import { normaliseDynamicValues } from "../../utils/dynamic-utils";
import ToastSuccessMessage from "../../components/ToastSuccessMessage";
import DynamicForm from "../../DynamicForm/DynamicForm";
import _ from "lodash";
import { isValidNumber } from "../../utils/validationUtils";

const PlanAdditionalFeesAndChargesDrawer: React.FC<
  IPlanAdditionalFeesAndChargesDrawerProps
> = ({
  open,
  onSuccess,
  onClose,
  additionalFeesAndChargesDetailsInfo,
  planId,
  lineExternalCode,
}) => {
  const [formDisabled, setFormDisabled] = useState(false);
  const [submitButtonState, setSubmitButtonState] =
    useState<EnhancedButtonStatus>();

  const [values, setValues] = useState<Record<string, any>>();

  const [additionalFeesAndChargesDetailsListQuery] = useLazyQuery(
    getAdditionalFeesAndChargesEnums()
  );

  const [loading, setLoading] = useState<boolean>(false);

  const [additionalFeesAndChargesAction] = useMutation(updatePlanFees());

  const [lovs, setLovs] = useState<Record<string, Record<string, string>>>({
    tPAFeesType: {},
    tPAFeesApplicableOn: {},
    planCurrency: {},
  });

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    setLoading(true);

    const newValues = _.cloneDeep(additionalFeesAndChargesDetailsInfo);

    if (isEmpty(newValues.tPAFeesType)) {
      newValues.tPAFeesType = "AMOUNT";
    }

    newValues.tPAFeesPercentage = isValidNumber(newValues.tPAFeesPercentage)
      ? (Number(newValues.tPAFeesPercentage) * 100).toFixed()
      : "";

    newValues.chargesPercentage = isValidNumber(newValues.chargesPercentage)
      ? (Number(newValues.chargesPercentage) * 100).toFixed()
      : "";

    let newLovs: Record<string, Record<string, string>> = {};
    const listingData = await additionalFeesAndChargesDetailsListQuery();

    const newAdditionalFeesAndChargesEnums = LookupToList(listingData.data);

    newLovs = {
      tPAFeesType: newAdditionalFeesAndChargesEnums["tPATypes"],
      tPAFeesApplicableOn:
        newAdditionalFeesAndChargesEnums["tPAApplicableOnOptions"],
      planCurrency: newAdditionalFeesAndChargesEnums["currencies"],
    };

    setValues(newValues);
    setLovs(newLovs);

    setLoading(false);
  };

  const submitForm = async (values: Record<string, any>) => {
    const [data] = normaliseDynamicValues(
      getInputs(values as any, lovs, lineExternalCode),
      values
    );

    setFormDisabled(true);
    setSubmitButtonState("loading");

    try {
      const isPercentage = data?.tPAFeesType?.toLowerCase() === "percentage";
      const variablesMutation = {
        entityId: planId,
        planFeesInputs: {
          policyCost: Number(data?.policyCost),
          chargesPercentage: Number(data?.chargesPercentage) / 100,
          tpaFeesType: data?.tPAFeesType,
          tpaFeesAmount: !isPercentage ? Number(data?.tPAFeesAmount) : null,
          tpaFeesPercentage: isPercentage
            ? Number(data?.tPAFeesPercentage) / 100
            : null,
          tpaFeesApplicableOn: isEmpty(data?.tPAFeesApplicableOn)
            ? null
            : data?.tPAFeesApplicableOn,
          minTPA: isPercentage ? Number(data?.minTPA) : null,
          maxTPA: isPercentage ? Number(data?.maxTPA) : null,
        },
      };

      const response = await additionalFeesAndChargesAction({
        variables: variablesMutation,
        errorPolicy: "all",
      });

      if (isEmpty(response.errors)) {
        toast.success(
          <ToastSuccessMessage>
            Additional Fees and Charges successfully updated.
          </ToastSuccessMessage>
        );
        setTimeout(() => {
          setSubmitButtonState("success");
          onSuccess();
          onClose();
        }, 500);
      } else {
        setSubmitButtonState(undefined);
        toast.error(
          <ToastErrorMessage>{getError(response)}</ToastErrorMessage>
        );
      }
    } catch (err) {
      setSubmitButtonState(undefined);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    } finally {
      setFormDisabled(false);
    }
  };

  const onChange = async (
    fieldName: string,
    value: unknown,
    newValues: Record<string, any>,
    errors: Record<string, string>,
    touched: Record<string, boolean>
  ) => {
    setValues(newValues);
    if (
      fieldName === "minTPA" ||
      fieldName === "maxTPA" ||
      fieldName === "tPAFeesAmount"
    ) {
      const minTPA = parseInt(newValues.minTPA);
      const maxTPA = parseInt(newValues.maxTPA);

      // const tpaAmount = parseInt(newValues.tPAFeesAmount);

      if (minTPA > maxTPA) {
        errors.minTPA = "Min TPA must be less than Max TPA";
        errors.maxTPA = "Max TPA must be greater than Min TPA";

        touched.minTPA = true;
        touched.maxTPA = true;
      }

      // if (newValues.tPAFeesType === "AMOUNT") {
      //   if (minTPA > tpaAmount) {
      //     touched.tPAFeesAmount = true;
      //     errors.tPAFeesAmount = "TPA Amount cannot be less than Min TPA";
      //   } else if (maxTPA < tpaAmount) {
      //     touched.tPAFeesAmount = true;
      //     errors.tPAFeesAmount = "TPA Amount cannot be greater than Max TPA";
      //   }
      // }
    }

    if (fieldName === "tPAFeesType") {
      newValues.tPAFeesAmount = "";
      errors.tPAFeesAmount = "";
      touched.tPAFeesAmount = false;

      newValues.tPAFeesPercentage = "";
      errors.tPAFeesPercentage = "";
      touched.tPAFeesPercentage = false;
    }
  };

  const onCustomValidateForm = async (
    v: Record<string, any>,
    errors: Record<string, string>
  ) => {
    const minTPA = parseInt(v.minTPA);
    const maxTPA = parseInt(v.maxTPA);

    // const tpaAmount = parseInt(v.tPAFeesAmount);

    if (v.tPAFeesType === "AMOUNT") {
      if (!errors.minTPA) {
        if (minTPA > maxTPA) {
          errors.minTPA = "Min TPA must be less than Max TPA";
        }
      }

      if (!errors.maxTPA) {
        if (minTPA > maxTPA) {
          errors.maxTPA = "Max TPA must be greater than Min TPA";
        }
      }
    }

    // if (!errors.tPAFeesAmount) {
    //   if (v.tPAFeesType === "AMOUNT") {
    //     if (minTPA > tpaAmount) {
    //       errors.tPAFeesAmount = "TPA Amount cannot be less than Min TPA";
    //     } else if (maxTPA < tpaAmount) {
    //       errors.tPAFeesAmount = "TPA Amount cannot be greater than Max TPA";
    //     }
    //   }
    // }

    return errors;
  };

  const inputForm = useMemo(() => {
    const result = getInputs(values as any, lovs, lineExternalCode);

    return result;
  }, [values, lovs]);

  return (
    <GenericDrawer
      title={"Modify Additional Fees and Charges"}
      onClose={() => onClose()}
      isOpen={open}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <DynamicForm
            inputs={inputForm}
            onSubmit={(values) => submitForm(values)}
            buttonText={"Submit"}
            submitButtonState={submitButtonState}
            onCustomValidate={onCustomValidateForm}
            // isSubmitButtonDisabled={submitButtonDisabled}
            disableForm={formDisabled}
            title="Information"
            hasDoprdownSpecificBehavior={true}
            onChange={onChange}
          />
        </>
      )}
    </GenericDrawer>
  );
};

export default PlanAdditionalFeesAndChargesDrawer;
