import dayjs from "dayjs";
import {
	IPaymentSimulationResponseData,
	IPaymentSimulationTableData,
	IPaymentTermsPopupFormState,
} from ".";

export const getPaymentTermsPopupFormState =
	(): IPaymentTermsPopupFormState => ({
		values: {
			policyNumber: null,
			policyIssuanceDate: null,
			policyExpiryDate: null,
			paymentType: null,
			effectiveFrom: null,
			frequency: null,
			numOfPayments: null,
			bills: [],
		},
		errors: {
			policyIssuanceDate: null,
			policyExpiryDate: null,
			paymentType: null,
			effectiveFrom: null,
			frequency: null,
			numOfPayments: null,
			amountDue: null,
		},
		touched: {
			policyIssuanceDate: false,
			policyExpiryDate: false,
			paymentType: false,
			effectiveFrom: false,
			frequency: false,
			numOfPayments: false,
		},
	});

export const arrayToList = (arr: string[]) => {
	let list: Record<string, any> = {};

	arr.forEach((element: string) => {
		list[element] = element;
	});

	return list;
};

export const numToList = (num: number) => {
	let list: Record<string, any> = {};

	for (let i = 1; i <= num; i++) {
		list[i] = i;
	}

	return list;
};

export const mapToListingData = (
	data: Array<IPaymentSimulationResponseData>
) => {
	let list: Record<string, IPaymentSimulationTableData> = {};

	data.forEach((obj: IPaymentSimulationResponseData, i: number) => {
		list[i] = {
			billNumber: obj.BillNumber,
			dueDate: obj.DueDate,
			totalPremium: obj.TotalPremium,
			totalCommission: obj.TotalCommission,
			amountDue: obj.AmountDue,
			outstandingAmount: obj.OutstandingBalance,
			status: obj.BillStatus,
			disabled: obj.BillStatus.toLowerCase() === "paid",
		};
	});

	// sort by due date ascending using dayjs
	const sortedList = Object.values(list).sort((a, b) => {
		return dayjs(a.dueDate).diff(dayjs(b.dueDate));
	});

	return sortedList;
};
