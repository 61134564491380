import React, { useEffect, useState } from "react";
import { IListingData } from "../../models/listing";
import StaticLayout from "../../page-layout/static-layout/StaticLayout";
import { useLazyQuery, useQuery } from "@apollo/client";
import { filterQuery, listQuery } from "./queries";
import { dataToLovs, mapToListingData } from "./utils";
import { filterSectionsContent, headers } from "./content";
import EnhancedTable from "../../components/enhanced-table/EnhancedTable";
import { IListingFilterWidgetSection } from "../../components/widgets/custom-listing-filter";
import ListingFilterWidget from "../../components/widgets/custom-listing-filter/ListingFilterWidget";
import { IAbstractRecord } from "../../models";
import _, { isEmpty } from "lodash";
import { getFilter, setFilter } from "../../utils/filter-utils";
import ToastErrorMessage from "../../components/ToastErrorMessage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_ERROR_TEXT,
  SEND_TO_BACKEND_DATE_FORMAT,
} from "../../constants";
import dayjs from "dayjs";
import { ITableOrder, TableSortOrder } from "../../utils/table-utils";
import { capitalizeFirstCharacter } from "../../utils/formatting-utils";
import { useAppSelector } from "../../redux/hooks";
import ClausePopUp from "../../forms/clause-popup/ClausePopUp";
import { IEnhancedTableMenuItem, IEnhanceTableHeaderClickable } from "../../components/enhanced-table";

interface IClauseLineData {
  lineId: string;
  lineName: string;
  clauseId: string;
}

const ClausesPage: React.FC = ({}) => {
  const navigate = useNavigate();
  navigate;
  const user = useAppSelector((state) => state.user);

  const [loadingState, setLoadingState] = useState<boolean>(false);
  let setSearchTimeout: NodeJS.Timeout;
  const [clauseDrawerStatus, setClauseDrawerStatus] = useState<IClauseLineData>();

  const [keywordSearch, setKeywordSearch] = useState("");

  const FILTER_SESSION_KEY = "clausesFilter";

  const [filterSections, setFilterSections] =
    useState<IListingFilterWidgetSection[]>();

    const initialfilterValues =
    Object.keys(getFilter(FILTER_SESSION_KEY)).length > 0
      ? getFilter(FILTER_SESSION_KEY)
      : {
          line: [],
          SelectedStatuses: [],
          createdOn: [],
        };

  const [filterValues, setFilterValues] =
    useState<IAbstractRecord>(initialfilterValues);

  const [booted, setBooted] = useState<boolean>(false);

  const [tableOrder, setTableOrder] = useState<Record<string, ITableOrder>>({
    clause: {
      orderBy: "",
      orderDirection: "asc",
    },
  });

  const [tableData, setTableData] = useState<IListingData<any>>({
    pagedItems: {},
    pageSize: 10,
    pageNumber: 0,
    totalCount: 0,
  });

  const isAdmin = user.userRoles.includes("Insurance-Admin");
  const actions: IEnhancedTableMenuItem[] = [
    {
      title: "+ New",
      onClick: () => {
        setClauseDrawerStatus({
          lineName: "",
          lineId: "",
          clauseId: ""
        });
      },
      isEntity: false,
      isBulk: false,
      iconUrl: "",
      hidden: !isAdmin,
    },
  ];

  const [getClausesLazy] = useLazyQuery(listQuery());

  const loadClauseList = async (
    currentPage = 0,
    pageSize = tableData.pageSize,
    orders = tableOrder,
    search = keywordSearch,
    filterV = filterValues,
  ) => {
    setLoadingState(true); // Set loading state

    const result = await getClausesLazy({
      variables: {
        currentPage: currentPage + 1,
        currentPageSize: pageSize,
        SelectedLineId:
          filterV?.line && filterV?.line?.length > 0
            ? filterV?.line
            : [],
        SelectedStatuses:
          filterV?.status && filterV?.status?.length > 0
            ? filterV.status
            : null,
        FromCreatedOn: filterV?.createdOn?.[0]
          ? dayjs(filterV?.createdOn?.[0]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
        ToCreatedOn: filterV?.createdOn?.[1]
          ? dayjs(filterV?.createdOn?.[1]).format(
              SEND_TO_BACKEND_DATE_FORMAT
            )
          : null,
        Attribute: capitalizeFirstCharacter(
          orders.clause.orderBy || "PlanConfigManagement_Clause_Name"
        ),
        Descending: orders.clause.orderDirection !== "asc",
        keywordSearch: search,
      },
      errorPolicy: "all",
    });

    const mappedPlanList = await mapToListingData(result.data);

    if (result.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }

    setKeywordSearch(search);
    setTableData({ ...mappedPlanList, pageNumber: currentPage, pageSize });
    setLoadingState(false); // Reset loading state

    return result;
  };

  const initialize = async () => {
    try {
      await loadClauseList();
      const filterDataResponse = await filterResponse.refetch();
      const lovs = dataToLovs(filterDataResponse.data);
      const savedFilters = getFilter(FILTER_SESSION_KEY) || filterValues;

      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterValues(savedFilters);
      setFilterSections(newFilterSections);
      setBooted(true);
    } catch (error) {
      console.error("Error initializing data:", error);
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const filterResponse = useQuery(filterQuery(), {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (filterResponse?.error) {
      toast.error(<ToastErrorMessage>{DEFAULT_ERROR_TEXT}</ToastErrorMessage>);
    }
    if (filterResponse?.data) {
      let savedFilters = getFilter(FILTER_SESSION_KEY);
      if (isEmpty(savedFilters)) {
        savedFilters = _.cloneDeep(filterValues);
      }
      setFilterValues(savedFilters);

      const lovs = dataToLovs(filterResponse.data);
      if (!Object.keys(lovs.line).length) {
        lovs.line = filterValues?.line;
      }

      if (!Object.keys(lovs.status).length) {
        lovs.status = filterValues?.status;
      }
      const newFilterSections = filterSectionsContent(lovs, savedFilters);
      setFilterSections(newFilterSections);
    }
  }, [filterResponse]);

  const delaySearch = (val: string) => {
    clearTimeout(setSearchTimeout);
    setSearchTimeout = setTimeout(() => {
      loadClauseList(0, tableData.pageSize, tableOrder, val, filterValues);
    }, 1000);
  };

  const handleSortChange = (orderBy: string, order: string) => {
    const newOrder = _.cloneDeep(tableOrder);
    newOrder.clause.orderBy = orderBy;
    newOrder.clause.orderDirection = order as any;
    loadClauseList(
      tableData.pageNumber,
      tableData.pageSize,
      newOrder,
      keywordSearch,
      filterValues
    );
    setTableOrder(newOrder);
  };

  const handlePageChange = async (
    page: number,
    pageSize: number
  ) => {
    await loadClauseList(page, pageSize, tableOrder, keywordSearch, filterValues);
  };

  actions[0].onClick = () => {
    setClauseDrawerStatus({
      lineId: "",
      clauseId: "",
      lineName: ""
    });
  };

  (
    headers.planConfigManagement_Clause_Name as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    setClauseDrawerStatus({
      lineId: payload.columns.lineId,
      clauseId: payload.columns.planConfigManagement_Clause_Id,
      lineName: payload.columns.planConfigManagement_Clause_LineID,
    });
  };

  (
    headers.planConfigManagement_Clause_LineID as IEnhanceTableHeaderClickable
  ).callback = (payload: any) => {
    navigate("/plan/lines/" + payload.columns.lineId);
  };

  const renderMainChildren = () => {
    return (
      <>
        <div style={{ marginTop: "20px" }}>
          <EnhancedTable
            title='Clauses'
            name='count'
            orderByAscendingByDefault
            inline={false}
            data={tableData}
            headers={headers}
            handlePageChange={(page: number) =>
              handlePageChange(page, tableData.pageSize)
            }
            handleSort={handleSortChange}
            handleRowsPerPageChange={(pageSize: number) =>
              handlePageChange(0, pageSize)
            }
            handleSearchChange={delaySearch}
            currentPage={tableData.pageNumber}
            hideToolbar={false}
            usePagination
            disableSelection
            actions={actions}
            loader={loadingState}
            isServerSide
            isToolbarAction={false}
            ordering={tableOrder.clause.orderDirection as TableSortOrder}
            orderingBy={tableOrder.clause.orderBy}
          />
      {!!clauseDrawerStatus && (
        <ClausePopUp
          open={!!clauseDrawerStatus}
          onClose={() => setClauseDrawerStatus(undefined)}
          onSuccess={() => {
            handlePageChange(0, 10);
          }}
          lineId={clauseDrawerStatus.lineId || null}
          lineName={clauseDrawerStatus.lineName || null}
          clauseId={clauseDrawerStatus.clauseId || null}
          isLineFieldEnabled={true}
        />
      )}
        </div>
      </>
    );
  };

  const onFilterUpdate = async (v: Record<string, any>) => {
    setFilter(v, FILTER_SESSION_KEY);
    loadClauseList(0, tableData.pageSize, tableOrder, keywordSearch, v);
  };

  const renderFilter = () => {
    return (
      filterSections && (
        <ListingFilterWidget
          name={""}
          filters={filterSections}
          onApplyFilter={(v) => {
            onFilterUpdate(v);
          }}
        ></ListingFilterWidget>
      )
    );
  };

  return (
    <StaticLayout
      loading={!booted}
      name={"Applications"}
      leftChildren={renderFilter()}
      mainChildren={renderMainChildren()}
    />
  );
};

export default ClausesPage;
