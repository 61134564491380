import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { formatDateTime } from '../../../../../../utils/formatting-utils';

interface ILeftSideStaticColumn {
  title: string;
  fields: {
    title: string;
    value: string;
    type: 'text' | 'date';
  }[];
}

const useStyles = makeStyles()(() => ({
  leftSideTitle: {
    fontSize: '22px',
    margin: '20px 0 30px',
    padding: '0 0 30px',
    textAlign: 'center',
    borderBottom: '6px solid #D30D2B',
    fontFamily: 'SourceSansPro-SemiBold',
  },
  leftSideItem: {
    margin: '0 0 15px',
  },
  leftSideItemTitle: {
    fontSize: '15px',
    margin: '0',
    fontFamily: 'SourceSansPro-Medium',
  },
  leftSideItemValue: {
    fontSize: '15px',
    margin: '0',
    fontFamily: 'SourceSansPro-Regular',
  },
}));

const LeftSideStaticColumn: React.FC<ILeftSideStaticColumn> = ({
  title,
  fields,
}) => {
  const { classes, cx } = useStyles();

  return (
    <div>
      <p className={classes.leftSideTitle}>{title}</p>
      {fields.map((field) => (
        <p className={cx(classes.leftSideItem, classes.leftSideItemValue)}>
          <span className={classes.leftSideItemTitle}>{`${field.title} `}</span>
          {renderValue(field.value, field.type)}
        </p>
      ))}
    </div>
  );
};

const renderValue = (value: string, type: 'text' | 'date') => {
  switch (type) {
    case 'date':
      return formatDateTime(value);
    case 'text':
    default:
      return value;
  }
};

export default LeftSideStaticColumn;
